import axios from 'axios'
import { useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'

import AgentAccessIcon from '../../../../../assets/icons/agent-access.svg'
import DownArrowIcon from '../../../../../assets/icons/arrow-down.svg'
import DocumentsIcon from '../../../../../assets/icons/documents.svg'
import OrganizationHistoryIcon from '../../../../../assets/icons/organization-history.svg'
import OrganizationInfoIcon from '../../../../../assets/icons/organization-info.svg'
import OrganizationUsersIcon from '../../../../../assets/icons/organization-users.svg'
import ShipmentsIcon from '../../../../../assets/icons/shipments.svg'
import {
	EditForm,
	ParsedFormData,
} from '../../../../../components/Form/EditForm'
import { Modal } from '../../../../../components/Modal'
import { NavTable } from '../../../../../components/NavTable'
import { OrganizationSummary } from '../../../../../components/OrganizationSummary'
import { GlobalTopTabItems } from '../../../../../components/Tabs'
import { DefaultLayout } from '../../../../../layouts/DefaultLayout'
import {
	APIHostname,
	HasPermission,
	HasRole,
	LogOff,
} from '../../../../../Profile'
import Control from '../../../../../types/Control'
import { OrganizationAccessDocumentLayout } from '../../../../../types/data/OrganizationAccessDocument'
import { EscapeURIParameters } from '../../../../../Utils'
import styles from './OrganizationDocuments.module.scss'

export const OrganizationDocuments = () => {
	const { id } = useParams()

	const [documents, setDocuments]: any = useState(null)
	const [organization, setOrganization]: any = useState(null)
	const [organizationLoaded, setOrganizationLoaded] = useState(false)
	const [documentsLoaded, setDocumentsLoaded] = useState(false)
	const [documentCount, setDocumentCount] = useState(0)
	const [errorMessage, setErrorMessage] = useState(<></>)
	const initialRedirectMessage = sessionStorage['redirect-message']
	const [redirectMessage] = useState(initialRedirectMessage)
	const [showModal, setShowModal] = useState(false)

	// Vary based on query string.
	const location = useLocation()
	const urlParams = new URLSearchParams(location.search)
	if (!urlParams.get('limit')) {
		urlParams.set('limit', '25')
	}

	useEffect(() => {
		axios({
			data: {},
			headers: {
				Authorization: `Bearer ${localStorage['token']}`,
			},
			method: 'GET',
			url: `${APIHostname}/api/v1/organizations/${id}`,
		})
			.then((organizationResponse: any) => {
				setErrorMessage(<></>)
				setOrganization(organizationResponse.data)
				setOrganizationLoaded(true)
			})
			.catch((error: any) => {
				switch (error?.response?.status) {
				case 401:
					LogOff('token-expired')
					return
				case 403:
					setErrorMessage(
						<>
								Error: You do not have permission to edit
								organization documents.
						</>
					)
					break
				default:
					if (error?.response?.data?.error) {
						setErrorMessage(
							error?.response?.data?.link ? (
								<Link to={error?.response?.data?.link}>
										Error:
									{error?.response?.data?.error}
								</Link>
							) : (
								<>{error?.response?.data?.error}</>
							)
						)
					} else {
						setErrorMessage(<>Error: Unknown error.</>)
							console.error(error) // eslint-disable-line
					}
				}
			})

		axios({
			data: {},
			headers: {
				Authorization: `Bearer ${localStorage['token']}`,
			},
			method: 'GET',
			url: `${APIHostname}/api/v1/organizations/${id}/documents?${EscapeURIParameters(
				urlParams.toString()
			)}&view=browse`,
		})
			.then((response: any) => {
				setErrorMessage(<></>)
				setDocuments(response.data.documents)
				setDocumentCount(response.data.document_count)
				setDocumentsLoaded(true)
			})
			.catch((error: any) => {
				if (error?.response?.status == 401) {
					LogOff('token-expired')
					return
				}
				console.error(error) // eslint-disable-line
				setErrorMessage(<>Error: Unknown error.</>)
			})
	}, [location])

	const OrganizationLeftMenuItems = [
		{
			icon: OrganizationInfoIcon,
			requiredPermissions: ['organization.read'],
			text: 'ORGANIZATION INFO',
			to: `/organizations/${id}`,
		},
		{
			icon: DocumentsIcon,
			requiredPermissions: ['shipment.read'],
			text: 'ORGANIZATION DOCUMENTS',
			to: `/organizations/${id}/documents`,
		},
		{
			icon: ShipmentsIcon,
			requiredPermissions: ['shipment.read'],
			text: 'LINKED SHIPMENTS',
			to: `/organizations/${id}/shipments`,
		},
		{
			icon: OrganizationUsersIcon,
			requiredPermissions: ['user.read'],
			text: 'ORGANIZATION USERS',
			to: `/organizations/${id}/users`,
		},
		{
			icon: AgentAccessIcon,
			requiredPermissions: ['organization.manageaccess'],
			text: 'PARTNER ACCESS',
			to: `/organizations/${id}/partner_access`,
		},
		{
			icon: OrganizationHistoryIcon,
			requiredPermissions: ['organization.read'],
			text: 'ORGANIZATION HISTORY',
			to: `/organizations/${id}/history`,
		},
	]

	const OrganizationBottomTabItems = [
		{
			icon: DownArrowIcon,
			text: `ORGANIZATION #${id} DOCUMENTS`,
			to: `/organizations/${id}/documents`,
		},
	]

	if (!organizationLoaded || !documentsLoaded) {
		if (errorMessage) {
			return (
				<DefaultLayout
					bottomTabItems={OrganizationBottomTabItems}
					leftMenuItems={OrganizationLeftMenuItems}
					showPrint={true}
					tabMenuItems={OrganizationTabMenuItems}
					topTabItems={GlobalTopTabItems}
				>
					<div className={styles.organizationDocumentsContainer}>
						<div className={styles.redirectMessage}>
							{redirectMessage}
						</div>
						<div className={styles.errorMessage}>
							{errorMessage}
						</div>
					</div>
				</DefaultLayout>
			)
		}

		return <div className="loading">Loading...</div>
	}

	const columns = [
		{
			control: Control.FileName,
			id: 'blob_filename',
			title: 'FILE NAME',
		},
		{
			id: 'description',
			title: 'Description',
		},
		{
			id: 'type',
			title: 'Type',
		},
		{
			control: Control.FileSize,
			id: 'size',
			title: 'Size',
		},
		{
			control: Control.DateTime,
			id: 'created',
			title: 'Uploaded Time',
		},
		{
			control: Control.User,
			id: 'created_by',
			title: 'Uploaded By',
		},
		{
			control: Control.Download,
			id: 'download',
			title: 'Download',
		},
	]

	if (HasRole('administrator', 'partner', 'memberpartner')) {
		columns.push({
			control: Control.Boolean,
			id: 'organization_access',
			title: 'Organization Access',
		})
	}

	sessionStorage.removeItem('redirect-message')
	return (
		<DefaultLayout
			bottomTabItems={OrganizationBottomTabItems}
			leftMenuItems={OrganizationLeftMenuItems}
			showPrint={true}
			tabMenuItems={OrganizationTabMenuItems}
			topTabItems={GlobalTopTabItems}
		>
			{showModal ? (
				<Modal setShowModal={setShowModal}>
					<h2>UPLOAD A DOCUMENT</h2>
					<EditForm
						data={{}}
						layout={OrganizationAccessDocumentLayout}
						name="document-form"
						onSubmit={async (formData: FormData) => {
							const parsedFormData = await ParsedFormData(
								formData,
								[]
							)
							setShowModal(false)
							parsedFormData['organization'] = parseInt(id ?? '0')

							// Create organization document
							axios({
								data: parsedFormData,
								headers: {
									Authorization: `Bearer ${localStorage['token']}`,
									'Content-Type': 'application/json',
								},
								method: 'POST',
								url: `${APIHostname}/api/v1/organizations/${id}/documents`,
							})
								.then((response: any) => {
									setShowModal(false)
									sessionStorage[
										'redirect-message'
									] = `Document #${response.data.document_id} created.`
									document.location.reload()
								})
								.catch((error: any) => {
									switch (error?.response?.status) {
									case 401:
										LogOff('token-expired')
										return
									case 403:
										setErrorMessage(
											<>
													Error: You do not have
													permission to edit
													organization documents.
											</>
										)
										break
									default:
										if (error?.response?.data?.error) {
											setErrorMessage(
												error?.response?.data
													?.link ? (
														<Link
															to={
																error?.response
																	?.data?.link
															}
														>
															Error:
															{
																error?.response
																	?.data
																	?.error
															}
														</Link>
													) : (
														<>
															{
																error?.response
																	?.data
																	?.error
															}
														</>
													)
											)
										} else {
											setErrorMessage(
												<>Error: Unknown error.</>
											)
												console.error(error) // eslint-disable-line
										}
									}
								})
						}}
						previewAPIData={async (formData: FormData) => {
							const parsedFormData = await ParsedFormData(
								formData,
								[]
							)
							parsedFormData['organization'] = parseInt(id ?? '0')

							return {
								data: JSON.stringify(parsedFormData, null, 4),
								method: 'POST',
								url: `${APIHostname}/api/v1/organizations/${id}/documents`,
							}
						}}
						readOnly={
							!HasPermission('organization', 'update') ||
							!organization?.active
						}
					></EditForm>
				</Modal>
			) : (
				<></>
			)}
			<div className={styles.organizationDocumentsContainer}>
				<OrganizationSummary organization={organization}>
					{HasPermission('nra', 'update') ? (
						<div className={styles.uploadButton}>
							<input
								className="pseudoSubmit"
								onClick={() => {
									setShowModal(true)
								}}
								type="button"
								value="UPLOAD FILE"
							/>
						</div>
					) : (
						<></>
					)}
				</OrganizationSummary>

				<div className={styles.redirectMessage}>{redirectMessage}</div>
				<div className={styles.errorMessage}>
					{errorMessage}{' '}
					{!organization?.active ? (
						<>This organization has been deleted.</>
					) : (
						<></>
					)}
				</div>

				<div className={styles.navTable}>
					<NavTable
						columns={columns}
						count={documentCount}
						data={documents}
						filters={JSON.parse(urlParams.get('filters') ?? '[]')}
						includeDelete={HasPermission('organization', 'delete')}
						includeExport={false}
						includeOrganizationAccess={HasPermission(
							'shipment',
							'update'
						)}
						primaryDisplayColumn=""
						primaryKey=""
					/>
				</div>
			</div>
		</DefaultLayout>
	)
}

export const OrganizationTabMenuItems = []
