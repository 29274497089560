import { getCountriesForTimezone } from 'countries-and-timezones'
import { Link } from 'react-router-dom'

import { HasRole } from '../../Profile'
import styles from './Footer.module.scss'

export const Footer = () => {
	const now = new Date()
	const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
	return (
		<footer className={styles.footer}>
			<div className={styles.copyright}>
				&copy; COPYRIGHT 2003 - {now.getFullYear()} GLOBAL OCEAN AGENCY
				LINES. ALL RIGHTS RESERVED.
			</div>
			{HasRole('administrator') ? (
				<div className={styles.sitemap}>
					<Link to="/sitemap">SITEMAP</Link>
				</div>
			) : (
				<></>
			)}
			<div className={styles.timezone}>
				NOTE: ALL TIMES DISPLAYED AS{' '}
				{getCountriesForTimezone(timeZone).map((country, index) => {
					return (
						<span key={index}>
							{' '}
							<span
								className={`fi fi-${country.id.toLowerCase()} ${
									styles.flag
								}`}
								title={country.name}
							></span>{' '}
						</span>
					)
				})}
				{timeZone.toUpperCase()}
			</div>
		</footer>
	)
}
