import axios from 'axios'
import { MouseEvent, useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'

import AgentAccessIcon from '../../../../assets/icons/agent-access.svg'
import DownArrowIcon from '../../../../assets/icons/arrow-down.svg'
import ChainIcon from '../../../../assets/icons/chain.svg'
import CopyIcon from '../../../../assets/icons/copy.svg'
import DeleteIcon from '../../../../assets/icons/delete.svg'
import DocumentsIcon from '../../../../assets/icons/documents.svg'
import PayBalanceIcon from '../../../../assets/icons/goal-pay-balance.svg'
import PayRequestMoneyIcon from '../../../../assets/icons/goal-pay-request-money.svg'
import PaySendMoneyIcon from '../../../../assets/icons/goal-pay-send-money.svg'
import PayTransactionsIcon from '../../../../assets/icons/goal-pay-transactions.svg'
import PayIncomingRequestsIcon from '../../../../assets/icons/goal-pay-transfer-money.svg'
import PartnerHistoryIcon from '../../../../assets/icons/organization-history.svg'
import PartnerInfoIcon from '../../../../assets/icons/organization-info.svg'
import PartnerUsersIcon from '../../../../assets/icons/organization-users.svg'
import { EditForm, ParsedFormData } from '../../../../components/Form/EditForm'
import { LeftMenuItem } from '../../../../components/LeftMenu'
import { GlobalTopTabItems, TabItem } from '../../../../components/Tabs'
import { DefaultLayout } from '../../../../layouts/DefaultLayout'
import {
	APIHostname,
	HasPermission,
	HasRole,
	LogOff,
	ProfilePartnerID,
} from '../../../../Profile'
import { PartnerEditLayout } from '../../../../types/data/Partner'
import styles from './Partner.module.scss'

export const Partner = () => {
	const { id } = useParams()
	const [partner, setPartner]: any = useState(null)
	const [partnerLoaded, setPartnerLoaded]: any = useState(false)
	const [errorMessage, setErrorMessage] = useState(<></>)
	const initialRedirectMessage = sessionStorage['redirect-message']
	const [redirectMessage] = useState(initialRedirectMessage)
	const [payRequestCount, setPayRequestCount] = useState(0)
	const [countsLoaded, setCountsLoaded] = useState(false)

	// Vary based on query string.
	const location = useLocation()

	useEffect(() => {
		axios({
			data: {},
			headers: {
				Authorization: `Bearer ${localStorage['token']}`,
			},
			method: 'GET',
			url: `${APIHostname}/api/v1/partners/${id}`,
		})
			.then((response: any) => {
				setErrorMessage(<></>)
				setPartner(response.data)
				setPartnerLoaded(true)

				axios({
					data: {},
					headers: {
						Authorization: `Bearer ${localStorage['token']}`,
					},
					method: 'GET',
					url: `${APIHostname}/api/v1/partners/${id}/pay_counts`,
				})
					.then((response: any) => {
						setErrorMessage(<></>)
						setPayRequestCount(response.data.pay_request_count)
						setCountsLoaded(true)
					})
					.catch((error: any) => {
						switch (error?.response?.status) {
						case 401:
							LogOff('token-expired')
							return
						case 403:
							setCountsLoaded(true)
							setErrorMessage(
								<>
										Error: You do not have permission to
										view GOAL pay requests.
								</>
							)
							break
						case 404:
							setCountsLoaded(true)
							break
						default:
							if (error?.response?.data?.error) {
								setErrorMessage(
									error?.response?.data?.link ? (
										<Link
											to={error?.response?.data?.link}
										>
												Error:
											{error?.response?.data?.error}
										</Link>
									) : (
										<>{error?.response?.data?.error}</>
									)
								)
							} else {
								setErrorMessage(<>Error: Unknown error.</>)
									console.error(error) // eslint-disable-line
							}
						}
					})
			})
			.catch((error: any) => {
				switch (error?.response?.status) {
				case 401:
					LogOff('token-expired')
					return
				case 403:
					setErrorMessage(
						<>
								Error: You do not have permission to view
								partners.
						</>
					)
					break
				default:
					if (error?.response?.data?.error) {
						setErrorMessage(
							error?.response?.data?.link ? (
								<Link to={error?.response?.data?.link}>
										Error:
									{error?.response?.data?.error}
								</Link>
							) : (
								<>{error?.response?.data?.error}</>
							)
						)
					} else {
						setErrorMessage(<>Error: Unknown error.</>)
							console.error(error) // eslint-disable-line
					}
				}
			})
	}, [location])

	const PartnerBottomTabItems = [
		{
			icon: DownArrowIcon,
			text: `PARTNER #${id}`,
			to: `/partners/${id}`,
		},
	]

	const PartnerLeftMenuItems: LeftMenuItem[] = [
		{
			icon: PartnerInfoIcon,
			requiredPermissions: ['partner.read'],
			text: 'PARTNER INFO',
			to: `/partners/${id}`,
		},
		{
			icon: AgentAccessIcon,
			requiredPermissions: ['organization.manageaccess'],
			text: 'ORGANIZATION ACCESS',
			to: `/partners/${id}/organization_access`,
		},
	]

	if (!partner?.goal_member) {
		PartnerLeftMenuItems.push({
			icon: AgentAccessIcon,
			requiredPermissions: ['partner.manageaccess'],
			requiredRole: 'administrator',
			text: 'PARTNER ACCESS',
			to: `/partners/${id}/partner_access`,
		})
	}
	PartnerLeftMenuItems.push({
		icon: DocumentsIcon,
		requiredPermissions: ['partner.read'],
		text: 'PARTNER DOCUMENTS',
		to: `/partners/${id}/documents`,
	})
	PartnerLeftMenuItems.push({
		icon: ChainIcon,
		requiredPermissions: ['shipment.read'],
		text: 'LINKED SHIPMENTS',
		to: `/partners/${id}/shipments`,
	})
	PartnerLeftMenuItems.push({
		icon: PartnerUsersIcon,
		requiredPermissions: ['user.read'],
		text: 'PARTNER USERS',
		to: `/partners/${id}/users`,
	})
	PartnerLeftMenuItems.push({
		icon: PartnerHistoryIcon,
		requiredPermissions: ['partner.read'],
		text: 'PARTNER HISTORY',
		to: `/partners/${id}/history`,
	})

	if (HasRole('administrator')) {
		if (
			HasPermission('pay', 'viewbalance') ||
			HasPermission('pay', 'sendmoney') ||
			HasPermission('pay', 'requestmoney')
		) {
			PartnerLeftMenuItems.push(
				{
					disabled: true,
					requiredPermissions: [],
					text: 'ADMINISTRATOR OPTIONS',
					to: '',
				},
				{
					icon: PayBalanceIcon,
					requiredPermissions: ['pay.viewbalance'],
					text: 'BALANCE',
					to: `/partners/${id}/pay/balance`,
				},
				{
					icon: PaySendMoneyIcon,
					requiredPermissions: ['pay.sendmoney'],
					requiredRole: 'administrator',
					text: 'DEPOSIT MONEY',
					to: `/partners/${id}/pay/deposit`,
				},
				{
					icon: PayRequestMoneyIcon,
					requiredPermissions: ['pay.requestmoney'],
					requiredRole: 'administrator',
					text: 'WITHDRAW MONEY',
					to: `/partners/${id}/pay/withdraw`,
				},
				{
					icon: PayRequestMoneyIcon,
					requiredPermissions: ['pay.requestmoney'],
					text: 'REQUEST MONEY',
					to: `/partners/${id}/pay/request`,
				},
				{
					icon: PaySendMoneyIcon,
					requiredPermissions: ['pay.sendmoney'],
					text: 'SEND MONEY',
					to: `/partners/${id}/pay/send`,
				},
				{
					count: payRequestCount,
					icon: PayIncomingRequestsIcon,
					requiredPermissions: ['pay.sendmoney'],
					text: 'INCOMING PAYMENT REQUESTS',
					to: `/partners/${id}/pay/incoming_requests`,
				},
				{
					icon: PayTransactionsIcon,
					requiredPermissions: ['pay.viewbalance'],
					text: 'TRANSACTIONS',
					to: `/partners/${id}/pay/transactions`,
				}
			)
		}
	}

	const PartnerTabMenuItems: TabItem[] = [
		{
			icon: CopyIcon,
			onClick: async (e: MouseEvent<Element>) => {
				e.preventDefault()

				// eslint-disable-next-line
				const confirmation = confirm(`Really copy partner?`)
				if (!confirmation) {
					return
				}

				const form = document.querySelector('form[name=\'partner\']')
				const formData = new FormData(form as HTMLFormElement)
				const parsedFormData = await ParsedFormData(formData, [])
				parsedFormData.copy = true
				axios({
					data: parsedFormData,
					headers: {
						Authorization: `Bearer ${localStorage['token']}`,
						'Content-Type': 'application/json',
					},
					method: 'POST',
					url: `${APIHostname}/api/v1/partners`,
				})
					.then((response: any) => {
						sessionStorage[
							'redirect-message'
						] = `Partner #${response.data.partner_id} created.`
						window.history.replaceState(
							{},
							'',
							`/partners/${response.data.partner_id}`
						)
						document.location.reload()
					})
					.catch((error: any) => {
						switch (error?.response?.status) {
						case 401:
							LogOff('token-expired')
							return
						case 403:
							setErrorMessage(
								<>
										Error: You do not have permission to
										edit partners.
								</>
							)
							break
						default:
							if (error?.response?.data?.error) {
								setErrorMessage(
									error?.response?.data?.link ? (
										<Link
											to={error?.response?.data?.link}
										>
												Error:
											{error?.response?.data?.error}
										</Link>
									) : (
										<>{error?.response?.data?.error}</>
									)
								)
							} else {
								setErrorMessage(<>Error: Unknown error.</>)
									console.error(error) // eslint-disable-line
							}
						}
					})
			},
			requiredPermissions: ['partner.create'],
			requiredRole: 'administrator||memberpartner||partner',
			text: 'COPY',
			to: '#',
		},
	]
	if (partner?.active) {
		PartnerTabMenuItems.push({
			icon: DeleteIcon,
			onClick: async (e: MouseEvent<Element>) => {
				e.preventDefault()

				// eslint-disable-next-line
				const confirmation = confirm(`Really delete partner?`)
				if (!confirmation) {
					return
				}

				axios({
					headers: {
						Authorization: `Bearer ${localStorage['token']}`,
						'Content-Type': 'application/json',
					},
					method: 'DELETE',
					url: `${APIHostname}/api/v1/partners/${id}`,
				})
					.then((response: any) => {
						sessionStorage[
							'redirect-message'
						] = `Partner #${response.data.partner_id} deleted.`
						window.history.replaceState({}, '', '/partners')
						document.location.reload()
					})
					.catch((error: any) => {
						switch (error?.response?.status) {
						case 401:
							LogOff('token-expired')
							return
						case 403:
							setErrorMessage(
								<>
										Error: You do not have permission to
										delete partners.
								</>
							)
							break
						default:
							if (error?.response?.data?.error) {
								setErrorMessage(
									error?.response?.data?.link ? (
										<Link
											to={error?.response?.data?.link}
										>
												Error:
											{error?.response?.data?.error}
										</Link>
									) : (
										<>{error?.response?.data?.error}</>
									)
								)
							} else {
								setErrorMessage(<>Error: Unknown error.</>)
									console.error(error) // eslint-disable-line
							}
						}
					})
			},
			requiredPermissions: ['partner.delete'],
			text: 'DELETE',
			to: '#',
		})
	} else {
		PartnerTabMenuItems.push({
			icon: DeleteIcon,
			onClick: async (e: MouseEvent<Element>) => {
				e.preventDefault()

				// eslint-disable-next-line
				const confirmation = confirm(`Really undelete partner?`)
				if (!confirmation) {
					return
				}

				axios({
					headers: {
						Authorization: `Bearer ${localStorage['token']}`,
						'Content-Type': 'application/json',
					},
					method: 'POST',
					url: `${APIHostname}/api/v1/partners/${id}/undelete`,
				})
					.then((response: any) => {
						sessionStorage[
							'redirect-message'
						] = `Partner #${response.data.partner_id} undeleted.`
						window.history.replaceState({}, '', '/partners')
						document.location.reload()
					})
					.catch((error: any) => {
						switch (error?.response?.status) {
						case 401:
							LogOff('token-expired')
							return
						case 403:
							setErrorMessage(
								<>
										Error: You do not have permission to
										undelete partners.
								</>
							)
							break
						default:
							if (error?.response?.data?.error) {
								setErrorMessage(
									error?.response?.data?.link ? (
										<Link
											to={error?.response?.data?.link}
										>
												Error:
											{error?.response?.data?.error}
										</Link>
									) : (
										<>{error?.response?.data?.error}</>
									)
								)
							} else {
								setErrorMessage(<>Error: Unknown error.</>)
									console.error(error) // eslint-disable-line
							}
						}
					})
			},
			requiredPermissions: ['partner.delete'],
			text: 'UNDELETE',
			to: '#',
		})
	}
	PartnerTabMenuItems.reverse()

	if (!partnerLoaded || !countsLoaded) {
		return (
			<DefaultLayout
				bottomTabItems={PartnerBottomTabItems}
				leftMenuItems={PartnerLeftMenuItems}
				showPrint={true}
				tabMenuItems={PartnerTabMenuItems}
				topTabItems={GlobalTopTabItems}
			>
				<div className={styles.partnerDocumentsContainer}>
					<div className={styles.redirectMessage}>
						{redirectMessage}
					</div>
					<div className={styles.errorMessage}>{errorMessage}</div>
				</div>
			</DefaultLayout>
		)
	}

	sessionStorage.removeItem('redirect-message')

	let readOnly = !HasPermission('partner', 'update') || !partner?.active
	if (readOnly && parseInt(id ?? '0') == ProfilePartnerID()) {
		readOnly = !HasPermission('partner', 'updateself')
	}
	let css = <></>
	if (readOnly) {
		css = <style>{'.partnerBranding { display: none; }'}</style>
	}
	return (
		<DefaultLayout
			bottomTabItems={PartnerBottomTabItems}
			leftMenuItems={PartnerLeftMenuItems}
			showPrint={true}
			tabMenuItems={PartnerTabMenuItems}
			topTabItems={GlobalTopTabItems}
		>
			{css}
			<div className={styles.partnerContainer}>
				<div className={styles.redirectMessage}>{redirectMessage}</div>
				<div className={styles.errorMessage}>
					{errorMessage}
					{!partner?.active ? (
						<>This partner has been deleted.</>
					) : (
						<></>
					)}
				</div>

				<EditForm
					data={partner}
					layout={PartnerEditLayout}
					name="partner"
					onSubmit={async (formData: FormData) => {
						// Update organization
						axios({
							data: await ParsedFormData(formData, []),
							headers: {
								Authorization: `Bearer ${localStorage['token']}`,
								'Content-Type': 'application/json',
							},
							method: 'POST',
							url: `${APIHostname}/api/v1/partners/${id}`,
						})
							.then(() => {
								sessionStorage[
									'redirect-message'
								] = `Partner #${id} updated.`
								window.history.replaceState({}, '', '/partners')
								document.location.reload()
							})
							.catch((error: any) => {
								switch (error?.response?.status) {
								case 401:
									LogOff('token-expired')
									return
								case 403:
										console.error(error) // eslint-disable-line
									setErrorMessage(
										<>
												Error: You do not have
												permission to edit partners.
										</>
									)
									break
								default:
									if (error?.response?.data?.error) {
										setErrorMessage(
											error?.response?.data?.link ? (
												<Link
													to={
														error?.response
															?.data?.link
													}
												>
														Error:
													{
														error?.response
															?.data?.error
													}
												</Link>
											) : (
												<>
													{
														error?.response
															?.data?.error
													}
												</>
											)
										)
									} else {
										setErrorMessage(
											<>Error: Unknown error.</>
										)
											console.error(error) // eslint-disable-line
									}
								}
							})
					}}
					previewAPIData={async (formData: FormData) => {
						const payload = await ParsedFormData(formData, [])

						return {
							data: JSON.stringify(payload, null, 4),
							method: 'POST',
							url: `${APIHostname}/api/v1/partners/${id}`,
						}
					}}
					readOnly={readOnly}
				/>
			</div>
		</DefaultLayout>
	)
}
