import axios from 'axios'
import { useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'

import ACIIcon from '../../../../../assets/icons/aci.svg'
import AMSIcon from '../../../../../assets/icons/ams.svg'
import ArrivalIcon from '../../../../../assets/icons/arrival-notice.svg'
import DownArrowIcon from '../../../../../assets/icons/arrow-down.svg'
import BookingIcon from '../../../../../assets/icons/booking.svg'
import DocumentsIcon from '../../../../../assets/icons/documents.svg'
import ShipmentHistoryIcon from '../../../../../assets/icons/history.svg'
import ISFIcon from '../../../../../assets/icons/isf.svg'
import NRAIcon from '../../../../../assets/icons/open-nra.svg'
import RemarksIcon from '../../../../../assets/icons/remarks.svg'
import ShipmentTrackingIcon from '../../../../../assets/icons/tracking.svg'
import { NavTable } from '../../../../../components/NavTable'
import { ShipmentSummary } from '../../../../../components/ShipmentSummary'
import { GlobalTopTabItems } from '../../../../../components/Tabs'
import { DefaultLayout } from '../../../../../layouts/DefaultLayout'
import { APIHostname, LogOff } from '../../../../../Profile'
import Control from '../../../../../types/Control'
import { EscapeURIParameters } from '../../../../../Utils'
import styles from './ShipmentHistory.module.scss'

export const ShipmentHistory = () => {
	const { id } = useParams()
	const [history, setHistory]: any = useState(null)
	const [shipment, setShipment]: any = useState(null)
	const [shipmentLoaded, setShipmentLoaded] = useState(false)
	const [historyLoaded, setHistoryLoaded] = useState(false)
	const [historyCount, setHistoryCount] = useState(0)
	const [errorMessage, setErrorMessage] = useState(<></>)
	const initialRedirectMessage = sessionStorage['redirect-message']
	const [redirectMessage] = useState(initialRedirectMessage)

	// Vary based on query string.
	const location = useLocation()
	const urlParams = new URLSearchParams(location.search)
	if (!urlParams.get('limit')) {
		urlParams.set('limit', '25')
	}

	useEffect(() => {
		axios({
			data: {},
			headers: {
				Authorization: `Bearer ${localStorage['token']}`,
			},
			method: 'GET',
			url: `${APIHostname}/api/v1/shipments/${id}`,
		})
			.then((response: any) => {
				setErrorMessage(<></>)
				setShipment(response.data)
				setShipmentLoaded(true)
			})
			.catch((error: any) => {
				switch (error?.response?.status) {
				case 401:
					LogOff('token-expired')
					return
				case 403:
					setErrorMessage(
						<>
								Error: You do not have permission to view
								shipments.
						</>
					)
					break
				default:
					if (error?.response?.data?.error) {
						setErrorMessage(
							error?.response?.data?.link ? (
								<Link to={error?.response?.data?.link}>
										Error:
									{error?.response?.data?.error}
								</Link>
							) : (
								<>{error?.response?.data?.error}</>
							)
						)
					} else {
						setErrorMessage(<>Error: Unknown error.</>)
							console.error(error) // eslint-disable-line
					}
				}
			})

		axios({
			data: {},
			headers: {
				Authorization: `Bearer ${localStorage['token']}`,
			},
			method: 'GET',
			url: `${APIHostname}/api/v1/shipments/${id}/history?${EscapeURIParameters(
				urlParams.toString()
			)}`,
		})
			.then((response: any) => {
				setErrorMessage(<></>)
				setHistory(response.data.historyRecords)
				setHistoryCount(response.data.historyRecord_count)
				setHistoryLoaded(true)
			})
			.catch((error: any) => {
				switch (error?.response?.status) {
				case 401:
					LogOff('token-expired')
					return
				case 403:
					setErrorMessage(
						<>
								Error: You do not have permission to view
								shipments.
						</>
					)
					break
				default:
					if (error?.response?.data?.error) {
						setErrorMessage(
							error?.response?.data?.link ? (
								<Link to={error?.response?.data?.link}>
										Error:
									{error?.response?.data?.error}
								</Link>
							) : (
								<>{error?.response?.data?.error}</>
							)
						)
					} else {
						setErrorMessage(<>Error: Unknown error.</>)
							console.error(error) // eslint-disable-line
					}
				}
			})
	}, [location])

	const arrivalNoticeTemplate = shipment?.arrival_notice_template
	const usDestination =
		shipment?.discharge_port?.toLowerCase().startsWith('us') ||
		shipment?.final_port_of_entry?.toLowerCase().startsWith('us')
	const caDestination =
		shipment?.discharge_port?.toLowerCase().startsWith('ca') ||
		shipment?.final_port_of_entry?.toLowerCase().startsWith('ca')
	const ShipmentLeftMenuItems = [
		{
			icon: BookingIcon,
			text: 'BOOKING',
			to: `/shipments/${id}`,
		},
		{
			count: shipment?.unread_remarks ?? null,
			icon: RemarksIcon,
			text: 'REMARKS',
			to: `/shipments/${id}/remarks`,
		},
		{
			disabled: !usDestination && !shipment?.ams_status,
			disabledReason: 'Shipment does not have US destination ports.',
			icon: AMSIcon,
			requiredRole: 'administrator||memberpartner||partner',
			text: 'AMS',
			to: `/shipments/${id}/ams`,
		},
		{
			disabled: !caDestination && !shipment?.aci_status,
			disabledReason: 'Shipment does not have CA destination ports.',
			icon: ACIIcon,
			requiredRole: 'administrator||memberpartner||partner',
			text: 'ACI',
			to: `/shipments/${id}/aci`,
		},
		{
			disabled: !usDestination,
			disabledReason: 'Shipment does not have US destination ports.',
			icon: ISFIcon,
			requiredRole: 'administrator||memberpartner||partner',
			text: 'ISF',
			to: `/shipments/${id}/isf`,
		},
		{
			icon: DocumentsIcon,
			text: 'DOCUMENTS',
			to: `/shipments/${id}/documents`,
		},
		{
			icon: DocumentsIcon,
			requiredRole: 'administrator||memberpartner||partner',
			text: 'HBL PRINT',
			to: `/shipments/${id}/hbl`,
		},
		{
			disabled: !usDestination,
			disabledReason: 'Shipment does not have US destination ports.',
			icon: NRAIcon,
			requiredRole: 'administrator||memberpartner||partner',
			text: 'NRA',
			to: `/shipments/${id}/nra`,
		},
		{
			disabled: !arrivalNoticeTemplate,
			disabledReason:
				'Destination partner does not have an Arrival Notice template.',
			icon: ArrivalIcon,
			requiredRole: 'administrator||memberpartner||partner',
			text: 'ARRIVAL NOTICE',
			to: `/shipments/${id}/arrival_notice${
				arrivalNoticeTemplate == 'default'
					? ''
					: `/${arrivalNoticeTemplate}`
			}`,
		},
		{
			icon: ShipmentTrackingIcon,
			requiredRole: 'administrator||memberpartner||partner||organization',
			text: 'SHIPMENT TRACKING',
			to: `/shipments/${id}/tracking`,
		},
		{
			icon: ShipmentHistoryIcon,
			text: 'SHIPMENT HISTORY',
			to: `/shipments/${id}/history`,
		},
	]

	const ShipmentHistoryBottomTabItems = [
		{
			icon: DownArrowIcon,
			text: `SHIPMENT #${id} HISTORY`,
			to: `/shipments/${id}/history`,
		},
	]

	if (!shipmentLoaded || !historyLoaded) {
		if (errorMessage) {
			return (
				<DefaultLayout
					bottomTabItems={ShipmentHistoryBottomTabItems}
					leftMenuItems={ShipmentLeftMenuItems}
					showPrint={true}
					tabMenuItems={ShipmentTabMenuItems}
					topTabItems={GlobalTopTabItems}
				>
					<div className={styles.shipmentHistoryContainer}>
						<div className={styles.redirectMessage}>
							{redirectMessage}
						</div>
						<div className={styles.errorMessage}>
							{errorMessage}
						</div>
					</div>
				</DefaultLayout>
			)
		}

		return <div className="loading">Loading...</div>
	}

	sessionStorage.removeItem('redirect-message')
	return (
		<DefaultLayout
			bottomTabItems={ShipmentHistoryBottomTabItems}
			leftMenuItems={ShipmentLeftMenuItems}
			showPrint={true}
			tabMenuItems={ShipmentTabMenuItems}
			topTabItems={GlobalTopTabItems}
		>
			<div className={styles.shipmentHistoryContainer}>
				<ShipmentSummary shipment={shipment} />

				<div className={styles.redirectMessage}>{redirectMessage}</div>
				<div className={styles.errorMessage}>
					{errorMessage}
					{errorMessage}{' '}
					{!shipment?.active ? (
						<>This shipment has been deleted.</>
					) : (
						<></>
					)}
				</div>

				<div className={styles.navTable}>
					<NavTable
						columns={[
							{
								control: Control.Hyperlink,
								id: 'link',
								title: 'Link',
							},
							{
								id: 'version',
								title: 'Version',
							},
							{
								id: 'action',
								title: 'Action',
							},
							{
								id: 'element',
								title: 'Element',
							},
							{
								id: 'original',
								title: 'Original Value',
							},
							{
								id: 'new',
								title: 'New Value',
							},
							{
								control: Control.LogFile,
								id: 'filename',
								title: 'FILENAME',
							},
							{
								control: Control.User,
								id: 'created_by',
								title: 'User',
							},
							{
								control: Control.DateTime,
								id: 'created',
								title: 'Date/Time',
							},
						]}
						count={historyCount}
						data={history}
						filters={JSON.parse(urlParams.get('filters') ?? '[]')}
						includeDelete={false}
						includeExport={false}
						primaryDisplayColumn=""
						primaryKey=""
					/>
				</div>
			</div>
		</DefaultLayout>
	)
}

export const ShipmentTabMenuItems = []
