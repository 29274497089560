const enum Control {
	ACIBillOfLadingType,
	Active,
	AmendmentType,
	ApprovePay,
	BillOfLadingType,
	Boolean,
	Calculation,
	Carrier,
	CarrierType,
	Charges,
	Checkbox,
	City,
	ConfirmedRejected,
	Containers,
	ContainerType,
	Country,
	Currency,
	Organization,
	OrganizationTextbox,
	DataCorrection,
	Date,
	DateTime,
	DocumentType,
	Download,
	Email,
	Entity,
	FCL,
	File,
	FileName,
	FileSize,
	FreightRates,
	HazardousCargoInfo,
	HotListed,
	HTML,
	Hyperlink,
	ImageFile,
	ISFTransmissionType,
	Locked,
	LogFile,
	ManufacturerLineItems,
	NameComponent,
	NonNullDate,
	NRA,
	NRARates,
	NRAReference,
	NRAsToGOAL,
	Number,
	OrganizationType,
	Partner,
	Password,
	Permissions,
	Phone,
	Port,
	Pricing,
	Read,
	ReadStatus,
	RejectPay,
	Role,
	ShipmentNRAs,
	ShipmentStatus,
	ShipmentType,
	Static,
	Status,
	TextBox,
	TimeZone,
	ToggleContactInfo,
	Unit,
	User,
}

export default Control
