import Control from '../Control'
import { SearchOperator } from '../Field'
import { Layout } from '../Layout'

export type Shipment = {
	booking_number: string
	consignee: number
	consignee_name: string
	data_correction: boolean
	days_since_oldest_remark: number
	days_until_last_port: number
	destination_partner_reference: string
	etd: string
	hbl: string
	hbl_carrier_scac: string
	hotlist: boolean
	id: number
	lock: boolean
	mbl: string
	mbl_carrier_scac: string
	origin_partner_reference: string
}

export interface ExtendedShipment extends Shipment {
	class: string
}

export const ShipmentEditLayout: Layout = {
	Groups: [
		{
			Direction: 'column',
			Flex: '1 1',
			Groups: [
				{
					Direction: 'row',
					Flex: '1 1',
					Groups: [
						{
							Border: true,
							Name: 'shipper',
							RequiredRows: [
								{
									Fields: [
										{
											Control: Control.Organization,
											HardRequired: true,
											Label: 'SHIPPER',
											Property: 'shipper',
											SubType: 'shipper',
											Width: 100,
										},
									],
								},
							],

							Width: 50,
						},
						{
							Border: true,
							RequiredRows: [
								{
									Fields: [
										{
											Control: Control.Organization,
											HardRequired: true,
											Label: 'CONSIGNEE',
											Property: 'consignee',
											SubType: 'consignee',
											Width: 100,
										},
									],
								},
							],

							Width: 50,
						},
					],
					Width: 100,
				},
				{
					Border: true,
					Flex: '1 1',
					Margin: '1rem 0',
					RequiredRows: [
						{
							Fields: [
								{
									Control: Control.Organization,
									Label: 'NOTIFY PARTY',
									Property: 'notify',
									Width: 100,
								},
							],
						},
					],
					Width: 100,
				},
				{
					Groups: [
						{
							Border: true,
							Direction: 'column',
							RequiredRows: [
								{
									Fields: [
										{
											Control: Control.Partner,
											HardRequired: true,
											Label: 'ORIGIN PARTNER',
											Property: 'origin_partner',
											SearchOperator:
												SearchOperator.Equals,
											Width: 100,
										},
									],
								},
								{
									Fields: [
										{
											Label: 'ORIGIN PARTNER REFERENCE #',
											Property:
												'origin_partner_reference',
											SoftRequired: true,
											Width: 100,
										},
									],
								},
							],
							Width: 100,
						},
						{
							Border: true,
							Direction: 'column',
							RequiredRows: [
								{
									Fields: [
										{
											Control: Control.Partner,
											HardRequired: true,
											Label: 'DESTINATION PARTNER',
											Property: 'destination_partner',
											SearchOperator:
												SearchOperator.Equals,
											Width: 100,
										},
									],
								},
								{
									Fields: [
										{
											Label: 'DESTINATION PARTNER REFERENCE #',
											Property:
												'destination_partner_reference',
											SoftRequired: true,
											Width: 100,
										},
									],
								},
							],
							Width: 100,
						},
					],

					Width: 100,
				},
			],
			Margin: '1rem 0 0 0 ',
			Width: 50,
		},
		{
			Border: true,
			Flex: '1 1',
			Margin: '1rem 0 0 0 ',
			RequiredRows: [
				{
					Fields: [
						{
							Default: '[AUTO-GENERATED UPON SAVE]',
							HardRequired: true,
							Label: 'BOOKING NUMBER',
							Property: 'booking_number',
							ReadOnly: true,
							Width: 50,
						},
						{
							Control: Control.ShipmentStatus,
							HardRequired: true,
							Label: 'STATUS',
							Property: 'status',
							Width: 50,
						},
					],
				},
				{
					Fields: [
						{
							Control: Control.Carrier,
							Label: 'HBL SCAC',
							Property: 'hbl_carrier',
							SoftRequired: true,
							SubType: 'hbl',
							Width: 50,
						},
						{
							Label: 'HBL #',
							MaxLength: 12,
							MinLength: 7,
							Property: 'hbl',
							SoftRequired: true,
							Width: 50,
						},
					],
				},
				{
					Fields: [
						{
							Control: Control.Carrier,
							Label: 'MBL SCAC',
							Property: 'mbl_carrier',
							SoftRequired: true,
							SubType: 'mbl',
							Width: 50,
						},
						{
							Label: 'MBL #',
							MaxLength: 12,
							MinLength: 7,
							Property: 'mbl',
							SoftRequired: true,
							Width: 50,
						},
					],
				},
				{
					Fields: [
						{
							Control: Control.FCL,
							Label: 'LOADING TYPE',
							Property: 'fcl',
							SoftRequired: true,
							Width: 50,
						},
						{
							Control: Control.BillOfLadingType,
							Label: 'BILL OF LADING TYPE',
							Property: 'bol_type',
							SoftRequired: true,
							Width: 50,
						},
					],
				},
				{
					Fields: [
						{
							Label: 'VESSEL',
							MaxLength: 23,
							MinLength: 1,
							Property: 'vessel',
							SoftRequired: true,
							Width: 50,
						},
						{
							Control: Control.Country,
							Label: 'VESSEL FLAG',
							Nullable: true,
							Property: 'vessel_country',
							SoftRequired: true,
							Width: 50,
						},
					],
				},
				{
					Fields: [
						{
							Label: 'VOYAGE',
							MaxLength: 5,
							MinLength: 1,
							Property: 'voyage',
							SoftRequired: true,
							Width: 50,
						},
						{
							Label: 'REGULATORY HBL',
							Property: 'regulatory_hbl',
							Width: 50,
						},
					],
				},
				{
					Fields: [
						{
							Control: Control.Calculation,
							Label: 'TOTAL CBM',
							Property: 'total_cbm',
							Width: 50,
						},
						{
							Control: Control.Calculation,
							Label: 'TOTAL PACKAGES',
							Property: 'total_packages',
							Width: 50,
						},
					],
				},
				{
					Fields: [
						{
							Control: Control.Calculation,
							Label: 'TOTAL GROSS WEIGHT (KG)',
							Property: 'total_gross_weight',
							Width: 50,
						},
						{
							Label: '',
							Property: '',
							Width: 50,
						},
					],
				},
				{
					Fields: [
						{
							Control: Control.Checkbox,
							Label: 'CONFIRMED BY DESTINATION PARTNER',
							Property: 'confirmed',
							Width: 50,
						},
						{
							Control: Control.Checkbox,
							Label: 'REJECTED BY DESTINATION PARTNER',
							Property: 'rejected',
							Width: 50,
						},
					],
				},
				{
					Fields: [
						{
							Control: Control.Checkbox,
							Label: 'SEND BOOKING',
							Property: 'send',
							Width: 50,
						},
						{
							Control: Control.Checkbox,
							Label: 'HOT LIST',
							Property: 'hotlist',
							Width: 50,
						},
					],
				},
				{
					Fields: [
						{
							Control: Control.Checkbox,
							Label: 'LOCKED',
							Property: 'lock',
							RequiredRole: 'administrator',
							Width: 100,
						},
					],
				},
				{
					Fields: [
						{
							Control: Control.Date,
							Label: 'CREATION DATE',
							Property: 'created',
							ReadOnly: true,
							Width: 33,
						},
						{
							Control: Control.Date,
							Label: 'MODIFIED DATE',
							Property: 'modified',
							ReadOnly: true,
							Width: 33,
						},
						{
							Control: Control.Number,
							Label: 'VERSION',
							Property: 'version',
							ReadOnly: true,
							Width: 33,
						},
					],
				},
			],

			Width: 100,
		},
		{
			Border: true,
			RequiredRows: [
				{
					Fields: [
						{
							Control: Control.Date,
							Label: 'BOOKING DATE',
							Property: 'booking_date',
							SoftRequired: true,
							Width: 25,
						},
						{
							Label: '',
							Property: '',
							Width: 25,
						},
						{
							Label: '',
							Property: '',
							Width: 25,
						},
						{
							Label: '',
							Property: '',
							Width: 25,
						},
					],
				},
				{
					Fields: [
						{
							Control: Control.Date,
							Label: 'ISSUE DATE',
							Property: 'issue_date',
							SoftRequired: true,
							Width: 25,
						},
						{
							Control: Control.Port,
							Label: 'PLACE OF ISSUE',
							Property: 'place_of_issue',
							SoftRequired: true,
							Width: 25,
						},
						{
							Label: '',
							Property: '',
							Width: 25,
						},
						{
							Label: '',
							Property: '',
							Width: 25,
						},
					],
				},
				{
					Fields: [
						{
							Control: Control.Date,
							Label: 'READY DATE',
							Property: 'ready_date',
							SoftRequired: true,
							Width: 25,
						},
						{
							Control: Control.Port,
							Label: 'PLACE OF RECEIPT',
							Property: 'place_of_receipt',
							SoftRequired: true,
							Width: 25,
						},
						{
							Label: '',
							Property: '',
							Width: 25,
						},
						{
							Label: '',
							Property: '',
							Width: 25,
						},
					],
				},
				{
					Fields: [
						{
							Control: Control.Date,
							Label: 'PRE-LOADING ETA',
							Property: 'preloading_port_eta',
							Width: 25,
						},
						{
							Control: Control.Port,
							Label: 'PRE-LOADING PORT',
							Property: 'preloading_port',
							Width: 25,
						},
						{
							Control: Control.Date,
							Label: 'PRE-LOADING ETD',
							Property: 'preloading_port_etd',
							Width: 25,
						},
						{
							Label: 'PRE-CARRIAGE VESSEL',
							Property: 'precarriage_vessel',
							SoftRequired: true,
							Width: 25,
						},
					],
				},
				{
					Fields: [
						{
							Label: '',
							Property: '',
							Width: 25,
						},
						{
							Control: Control.Port,
							Label: 'LOADING PORT',
							Property: 'loading_port',
							SoftRequired: true,
							Width: 25,
						},
						{
							Control: Control.Date,
							Label: 'LOADING PORT ETD',
							Property: 'loading_port_etd',
							Width: 25,
						},
						{
							Label: '',
							Property: '',
							Width: 25,
						},
					],
				},
				{
					Fields: [
						{
							Label: '',
							Property: '',
							Width: 25,
						},
						{
							Control: Control.Port,
							Label: 'LAST FOREIGN PORT',
							Property: 'last_foreign_port',
							SoftRequired: true,
							Width: 25,
						},
						{
							Control: Control.Date,
							Label: 'LAST FOREIGN PORT ETD',
							Property: 'last_foreign_port_etd',
							SoftRequired: true,
							Width: 25,
						},
						{
							Label: '',
							Property: '',
							Width: 25,
						},
					],
				},
				{
					Fields: [
						{
							Control: Control.Date,
							Label: 'DISCHARGE PORT ETA',
							Property: 'discharge_port_eta',
							SoftRequired: true,
							Width: 25,
						},
						{
							Control: Control.Port,
							Label: 'DISCHARGE PORT (REQUIRED BY AMS)',
							Property: 'discharge_port',
							SoftRequired: true,
							Width: 25,
						},
						{
							Label: '',
							Property: '',
							Width: 25,
						},
						{
							Label: '',
							Property: '',
							Width: 25,
						},
					],
				},
				{
					Fields: [
						{
							Control: Control.Date,
							Label: 'FINAL PORT OF ENTRY ETA',
							Property: 'final_port_of_entry_eta',
							SoftRequired: true,
							Width: 25,
						},
						{
							Control: Control.Port,
							Label: 'FINAL PORT OF ENTRY',
							Property: 'final_port_of_entry',
							SoftRequired: true,
							Width: 25,
						},
						{ Label: '', Property: '', Width: 25 },
						{ Label: '', Property: '', Width: 25 },
					],
				},
				{
					Fields: [
						{
							Control: Control.Date,
							Label: 'PLACE OF DELIVERY ETA',
							Property: 'place_of_delivery_eta',
							SoftRequired: true,
							Width: 25,
						},
						{
							Control: Control.Port,
							Label: 'PLACE OF DELIVERY',
							Property: 'place_of_delivery',
							SoftRequired: true,
							Width: 25,
						},
						{ Label: '', Property: '', Width: 25 },
						{ Label: '', Property: '', Width: 25 },
					],
				},
			],

			Width: 100,
		},
		{
			Label: 'CONTAINERS',
			LabelBackground: 'blue',
			Padding: '0',
			RequiredRows: [
				{
					Fields: [
						{
							Control: Control.Containers,
							Property: 'containers',
							Width: 100,
						},
					],
				},
			],

			Width: 100,
		},
		{
			Label: 'PRICING',
			LabelBackground: 'blue',
			Padding: '0',
			RequiredRole: 'administrator||memberpartner||partner',
			RequiredRows: [
				{
					Fields: [
						{
							Control: Control.Pricing,
							Property: 'pricings',
							Width: 100,
						},
					],
				},
			],

			Width: 100,
		},
		{
			Label: 'HAZARDOUS CARGO INFO',
			LabelBackground: 'orange',
			Padding: '0',
			RequiredRows: [
				{
					Fields: [
						{
							Control: Control.HazardousCargoInfo,
							Property: 'hazardous_cargoes',
							Width: 100,
						},
					],
				},
			],

			Width: 100,
		},
		{
			Label: 'NRA TO GOAL UNDER SERVICE CONTRACT',
			LabelBackground: 'blue',
			Padding: '0',
			RequiredRole: 'administrator||memberpartner||partner',
			RequiredRows: [
				{
					Fields: [
						{
							Control: Control.NRAsToGOAL,
							Property: 'nras_to_goal',
							Width: 100,
						},
					],
				},
			],

			Width: 100,
		},
	],
}

export const ShipmentSearchLayout: Layout = {
	Groups: [
		{
			Border: true,
			Margin: '1rem 0 0 0',
			Name: 'Shipper',
			OptionalRows: [
				{
					Fields: [
						{
							Control: Control.Organization,
							Label: 'CONSIGNEE',
							Property: 'consignee_name',
							Width: 25,
						},
						{
							Control: Control.Organization,
							Label: 'SHIPPER',
							Property: 'shipper_name',
							Width: 25,
						},
						{
							Label: 'BOOKING NO',
							Property: 'booking_number',
							Width: 25,
						},
						{
							Control: Control.Containers,
							Label: 'CONTAINER',
							Property: 'container',
							Width: 25,
						},
					],
				},
				{
					Fields: [
						{
							Control: Control.Partner,
							Label: 'ORIGIN PARTNER',
							Property: 'origin_partner_name',
							Width: 25,
						},
						{
							Control: Control.Partner,
							Label: 'DESTINATION PARTNER',
							Property: 'destination_partner_name',
							Width: 25,
						},
						{
							Label: 'MBL#',
							Property: 'mbl',
							Width: 25,
						},
						{
							Label: 'HBL#',
							Property: 'hbl',
							Width: 25,
						},
					],
				},
				{
					Fields: [
						{
							Control: Control.ShipmentStatus,
							Label: 'STATUS',
							Property: 'status',
							Width: 25,
						},
						{
							Control: Control.Boolean,
							Label: 'CONFIRMED',
							Property: 'confirmed',
							Width: 25,
						},
						{
							Control: Control.Boolean,
							Label: 'REJECTED',
							Property: 'rejected',
							Width: 25,
						},
						{
							HardRequired: true,
							Label: 'PO #',
							Property: 'po_number',
							Width: 25,
						},
					],
				},
				{
					Fields: [
						{
							Control: Control.Date,
							Label: 'LOADING PORT ETD',
							Property: 'loading_port_etd-gte',
							SearchOperator: SearchOperator.GreaterThanOrEqual,
							Width: 22.5,
						},
						{
							Control: Control.Static,
							Property: 'TO',
							Width: 5,
						},
						{
							Control: Control.Date,
							Property: 'loading_port_etd-lte',
							SearchOperator: SearchOperator.LessThanOrEqual,
							Width: 22.5,
						},
						{
							Control: Control.Date,
							Label: 'DISCHARGE PORT ETA',
							Property: 'discharge_port_eta-gte',
							Width: 22.5,
						},
						{
							Control: Control.Static,
							Property: 'TO',
							Width: 5,
						},
						{
							Control: Control.Date,
							Property: 'discharge_port_eta-lte',
							Width: 22.5,
						},
					],
				},
				{
					Fields: [
						{
							Control: Control.Boolean,
							Label: 'HOT LISTED',
							Property: 'hotlist',
							Width: 25,
						},
						{
							Control: Control.Boolean,
							Label: 'LATE ACI',
							Property: 'late_aci',
							Width: 25,
						},
						{
							Control: Control.Boolean,
							Label: 'LATE AMS',
							Property: 'late_ams',
							Width: 25,
						},
						{
							Control: Control.Boolean,
							Label: 'UNCONFIRMED',
							Property: 'unconfirmed',
							Width: 25,
						},
					],
					IncludeInEdit: false,
				},
				{
					Fields: [
						{
							Control: Control.Boolean,
							Label: 'NEW DOCUMENTS',
							Property: 'new_documents',
							Width: 25,
						},
						{
							Control: Control.Boolean,
							Label: 'NEW REMARKS',
							Property: 'new_remarks',
							Width: 25,
						},
						{
							Control: Control.Boolean,
							Label: 'DATA CORRECTION',
							Property: 'data_correction',
							Width: 25,
						},
						{
							Control: Control.Boolean,
							Label: 'MISSING NRA',
							Property: 'missing_nra',
							Width: 25,
						},
					],
				},
				{
					Fields: [
						{
							Control: Control.Active,
							IncludeInEdit: false,
							Label: 'INCLUDE',
							Property: 'active',
							RequiredRole: 'administrator',
							Width: 25,
						},
						{
							Label: '',
							Property: '',
							Width: 25,
						},
						{
							Label: '',
							Property: '',
							Width: 25,
						},
						{
							Label: '',
							Property: '',
							Width: 25,
						},
					],
				},
			],
			RequiredRows: [
				{
					Fields: [
						{
							IncludeInEdit: false,
							IncludeInSearch: true,
							Label: 'WILDCARD (SEARCH ALL FIELDS; USE "OR" FOR MULTIPLE CRITERIA)',
							Property: '*',
							Width: 100,
						},
					],
				},
			],
			Width: 100,
		},
	],
}
