import { FocusEvent } from 'react'
import { Link } from 'react-router-dom'

import Control from '../../types/Control'
import { Field } from '../../types/Field'
import { EditACIBillOfLadingType } from './Controls/ACIBillOfLadingType'
import { EditActive } from './Controls/Active'
import { EditAmendmentType } from './Controls/AmendmentType'
import { EditBillOfLadingType } from './Controls/BillOfLadingType'
import { EditBoolean } from './Controls/Boolean'
import { EditCalculation } from './Controls/Calculation'
import { EditCarrier } from './Controls/Carrier'
import { EditCarrierType } from './Controls/CarrierType'
import { EditCharges } from './Controls/Charges'
import { EditContainers } from './Controls/Containers'
import { EditContainerType } from './Controls/ContainerType'
import { EditCountry } from './Controls/Country'
import { EditDocumentType } from './Controls/DocumentType'
import { EditFCL } from './Controls/FCL'
import { EditFreightRates } from './Controls/FreightRates'
import { EditHazardousCargo } from './Controls/HazardousCargo'
import { EditHotListed } from './Controls/HotListed'
import { EditISFTransmissionType } from './Controls/ISFTransmissionType'
import { EditManufacturerLineItems } from './Controls/ManufacturerLineItems'
import { EditNRA } from './Controls/NRA'
import { EditNRARates } from './Controls/NRARates'
import { EditNRAsToGOAL } from './Controls/NRAsToGOAL'
import { EditOrganization } from './Controls/Organization'
import { EditOrganizationTextbox } from './Controls/OrganizationTextbox'
import { EditOrganizationType } from './Controls/OrganizationType'
import { EditPartner } from './Controls/Partner'
import { EditPermissions } from './Controls/Permissions'
import { EditPort } from './Controls/Port'
import { EditPricing } from './Controls/Pricing'
import { EditRole } from './Controls/Role'
import { EditShipmentNRAs } from './Controls/ShipmentNRAs'
import { EditShipmentStatus } from './Controls/ShipmentStatus'
import { EditShipmentType } from './Controls/ShipmentType'
import { EditTimeZone } from './Controls/TimeZone'
import { EditUnit } from './Controls/Unit'
import { EditUser } from './Controls/User'
import { DateOnly, DateTime } from './EditForm'
import styles from './Field.module.scss'

export const DisplayField = (props: FieldProps) => {
	const displayControl = (control?: Control) => {
		switch (control) {
		default:
			return (
				<>
					<div className={styles.label}>{props.field.Label}</div>
					<span>{props.value}</span>
				</>
			)
		}
	}

	return (
		<div
			className={styles.fieldOuterContainer}
			id={props.field.Property ? `${props.field.Property}_container` : ''}
			style={{
				display: props.hidden ? 'none' : 'unset',
				width: `${props.field.Width?.toString()}%`,
			}}
		>
			{props.field.Property ? displayControl(props.field.Control) : <></>}
		</div>
	)
}

export const EditField = (props: FieldProps) => {
	if (props.field.Label && !props.field.Label?.endsWith(':')) {
		props.field.Label += ':'
	}
	const editControl = (control?: Control) => {
		switch (control) {
		case Control.ACIBillOfLadingType:
			return (
				<EditACIBillOfLadingType
					field={props.field}
					isSearch={props.isSearch}
					onChange={props.onChange}
					readOnly={
						(props.readOnly || props.field.ReadOnly) &&
							!props.isSearch
					}
					value={props.value}
				/>
			)
		case Control.Active:
			return (
				<>
					<div className={styles.label}>
						{props.field.Label ?? <span>{'\u00A0'}</span>}
						{props.field.HardRequired && !props.isSearch ? (
							<>
								{'\u00A0'}
								<span
									className="hardRequired"
									title="REQUIRED IN ORDER TO SAVE"
								>
										*
								</span>
							</>
						) : (
							<></>
						)}
						{props.field.SoftRequired && !props.isSearch ? (
							<>
								{'\u00A0'}
								<span
									className="softRequired"
									title="REQUIRED"
								>
										*
								</span>
							</>
						) : (
							<></>
						)}
					</div>
					<EditActive
						field={props.field}
						isSearch={props.isSearch}
						onChange={props.onChange}
						readOnly={
							(props.readOnly || props.field.ReadOnly) &&
								!props.isSearch
						}
						value={props.value}
					/>
				</>
			)
		case Control.AmendmentType:
			return (
				<EditAmendmentType
					field={props.field}
					isSearch={props.isSearch}
					onChange={props.onChange}
					readOnly={
						(props.readOnly || props.field.ReadOnly) &&
							!props.isSearch
					}
					value={props.value}
				/>
			)
		case Control.BillOfLadingType:
			return (
				<EditBillOfLadingType
					field={props.field}
					isSearch={props.isSearch}
					onChange={props.onChange}
					readOnly={
						(props.readOnly || props.field.ReadOnly) &&
							!props.isSearch
					}
					value={props.value}
				/>
			)
		case Control.Boolean:
			return (
				<EditBoolean
					checkbox={true}
					field={props.field}
					isSearch={props.isSearch}
					onChange={props.onChange}
					readOnly={
						(props.readOnly || props.field.ReadOnly) &&
							!props.isSearch
					}
					value={props.value}
				/>
			)
		case Control.Calculation:
			return (
				<EditCalculation
					data={props.data}
					field={props.field}
					isSearch={props.isSearch}
					onChange={props.onChange}
					readOnly={
						(props.readOnly || props.field.ReadOnly) &&
							!props.isSearch
					}
					value={props.value}
				/>
			)
		case Control.Carrier:
			return (
				<EditCarrier
					data={props.data}
					field={props.field}
					isSearch={props.isSearch}
					onChange={props.onChange}
					readOnly={
						(props.readOnly || props.field.ReadOnly) &&
							!props.isSearch
					}
					value={props.value}
				/>
			)
		case Control.CarrierType:
			return (
				<EditCarrierType
					data={props.data}
					field={props.field}
					isSearch={props.isSearch}
					onChange={props.onChange}
					readOnly={
						(props.readOnly || props.field.ReadOnly) &&
							!props.isSearch
					}
					value={props.value}
				/>
			)
		case Control.Charges:
			if (props.isSearch) {
				return (
					<>
						{' '}
						<div className={styles.label}>CHARGES:</div>
						<input
							autoFocus={props.field.AutoFocus}
							className={`${
								props.field.HardRequired && !props.isSearch
									? 'hardRequired'
									: ''
							} ${
								props.field.SoftRequired && !props.isSearch
									? 'softRequired'
									: ''
							}`}
							data-hardrequired={
								props.field.HardRequired && !props.isSearch
							}
							data-nullable="true"
							data-softrequired={
								props.field.SoftRequired && !props.isSearch
							}
							defaultValue={props.value ?? ''}
							id={props.field.Property}
							name={props.field.Property}
							onChange={props.onChange}
							placeholder=" "
							readOnly={
								(props.readOnly || props.field.ReadOnly) &&
									!props.isSearch
							}
							required={
								props.field.HardRequired && !props.isSearch
							}
							title={props.field.Label?.replace(':', '')}
							type="text"
						/>
					</>
				)
			}

			return (
				<EditCharges
					data={props.data}
					field={props.field}
					isSearch={props.isSearch}
					onChange={props.onChange}
					readOnly={
						(props.readOnly || props.field.ReadOnly) &&
							!props.isSearch
					}
					value={props.value}
				/>
			)
		case Control.Checkbox:
			// props.field.Label = props.field.Label?.replaceAll(':', '')
			return (
				<EditBoolean
					checkbox={true}
					field={props.field}
					isSearch={props.isSearch}
					onChange={props.onChange}
					readOnly={
						(props.readOnly || props.field.ReadOnly) &&
							!props.isSearch
					}
					value={props.value}
				/>
			)
		case Control.Containers:
			if (props.isSearch) {
				return (
					<>
						{' '}
						<div className={styles.label}>
								CONTAINER NUMBER:
						</div>
						<input
							autoFocus={props.field.AutoFocus}
							className={`${
								props.field.HardRequired && !props.isSearch
									? 'hardRequired'
									: ''
							} ${
								props.field.SoftRequired && !props.isSearch
									? 'softRequired'
									: ''
							}`}
							data-hardrequired={
								props.field.HardRequired && !props.isSearch
							}
							data-nullable="true"
							data-softrequired={
								props.field.SoftRequired && !props.isSearch
							}
							defaultValue={props.value ?? ''}
							id={props.field.Property}
							maxLength={11}
							minLength={11}
							name={props.field.Property}
							onChange={props.onChange}
							pattern={'[A-Za-z]{4}[0-9]{7}'}
							placeholder=" "
							readOnly={
								(props.readOnly || props.field.ReadOnly) &&
									!props.isSearch
							}
							required={
								props.field.HardRequired && !props.isSearch
							}
							title={props.field.Label?.replace(':', '')}
							type="text"
						/>
					</>
				)
			}

			return (
				<EditContainers
					data={props.data}
					field={props.field}
					isSearch={props.isSearch}
					onChange={props.onChange}
					readOnly={
						(props.readOnly || props.field.ReadOnly) &&
							!props.isSearch
					}
					value={props.value}
				/>
			)
		case Control.ContainerType:
			if (props.isSearch) {
				return (
					<>
						{' '}
						<div className={styles.label}>CONTAINER TYPE:</div>
						<input
							autoFocus={props.field.AutoFocus}
							className={`${
								props.field.HardRequired && !props.isSearch
									? 'hardRequired'
									: ''
							} ${
								props.field.SoftRequired && !props.isSearch
									? 'softRequired'
									: ''
							}`}
							data-hardrequired={
								props.field.HardRequired && !props.isSearch
							}
							data-nullable="true"
							data-softrequired={
								props.field.SoftRequired && !props.isSearch
							}
							defaultValue={props.value ?? ''}
							id={props.field.Property}
							name={props.field.Property}
							onChange={props.onChange}
							placeholder=" "
							readOnly={
								(props.readOnly || props.field.ReadOnly) &&
									!props.isSearch
							}
							required={
								props.field.HardRequired && !props.isSearch
							}
							title={props.field.Label?.replace(':', '')}
							type="text"
						/>
					</>
				)
			}

			return (
				<EditContainerType
					data={props.data}
					field={props.field}
					isSearch={props.isSearch}
					onChange={props.onChange}
					readOnly={
						(props.readOnly || props.field.ReadOnly) &&
							!props.isSearch
					}
					value={props.value}
				/>
			)
		case Control.Country:
			return (
				<>
					<div className={styles.label}>
						{props.field.Label ?? <span>{'\u00A0'}</span>}
						{props.field.HardRequired && !props.isSearch ? (
							<>
								{'\u00A0'}
								<span
									className="hardRequired"
									title="REQUIRED IN ORDER TO SAVE"
								>
										*
								</span>
							</>
						) : (
							<></>
						)}
						{props.field.SoftRequired && !props.isSearch ? (
							<>
								{'\u00A0'}
								<span
									className="softRequired"
									title="REQUIRED"
								>
										*
								</span>
							</>
						) : (
							<></>
						)}{' '}
					</div>
					<EditCountry
						data-nullable={true}
						field={props.field}
						isSearch={props.isSearch}
						onChange={props.onChange}
						readOnly={
							(props.readOnly || props.field.ReadOnly) &&
								!props.isSearch
						}
						value={props.value}
					/>
				</>
			)
		case Control.Currency:
			return (
				<>
					<div className={styles.label}>
						{props.field.Label ?? <span>{'\u00A0'}</span>}
						{props.field.HardRequired && !props.isSearch ? (
							<>
								{'\u00A0'}
								<span
									className="hardRequired"
									title="REQUIRED IN ORDER TO SAVE"
								>
										*
								</span>
							</>
						) : (
							<></>
						)}
						{props.field.SoftRequired && !props.isSearch ? (
							<>
								{'\u00A0'}
								<span
									className="softRequired"
									title="REQUIRED"
								>
										*
								</span>
							</>
						) : (
							<></>
						)}{' '}
					</div>
					<div className={styles.money}>
						<span>$</span>
						<input
							autoFocus={props.field.AutoFocus}
							className={`
									${props.field.HardRequired && !props.isSearch ? 'hardRequired' : ''} ${
					props.field.SoftRequired && !props.isSearch
						? 'softRequired'
						: ''
				}`}
							data-hardrequired={
								props.field.HardRequired && !props.isSearch
							}
							data-number={true}
							data-softrequired={
								props.field.SoftRequired && !props.isSearch
							}
							defaultValue={props.value ?? ''}
							id={props.field.Property}
							min={0}
							name={props.field.Property}
							onChange={props.onChange}
							placeholder=" "
							readOnly={
								(props.readOnly || props.field.ReadOnly) &&
									!props.isSearch
							}
							required={
								props.field.HardRequired && !props.isSearch
							}
							step={0.01}
							title={props.field.Label?.replace(':', '')}
							type="number"
						/>
					</div>
				</>
			)
		case Control.DocumentType:
			return (
				<EditDocumentType
					field={props.field}
					isSearch={props.isSearch}
					onChange={props.onChange}
					readOnly={
						(props.readOnly || props.field.ReadOnly) &&
							!props.isSearch
					}
					value={props.value}
				/>
			)
		case Control.Date:
			return (
				<>
					<div className={styles.label}>
						{props.field.Label ?? <span>{'\u00A0'}</span>}
						{props.field.HardRequired && !props.isSearch ? (
							<>
								{'\u00A0'}
								<span
									className="hardRequired"
									title="REQUIRED IN ORDER TO SAVE"
								>
										*
								</span>
							</>
						) : (
							<></>
						)}
						{props.field.SoftRequired && !props.isSearch ? (
							<>
								{'\u00A0'}
								<span
									className="softRequired"
									title="REQUIRED"
								>
										*
								</span>
							</>
						) : (
							<></>
						)}{' '}
					</div>
					<input
						autoFocus={props.field.AutoFocus}
						className={`${
							props.field.HardRequired && !props.isSearch
								? 'hardRequired'
								: ''
						} ${
							props.field.SoftRequired && !props.isSearch
								? 'softRequired'
								: ''
						} ${!props.value ? 'blank' : ''}`}
						data-hardrequired={
							props.field.HardRequired && !props.isSearch
						}
						data-searchoperator={
							props.field.SearchOperator
								? props.field.SearchOperator
								: null
						}
						data-softrequired={
							props.field.SoftRequired && !props.isSearch
						}
						defaultValue={DateOnly(props.value) ?? ''}
						id={props.field.Property}
						name={props.field.Property}
						onChange={(e: any) => {
							if (e.target.value) {
								e.target.classList.remove('blank')
							} else {
								e.target.classList.add('blank')
							}

							if (props.onChange) {
								props.onChange(e)
							}
						}}
						onFocus={(e: FocusEvent) => {
							if (
								!(props.readOnly || props.field.ReadOnly) ||
									props.isSearch
							) {
								(e.target as HTMLInputElement).showPicker()
							}
						}}
						placeholder=" "
						readOnly={
							(props.readOnly || props.field.ReadOnly) &&
								!props.isSearch
						}
						required={
							props.field.HardRequired && !props.isSearch
						}
						title={props.field.Label?.replace(':', '')}
						type="date"
					/>
				</>
			)
		case Control.DateTime:
			return (
				<>
					<div className={styles.label}>
						{props.field.Label ?? <span>{'\u00A0'}</span>}
						{props.field.HardRequired && !props.isSearch ? (
							<>
								{'\u00A0'}
								<span
									className="hardRequired"
									title="REQUIRED IN ORDER TO SAVE"
								>
										*
								</span>
							</>
						) : (
							<></>
						)}
						{props.field.SoftRequired && !props.isSearch ? (
							<>
								{'\u00A0'}
								<span
									className="softRequired"
									title="REQUIRED"
								>
										*
								</span>
							</>
						) : (
							<></>
						)}{' '}
					</div>
					<input
						autoFocus={props.field.AutoFocus}
						className={`${
							props.field.HardRequired && !props.isSearch
								? 'hardRequired'
								: ''
						} ${
							props.field.SoftRequired && !props.isSearch
								? 'softRequired'
								: ''
						} ${!props.value ? 'blank' : ''}`}
						data-hardrequired={
							props.field.HardRequired && !props.isSearch
						}
						data-searchoperator={
							props.field.SearchOperator
								? props.field.SearchOperator
								: null
						}
						data-softrequired={
							props.field.SoftRequired && !props.isSearch
						}
						defaultValue={DateTime(props.value)}
						id={props.field.Property}
						name={props.field.Property}
						onChange={(e: any) => {
							if (e.target.value) {
								e.target.classList.remove('blank')
							} else {
								e.target.classList.add('blank')
							}

							if (props.onChange) {
								props.onChange(e)
							}
						}}
						onFocus={(e: FocusEvent) => {
							if (
								!(props.readOnly || props.field.ReadOnly) ||
									props.isSearch
							) {
								(e.target as HTMLInputElement).showPicker()
							}
						}}
						placeholder=" "
						readOnly={
							(props.readOnly || props.field.ReadOnly) &&
								!props.isSearch
						}
						required={
							props.field.HardRequired && !props.isSearch
						}
						title={props.field.Label?.replace(':', '')}
						type="text"
					/>
				</>
			)
		case Control.Email:
			return (
				<>
					<div className={styles.label}>
						{props.field.Label ?? <span>{'\u00A0'}</span>}
						{props.field.HardRequired && !props.isSearch ? (
							<>
								{'\u00A0'}
								<span
									className="hardRequired"
									title="REQUIRED IN ORDER TO SAVE"
								>
										*
								</span>
							</>
						) : (
							<></>
						)}
						{props.field.SoftRequired && !props.isSearch ? (
							<>
								{'\u00A0'}
								<span
									className="softRequired"
									title="REQUIRED"
								>
										*
								</span>
							</>
						) : (
							<></>
						)}{' '}
					</div>
					<input
						autoFocus={props.field.AutoFocus}
						className={`${
							props.field.HardRequired && !props.isSearch
								? 'hardRequired'
								: ''
						} ${
							props.field.SoftRequired && !props.isSearch
								? 'softRequired'
								: ''
						}`}
						data-hardrequired={
							props.field.HardRequired && !props.isSearch
						}
						data-softrequired={
							props.field.SoftRequired && !props.isSearch
						}
						defaultValue={props.value ?? ''}
						id={props.field.Property}
						name={props.field.Property}
						onChange={props.onChange}
						placeholder=" "
						readOnly={
							(props.readOnly || props.field.ReadOnly) &&
								!props.isSearch
						}
						required={
							props.field.HardRequired && !props.isSearch
						}
						title={props.field.Label?.replace(':', '')}
						type="email"
					/>
				</>
			)
		case Control.FCL:
			return (
				<EditFCL
					field={props.field}
					isSearch={props.isSearch}
					onChange={props.onChange}
					readOnly={
						(props.readOnly || props.field.ReadOnly) &&
							!props.isSearch
					}
					value={props.value}
				/>
			)
		case Control.File:
			if (props.isSearch || props.readOnly || props.field.ReadOnly) {
				return <></>
			}

			return (
				<>
					<div className={styles.label}>
						{props.field.Label}{' '}
						{props.field.HardRequired && !props.isSearch ? (
							<>
								{'\u00A0'}
								<span
									className="hardRequired"
									title="REQUIRED IN ORDER TO SAVE"
								>
										*
								</span>
							</>
						) : (
							<></>
						)}
						{props.field.SoftRequired && !props.isSearch ? (
							<>
								{'\u00A0'}
								<span
									className="softRequired"
									title="REQUIRED"
								>
										*
								</span>
							</>
						) : (
							<></>
						)}{' '}
					</div>
					<input
						autoFocus={props.field.AutoFocus}
						className={`${
							props.field.HardRequired && !props.isSearch
								? 'hardRequired'
								: ''
						} ${
							props.field.SoftRequired && !props.isSearch
								? 'softRequired'
								: ''
						}`}
						data-file="true"
						data-hardrequired={
							props.field.HardRequired && !props.isSearch
						}
						data-nullable="true"
						data-softrequired={
							props.field.SoftRequired && !props.isSearch
						}
						defaultValue={props.value ?? ''}
						id={props.field.Property}
						name={props.field.Property}
						onChange={props.onChange}
						placeholder=" "
						readOnly={
							(props.readOnly || props.field.ReadOnly) &&
								!props.isSearch
						}
						required={
							props.field.HardRequired && !props.isSearch
						}
						title={props.field.Label?.replace(':', '')}
						type="file"
					/>
				</>
			)
		case Control.FreightRates:
			if (props.isSearch) {
				return (
					<div className="freightRates">
						{' '}
						<div className={styles.label}>
								FREIGHT RATES, CHARGES, WEIGHTS, AND/OR
								MEASUREMENTS:
						</div>
						<input
							autoFocus={props.field.AutoFocus}
							className={`${
								props.field.HardRequired && !props.isSearch
									? 'hardRequired'
									: ''
							} ${
								props.field.SoftRequired && !props.isSearch
									? 'softRequired'
									: ''
							}`}
							data-hardrequired={
								props.field.HardRequired && !props.isSearch
							}
							data-nullable="true"
							data-softrequired={
								props.field.SoftRequired && !props.isSearch
							}
							defaultValue={props.value ?? ''}
							id={props.field.Property}
							name={props.field.Property}
							onChange={props.onChange}
							placeholder=" "
							readOnly={
								(props.readOnly || props.field.ReadOnly) &&
									!props.isSearch
							}
							required={
								props.field.HardRequired && !props.isSearch
							}
							title={props.field.Label?.replace(':', '')}
							type="text"
						/>
					</div>
				)
			}

			return (
				<div className="freightRates">
					<EditFreightRates
						data={props.data}
						field={props.field}
						isSearch={props.isSearch}
						onChange={props.onChange}
						readOnly={
							(props.readOnly || props.field.ReadOnly) &&
								!props.isSearch
						}
						value={props.value}
					/>
				</div>
			)
		case Control.HazardousCargoInfo:
			return (
				<EditHazardousCargo
					data={props.data}
					field={props.field}
					isSearch={props.isSearch}
					onChange={props.onChange}
					readOnly={
						(props.readOnly || props.field.ReadOnly) &&
							!props.isSearch
					}
					value={props.value}
				/>
			)
		case Control.HotListed:
			return (
				<>
					<div className={styles.label}>
						{props.field.Label ?? <span>{'\u00A0'}</span>}
						{props.field.HardRequired && !props.isSearch ? (
							<>
								{'\u00A0'}
								<span
									className="hardRequired"
									title="REQUIRED IN ORDER TO SAVE"
								>
										*
								</span>
							</>
						) : (
							<></>
						)}
						{props.field.SoftRequired && !props.isSearch ? (
							<>
								{'\u00A0'}
								<span
									className="softRequired"
									title="REQUIRED"
								>
										*
								</span>
							</>
						) : (
							<></>
						)}{' '}
					</div>
					<EditHotListed
						field={props.field}
						isSearch={props.isSearch}
						onChange={props.onChange}
						readOnly={
							(props.readOnly || props.field.ReadOnly) &&
								!props.isSearch
						}
						value={props.value}
					/>
				</>
			)
		case Control.HTML:
			return (
				<>
					<div className={styles.label}>
						{props.field.Label ?? <span>{'\u00A0'}</span>}
						{props.field.HardRequired && !props.isSearch ? (
							<>
								{'\u00A0'}
								<span
									className="hardRequired"
									title="REQUIRED IN ORDER TO SAVE"
								>
										*
								</span>
							</>
						) : (
							<></>
						)}
						{props.field.SoftRequired && !props.isSearch ? (
							<>
								{'\u00A0'}
								<span
									className="softRequired"
									title="REQUIRED"
								>
										*
								</span>
							</>
						) : (
							<></>
						)}{' '}
					</div>
					<textarea
						className={`${styles.html} ${
							props.field.HardRequired && !props.isSearch
								? 'hardRequired'
								: ''
						} ${
							props.field.SoftRequired && !props.isSearch
								? 'softRequired'
								: ''
						}`}
						data-hardrequired={
							props.field.HardRequired && !props.isSearch
						}
						data-softrequired={
							props.field.SoftRequired && !props.isSearch
						}
						defaultValue={props.value ?? ''}
						id={props.field.Property}
						name={props.field.Property}
						onChange={props.onChange}
						placeholder=" "
						readOnly={
							(props.readOnly || props.field.ReadOnly) &&
								!props.isSearch
						}
						title={props.field.Label?.replace(':', '')}
					/>
				</>
			)
		case Control.ImageFile:
			if (props.isSearch || props.readOnly || props.field.ReadOnly) {
				return <></>
			}

			return (
				<>
					<div className={styles.label}>
						{props.field.Label}
						{props.field.HardRequired && !props.isSearch ? (
							<>
								{'\u00A0'}
								<span
									className="hardRequired"
									title="REQUIRED IN ORDER TO SAVE"
								>
										*
								</span>
							</>
						) : (
							<></>
						)}
						{props.field.SoftRequired && !props.isSearch ? (
							<>
								{'\u00A0'}
								<span
									className="softRequired"
									title="REQUIRED"
								>
										*
								</span>
							</>
						) : (
							<></>
						)}{' '}
					</div>
					<div className={styles.imageBox}>
						<img
							id={`${props.field.Property}-preview`}
							onError={(event: any) => {
								event.target.style.display = 'none'
							}}
							onLoad={(event: any) => {
								event.target.style.display = ''
							}}
							src={
								props.data[
									`${props.field.Property}_preview`
								]
									? props.data[
										`${props.field.Property}_preview`
										  ]
									: ''
							}
						/>{' '}
						<input
							autoFocus={props.field.AutoFocus}
							className={`
			${props.field.HardRequired && !props.isSearch ? 'hardRequired' : ''} ${
					props.field.SoftRequired && !props.isSearch
						? 'softRequired'
						: ''
				}`}
							data-file="true"
							data-hardrequired={
								props.field.HardRequired && !props.isSearch
							}
							data-nullable="true"
							data-softrequired={
								props.field.SoftRequired && !props.isSearch
							}
							defaultValue={props.value ?? ''}
							id={props.field.Property}
							name={props.field.Property}
							onChange={(event: any) => {
								const [file] = event.target.files
								if (file) {
									(
											document.getElementById(
												`${props.field.Property}-preview`
											) as HTMLImageElement
									).src = URL.createObjectURL(file)
								}
								if (props.onChange) {
									props.onChange(event)
								}
							}}
							placeholder=" "
							readOnly={
								(props.readOnly || props.field.ReadOnly) &&
									!props.isSearch
							}
							required={
								props.field.HardRequired && !props.isSearch
							}
							title={props.field.Label?.replace(':', '')}
							type="file"
						/>
					</div>
				</>
			)
		case Control.ISFTransmissionType:
			return (
				<EditISFTransmissionType
					field={props.field}
					isSearch={props.isSearch}
					onChange={props.onChange}
					readOnly={
						(props.readOnly || props.field.ReadOnly) &&
							!props.isSearch
					}
					value={props.value}
				/>
			)
		case Control.ManufacturerLineItems:
			if (props.isSearch) {
				return (
					<div className="manufacturerLineItems">
						{' '}
						<div className={styles.label}>
								MANUFACTURER LINE ITEMS:
						</div>
						<input
							autoFocus={props.field.AutoFocus}
							className={`${
								props.field.HardRequired && !props.isSearch
									? 'hardRequired'
									: ''
							} ${
								props.field.SoftRequired && !props.isSearch
									? 'softRequired'
									: ''
							}`}
							data-hardrequired={
								props.field.HardRequired && !props.isSearch
							}
							data-nullable="true"
							data-softrequired={
								props.field.SoftRequired && !props.isSearch
							}
							defaultValue={props.value ?? ''}
							id={props.field.Property}
							name={props.field.Property}
							onChange={props.onChange}
							placeholder=" "
							readOnly={
								(props.readOnly || props.field.ReadOnly) &&
									!props.isSearch
							}
							required={
								props.field.HardRequired && !props.isSearch
							}
							title={props.field.Label?.replace(':', '')}
							type="text"
						/>
					</div>
				)
			}

			return (
				<div className="manufacturerLineItems">
					<EditManufacturerLineItems
						data={props.data}
						field={props.field}
						isSearch={props.isSearch}
						onChange={props.onChange}
						readOnly={
							(props.readOnly || props.field.ReadOnly) &&
								!props.isSearch
						}
						value={props.value}
					/>
				</div>
			)
		case Control.NameComponent:
			return (
				<>
					<div className={styles.label}>
						{props.field.Label ?? <span>{'\u00A0'}</span>}
						{props.field.HardRequired && !props.isSearch ? (
							<>
								{'\u00A0'}
								<span
									className="hardRequired"
									title="REQUIRED IN ORDER TO SAVE"
								>
										*
								</span>
							</>
						) : (
							<></>
						)}
						{props.field.SoftRequired && !props.isSearch ? (
							<>
								{'\u00A0'}
								<span
									className="softRequired"
									title="REQUIRED"
								>
										*
								</span>
							</>
						) : (
							<></>
						)}{' '}
					</div>
					<input
						autoFocus={props.field.AutoFocus}
						className={`${
							props.field.HardRequired && !props.isSearch
								? 'hardRequired'
								: ''
						} ${
							props.field.SoftRequired && !props.isSearch
								? 'softRequired'
								: ''
						}`}
						data-hardrequired={
							props.field.HardRequired && !props.isSearch
						}
						data-softrequired={
							props.field.SoftRequired && !props.isSearch
						}
						defaultValue={
							props.value ??
								(props.field.Default ? props.field.Default : '')
						}
						id={props.field.Property}
						name={props.field.Property}
						onChange={(event: any) => {
							(
									document.getElementById(
										'name'
									) as HTMLInputElement
							).value = `${
								(
										document.getElementById(
											'first_name'
										) as HTMLInputElement
								).value
							} ${
								(
										document.getElementById(
											'last_name'
										) as HTMLInputElement
								).value
							}`
							if (props.onChange) {
								props.onChange(event)
							}
						}}
						placeholder=" "
						readOnly={
							(props.readOnly || props.field.ReadOnly) &&
								!props.isSearch
						}
						required={
							props.field.HardRequired && !props.isSearch
						}
						title={props.field.Label?.replace(':', '')}
						type="text"
					/>
				</>
			)
		case Control.NRARates:
			if (props.isSearch) {
				return (
					<>
						{' '}
						<div className={styles.label}>NRA RATE:</div>
						<input
							autoFocus={props.field.AutoFocus}
							className={`${
								props.field.HardRequired && !props.isSearch
									? 'hardRequired'
									: ''
							} ${
								props.field.SoftRequired && !props.isSearch
									? 'softRequired'
									: ''
							}`}
							data-hardrequired={
								props.field.HardRequired && !props.isSearch
							}
							data-nullable="true"
							data-softrequired={
								props.field.SoftRequired && !props.isSearch
							}
							defaultValue={props.value ?? ''}
							id={props.field.Property}
							name={props.field.Property}
							onChange={props.onChange}
							placeholder=" "
							readOnly={
								(props.readOnly || props.field.ReadOnly) &&
									!props.isSearch
							}
							required={
								props.field.HardRequired && !props.isSearch
							}
							title={props.field.Label?.replace(':', '')}
							type="text"
						/>
					</>
				)
			}

			return (
				<EditNRARates
					data={props.data}
					field={props.field}
					isSearch={props.isSearch}
					onChange={props.onChange}
					readOnly={
						(props.readOnly || props.field.ReadOnly) &&
							!props.isSearch
					}
					value={props.value}
				/>
			)
		case Control.NRAReference:
			return (
				<>
					<div className={styles.label}>
						{props.field.Label ?? <span>{'\u00A0'}</span>}
						{props.field.HardRequired && !props.isSearch ? (
							<>
								{'\u00A0'}
								<span
									className="hardRequired"
									title="REQUIRED IN ORDER TO SAVE"
								>
										*
								</span>
							</>
						) : (
							<></>
						)}
						{props.field.SoftRequired && !props.isSearch ? (
							<>
								{'\u00A0'}
								<span
									className="softRequired"
									title="REQUIRED"
								>
										*
								</span>
							</>
						) : (
							<></>
						)}{' '}
					</div>
					{props.value ? (
						<Link to={`/nra/${props.value}`}>
							{props.value}
						</Link>
					) : (
						<>N/A</>
					)}
				</>
			)
		case Control.NRAsToGOAL:
			return (
				<EditNRAsToGOAL
					data={props.data}
					field={props.field}
					isSearch={props.isSearch}
					onChange={props.onChange}
					readOnly={
						(props.readOnly || props.field.ReadOnly) &&
							!props.isSearch
					}
					value={props.value}
				/>
			)
		case Control.Number:
			return (
				<>
					<div className={styles.label}>
						{props.field.Label ?? <span>{'\u00A0'}</span>}
						{props.field.HardRequired && !props.isSearch ? (
							<>
								{'\u00A0'}
								<span
									className="hardRequired"
									title="REQUIRED IN ORDER TO SAVE"
								>
										*
								</span>
							</>
						) : (
							<></>
						)}
						{props.field.SoftRequired && !props.isSearch ? (
							<>
								{'\u00A0'}
								<span
									className="softRequired"
									title="REQUIRED"
								>
										*
								</span>
							</>
						) : (
							<></>
						)}{' '}
					</div>
					<input
						autoFocus={props.field.AutoFocus}
						className={`
							${props.field.HardRequired && !props.isSearch ? 'hardRequired' : ''} ${
					props.field.SoftRequired && !props.isSearch
						? 'softRequired'
						: ''
				}`}
						data-hardrequired={
							props.field.HardRequired && !props.isSearch
						}
						data-number={true}
						data-softrequired={
							props.field.SoftRequired && !props.isSearch
						}
						defaultValue={props.value ?? ''}
						id={props.field.Property}
						name={props.field.Property}
						onChange={props.onChange}
						placeholder=" "
						readOnly={
							(props.readOnly || props.field.ReadOnly) &&
								!props.isSearch
						}
						required={
							props.field.HardRequired && !props.isSearch
						}
						title={props.field.Label?.replace(':', '')}
						type="number"
					/>
				</>
			)
		case Control.Organization:
			if (!props.isSearch) {
				return (
					<EditOrganization
						data={props.data}
						field={props.field}
						isSearch={props.isSearch}
						onChange={props.onChange}
						readOnly={
							(props.readOnly || props.field.ReadOnly) &&
								!props.isSearch
						}
						value={props.value}
					/>
				)
			}
			break
		case Control.OrganizationTextbox:
			if (!props.isSearch) {
				return (
					<EditOrganizationTextbox
						data={props.data}
						field={props.field}
						isSearch={props.isSearch}
						onChange={props.onChange}
						readOnly={
							(props.readOnly || props.field.ReadOnly) &&
								!props.isSearch
						}
						value={props.value}
					/>
				)
			}
			break
		case Control.OrganizationType:
			return (
				<EditOrganizationType
					data={props.data}
					field={props.field}
					isSearch={props.isSearch}
					onChange={props.onChange}
					readOnly={
						(props.readOnly || props.field.ReadOnly) &&
							!props.isSearch
					}
					value={props.value}
				/>
			)
		case Control.Partner:
			return (
				<EditPartner
					data={props.data}
					field={props.field}
					isSearch={props.isSearch}
					onChange={props.onChange}
					readOnly={
						(props.readOnly || props.field.ReadOnly) &&
							!props.isSearch
					}
					value={props.value}
				/>
			)
		case Control.Password:
			return (
				<>
					<div className={styles.label}>
						{props.field.Label ?? <span>{'\u00A0'}</span>}
						{props.field.HardRequired && !props.isSearch ? (
							<>
								{'\u00A0'}
								<span
									className="hardRequired"
									title="REQUIRED IN ORDER TO SAVE"
								>
										*
								</span>
							</>
						) : (
							<></>
						)}
						{props.field.SoftRequired && !props.isSearch ? (
							<>
								{'\u00A0'}
								<span
									className="softRequired"
									title="REQUIRED"
								>
										*
								</span>
							</>
						) : (
							<></>
						)}{' '}
					</div>
					<input
						autoComplete="new-password"
						autoFocus={props.field.AutoFocus}
						className={`
							${props.field.HardRequired && !props.isSearch ? 'hardRequired' : ''} ${
					props.field.SoftRequired && !props.isSearch
						? 'softRequired'
						: ''
				}`}
						data-hardrequired={
							props.field.HardRequired && !props.isSearch
						}
						data-softrequired={
							props.field.SoftRequired && !props.isSearch
						}
						defaultValue={
							props.value ??
								(props.field.Default ? props.field.Default : '')
						}
						id={props.field.Property}
						maxLength={32}
						minLength={8}
						name={props.field.Property}
						onChange={(e: any) => {
							if (e.target.id == 'confirm_password') {
								(
										e.target as HTMLInputElement
								).setCustomValidity(
									(
											document.getElementById(
												'password'
											) as HTMLInputElement
									).value !=
											(
												document.getElementById(
													'confirm_password'
												) as HTMLInputElement
											).value
										? 'Passwords must match.'
										: ''
								)
							}

							if (props.onChange) {
								props.onChange(e)
							}
						}}
						pattern={
							'^(?=.*[a-z])(?=.*[A-Z])(?=.*d)(?=.*[`~!@#$%^&*()-_=+[]{}\\|;:\'",<.>/?])[A-Za-zd`~!@#$%^&*()-_=+[]{}\\|;:\'",<.>/?]{8,32}$'
						}
						placeholder=" "
						readOnly={
							(props.readOnly || props.field.ReadOnly) &&
								!props.isSearch
						}
						required={
							props.field.HardRequired && !props.isSearch
						}
						title={props.field.Label?.replace(':', '')}
						type="password"
					/>
				</>
			)
		case Control.Permissions:
			return (
				<EditPermissions
					data={props.data}
					permissions={props.value}
				/>
			)
		case Control.Phone:
			return (
				<>
					<div className={styles.label}>
						{props.field.Label ?? <span>{'\u00A0'}</span>}
						{props.field.HardRequired && !props.isSearch ? (
							<>
								{'\u00A0'}
								<span
									className="hardRequired"
									title="REQUIRED IN ORDER TO SAVE"
								>
										*
								</span>
							</>
						) : (
							<></>
						)}
						{props.field.SoftRequired && !props.isSearch ? (
							<>
								{'\u00A0'}
								<span
									className="softRequired"
									title="REQUIRED"
								>
										*
								</span>
							</>
						) : (
							<></>
						)}{' '}
					</div>
					<input
						autoFocus={props.field.AutoFocus}
						className={`
							${props.field.HardRequired && !props.isSearch ? 'hardRequired' : ''} ${
					props.field.SoftRequired && !props.isSearch
						? 'softRequired'
						: ''
				}`}
						data-hardrequired={
							props.field.HardRequired && !props.isSearch
						}
						data-softrequired={
							props.field.SoftRequired && !props.isSearch
						}
						defaultValue={
							props.value ??
								(props.field.Default ? props.field.Default : '')
						}
						id={props.field.Property}
						name={props.field.Property}
						onChange={props.onChange}
						placeholder=" "
						readOnly={
							(props.readOnly || props.field.ReadOnly) &&
								!props.isSearch
						}
						required={
							props.field.HardRequired && !props.isSearch
						}
						title={props.field.Label?.replace(':', '')}
						type="tel"
					/>
				</>
			)
		case Control.Port:
			return (
				<EditPort
					data={props.data}
					field={props.field}
					isSearch={props.isSearch}
					onChange={props.onChange}
					readOnly={
						(props.readOnly || props.field.ReadOnly) &&
							!props.isSearch
					}
					value={props.value}
				/>
			)
		case Control.Pricing:
			return (
				<EditPricing
					data={props.data}
					field={props.field}
					isSearch={props.isSearch}
					onChange={props.onChange}
					readOnly={
						(props.readOnly || props.field.ReadOnly) &&
							!props.isSearch
					}
					value={props.value}
				/>
			)
		case Control.Role:
			return (
				<EditRole
					data={props.data}
					field={props.field}
					isSearch={props.isSearch}
					onChange={props.onChange}
					readOnly={
						(props.readOnly || props.field.ReadOnly) &&
							!props.isSearch
					}
					value={props.value}
				/>
			)
		case Control.NRA:
			return (
				<EditNRA
					data={props.data}
					field={props.field}
					isSearch={props.isSearch}
					onChange={props.onChange}
					readOnly={
						(props.readOnly || props.field.ReadOnly) &&
							!props.isSearch
					}
					value={props.value}
				/>
			)
		case Control.ShipmentNRAs:
			return (
				<EditShipmentNRAs
					data={props.data}
					field={props.field}
					isSearch={props.isSearch}
					onChange={props.onChange}
					readOnly={
						(props.readOnly || props.field.ReadOnly) &&
							!props.isSearch
					}
					value={props.value}
				/>
			)
		case Control.ShipmentStatus:
			return (
				<EditShipmentStatus
					field={props.field}
					isSearch={props.isSearch}
					onChange={props.onChange}
					readOnly={
						(props.readOnly || props.field.ReadOnly) &&
							!props.isSearch
					}
					value={props.value}
				/>
			)
		case Control.ShipmentType:
			return (
				<EditShipmentType
					field={props.field}
					isSearch={props.isSearch}
					onChange={props.onChange}
					readOnly={
						(props.readOnly || props.field.ReadOnly) &&
							!props.isSearch
					}
					value={props.value}
				/>
			)
		case Control.Static:
			return (
				<>
					<div className={styles.label}>
						<span>{'\u00A0'}</span>
					</div>
					<div className={styles.static}>
						{props.field.Property}
					</div>
				</>
			)
		case Control.ToggleContactInfo:
			return (
				<div className={styles.toggleContactInfo}>
					<label htmlFor="showContactInfo">
							SHOW MANUFACTURER CONTACT INFO?{' '}
					</label>
					<input
						id="showContactInfo"
						onClick={(e: any) => {
							if (e.target.checked) {
								const head =
										document.head ||
										document.getElementsByTagName('head')[0]
								const style =
										document.createElement('style')
								style.id = 'showContactInfoStyle'
								style.innerText = `.${props.field.Property} .organizationMetadata {display: block !important;}`
								head.appendChild(style)
							} else {
								const style = document.getElementById(
									'showContactInfoStyle'
								)
								style?.remove()
							}
						}}
						type="checkbox"
					/>
				</div>
			)
		case Control.TextBox:
			return (
				<>
					<div className={styles.label}>
						{props.field.Label ?? <span>{'\u00A0'}</span>}
						{props.field.HardRequired && !props.isSearch ? (
							<>
								{'\u00A0'}
								<span
									className="hardRequired"
									title="REQUIRED IN ORDER TO SAVE"
								>
										*
								</span>
							</>
						) : (
							<></>
						)}
						{props.field.SoftRequired && !props.isSearch ? (
							<>
								{'\u00A0'}
								<span
									className="softRequired"
									title="REQUIRED"
								>
										*
								</span>
							</>
						) : (
							<></>
						)}{' '}
					</div>
					<textarea
						autoFocus={props.field.AutoFocus}
						className={`${styles.textbox} ${
							props.field.HardRequired && !props.isSearch
								? 'hardRequired'
								: ''
						} ${
							props.field.SoftRequired && !props.isSearch
								? 'softRequired'
								: ''
						}`}
						data-hardrequired={
							props.field.HardRequired && !props.isSearch
						}
						data-softrequired={
							props.field.SoftRequired && !props.isSearch
						}
						defaultValue={props.value ?? ''}
						id={props.field.Property}
						name={props.field.Property}
						onChange={props.onChange}
						placeholder=" "
						readOnly={
							(props.readOnly || props.field.ReadOnly) &&
								!props.isSearch
						}
						title={props.field.Label?.replace(':', '')}
					/>
				</>
			)
		case Control.TimeZone:
			return (
				<>
					<div className={styles.label}>
						{props.field.Label ?? <span>{'\u00A0'}</span>}
						{props.field.HardRequired && !props.isSearch ? (
							<>
								{'\u00A0'}
								<span
									className="hardRequired"
									title="REQUIRED IN ORDER TO SAVE"
								>
										*
								</span>
							</>
						) : (
							<></>
						)}
						{props.field.SoftRequired && !props.isSearch ? (
							<>
								{'\u00A0'}
								<span
									className="softRequired"
									title="REQUIRED"
								>
										*
								</span>
							</>
						) : (
							<></>
						)}{' '}
					</div>
					<EditTimeZone
						field={props.field}
						isSearch={props.isSearch}
						onChange={props.onChange}
						readOnly={
							(props.readOnly || props.field.ReadOnly) &&
								!props.isSearch
						}
						value={props.value}
					/>
				</>
			)
		case Control.Unit:
			return (
				<>
					<div className={styles.label}>
						{props.field.Label ?? <span>{'\u00A0'}</span>}
						{props.field.HardRequired && !props.isSearch ? (
							<>
								{'\u00A0'}
								<span
									className="hardRequired"
									title="REQUIRED IN ORDER TO SAVE"
								>
										*
								</span>
							</>
						) : (
							<></>
						)}
						{props.field.SoftRequired && !props.isSearch ? (
							<>
								{'\u00A0'}
								<span
									className="softRequired"
									title="REQUIRED"
								>
										*
								</span>
							</>
						) : (
							<></>
						)}{' '}
					</div>
					<EditUnit
						data={props.data}
						field={props.field}
						isSearch={props.isSearch}
						onChange={props.onChange}
						readOnly={
							(props.readOnly || props.field.ReadOnly) &&
								!props.isSearch
						}
						value={props.value}
					/>
				</>
			)
		case Control.User:
			return (
				<EditUser
					data={props.data}
					field={props.field}
					isSearch={props.isSearch}
					onChange={props.onChange}
					readOnly={
						(props.readOnly || props.field.ReadOnly) &&
							!props.isSearch
					}
					value={props.value}
				/>
			)
		}

		let value = props.value
		if (props.field.Properties) {
			value = props.field.Properties.map((property: string) => {
				return props.data[property]
			}).join(', ')
		}
		return (
			<>
				<div className={styles.label}>
					{props.field.Label ?? <span>{'\u00A0'}</span>}
					{props.field.HardRequired && !props.isSearch ? (
						<>
							{'\u00A0'}
							<span
								className="hardRequired"
								title="REQUIRED IN ORDER TO SAVE"
							>
								*
							</span>
						</>
					) : (
						<></>
					)}
					{props.field.SoftRequired && !props.isSearch ? (
						<>
							{'\u00A0'}
							<span className="softRequired" title="REQUIRED">
								*
							</span>
						</>
					) : (
						<></>
					)}{' '}
				</div>
				{props.field.Notes ? (
					<div className={styles.notes}>{props.field.Notes}</div>
				) : (
					<></>
				)}
				<input
					autoComplete={!props.field.AutoComplete ? 'off' : ''}
					autoFocus={props.field.AutoFocus}
					className={`
							${props.field.HardRequired && !props.isSearch ? 'hardRequired' : ''} ${
				props.field.SoftRequired && !props.isSearch
					? 'softRequired'
					: ''
			}`}
					data-hardrequired={
						props.field.HardRequired && !props.isSearch
					}
					data-softrequired={
						props.field.SoftRequired && !props.isSearch
					}
					defaultValue={
						value ??
						(props.field.Default ? props.field.Default : '')
					}
					id={props.field.Property}
					maxLength={props.field.MaxLength}
					minLength={props.field.MinLength}
					name={props.field.Property}
					onChange={props.onChange}
					placeholder=" "
					readOnly={
						(props.readOnly || props.field.ReadOnly) &&
						!props.isSearch
					}
					required={props.field.HardRequired && !props.isSearch}
					tabIndex={
						props.field.ReadOnly && !props.readOnly ? -1 : undefined
					}
					title={props.field.Label?.replace(':', '')}
					type="text"
				/>
			</>
		)
	}

	return (
		<div
			className={styles.fieldOuterContainer}
			id={props.field.Property ? `${props.field.Property}_container` : ''}
			style={{
				display: props.hidden ? 'none' : 'unset',
				width: `${props.field.Width?.toString()}%`,
			}}
		>
			{props.field.Property ? editControl(props.field.Control) : <></>}
		</div>
	)
}

export type FieldProps = {
	data?: any
	field: Field
	hidden?: boolean
	isSearch: boolean
	onChange?: (event: any) => void
	readOnly?: boolean
	value: any
}
