import { useReactiveVar } from '@apollo/client'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import { AnonymousRouter } from './AnonymousRouter'
import { AuthenticatedRouter } from './AuthenticatedRouter'
import { Authenticated } from './Profile'
import { ApplyTheme } from './Theme'

const queryClient = new QueryClient()

const App = () => {
	const authenticated = useReactiveVar(Authenticated)
	if (location.hostname.startsWith('www.')) {
		location.replace(location.href.replace('www.', ''))
		return <></>
	}

	ApplyTheme()

	if (!authenticated) {
		return (
			<QueryClientProvider client={queryClient}>
				<AnonymousRouter />
			</QueryClientProvider>
		)
	}

	return (
		<QueryClientProvider client={queryClient}>
			<AuthenticatedRouter />
		</QueryClientProvider>
	)
}

export default App
