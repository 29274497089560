import Control from '../Control'
import { Layout } from '../Layout'

export const RegisterLayout: Layout = {
	Groups: [
		{
			Direction: 'row',
			Flex: '1 1 0%',
			Groups: [
				{
					Direction: 'column',
					OptionalRows: [
						{
							Fields: [
								{
									HardRequired: true,
									Label: 'FIRST NAME',
									Property: 'first_name',
									Width: 50,
								},
								{
									HardRequired: true,
									Label: 'LAST NAME',
									Property: 'last_name',
									Width: 50,
								},
							],
						},
						{
							Fields: [
								{
									HardRequired: true,
									Label: 'COMPANY',
									Property: 'company',
									Width: 100,
								},
							],
						},
						{
							Fields: [
								{
									HardRequired: true,
									Label: 'TITLE',
									Property: 'title',
									Width: 50,
								},
								{
									Control: Control.Email,
									HardRequired: true,
									Label: 'EMAIL',
									Property: 'email',
									Width: 50,
								},
							],
						},
						{
							Fields: [
								{
									Control: Control.Phone,
									Label: 'PHONE',
									Property: 'phone',
									SoftRequired: true,
									Width: 50,
								},
							],
						},
					],
					Width: 100,
				},
			],
			Margin: '0 0 1rem 0',
			Padding: '1rem 0 0 0',
		},
		{
			Groups: [
				{
					IncludeInSearch: false,
					Label: 'PASSWORD',
					LabelBackground: 'blue',
					RequiredRows: [
						{
							Fields: [
								{
									Control: Control.Password,
									HardRequired: true,
									Label: 'PASSWORD',
									Property: 'password',
									Width: 25,
								},
								{
									Control: Control.Password,
									HardRequired: true,
									Label: 'CONFIRM PASSWORD',
									Property: 'confirm_password',
									Width: 25,
								},
								{
									Label: '',
									Property: '',
									Width: 25,
								},
								{
									Label: '',
									Property: '',
									Width: 25,
								},
							],
						},
					],
					Width: 100,
				},
			],
			Width: 100,
		},
	],
}
