import { makeVar } from '@apollo/client'
import axios from 'axios'
import jwt_decode from 'jwt-decode'

export const APIHostname =
	location.hostname == 'localhost'
		? 'https://preview.goalportal.net'
		: location.origin

export const Authenticated = makeVar(localStorage['logged-in'] === 'true')

export const LogOff = async (reason?: string) => {
	axios({
		method: 'POST',
		url: `${APIHostname}/api/v1/logout`,
	})
	localStorage.clear()

	const cookies = document.cookie.split(';')
	for (let i = 0; i < cookies.length; i++) {
		const cookie = cookies[i]
		const eqPos = cookie.indexOf('=')
		const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
		document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`
	}

	location.replace(`/${reason ? `#${reason}` : ''}`)
}

export const RenewToken = async (email: string, rememberMe: boolean) => {
	await axios({
		data: {
			email: email,
			rememberMe: rememberMe,
		},
		headers: {
			Authorization: `Bearer ${localStorage['token']}`,
			'Content-Type': 'application/json',
		},
		method: 'POST',
		url: `${APIHostname}/api/v1/renew`,
	})
		.then((response: any) => {
			const token: any = jwt_decode(response.data.token)
			if (!token.interactive) {
				return
			}

			localStorage['logged-in'] = 'true'
			if (response.data.token) {
				localStorage['token'] = response.data.token
			}
		})
		.catch((error: any) => {
			switch (error?.response?.status) {
			case 401:
				LogOff('token-expired')
				return
			default:
					console.error(error) // eslint-disable-line
			}
		})
}

let profileDeveloperMode = false
let profileEmail = '',
	profileImagePreview = '',
	profileName = '',
	profileOrganizationName = '',
	profilePartnerName = '',
	profileRole = '',
	profileTitle = ''
let profileOrganizationID = 0,
	profilePartnerID = 0,
	profileUserID = 0
let profilePermissions: { [key: string]: boolean } = {}
let tokenExpiration = 0
if (localStorage['token'] != undefined && localStorage['token'].length > 0) {
	const token: any = jwt_decode(localStorage['token'])
	tokenExpiration = token.exp

	// Handle expired tokens
	if (tokenExpiration * 1000 <= new Date().getTime()) {
		LogOff('token-expired')
	} else {
		profileDeveloperMode = token.developerMode
		profileEmail = token.name
		profileImagePreview = token.imagePreview
		profileOrganizationID = token.organizationID
		profileOrganizationName = token.organizationName
		profileName = token.name
		profilePartnerID = token.partnerID
		profilePartnerName = token.partnerName
		profilePermissions = token.permissions ?? {}
		profileRole = token.role
		profileTitle = token.title
		profileUserID = token.userID

		// If token expires in next hour, renew it immediately
		if (new Date().getTime() + 60 * 60 > tokenExpiration * 1000) {
			RenewToken(profileEmail, localStorage['remember-me'] == 'true')
		}

		// Renew token every hour
		setInterval(() => {
			RenewToken(profileEmail, localStorage['remember-me'] == 'true')
		}, 60 * 60 * 1000)
	}
}

export const ProfileDeveloperMode = makeVar(profileDeveloperMode)
export const ProfileEmail = makeVar(profileEmail)
export const ProfileImagePreview = makeVar(profileImagePreview)
export const ProfileName = makeVar(profileName)
export const ProfileOrganizationID = makeVar(profileOrganizationID)
export const ProfileOrganizationName = makeVar(profileOrganizationName)
export const ProfilePartnerID = makeVar(profilePartnerID)
export const ProfilePartnerName = makeVar(profilePartnerName)
export const ProfilePermissions = makeVar(profilePermissions)
export const ProfileRole = makeVar(profileRole)
export const ProfileTitle = makeVar(profileTitle)
export const ProfileUserID = makeVar(profileUserID)
export const TokenExpiration = makeVar(tokenExpiration)

export const HasPermission = (type: string, action: string): boolean => {
	return ProfilePermissions()[`${type}.${action}`] == true
}

export const HasDeveloperMode = (required: boolean | undefined): boolean => {
	if (required) {
		return ProfileDeveloperMode()
	}

	return true
}

export const HasPermissions = (combos?: string[]): boolean => {
	if (!combos) {
		return true
	}

	let result = true
	const permissions = ProfilePermissions()
	combos.forEach((permission: string) => {
		if (permissions[permission] != true) {
			result = false
			return
		}
	})

	return result
}

export const HasRole = (...roles: string[]): boolean => {
	if (!roles || roles.length == 0) {
		return true
	}

	let returnValue = false
	roles.forEach((role: string) => {
		const subRoles = role.split('||')
		subRoles.forEach((subRole: string) => {
			if (subRole == ProfileRole() || subRole == '') {
				returnValue = true
			}
		})
	})

	return returnValue
}

export const HasValues = (
	requiredValues: { [name: string]: any } | undefined,
	data: { [name: string]: any }
): boolean => {
	if (!requiredValues) {
		return true
	}

	let returnValue = true
	Object.keys(requiredValues).forEach((key: string) => {
		let matched = false
		requiredValues[key].split('||').forEach((value: string) => {
			if (data[key] == value) {
				matched = true
			}
		})
		if (!matched) {
			returnValue = false
		}
	})

	return returnValue
}

export const HasPermissionsRoleAndValues = (
	permissions: string[] | undefined,
	role: string | undefined,
	requiredValues: { [name: string]: any } | undefined,
	data: { [name: string]: any }
): boolean => {
	if (permissions && !HasPermissions(permissions)) {
		return false
	}

	if (role && !HasRole(role)) {
		return false
	}

	if (!requiredValues) {
		return true
	}

	return HasValues(requiredValues, data)
}
