import { Link } from 'react-router-dom'

import PreAlertsIcon from '../../../assets/icons/pre-alert.svg'
import { EncodedStringify } from '../../../types/Filter'
import styles from './PreAlerts.module.scss'

export const PreAlerts = (props: PreAlertsProps) => {
	const filters = [
		{
			field: 'prealert',
			operator: 'eq',
			value: 'true',
		},
	]
	return (
		<div className="kpiOuterContainer">
			<div className={`kpiInnerContainer ${styles.innerContainer}`}>
				<Link to={`/shipments/?filters=${EncodedStringify(filters)}`}>
					<img alt="PRE-ALERTS" src={PreAlertsIcon} />
					<br />
					PRE-ALERTS:
					<br />
					{props.preAlertCount}
				</Link>
			</div>
		</div>
	)
}

export type PreAlertsProps = {
	preAlertCount: number
}
