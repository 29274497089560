import axios from 'axios'
import { useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'

import ACIIcon from '../../../../../assets/icons/aci.svg'
import AMSIcon from '../../../../../assets/icons/ams.svg'
import ArrivalIcon from '../../../../../assets/icons/arrival-notice.svg'
import DownArrowIcon from '../../../../../assets/icons/arrow-down.svg'
import BookingIcon from '../../../../../assets/icons/booking.svg'
import DocumentsIcon from '../../../../../assets/icons/documents.svg'
import ShipmentHistoryIcon from '../../../../../assets/icons/history.svg'
import ISFIcon from '../../../../../assets/icons/isf.svg'
import NRAIcon from '../../../../../assets/icons/open-nra.svg'
import RemarksIcon from '../../../../../assets/icons/remarks.svg'
import ShipmentTrackingIcon from '../../../../../assets/icons/tracking.svg'
import {
	EditForm,
	ParsedFormData,
} from '../../../../../components/Form/EditForm'
import { ShipmentSummary } from '../../../../../components/ShipmentSummary'
import { GlobalTopTabItems } from '../../../../../components/Tabs'
import { DefaultLayout } from '../../../../../layouts/DefaultLayout'
import { APIHostname, HasPermission, LogOff } from '../../../../../Profile'
import { HBLLayout } from '../../../../../types/data/HBL'
import styles from './ShipmentHBL.module.scss'

export const ShipmentHBL = () => {
	const { id } = useParams()

	const [shipment, setShipment]: any = useState(null)
	const [loaded, setLoaded] = useState(false)
	const [errorMessage, setErrorMessage] = useState(<></>)
	const initialRedirectMessage = sessionStorage['redirect-message']
	const [redirectMessage] = useState(initialRedirectMessage)

	// Vary based on query string.
	const location = useLocation()

	useEffect(() => {
		axios({
			data: {},
			headers: {
				Authorization: `Bearer ${localStorage['token']}`,
			},
			method: 'GET',
			url: `${APIHostname}/api/v1/shipments/${id}?view=hbl`,
		})
			.then((shipmentResponse: any) => {
				setErrorMessage(<></>)
				if (!shipmentResponse.data.export_references) {
					shipmentResponse.data.export_references = `GOAL has tendered this cargo for co-loading to ${shipmentResponse.data.origin_partner_name}.`
				}
				if (!shipmentResponse.data.num_bills_of_lading) {
					shipmentResponse.data.num_bills_of_lading = 1
				}
				shipmentResponse.data.hbl_date = new Date()
					.toISOString()
					.split('T')[0]
				shipmentResponse.data.filename = `GOAL HBL ${shipmentResponse.data.hbl}.pdf`
				shipmentResponse.data.save = true

				if (!shipmentResponse.data.to_obtain_delivery) {
					axios({
						data: {},
						headers: {
							Authorization: `Bearer ${localStorage['token']}`,
						},
						method: 'GET',
						url: `${APIHostname}/api/v1/partners/${shipmentResponse.data.destination_partner}`,
					}).then((partnerResponse: any) => {
						shipmentResponse.data.to_obtain_delivery = `${
							partnerResponse.data.name ?? ''
						}\r\n${partnerResponse.data.address ?? ''}\r\n${
							partnerResponse.data.city ?? ''
						}, ${partnerResponse.data.state ?? ''} ${
							partnerResponse.data.postal_code ?? ''
						}${
							partnerResponse.data.country != 'US'
								? `\r\n${partnerResponse.data.country ?? ''}`
								: ''
						}\r\n${partnerResponse.data.phone ?? ''}`

						setShipment(shipmentResponse.data)
						setLoaded(true)
					})
				} else {
					setShipment(shipmentResponse.data)
					setLoaded(true)
				}
			})
			.catch((error: any) => {
				switch (error?.response?.status) {
				case 401:
					LogOff('token-expired')
					return
				case 403:
					setErrorMessage(
						<>
								Error: You do not have permission to edit
								shipment documents.
						</>
					)
					break
				default:
					if (error?.response?.data?.error) {
						setErrorMessage(
							<Link to={error?.response?.data?.link}>
									Error: {error?.response?.data?.error}
							</Link>
						)
					} else {
						setErrorMessage(<>Error: Unknown error.</>)
							console.error(error) // eslint-disable-line
					}
				}
			})
	}, [location])

	const arrivalNoticeTemplate = shipment?.arrival_notice_template
	const usDestination =
		shipment?.discharge_port?.toLowerCase().startsWith('us') ||
		shipment?.final_port_of_entry?.toLowerCase().startsWith('us')
	const caDestination =
		shipment?.discharge_port?.toLowerCase().startsWith('ca') ||
		shipment?.final_port_of_entry?.toLowerCase().startsWith('ca')
	const ShipmentLeftMenuItems = [
		{
			icon: BookingIcon,
			text: 'BOOKING',
			to: `/shipments/${id}`,
		},
		{
			count: shipment?.unread_remarks ?? null,
			icon: RemarksIcon,
			text: 'REMARKS',
			to: `/shipments/${id}/remarks`,
		},
		{
			disabled: !usDestination && !shipment?.ams_status,
			disabledReason: 'Shipment does not have US destination ports.',
			icon: AMSIcon,
			requiredRole: 'administrator||memberpartner||partner',
			text: 'AMS',
			to: `/shipments/${id}/ams`,
		},
		{
			disabled: !caDestination && !shipment?.aci_status,
			disabledReason: 'Shipment does not have CA destination ports.',
			icon: ACIIcon,
			requiredRole: 'administrator||memberpartner||partner',
			text: 'ACI',
			to: `/shipments/${id}/aci`,
		},
		{
			disabled: !usDestination,
			disabledReason: 'Shipment does not have US destination ports.',
			icon: ISFIcon,
			requiredRole: 'administrator||memberpartner||partner',
			text: 'ISF',
			to: `/shipments/${id}/isf`,
		},
		{
			icon: DocumentsIcon,
			text: 'DOCUMENTS',
			to: `/shipments/${id}/documents`,
		},
		{
			icon: DocumentsIcon,
			requiredRole: 'administrator||memberpartner||partner',
			text: 'HBL PRINT',
			to: `/shipments/${id}/hbl`,
		},
		{
			disabled: !usDestination,
			disabledReason: 'Shipment does not have US destination ports.',
			icon: NRAIcon,
			requiredRole: 'administrator||memberpartner||partner',
			text: 'NRA',
			to: `/shipments/${id}/nra`,
		},
		{
			disabled: !arrivalNoticeTemplate,
			disabledReason:
				'Destination partner does not have an Arrival Notice template.',
			icon: ArrivalIcon,
			requiredRole: 'administrator||memberpartner||partner',
			text: 'ARRIVAL NOTICE',
			to: `/shipments/${id}/arrival_notice${
				arrivalNoticeTemplate == 'default'
					? ''
					: `/${arrivalNoticeTemplate}`
			}`,
		},
		{
			icon: ShipmentTrackingIcon,
			requiredRole: 'administrator||memberpartner||partner||organization',
			text: 'SHIPMENT TRACKING',
			to: `/shipments/${id}/tracking`,
		},
		{
			icon: ShipmentHistoryIcon,
			text: 'SHIPMENT HISTORY',
			to: `/shipments/${id}/history`,
		},
	]

	const ShipmentBottomTabItems = [
		{
			icon: DownArrowIcon,
			text: `SHIPMENT #${id} HBL`,
			to: `/shipments/${id}/hbl`,
		},
	]

	if (!loaded) {
		if (errorMessage) {
			return (
				<DefaultLayout
					bottomTabItems={ShipmentBottomTabItems}
					leftMenuItems={ShipmentLeftMenuItems}
					showPrint={true}
					tabMenuItems={ShipmentTabMenuItems}
					topTabItems={GlobalTopTabItems}
				>
					<div className={styles.shipmentDocumentsContainer}>
						<div className={styles.redirectMessage}>
							{redirectMessage}
						</div>
						<div className={styles.errorMessage}>
							{errorMessage}
						</div>
					</div>
				</DefaultLayout>
			)
		}

		return <div className="loading">Loading...</div>
	}

	sessionStorage.removeItem('redirect-message')
	return (
		<DefaultLayout
			bottomTabItems={ShipmentBottomTabItems}
			leftMenuItems={ShipmentLeftMenuItems}
			showPrint={true}
			tabMenuItems={ShipmentTabMenuItems}
			topTabItems={GlobalTopTabItems}
		>
			<div className={styles.shipmentHBLContainer}>
				<ShipmentSummary shipment={shipment} />
				<div className={styles.redirectMessage}>{redirectMessage}</div>
				<div className={styles.errorMessage}>
					{errorMessage}
					{errorMessage}{' '}
					{!shipment?.active ? (
						<>This shipment has been deleted.</>
					) : (
						<></>
					)}
				</div>
				<EditForm
					data={shipment}
					layout={HBLLayout}
					name="document-form"
					onSubmit={async (formData: FormData) => {
						const parsedFormData = await ParsedFormData(formData, [
							'freight_rates',
						])

						// Create arrival notice
						axios({
							data: parsedFormData,
							headers: {
								Authorization: `Bearer ${localStorage['token']}`,
								'Content-Type': 'application/json',
							},
							method: 'POST',
							url: `${APIHostname}/api/v1/shipments/${id}/hbl`,
						})
							.then((response: any) => {
								sessionStorage[
									'redirect-message'
								] = `Document #${response.data.document_id} for arrival notice created.`

								window.history.replaceState(
									{},
									'',
									`/shipments/${response.data.shipment_id}/documents`
								)
								document.location.reload()
							})
							.catch((error: any) => {
								switch (error?.response?.status) {
								case 401:
									LogOff('token-expired')
									return
								case 403:
									setErrorMessage(
										<>
												Error: You do not have
												permission to edit HBLs.
										</>
									)
									break
								default:
									if (error?.response?.data?.error) {
										setErrorMessage(
											<Link
												to={
													error?.response?.data
														?.link
												}
											>
													Error:
												{
													error?.response?.data
														?.error
												}
											</Link>
										)
									} else {
										setErrorMessage(
											<>Error: Unknown error.</>
										)
											console.error(error) // eslint-disable-line
									}
								}
							})
					}}
					previewAPIData={async (formData: FormData) => {
						const parsedFormData = await ParsedFormData(formData, [
							'freight_rates',
						])

						return {
							data: JSON.stringify(parsedFormData, null, 4),
							method: 'POST',
							url: `${APIHostname}/api/v1/shipments/${id}/hbl`,
						}
					}}
					readOnly={
						!HasPermission('shipment', 'update') || shipment.lock
					}
					submitButtonLabel="GENERATE HBL"
				></EditForm>{' '}
			</div>
		</DefaultLayout>
	)
}

export const ShipmentTabMenuItems = []
