import { ReactElement, useState } from 'react'

import AddIcon from '../../../../assets/icons/add.svg'
import DeleteIcon from '../../../../assets/icons/delete.svg'
import { FieldProps } from '../../Field'
import styles from './Charges.module.scss'

export const EditCharges = (props: FieldProps) => {
	const [chargeCount, setChargeCount] = useState(
		props.data?.charges?.length ? props.data?.charges?.length : 0
	)

	const rows: ReactElement[] = []
	for (let i = 0; i < chargeCount; i++) {
		rows.push(
			<div
				className={styles.row}
				id={`${props.field.Property}_${i}`}
				key={`chargeNumber${i}`}
			>
				<input
					data-number={true}
					defaultValue={
						props.data?.charges ? props.data?.charges[i]?.id : ''
					}
					id={`${props.field.Property}_${i}_id`}
					name={`${props.field.Property}_${i}_id`}
					type="hidden"
				/>
				<input
					data-number={true}
					defaultValue={
						props.data?.charges
							? props.data?.charges[i]?.shipment
							: ''
					}
					id={`${props.field.Property}_${i}_shipment`}
					name={`${props.field.Property}_${i}_shipment`}
					type="hidden"
				/>
				<input
					data-boolean={true}
					defaultValue={'true'}
					id={`${props.field.Property}_${i}_active`}
					name={`${props.field.Property}_${i}_active`}
					type="hidden"
				/>
				<div
					className={styles.row}
					id={`${props.field.Property}_${i}_contents`}
				>
					<div className={styles.deleteColumn}>
						<div>{i + 1}</div>
						<img
							className={styles.delete}
							onClick={() => {
								// eslint-disable-next-line no-alert
								if (confirm('Really delete charge?')) {
									(
										document.getElementById(
											`${props.field.Property}_${i}_active`
										) as HTMLInputElement
									).value = 'false'
									;(
										document.getElementById(
											`${props.field.Property}_${i}`
										) as HTMLInputElement
									).style.display = 'none'
									;(
										document.getElementById(
											`${props.field.Property}_${i}_contents`
										) as HTMLInputElement
									).innerHTML = ''

									if (props.onChange) {
										props.onChange(null)
									}
								}
							}}
							src={DeleteIcon}
							title="DELETE ROW"
						/>{' '}
					</div>
					<div className={styles.inputColumn}>
						<div className={styles.row}>
							<div className={styles.oneTenth}>
								<div className={styles.label}>
									CHARGE:{' '}
									<span
										className="hardRequired"
										title="REQUIRED IN ORDER TO SAVE"
									>
										*
									</span>
								</div>
								<input
									className={
										!props.isSearch ? 'hardRequired' : ''
									}
									data-hardrequired={true}
									defaultValue={
										props.data?.charges
											? props.data?.charges[i]?.charge
											: ''
									}
									id={`${props.field.Property}_${i}_charge`}
									name={`${props.field.Property}_${i}_charge`}
									onChange={props.onChange}
									readOnly={
										props.readOnly || props.field.ReadOnly
									}
									required={true}
									title={'CHARGE'}
									type="text"
								/>
							</div>
							{!props.isSearch ? (
								<>
									<div className={styles.oneTenth}>
										<div className={styles.label}>
											AMOUNT:{' '}
											<span
												className="hardRequired"
												title="REQUIRED IN ORDER TO SAVE"
											>
												*
											</span>
										</div>
										<input
											className={
												!props.isSearch
													? 'hardRequired'
													: ''
											}
											data-hardrequired={true}
											data-number={true}
											defaultValue={
												props.data?.charges
													? props.data?.charges[i]
														?.amount
													: ''
											}
											id={`${props.field.Property}_${i}_amount`}
											name={`${props.field.Property}_${i}_amount`}
											onChange={props.onChange}
											readOnly={
												props.readOnly ||
												props.field.ReadOnly
											}
											required={true}
											step={0.01}
											title={'AMOUNT'}
											type="number"
										/>
									</div>
								</>
							) : (
								<></>
							)}
						</div>
					</div>
				</div>
			</div>
		)
	}

	if (chargeCount == 0) {
		rows.push(
			<div className={`${styles.row} ${styles.emptyRow}`} key="noCharges">
				No charges.
			</div>
		)
	}

	return (
		<div
			className={`${styles.fieldOuterContainer} ${
				!props.isSearch ? styles.editForm : ''
			}`}
			id={props.field.Property ? `${props.field.Property}_container` : ''}
			key="addCharge"
		>
			{rows}
			{!(props.isSearch || props.readOnly) ? (
				<div className={styles.buttonRow}>
					<button type="button">
						<img
							alt="ADD CONTAINER"
							onClick={() => {
								setChargeCount(chargeCount + 1)
								if (props.onChange) {
									props.onChange(null)
									setTimeout(() => {
										if (props.onChange) {
											props.onChange(null)
										}
									}, 100)
									setTimeout(() => {
										if (props.onChange) {
											props.onChange(null)
										}
									}, 250)
									setTimeout(() => {
										if (props.onChange) {
											props.onChange(null)
										}
									}, 500)
									setTimeout(() => {
										if (props.onChange) {
											props.onChange(null)
										}
									}, 1000)
									setTimeout(() => {
										if (props.onChange) {
											props.onChange(null)
										}
									}, 2500)
								}
							}}
							src={AddIcon}
						/>
					</button>
				</div>
			) : (
				<></>
			)}
		</div>
	)
}
