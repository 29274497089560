import axios from 'axios'
import { ReactElement, useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'

import ACIIcon from '../../../../../assets/icons/aci.svg'
import AMSIcon from '../../../../../assets/icons/ams.svg'
import ArrivalIcon from '../../../../../assets/icons/arrival-notice.svg'
import DownArrowIcon from '../../../../../assets/icons/arrow-down.svg'
import BookingIcon from '../../../../../assets/icons/booking.svg'
import DocumentsIcon from '../../../../../assets/icons/documents.svg'
import EDIIcon from '../../../../../assets/icons/edi.svg'
import ShipmentHistoryIcon from '../../../../../assets/icons/history.svg'
import ISFIcon from '../../../../../assets/icons/isf.svg'
import NRAIcon from '../../../../../assets/icons/open-nra.svg'
import RemarksIcon from '../../../../../assets/icons/remarks.svg'
import ShipmentTrackingIcon from '../../../../../assets/icons/tracking.svg'
import {
	EditForm,
	ParsedFormData,
} from '../../../../../components/Form/EditForm'
import { NavTable } from '../../../../../components/NavTable'
import { ShipmentSummary } from '../../../../../components/ShipmentSummary'
import { GlobalTopTabItems } from '../../../../../components/Tabs'
import { DefaultLayout } from '../../../../../layouts/DefaultLayout'
import { APIHostname, HasPermission, LogOff } from '../../../../../Profile'
import Control from '../../../../../types/Control'
import { ACILayout } from '../../../../../types/data/ACI'
import { EscapeURIParameters } from '../../../../../Utils'
import styles from './ShipmentACI.module.scss'

export const ShipmentACI = () => {
	const { id } = useParams()

	const [shipment, setShipment]: any = useState(null)
	const [aciMessages, setACIMessages]: any = useState(null)
	const [aciMessageCount, setACIMessageCount]: any = useState(0)
	const [shipmentLoaded, setShipmentLoaded] = useState(false)
	const [aciMessagesLoaded, setACIMessagesLoaded] = useState(false)
	const [errorMessage, setErrorMessage] = useState(<></>)
	const initialRedirectMessage = sessionStorage['redirect-message']
	const [redirectMessage] = useState(initialRedirectMessage)

	// Vary based on query string.
	const location = useLocation()
	const urlParams = new URLSearchParams(location.search)
	if (!urlParams.get('limit')) {
		urlParams.set('limit', '25')
	}

	useEffect(() => {
		axios({
			data: {},
			headers: {
				Authorization: `Bearer ${localStorage['token']}`,
			},
			method: 'GET',
			url: `${APIHostname}/api/v1/shipments/${id}`,
		})
			.then((shipmentResponse: any) => {
				setErrorMessage(<></>)
				setShipment(shipmentResponse.data)
				setShipmentLoaded(true)
			})
			.catch((error: any) => {
				switch (error?.response?.status) {
				case 401:
					LogOff('token-expired')
					return
				case 403:
					setErrorMessage(
						<>
								Error: You do not have permission to edit
								shipment documents.
						</>
					)
					break
				default:
					if (error?.response?.data?.error) {
						setErrorMessage(
							error?.response?.data?.link ? (
								<Link to={error?.response?.data?.link}>
										Error:
									{error?.response?.data?.error}
								</Link>
							) : (
								<>{error?.response?.data?.error}</>
							)
						)
					} else {
						setErrorMessage(<>Error: Unknown error.</>)
							console.error(error) // eslint-disable-line
					}
				}
			})

		axios({
			data: {},
			headers: {
				Authorization: `Bearer ${localStorage['token']}`,
			},
			method: 'GET',
			url: `${APIHostname}/api/v1/shipments/${id}/aci_messages?${EscapeURIParameters(
				urlParams.toString()
			)}&view=browse`,
		})
			.then((response: any) => {
				setErrorMessage(<></>)
				setACIMessages(response.data.aci_messages)
				setACIMessageCount(response.data.aci_message_count)
				setACIMessagesLoaded(true)
			})
			.catch((error: any) => {
				if (error?.response?.status == 401) {
					LogOff('token-expired')
					return
				}
				console.error(error) // eslint-disable-line
				setErrorMessage(<>Error: Unknown error.</>)
			})
	}, [location])

	const arrivalNoticeTemplate = shipment?.arrival_notice_template
	const usDestination =
		shipment?.discharge_port?.toLowerCase().startsWith('us') ||
		shipment?.final_port_of_entry?.toLowerCase().startsWith('us')
	const caDestination =
		shipment?.discharge_port?.toLowerCase().startsWith('ca') ||
		shipment?.final_port_of_entry?.toLowerCase().startsWith('ca')
	const ShipmentLeftMenuItems = [
		{
			icon: BookingIcon,
			text: 'BOOKING',
			to: `/shipments/${id}`,
		},
		{
			count: shipment?.unread_remarks ?? null,
			icon: RemarksIcon,
			text: 'REMARKS',
			to: `/shipments/${id}/remarks`,
		},
		{
			disabled: !usDestination && !shipment?.ams_status,
			disabledReason: 'Shipment does not have US destination ports.',
			icon: AMSIcon,
			requiredRole: 'administrator||memberpartner||partner',
			text: 'AMS',
			to: `/shipments/${id}/ams`,
		},
		{
			disabled: !caDestination && !shipment?.aci_status,
			disabledReason: 'Shipment does not have CA destination ports.',
			icon: ACIIcon,
			requiredRole: 'administrator||memberpartner||partner',
			text: 'ACI',
			to: `/shipments/${id}/aci`,
		},
		{
			disabled: !usDestination,
			disabledReason: 'Shipment does not have US destination ports.',
			icon: ISFIcon,
			requiredRole: 'administrator||memberpartner||partner',
			text: 'ISF',
			to: `/shipments/${id}/isf`,
		},
		{
			icon: DocumentsIcon,
			text: 'DOCUMENTS',
			to: `/shipments/${id}/documents`,
		},
		{
			icon: DocumentsIcon,
			requiredRole: 'administrator||memberpartner||partner',
			text: 'HBL PRINT',
			to: `/shipments/${id}/hbl`,
		},
		{
			disabled: !usDestination,
			disabledReason: 'Shipment does not have US destination ports.',
			icon: NRAIcon,
			requiredRole: 'administrator||memberpartner||partner',
			text: 'NRA',
			to: `/shipments/${id}/nra`,
		},
		{
			disabled: !arrivalNoticeTemplate,
			disabledReason:
				'Destination partner does not have an Arrival Notice template.',
			icon: ArrivalIcon,
			requiredRole: 'administrator||memberpartner||partner',
			text: 'ARRIVAL NOTICE',
			to: `/shipments/${id}/arrival_notice${
				arrivalNoticeTemplate == 'default'
					? ''
					: `/${arrivalNoticeTemplate}`
			}`,
		},
		{
			icon: ShipmentTrackingIcon,
			requiredRole: 'administrator||memberpartner||partner||organization',
			text: 'SHIPMENT TRACKING',
			to: `/shipments/${id}/tracking`,
		},
		{
			icon: ShipmentHistoryIcon,
			text: 'SHIPMENT HISTORY',
			to: `/shipments/${id}/history`,
		},
	]

	const ShipmentBottomTabItems = [
		{
			icon: DownArrowIcon,
			text: `SHIPMENT #${id} ACI`,
			to: `/shipments/${id}/aci`,
		},
	]

	const ShipmentTabMenuItems = [
		{
			icon: EDIIcon,
			text: 'PREVIEW EDI',
			to: `${APIHostname}/api/v1/shipments/${id}/aci`,
		},
	]

	if (!aciMessagesLoaded || !shipmentLoaded) {
		if (errorMessage) {
			return (
				<DefaultLayout
					bottomTabItems={ShipmentBottomTabItems}
					leftMenuItems={ShipmentLeftMenuItems}
					showPrint={true}
					tabMenuItems={ShipmentTabMenuItems}
					topTabItems={GlobalTopTabItems}
				>
					<div className={styles.shipmentACIContainer}>
						<div className={styles.redirectMessage}>
							{redirectMessage}
						</div>
						<div className={styles.errorMessage}>
							{errorMessage}
						</div>
					</div>
				</DefaultLayout>
			)
		}

		return <div className="loading">Loading...</div>
	}

	const invalidMessages: ReactElement[] = []
	if (shipment) {
		if (!caDestination) {
			invalidMessages.push(
				<li>Canadian discharge port or final port of entry required</li>
			)
		}
		if (!shipment.shipper) {
			invalidMessages.push(<li>Shipper required</li>)
		} else {
			if (!shipment.shipper_name) {
				invalidMessages.push(<li>Shipper name required</li>)
			}
			if (!shipment.shipper_main_contact_name) {
				invalidMessages.push(
					<li>Shipper main contact name required</li>
				)
			}
			if (!shipment.shipper_address) {
				invalidMessages.push(<li>Shipper address required</li>)
			}
			if (!shipment.shipper_city) {
				invalidMessages.push(<li>Shipper city required</li>)
			}
			if (!shipment.shipper_postal_code) {
				invalidMessages.push(<li>Shipper postal code required</li>)
			}
			if (
				shipment.shipper_country?.toString().toUpperCase() == 'US' &&
				!shipment.shipper_state
			) {
				invalidMessages.push(<li>Shipper state required</li>)
			}
			if (!shipment.shipper_country) {
				invalidMessages.push(<li>Shipper country required</li>)
			}
		}
		if (!shipment.consignee) {
			invalidMessages.push(<li>Consignee required</li>)
		} else {
			if (!shipment.consignee_name) {
				invalidMessages.push(<li>Consignee name required</li>)
			}
			if (!shipment.consignee_main_contact_name) {
				invalidMessages.push(
					<li>Consignee main contact name required</li>
				)
			}
			if (!shipment.consignee_address) {
				invalidMessages.push(<li>Consignee address required</li>)
			}
			if (!shipment.consignee_city) {
				invalidMessages.push(<li>Consignee city required</li>)
			}
			if (!shipment.consignee_postal_code) {
				invalidMessages.push(<li>Consignee postal code required</li>)
			}
			if (
				shipment.consignee_country?.toString().toUpperCase() == 'US' &&
				!shipment.consignee_state
			) {
				invalidMessages.push(<li>Consignee state required</li>)
			}
			if (!shipment.consignee_country) {
				invalidMessages.push(<li>Consignee country required</li>)
			}
		}
		if (!shipment.notify) {
			if (!shipment.notify) {
				invalidMessages.push(<li>Notify party required</li>)
			}
		} else {
			if (!shipment.notify_name) {
				invalidMessages.push(<li>Notify party name required</li>)
			}
			if (!shipment.notify_main_contact_name) {
				invalidMessages.push(
					<li>Notify party main contact name required</li>
				)
			}
			if (!shipment.notify_address) {
				invalidMessages.push(<li>Notify party address required</li>)
			}
			if (!shipment.notify_city) {
				invalidMessages.push(<li>Notify party city required</li>)
			}
			if (!shipment.notify_postal_code) {
				invalidMessages.push(<li>Notify party postal code required</li>)
			}
			if (
				shipment.notify_country?.toString().toUpperCase() == 'US' &&
				!shipment.notify_state
			) {
				invalidMessages.push(<li>Notify party state required</li>)
			}
			if (!shipment.notify_country) {
				invalidMessages.push(<li>Notify party country required</li>)
			}
			const containerNumbers: { [key: string]: boolean } = {}
			shipment.containers?.forEach((container: any, index: number) => {
				if (containerNumbers[container.number]) {
					invalidMessages.push(
						<li>Container numbers cannot be duplicated</li>
					)
				}
				if (!container.marks || container.marks.length < 3) {
					invalidMessages.push(
						<li key="marks">
							Container #{index + 1} Marks must be at least 3
							characters
						</li>
					)
				}
				containerNumbers[container.number] = true
			})
		}
	}

	sessionStorage.removeItem('redirect-message')
	return (
		<DefaultLayout
			bottomTabItems={ShipmentBottomTabItems}
			leftMenuItems={ShipmentLeftMenuItems}
			showPrint={true}
			tabMenuItems={ShipmentTabMenuItems}
			topTabItems={GlobalTopTabItems}
		>
			<div className={styles.shipmentACIContainer}>
				<ShipmentSummary shipment={shipment}>
					{invalidMessages.length > 0 ? (
						<div className={styles.errorMessage}>
							<div className={styles.errorMessage}>
								The following required data must be corrected
								prior to submitting to ACI:
								<ul>{invalidMessages}</ul>
							</div>
						</div>
					) : (
						<></>
					)}
					<EditForm
						additionalButtons={
							shipment.aci_flag == 'C' ||
							shipment.aci_flag == 'R' ? (
									<input
										disabled={
											invalidMessages.length > 0 ||
										shipment.lock
										}
										onClick={() => {
											if (
											// eslint-disable-next-line
											!confirm('Really cancel this ACI?')
											) {
												return
											}

											axios({
												data: {},
												headers: {
													Authorization: `Bearer ${localStorage['token']}`,
													'Content-Type':
													'application/json',
												},
												method: 'PATCH',
												url: `${APIHostname}/api/v1/shipments/${id}/aci/cancel`,
											})
												.then((response: any) => {
													sessionStorage[
														'redirect-message'
													] = `ACI cancelled for shipment #${response.data.shipment_id}.`
													document.location.reload()
												})
												.catch((error: any) => {
													switch (
														error?.response?.status
													) {
													case 401:
														LogOff('token-expired')
														return
													case 403:
														setErrorMessage(
															<>
																Error: You do
																not have
																permission to
																edit ACI.
															</>
														)
														break
													default:
														if (
															error?.response
																?.data?.error
														) {
															setErrorMessage(
																error?.response
																	?.data
																	?.link ? (
																		<Link
																			to={
																				error
																					?.response
																					?.data
																					?.link
																			}
																		>
																		Error:
																			{
																				error
																					?.response
																					?.data
																					?.error
																			}
																		</Link>
																	) : (
																		<>
																			{
																				error
																					?.response
																					?.data
																					?.error
																			}
																		</>
																	)
															)
														} else {
															setErrorMessage(
																<>
																	Error:
																	Unknown
																	error.
																</>
															)
															console.error(error) // eslint-disable-line
														}
													}
												})
										}}
										type="button"
										value="CANCEL ACI"
									/>
								) : (
									<> </>
								)
						}
						data={shipment}
						disableSubmitButton={
							invalidMessages.length > 0 || shipment.lock
						}
						hasClearButton={false}
						layout={ACILayout}
						name="document-form"
						onSubmit={async (formData: FormData) => {
							const parsedFormData = await ParsedFormData(
								formData,
								[]
							)

							// Save ACI data
							parsedFormData['id'] = parseInt(id ?? '0')
							axios({
								data: parsedFormData,
								headers: {
									Authorization: `Bearer ${localStorage['token']}`,
									'Content-Type': 'application/json',
								},
								method: 'PATCH',
								url: `${APIHostname}/api/v1/shipments/${id}`,
							})
								.then(() => {
									axios({
										data: parsedFormData,
										headers: {
											Authorization: `Bearer ${localStorage['token']}`,
											'Content-Type': 'application/json',
										},
										method: 'PATCH',
										url: `${APIHostname}/api/v1/shipments/${id}/aci/submit`,
									})
										.then((response: any) => {
											sessionStorage[
												'redirect-message'
											] = `ACI submitted for shipment #${response.data.shipment_id}.`
											document.location.reload()
										})
										.catch((error: any) => {
											switch (error?.response?.status) {
											case 401:
												LogOff('token-expired')
												return
											case 403:
												setErrorMessage(
													<>
															Error: You do not
															have permission to
															edit ACI.
													</>
												)
												break
											default:
												if (
													error?.response?.data
														?.error
												) {
													setErrorMessage(
														error?.response
															?.data?.link ? (
																<Link
																	to={
																		error
																			?.response
																			?.data
																			?.link
																	}
																>
																	Error:
																	{
																		error
																			?.response
																			?.data
																			?.error
																	}
																</Link>
															) : (
																<>
																	{
																		error
																			?.response
																			?.data
																			?.error
																	}
																</>
															)
													)
												} else {
													setErrorMessage(
														<>
																Error: Unknown
																error.
														</>
													)
														console.error(error) // eslint-disable-line
												}
											}
										})
								})
								.catch((error: any) => {
									switch (error?.response?.status) {
									case 401:
										LogOff('token-expired')
										return
									case 403:
										setErrorMessage(
											<>
													Error: You do not have
													permission to edit ACI.
											</>
										)
										break
									default:
										if (error?.response?.data?.error) {
											setErrorMessage(
												error?.response?.data
													?.link ? (
														<Link
															to={
																error?.response
																	?.data?.link
															}
														>
															Error:
															{
																error?.response
																	?.data
																	?.error
															}
														</Link>
													) : (
														<>
															{
																error?.response
																	?.data
																	?.error
															}
														</>
													)
											)
										} else {
											setErrorMessage(
												<>Error: Unknown error.</>
											)
												console.error(error) // eslint-disable-line
										}
									}
								})
						}}
						previewAPIData={async (formData: FormData) => {
							const parsedFormData = await ParsedFormData(
								formData,
								[]
							)

							// Save ACI data
							parsedFormData['id'] = parseInt(id ?? '0')
							return {
								data: JSON.stringify(parsedFormData, null, 4),
								method: 'PATCH',
								url: `${APIHostname}/api/v1/shipments/${id}`,
							}
						}}
						readOnly={
							!HasPermission('shipment', 'update') ||
							shipment.lock
						}
						submitButtonLabel={
							shipment.aci_flag == 'C' || shipment.aci_flag == 'R'
								? 'RESUBMIT ACI'
								: 'SUBMIT ACI'
						}
					></EditForm>{' '}
				</ShipmentSummary>
				<div className={styles.redirectMessage}>{redirectMessage}</div>
				<div className={styles.errorMessage}>
					{errorMessage}
					{errorMessage}{' '}
					{!shipment?.active ? (
						<>This shipment has been deleted.</>
					) : (
						<></>
					)}
				</div>
				<div className={styles.navTable}>
					<NavTable
						columns={[
							{
								id: 'status',
								title: 'STATUS',
							},
							{
								id: 'message',
								title: 'RAW MESSAGE FROM CBSA',
							},
							{
								control: Control.LogFile,
								id: 'filename',
								title: 'FILENAME',
							},
							{
								control: Control.DateTime,
								id: 'created',
								title: 'MODIFIED',
							},
						]}
						count={aciMessageCount}
						data={aciMessages}
						filters={JSON.parse(urlParams.get('filters') ?? '[]')}
						includeDelete={HasPermission('shipment', 'delete')}
						includeExport={false}
						primaryDisplayColumn=""
						primaryKey=""
					/>
				</div>
			</div>
		</DefaultLayout>
	)
}
