import axios from 'axios'
import { MouseEvent, useEffect, useMemo, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'

import ACIIcon from '../../../../assets/icons/aci.svg'
import AMSIcon from '../../../../assets/icons/ams.svg'
import ArrivalIcon from '../../../../assets/icons/arrival-notice.svg'
import DownArrowIcon from '../../../../assets/icons/arrow-down.svg'
import BookingIcon from '../../../../assets/icons/booking.svg'
import ConfirmIcon from '../../../../assets/icons/confirm.svg'
import CopyIcon from '../../../../assets/icons/copy.svg'
import DeleteIcon from '../../../../assets/icons/delete.svg'
import DocumentsIcon from '../../../../assets/icons/documents.svg'
import ShipmentHistoryIcon from '../../../../assets/icons/history.svg'
import HotListActiveIcon from '../../../../assets/icons/hot-active.svg'
import HotListInactiveIcon from '../../../../assets/icons/hot-inactive.svg'
import ISFIcon from '../../../../assets/icons/isf.svg'
import LockedIcon from '../../../../assets/icons/locked.svg'
import NRAIcon from '../../../../assets/icons/open-nra.svg'
import RejectIcon from '../../../../assets/icons/reject.svg'
import RemarksIcon from '../../../../assets/icons/remarks.svg'
import SendIcon from '../../../../assets/icons/send-booking.svg'
import ShipmentTrackingIcon from '../../../../assets/icons/tracking.svg'
import UnlockedIcon from '../../../../assets/icons/unlocked.svg'
import { EditForm, ParsedFormData } from '../../../../components/Form/EditForm'
import { GlobalTopTabItems } from '../../../../components/Tabs'
import { DefaultLayout } from '../../../../layouts/DefaultLayout'
import {
	APIHostname,
	HasPermission,
	LogOff,
	ProfilePartnerID,
} from '../../../../Profile'
import { ShipmentEditLayout } from '../../../../types/data/Shipment'
import { RemoveEmpty } from '../../../../Utils'
import styles from './Shipment.module.scss'

export const Shipment = () => {
	const { id } = useParams()
	const [shipment, setShipment]: any = useState(null)
	const [hotlist, setHotList] = useState(false)
	const [lock, setLock] = useState(false)
	const [loaded, setLoaded] = useState(false)
	const [errorMessage, setErrorMessage] = useState(<></>)
	const initialRedirectMessage = sessionStorage['redirect-message']
	const [redirectMessage] = useState(initialRedirectMessage)

	// Vary based on query string.
	const location = useLocation()

	useEffect(() => {
		axios({
			data: {},
			headers: {
				Authorization: `Bearer ${localStorage['token']}`,
			},
			method: 'GET',
			url: `${APIHostname}/api/v1/shipments/${id}${location.search}`,
		})
			.then((response: any) => {
				setErrorMessage(<></>)
				setShipment(response.data)
				setHotList(response.data.hotlist)
				setLock(response.data.lock)
				setLoaded(true)
			})
			.catch((error: any) => {
				switch (error?.response?.status) {
				case 401:
					LogOff('token-expired')
					return
				case 403:
					setErrorMessage(
						<>
								Error: You do not have permission to view
								shipments.
						</>
					)
					break
				default:
					if (error?.response?.data?.error) {
						setErrorMessage(
							error?.response?.data?.link ? (
								<Link to={error?.response?.data?.link}>
										Error:
									{error?.response?.data?.error}
								</Link>
							) : (
								<>{error?.response?.data?.error}</>
							)
						)
					} else {
						setErrorMessage(<>Error: Unknown error.</>)
							console.error(error) // eslint-disable-line
					}
				}
			})
	}, [location])

	const arrivalNoticeTemplate = shipment?.arrival_notice_template
	const usDestination =
		shipment?.discharge_port?.toLowerCase().startsWith('us') ||
		shipment?.final_port_of_entry?.toLowerCase().startsWith('us')
	const caDestination =
		shipment?.discharge_port?.toLowerCase().startsWith('ca') ||
		shipment?.final_port_of_entry?.toLowerCase().startsWith('ca')
	const ShipmentLeftMenuItems = [
		{
			icon: BookingIcon,
			text: 'BOOKING',
			to: `/shipments/${id}`,
		},
		{
			count: shipment?.unread_remarks ?? null,
			icon: RemarksIcon,
			text: 'REMARKS',
			to: `/shipments/${id}/remarks`,
		},
		{
			disabled: !usDestination && !shipment?.ams_status,
			disabledReason: 'Shipment does not have US destination ports.',
			icon: AMSIcon,
			requiredRole: 'administrator||memberpartner||partner',
			text: 'AMS',
			to: `/shipments/${id}/ams`,
		},
		{
			disabled: !caDestination && !shipment?.aci_status,
			disabledReason: 'Shipment does not have CA destination ports.',
			icon: ACIIcon,
			requiredRole: 'administrator||memberpartner||partner',
			text: 'ACI',
			to: `/shipments/${id}/aci`,
		},
		{
			disabled: !usDestination,
			disabledReason: 'Shipment does not have US destination ports.',
			icon: ISFIcon,
			requiredRole: 'administrator||memberpartner||partner',
			text: 'ISF',
			to: `/shipments/${id}/isf`,
		},
		{
			icon: DocumentsIcon,
			text: 'DOCUMENTS',
			to: `/shipments/${id}/documents`,
		},
		{
			icon: DocumentsIcon,
			requiredRole: 'administrator||memberpartner||partner',
			text: 'HBL PRINT',
			to: `/shipments/${id}/hbl`,
		},
		{
			disabled: !usDestination,
			disabledReason: 'Shipment does not have US destination ports.',
			icon: NRAIcon,
			requiredRole: 'administrator||memberpartner||partner',
			text: 'NRA',
			to: `/shipments/${id}/nra`,
		},
		{
			disabled: !arrivalNoticeTemplate,
			disabledReason:
				'Destination partner does not have an Arrival Notice template.',
			icon: ArrivalIcon,
			requiredRole: 'administrator||memberpartner||partner',
			text: 'ARRIVAL NOTICE',
			to: `/shipments/${id}/arrival_notice${
				arrivalNoticeTemplate == 'default'
					? ''
					: `/${arrivalNoticeTemplate}`
			}`,
		},
		{
			icon: ShipmentTrackingIcon,
			requiredRole: 'administrator||memberpartner||partner||organization',
			text: 'SHIPMENT TRACKING',
			to: `/shipments/${id}/tracking`,
		},
		{
			icon: ShipmentHistoryIcon,
			text: 'SHIPMENT HISTORY',
			to: `/shipments/${id}/history`,
		},
	]

	const ShipmentBottomTabItems = [
		{
			icon: DownArrowIcon,
			text: `SHIPMENT #${id}`,
			to: `/shipments/${id}`,
		},
	]

	let ShipmentTabMenuItems = [
		{
			icon: hotlist ? HotListActiveIcon : HotListInactiveIcon,
			onClick: (e: MouseEvent<Element>) => {
				e.preventDefault()
				axios({
					data: {
						hotlist: !hotlist,
						id: shipment.id,
					},
					headers: {
						Authorization: `Bearer ${localStorage['token']}`,
						'Content-Type': 'application/json',
					},
					method: 'PATCH',
					url: `${APIHostname}/api/v1/shipments/${id}`,
				})
					.then((response: any) => {
						sessionStorage['redirect-message'] = `Shipment #${
							response.data.shipment_id
						} ${hotlist ? 'removed from' : 'added to'} hot list.`
						window.history.replaceState(
							{},
							'',
							`/shipments/${response.data.shipment_id}`
						)
						document.location.reload()
					})
					.catch((error: any) => {
						switch (error?.response?.status) {
						case 401:
							LogOff('token-expired')
							return
						case 403:
							setErrorMessage(
								<>
										Error: You do not have permission to
										edit shipments.
								</>
							)
							break
						default:
							if (error?.response?.data?.error) {
								setErrorMessage(
									error?.response?.data?.link ? (
										<Link
											to={error?.response?.data?.link}
										>
												Error:
											{error?.response?.data?.error}
										</Link>
									) : (
										<>{error?.response?.data?.error}</>
									)
								)
							} else {
								setErrorMessage(<>Error: Unknown error.</>)
									console.error(error) // eslint-disable-line
							}
						}
					})
			},
			requiredPermissions: ['shipment.update'],
			text: 'HOT',
			to: '#',
		},
		{
			icon: lock ? LockedIcon : UnlockedIcon,
			onClick: (e: MouseEvent<Element>) => {
				e.preventDefault()

				// eslint-disable-next-line
				const confirmation = confirm(
					`Really ${lock ? 'unlock' : 'lock'} shipment?`
				)
				if (!confirmation) {
					return
				}

				axios({
					data: {
						id: shipment.id,
						lock: !lock,
					},
					headers: {
						Authorization: `Bearer ${localStorage['token']}`,
						'Content-Type': 'application/json',
					},
					method: 'PATCH',
					url: `${APIHostname}/api/v1/shipments/${id}`,
				})
					.then((response: any) => {
						sessionStorage['redirect-message'] = `Shipment #${
							response.data.shipment_id
						} ${lock ? 'unlocked' : 'locked'}.`
						window.history.replaceState(
							{},
							'',
							`/shipments/${response.data.shipment_id}`
						)
						document.location.reload()
					})
					.catch((error: any) => {
						switch (error?.response?.status) {
						case 401:
							LogOff('token-expired')
							return
						case 403:
							setErrorMessage(
								<>
										Error: You do not have permission to
										lock or unlock shipments.
								</>
							)
							break
						default:
							if (error?.response?.data?.error) {
								setErrorMessage(
									error?.response?.data?.link ? (
										<Link
											to={error?.response?.data?.link}
										>
												Error:
											{error?.response?.data?.error}
										</Link>
									) : (
										<>{error?.response?.data?.error}</>
									)
								)
							} else {
								setErrorMessage(<>Error: Unknown error.</>)
									console.error(error) // eslint-disable-line
							}
						}
					})
			},
			requiredPermissions: ['shipment.update'],
			requiredRole: 'administrator',
			text: lock ? 'UNLOCK' : 'LOCK',
			to: '#',
		},
		{
			icon: CopyIcon,
			onClick: async (e: MouseEvent<Element>) => {
				e.preventDefault()

				if (document.getElementById('hardInvalidFields')?.innerHTML) {
					// eslint-disable-next-line
					alert(
						'Please correct the errors on the form before copying.'
					)
					window.scrollTo(0, document.body.scrollHeight)
					return
				}

				// eslint-disable-next-line
				const confirmation = confirm(`Really copy shipment?`)
				if (!confirmation) {
					return
				}

				const form = document.querySelector('form[name=\'shipment\']')
				const formData = new FormData(form as HTMLFormElement)
				const parsedFormData = await ParsedFormData(formData, [
					'containers',
					'pricings',
					'hazardous_cargoes',
					'nras_to_goal',
				])
				parsedFormData.copy = true
				axios({
					data: parsedFormData,
					headers: {
						Authorization: `Bearer ${localStorage['token']}`,
						'Content-Type': 'application/json',
					},
					method: 'POST',
					url: `${APIHostname}/api/v1/shipments`,
				})
					.then((response: any) => {
						sessionStorage[
							'redirect-message'
						] = `Shipment #${response.data.shipment_id} created.`
						window.history.replaceState(
							{},
							'',
							`/shipments/${response.data.shipment_id}`
						)
						document.location.reload()
					})
					.catch((error: any) => {
						switch (error?.response?.status) {
						case 401:
							LogOff('token-expired')
							return
						case 403:
							setErrorMessage(
								<>
										Error: You do not have permission to
										edit shipments.
								</>
							)
							break
						default:
							if (error?.response?.data?.error) {
								setErrorMessage(
									error?.response?.data?.link ? (
										<Link
											to={error?.response?.data?.link}
										>
												Error:
											{error?.response?.data?.error}
										</Link>
									) : (
										<>{error?.response?.data?.error}</>
									)
								)
							} else {
								setErrorMessage(<>Error: Unknown error.</>)
									console.error(error) // eslint-disable-line
							}
						}
					})
			},
			requiredPermissions: ['shipment.create'],
			text: 'COPY',
			to: '#',
		},
	]
	if (shipment?.active) {
		ShipmentTabMenuItems.push({
			icon: DeleteIcon,
			onClick: async (e: MouseEvent<Element>) => {
				e.preventDefault()

				// eslint-disable-next-line
				const confirmation = confirm(`Really delete shipment?`)
				if (!confirmation) {
					return
				}

				axios({
					headers: {
						Authorization: `Bearer ${localStorage['token']}`,
						'Content-Type': 'application/json',
					},
					method: 'DELETE',
					url: `${APIHostname}/api/v1/shipments/${id}`,
				})
					.then((response: any) => {
						sessionStorage[
							'redirect-message'
						] = `Shipment #${response.data.shipment_id} deleted.`
						window.history.replaceState({}, '', '/shipments')
						document.location.reload()
					})
					.catch((error: any) => {
						switch (error?.response?.status) {
						case 401:
							LogOff('token-expired')
							return
						case 403:
							setErrorMessage(
								<>
										Error: You do not have permission to
										delete shipments.
								</>
							)
							break
						default:
							if (error?.response?.data?.error) {
								setErrorMessage(
									error?.response?.data?.link ? (
										<Link
											to={error?.response?.data?.link}
										>
												Error:
											{error?.response?.data?.error}
										</Link>
									) : (
										<>{error?.response?.data?.error}</>
									)
								)
							} else {
								setErrorMessage(<>Error: Unknown error.</>)
									console.error(error) // eslint-disable-line
							}
						}
					})
			},
			requiredPermissions: ['shipment.delete'],
			text: 'DELETE',
			to: '#',
		})
	} else {
		ShipmentTabMenuItems.push({
			icon: DeleteIcon,
			onClick: async (e: MouseEvent<Element>) => {
				e.preventDefault()

				// eslint-disable-next-line
				const confirmation = confirm(`Really undelete shipment?`)
				if (!confirmation) {
					return
				}

				axios({
					headers: {
						Authorization: `Bearer ${localStorage['token']}`,
						'Content-Type': 'application/json',
					},
					method: 'POST',
					url: `${APIHostname}/api/v1/shipments/${id}/undelete`,
				})
					.then((response: any) => {
						sessionStorage[
							'redirect-message'
						] = `Shipment #${response.data.shipment_id} undeleted.`
						window.history.replaceState({}, '', '/shipments')
						document.location.reload()
					})
					.catch((error: any) => {
						switch (error?.response?.status) {
						case 401:
							LogOff('token-expired')
							return
						case 403:
							setErrorMessage(
								<>
										Error: You do not have permission to
										undelete shipments.
								</>
							)
							break
						default:
							if (error?.response?.data?.error) {
								setErrorMessage(
									error?.response?.data?.link ? (
										<Link
											to={error?.response?.data?.link}
										>
												Error:
											{error?.response?.data?.error}
										</Link>
									) : (
										<>{error?.response?.data?.error}</>
									)
								)
							} else {
								setErrorMessage(<>Error: Unknown error.</>)
									console.error(error) // eslint-disable-line
							}
						}
					})
			},
			requiredPermissions: ['shipment.delete'],
			text: 'UNDELETE',
			to: '#',
		})
	}

	if (shipment && (shipment?.status == 'inprogress' || !shipment?.status)) {
		ShipmentTabMenuItems.push({
			icon: SendIcon,
			onClick: async (e: MouseEvent<Element>) => {
				e.preventDefault()

				axios({
					data: {
						sent: true,
						status: 'unconfirmed',
					},
					headers: {
						Authorization: `Bearer ${localStorage['token']}`,
						'Content-Type': 'application/json',
					},
					method: 'PATCH',
					url: `${APIHostname}/api/v1/shipments/${id}`,
				})
					.then((response: any) => {
						sessionStorage[
							'redirect-message'
						] = `Shipment #${response.data.shipment_id} sent.`
						window.history.replaceState(
							{},
							'',
							`/shipments/${response.data.shipment_id}`
						)
						document.location.reload()
					})
					.catch((error: any) => {
						switch (error?.response?.status) {
						case 401:
							LogOff('token-expired')
							return
						case 403:
							setErrorMessage(
								<>
										Error: You do not have permission to
										edit shipments.
								</>
							)
							break
						default:
							if (error?.response?.data?.error) {
								setErrorMessage(
									error?.response?.data?.link ? (
										<Link
											to={error?.response?.data?.link}
										>
												Error:
											{error?.response?.data?.error}
										</Link>
									) : (
										<>{error?.response?.data?.error}</>
									)
								)
							} else {
								setErrorMessage(<>Error: Unknown error.</>)
									console.error(error) // eslint-disable-line
							}
						}
					})
			},
			requiredPermissions: ['shipment.update'],
			text: 'SEND BOOKING',
			to: '/shipments/send',
		})
	}

	if (shipment?.status == 'unconfirmed') {
		ShipmentTabMenuItems.push({
			icon: ConfirmIcon,
			onClick: async (e: MouseEvent<Element>) => {
				e.preventDefault()

				axios({
					data: {
						confirmed: true,
						status: 'accepted',
					},
					headers: {
						Authorization: `Bearer ${localStorage['token']}`,
						'Content-Type': 'application/json',
					},
					method: 'PATCH',
					url: `${APIHostname}/api/v1/shipments/${id}`,
				})
					.then((response: any) => {
						sessionStorage[
							'redirect-message'
						] = `Shipment #${response.data.shipment_id} confirmed.`
						window.history.replaceState(
							{},
							'',
							`/shipments/${response.data.shipment_id}`
						)
						document.location.reload()
					})
					.catch((error: any) => {
						switch (error?.response?.status) {
						case 401:
							LogOff('token-expired')
							return
						case 403:
							setErrorMessage(
								<>
										Error: You do not have permission to
										edit shipments.
								</>
							)
							break
						default:
							if (error?.response?.data?.error) {
								setErrorMessage(
									error?.response?.data?.link ? (
										<Link
											to={error?.response?.data?.link}
										>
												Error:
											{error?.response?.data?.error}
										</Link>
									) : (
										<>{error?.response?.data?.error}</>
									)
								)
							} else {
								setErrorMessage(<>Error: Unknown error.</>)
									console.error(error) // eslint-disable-line
							}
						}
					})
			},
			requiredPermissions: ['shipment.update'],
			text: 'CONFIRM',
			to: '#',
		})

		ShipmentTabMenuItems.push({
			icon: RejectIcon,
			onClick: async (e: MouseEvent<Element>) => {
				e.preventDefault()

				axios({
					data: {
						rejected: true,
						status: 'rejected',
					},
					headers: {
						Authorization: `Bearer ${localStorage['token']}`,
						'Content-Type': 'application/json',
					},
					method: 'PATCH',
					url: `${APIHostname}/api/v1/shipments/${id}`,
				})
					.then((response: any) => {
						sessionStorage[
							'redirect-message'
						] = `Shipment #${response.data.shipment_id} rejected.`
						window.history.replaceState(
							{},
							'',
							`/shipments/${response.data.shipment_id}`
						)
						document.location.reload()
					})
					.catch((error: any) => {
						switch (error?.response?.status) {
						case 401:
							LogOff('token-expired')
							return
						case 403:
							setErrorMessage(
								<>
										Error: You do not have permission to
										edit shipments.
								</>
							)
							break
						default:
							if (error?.response?.data?.error) {
								setErrorMessage(
									error?.response?.data?.link ? (
										<Link
											to={error?.response?.data?.link}
										>
												Error:
											{error?.response?.data?.error}
										</Link>
									) : (
										<>{error?.response?.data?.error}</>
									)
								)
							} else {
								setErrorMessage(<>Error: Unknown error.</>)
									console.error(error) // eslint-disable-line
							}
						}
					})
			},
			requiredPermissions: ['shipment.update'],
			text: 'REJECT',
			to: '/shipments/reject',
		})
	}

	ShipmentTabMenuItems = ShipmentTabMenuItems.reverse()

	const form = useMemo(
		() => (
			<EditForm
				customHardValidation={() => {
					const hiFields: string[] = []

					const partnerID = ProfilePartnerID()?.toString()
					if (partnerID) {
						if (
							partnerID !=
								(
									document.getElementById(
										'destination_partner'
									) as HTMLInputElement
								)?.value &&
							partnerID !=
								(
									document.getElementById(
										'origin_partner'
									) as HTMLInputElement
								)?.value
						) {
							hiFields.push(
								'EITHER DESTINATION PARTNER OR ORIGIN PARTNER MUST MATCH YOUR PARTNER'
							)
						}
					}
					return hiFields
				}}
				customSoftValidation={() => {
					const siFields: string[] = []

					const containerNumbersMap: { [key: string]: boolean } = {}
					const containerNumbers = document.querySelectorAll(
						'[id^=containers_][id$=_number]'
					)
					containerNumbers.forEach((number: any) => {
						if (!number.id.endsWith('_po_number')) {
							if (containerNumbersMap[number.value]) {
								siFields.push(
									'CONTAINER NUMBERS MUST BE UNIQUE'
								)
							}
							containerNumbersMap[number.value] = true
						}
					})

					let containersActiveCount = 0
					const containersActive = document.querySelectorAll(
						'[id^=containers_][id$=_active]'
					)
					containersActive.forEach((active: any) => {
						if (active.value == 'true') {
							containersActiveCount++
						}
					})
					if (containersActiveCount == 0) {
						siFields.push('CONTAINER(S) REQUIRED')
					}

					let nrasActiveCount = 0
					const nrasActive = document.querySelectorAll(
						'[id^=nras_][id$=_active]'
					)
					nrasActive.forEach((active: any) => {
						if (active.value == 'true') {
							nrasActiveCount++
						}
					})
					if (nrasActiveCount == 0) {
						siFields.push(
							'NRA TO GOAL UNDER SERVICE CONTRACT REQUIRED'
						)
					}

					return siFields
				}}
				data={shipment}
				layout={ShipmentEditLayout}
				name="shipment"
				onSubmit={async (formData: FormData) => {
					const parsedFormData = await ParsedFormData(formData, [
						'containers',
						'pricings',
						'hazardous_cargoes',
						'nras_to_goal',
					])

					/* eslint-disable no-alert */
					if (
						shipment.shipper &&
						parsedFormData.shipper != shipment.shipper
					) {
						if (
							shipment.ams_flag == 'C' ||
							shipment.ams_flag == 'R'
						) {
							alert(
								'Shipper has changed. AMS has already been submitted.\r\n\r\nPlease cancel the AMS before changing the shipper.\r\n\r\nDocuments will need to be regenerated.'
							)
							return
						}
						if (
							shipment.aci_flag == 'C' ||
							shipment.aci_flag == 'R'
						) {
							alert(
								'Shipper has changed. ACI has already been submitted.\r\n\r\nPlease cancel the ACI before changing the shipper.\r\n\r\nDocuments will need to be regenerated.'
							)
							return
						}
						if (
							!confirm(
								'Shipper has changed. Shipment documents will need to be regenerated. Continue?'
							)
						) {
							return
						}
					}
					if (
						shipment.consignee &&
						parsedFormData.consignee != shipment.consignee
					) {
						if (
							shipment.ams_flag == 'C' ||
							shipment.ams_flag == 'R'
						) {
							alert(
								'Consignee has changed. AMS has already been submitted.\r\n\r\nPlease cancel the AMS before changing the consignee.\r\n\r\nDocuments will need to be regenerated.'
							)
							return
						}
						if (
							shipment.aci_flag == 'C' ||
							shipment.aci_flag == 'R'
						) {
							alert(
								'Consignee has changed. ACI has already been submitted.\r\n\r\nPlease cancel the ACI before changing the consignee.\r\n\r\nDocuments will need to be regenerated.'
							)
							return
						}
						if (
							!confirm(
								'Consignee has changed. Shipment documents will need to be regenerated. Continue?'
							)
						) {
							return
						}
					}
					if (
						shipment.notify &&
						parsedFormData.notify != shipment.notify
					) {
						if (
							shipment.ams_flag == 'C' ||
							shipment.ams_flag == 'R'
						) {
							alert(
								'Notify party has changed. AMS has already been submitted.\r\n\r\nPlease cancel the AMS before changing the notify party.\r\n\r\nDocuments will need to be regenerated.'
							)
							return
						}
						if (
							shipment.aci_flag == 'C' ||
							shipment.aci_flag == 'R'
						) {
							alert(
								'Notify party has changed. ACI has already been submitted.\r\n\r\nPlease cancel the ACI before changing the notify party.\r\n\r\nDocuments will need to be regenerated.'
							)
							return
						}
						if (
							!confirm(
								'Notify party has changed. Shipment documents will need to be regenerated. Continue?'
							)
						) {
							return
						}
					}
					if (
						shipment.origin_partner &&
						parsedFormData.origin_partner != shipment.origin_partner
					) {
						if (
							shipment.ams_flag == 'C' ||
							shipment.ams_flag == 'R'
						) {
							alert(
								'Origin partner has changed. AMS has already been submitted.\r\n\r\nPlease cancel the AMS before changing the origin partner.\r\n\r\nDocuments will need to be regenerated.'
							)
							return
						}
						if (
							shipment.aci_flag == 'C' ||
							shipment.aci_flag == 'R'
						) {
							alert(
								'Origin partner has changed. ACI has already been submitted.\r\n\r\nPlease cancel the ACI before changing the origin partner.\r\n\r\nDocuments will need to be regenerated.'
							)
							return
						}
						if (
							!confirm(
								'Origin partner has changed. Shipment documents will need to be regenerated. Continue?'
							)
						) {
							return
						}
					}
					if (
						shipment.destination_partner &&
						parsedFormData.destination_partner !=
							shipment.destination_partner
					) {
						if (
							shipment.ams_flag == 'C' ||
							shipment.ams_flag == 'R'
						) {
							alert(
								'Destination partner has changed. AMS has already been submitted.\r\n\r\nPlease cancel the AMS before changing the destination partner.\r\n\r\nDocuments will need to be regenerated.'
							)
							return
						}
						if (
							shipment.aci_flag == 'C' ||
							shipment.aci_flag == 'R'
						) {
							alert(
								'Destination partner has changed. ACI has already been submitted.\r\n\r\nPlease cancel the ACI before changing the destination partner.\r\n\r\nDocuments will need to be regenerated.'
							)
							return
						}
						if (
							!confirm(
								'Destination partner has changed. Shipment documents will need to be regenerated. Continue?'
							)
						) {
							return
						}
					}
					if (shipment.hbl && parsedFormData.hbl != shipment.hbl) {
						if (
							shipment.ams_flag == 'C' ||
							shipment.ams_flag == 'R'
						) {
							alert(
								'HBL has changed. AMS has already been submitted.\r\n\r\nPlease cancel the AMS before changing the HBL.\r\n\r\nDocuments will need to be regenerated.'
							)
							return
						}
						if (
							shipment.aci_flag == 'C' ||
							shipment.aci_flag == 'R'
						) {
							alert(
								'HBL has changed. ACI has already been submitted.\r\n\r\nPlease cancel the ACI before changing the HBL.\r\n\r\nDocuments will need to be regenerated.'
							)
							return
						}
						if (
							!confirm(
								'HBL has changed. Shipment documents will need to be regenerated. Continue?'
							)
						) {
							return
						}
					}
					/* eslint-enable no-alert */

					axios({
						data: parsedFormData,
						headers: {
							Authorization: `Bearer ${localStorage['token']}`,
							'Content-Type': 'application/json',
						},
						method: 'POST',
						url: `${APIHostname}/api/v1/shipments/${id}`,
					})
						.then(() => {
							sessionStorage[
								'redirect-message'
							] = `Shipment #${id} updated.`
							window.history.replaceState({}, '', '/shipments')
							document.location.reload()
						})
						.catch((error: any) => {
							switch (error?.response?.status) {
							case 401:
								LogOff('/#token-expired')
								return
							case 403:
								setErrorMessage(
									<>
											Error: You do not have permission to
											edit shipments.
									</>
								)
								break
							default:
								if (error?.response?.data?.error) {
									setErrorMessage(
										<>
												Error:
											{error?.response?.data?.error}
										</>
									)
								} else {
									setErrorMessage(
										<>Error: Unknown error.</>
									)
										console.error(error) // eslint-disable-line
								}
							}
						})
				}}
				previewAPIData={async (formData: FormData) => {
					let parsedFormData = await ParsedFormData(formData, [
						'containers',
						'pricings',
						'hazardous_cargoes',
						'nras_to_goal',
					])
					parsedFormData = RemoveEmpty(parsedFormData)

					return {
						data: JSON.stringify(parsedFormData, null, 4),
						method: 'POST',
						url: `${APIHostname}/api/v1/shipments/${id}`,
					}
				}}
				readOnly={
					!HasPermission('shipment', 'update') ||
					shipment?.lock ||
					!shipment?.active
				}
				warnOnNavigate={true}
			/>
		),
		[shipment]
	)

	if (!loaded) {
		if (errorMessage) {
			return (
				<DefaultLayout
					bottomTabItems={ShipmentBottomTabItems}
					leftMenuItems={ShipmentLeftMenuItems}
					showPrint={true}
					tabMenuItems={ShipmentTabMenuItems}
					topTabItems={GlobalTopTabItems}
				>
					<div className={styles.shipmentContainer}>
						<div className={styles.redirectMessage}>
							{redirectMessage}
						</div>
						<div className={styles.errorMessage}>
							{errorMessage}
						</div>
					</div>
				</DefaultLayout>
			)
		}

		return <div className="loading">Loading...</div>
	}

	sessionStorage.removeItem('redirect-message')

	return (
		<DefaultLayout
			bottomTabItems={ShipmentBottomTabItems}
			leftMenuItems={ShipmentLeftMenuItems}
			showPrint={true}
			tabMenuItems={ShipmentTabMenuItems}
			topTabItems={GlobalTopTabItems}
		>
			<div className={styles.shipmentContainer}>
				<div className={styles.redirectMessage}>{redirectMessage}</div>
				<div className={styles.errorMessage}>
					{errorMessage}{' '}
					{!shipment?.active ? (
						<>This shipment has been deleted.</>
					) : (
						<></>
					)}
				</div>

				{form}
			</div>
		</DefaultLayout>
	)
}
