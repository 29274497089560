import axios from 'axios'
import { useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'

import AgentAccessIcon from '../../../../../assets/icons/agent-access.svg'
import DownArrowIcon from '../../../../../assets/icons/arrow-down.svg'
import ChainIcon from '../../../../../assets/icons/chain.svg'
import DocumentsIcon from '../../../../../assets/icons/documents.svg'
import PayBalanceIcon from '../../../../../assets/icons/goal-pay-balance.svg'
import PayRequestMoneyIcon from '../../../../../assets/icons/goal-pay-request-money.svg'
import PaySendMoneyIcon from '../../../../../assets/icons/goal-pay-send-money.svg'
import PayTransactionsIcon from '../../../../../assets/icons/goal-pay-transactions.svg'
import PayIncomingRequestsIcon from '../../../../../assets/icons/goal-pay-transfer-money.svg'
import PartnerHistoryIcon from '../../../../../assets/icons/organization-history.svg'
import PartnerInfoIcon from '../../../../../assets/icons/organization-info.svg'
import PartnerUsersIcon from '../../../../../assets/icons/organization-users.svg'
import { LeftMenuItem } from '../../../../../components/LeftMenu'
import { NavTable } from '../../../../../components/NavTable'
import { PartnerSummary } from '../../../../../components/PartnerSummary'
import { GlobalTopTabItems } from '../../../../../components/Tabs'
import { DefaultLayout } from '../../../../../layouts/DefaultLayout'
import {
	APIHostname,
	HasPermission,
	HasRole,
	LogOff,
} from '../../../../../Profile'
import Control from '../../../../../types/Control'
import { EscapeURIParameters } from '../../../../../Utils'
import styles from './PartnerHistory.module.scss'

export const PartnerHistory = () => {
	const { id } = useParams()
	const [history, setHistory]: any = useState(null)
	const [historyCount, setHistoryCount] = useState(0)
	const [errorMessage, setErrorMessage] = useState(<></>)
	const [partnerLoaded, setPartnerLoaded]: any = useState(false)
	const [historyLoaded, setHistoryLoaded]: any = useState(false)
	const [payRequestCount, setPayRequestCount] = useState(0)
	const [countsLoaded, setCountsLoaded] = useState(false)
	const [partner, setPartner]: any = useState(null)
	const initialRedirectMessage = sessionStorage['redirect-message']
	const [redirectMessage] = useState(initialRedirectMessage)

	// Vary based on query string.
	const location = useLocation()
	const urlParams = new URLSearchParams(location.search)
	if (!urlParams.get('limit')) {
		urlParams.set('limit', '25')
	}

	useEffect(() => {
		axios({
			data: {},
			headers: {
				Authorization: `Bearer ${localStorage['token']}`,
			},
			method: 'GET',
			url: `${APIHostname}/api/v1/partners/${id}${location.search}`,
		})
			.then((response: any) => {
				setErrorMessage(<></>)
				setPartner(response.data)
				setPartnerLoaded(true)

				axios({
					data: {},
					headers: {
						Authorization: `Bearer ${localStorage['token']}`,
					},
					method: 'GET',
					url: `${APIHostname}/api/v1/partners/${id}/pay_counts`,
				})
					.then((response: any) => {
						setErrorMessage(<></>)
						setPayRequestCount(response.data.pay_request_count)
						setCountsLoaded(true)
					})
					.catch((error: any) => {
						switch (error?.response?.status) {
						case 401:
							LogOff('token-expired')
							return
						case 403:
							setCountsLoaded(true)
							setErrorMessage(
								<>
										Error: You do not have permission to
										view GOAL pay requests.
								</>
							)
							break
						case 404:
							setCountsLoaded(true)
							break
						default:
							if (error?.response?.data?.error) {
								setErrorMessage(
									error?.response?.data?.link ? (
										<Link
											to={error?.response?.data?.link}
										>
												Error:
											{error?.response?.data?.error}
										</Link>
									) : (
										<>{error?.response?.data?.error}</>
									)
								)
							} else {
								setErrorMessage(<>Error: Unknown error.</>)
									console.error(error) // eslint-disable-line
							}
						}
					})
			})
			.catch((error: any) => {
				switch (error?.response?.status) {
				case 401:
					LogOff('token-expired')
					return
				case 403:
					setErrorMessage(
						<>
								Error: You do not have permission to view
								partners.
						</>
					)
					break
				default:
					if (error?.response?.data?.error) {
						setErrorMessage(
							error?.response?.data?.link ? (
								<Link to={error?.response?.data?.link}>
										Error:
									{error?.response?.data?.error}
								</Link>
							) : (
								<>{error?.response?.data?.error}</>
							)
						)
					} else {
						setErrorMessage(<>Error: Unknown error.</>)
							console.error(error) // eslint-disable-line
					}
				}
			})

		axios({
			data: {},
			headers: {
				Authorization: `Bearer ${localStorage['token']}`,
			},
			method: 'GET',
			url: `${APIHostname}/api/v1/partners/${id}/history?${EscapeURIParameters(
				urlParams.toString()
			)}`,
		})
			.then((response: any) => {
				setErrorMessage(<></>)
				setHistory(response.data.historyRecords)
				setHistoryCount(response.data.historyRecord_count)
				setHistoryLoaded(true)
			})
			.catch((error: any) => {
				switch (error?.response?.status) {
				case 401:
					LogOff('token-expired')
					return
				case 403:
					setErrorMessage(
						<>
								Error: You do not have permission to view
								partners.
						</>
					)
					break
				default:
					if (error?.response?.data?.error) {
						setErrorMessage(
							error?.response?.data?.link ? (
								<Link to={error?.response?.data?.link}>
										Error:
									{error?.response?.data?.error}
								</Link>
							) : (
								<>{error?.response?.data?.error}</>
							)
						)
					} else {
						setErrorMessage(<>Error: Unknown error.</>)
							console.error(error) // eslint-disable-line
					}
				}
			})
	}, [location])

	const PartnerLeftMenuItems: LeftMenuItem[] = [
		{
			icon: PartnerInfoIcon,
			requiredPermissions: ['partner.read'],
			text: 'PARTNER INFO',
			to: `/partners/${id}`,
		},
		{
			icon: AgentAccessIcon,
			requiredPermissions: ['organization.manageaccess'],
			text: 'ORGANIZATION ACCESS',
			to: `/partners/${id}/organization_access`,
		},
	]

	if (!partner?.goal_member) {
		PartnerLeftMenuItems.push({
			icon: AgentAccessIcon,
			requiredPermissions: ['partner.manageaccess'],
			requiredRole: 'administrator',
			text: 'PARTNER ACCESS',
			to: `/partners/${id}/partner_access`,
		})
	}
	PartnerLeftMenuItems.push({
		icon: DocumentsIcon,
		requiredPermissions: ['partner.read'],
		text: 'PARTNER DOCUMENTS',
		to: `/partners/${id}/documents`,
	})
	PartnerLeftMenuItems.push({
		icon: ChainIcon,
		requiredPermissions: ['shipment.read'],
		text: 'LINKED SHIPMENTS',
		to: `/partners/${id}/shipments`,
	})
	PartnerLeftMenuItems.push({
		icon: PartnerUsersIcon,
		requiredPermissions: ['user.read'],
		text: 'PARTNER USERS',
		to: `/partners/${id}/users`,
	})
	PartnerLeftMenuItems.push({
		icon: PartnerHistoryIcon,
		requiredPermissions: ['partner.read'],
		text: 'PARTNER HISTORY',
		to: `/partners/${id}/history`,
	})

	if (HasRole('administrator')) {
		if (
			HasPermission('pay', 'viewbalance') ||
			HasPermission('pay', 'sendmoney') ||
			HasPermission('pay', 'requestmoney')
		) {
			PartnerLeftMenuItems.push(
				{
					disabled: true,
					requiredPermissions: [],
					text: 'ADMINISTRATOR OPTIONS',
					to: '',
				},
				{
					icon: PayBalanceIcon,
					requiredPermissions: ['pay.viewbalance'],
					text: 'BALANCE',
					to: `/partners/${id}/pay/balance`,
				},
				{
					icon: PaySendMoneyIcon,
					requiredPermissions: ['pay.sendmoney'],
					requiredRole: 'administrator',
					text: 'DEPOSIT MONEY',
					to: `/partners/${id}/pay/deposit`,
				},
				{
					icon: PayRequestMoneyIcon,
					requiredPermissions: ['pay.requestmoney'],
					requiredRole: 'administrator',
					text: 'WITHDRAW MONEY',
					to: `/partners/${id}/pay/withdraw`,
				},
				{
					icon: PayRequestMoneyIcon,
					requiredPermissions: ['pay.requestmoney'],
					text: 'REQUEST MONEY',
					to: `/partners/${id}/pay/request`,
				},
				{
					icon: PaySendMoneyIcon,
					requiredPermissions: ['pay.sendmoney'],
					text: 'SEND MONEY',
					to: `/partners/${id}/pay/send`,
				},
				{
					count: payRequestCount,
					icon: PayIncomingRequestsIcon,
					requiredPermissions: ['pay.sendmoney'],
					text: 'INCOMING PAYMENT REQUESTS',
					to: `/partners/${id}/pay/incoming_requests`,
				},
				{
					icon: PayTransactionsIcon,
					requiredPermissions: ['pay.viewbalance'],
					text: 'TRANSACTIONS',
					to: `/partners/${id}/pay/transactions`,
				}
			)
		}
	}

	const PartnerHistoryBottomTabItems = [
		{
			icon: DownArrowIcon,
			text: `PARTNER #${id} HISTORY`,
			to: `/partners/${id}/history`,
		},
	]

	if (!partnerLoaded || !historyLoaded || !countsLoaded) {
		if (errorMessage) {
			return (
				<DefaultLayout
					bottomTabItems={PartnerHistoryBottomTabItems}
					leftMenuItems={PartnerLeftMenuItems}
					showPrint={true}
					tabMenuItems={[]}
					topTabItems={GlobalTopTabItems}
				>
					<div className={styles.partnerHistoryContainer}>
						<div className={styles.redirectMessage}>
							{redirectMessage}
						</div>
						<div className={styles.errorMessage}>
							{errorMessage}
						</div>
					</div>
				</DefaultLayout>
			)
		}

		return <div className="loading">Loading...</div>
	}

	sessionStorage.removeItem('redirect-message')
	return (
		<DefaultLayout
			bottomTabItems={PartnerHistoryBottomTabItems}
			leftMenuItems={PartnerLeftMenuItems}
			showPrint={true}
			tabMenuItems={[]}
			topTabItems={GlobalTopTabItems}
		>
			<div className={styles.partnerHistoryContainer}>
				<div className={styles.redirectMessage}>{redirectMessage}</div>
				<div className={styles.errorMessage}>
					{errorMessage}{' '}
					{!partner?.active ? (
						<>This partner has been deleted.</>
					) : (
						<></>
					)}
				</div>

				<PartnerSummary partner={partner} />

				<div className={styles.navTable}>
					<NavTable
						columns={[
							{
								control: Control.Hyperlink,
								id: 'link',
								title: 'Link',
							},
							{
								id: 'version',
								title: 'Version',
							},
							{
								id: 'action',
								title: 'Action',
							},
							{
								id: 'element',
								title: 'Element',
							},
							{
								id: 'original',
								title: 'Original Value',
							},
							{
								id: 'new',
								title: 'New Value',
							},
							{
								control: Control.User,
								id: 'created_by',
								title: 'USER',
							},
							{
								control: Control.DateTime,
								id: 'created',
								title: 'Date/Time',
							},
						]}
						count={historyCount}
						data={history}
						filters={JSON.parse(urlParams.get('filters') ?? '[]')}
						includeDelete={false}
						primaryDisplayColumn=""
						primaryKey=""
					/>
				</div>
			</div>
		</DefaultLayout>
	)
}
