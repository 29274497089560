import axios from 'axios'
import { getCountriesForTimezone } from 'countries-and-timezones'
import { MouseEvent, ReactElement, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

import CalendarIcon from '../../assets/icons/calendar.svg'
import ConfirmIcon from '../../assets/icons/confirm.svg'
import DataCorrectionIcon from '../../assets/icons/data-correction.svg'
import DeleteIcon from '../../assets/icons/delete.svg'
import DownloadIcon from '../../assets/icons/download.svg'
import ExportIcon from '../../assets/icons/excel.svg'
import HotListActiveIcon from '../../assets/icons/hot-active.svg'
import LockedIcon from '../../assets/icons/locked.svg'
import NextIcon from '../../assets/icons/next.svg'
import OrganizationAccessIcon from '../../assets/icons/organization-info.svg'
import PreviousIcon from '../../assets/icons/previous.svg'
import RejectIcon from '../../assets/icons/reject.svg'
import { APIHostname, HasRole, LogOff } from '../../Profile'
import { Column } from '../../types/Column'
import Control from '../../types/Control'
import { EncodedStringify, Filter } from '../../types/Filter'
import { Sort } from '../../types/Sort'
import { EscapeURIParameters, RandomID } from '../../Utils'
import { ShipmentStatuses } from '../Form/Controls/ShipmentStatus'
import { MediaType } from '../MediaType'
import styles from './NavTable.module.scss'

export const NavTable = (props: NavTableProps) => {
	// Apply sorting
	const location = useLocation()
	const urlParams = new URLSearchParams(location.search)
	let sorts: Sort[] = JSON.parse(urlParams.get('sorts') ?? '[]')
	const sortFieldsOrder: { [field: string]: number } = {}
	const sortFields: { [field: string]: string } = {}
	sorts.forEach((sort: Sort, index: number) => {
		sortFieldsOrder[sort.field] = index
		sortFields[sort.field] = sort.order
	})
	const limit = parseInt(urlParams.get('limit') ?? '25')
	const offset = parseInt(urlParams.get('offset') ?? '0')
	const [bulkSelected, setBulkSelected] = useState(false)

	useEffect(() => {
		const checkboxes = document.querySelectorAll('input[type=checkbox]')
		checkboxes.forEach((checkbox: any) => {
			(checkbox as HTMLInputElement).checked = false
		})

		setBulkSelected(false)
	}, [location])

	const headers: ReactElement[] = []
	if (props.includeDelete || props.includeOrganizationAccess) {
		headers.push(<th key="bulk"></th>)
	}
	props.columns?.forEach((column: Column, index: number) => {
		const children = column.children ? column.children : [column]
		headers.push(
			<th
				key={`column${index}`}
				title="Click field titles to sort. Hold 'Ctrl' when clicking to sort by multiple fields."
			>
				{children.map((subColumn: Column) => {
					let subColumnID = subColumn.id!
					switch (subColumn.control) {
					case Control.Carrier:
					case Control.Organization:
					case Control.Partner:
						subColumnID += '_name'
						break
					}

					return (
						<div
							key={`subcolumn${subColumn.id}`}
							onClick={(e: React.MouseEvent) => {
								if (!sortFields[subColumnID]) {
									sorts.push({
										field: subColumnID,
										order: 'ASC',
									})
									sortFieldsOrder[subColumnID] =
										sorts.length - 1
								} else {
									switch (
										sortFields[subColumnID].toUpperCase()
									) {
									case 'ASC':
										sorts[
											sortFieldsOrder[subColumnID]
										].order = 'DESC'
										break
									case 'DESC':
										sorts.splice(
											sortFieldsOrder[subColumnID],
											1
										)
										break
									}
								}
								if (!e.ctrlKey) {
									sorts = sorts[sortFieldsOrder[subColumnID]]
										? [sorts[sortFieldsOrder[subColumnID]]]
										: []
								}
								if (sorts.length) {
									urlParams.set(
										'sorts',
										EncodedStringify(sorts)
									)
								} else {
									urlParams.delete('sorts')
								}
								urlParams.delete('offset')
								document.location.replace(
									`${document.location.origin}${
										location.pathname
									}${
										urlParams.size > 0 ? '?' : ''
									}${EscapeURIParameters(
										urlParams.toString()
									)}`
								)
							}}
						>
							{subColumn.icon ? (
								<img
									className={styles.half}
									src={subColumn.icon}
								/>
							) : (
								subColumn.title
							)}
							{sortFields[subColumnID]
								? ` ${
									sortFields[subColumnID] == 'ASC'
										? '\u25B2'
										: '\u25BC'
								  } ${
									sorts.length > 1
										? (
											sortFieldsOrder[
												subColumnID
											] + 1
											  ).toString()
										: ''
								  }`
								: ''}
						</div>
					)
				})}
			</th>
		)
	})

	const filtersMap: { [key: string]: string } = {}
	let wildcard = ''
	props.filters.forEach((filter: Filter) => {
		if (filter.aggregate?.toLowerCase() == 'or') {
			filter.children?.forEach((subFilter: Filter) => {
				if (
					subFilter.operator?.toLowerCase() == 'contains' ||
					subFilter.operator?.toLowerCase() == 'eq'
				) {
					if (subFilter.field == '*') {
						wildcard = subFilter.value ?? ''
					} else {
						filtersMap[subFilter.field ?? ''] =
							subFilter.value ?? ''
					}
				}
			})
		}
		if (
			filter.operator?.toLowerCase() == 'contains' ||
			filter.operator?.toLowerCase() == 'eq'
		) {
			if (filter.field == '*') {
				wildcard = filter.value ?? ''
			} else {
				filtersMap[filter.field ?? ''] = filter.value ?? ''
			}
		}
	})
	const renderValue = (row: any, column: Column) => {
		switch (column.control) {
		case Control.ApprovePay:
			if (!HasRole('administrator', 'memberpartner', 'partner')) {
				return <></>
			}

			if (row['cleared']) {
				return <></>
			}

			return (
				<input
					className={`pseudoSubmit ${styles.accept}`}
					onClick={(e: MouseEvent<Element>) => {
						e.preventDefault()
						// eslint-disable-next-line no-alert
						if (confirm('Really approve payment?')) {
							axios({
								data: {
									accept: true,
								},
								headers: {
									Authorization: `Bearer ${localStorage['token']}`,
									'Content-Type': 'application/json',
								},
								method: 'PATCH',
								url: `${APIHostname}/api/v1/partners/${row['origin']}/pay_requests/${row['id']}`,
							})
								.then(() => {
									sessionStorage[
										'redirect-message'
									] = `Pay request #${row['id']} accepted.`
									document.location.reload()
								})
								.catch((error: any) => {
									switch (error?.response?.status) {
									case 401:
										LogOff('token-expired')
										return
									default:
												console.error(error) // eslint-disable-line
									}
								})
						}
					}}
					type="button"
					value="ACCEPT"
				/>
			)

			break
		case Control.Boolean:
		case Control.Checkbox:
			if (column.id) {
				return row[column.id] ? 'true' : 'false'
			}
			break
		case Control.Carrier:
			if (column.id && row[column.id] && row[`${column.id}_name`]) {
				return (
					<>
						<Link
							rel="noreferrer"
							target="_blank"
							to={`/carriers/${row[column.id]}`}
						>
							<img
								className={styles.half}
								onError={(event: any) => {
									event.target.style.display = 'none'
								}}
								onLoad={(event: any) => {
									event.target.style.display = ''
								}}
								src={
									row[`${column.id}_image_preview`]
										? row[`${column.id}_image_preview`]
										: ''
								}
							/>
						</Link>

						{highlightSearch(
							`[${row[`${column.id}_scac`]}] ${
								row[`${column.id}_name`]
							}`,
							wildcard,
							[
								filtersMap[column.id],
								filtersMap[`${column.id}_name`],
							]
						)}
					</>
				)
			}
			break
		case Control.ConfirmedRejected:
			if (column.id) {
				if (row['status'] == 'rejected') {
					return (
						<span className={styles.rejected}>
							<img src={RejectIcon} /> REJECTED BOOKING
						</span>
					)
				} else {
					if (row['confirmed']) {
						return (
							<span className={styles.confirmed}>
								<img src={ConfirmIcon} /> ACCEPTED BOOKING
							</span>
						)
					}
				}
				return <></>
			}
			break
		case Control.Country:
			if (column.id && row[column.id] && row[column.id].length == 2) {
				return (
					<>
						{' '}
						<span
							className={`fi fi-${row[column.id]
								.substring(0, 2)
								.toLowerCase()} ${styles.flag}`}
						></span>
						{highlightSearch(row[column.id], wildcard, [
							filtersMap[column.id],
						])}{' '}
					</>
				)
			}
			break
		case Control.DataCorrection:
			if (column.id && row[column.id] == true) {
				return (
					<>
						<img
							className={styles.full}
							src={DataCorrectionIcon}
							title="DATA CORRECTION REQUIRED"
						/>
					</>
				)
			}
			break
		case Control.Date: {
			if (column.id && row[column.id]) {
				const now = new Date()
				const d = new Date(row[column.id])
				if (
					now.getFullYear() == d.getUTCFullYear() &&
						now.getMonth() == d.getUTCMonth() &&
						now.getDate() == d.getUTCDate()
				) {
					return (
						<span className={styles.date}>
							<img
								className={styles.half}
								src={CalendarIcon}
							/>
								TODAY
						</span>
					)
				}

				return (
					<>
						<span className={styles.date}>
							<img
								className={styles.half}
								src={CalendarIcon}
							/>
							{d.getUTCMonth() + 1}/
							{d.getUTCDate().toString().padStart(2, '0')}/
							{d.getUTCFullYear()}
						</span>
					</>
				)
			}
			break
		}
		case Control.DateTime: {
			if (column.id && row[column.id]) {
				const now = new Date()
				const d = new Date(row[column.id])
				if (
					now.getFullYear() == d.getFullYear() &&
						now.getMonth() == d.getMonth() &&
						now.getDate() == d.getDate()
				) {
					return (
						<span className={styles.date}>
							<img
								className={styles.half}
								src={CalendarIcon}
							/>
								TODAY {d.toLocaleTimeString()}
						</span>
					)
				}

				return (
					<span className={styles.date}>
						<img className={styles.half} src={CalendarIcon} />
						{d.getMonth() + 1}/
						{d.getDate().toString().padStart(2, '0')}/
						{d.getFullYear()}
					</span>
				)
			}
			break
		}
		case Control.Download:
			return (
				<a
					download={true}
					href={row['download_link']}
					rel="noreferrer"
					target="_blank"
				>
					<img
						className={`${styles.download} ${styles.full}`}
						src={DownloadIcon}
					/>
				</a>
			)
		case Control.Email:
			if (column.id && row[column.id]) {
				return (
					<a href={`mailto:${row[column.id]}`}>
						{highlightSearch(row[column.id], wildcard, [
							filtersMap[column.id],
						])}
					</a>
				)
			}
			break
		case Control.FileName:
			if (column.id && row[column.id]) {
				if (row['inline_link']) {
					return (
						<div className={styles.fileIcon}>
							<a
								download={true}
								href={row['download_link']}
								rel="noreferrer"
								target="_blank"
							>
								<MediaType MediaType={row['media_type']} />
							</a>{' '}
							<a
								href={`${row['inline_link']}`}
								rel="noreferrer"
								target="_blank"
							>
								{row[column.id]}
							</a>
						</div>
					)
				}

				if (row['download_link']) {
					return (
						<div className={styles.fileIcon}>
							<a
								download={true}
								href={row['download_link']}
								rel="noreferrer"
								target="_blank"
							>
								<MediaType MediaType={row['media_type']} />
							</a>{' '}
							<a
								href={`${row['download_link']}`}
								rel="noreferrer"
								target="_blank"
							>
								{row[column.id]}
							</a>
						</div>
					)
				}

				return (
					<div className={styles.fileIcon}>
						<MediaType MediaType={row['media_type']} />
						{row[column.id]}
					</div>
				)
			}
			break
		case Control.FileSize:
			if (column.id && row[column.id]) {
				if (row[column.id] > 1099511627776) {
					const numTB =
							Math.round((row[column.id] / 1099511627776) * 100) /
							100
					return `${numTB} TB`
				}
				if (row[column.id] > 1073741824) {
					const numGB =
							Math.round((row[column.id] / 1073741824) * 100) /
							100
					return `${numGB} GB`
				}
				if (row[column.id] > 1048576) {
					const numMB =
							Math.round((row[column.id] / 1048576) * 100) / 100
					return `${numMB} MB`
				}
				if (row[column.id] > 1024) {
					const numKB =
							Math.round((row[column.id] / 1024) * 100) / 100
					return `${numKB} KB`
				}
				return `${row[column.id]} bytes`
			}
			break
		case Control.HotListed:
			if (column.id && row[column.id] == true) {
				return (
					<>
						<img
							className={styles.full}
							src={HotListActiveIcon}
							title="HOT LIST"
						/>
					</>
				)
			}
			break
		case Control.HTML:
			if (column.id && row[column.id]) {
				return <>{row[column.id]}</>
			}
			break
		case Control.Hyperlink: {
			if (column.id && row[column.id]) {
				let link = row[column.id]
				if (!link.startsWith('http') && !link.startsWith('/')) {
					link = `http://${link}`
				}
				return (
					<a className={styles.external} href={link}>
						{highlightSearch(row[column.id], wildcard, [
							filtersMap[column.id],
						])}
					</a>
				)
			}
			break
		}
		case Control.ImageFile: {
			return (
				<div>
					<Link
						to={
							props.linkPathRoot
								? `${props.linkPathRoot}${
									location.pathname.endsWith('/')
										? ''
										: '/'
									  }${row[props.primaryKey]}${
									props.linkSuffix ?? ''
									  }`
								: `${location.pathname}${
									location.pathname.endsWith('/')
										? ''
										: '/'
									  }${row[props.primaryKey]}${
									props.linkSuffix ?? ''
									  }`
						}
					>
						<img
							className={styles.full}
							onError={(event: any) => {
								event.target.style.display = 'none'
							}}
							onLoad={(event: any) => {
								event.target.style.display = ''
							}}
							src={
								row[`${column.id}_name`]
									? row[`${column.id}_name`]
									: ''
							}
						/>
					</Link>
				</div>
			)
		}
		case Control.Locked:
			if (column.id && row[column.id] == true) {
				return (
					<>
						<img
							className={styles.full}
							src={LockedIcon}
							title="LOCKED"
						/>
					</>
				)
			}
			break
		case Control.LogFile: {
			if (column.id && row[column.id]) {
				return (
					<a
						className={styles.external}
						href={`${APIHostname}/api/v1/logfile/${
							row[column.id]
						}`}
						rel="noreferrer"
						target="_blank"
					>
						{highlightSearch(row[column.id], wildcard, [
							filtersMap[column.id],
						])}
					</a>
				)
			}
			break
		}
		case Control.Currency:
			if (column.id && row[column.id]) {
				return (
					<div className={styles.money}>
						{highlightSearch(
							CurrencyFormatter.format(row[column.id]),
							wildcard,
							[filtersMap[column.id]]
						)}
					</div>
				)
			}
			break
		case Control.NonNullDate:
			if (column.id && row[column.id]) {
				return (
					<div className={styles.confirmed}>
						<img src={ConfirmIcon} />
					</div>
				)
			} else {
				return (
					<div className={styles.rejected}>
						<img src={RejectIcon} />
					</div>
				)
			}
			break
		case Control.Organization:
			if (column.id && row[column.id] && row[`${column.id}_name`]) {
				return (
					<>
						<Link
							rel="noreferrer"
							target="_blank"
							to={`/organizations/${row[column.id]}`}
						>
							<img
								className={styles.half}
								onError={(event: any) => {
									event.target.style.display = 'none'
								}}
								onLoad={(event: any) => {
									event.target.style.display = ''
								}}
								src={
									row[`${column.id}_image_preview`]
										? row[`${column.id}_image_preview`]
										: ''
								}
							/>
						</Link>

						{highlightSearch(
							row[`${column.id}_name`],
							wildcard,
							[
								filtersMap[column.id],
								filtersMap[`${column.id}_name`],
							]
						)}
					</>
				)
			}
			break
		case Control.Partner:
			if (column.id && row[column.id] && row[`${column.id}_name`]) {
				return (
					<>
						<Link
							rel="noreferrer"
							target="_blank"
							to={`/partners/${row[column.id]}`}
						>
							<img
								className={styles.half}
								onError={(event: any) => {
									event.target.style.display = 'none'
								}}
								onLoad={(event: any) => {
									event.target.style.display = ''
								}}
								src={
									row[`${column.id}_image_preview`]
										? row[`${column.id}_image_preview`]
										: ''
								}
							/>
						</Link>

						{highlightSearch(
							row[`${column.id}_name`],
							wildcard,
							[
								filtersMap[column.id],
								filtersMap[`${column.id}_name`],
							]
						)}
					</>
				)
			}
			break
		case Control.Phone:
			if (column.id && row[column.id]) {
				return (
					<a href={`tel:${row[column.id]}`}> {row[column.id]}</a>
				)
			}
			break
		case Control.Port:
			if (column.id && row[column.id] && row[column.id].length >= 2) {
				return (
					<>
						{' '}
						<Link
							className={`fi fi-${row[column.id]
								.substring(0, 2)
								.toLowerCase()} ${styles.flag}`}
							rel="noreferrer"
							target="_blank"
							to={`/ports/${row[column.id]}`}
						></Link>
							[
						{highlightSearch(row[column.id], wildcard, [
							filtersMap[column.id],
						])}
							]{' '}
						{highlightSearch(
							row[`${column.id}_name`],
							wildcard,
							[
								filtersMap[`${column.id}`],
								filtersMap[`${column.id}_name`],
							]
						)}
					</>
				)
			}
			break
		case Control.Read:
			if (column.id && row[column.id]) {
				if (row['read']) {
					const now = new Date()
					const d = new Date(row[column.id])
					if (
						now.getFullYear() == d.getFullYear() &&
							now.getMonth() == d.getMonth() &&
							now.getDate() == d.getDate()
					) {
						return (
							<>
								<img
									className={styles.half}
									src={CalendarIcon}
								/>{' '}
									TODAY {d.toLocaleTimeString()}
							</>
						)
					}

					return (
						<>
							<img
								className={styles.half}
								src={CalendarIcon}
							/>{' '}
							{d.getMonth() + 1}/
							{d.getDate().toString().padStart(2, '0')}/
							{d.getFullYear()}
						</>
					)
				} else {
					if (
						!HasRole(
							'administrator',
							'memberpartner',
							'nonmemberpartner',
							'partner'
						)
					) {
						return <></>
					}

					return (
						<input
							className="pseudoSubmit"
							onClick={(e: MouseEvent<Element>) => {
								e.preventDefault()
								axios({
									data: {},
									headers: {
										Authorization: `Bearer ${localStorage['token']}`,
										'Content-Type': 'application/json',
									},
									method: 'PATCH',
									url: `${APIHostname}/api/v1/shipments/${
										row['shipment']
									}/${column.endpoint ?? 'remarks'}/${
										row['id']
									}/read`,
								})
									.then(() => {
										sessionStorage['redirect-message'] =
												'Shipment remark read.'
										document.location.reload()
									})
									.catch((error: any) => {
										switch (error?.response?.status) {
										case 401:
											LogOff('token-expired')
											return
										default:
													console.error(error) // eslint-disable-line
										}
									})
							}}
							type="button"
							value="CONFIRM READ"
						/>
					)
				}
			} else {
				if (
					!HasRole(
						'administrator',
						'memberpartner',
						'nonmemberpartner',
						'partner'
					)
				) {
					return <></>
				}

				return (
					<input
						onClick={(e: MouseEvent<Element>) => {
							e.preventDefault()
							axios({
								data: {},
								headers: {
									Authorization: `Bearer ${localStorage['token']}`,
									'Content-Type': 'application/json',
								},
								method: 'PATCH',
								url: `${APIHostname}/api/v1/shipments/${
									row['shipment']
								}/${column.endpoint ?? 'remarks'}/${
									row['id']
								}/read`,
							})
								.then(() => {
									sessionStorage['redirect-message'] =
											'Shipment remark read.'
									document.location.reload()
								})
								.catch((error: any) => {
									switch (error?.response?.status) {
									case 401:
										LogOff('token-expired')
										return
									default:
												console.error(error) // eslint-disable-line
									}
								})
						}}
						type="submit"
						value="CONFIRM READ"
					/>
				)
			}

			break
		case Control.ReadStatus:
			return row['read'] ? (
				<div className={styles.read}>READ</div>
			) : (
				<div className={styles.unread}>UNREAD</div>
			)
			break
		case Control.RejectPay:
			if (!HasRole('administrator', 'memberpartner', 'partner')) {
				return <></>
			}

			if (row['cleared']) {
				return <></>
			}

			return (
				<input
					className={`pseudoSubmit ${styles.reject}`}
					onClick={(e: MouseEvent<Element>) => {
						e.preventDefault()
						// eslint-disable-next-line no-alert
						if (confirm('Really reject payment?')) {
							axios({
								data: {
									reject: true,
								},
								headers: {
									Authorization: `Bearer ${localStorage['token']}`,
									'Content-Type': 'application/json',
								},
								method: 'PATCH',
								url: `${APIHostname}/api/v1/partners/${row['origin']}/pay_requests/${row['id']}`,
							})
								.then(() => {
									sessionStorage[
										'redirect-message'
									] = `Pay request #${row['id']} rejected.`
									document.location.reload()
								})
								.catch((error: any) => {
									switch (error?.response?.status) {
									case 401:
										LogOff('token-expired')
										return
									default:
												console.error(error) // eslint-disable-line
									}
								})
						}
					}}
					type="button"
					value="REJECT	"
				/>
			)

			break
		case Control.Role:
			if (column.id) {
				switch (row[column.id]?.toString().toUpperCase()) {
				case 'ADMINISTRATOR':
					return (
						<div className={styles.administrator}>
									Administrator
						</div>
					)
				case 'ORGANIZATION':
					return (
						<div className={styles.organization}>
									Organization
						</div>
					)
				case 'PARTNER':
					return <div className={styles.partner}>Partner</div>
				default:
					return <div className={styles.pending}>PENDING</div>
				}
			}
			break
		case Control.Status:
			if (column.id) {
				if (
					row['status'] != 'rejected' &&
						row['status'] != 'accepted'
				) {
					return ShipmentStatuses[row[column.id]]
				}
				return <></>
			}
			break
		case Control.TimeZone:
			if (column.id && row[column.id]) {
				{
					return (
						<div className={styles.flexRow}>
							{getCountriesForTimezone(row[column.id]).map(
								(country, index) => {
									return (
										<span key={index}>
											{' '}
											<span
												className={`fi fi-${country.id.toLowerCase()} ${
													styles.flag
												}`}
												title={country.name}
											></span>{' '}
										</span>
									)
								}
							)}
							{highlightSearch(row[column.id], wildcard, [
								filtersMap[column.id],
							])}
						</div>
					)
				}
			}
			break
		case Control.User: {
			if (column.id && row[column.id] && row[`${column.id}_name`]) {
				return (
					<>
						<Link
							rel="noreferrer"
							target="_blank"
							to={`/users/${row[column.id]}`}
						>
							<img
								className={styles.half}
								onError={(event: any) => {
									event.target.style.display = 'none'
								}}
								onLoad={(event: any) => {
									event.target.style.display = ''
								}}
								src={
									row[`${column.id}_image_preview`]
										? row[`${column.id}_image_preview`]
										: ''
								}
							/>
						</Link>

						{highlightSearch(
							row[`${column.id}_name`],
							wildcard,
							[
								filtersMap[column.id],
								filtersMap[`${column.id}_name`],
							]
						)}
					</>
				)
			}
			break
		}
		default:
			if (column.id && row[column.id]) {
				return highlightSearch(row[column.id], wildcard, [
					filtersMap[column.id],
				])
			}
			break
		}

		return <></>
	}

	const rows: ReactElement[] = []
	props.data?.forEach((row: any, rowIndex: number) => {
		const cols: ReactElement[] = []
		if (props.includeDelete || props.includeOrganizationAccess) {
			cols.push(
				<td key={`bulkCol${rowIndex}`}>
					<input
						data-id={row['id']}
						id={`bulk${rowIndex}`}
						name={`bulk${rowIndex}`}
						onChange={() => {
							const bulks = document.querySelectorAll(
								'input[type=\'checkbox\'][name^=\'bulk\']:checked'
							)
							setBulkSelected(bulks.length > 0)
						}}
						type="checkbox"
					/>
				</td>
			)
		}
		const primaryDisplayColumn: { [key: string]: boolean } = {}
		props.primaryDisplayColumn?.split(',').forEach((column: string) => {
			primaryDisplayColumn[column] = true
		})
		props.columns.forEach((column: Column, index: number) => {
			const children = column.children ? column.children : [column]

			let colSpan = 1
			if (column.id == 'read' && !row['read']) {
				colSpan = 2
			} else {
				if (column.id == 'read_by' && !row['read']) {
					colSpan = 0
				}
			}

			if (colSpan) {
				cols.push(
					<td colSpan={colSpan} key={`column${index}`}>
						{children.map((subColumn: Column) => (
							<div key={`subcolumn${subColumn.id}`}>
								{primaryDisplayColumn[subColumn.id ?? ''] ||
								props.primaryKey == subColumn.id ? (
										<Link
											to={
												props.linkPathRoot
													? `${props.linkPathRoot}${
														location.pathname.endsWith(
															'/'
														)
															? ''
															: '/'
												  }${row[props.primaryKey]}${
														props.linkSuffix ?? ''
												  }`
													: `${location.pathname}${
														location.pathname.endsWith(
															'/'
														)
															? ''
															: '/'
												  }${row[props.primaryKey]}${
														props.linkSuffix ?? ''
												  }`
											}
										>
											{row && row[subColumn?.id ?? ''] ? (
												subColumn.prefix
											) : (
												<></>
											)}
											{renderValue(row, subColumn)}
											{row && row[subColumn?.id ?? ''] ? (
												subColumn.suffix
											) : (
												<></>
											)}
										</Link>
									) : (
										<>
											{row && row[subColumn?.id ?? ''] ? (
												subColumn.prefix
											) : (
												<></>
											)}
											{renderValue(row, subColumn)}
											{row && row[subColumn?.id ?? ''] ? (
												subColumn.suffix
											) : (
												<></>
											)}
										</>
									)}
							</div>
						))}
					</td>
				)
			}
		})

		if (row['status'] == 'rejected') {
			rows.push(
				<tr className={styles.rejected} key={`row${rowIndex}`}>
					{cols}
				</tr>
			)
		} else {
			if (row['confirmed']) {
				rows.push(
					<tr className={styles.confirmed} key={`row${rowIndex}`}>
						{cols}
					</tr>
				)
			} else {
				rows.push(
					<tr className={row['class']} key={`row${rowIndex}`}>
						{cols}
					</tr>
				)
			}
		}
	})

	const rowCount = rows.length
	if (rowCount == 0) {
		rows.push(
			<tr key="noRecords">
				<td
					colSpan={
						props.includeDelete || props.includeOrganizationAccess
							? props.columns.length + 1
							: props.columns.length
					}
				>
					NO RECORDS FOUND.
				</td>
			</tr>
		)
	}

	const prevNextLinks = (
		<>
			{offset > 0 ? (
				<span
					className={styles.previousLink}
					onClick={() => {
						const newOffset = Math.max(offset - limit, 0)
						if (newOffset > 0) {
							urlParams.set(
								'offset',
								Math.max(offset - limit, 0).toString()
							)
						} else {
							urlParams.delete('offset')
						}
						document.location.replace(
							`${document.location.origin}${location.pathname}${
								urlParams.size > 0 ? '?' : ''
							}${EscapeURIParameters(urlParams.toString())}`
						)
					}}
				>
					<img
						className={styles.full}
						src={PreviousIcon}
						title="PREVIOUS"
					/>{' '}
					PREVIOUS
				</span>
			) : (
				''
			)}
			{offset + rowCount < props.count ? (
				<span
					className={styles.nextLink}
					onClick={() => {
						urlParams.set('offset', (offset + limit).toString())
						document.location.replace(
							`${document.location.origin}${location.pathname}${
								urlParams.size > 0 ? '?' : ''
							}${EscapeURIParameters(urlParams.toString())}`
						)
					}}
				>
					<img className={styles.full} src={NextIcon} title="NEXT" />{' '}
					NEXT
				</span>
			) : (
				''
			)}
		</>
	)

	return (
		<>
			<div className={styles.navTableNavigation}>
				<div className={styles.resultCountGroup}>
					<span className={styles.resultCountLabel}>
						{props.label
							? props.label
							: props.filters.length > 0
								? 'FILTERED RESULTS:'
								: 'ALL RESULTS:'}{' '}
					</span>
					<span className={styles.resultCount}>
						{rowCount > 0 ? (
							<>
								{(offset + 1).toLocaleString()} &ndash;{' '}
								{(offset + rowCount).toLocaleString()} OF{' '}
								{props.count.toLocaleString()}
							</>
						) : (
							<>0</>
						)}
					</span>
				</div>
				<div className={styles.navigationGroup}>
					{props.includeDelete ? (
						<span
							className={styles.delete}
							onClick={() => {
								const bulks = document.querySelectorAll(
									'input[type=\'checkbox\'][name^=\'bulk\']:checked'
								)
								if (bulks.length == 0) {
									// eslint-disable-next-line
									alert('No records selected.')
									return
								}
								// eslint-disable-next-line
								const confirmation = confirm(
									`Really delete ${bulks.length} record${
										bulks.length > 1 ? 's' : ''
									}?`
								)
								if (!confirmation) {
									return
								}

								const idList: number[] = []
								bulks.forEach((node: Element) => {
									idList.push(
										parseInt(
											(node as HTMLInputElement).dataset
												.id ?? '0'
										)
									)
								})
								axios({
									data: `{"ids":[${idList.join(',')}]}`,
									headers: {
										Authorization: `Bearer ${localStorage['token']}`,
										'Content-Type': 'application/json',
									},
									method: 'DELETE',
									url: `${APIHostname}/api/v1${
										props.apiPathRoot
											? props.apiPathRoot
											: props.linkPathRoot
												? props.linkPathRoot
												: location.pathname
									}`.replace('/administration', ''),
								})
									.then(() => {
										document.location.reload()
									})
									.catch((error: any) => {
										switch (error?.response?.status) {
										case 401:
											LogOff('token-expired')
											return
										default:
												console.error(error) // eslint-disable-line
										}
									})
							}}
							style={{ display: bulkSelected ? '' : 'none' }}
						>
							<img
								className={styles.full}
								src={DeleteIcon}
								title="DELETE"
							/>{' '}
							DELETE
						</span>
					) : (
						''
					)}
					{props.includeOrganizationAccess ? (
						<>
							<span
								className={styles.delete}
								onClick={() => {
									const bulks = document.querySelectorAll(
										'input[type=\'checkbox\'][name^=\'bulk\']:checked'
									)
									if (bulks.length == 0) {
										// eslint-disable-next-line
										alert('No records selected.')
										return
									}
									// eslint-disable-next-line
									const confirmation = confirm(
										`Really add organization access for ${
											bulks.length
										} record${bulks.length > 1 ? 's' : ''}?`
									)
									if (!confirmation) {
										return
									}

									const idList: number[] = []
									bulks.forEach((node: Element) => {
										idList.push(
											parseInt(
												(node as HTMLInputElement)
													.dataset.id ?? '0'
											)
										)
									})
									axios({
										data: `{"action": "add",
											"ids":[${idList.join(',')}]}`,
										headers: {
											Authorization: `Bearer ${localStorage['token']}`,
											'Content-Type': 'application/json',
										},
										method: 'POST',
										url: `${APIHostname}/api/v1${
											props.apiPathRoot
												? props.apiPathRoot
												: props.linkPathRoot
													? props.linkPathRoot
													: location.pathname
										}/organization_access`.replace(
											'/administration',
											''
										),
									})
										.then(() => {
											document.location.reload()
										})
										.catch((error: any) => {
											switch (error?.response?.status) {
											case 401:
												LogOff('token-expired')
												return
											default:
													console.error(error) // eslint-disable-line
											}
										})
								}}
								style={{ display: bulkSelected ? '' : 'none' }}
							>
								<img
									className={styles.full}
									src={OrganizationAccessIcon}
									title="ADD ORGANIZATION (CONSIGNEES, SHIPPERS) ACCESS"
								/>{' '}
								ADD ORGANIZATION (CONSIGNEES, SHIPPERS) ACCESS
							</span>
							<span
								className={styles.delete}
								onClick={() => {
									const bulks = document.querySelectorAll(
										'input[type=\'checkbox\'][name^=\'bulk\']:checked'
									)
									if (bulks.length == 0) {
										// eslint-disable-next-line
										alert('No records selected.')
										return
									}
									// eslint-disable-next-line
									const confirmation = confirm(
										`Really remove organization access for ${
											bulks.length
										} record${bulks.length > 1 ? 's' : ''}?`
									)
									if (!confirmation) {
										return
									}

									const idList: number[] = []
									bulks.forEach((node: Element) => {
										idList.push(
											parseInt(
												(node as HTMLInputElement)
													.dataset.id ?? '0'
											)
										)
									})
									axios({
										data: `{"action": "remove",
										"ids":[${idList.join(',')}]}`,
										headers: {
											Authorization: `Bearer ${localStorage['token']}`,
											'Content-Type': 'application/json',
										},
										method: 'POST',
										url: `${APIHostname}/api/v1${
											props.apiPathRoot
												? props.apiPathRoot
												: props.linkPathRoot
													? props.linkPathRoot
													: location.pathname
										}/organization_access`.replace(
											'/administration',
											''
										),
									})
										.then(() => {
											document.location.reload()
										})
										.catch((error: any) => {
											switch (error?.response?.status) {
											case 401:
												LogOff('token-expired')
												return
											default:
													console.error(error) // eslint-disable-line
											}
										})
								}}
								style={{ display: bulkSelected ? '' : 'none' }}
							>
								<img
									className={styles.full}
									src={OrganizationAccessIcon}
									title="REMOVE ORGANIZATION (CONSIGNEES, SHIPPERS) ACCESS"
								/>{' '}
								REMOVE ORGANIZATION (CONSIGNEES, SHIPPERS)
								ACCESS
							</span>
						</>
					) : (
						''
					)}
					{props.includeExport != false ? (
						<a
							className={styles.export}
							download={true}
							href={`${`${APIHostname}/api/v1${
								props.apiPathRoot
									? props.apiPathRoot + location.search
									: props.linkPathRoot
										? props.linkPathRoot + location.search
										: location.pathname + location.search
							}`.replace('/administration', '')}${
								(props.apiPathRoot
									? props.apiPathRoot + location.search
									: props.linkPathRoot
										? props.linkPathRoot + location.search
										: location.pathname + location.search
								).indexOf('?') > -1
									? '&'
									: '?'
							}format=csv&view=browse`}
							rel="noreferrer"
							target="_blank"
						>
							<img
								className={styles.full}
								src={ExportIcon}
								title="EXPORT"
							/>{' '}
							EXPORT
						</a>
					) : (
						''
					)}
					{prevNextLinks}
					{props.includeDisplayCount != false ? (
						<>
							<span className={styles.displayMaxLabel}>
								DISPLAY MAX:
							</span>
							<select
								className={styles.displayMax}
								defaultValue={limit}
								name="displayMax"
								onChange={(
									e: React.ChangeEvent<HTMLSelectElement>
								) => {
									urlParams.set(
										'limit',
										e.target.value.toString()
									)
									document.location.replace(
										`${document.location.origin}${
											location.pathname
										}${
											urlParams.size > 0 ? '?' : ''
										}${EscapeURIParameters(
											urlParams.toString()
										)}`
									)
								}}
							>
								<option>10</option>
								<option>25</option>
								<option>50</option>
								<option>100</option>
							</select>
						</>
					) : (
						<></>
					)}
				</div>
			</div>

			<table cellSpacing="0" className={styles.navTable}>
				<thead>
					<tr className={styles.headerRow}>{headers}</tr>
				</thead>
				<tbody>{rows}</tbody>
			</table>

			<div className={`${styles.navTableNavigation} ${styles.bottom}`}>
				<div className={styles.navigationGroup}>{prevNextLinks}</div>
			</div>
		</>
	)
}

export const CurrencyFormatter = new Intl.NumberFormat('en-US', {
	currency: 'USD',
	style: 'currency',

	// These options are needed to round to whole numbers if that's what you want.
	//minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
	//maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
})

export type NavTableProps = {
	apiPathRoot?: string
	columns: Column[]
	count: number
	data: any[]
	filters: Filter[]
	includeDelete?: boolean
	includeDisplayCount?: boolean
	includeExport?: boolean
	includeOrganizationAccess?: boolean
	label?: string
	linkPathRoot?: string
	linkSuffix?: string
	primaryDisplayColumn?: string
	primaryKey: string
}

const highlightSearch = (
	value: string,
	wildcardTerm: string,
	fieldTerms: string[]
) => {
	if (!value) {
		return ''
	}
	value = value.toString()

	if (fieldTerms) {
		for (let i = 0; i < fieldTerms.length; i++) {
			if (fieldTerms[i]) {
				const fieldSubTerms = fieldTerms[i].toUpperCase().split(' OR ')
				for (let j = 0; j < fieldSubTerms.length; j++) {
					const fieldIndex = value
						.toUpperCase()
						.indexOf(fieldSubTerms[j].toUpperCase())
					if (fieldIndex > -1) {
						return (
							<span key={RandomID(16)}>
								{value.substring(0, fieldIndex)}
								<span className={styles.highlight}>
									{value.substring(
										fieldIndex,
										fieldIndex + fieldSubTerms[j].length
									)}
								</span>
								{value.substring(
									fieldIndex + fieldSubTerms[j].length
								)}
							</span>
						)
					}
				}
			}
		}
	}

	if (wildcardTerm) {
		const wildcardTerms = wildcardTerm.toUpperCase().split(' OR ')
		for (let i = 0; i < wildcardTerms.length; i++) {
			const wildcardIndex = value
				.toUpperCase()
				.indexOf(wildcardTerms[i].toUpperCase())
			if (wildcardIndex > -1) {
				return (
					<>
						{value.substring(0, wildcardIndex)}
						<span className={styles.highlight}>
							{value.substring(
								wildcardIndex,
								wildcardIndex + wildcardTerms[i].length
							)}
						</span>
						{value.substring(
							wildcardIndex + wildcardTerms[i].length
						)}
					</>
				)
			}

			// Acronyms
			if (wildcardTerms[i] == wildcardTerms[i].toUpperCase()) {
				let pos = 0
				let matched = false
				const output = []
				for (let j = 0; j < wildcardTerms[i].length; j++) {
					const newPos = value.indexOf(wildcardTerms[i][j], pos)
					if (newPos > -1) {
						output.push(value.substring(pos, newPos))
						output.push(
							<span className={styles.highlight}>
								{wildcardTerms[i][j]}
							</span>
						)
						pos = newPos + 1
						if (j == wildcardTerms[i].length - 1) {
							matched = true
						}
					} else {
						break
					}
				}
				if (matched) {
					output.push(value.substring(pos))
					return output
				}
			}
		}
	}

	return value
}
