import axios from 'axios'
import { useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'

import DownArrowIcon from '../../../../../assets/icons/arrow-down.svg'
import InboxIcon from '../../../../../assets/icons/inbox.svg'
import UserIcon from '../../../../../assets/icons/new-user.svg'
import UserHistoryIcon from '../../../../../assets/icons/organization-history.svg'
import { EditPermissions } from '../../../../../components/Form/Controls/Permissions'
import { ParsedFormData } from '../../../../../components/Form/EditForm'
import { GlobalTopTabItems } from '../../../../../components/Tabs'
import { UserSummary } from '../../../../../components/UserSummary'
import { DefaultLayout } from '../../../../../layouts/DefaultLayout'
import { APIHostname, LogOff } from '../../../../../Profile'
import styles from './UserAPI.module.scss'

export const UserAPI = () => {
	const { id } = useParams()
	const [apiKey, setApiKey]: any = useState('')
	const [errorMessage, setErrorMessage] = useState(<></>)
	const [user, setUser]: any = useState(null)
	const [userLoaded, setUserLoaded] = useState(false)
	const initialRedirectMessage = sessionStorage['redirect-message']
	const [redirectMessage] = useState(initialRedirectMessage)

	// Vary based on query string.
	useLocation()

	useEffect(() => {
		axios({
			data: {},
			headers: {
				Authorization: `Bearer ${localStorage['token']}`,
			},
			method: 'GET',
			url: `${APIHostname}/api/v1/users/${id}`,
		})
			.then((response: any) => {
				setUser(response.data)
				setUserLoaded(true)
			})
			.catch((error: any) => {
				switch (error?.response?.status) {
				case 401:
					LogOff('token-expired')
					return
				case 403:
					setErrorMessage(
						<>
								Error: You do not have permission to view users.
						</>
					)
					break
				default:
					if (error?.response?.data?.error) {
						setErrorMessage(
							error?.response?.data?.link ? (
								<Link to={error?.response?.data?.link}>
										Error:
									{error?.response?.data?.error}
								</Link>
							) : (
								<>{error?.response?.data?.error}</>
							)
						)
					} else {
						setErrorMessage(<>Error: Unknown error.</>)
							console.error(error) // eslint-disable-line
					}
				}
			})
	}, [location])

	const APILeftMenuItems = [
		{
			icon: UserIcon,
			requiredPermissions: ['user.read'],
			text: 'USER INFO',
			to: `/users/${id}`,
		},
		{
			icon: UserIcon,
			requireDeveloperMode: true,
			requiredPermissions: ['user.generateapitoken'],
			text: 'USER API TOKENS',
			to: `/users/${id}/api`,
		},
		{
			icon: InboxIcon,
			requiredPermissions: ['user.read'],
			text: 'USER NOTIFICATIONS',
			to: `/users/${id}/notifications`,
		},
		{
			icon: UserHistoryIcon,
			requiredPermissions: ['user.read'],
			text: 'USER HISTORY',
			to: `/users/${id}/history`,
		},
	]

	const APIBottomTabItems = [
		{
			icon: DownArrowIcon,
			text: 'GENERATE API TOKEN',
			to: `/users/${id}/api`,
		},
	]

	if (!userLoaded) {
		if (errorMessage) {
			return (
				<DefaultLayout
					bottomTabItems={APIBottomTabItems}
					leftMenuItems={APILeftMenuItems}
					showPrint={true}
					tabMenuItems={APITabMenuItems}
					topTabItems={GlobalTopTabItems}
				>
					<div className={styles.userHistoryContainer}>
						<div className={styles.redirectMessage}>
							{redirectMessage}
						</div>
						<div className={styles.errorMessage}>
							{errorMessage}
						</div>
					</div>
				</DefaultLayout>
			)
		}

		return <div className="loading">Loading...</div>
	}

	sessionStorage.removeItem('redirect-message')
	return (
		<DefaultLayout
			bottomTabItems={APIBottomTabItems}
			leftMenuItems={APILeftMenuItems}
			showPrint={true}
			tabMenuItems={APITabMenuItems}
			topTabItems={GlobalTopTabItems}
		>
			<div className={styles.userApiContainer}>
				<UserSummary user={user} />

				<div className={styles.redirectMessage}>{redirectMessage}</div>
				<div className={styles.errorMessage}>
					{errorMessage}
					{!user?.active ? <>This user has been deleted.</> : <></>}
				</div>

				<form
					onSubmit={async (e: React.FormEvent<HTMLFormElement>) => {
						const formData = new FormData(e.currentTarget)
						const payload = await ParsedFormData(formData, [])
						payload.permissions = {}
						const permissions = document.querySelectorAll(
							'input[type=checkbox]'
						)
						permissions.forEach((permission: Element) => {
							const permissionBox = permission as HTMLInputElement
							if (permissionBox.checked) {
								payload.permissions[permissionBox.name] =
									permissionBox.checked
							}
						})

						axios({
							data: payload,
							headers: {
								Authorization: `Bearer ${localStorage['token']}`,
								'Content-Type': 'text/csv',
							},
							method: 'POST',
							url: `${APIHostname}/api/v1/token`,
						})
							.then((response: any) => {
								setApiKey(response.data['token'])
								setErrorMessage(<></>)
							})
							.catch((error: any) => {
								switch (error?.response?.status) {
								case 401:
									LogOff('token-expired')
									return
								case 403:
									setErrorMessage(
										<>
												Error: You do not have
												permission to generate API
												tokens.
										</>
									)
									break
								default:
									if (error?.response?.data?.error) {
										setErrorMessage(
											error?.response?.data?.link ? (
												<Link
													to={
														error?.response
															?.data?.link
													}
												>
														Error:
													{
														error?.response
															?.data?.error
													}
												</Link>
											) : (
												<>
													{
														error?.response
															?.data?.error
													}
												</>
											)
										)
									} else {
										setErrorMessage(
											<>Error: Unknown error.</>
										)
											console.error(error) // eslint-disable-line
									}
								}
							})
						e.preventDefault()
					}}
				>
					Use the form below to generate an{' '}
					<Link to="/api">API token</Link> to be used for this user.
					<textarea
						className={styles.apiKey}
						onFocus={(event: any) => {
							event.target.select()
							document.execCommand('copy')
						}}
						readOnly={true}
						title="API KEY"
						value={apiKey}
					></textarea>
					<EditPermissions permissions={{}} />
					<div className={styles.buttonBox}>
						<input type="submit" value="Generate API Token" />
					</div>
				</form>
			</div>
		</DefaultLayout>
	)
}

export const APITabMenuItems = []
