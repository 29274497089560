import DownArrowIcon from '../../assets/icons/arrow-down.svg'
import { DefaultLayout } from '../../layouts/DefaultLayout'
import styles from './CarrierTracking.module.scss'

export const CarrierTracking = () => {
	return (
		<DefaultLayout
			bottomTabItems={CarrierTrackingBottomTabItems}
			leftMenuItems={[]}
			showPrint={true}
			tabMenuItems={[]}
			topTabItems={[]}
		>
			<div className={styles.carrierTrackingContainer}>
				<ul>
					<li>
						<a
							href="https://www.aclcargo.com/track-cargo/"
							rel="noreferrer"
							target="_blank"
						>
							ACL Atlantic Container Line
						</a>
					</li>
					<li>
						<a
							href="https://www.alianca.com.br/alianca/en/alianca/index.html"
							rel="noreferrer"
							target="_blank"
						>
							Alianca Navegaceo e Logistica Ltda.
						</a>
					</li>
					<li>
						<a
							href="http://www.bal.cn/index.html"
							rel="noreferrer"
							target="_blank"
						>
							BAL CONTAINER LINE
						</a>
					</li>
					<li>
						<a
							href="https://www.cma-cgm.com/ebusiness/tracking"
							rel="noreferrer"
							target="_blank"
						>
							CMA-CGM
						</a>
					</li>
					<li>
						<a
							href="https://elines.coscoshipping.com/ebusiness/cargoTracking"
							rel="noreferrer"
							target="_blank"
						>
							COSCO CONTAINER LINES
						</a>
					</li>
					<li>
						<a
							href="https://www.culines.com/en/site/bill"
							rel="noreferrer"
							target="_blank"
						>
							CULINES
						</a>
					</li>
					<li>
						<a
							href="https://www.emiratesline.com/"
							rel="noreferrer"
							target="_blank"
						>
							Emirates Shipping Line
						</a>
					</li>
					<li>
						<a
							href="https://www.shipmentlink.com/servlet/TDB1_CargoTracking.do"
							rel="noreferrer"
							target="_blank"
						>
							Evergreen Lines
						</a>
					</li>
					<li>
						<a
							href="https://www.fesco.ru/en/"
							rel="noreferrer"
							target="_blank"
						>
							FESCO
						</a>
					</li>
					<li>
						<a
							href="https://www.hamburgsud-line.com/liner/en/liner_services/ecommerce/visibility/track_trace/index.html"
							rel="noreferrer"
							target="_blank"
						>
							Hamburg-Sued (Columbus Line)
						</a>
					</li>
					<li>
						<a
							href="https://www.hapag-lloyd.com/en/online-business/tracing/tracing-by-container.html"
							rel="noreferrer"
							target="_blank"
						>
							Hapag-Lloyd
						</a>
					</li>
					<li>
						<a
							href="https://www.hmm21.com/cms/company/engn/index.jsp"
							rel="noreferrer"
							target="_blank"
						>
							HYUNDAI Merchant Marine (HMM)
						</a>
					</li>
					<li>
						<a
							href="https://www.maersk.com/"
							rel="noreferrer"
							target="_blank"
						>
							Maersk Line
						</a>
					</li>
					<li>
						<a
							href="https://www.matson.com/"
							rel="noreferrer"
							target="_blank"
						>
							MATSON
						</a>
					</li>
					<li>
						<a
							href="https://www.msc.com/tracking/tracking_login.html"
							rel="noreferrer"
							target="_blank"
						>
							MSC
						</a>
					</li>
					<li>
						<a
							href="https://ecomm.one-line.com/ecom/CUP_HOM_3301.do?sessLocale=en"
							rel="noreferrer"
							target="_blank"
						>
							Ocean Network Express
						</a>
					</li>
					<li>
						<a
							href="https://www.oocl.com/eng/Pages/default.aspx"
							rel="noreferrer"
							target="_blank"
						>
							OOCL
						</a>
					</li>

					<li>
						<a
							href="https://www.pilship.com/en-pil-pacific-international-lines/1.html"
							rel="noreferrer"
							target="_blank"
						>
							PIL Pacific International Lines
						</a>
					</li>

					<li>
						<a
							href="https://esvc.smlines.com/smline/CUP_HOM_3301.do?sessLocale="
							rel="noreferrer"
							target="_blank"
						>
							SM Line
						</a>
					</li>
					<li>
						<a
							href="https://www.sethshipping.com/"
							rel="noreferrer"
							target="_blank"
						>
							SETH SHIPPING
						</a>
					</li>
					<li>
						<a
							href="https://www.sealandmaersk.com/"
							rel="noreferrer"
							target="_blank"
						>
							SEALAND
						</a>
					</li>
					<li>
						<a
							href="https://www.swireshipping.com/"
							rel="noreferrer"
							target="_blank"
						>
							Swire Shipping
						</a>
					</li>
					<li>
						<a
							href="https://vgm.tslines.com/CargoTracking/CargoTrackingBLX"
							rel="noreferrer"
							target="_blank"
						>
							T.S. Lines
						</a>
					</li>

					<li>
						<a
							href="https://www.wanhai.com/views/Main.xhtml"
							rel="noreferrer"
							target="_blank"
						>
							Wan Hai Lines
						</a>
					</li>

					<li>
						<a
							href="https://www.yangming.com/"
							rel="noreferrer"
							target="_blank"
						>
							Yang Ming
						</a>
					</li>

					<li>
						<a
							href="https://www.zim.com/tools/track-a-shipment"
							rel="noreferrer"
							target="_blank"
						>
							Zim Line
						</a>
					</li>

					<li>
						<a
							href="http://shipping-container-info.com/container-info/"
							rel="noreferrer"
							target="_blank"
						>
							Container Information
						</a>
					</li>
				</ul>
			</div>
		</DefaultLayout>
	)
}

export const CarrierTrackingBottomTabItems = [
	{
		icon: DownArrowIcon,
		text: 'SEA CARGO TRACKING',
		to: '/carrier-tracking',
	},
]
