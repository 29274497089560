import Control from '../Control'
import { Layout } from '../Layout'

export type Partner = {
	id: number
	main_contact_name: string
	name: string
	oti: string
	parentID: number
	code: string
}

export const PartnerEditLayout: Layout = {
	Groups: [
		{
			Border: true,
			Direction: 'column',
			Flex: '1 1',
			Margin: '1rem 0 0 0',
			OptionalRows: [
				{
					Fields: [
						{
							HardRequired: true,
							Label: 'CODE',
							MaxLength: 8,
							MinLength: 8,
							Property: 'code',
							Width: 33,
						},
						{
							HardRequired: true,
							Label: 'DISPLAY NAME',
							Property: 'name',
							Width: 33,
						},
						{
							HardRequired: true,
							Label: 'LEGAL NAME',
							Property: 'legal_name',
							Width: 33,
						},
					],
				},
				{
					Fields: [
						{
							Control: Control.Partner,
							Label: 'PARENT',
							Property: 'parent',
							Width: 100,
						},
					],
				},
				{
					Fields: [
						{
							Control: Control.TextBox,
							Label: 'ADDRESS',
							Property: 'address',
							RowSpan: 2,
							SoftRequired: true,
							Width: 100,
						},
					],
				},
				{
					Fields: [
						{
							Label: 'CITY',
							Property: 'city',
							SoftRequired: true,
							Width: 25,
						},
						{
							Label: 'STATE',
							Property: 'state',
							Width: 25,
						},
						{
							Label: 'POSTAL CODE',
							Property: 'postal_code',
							SoftRequired: true,
							Width: 25,
						},
						{
							Control: Control.Country,
							HardRequired: true,
							Label: 'COUNTRY',
							Property: 'country',
							Width: 25,
						},
					],
				},
				{
					Fields: [
						{
							Label: 'OTI #',
							Property: 'oti',
							SoftRequired: true,
							Width: 50,
						},
						{
							Label: 'EORI #',
							MaxLength: 21,
							Property: 'eori',
							SoftRequired: true,
							Width: 50,
						},
					],
				},
				{
					Fields: [
						{
							Control: Control.Checkbox,
							Label: 'GOAL MEMBER:',
							Property: 'goal_member',
							Width: 100,
						},
					],
				},
			],
			Width: 50,
		},
		{
			Border: true,
			Direction: 'column',
			Flex: '1 1',
			Margin: '1rem 0 0 0',
			OptionalRows: [
				{
					Fields: [
						{
							Label: 'MAIN CONTACT',
							Property: 'main_contact_name',
							SoftRequired: true,
							Width: 100,
						},
					],
				},
				{
					Fields: [
						{
							Control: Control.Phone,
							Label: 'PHONE',
							Property: 'phone',
							SoftRequired: true,
							Width: 33,
						},
						{
							Control: Control.Phone,
							Label: 'FAX',
							Property: 'fax',
							Width: 33,
						},
						{
							Control: Control.Hyperlink,
							Label: 'WEBSITE',
							Property: 'website',
							SoftRequired: true,
							Width: 33,
						},
					],
				},
				{
					Fields: [
						{
							Control: Control.TextBox,
							Label: '24X7 CONTACT',
							Property: 'contact_247_name',
							SoftRequired: true,
							Width: 100,
						},
					],
				},
				{
					Fields: [
						{
							Label: 'LOCATIONS SERVED',
							Property: 'locations_served',
							Width: 50,
						},
						{
							Label: 'SERVICES OFFERED',
							Property: 'services_offered',
							Width: 50,
						},
					],
				},
				{
					Fields: [
						{
							Control: Control.TextBox,
							Label: 'NOTES',
							Property: 'notes',
							Width: 100,
						},
					],
				},
				{
					Fields: [
						{
							Control: Control.TimeZone,
							Label: 'TIME ZONE',
							Property: 'timezone',
							Width: 100,
						},
					],
				},
				{
					Fields: [
						{
							Control: Control.Date,
							Label: 'CREATION DATE',
							Property: 'created',
							ReadOnly: true,
							Width: 33,
						},
						{
							Control: Control.Date,
							Label: 'MODIFIED DATE',
							Property: 'modified',
							ReadOnly: true,
							Width: 33,
						},
						{
							Control: Control.Number,
							Label: 'VERSION',
							Property: 'version',
							ReadOnly: true,
							Width: 33,
						},
					],
				},
			],
			Width: 100,
		},
		{
			Border: true,
			ClassName: 'partnerBranding',
			Label: 'PARTNER BRANDING',
			LabelBackground: 'blue',
			RequiredRole: 'administrator||memberpartner||partner',
			RequiredRows: [
				{
					Fields: [
						{
							Label: 'HOSTNAME',
							Notes: 'Note: A DNS CNAME record must be made for the hostname below pointing to goalportal.net',
							Property: 'hostname',
							Width: 33,
						},
						{
							Control: Control.ImageFile,
							Label: 'LOGO',
							Property: 'logo',
							Width: 33,
						},
						{
							Control: Control.ImageFile,
							Label: 'FAVICON',
							Property: 'favicon',
							Width: 33,
						},
					],
				},
				{
					Fields: [
						{
							Control: Control.HTML,
							Label: 'LOGIN LEFT COLUMN (HTML)',
							Property: 'login_left_column',
							Width: 33,
						},
						{
							Control: Control.HTML,
							Label: 'LOGIN RIGHT COLUMN (HTML)',
							Property: 'login_right_column',
							Width: 33,
						},
						{
							Control: Control.HTML,
							Label: 'STYLESHEET (CSS)',
							Property: 'stylesheet',
							Width: 33,
						},
					],
				},
				{
					Fields: [
						{
							Label: 'ARRIVAL NOTICE TEMPLATE',
							Property: 'arrival_notice_template',
							RequiredRole: 'administrator',
							Width: 50,
						},
						{
							Label: 'FILENAME PREFIX',
							Property: 'filename_prefix',
							Width: 50,
						},
					],
				},
				{
					Fields: [
						{
							Control: Control.TextBox,
							Label: 'ARRIVAL NOTICE FOOTER',
							Property: 'arrival_notice_footer',
							Width: 100,
						},
					],
				},
			],

			Width: 100,
		},
	],
}

export const PartnerSearchLayout: Layout = {
	Groups: [
		{
			Border: true,
			Margin: '1rem 0 0 0',
			OptionalRows: [
				{
					Fields: [
						{
							Label: 'COMPANY NAME',
							Property: 'name',
							Width: 25,
						},
						{
							Label: 'COMPANY CODE',
							Property: 'code',
							Width: 25,
						},
						{
							Control: Control.Country,
							Label: 'COUNTRY',
							Property: 'country',
							Width: 25,
						},
						{
							Control: Control.TimeZone,
							Label: 'TIME ZONE',
							Property: 'timezone',
							Width: 25,
						},
					],
				},
				{
					Fields: [
						{
							Control: Control.Boolean,
							Label: 'GOAL MEMBER',
							Property: 'goal_member',
							Width: 50,
						},
						{
							Control: Control.Active,
							Label: 'INCLUDE',
							Property: 'active',
							Width: 50,
						},
					],
					IncludeInEdit: false,
					RequiredRole: 'administrator',
				},
			],
			RequiredRows: [
				{
					Fields: [
						{
							IncludeInEdit: false,
							IncludeInSearch: true,
							Label: 'WILDCARD (SEARCH ALL FIELDS; USE "OR" FOR MULTIPLE CRITERIA)',
							Property: '*',
							Width: 100,
						},
					],
				},
			],
			Width: 100,
		},
	],
}
