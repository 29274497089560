import axios from 'axios'
import { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

import ArrivalNoticeIcon from '../../../assets/icons/arrival-notice.svg'
import DownArrowIcon from '../../../assets/icons/arrow-down.svg'
import DataCorrectionIcon from '../../../assets/icons/data-correction.svg'
import HotListActiveIcon from '../../../assets/icons/hot-active.svg'
import LockedIcon from '../../../assets/icons/locked.svg'
import NewIcon from '../../../assets/icons/new.svg'
import { SearchForm } from '../../../components/Form/SearchForm'
import { NavTable } from '../../../components/NavTable'
import { GlobalTopTabItems } from '../../../components/Tabs'
import { DefaultLayout } from '../../../layouts/DefaultLayout'
import { APIHostname, HasPermission, LogOff } from '../../../Profile'
import Control from '../../../types/Control'
import { ArrivalNoticeSearchLayout } from '../../../types/data/ArrivalNoticeOverride'
import { EscapeURIParameters } from '../../../Utils'
import styles from './ArrivalNotices.module.scss'

export const ArrivalNotices = () => {
	const [arrivalNotices, setArrivalNotices] = useState([])
	const [arrivalNoticeCount, setArrivalNoticeCount] = useState(0)
	const [arrivalNoticesLoaded, setArrivalNoticesLoaded] = useState(false)
	const [errorMessage, setErrorMessage] = useState(<></>)
	const initialRedirectMessage = sessionStorage['redirect-message']
	const [redirectMessage] = useState(initialRedirectMessage)

	// Vary based on query string.
	const location = useLocation()
	const urlParams = new URLSearchParams(location.search)
	if (!urlParams.get('limit')) {
		urlParams.set('limit', '25')
	}

	useEffect(() => {
		axios({
			data: {},
			headers: {
				Authorization: `Bearer ${localStorage['token']}`,
			},
			method: 'GET',
			url: `${APIHostname}/api/v1/arrival_notices?${EscapeURIParameters(
				urlParams.toString()
			)}&view=browse`,
		})
			.then((response: any) => {
				setErrorMessage(<></>)
				setArrivalNotices(response.data.arrival_notices)
				setArrivalNoticeCount(response.data.arrival_notice_count)
				setArrivalNoticesLoaded(true)
			})
			.catch((error: any) => {
				switch (error?.response?.status) {
				case 401:
					LogOff('token-expired')
					return
				case 403:
					setErrorMessage(
						<>
								Error: You do not have permission to view
								arrival notices.
						</>
					)
					break
				default:
					if (error?.response?.data?.error) {
						setErrorMessage(
							error?.response?.data?.link ? (
								<Link to={error?.response?.data?.link}>
										Error:
									{error?.response?.data?.error}
								</Link>
							) : (
								<>{error?.response?.data?.error}</>
							)
						)
					} else {
						setErrorMessage(<>Error: Unknown error.</>)
							console.error(error) // eslint-disable-line
					}
				}
			})
	}, [location])

	const ArrivalNoticesTabMenuItems = [
		{
			icon: NewIcon,
			requiredPermissions: ['arrival_notice.create'],
			text: 'NEW',
			to: '/arrival_notices/new',
		},
	].reverse()

	const ArrivalNoticesLeftMenuItems = [
		{
			icon: ArrivalNoticeIcon,
			text: 'ARRIVAL NOTICES',
			to: '/arrival_notices',
		},
	]

	if (!arrivalNoticesLoaded) {
		if (errorMessage) {
			return (
				<DefaultLayout
					bottomTabItems={ArrivalNoticesBottomTabItems}
					leftMenuItems={ArrivalNoticesLeftMenuItems}
					showPrint={true}
					tabMenuItems={ArrivalNoticesTabMenuItems}
					topTabItems={GlobalTopTabItems}
				>
					<div className={styles.arrivalNoticesContainer}>
						<div className={styles.redirectMessage}>
							{redirectMessage}
						</div>
						<div className={styles.errorMessage}>
							{errorMessage}
						</div>
					</div>
				</DefaultLayout>
			)
		}

		return <div className="loading">Loading...</div>
	}

	sessionStorage.removeItem('redirect-message')
	const css = <style>{'.showAdvanced {display:none !important;}'}</style>

	return (
		<DefaultLayout
			bottomTabItems={ArrivalNoticesBottomTabItems}
			leftMenuItems={ArrivalNoticesLeftMenuItems}
			showPrint={true}
			tabMenuItems={ArrivalNoticesTabMenuItems}
			topTabItems={GlobalTopTabItems}
		>
			{css}
			<div className={styles.arrivalNoticesContainer}>
				<div className={styles.redirectMessage}>{redirectMessage}</div>
				<div className={styles.errorMessage}>{errorMessage}</div>

				<div className={styles.searchForm}>
					<SearchForm
						data={{}}
						layout={ArrivalNoticeSearchLayout}
						name="arrivalNotices"
						readOnly={false}
					/>
				</div>

				<NavTable
					columns={[
						{
							id: 'override_hbl',
							title: 'HBL',
						},
						{
							id: 'override_mbl',
							title: 'MBL',
						},
						{
							control: Control.Date,
							id: 'override_date',
							title: 'DATE',
						},
						{
							id: 'override_reference',
							title: 'REFERENCE',
						},
						{
							control: Control.Partner,
							id: 'destination_partner',
							title: 'DESTINATION PARTNER',
						},
						{
							control: Control.Organization,
							id: 'override_shipper',
							title: 'SHIPPER',
						},
						{
							control: Control.Organization,
							id: 'override_consignee',
							title: 'CONSIGNEE',
						},
						{
							control: Control.Organization,
							id: 'override_notify',
							title: 'NOTIFY',
						},
						{
							id: 'override_port_of_load',
							title: 'PORT OF LOAD',
						},
						{
							id: 'override_port_of_discharge',
							title: 'PORT OF DISCHARGE',
						},
						{
							id: 'override_place_of_delivery',
							title: 'PLACE OF DELIVERY',
						},
						{
							id: 'override_vessel',
							title: 'VESSEL',
						},
						{
							id: 'override_voyage',
							title: 'VOYAGE',
						},
						{
							children: [
								{
									control: Control.DateTime,
									id: 'created',
									title: 'CREATION DATE',
								},
								{
									control: Control.User,
									id: 'created_by',
									title: 'CREATED BY',
								},
							],
						},
						{
							children: [
								{
									control: Control.DateTime,
									id: 'modified',
									title: 'MODIFIED DATE',
								},
								{
									control: Control.User,
									id: 'modified_by',
									title: 'MODIFIED BY',
								},
							],
						},
						{
							children: [
								{
									control: Control.Locked,
									icon: LockedIcon,
									id: 'lock',
									title: 'LOCKED',
								},
								{
									control: Control.HotListed,
									icon: HotListActiveIcon,
									id: 'hotlist',
									title: 'HOT LIST',
								},
								{
									control: Control.DataCorrection,
									icon: DataCorrectionIcon,
									id: 'data_correction',
									title: 'DATA CORRECTION',
								},
							],
						},
					]}
					count={arrivalNoticeCount}
					data={arrivalNotices}
					filters={JSON.parse(urlParams.get('filters') ?? '[]')}
					includeDelete={HasPermission('arrival_notice', 'delete')}
					linkSuffix={urlParams.get('linkSuffix') ?? undefined}
					primaryDisplayColumn="override_hbl"
					primaryKey="id"
				/>
			</div>
		</DefaultLayout>
	)
}

export const ArrivalNoticesBottomTabItems = [
	{
		icon: DownArrowIcon,
		text: 'ARRIVAL NOTICES',
		to: '/arrival_notices',
	},
]
