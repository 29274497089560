import '/node_modules/flag-icons/css/flag-icons.min.css'

import axios from 'axios'
import { ChangeEvent, ReactElement, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDebouncedCallback } from 'use-debounce'

import { APIHostname, LogOff } from '../../../../Profile'
import { EncodedStringify } from '../../../../types/Filter'
import { RandomID } from '../../../../Utils'
import { FieldProps } from '../../Field'
import styles from './Port.module.scss'

export const EditPort = (props: FieldProps) => {
	const [previews, setPreviews] = useState(<></>)
	const [value, setValue] = useState(props.value ?? '')
	const [searchAheadID] = useState(RandomID(16))

	const search = (target: HTMLInputElement) => {
		if (target.value) {
			const filters = [
				{
					field: 'name,code',
					operator: 'contains',
					value: target.value,
				},
			]
			axios({
				data: {},
				headers: {
					Authorization: `Bearer ${localStorage['token']}`,
				},
				method: 'GET',
				url: `${APIHostname}/api/v1/ports?filters=${EncodedStringify(
					filters
				)}&sorts=[{"field":"name","order":"ASC"}]&limit=250`,
			})
				.then((response: any) => {
					if (response.data.ports) {
						const rows: ReactElement[] = []
						response.data.ports.forEach((p: any) => {
							rows.push(
								<tr
									key={`preview${p.code}`}
									onClick={(event: any) => {
										setValue(p.code)
										;(
											document.getElementById(
												`${props.field.Property}_name`
											) as HTMLInputElement
										).value = `[${p.code}] ${p.name}`
										;(
											document.getElementById(
												`${props.field.Property}_name`
											) as HTMLInputElement
										).dataset.value = `[${p.code}] ${p.name}`
										setPreviews(<></>)

										if (props.onChange) {
											props.onChange(event)
										}
									}}
								>
									<td>
										<Link
											className={`fi fi-${p.country_code.toLowerCase()}`}
											rel="noreferrer"
											target="_blank"
											to={`/ports/${p.code}`}
										></Link>
									</td>
									<td>{p.country_code}</td>
									<td>{p.city_code}</td>
									<td>{p.name}</td>
									<td>{p.dcode}</td>
									<td>{p.kcode}</td>
								</tr>
							)
						})
						const searchAhead =
							document.getElementById(searchAheadID)
						if (searchAhead) {
							searchAhead.style.left = `${target.offsetLeft}px`
							searchAhead.style.top = `${
								target.offsetTop + target.offsetHeight
							}px`
							searchAhead.style.width = `${Math.max(
								target.offsetWidth,
								400
							)}px`
						}
						setPreviews(
							<table>
								<tbody>{rows}</tbody>
							</table>
						)
					} else {
						setPreviews(<></>)
					}
				})
				.catch((error: any) => {
					switch (error?.response?.status) {
					case 401:
						LogOff('token-expired')
						return
					default:
							console.error(error) // eslint-disable-line
					}
					setPreviews(<></>)
				})
		} else {
			setPreviews(<></>)
		}
	}

	const defaultValue = props.value
		? `[${props.data[`${props.field.Property}`]}] ${
			props.data[`${props.field.Property}_name`]
		  }`
		: ''
	return (
		<div
			className={styles.fieldOuterContainer}
			id={props.field.Property ? `${props.field.Property}_container` : ''}
		>
			<div className={styles.label}>
				{props.field.Label}
				{props.field.HardRequired && !props.isSearch ? (
					<>
						{'\u00A0'}
						<span
							className="hardRequired"
							title="REQUIRED IN ORDER TO SAVE"
						>
							*
						</span>
					</>
				) : (
					<></>
				)}
				{props.field.SoftRequired && !props.isSearch ? (
					<>
						{'\u00A0'}
						<span className="softRequired" title="REQUIRED">
							*
						</span>
					</>
				) : (
					<></>
				)}{' '}
			</div>

			<div className={styles.portBox}>
				{value && value.length > 2 ? (
					<Link
						className={`fi fi-${value
							.substring(0, 2)
							.toLowerCase()} ${styles.flag}`}
						rel="noreferrer"
						target="_blank"
						to={`/ports/${value}`}
					></Link>
				) : (
					<></>
				)}
				<input
					autoComplete="off"
					className={`${
						props.field.HardRequired && !props.isSearch
							? 'hardRequired'
							: ''
					} ${
						props.field.SoftRequired && !props.isSearch
							? 'softRequired'
							: ''
					}`}
					data-value={defaultValue}
					defaultValue={defaultValue}
					id={`${props.field.Property}_name`}
					onChange={useDebouncedCallback((e: ChangeEvent) => {
						search(e.target as HTMLInputElement)
						if (props.onChange) {
							props.onChange(e)
						}
					}, 100)}
					onFocus={(e: ChangeEvent) => {
						const target = e.target as HTMLInputElement
						target.value = value
						search(target)
					}}
					placeholder=" "
					readOnly={props.readOnly || props.field.ReadOnly}
					required={props.field.HardRequired && !props.isSearch}
					title={props.field.Label?.replace(':', '')}
					type="text"
				/>
				<div className={styles.previews} id={searchAheadID}>
					{previews}
				</div>
			</div>
			<input
				data-hardrequired={props.field.HardRequired && !props.isSearch}
				data-nullable={true}
				data-softrequired={props.field.SoftRequired && !props.isSearch}
				id={props.field.Property}
				name={props.field.Property}
				readOnly={true}
				type="hidden"
				value={value}
			/>
		</div>
	)
}
