import axios from 'axios'
import { useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'

import DownArrowIcon from '../../../../../assets/icons/arrow-down.svg'
import InboxIcon from '../../../../../assets/icons/inbox.svg'
import UserIcon from '../../../../../assets/icons/new-user.svg'
import UserHistoryIcon from '../../../../../assets/icons/organization-history.svg'
import { NavTable } from '../../../../../components/NavTable'
import { GlobalTopTabItems } from '../../../../../components/Tabs'
import { UserSummary } from '../../../../../components/UserSummary'
import { DefaultLayout } from '../../../../../layouts/DefaultLayout'
import { APIHostname, LogOff } from '../../../../../Profile'
import Control from '../../../../../types/Control'
import { EscapeURIParameters } from '../../../../../Utils'
import styles from './UserHistory.module.scss'

export const UserHistory = () => {
	const { id } = useParams()
	const [history, setHistory]: any = useState(null)
	const [user, setUser]: any = useState(null)
	const [userLoaded, setUserLoaded] = useState(false)
	const [historyCount, setHistoryCount] = useState(0)
	const [historyLoaded, setHistoryLoaded] = useState(false)
	const [errorMessage, setErrorMessage] = useState(<></>)
	const initialRedirectMessage = sessionStorage['redirect-message']
	const [redirectMessage] = useState(initialRedirectMessage)

	// Vary based on query string.
	const location = useLocation()
	const urlParams = new URLSearchParams(location.search)
	if (!urlParams.get('limit')) {
		urlParams.set('limit', '25')
	}

	useEffect(() => {
		axios({
			data: {},
			headers: {
				Authorization: `Bearer ${localStorage['token']}`,
			},
			method: 'GET',
			url: `${APIHostname}/api/v1/users/${id}`,
		})
			.then((response: any) => {
				setUser(response.data)
				setUserLoaded(true)
			})
			.catch((error: any) => {
				switch (error?.response?.status) {
				case 401:
					LogOff('token-expired')
					return
				case 403:
					setErrorMessage(
						<>
								Error: You do not have permission to view users.
						</>
					)
					break
				default:
					if (error?.response?.data?.error) {
						setErrorMessage(
							error?.response?.data?.link ? (
								<Link to={error?.response?.data?.link}>
										Error:
									{error?.response?.data?.error}
								</Link>
							) : (
								<>{error?.response?.data?.error}</>
							)
						)
					} else {
						setErrorMessage(<>Error: Unknown error.</>)
							console.error(error) // eslint-disable-line
					}
				}
			})

		axios({
			data: {},
			headers: {
				Authorization: `Bearer ${localStorage['token']}`,
			},
			method: 'GET',
			url: `${APIHostname}/api/v1/users/${id}/history${
				location.search
			}?${EscapeURIParameters(urlParams.toString())}&view=browse`,
		})
			.then((response: any) => {
				setHistory(response.data.historyRecords)
				setHistoryCount(response.data.historyRecord_count)
				setHistoryLoaded(true)
			})
			.catch((error: any) => {
				switch (error?.response?.status) {
				case 401:
					LogOff('token-expired')
					return
				case 403:
					setErrorMessage(
						<>
								Error: You do not have permission to view users.
						</>
					)
					break
				default:
					if (error?.response?.data?.error) {
						setErrorMessage(
							error?.response?.data?.link ? (
								<Link to={error?.response?.data?.link}>
										Error:
									{error?.response?.data?.error}
								</Link>
							) : (
								<>{error?.response?.data?.error}</>
							)
						)
					} else {
						setErrorMessage(<>Error: Unknown error.</>)
							console.error(error) // eslint-disable-line
					}
				}
			})
	}, [location])

	const UserLeftMenuItems = [
		{
			icon: UserIcon,
			requiredPermissions: ['user.read'],
			text: 'USER INFO',
			to: `/users/${id}`,
		},
		{
			icon: UserIcon,
			requireDeveloperMode: true,
			requiredPermissions: ['user.generateapitoken'],
			text: 'USER API TOKENS',
			to: `/users/${id}/api`,
		},
		{
			icon: InboxIcon,
			requiredPermissions: ['user.read'],
			text: 'USER NOTIFICATIONS',
			to: `/users/${id}/notifications`,
		},
		{
			icon: UserHistoryIcon,
			requiredPermissions: ['user.read'],
			text: 'USER HISTORY',
			to: `/users/${id}/history`,
		},
	]

	const UserHistoryBottomTabItems = [
		{
			icon: DownArrowIcon,
			text: `USER #${id} HISTORY`,
			to: `/users/${id}/history`,
		},
	]

	if (!userLoaded || !historyLoaded) {
		if (errorMessage) {
			return (
				<DefaultLayout
					bottomTabItems={UserHistoryBottomTabItems}
					leftMenuItems={UserLeftMenuItems}
					showPrint={true}
					tabMenuItems={UserTabMenuItems}
					topTabItems={GlobalTopTabItems}
				>
					<div className={styles.userHistoryContainer}>
						<div className={styles.redirectMessage}>
							{redirectMessage}
						</div>
						<div className={styles.errorMessage}>
							{errorMessage}
						</div>
					</div>
				</DefaultLayout>
			)
		}

		return <div className="loading">Loading...</div>
	}

	sessionStorage.removeItem('redirect-message')
	return (
		<DefaultLayout
			bottomTabItems={UserHistoryBottomTabItems}
			leftMenuItems={UserLeftMenuItems}
			showPrint={true}
			tabMenuItems={UserTabMenuItems}
			topTabItems={GlobalTopTabItems}
		>
			<div className={styles.userHistoryContainer}>
				<UserSummary user={user} />

				<div className={styles.redirectMessage}>{redirectMessage}</div>
				<div className={styles.errorMessage}>
					{errorMessage}
					{!user?.active ? <>This user has been deleted.</> : <></>}
				</div>

				<div className={styles.navTable}>
					<NavTable
						columns={[
							{
								control: Control.Hyperlink,
								id: 'link',
								title: 'Link',
							},
							{
								id: 'version',
								title: 'Version',
							},
							{
								id: 'action',
								title: 'Action',
							},
							{
								id: 'element',
								title: 'Element',
							},
							{
								id: 'original',
								title: 'Original Value',
							},
							{
								id: 'new',
								title: 'New Value',
							},
							{
								control: Control.User,
								id: 'created_by',
								title: 'User',
							},
							{
								control: Control.DateTime,
								id: 'created',
								title: 'Date/Time',
							},
						]}
						count={historyCount}
						data={history}
						filters={JSON.parse(urlParams.get('filters') ?? '[]')}
						includeDelete={false}
						includeExport={false}
						primaryDisplayColumn=""
						primaryKey=""
					/>
				</div>
			</div>
		</DefaultLayout>
	)
}

export const UserTabMenuItems = []
