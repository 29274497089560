import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'

import ACIIcon from '../../../../../../assets/icons/aci.svg'
import AMSIcon from '../../../../../../assets/icons/ams.svg'
import ArrivalIcon from '../../../../../../assets/icons/arrival-notice.svg'
import DownArrowIcon from '../../../../../../assets/icons/arrow-down.svg'
import BookingIcon from '../../../../../../assets/icons/booking.svg'
import DocumentsIcon from '../../../../../../assets/icons/documents.svg'
import ShipmentHistoryIcon from '../../../../../../assets/icons/history.svg'
import ISFIcon from '../../../../../../assets/icons/isf.svg'
import NRAIcon from '../../../../../../assets/icons/open-nra.svg'
import RemarksIcon from '../../../../../../assets/icons/remarks.svg'
import ShipmentTrackingIcon from '../../../../../../assets/icons/tracking.svg'
import { FormatAddress } from '../../../../../../components/Form/Controls/OrganizationTextbox'
import {
	EditForm,
	ParsedFormData,
} from '../../../../../../components/Form/EditForm'
import { ShipmentSummary } from '../../../../../../components/ShipmentSummary'
import { GlobalTopTabItems } from '../../../../../../components/Tabs'
import { DefaultLayout } from '../../../../../../layouts/DefaultLayout'
import { APIHostname, HasPermission, LogOff } from '../../../../../../Profile'
import { ArrivalNoticeOverrideLayout } from '../../../../../../types/data/ArrivalNoticeOverride'
import { FormatDateMMDDYYYY as FormatDateMMDDYYYY } from '../../../../../../Utils'
import styles from '../ShipmentArrivalNotice.module.scss'

export const ShipmentArrivalNoticeOverride = () => {
	const { id } = useParams()

	const [shipment, setShipment]: any = useState(null)
	const [unitsMap, setUnitsMap]: any = useState(null)
	const [partner, setPartner]: any = useState(null)
	const [shipmentLoaded, setShipmentLoaded] = useState(false)
	const [unitsLoaded, setUnitsLoaded] = useState(false)
	const [errorMessage, setErrorMessage] = useState(<></>)
	const initialRedirectMessage = sessionStorage['redirect-message']
	const [redirectMessage] = useState(initialRedirectMessage)

	// Vary based on query string.
	const location = useLocation()

	const countries = useQuery({
		queryFn: () =>
			fetch(
				`${APIHostname}/api/v1/countries?sorts=[{"field":"name","order":"ASC"}]`,
				{
					headers: {
						Authorization: `Bearer ${localStorage['token']}`,
					},
				}
			).then((response) => response.json()),
		queryKey: ['countries'],
		refetchInterval: 0,
		refetchOnWindowFocus: false,
	})

	useEffect(() => {
		axios({
			data: {},
			headers: {
				Authorization: `Bearer ${localStorage['token']}`,
			},
			method: 'GET',
			url: `${APIHostname}/api/v1/shipments/${id}`,
		})
			.then((shipmentResponse: any) => {
				setErrorMessage(<></>)
				shipmentResponse.data.save = true

				axios({
					data: {},
					headers: {
						Authorization: `Bearer ${localStorage['token']}`,
					},
					method: 'GET',
					url: `${APIHostname}/api/v1/partners/${shipmentResponse.data.destination_partner}`,
				})
					.then((partnerResponse: any) => {
						setPartner(partnerResponse.data)
						if (partnerResponse.data.filename_prefix) {
							shipmentResponse.data.filename = `${partnerResponse.data.filename_prefix} Arrival Notice ${shipmentResponse.data.hbl}.pdf`
						} else {
							shipmentResponse.data.filename = `GOAL Arrival Notice ${shipmentResponse.data.hbl}.pdf`
						}
						setShipment(shipmentResponse.data)

						setShipmentLoaded(true)
					})
					.catch((error: any) => {
						switch (error?.response?.status) {
						case 401:
							LogOff('token-expired')
							return
						case 403:
							setErrorMessage(
								<>
										Error: You do not have permission to
										view partners.
								</>
							)
							break
						case 404:
							setPartner({
								id: shipmentResponse.data
									.destination_partner,
							})
							setShipmentLoaded(true)
							break
						default:
							if (error?.response?.data?.error) {
								setErrorMessage(
									error?.response?.data?.link ? (
										<Link
											to={error?.response?.data?.link}
										>
												Error:
											{error?.response?.data?.error}
										</Link>
									) : (
										<>{error?.response?.data?.error}</>
									)
								)
							} else {
								setErrorMessage(<>Error: Unknown error.</>)
									console.error(error) // eslint-disable-line
							}
						}
					})
			})
			.catch((error: any) => {
				switch (error?.response?.status) {
				case 401:
					LogOff('token-expired')
					return
				case 403:
					setErrorMessage(
						<>
								Error: You do not have permission to edit
								shipment documents.
						</>
					)
					break
				default:
					if (error?.response?.data?.error) {
						setErrorMessage(
							error?.response?.data?.link ? (
								<Link to={error?.response?.data?.link}>
										Error:
									{error?.response?.data?.error}
								</Link>
							) : (
								<>{error?.response?.data?.error}</>
							)
						)
					} else {
						setErrorMessage(<>Error: Unknown error.</>)
							console.error(error) // eslint-disable-line
					}
				}
			})

		axios({
			data: {},
			headers: {
				Authorization: `Bearer ${localStorage['token']}`,
			},
			method: 'GET',
			url: `${APIHostname}/api/v1/units?sorts=[{"field":"description","order":"ASC"}]`,
		})
			.then((unitsResponse: any) => {
				setErrorMessage(<></>)
				const unitsMap: { [key: string]: string } = {}
				unitsResponse.data.units.forEach((unit: any) => {
					unitsMap[unit.id] = unit.name
				})
				setUnitsMap(unitsMap)
				setUnitsLoaded(true)
			})
			.catch((error: any) => {
				switch (error?.response?.status) {
				case 401:
					LogOff('token-expired')
					return
				case 403:
					setErrorMessage(
						<>
								Error: You do not have permission to edit
								shipment documents.
						</>
					)
					break
				default:
					if (error?.response?.data?.error) {
						setErrorMessage(
							error?.response?.data?.link ? (
								<Link to={error?.response?.data?.link}>
										Error:
									{error?.response?.data?.error}
								</Link>
							) : (
								<>{error?.response?.data?.error}</>
							)
						)
					} else {
						setErrorMessage(<>Error: Unknown error.</>)
							console.error(error) // eslint-disable-line
					}
				}
			})
	}, [location])

	const arrivalNoticeTemplate = shipment?.arrival_notice_template
	const usDestination =
		shipment?.discharge_port?.toLowerCase().startsWith('us') ||
		shipment?.final_port_of_entry?.toLowerCase().startsWith('us')
	const caDestination =
		shipment?.discharge_port?.toLowerCase().startsWith('ca') ||
		shipment?.final_port_of_entry?.toLowerCase().startsWith('ca')
	const ShipmentLeftMenuItems = [
		{
			icon: BookingIcon,
			text: 'BOOKING',
			to: `/shipments/${id}`,
		},
		{
			count: shipment?.unread_remarks ?? null,
			icon: RemarksIcon,
			text: 'REMARKS',
			to: `/shipments/${id}/remarks`,
		},
		{
			disabled: !usDestination && !shipment?.ams_status,
			disabledReason: 'Shipment does not have US destination ports.',
			icon: AMSIcon,
			requiredRole: 'administrator||memberpartner||partner',
			text: 'AMS',
			to: `/shipments/${id}/ams`,
		},
		{
			disabled: !caDestination && !shipment?.aci_status,
			disabledReason: 'Shipment does not have CA destination ports.',
			icon: ACIIcon,
			requiredRole: 'administrator||memberpartner||partner',
			text: 'ACI',
			to: `/shipments/${id}/aci`,
		},
		{
			disabled: !usDestination,
			disabledReason: 'Shipment does not have US destination ports.',
			icon: ISFIcon,
			requiredRole: 'administrator||memberpartner||partner',
			text: 'ISF',
			to: `/shipments/${id}/isf`,
		},
		{
			icon: DocumentsIcon,
			text: 'DOCUMENTS',
			to: `/shipments/${id}/documents`,
		},
		{
			icon: DocumentsIcon,
			requiredRole: 'administrator||memberpartner||partner',
			text: 'HBL PRINT',
			to: `/shipments/${id}/hbl`,
		},
		{
			disabled: !usDestination,
			disabledReason: 'Shipment does not have US destination ports.',
			icon: NRAIcon,
			requiredRole: 'administrator||memberpartner||partner',
			text: 'NRA',
			to: `/shipments/${id}/nra`,
		},
		{
			disabled: !arrivalNoticeTemplate,
			disabledReason:
				'Destination partner does not have an Arrival Notice template.',
			icon: ArrivalIcon,
			requiredRole: 'administrator||memberpartner||partner',
			text: 'ARRIVAL NOTICE',
			to: `/shipments/${id}/arrival_notice${
				arrivalNoticeTemplate == 'default'
					? ''
					: `/${arrivalNoticeTemplate}`
			}`,
		},
		{
			icon: ShipmentTrackingIcon,
			requiredRole: 'administrator||memberpartner||partner||organization',
			text: 'SHIPMENT TRACKING',
			to: `/shipments/${id}/tracking`,
		},
		{
			icon: ShipmentHistoryIcon,
			text: 'SHIPMENT HISTORY',
			to: `/shipments/${id}/history`,
		},
	]

	const ShipmentBottomTabItems = [
		{
			icon: DownArrowIcon,
			text: `SHIPMENT #${id} ARRIVAL NOTICE`,
			to: `/shipments/${id}/arrival_notice${
				arrivalNoticeTemplate == 'default'
					? ''
					: `/${arrivalNoticeTemplate}`
			}`,
		},
	]

	if (!shipmentLoaded || !unitsLoaded) {
		if (errorMessage) {
			return (
				<DefaultLayout
					bottomTabItems={ShipmentBottomTabItems}
					leftMenuItems={ShipmentLeftMenuItems}
					showPrint={true}
					tabMenuItems={ShipmentTabMenuItems}
					topTabItems={GlobalTopTabItems}
				>
					<div className={styles.shipmentArrivalNoticeContainer}>
						<div className={styles.redirectMessage}>
							{redirectMessage}
						</div>
						<div className={styles.errorMessage}>
							{errorMessage}
						</div>
					</div>
				</DefaultLayout>
			)
		}

		return <div className="loading">Loading...</div>
	}

	const countryNames = countries.data.countries.reduce(
		(acc: any, country: any) => {
			acc[country.code] = country.name
			return acc
		},
		{}
	)

	let partnerMessage = <></>
	let readOnly = !HasPermission('shipment', 'update')
	if (!partner.arrival_notice_template) {
		partnerMessage = (
			<>
				Partner #{partner.id} {partner.name} is not associated with an
				Arrival Notice template.
			</>
		)
		readOnly = true
	}

	shipment.override_hbl = shipment.hbl
	shipment.override_date = FormatDateMMDDYYYY(new Date())
	shipment.override_reference = shipment.destination_partner_reference
	shipment.override_shipper = shipment.shipper
	shipment.override_shipper_name = shipment.shipper_name
	shipment.override_shipper_address = FormatAddress(
		shipment,
		'shipper',
		countryNames
	)
	shipment.override_consignee = shipment.consignee
	shipment.override_consignee_name = shipment.consignee_name
	shipment.override_consignee_address = FormatAddress(
		shipment,
		'consignee',
		countryNames
	)
	shipment.override_notify = shipment.notify
	shipment.override_notify_name = shipment.notify_name
	shipment.override_notify_address = FormatAddress(
		shipment,
		'notify',
		countryNames
	)
	shipment.override_mbl = shipment.mbl
	if (shipment.mbl_carrier_scac) {
		shipment.override_mbl += `/${shipment.mbl_carrier_scac}`
	}
	shipment.override_vessel = shipment.vessel
	shipment.override_voyage = shipment.voyage
	shipment.override_ams_scac = shipment.hbl_carrier_scac
	shipment.override_ams = shipment.hbl
	shipment.override_port_of_load = shipment.loading_port_name
	shipment.override_port_of_discharge = shipment.discharge_port_name
	try {
		shipment.override_port_of_discharge_eta = FormatDateMMDDYYYY(
			shipment.discharge_port_eta
		)
	} catch (e) {
		console.error(e) // eslint-disable-line
	}
	shipment.override_place_of_delivery = shipment.place_of_delivery_name
	try {
		shipment.override_place_of_delivery_eta = FormatDateMMDDYYYY(
			shipment.place_of_delivery_eta
		)
	} catch (e) {
		console.error(e) // eslint-disable-line
	}
	shipment.override_kgs_cbm_pieces = `${shipment.total_gross_weight} KGS / ${shipment.total_cbm} CBM / ${shipment.total_packages} PCS`
	shipment.override_container_numbers = shipment.containers
		.map((container: any) => {
			return `${container.number} / ${unitsMap[container.unit]} / ${
				container.gross_weight
			} KGS / ${container.cbm} CBM / ${container.packages} PCS`
		})
		.join('\r\n')
	return (
		<DefaultLayout
			bottomTabItems={ShipmentBottomTabItems}
			leftMenuItems={ShipmentLeftMenuItems}
			showPrint={true}
			tabMenuItems={ShipmentTabMenuItems}
			topTabItems={GlobalTopTabItems}
		>
			<div className={styles.shipmentArrivalNoticeContainer}>
				<ShipmentSummary shipment={shipment} />
				<div className={styles.redirectMessage}>{redirectMessage}</div>
				<div className={styles.errorMessage}>
					{errorMessage}
					{errorMessage}{' '}
					{!shipment?.active ? (
						<>This shipment has been deleted.</>
					) : (
						<></>
					)}
				</div>
				<div className={styles.partnerMessage}>{partnerMessage}</div>
				<EditForm
					data={shipment}
					layout={ArrivalNoticeOverrideLayout}
					name="document-form"
					onSubmit={async (formData: FormData) => {
						const parsedFormData = await ParsedFormData(formData, [
							'charges',
						])

						// Create arrival notice
						axios({
							data: parsedFormData,
							headers: {
								Authorization: `Bearer ${localStorage['token']}`,
								'Content-Type': 'application/json',
							},
							method: 'POST',
							url: `${APIHostname}/api/v1/shipments/${id}/arrival_notice/pdf${
								partner.arrival_notice_template == 'default' ||
								partner.arrival_notice_template == 'override'
									? ''
									: `/${partner.arrival_notice_template}`
							}`,
						})
							.then((response: any) => {
								sessionStorage[
									'redirect-message'
								] = `Document #${response.data.document_id} for arrival notice created.`

								window.history.replaceState(
									{},
									'',
									`/shipments/${response.data.shipment_id}/documents`
								)
								document.location.reload()
							})
							.catch((error: any) => {
								switch (error?.response?.status) {
								case 401:
									LogOff('token-expired')
									return
								case 403:
									setErrorMessage(
										<>
												Error: You do not have
												permission to edit arrival
												notices.
										</>
									)
									break
								default:
									if (error?.response?.data?.error) {
										setErrorMessage(
											error?.response?.data?.link ? (
												<Link
													to={
														error?.response
															?.data?.link
													}
												>
														Error:
													{
														error?.response
															?.data?.error
													}
												</Link>
											) : (
												<>
													{
														error?.response
															?.data?.error
													}
												</>
											)
										)
									} else {
										setErrorMessage(
											<>Error: Unknown error.</>
										)
											console.error(error) // eslint-disable-line
									}
								}
							})
					}}
					previewAPIData={async (formData: FormData) => {
						const parsedFormData = await ParsedFormData(formData, [
							'charges',
						])

						// Save ACI data
						parsedFormData['id'] = parseInt(id ?? '0')
						return {
							data: JSON.stringify(parsedFormData, null, 4),
							method: 'POST',
							url: `${APIHostname}/api/v1/shipments/${id}/arrival_notice/pdf${
								partner.arrival_notice_template == 'default' ||
								partner.arrival_notice_template == 'override'
									? ''
									: `/${partner.arrival_notice_template}`
							}`,
						}
					}}
					readOnly={readOnly || shipment.lock}
					submitButtonLabel="GENERATE ARRIVAL NOTICE"
				></EditForm>{' '}
			</div>
		</DefaultLayout>
	)
}

export const ShipmentTabMenuItems = []
