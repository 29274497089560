import { FieldProps } from '../../Field'

export const EditHotListed = (props: FieldProps) => {
	return (
		<select
			className={`${
				props.field.HardRequired && !props.isSearch
					? 'hardRequired'
					: ''
			} ${
				props.field.SoftRequired && !props.isSearch
					? 'softRequired'
					: ''
			}`}
			data-hardrequired={props.field.HardRequired && !props.isSearch}
			data-nullable={props.field.Nullable}
			data-softrequired={props.field.SoftRequired && !props.isSearch}
			defaultValue={props.value}
			name={props.field.Property}
			required={props.field.HardRequired && !props.isSearch}
			title={props.field.Label?.replace(':', '')}
		>
			<option key="any" value="">
				Any
			</option>
			<option key="true" value="true">
				Hot Listed
			</option>
			<option key="false" value="false">
				Not Hot Listed
			</option>
		</select>
	)
}
