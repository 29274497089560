import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { ChangeEvent, ReactElement, useState } from 'react'
import { Link } from 'react-router-dom'

import NRAIcon from '../../../../assets/icons/nra.svg'
import {
	APIHostname,
	LogOff,
	ProfileName,
	ProfileUserID,
} from '../../../../Profile'
import Control from '../../../../types/Control'
import { EncodedStringify, Filter } from '../../../../types/Filter'
import { RandomID } from '../../../../Utils'
import { CurrencyFormatter } from '../../../NavTable'
import { DateOnly } from '../../EditForm'
import { EditField, FieldProps } from '../../Field'
import { EditUser } from '../User'
import styles from './NRA.module.scss'

export const EditNRA = (props: FieldProps) => {
	const [previews, setPreviews] = useState(<></>)
	const [value, setValue] = useState(props.value ?? 0)
	const [searchAheadID] = useState(RandomID(16))

	const { isPending, error, data } = useQuery({
		queryFn: () =>
			fetch(
				`${APIHostname}/api/v1/container_types?sorts=[{"field":"id","order":"ASC"}]`,
				{
					headers: {
						Authorization: `Bearer ${localStorage['token']}`,
					},
				}
			).then((response) => response.json()),
		queryKey: ['container_types'],
		refetchInterval: 0,
		refetchOnWindowFocus: false,
	})

	if (error) {
		console.error(error) // eslint-disable-line
	}

	if (isPending) {
		return <>Loading...</>
	}

	const search = (target: HTMLInputElement) => {
		if (target.value) {
			const tomorrow = new Date(
				new Date().getTime() + 24 * 60 * 60 * 1000
			)
			const filters: Filter[] = [
				{
					aggregate: 'or',
					children: [
						{
							field: 'reference_name',
							operator: 'contains',
							value: target.value,
						},
						{
							field: 'reference_number',
							operator: 'contains',
							value: target.value,
						},
					],
				},
				{
					field: 'replaced_by',
					operator: 'null',
					value: '',
				},
				{
					aggregate: 'or',
					children: [
						{
							field: 'expiration_date',
							operator: 'gt',
							value: `${tomorrow.getFullYear()}-${(
								tomorrow.getMonth() + 1
							)
								.toString()
								.padStart(2, '0')}-${tomorrow.getDate()}`,
						},
						{
							field: 'expiration_date',
							operator: 'null',
						},
					],
				},
			]
			if (props.data.consignee) {
				filters.push({
					field: 'organization',
					operator: 'equals',
					value: props.data.consignee.toString(),
				})
			}
			axios({
				data: {},
				headers: {
					Authorization: `Bearer ${localStorage['token']}`,
				},
				method: 'GET',
				url: `${APIHostname}/api/v1/nras?filters=${EncodedStringify(
					filters
				)}&sorts=[{"field":"name","order":"ASC"}]&limit=250`,
			})
				.then((response: any) => {
					if (response.data.nras) {
						const rows: ReactElement[] = []
						response.data.nras.forEach((p: any) => {
							rows.push(
								<tr
									key={`preview${p.id}`}
									onClick={(event: any) => {
										setValue(p.id)
										;(
											document.getElementById(
												`${props.field.Property}_link`
											) as HTMLAnchorElement
										).href = `/nras/${p.id}`
										;(
											document.getElementById(
												`${props.field.Property}_logo`
											) as HTMLImageElement
										).src = NRAIcon
										;(
											document.getElementById(
												`${props.field.Property}_name`
											) as HTMLInputElement
										).value = `[${p.reference_number}] ${p.reference_name}`
										if (p.organization_name) {
											(
												document.getElementById(
													`${props.field.Property}_organization_name`
												) as HTMLInputElement
											).value = p.organization_name
										}
										if (p.presented_to) {
											(
												document.getElementById(
													`${props.field.Property}_presented_to`
												) as HTMLInputElement
											).value = p.presented_to
										}
										if (p.reference_name) {
											(
												document.getElementById(
													`${props.field.Property}_reference_name`
												) as HTMLInputElement
											).value = p.reference_name
										}
										if (p.reference_number) {
											(
												document.getElementById(
													`${props.field.Property}_reference_number`
												) as HTMLInputElement
											).value = p.reference_number
										}
										if (p.presented_by_name) {
											(
												document.getElementById(
													`${props.field.Property}_presented_by`
												) as HTMLInputElement
											).value = p.presented_by_name
										}
										if (p.origin) {
											(
												document.getElementById(
													`${props.field.Property}_origin`
												) as HTMLInputElement
											).value = `[${p.origin}] ${p.origin_name}`
										}
										if (p.destination) {
											(
												document.getElementById(
													`${props.field.Property}_destination`
												) as HTMLInputElement
											).value = `[${p.destination}] ${p.destination_name}`
										}
										if (p.effective_date) {
											(
												document.getElementById(
													`${props.field.Property}_effective_date`
												) as HTMLInputElement
											).value = DateOnly(p.effective_date)
										}
										if (p.expiration_date) {
											(
												document.getElementById(
													`${props.field.Property}_expiration_date`
												) as HTMLInputElement
											).value = DateOnly(
												p.expiration_date
											)
										}
										(
											document.getElementById(
												`${props.field.Property}_linked_by`
											) as HTMLInputElement
										).value = ProfileUserID()?.toString()
										;(
											document.getElementById(
												`${props.field.Property}_linked_by_name`
											) as HTMLInputElement
										).value = ProfileName()
										;(
											document.getElementById(
												`${props.field.Property}_linked_by_link`
											) as HTMLAnchorElement
										).href = `/users/${ProfileUserID()}`
										;(
											document.getElementById(
												`${props.field.Property}_linked_by_image_preview`
											) as HTMLImageElement
										).src = `${APIHostname}/api/v1/users/${ProfileUserID()}/profile_picture`
										const d = new Date()
										;(
											document.getElementById(
												`${props.field.Property}_linked`
											) as HTMLInputElement
										).value = `${d.getFullYear()}-${(
											d.getMonth() + 1
										)
											.toString()
											.padStart(2, '0')}-${d
											.getDate()
											.toString()
											.padStart(2, '0')}`
										let remarksTable = `<table class="${styles.remarksTable}"><thead><tr><th>UNIT</th><th>RATE</th><th>REMARKS/COMMODITY</th></tr></thead><tbody>`
										if (p.rates && p.rates.length > 0) {
											p.rates.forEach((rate: any) => {
												remarksTable += `<tr><td>${
													data.container_types.filter(
														(
															containerType: any
														) => {
															return (
																containerType.id ==
																rate.type
															)
														}
													)[0]?.name
												}</td><td>${CurrencyFormatter.format(
													rate.rate
												)}</td><td>${
													rate.remarks
												}</td></tr>`
											})
										} else {
											remarksTable +=
												'<tr><td colspan="3">No rates found.</td></tr>'
										}
										remarksTable += '</tbody></table>'
										;(
											document.getElementById(
												`${props.field.Property}_remarks_table`
											) as HTMLInputElement
										).innerHTML = remarksTable
										if (p.expiration_date) {
											(
												document.getElementById(
													`${props.field.Property}_expiration_date`
												) as HTMLInputElement
											).value = DateOnly(
												p.expiration_date
											)
										}
										if (p.additional_charges_note) {
											(
												document.getElementById(
													`${props.field.Property}_additional_charges_note`
												) as HTMLInputElement
											).value = DateOnly(
												p.additional_charges_note
											)
										}
										(
											document.getElementById(
												`${props.field.Property}_name`
											) as HTMLInputElement
										).dataset.value = `[${p.reference_number}] ${p.reference_name}`
										setPreviews(<></>)

										if (props.onChange) {
											props.onChange(event)
										}
									}}
								>
									<td>
										<img
											onError={(event: any) => {
												if (
													event.target.src == NRAIcon
												) {
													event.target.style.display =
														'none'
												} else {
													event.target.src = NRAIcon
												}
											}}
											onLoad={(event: any) => {
												event.target.style.display = ''
											}}
											src={
												p.image_preview
													? p.image_preview
													: NRAIcon
											}
										/>
									</td>
									<td>{p.id}</td>
									<td>{p.reference_number}</td>
									<td>{p.reference_name}</td>
								</tr>
							)
						})
						const searchAhead =
							document.getElementById(searchAheadID)
						if (searchAhead) {
							searchAhead.style.left = `${target.offsetLeft}px`
							searchAhead.style.top = `${
								target.offsetTop + target.offsetHeight
							}px`
							searchAhead.style.width = `${Math.max(
								target.offsetWidth,
								400
							)}px`
						}
						setPreviews(
							<table>
								<tbody>{rows}</tbody>
							</table>
						)
					} else {
						setPreviews(<></>)
					}
				})
				.catch((error: any) => {
					switch (error?.response?.status) {
					case 401:
						LogOff('token-expired')
						return
					default:
							console.error(error) // eslint-disable-line
					}
					setPreviews(<></>)
				})
		} else {
			setPreviews(<></>)
		}
	}

	const defaultValue = props.value
		? `[${props.data[`${props.field.Property}_reference_number`]}] ${
			props.data[`${props.field.Property}_reference_name`]
		  }`
		: ''
	const remarksTableRows: ReactElement[] = []
	// eslint-disable-next-line no-constant-condition
	for (let i = 0; true; i++) {
		if (props.data[`${props.field.Property}_rates_${i}_active`]) {
			remarksTableRows.push(
				<tr key={`rate${i}`}>
					<td>
						{
							data.container_types.filter(
								(containerType: any) => {
									return (
										containerType.id ==
										props.data[
											`${props.field.Property}_rates_${i}_type`
										]
									)
								}
							)[0]?.name
						}
					</td>
					<td>
						{CurrencyFormatter.format(
							props.data[
								`${props.field.Property}_rates_${i}_rate`
							]
						)}
					</td>
					<td>
						{
							props.data[
								`${props.field.Property}_rates_${i}_remarks`
							]
						}
					</td>
				</tr>
			)
		} else {
			break
		}
	}
	if (remarksTableRows.length == 0) {
		remarksTableRows.push(
			<tr key="noRates">
				<td colSpan={3}>No rates found.</td>
			</tr>
		)
	}
	return (
		<div className={styles.fieldOuterContainer}>
			<div className={styles.label}>
				{props.field.Label}
				{props.field.HardRequired && !props.isSearch ? (
					<>
						{'\u00A0'}
						<span
							className="hardRequired"
							title="REQUIRED IN ORDER TO SAVE"
						>
							*
						</span>
					</>
				) : (
					<></>
				)}
				{props.field.SoftRequired && !props.isSearch ? (
					<>
						{'\u00A0'}
						<span className="softRequired" title="REQUIRED">
							*
						</span>
					</>
				) : (
					<></>
				)}{' '}
			</div>
			<div className={`${styles.searchAheadBox} pseudoInput`}>
				<Link
					id={`${props.field.Property}_link`}
					rel="noreferrer"
					target="_blank"
					to={`/nra/${props.data[`${props.field.Property}_id`]}`}
				>
					<img
						id={`${props.field.Property}_logo`}
						onError={(event: any) => {
							if (event.target.src == NRAIcon) {
								event.target.style.display = 'none'
							} else {
								event.target.src = NRAIcon
							}
						}}
						onLoad={(event: any) => {
							event.target.style.display = ''
						}}
						src={
							props.data[`${props.field.Property}_image_preview`]
								? props.data[
									`${props.field.Property}_image_preview`
								  ]
								: NRAIcon
						}
					/>
				</Link>
				<input
					autoComplete="off"
					className={`${
						props.field.HardRequired && !props.isSearch
							? 'hardRequired'
							: ''
					} ${
						props.field.SoftRequired && !props.isSearch
							? 'softRequired'
							: ''
					}`}
					data-value={defaultValue}
					defaultValue={defaultValue}
					id={`${props.field.Property}_name`}
					onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
						search(e.target)
						if (props.onChange) {
							props.onChange(e)
						}
					}}
					onFocus={(e: ChangeEvent) => {
						search(e.target as HTMLInputElement)
					}}
					placeholder=" "
					readOnly={props.readOnly || props.field.ReadOnly}
					required={props.field.HardRequired && !props.isSearch}
					title={props.field.Label?.replace(':', '')}
					type="text"
				/>
				<div className={styles.previews} id={searchAheadID}>
					{previews}
				</div>
			</div>
			<input
				data-hardrequired={props.field.HardRequired && !props.isSearch}
				data-nullable={true}
				data-number={true}
				data-softrequired={props.field.SoftRequired && !props.isSearch}
				id={`${props.field.Property}_id`}
				name={`${props.field.Property}_id`}
				readOnly={true}
				type="hidden"
				value={value}
			/>
			<div className="organizationMetadata">
				<div className={styles.halfWidthContainer}>
					<div className={styles.halfWidth}>
						<EditUser
							data={props.data}
							field={{
								Label: 'LINKED BY:',
								Property: `${props.field.Property}_linked_by`,
							}}
							isSearch={props.isSearch}
							readOnly={true}
							value={
								props.data
									? props.data[
										`${props.field.Property}_linked_by`
									  ]
									: ''
							}
						/>
					</div>
					<div className={styles.halfWidth}>
						<EditField
							data={props.data ?? {}}
							field={{
								Control: Control.Date,
								Label: 'LINKED:',
								Property: `${props.field.Property}_linked`,
							}}
							isSearch={props.isSearch}
							readOnly={true}
							value={
								props.data
									? DateOnly(
										props.data[
											`${props.field.Property}_linked`
										]
									  )
									: ''
							}
						/>
					</div>
				</div>
				<div className={styles.quarterWidthContainer}>
					<div className={styles.quarterWidth}>
						<div className={styles.label}>CONSIGNEE:</div>
						<input
							defaultValue={
								props.data
									? `${
										props.data[
											`${props.field.Property}_organization_name`
										] ?? ''
									  }`
									: ''
							}
							id={`${props.field.Property}_organization_name`}
							readOnly={true}
							tabIndex={-1}
							title="CONSIGNEE"
							type="text"
						/>
					</div>
					<div className={styles.quarterWidth}>
						<div className={styles.label}>PRESENTED TO:</div>
						<input
							defaultValue={
								props.data
									? `${
										props.data[
											`${props.field.Property}_presented_to`
										] ?? ''
									  }`
									: ''
							}
							id={`${props.field.Property}_presented_to`}
							readOnly={true}
							tabIndex={-1}
							title="ADDRESS"
							type="text"
						/>
					</div>
					<div className={styles.quarterWidth}>
						<div className={styles.label}>REFERENCE NAME:</div>
						<input
							defaultValue={
								props.data
									? `${
										props.data[
											`${props.field.Property}_reference_name`
										] ?? ''
									  }`
									: ''
							}
							id={`${props.field.Property}_reference_name`}
							readOnly={true}
							tabIndex={-1}
							title="REFERENCE NAME"
							type="text"
						/>
					</div>
					<div className={styles.quarterWidth}>
						<div className={styles.label}>NRA FILE NUMBER:</div>
						<input
							defaultValue={
								props.data
									? `${
										props.data[
											`${props.field.Property}_reference_number`
										] ?? ''
									  }`
									: ''
							}
							id={`${props.field.Property}_reference_number`}
							readOnly={true}
							tabIndex={-1}
							title="NRA FILE NUMBER"
							type="text"
						/>
					</div>
				</div>
				<div className={styles.quarterWidthContainer}>
					<div className={styles.quarterWidth}>
						<div className={styles.label}>PRESENTED BY:</div>
						<input
							defaultValue={
								props.data
									? `${
										props.data[
											`${props.field.Property}_presented_by`
										] ?? ''
									  }`
									: ''
							}
							id={`${props.field.Property}_presented_by`}
							readOnly={true}
							tabIndex={-1}
							title="PRESENTED BY"
							type="text"
						/>
					</div>
					<div className={styles.quarterWidth}>
						<div className={styles.label}>ORIGIN:</div>
						<div className={styles.countryBox}>
							<span
								className={`fi fi-${
									props.data
										? props.data[
											`${props.field.Property}_origin`
										  ]
											?.toLowerCase()
											.substring(0, 2) ?? ''
										: ''
								} ${styles.flag}`}
								id={`${props.field.Property}_flag`}
							></span>
							<input
								defaultValue={
									props.data
										? `${
											props.data[
												`${props.field.Property}_origin`
											] ?? ''
										  }`
										: ''
								}
								id={`${props.field.Property}_origin`}
								readOnly={true}
								tabIndex={-1}
								title="ORIGIN"
								type="text"
							/>
						</div>
					</div>
					<div className={styles.quarterWidth}>
						<div className={styles.label}>DESTINATION:</div>
						<div className={styles.countryBox}>
							<span
								className={`fi fi-${
									props.data
										? props.data[
											`${props.field.Property}_destination`
										  ]
											?.toLowerCase()
											.substring(0, 2) ?? ''
										: ''
								} ${styles.flag}`}
								id={`${props.field.Property}_flag`}
							></span>
							<input
								defaultValue={
									props.data
										? `${
											props.data[
												`${props.field.Property}_destination`
											] ?? ''
										  }`
										: ''
								}
								id={`${props.field.Property}_destination`}
								readOnly={true}
								tabIndex={-1}
								title="DESTINATION"
								type="text"
							/>
						</div>
					</div>
					<div className={styles.quarterWidth}></div>
				</div>
				<div className={styles.quarterWidthContainer}>
					<div className={styles.quarterWidth}>
						<div className={styles.label}>EFFECTIVE DATE:</div>
						<input
							defaultValue={
								props.data
									? DateOnly(
										props.data[
											`${props.field.Property}_effective_date`
										] ?? ''
									  )
									: ''
							}
							id={`${props.field.Property}_effective_date`}
							readOnly={true}
							tabIndex={-1}
							title="EFFECTIVE DATE"
							type="text"
						/>
					</div>
					<div className={styles.quarterWidth}>
						<div className={styles.label}>EXPIRATION DATE:</div>
						<input
							defaultValue={
								props.data
									? DateOnly(
										props.data[
											`${props.field.Property}_expiration_date`
										] ?? ''
									  )
									: ''
							}
							id={`${props.field.Property}_expiration_date`}
							readOnly={true}
							tabIndex={-1}
							title="EXPIRATION DATE"
							type="text"
						/>
					</div>
					<div className={styles.quarterWidth}></div>
					<div className={styles.quarterWidth}></div>
				</div>
			</div>
			<div id={`${props.field.Property}_remarks_table`}>
				<table className={styles.remarksTable}>
					<thead>
						<tr>
							<th>UNIT</th>
							<th>RATE</th>
							<th>REMARKS/COMMODITY</th>
						</tr>
					</thead>
					<tbody>{remarksTableRows}</tbody>
				</table>
			</div>
		</div>
	)
}
