import axios from 'axios'
import { ChangeEvent, ReactElement, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDebouncedCallback } from 'use-debounce'

import UserIcon from '../../../../assets/icons/new-user.svg'
import { APIHostname, LogOff } from '../../../../Profile'
import { EncodedStringify } from '../../../../types/Filter'
import { RandomID } from '../../../../Utils'
import { FieldProps } from '../../Field'
import styles from './User.module.scss'

export const EditUser = (props: FieldProps) => {
	const [previews, setPreviews] = useState(<></>)
	const [value, setValue] = useState(props.value ?? 0)
	const [searchAheadID] = useState(RandomID(16))

	const search = (target: HTMLInputElement) => {
		if (target.value) {
			const filters = [
				{
					field: 'name',
					operator: 'contains',
					value: target.value,
				},
			]
			axios({
				data: {},
				headers: {
					Authorization: `Bearer ${localStorage['token']}`,
				},
				method: 'GET',
				url: `${APIHostname}/api/v1/users?filters=${EncodedStringify(
					filters
				)}&sorts=[{"field":"name","order":"ASC"}]&limit=250`,
			})
				.then((response: any) => {
					if (response.data.users) {
						const rows: ReactElement[] = []
						response.data.users.forEach((p: any) => {
							rows.push(
								<tr
									key={`preview${p.id}`}
									onClick={(event: any) => {
										setValue(p.id)
										;(
											document.getElementById(
												`${props.field.Property}_link`
											) as HTMLAnchorElement
										).href = `/users/${p.id}`
										;(
											document.getElementById(
												`${props.field.Property}_image_preview`
											) as HTMLImageElement
										).src = p.image_preview
										;(
											document.getElementById(
												`${props.field.Property}_name`
											) as HTMLInputElement
										).value = p.name
										;(
											document.getElementById(
												`${props.field.Property}_name`
											) as HTMLInputElement
										).dataset.value = p.name
										setPreviews(<></>)

										if (props.onChange) {
											props.onChange(event)
										}
									}}
								>
									<td>
										<img
											onError={(event: any) => {
												if (
													event.target.src == UserIcon
												) {
													event.target.style.display =
														'none'
												} else {
													event.target.src = UserIcon
												}
											}}
											onLoad={(event: any) => {
												event.target.style.display = ''
											}}
											src={
												p.image_preview
													? p.image_preview
													: UserIcon
											}
										/>
									</td>
									<td>{p.id}</td>
									<td>{p.code}</td>
									<td>{p.name}</td>
								</tr>
							)
						})
						const searchAhead =
							document.getElementById(searchAheadID)
						if (searchAhead) {
							searchAhead.style.left = `${target.offsetLeft}px`
							searchAhead.style.top = `${
								target.offsetTop + target.offsetHeight
							}px`
							searchAhead.style.width = `${Math.max(
								target.offsetWidth,
								400
							)}px`
						}
						setPreviews(
							<table>
								<tbody>{rows}</tbody>
							</table>
						)
					} else {
						setPreviews(<></>)
					}
				})
				.catch((error: any) => {
					switch (error?.response?.status) {
					case 401:
						LogOff('token-expired')
						return
					default:
							console.error(error) // eslint-disable-line
					}
					setPreviews(<></>)
				})
		} else {
			setPreviews(<></>)
		}
	}

	const defaultValue = props.value
		? props.data[`${props.field.Property}_name`]
		: ''
	return (
		<div
			className={styles.fieldOuterContainer}
			id={props.field.Property ? `${props.field.Property}_container` : ''}
		>
			<div className={styles.label}>
				{props.field.Label}
				{props.field.Label?.endsWith(':') ? '' : ':'}
				{props.field.HardRequired && !props.isSearch ? (
					<>
						{'\u00A0'}
						<span
							className="hardRequired"
							title="REQUIRED IN ORDER TO SAVE"
						>
							*
						</span>
					</>
				) : (
					<></>
				)}
				{props.field.SoftRequired && !props.isSearch ? (
					<>
						{'\u00A0'}
						<span className="softRequired" title="REQUIRED">
							*
						</span>
					</>
				) : (
					<></>
				)}{' '}
			</div>
			<div className={`${styles.searchAheadBox} pseudoInput`}>
				<Link
					id={`${props.field.Property}_link`}
					rel="noreferrer"
					target="_blank"
					to={`/users/${props.data[props.field.Property]}`}
				>
					<img
						id={`${props.field.Property}_image_preview`}
						onError={(event: any) => {
							if (event.target.src == UserIcon) {
								event.target.style.display = 'none'
							} else {
								event.target.src = UserIcon
							}
						}}
						onLoad={(event: any) => {
							event.target.style.display = ''
						}}
						src={
							props.data[`${props.field.Property}_image_preview`]
								? props.data[`${props.field.Property}`]
								: UserIcon
						}
					/>
				</Link>
				<input
					autoComplete="off"
					className={`${
						props.field.HardRequired && !props.isSearch
							? 'hardRequired'
							: ''
					} ${
						props.field.SoftRequired && !props.isSearch
							? 'softRequired'
							: ''
					}`}
					data-value={defaultValue}
					defaultValue={defaultValue}
					id={`${props.field.Property}_name`}
					onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
						search(e.target)
						if (props.onChange) {
							props.onChange(e)
						}
					}}
					onFocus={useDebouncedCallback((e: ChangeEvent) => {
						search(e.target as HTMLInputElement)
					}, 100)}
					placeholder=" "
					readOnly={props.readOnly || props.field.ReadOnly}
					title={props.field.Label?.replace(':', '')}
					type="text"
				/>
				<div className={styles.previews} id={searchAheadID}>
					{previews}
				</div>
			</div>
			<input
				data-hardrequired={props.field.HardRequired && !props.isSearch}
				data-nullable={true}
				data-number={true}
				data-softrequired={props.field.SoftRequired && !props.isSearch}
				id={props.field.Property}
				name={props.field.Property}
				readOnly={true}
				type="hidden"
				value={value}
			/>
		</div>
	)
}
