import axios from 'axios'
import { useState } from 'react'
import {
	GoogleReCaptcha,
	GoogleReCaptchaProvider,
} from 'react-google-recaptcha-v3'

import DownArrowIcon from '../../../assets/icons/arrow-down.svg'
import { EditForm, ParsedFormData } from '../../../components/Form/EditForm'
import { DefaultLayout } from '../../../layouts/DefaultLayout'
import { APIHostname } from '../../../Profile'
import { RegisterLayout } from '../../../types/data/Register'
import { RecaptchaSiteKey } from '../../../types/Recaptcha'
import { LogInLeftMenuItems, LogInTopTabItems } from '..'
import styles from './Register.module.scss'

export const CreateAccount = () => {
	const [formVisible, setFormVisible] = useState(true)
	const [errorMessage, setErrorMessage] = useState<
		JSX.Element | string | null
	>(null)
	const [successMessage, setSuccessMessage] = useState<
		JSX.Element | string | null
	>(null)
	const [reCaptchaVerified, setReCaptchaVerified] = useState(false)

	return (
		<DefaultLayout
			bottomTabItems={CreateAccountBottomTabItems}
			leftMenuItems={LogInLeftMenuItems}
			showPrint={false}
			tabMenuItems={CreateAccountTabMenuItems}
			topTabItems={LogInTopTabItems}
		>
			<div className={styles.registerOuterContainer}>
				<div className={styles.registerInnerContainer}>
					<div className={styles.successMessage}>
						{successMessage}
					</div>
					<div className={styles.errorMessage}>{errorMessage}</div>

					<div style={!formVisible ? { display: 'none' } : {}}>
						Thank you for your interest in the GOAL portal. To
						register, please fill out the form below. Verification
						may take a day.
					</div>

					<div style={{ display: formVisible ? 'flex' : 'none' }}>
						<GoogleReCaptchaProvider
							reCaptchaKey={RecaptchaSiteKey}
						>
							<EditForm
								data={{}}
								layout={RegisterLayout}
								name="user"
								onSubmit={async (formData: FormData) => {
									if (reCaptchaVerified) {
										// Create user
										axios({
											data: await ParsedFormData(
												formData,
												[]
											),
											headers: {
												'Content-Type':
													'application/json',
											},
											method: 'POST',
											url: `${APIHostname}/api/v1/register`,
										})
											.then(() => {
												setSuccessMessage(
													<>
														Thank you. Your
														registration request has
														been received. It will
														be reviewed and you will
														receive an email when
														your account is active.
													</>
												)
												setErrorMessage(<></>)
												setFormVisible(false)
											})
											.catch((error: any) => {
												setSuccessMessage(null)
												if (
													error?.response?.data
														?.errors?.length
												) {
													if (
														error?.response?.data
															?.errors.length > 1
													) {
														setErrorMessage(
															`Errors: ${error?.response?.data?.errors.join(
																', '
															)}`
														)
													} else {
														setErrorMessage(
															`Error: ${error?.response?.data?.errors[0]}`
														)
													}
												} else {
													setErrorMessage(
														<>
															Error: Unknown
															error.
														</>
													)
												}
											})
									}
								}}
								previewAPIData={async (formData: FormData) => {
									const parsedFormData = await ParsedFormData(
										formData,
										[]
									)

									return {
										data: JSON.stringify(
											parsedFormData,
											null,
											4
										),
										method: 'POST',
										url: `${APIHostname}/api/v1/register`,
									}
								}}
								readOnly={false}
							>
								<GoogleReCaptcha
									onVerify={() => {
										setReCaptchaVerified(true)
									}}
								/>
							</EditForm>
						</GoogleReCaptchaProvider>
					</div>
				</div>
			</div>
		</DefaultLayout>
	)
}

export const CreateAccountBottomTabItems = [
	{
		icon: DownArrowIcon,
		text: 'CREATE ACCOUNT',
		to: '/register',
	},
]

export const CreateAccountTabMenuItems = []
