import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { ReactElement, useState } from 'react'
import { Link } from 'react-router-dom'

import OrganizationsIcon from '../../../../assets/icons/organizations.svg'
import { APIHostname, LogOff } from '../../../../Profile'
import { EncodedStringify } from '../../../../types/Filter'
import { RandomID } from '../../../../Utils'
import { FieldProps } from '../../Field'
import styles from './Organization.module.scss'

export const EditOrganization = (props: FieldProps) => {
	const [previews, setPreviews] = useState(<></>)
	const [labels, setLabels] = useState(
		props.value?.toString().length > 0 ? (
			<>
				{props.value
					.toString()
					.split(',')
					.map((name: string) => (
						<li key={name}>ORGANIZATION #{name}</li>
					))}
			</>
		) : (
			<></>
		)
	)
	const [value, setValue] = useState(props.value ?? '0')
	const [searchAheadID] = useState(RandomID(16))
	const [errorMessage, setErrorMessage] = useState(<></>)

	const { isPending, error, data } = useQuery({
		queryFn: () =>
			fetch(
				`${APIHostname}/api/v1/organizationtypes?sorts=[{"field":"name","order":"ASC"}]`,
				{
					headers: {
						Authorization: `Bearer ${localStorage['token']}`,
					},
				}
			).then((response) => response.json()),
		queryKey: ['organization_types'],
		refetchInterval: 0,
		refetchOnWindowFocus: false,
	})

	if (error) {
		console.error(error) // eslint-disable-line
	}

	if (isPending) {
		return <></>
	}

	const search = (target: HTMLInputElement) => {
		if (target.value) {
			const filters = [
				{
					field: 'name,code',
					operator: 'contains',
					value: target.value,
				},
			]
			if (props.field.SubType) {
				filters.push({
					field: 'type',
					operator: 'equals',
					value: props.field.SubType,
				})
			}
			axios({
				data: {},
				headers: {
					Authorization: `Bearer ${localStorage['token']}`,
				},
				method: 'GET',
				url: `${APIHostname}/api/v1/organizations?filters=${EncodedStringify(
					filters
				)}&sorts=[{"field":"name","order":"ASC"}]&limit=250`,
			})
				.then((response: any) => {
					const idMap: { [id: string]: boolean } = {}
					if (props.field.Multiple) {
						value
							?.toString()
							.split(',')
							.forEach((id: string) => {
								idMap[id] = true
							})
					}

					if (response.data.organization_count != null) {
						if (response.data.organizations) {
							const rows: ReactElement[] = []
							response.data.organizations.forEach((p: any) => {
								rows.push(
									<tr
										key={`preview${p.id}`}
										onMouseDown={(event: any) => {
											if (props.field.Multiple) {
												const checkbox =
													document.getElementById(
														`multiple_organization_${p.id}`
													) as HTMLInputElement
												checkbox.checked =
													!checkbox.checked

												const checkboxes =
													document.querySelectorAll(
														'input[type=\'checkbox\'][id^=\'multiple\']:checked'
													)
												const ids: string[] = []
												const names: string[] = []
												checkboxes.forEach(
													(element: any) => {
														const idParts =
															element.id.split(
																'_'
															)
														ids.push(
															idParts[
																idParts.length -
																	1
															]
														)
														names.push(
															(
																document.getElementById(
																	`${element.id}_name`
																) as HTMLInputElement
															).value
														)
													}
												)
												setValue(ids.join(','))
												if (ids.length > 0) {
													setLabels(
														<>
															{names.map(
																(
																	name: string
																) => (
																	<li
																		key={
																			name
																		}
																	>
																		{name}
																	</li>
																)
															)}
														</>
													)
												} else {
													setLabels(<></>)
												}

												event.preventDefault()

												if (props.onChange) {
													props.onChange(event)
												}

												return false
											} else {
												setValue(p.id)
												;(
													document.getElementById(
														`${props.field.Property}_link`
													) as HTMLAnchorElement
												).href = `/organizations/${p.id}`
												if (p.has_logo) {
													(
														document.getElementById(
															`${props.field.Property}_logo`
														) as HTMLImageElement
													).src = p.image_preview
												} else {
													(
														document.getElementById(
															`${props.field.Property}_logo`
														) as HTMLImageElement
													).src = OrganizationsIcon
												}
												(
													document.getElementById(
														`${props.field.Property}_name`
													) as HTMLInputElement
												).value = p.name
												;(
													document.getElementById(
														`${props.field.Property}_main_contact_name`
													) as HTMLInputElement
												).value =
													p.main_contact_name ?? ''
												;(
													document.getElementById(
														`${props.field.Property}_address`
													) as HTMLInputElement
												).value = p.address ?? ''
												;(
													document.getElementById(
														`${props.field.Property}_city`
													) as HTMLInputElement
												).value = p.city ?? ''
												;(
													document.getElementById(
														`${props.field.Property}_state`
													) as HTMLInputElement
												).value = p.state ?? ''
												;(
													document.getElementById(
														`${props.field.Property}_postal_code`
													) as HTMLInputElement
												).value = p.postal_code ?? ''
												;(
													document.getElementById(
														`${props.field.Property}_country`
													) as HTMLInputElement
												).value = p.country ?? ''
												if (p.country) {
													(
														document.getElementById(
															`${props.field.Property}_flag`
														) as HTMLSpanElement
													).className = `fi fi-${p.country?.toLowerCase()} ${
														styles.flag
													}`
												} else {
													(
														document.getElementById(
															`${props.field.Property}_flag`
														) as HTMLSpanElement
													).className = `fi ${styles.flag}`
												}
												const presentedTo =
													document.getElementById(
														'presented_to'
													)
												if (presentedTo) {
													(
														presentedTo as HTMLInputElement
													).value =
														p.main_contact_name
												}

												setPreviews(<></>)

												if (props.onChange) {
													props.onChange(event)
												}
											}
										}}
									>
										{props.field.Multiple ? (
											<td>
												<input
													className={styles.checkbox}
													defaultChecked={idMap[p.id]}
													id={`multiple_organization_${p.id}`}
													onChange={(event: any) => {
														event.stopPropagation()
													}}
													type="checkbox"
													value={p.id}
												/>
												<input
													id={`multiple_organization_${p.id}_name`}
													type="hidden"
													value={p.name}
												/>
											</td>
										) : (
											<></>
										)}

										<td>
											<img
												onError={(event: any) => {
													if (
														event.target.src ==
														OrganizationsIcon
													) {
														event.target.style.display =
															'none'
													} else {
														event.target.src =
															OrganizationsIcon
													}
												}}
												onLoad={(event: any) => {
													event.target.style.display =
														''
												}}
												src={
													p.image_preview
														? p.image_preview
														: OrganizationsIcon
												}
											/>
										</td>
										<td>{p.id}</td>
										<td>{p.code}</td>
										<td>{p.name}</td>
										<td>
											{
												data.organization_types.filter(
													(organizationType: any) => {
														return (
															organizationType.type ==
															p.type
														)
													}
												)[0]?.name
											}
										</td>
									</tr>
								)
							})
							const searchAhead =
								document.getElementById(searchAheadID)
							if (searchAhead) {
								searchAhead.style.left = `${
									target.offsetLeft + 0.5
								}px`
								searchAhead.style.top = `${
									target.offsetTop + target.offsetHeight
								}px`
								searchAhead.style.width = `${Math.max(
									target.offsetWidth,
									400
								)}px`
							}
							setPreviews(
								<table>
									<tbody>{rows}</tbody>
								</table>
							)
						} else {
							setPreviews(
								<div className={styles.noMatches}>
									NO MATCHING ORGANIZATIONS
								</div>
							)
						}
					} else {
						setPreviews(<></>)
					}
				})
				.catch((error: any) => {
					switch (error?.response?.status) {
					case 401:
						LogOff('token-expired')
						return
					case 403:
						setErrorMessage(
							<>
									Error: You do not have permission to view
									organizations.
							</>
						)
						break
					default:
						if (error?.response?.data?.error) {
							setErrorMessage(
								error?.response?.data?.link ? (
									<Link to={error?.response?.data?.link}>
											Error:
										{error?.response?.data?.error}
									</Link>
								) : (
									<>{error?.response?.data?.error}</>
								)
							)
						} else {
							setErrorMessage(<>Error: Unknown error.</>)
								console.error(error) // eslint-disable-line
						}
					}
					setPreviews(<></>)
				})
		} else {
			setPreviews(<></>)
		}
	}

	const defaultValue = props.value
		? props.data[`${props.field.Property}_name`]
		: ''
	return (
		<div
			className={`${styles.fieldOuterContainer} organization`}
			id={props.field.Property ? `${props.field.Property}_container` : ''}
		>
			<div>
				<div className={styles.label}>
					{props.field.Label}
					{props.field.Label?.endsWith(':') ? '' : ':'}
					{props.field.HardRequired && !props.isSearch ? (
						<>
							{'\u00A0'}
							<span
								className="hardRequired"
								title="REQUIRED IN ORDER TO SAVE"
							>
								*
							</span>
						</>
					) : (
						<></>
					)}
					{props.field.SoftRequired && !props.isSearch ? (
						<>
							{'\u00A0'}
							<span className="softRequired" title="REQUIRED">
								*
							</span>
						</>
					) : (
						<></>
					)}{' '}
				</div>
				<div className={styles.errorMessage}>{errorMessage}</div>
				<div className={`${styles.searchAheadBox} pseudoInput`}>
					<Link
						id={`${props.field.Property}_link`}
						rel="noreferrer"
						target="_blank"
						to={`/organizations/${
							props.data[props.field.Property]
						}`}
					>
						<img
							id={`${props.field.Property}_logo`}
							onError={(event: any) => {
								if (event.target.src == OrganizationsIcon) {
									event.target.style.display = 'none'
								} else {
									event.target.src = OrganizationsIcon
								}
							}}
							onLoad={(event: any) => {
								event.target.style.display = ''
							}}
							src={
								props.data[
									`${props.field.Property}_image_preview`
								]
									? props.data[
										`${props.field.Property}_image_preview`
									  ]
									: OrganizationsIcon
							}
						/>
					</Link>
					<input
						autoComplete="off"
						className={`${
							props.field.HardRequired && !props.isSearch
								? 'hardRequired'
								: ''
						} ${
							props.field.SoftRequired && !props.isSearch
								? 'softRequired'
								: ''
						}`}
						data-value={defaultValue}
						defaultValue={defaultValue}
						id={`${props.field.Property}_name`}
						onBlur={() => {
							setPreviews(<></>)
						}}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
							if (!props.readOnly) {
								search(e.target)
							}
						}}
						onFocus={(e: React.ChangeEvent<HTMLInputElement>) => {
							if (!props.readOnly) {
								search(e.target)
							}
						}}
						placeholder=" "
						readOnly={props.readOnly || props.field.ReadOnly}
						required={props.field.HardRequired && !props.isSearch}
						title={props.field.Label?.replace(':', '')}
						type="text"
					/>
					<div className={styles.previews} id={searchAheadID}>
						{previews}
					</div>
				</div>
				{props.field.Multiple ? (
					<ul
						className={styles.multiLabel}
						id={`${props.field.Property}_multilabel`}
					>
						{labels}
					</ul>
				) : (
					<></>
				)}
				<input
					data-hardrequired={
						props.field.HardRequired && !props.isSearch
					}
					data-multiple={props.field.Multiple}
					data-nullable={true}
					data-number={true}
					data-softrequired={
						props.field.SoftRequired && !props.isSearch
					}
					id={props.field.Property}
					name={props.field.Property}
					readOnly={true}
					type="hidden"
					value={value}
				/>
			</div>
			<div className="organizationMetadata">
				<div>
					<div className={styles.label}>MAIN CONTACT:</div>
					<input
						defaultValue={
							props.data
								? `${
									props.data[
										`${props.field.Property}_main_contact_name`
									] ?? ''
								  }`
								: ''
						}
						id={`${props.field.Property}_main_contact_name`}
						readOnly={true}
						tabIndex={-1}
						title="MAIN CONTACT"
						type="text"
					/>
				</div>
				<div>
					<div className={styles.label}>ADDRESS:</div>
					<textarea
						defaultValue={
							props.data
								? `${
									props.data[
										`${props.field.Property}_address`
									] ?? ''
								  }`
								: ''
						}
						id={`${props.field.Property}_address`}
						readOnly={true}
						rows={3}
						tabIndex={-1}
						title="ADDRESS"
					/>
				</div>
				<div className={styles.halfWidthContainer}>
					<div className={styles.halfWidth}>
						<div className={styles.label}>CITY:</div>
						<input
							defaultValue={
								props.data
									? `${
										props.data[
											`${props.field.Property}_city`
										] ?? ''
									  }`
									: ''
							}
							id={`${props.field.Property}_city`}
							readOnly={true}
							tabIndex={-1}
							title="CITY"
							type="text"
						/>
					</div>
					<div className={styles.halfWidth}>
						<div className={styles.label}>STATE:</div>
						<input
							defaultValue={
								props.data
									? `${
										props.data[
											`${props.field.Property}_state`
										] ?? ''
									  }`
									: ''
							}
							id={`${props.field.Property}_state`}
							readOnly={true}
							tabIndex={-1}
							title="STATE"
							type="text"
						/>
					</div>
				</div>
				<div className={styles.halfWidthContainer}>
					<div className={styles.halfWidth}>
						<div className={styles.label}>POSTAL CODE:</div>
						<input
							defaultValue={
								props.data
									? `${
										props.data[
											`${props.field.Property}_postal_code`
										] ?? ''
									  }`
									: ''
							}
							id={`${props.field.Property}_postal_code`}
							readOnly={true}
							tabIndex={-1}
							title="POSTAL CODE"
							type="text"
						/>
					</div>
					<div className={styles.halfWidth}>
						<div className={styles.label}>COUNTRY:</div>
						<div className={styles.countryBox}>
							<span
								className={`fi fi-${
									props.data
										? props.data[
											`${props.field.Property}_country`
										  ]?.toLowerCase() ?? ''
										: ''
								} ${styles.flag}`}
								id={`${props.field.Property}_flag`}
							></span>
							<input
								defaultValue={
									props.data
										? `${
											props.data[
												`${props.field.Property}_country`
											] ?? ''
										  }`
										: ''
								}
								id={`${props.field.Property}_country`}
								readOnly={true}
								tabIndex={-1}
								title="COUNTRY"
								type="text"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
