import axios from 'axios'
import { MouseEvent, useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'

import DownArrowIcon from '../../../../assets/icons/arrow-down.svg'
import CopyIcon from '../../../../assets/icons/copy.svg'
import DeleteIcon from '../../../../assets/icons/delete.svg'
import EmailTemplatesIcon from '../../../../assets/icons/email_templates.svg'
import { EditPermissions } from '../../../../components/Form/Controls/Permissions'
import { EditForm, ParsedFormData } from '../../../../components/Form/EditForm'
import { GlobalTopTabItems } from '../../../../components/Tabs'
import { DefaultLayout } from '../../../../layouts/DefaultLayout'
import {
	APIHostname,
	HasPermission,
	HasRole,
	LogOff,
} from '../../../../Profile'
import { EmailTemplateLayout } from '../../../../types/data/EmailTemplate'
import styles from './EmailTemplate.module.scss'

export const EmailTemplate = () => {
	const { id } = useParams()
	const EmailTemplateBottomTabItems = [
		{
			icon: DownArrowIcon,
			text: `EMAIL TEMPLATE #${id}`,
			to: '/email_templates',
		},
	]

	const [email_template, setEmailTemplate]: any = useState(null)
	const [loaded, setLoaded] = useState(false)
	const [errorMessage, setErrorMessage] = useState(<></>)
	const initialRedirectMessage = sessionStorage['redirect-message']
	const [redirectMessage] = useState(initialRedirectMessage)

	// Vary based on query string.
	const location = useLocation()

	useEffect(() => {
		axios({
			data: {},
			headers: {
				Authorization: `Bearer ${localStorage['token']}`,
			},
			method: 'GET',
			url: `${APIHostname}/api/v1/email_templates/${id}${location.search}`,
		})
			.then((response: any) => {
				setEmailTemplate(response.data)
				setLoaded(true)
			})
			.catch((error: any) => {
				switch (error?.response?.status) {
				case 401:
					LogOff('token-expired')
					return
				case 403:
					setErrorMessage(
						<>
								Error: You do not have permission to view
								email_templates.
						</>
					)
					break
				default:
					if (error?.response?.data?.error) {
						setErrorMessage(
							error?.response?.data?.link ? (
								<Link to={error?.response?.data?.link}>
										Error:
									{error?.response?.data?.error}
								</Link>
							) : (
								<>{error?.response?.data?.error}</>
							)
						)
					} else {
						setErrorMessage(<>Error: Unknown error.</>)
							console.error(error) // eslint-disable-line
					}
				}
			})
	}, [location])

	const EmailTemplateTabMenuItems = [
		{
			icon: CopyIcon,
			onClick: async (e: MouseEvent<Element>) => {
				e.preventDefault()

				// eslint-disable-next-line
				const confirmation = confirm(`Really copy email_template?`)
				if (!confirmation) {
					return
				}

				const form = document.querySelector(
					'form[name=\'email_template\']'
				)
				const formData = new FormData(form as HTMLFormElement)
				const payload = await ParsedFormData(formData, [])
				payload.permissions = {}
				const permissions = document.querySelectorAll(
					'input[type=checkbox]'
				)
				permissions.forEach((permission: Element) => {
					const permissionBox = permission as HTMLInputElement
					payload.permissions[permissionBox.name] =
						permissionBox.checked
				})
				axios({
					data: payload,
					headers: {
						Authorization: `Bearer ${localStorage['token']}`,
						'Content-Type': 'application/json',
					},
					method: 'POST',
					url: `${APIHostname}/api/v1/email_templates`,
				})
					.then((response: any) => {
						sessionStorage[
							'redirect-message'
						] = `Email template #${response.data.email_template_id} created.`
						window.history.replaceState(
							{},
							'',
							`/email_templates/${response.data.email_template_id}`
						)
						document.location.reload()
					})
					.catch((error: any) => {
						switch (error?.response?.status) {
						case 401:
							LogOff('token-expired')
							return
						case 403:
							setErrorMessage(
								<>
										Error: You do not have permission to
										edit email_templates.
								</>
							)
							break
						default:
							if (error?.response?.data?.error) {
								setErrorMessage(
									error?.response?.data?.link ? (
										<Link
											to={error?.response?.data?.link}
										>
												Error:
											{error?.response?.data?.error}
										</Link>
									) : (
										<>{error?.response?.data?.error}</>
									)
								)
							} else {
								setErrorMessage(<>Error: Unknown error.</>)
									console.error(error) // eslint-disable-line
							}
						}
					})
			},
			requiredPermissions: ['email_template.create'],
			requiredRole: 'administrator',
			text: 'COPY',
			to: '#',
		},
		{
			icon: DeleteIcon,
			onClick: async (e: MouseEvent<Element>) => {
				e.preventDefault()

				// eslint-disable-next-line
				const confirmation = confirm(`Really delete email template?`)
				if (!confirmation) {
					return
				}

				axios({
					headers: {
						Authorization: `Bearer ${localStorage['token']}`,
						'Content-Type': 'application/json',
					},
					method: 'DELETE',
					url: `${APIHostname}/api/v1/email_templates/${id}`,
				})
					.then((response: any) => {
						sessionStorage[
							'redirect-message'
						] = `Email template #${response.data.email_template_id} deleted.`
						window.history.replaceState({}, '', '/email_templates')
						document.location.reload()
					})
					.catch((error: any) => {
						switch (error?.response?.status) {
						case 401:
							LogOff('token-expired')
							return
						case 403:
							setErrorMessage(
								<>
										Error: You do not have permission to
										delete email templates.
								</>
							)
							break
						default:
							if (error?.response?.data?.error) {
								setErrorMessage(
									error?.response?.data?.link ? (
										<Link
											to={error?.response?.data?.link}
										>
												Error:
											{error?.response?.data?.error}
										</Link>
									) : (
										<>{error?.response?.data?.error}</>
									)
								)
							} else {
								setErrorMessage(<>Error: Unknown error.</>)
									console.error(error) // eslint-disable-line
							}
						}
					})
			},
			requiredPermissions: ['email_template.delete'],
			text: 'DELETE',
			to: '#',
		},
	].reverse()

	const EmailTemplateLeftMenuItems = [
		{
			icon: EmailTemplatesIcon,
			text: 'EMAIL TEMPLATE',
			to: `/email_templates/${id}`,
		},
	]

	if (!loaded) {
		if (errorMessage) {
			return (
				<DefaultLayout
					bottomTabItems={EmailTemplateBottomTabItems}
					leftMenuItems={EmailTemplateLeftMenuItems}
					showPrint={true}
					tabMenuItems={EmailTemplateTabMenuItems}
					topTabItems={GlobalTopTabItems}
				>
					<div className={styles.emailTemplateContainer}>
						<div className={styles.redirectMessage}>
							{redirectMessage}
						</div>
						<div className={styles.errorMessage}>
							{errorMessage}
						</div>
					</div>
				</DefaultLayout>
			)
		}

		return <div className="loading">Loading...</div>
	}

	sessionStorage.removeItem('redirect-message')
	return (
		<DefaultLayout
			bottomTabItems={EmailTemplateBottomTabItems}
			leftMenuItems={EmailTemplateLeftMenuItems}
			showPrint={true}
			tabMenuItems={EmailTemplateTabMenuItems}
			topTabItems={GlobalTopTabItems}
		>
			<div className={styles.emailTemplateContainer}>
				<div className={styles.redirectMessage}>{redirectMessage}</div>
				<div className={styles.errorMessage}>{errorMessage}</div>

				<EditForm
					data={email_template}
					layout={EmailTemplateLayout}
					name="email_template"
					onSubmit={async (formData: FormData) => {
						const payload = await ParsedFormData(formData, [])
						payload.permissions = {}
						const permissions = document.querySelectorAll(
							'input[type=checkbox]'
						)
						permissions.forEach((permission: Element) => {
							const permissionBox = permission as HTMLInputElement
							payload.permissions[permissionBox.name] =
								permissionBox.checked
						})
						axios({
							data: payload,
							headers: {
								Authorization: `Bearer ${localStorage['token']}`,
								'Content-Type': 'application/json',
							},
							method: 'POST',
							url: `${APIHostname}/api/v1/email_templates/${id}`,
						})
							.then(() => {
								sessionStorage[
									'redirect-message'
								] = `Email template #${id} updated.`
								window.history.replaceState(
									{},
									'',
									'/email_templates'
								)
								document.location.reload()
							})
							.catch((error: any) => {
								switch (error?.response?.status) {
								case 401:
									LogOff('token-expired')
									return
								case 403:
									setErrorMessage(
										<>
												Error: You do not have
												permission to view
												email_templates.
										</>
									)
									break
								default:
									if (error?.response?.data?.error) {
										setErrorMessage(
											error?.response?.data?.link ? (
												<Link
													to={
														error?.response
															?.data?.link
													}
												>
														Error:
													{
														error?.response
															?.data?.error
													}
												</Link>
											) : (
												<>
													{
														error?.response
															?.data?.error
													}
												</>
											)
										)
									} else {
										setErrorMessage(
											<>Error: Unknown error.</>
										)
											console.error(error) // eslint-disable-line
									}
								}
							})
					}}
					previewAPIData={async (formData: FormData) => {
						const payload = await ParsedFormData(formData, [])

						return {
							data: JSON.stringify(payload, null, 4),
							method: 'POST',
							url: `${APIHostname}/api/v1/email_templates/${id}`,
						}
					}}
					readOnly={!HasRole('administrator')}
				>
					{HasPermission('email_template', 'managepermissions') ? (
						<EditPermissions
							permissions={email_template.permissions}
						/>
					) : (
						<></>
					)}
				</EditForm>
			</div>{' '}
		</DefaultLayout>
	)
}
