import Control from '../Control'
import { Layout } from '../Layout'

export type Carrier = {
	id: number
	name: string
	scac: string
}

export const CarrierEditLayout: Layout = {
	Groups: [
		{
			Border: true,
			Direction: 'column',
			Flex: '1 1',
			Margin: '1rem 0 0 0',
			OptionalRows: [
				{
					Fields: [
						{
							HardRequired: true,
							Label: 'SCAC',
							MaxLength: 4,
							MinLength: 4,
							Property: 'scac',
							Width: 33,
						},
						{
							HardRequired: true,
							Label: 'NAME',
							Property: 'name',
							Width: 33,
						},
						{
							Control: Control.CarrierType,
							HardRequired: true,
							Label: 'TYPE',
							Property: 'type',
							Width: 33,
						},
					],
				},
				{
					Fields: [
						{
							Label: 'ACI',
							MaxLength: 4,
							Property: 'aci',
							SoftRequired: true,
							Width: 33,
						},
					],
				},
				{
					Fields: [
						{
							Control: Control.TextBox,
							Label: 'NOTES',
							Property: 'notes',
							Width: 100,
						},
					],
				},
				{
					Fields: [
						{
							Control: Control.ImageFile,
							Label: 'LOGO',
							Property: 'logo',
							Width: 100,
						},
					],
				},
				{
					Fields: [
						{
							Control: Control.Date,
							Label: 'CREATION DATE',
							Property: 'created',
							ReadOnly: true,
							Width: 33,
						},
						{
							Control: Control.Date,
							Label: 'MODIFIED DATE',
							Property: 'modified',
							ReadOnly: true,
							Width: 33,
						},
						{
							Control: Control.Number,
							Label: 'VERSION',
							Property: 'version',
							ReadOnly: true,
							Width: 33,
						},
					],
				},
			],
			Width: 100,
		},
	],
}

export const CarrierSearchLayout: Layout = {
	Groups: [
		{
			Border: true,
			Margin: '1rem 0 0 0',
			OptionalRows: [
				{
					Fields: [
						{
							Label: 'SCAC',
							Property: 'scac',
							Width: 33,
						},
						{
							Label: 'NAME',
							Property: 'name',
							Width: 33,
						},
						{
							Label: 'ACI',
							MaxLength: 4,
							Property: 'aci',
							Width: 33,
						},
					],
				},
			],
			RequiredRows: [
				{
					Fields: [
						{
							IncludeInEdit: false,
							IncludeInSearch: true,
							Label: 'WILDCARD (SEARCH ALL FIELDS; USE "OR" FOR MULTIPLE CRITERIA)',
							Property: '*',
							Width: 100,
						},
					],
				},
			],
			Width: 100,
		},
	],
}

export const NewCarrierLayout: Layout = {
	Groups: [
		{
			Border: true,
			Direction: 'column',
			Flex: '1 1',
			Margin: '1rem 0 0 0',
			OptionalRows: [
				{
					Fields: [
						{
							HardRequired: true,
							Label: 'SCAC',
							MaxLength: 4,
							MinLength: 4,
							Property: 'scac',
							Width: 33,
						},
						{
							HardRequired: true,
							Label: 'NAME',
							Property: 'name',
							Width: 33,
						},
						{
							Control: Control.CarrierType,
							HardRequired: true,
							Label: 'TYPE',
							Property: 'type',
							Width: 33,
						},
					],
				},
				{
					Fields: [
						{
							Label: 'ACI',
							Property: 'aci',
							SoftRequired: true,
							Width: 33,
						},
					],
				},
				{
					Fields: [
						{
							Control: Control.TextBox,
							Label: 'NOTES',
							Property: 'notes',
							Width: 100,
						},
					],
				},
				{
					Fields: [
						{
							Control: Control.ImageFile,
							Label: 'LOGO',
							Property: 'logo',
							Width: 100,
						},
					],
				},
			],
			Width: 100,
		},
	],
}
