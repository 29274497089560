import axios from 'axios'
import { useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'

import AgentAccessIcon from '../../../../../assets/icons/agent-access.svg'
import DownArrowIcon from '../../../../../assets/icons/arrow-down.svg'
import DataCorrectionIcon from '../../../../../assets/icons/data-correction.svg'
import DocumentsIcon from '../../../../../assets/icons/documents.svg'
import HotListActiveIcon from '../../../../../assets/icons/hot-active.svg'
import HotListInactiveIcon from '../../../../../assets/icons/hot-inactive.svg'
import LockedIcon from '../../../../../assets/icons/locked.svg'
import NewIcon from '../../../../../assets/icons/new.svg'
import OrganizationHistoryIcon from '../../../../../assets/icons/organization-history.svg'
import OrganizationInfoIcon from '../../../../../assets/icons/organization-info.svg'
import OrganizationUsersIcon from '../../../../../assets/icons/organization-users.svg'
import ShipmentsIcon from '../../../../../assets/icons/shipments.svg'
import { SearchForm } from '../../../../../components/Form/SearchForm'
import { NavTable } from '../../../../../components/NavTable'
import { GlobalTopTabItems } from '../../../../../components/Tabs'
import { DefaultLayout } from '../../../../../layouts/DefaultLayout'
import { APIHostname, HasPermission, LogOff } from '../../../../../Profile'
import Control from '../../../../../types/Control'
import { ShipmentSearchLayout } from '../../../../../types/data/Shipment'
import { EncodedStringify, Filter } from '../../../../../types/Filter'
import { EscapeURIParameters } from '../../../../../Utils'
import styles from './Shipments.module.scss'

export const OrganizationShipments = () => {
	const { id } = useParams()

	const [shipments, setShipments] = useState([])
	const [shipmentCount, setShipmentCount] = useState(0)
	const [shipmentsLoaded, setShipmentsLoaded] = useState(false)
	const [errorMessage, setErrorMessage] = useState(<></>)
	const [organizationLoaded, setOrganizationLoaded]: any = useState(false)
	const [organization, setOrganization]: any = useState(null)
	const initialRedirectMessage = sessionStorage['redirect-message']
	const [redirectMessage] = useState(initialRedirectMessage)

	// Vary based on query string.
	const location = useLocation()
	const urlParams = new URLSearchParams(location.search)
	if (!urlParams.get('limit')) {
		urlParams.set('limit', '25')
	}
	const filters = JSON.parse(urlParams.get('filters') ?? '[]')
	let filterFound = false
	filters.forEach((filter: Filter) => {
		if (filter.aggregate?.toLowerCase() == 'or') {
			filterFound = true
		}
	})
	if (!filterFound) {
		filters.push({
			aggregate: 'or',
			children: [
				{
					field: 'bond_holder',
					operator: 'eq',
					value: id?.toString(),
				},
				{
					field: 'buyer',
					operator: 'eq',
					value: id?.toString(),
				},
				{
					field: 'consignee',
					operator: 'eq',
					value: id?.toString(),
				},
				{
					field: 'consolidator',
					operator: 'eq',
					value: id?.toString(),
				},
				{
					field: 'container_stuffer',
					operator: 'eq',
					value: id?.toString(),
				},
				{
					field: 'importer',
					operator: 'eq',
					value: id?.toString(),
				},
				{
					field: 'notify',
					operator: 'eq',
					value: id?.toString(),
				},
				{
					field: 'seller',
					operator: 'eq',
					value: id?.toString(),
				},
				{
					field: 'ship_to_party',
					operator: 'eq',
					value: id?.toString(),
				},
				{
					field: 'shipper',
					operator: 'eq',
					value: id?.toString(),
				},
			],
		})
	}
	urlParams.set('filters', EncodedStringify(filters))

	useEffect(() => {
		axios({
			data: {},
			headers: {
				Authorization: `Bearer ${localStorage['token']}`,
			},
			method: 'GET',
			url: `${APIHostname}/api/v1/organizations/${id}${location.search}`,
		})
			.then((response: any) => {
				setErrorMessage(<></>)
				setOrganization(response.data)
				setOrganizationLoaded(true)
			})
			.catch((error: any) => {
				switch (error?.response?.status) {
				case 401:
					LogOff('token-expired')
					return
				case 403:
					setErrorMessage(
						<>
								Error: You do not have permission to view
								organizations.
						</>
					)
					break
				default:
					if (error?.response?.data?.error) {
						setErrorMessage(
							error?.response?.data?.link ? (
								<Link to={error?.response?.data?.link}>
										Error:
									{error?.response?.data?.error}
								</Link>
							) : (
								<>{error?.response?.data?.error}</>
							)
						)
					} else {
						setErrorMessage(<>Error: Unknown error.</>)
							console.error(error) // eslint-disable-line
					}
				}
			})

		axios({
			data: {},
			headers: {
				Authorization: `Bearer ${localStorage['token']}`,
			},
			method: 'GET',
			url: `${APIHostname}/api/v1/shipments?${EscapeURIParameters(
				urlParams.toString()
			)}&view=browse`,
		})
			.then((response: any) => {
				setShipments(response.data.shipments)
				setShipmentCount(response.data.shipment_count)
				setShipmentsLoaded(true)
			})
			.catch((error: any) => {
				switch (error?.response?.status) {
				case 401:
					LogOff('token-expired')
					return
				case 403:
					setErrorMessage(
						<>
								Error: You do not have permission to view
								shipments.
						</>
					)
					break
				default:
					if (error?.response?.data?.error) {
						setErrorMessage(
							error?.response?.data?.link ? (
								<Link to={error?.response?.data?.link}>
										Error:
									{error?.response?.data?.error}
								</Link>
							) : (
								<>{error?.response?.data?.error}</>
							)
						)
					} else {
						setErrorMessage(<>Error: Unknown error.</>)
							console.error(error) // eslint-disable-line
					}
				}
			})
	}, [location])

	const ShipmentsTabMenuItems = [
		{
			icon:
				location.search.indexOf(
					'?filters=[{%22field%22:%22hotlist%22,%22operator%22:%22eq%22,%22value%22:%22true%22}]'
				) > -1
					? HotListActiveIcon
					: HotListInactiveIcon,
			requiredPermissions: ['shipment.update'],
			text: 'HOT',
			to: '/shipments/?filters=[{%22field%22:%22hotlist%22,%22operator%22:%22eq%22,%22value%22:%22true%22}]',
		},
		{
			icon: NewIcon,
			requiredPermissions: ['shipment.create'],
			text: 'NEW',
			to: '/shipments/new',
		},
	].reverse()

	const OrganizationBottomTabItems = [
		{
			icon: DownArrowIcon,
			text: `ORGANIZATION #${id} SHIPMENTS`,
			to: `/organizations/${id}/shipments`,
		},
	]

	const OrganizationLeftMenuItems = [
		{
			icon: OrganizationInfoIcon,
			requiredPermissions: ['organization.read'],
			text: 'ORGANIZATION INFO',
			to: `/organizations/${id}`,
		},
		{
			icon: DocumentsIcon,
			requiredPermissions: ['shipment.read'],
			text: 'ORGANIZATION DOCUMENTS',
			to: `/organizations/${id}/documents`,
		},
		{
			icon: ShipmentsIcon,
			requiredPermissions: ['shipment.read'],
			text: 'LINKED SHIPMENTS',
			to: `/organizations/${id}/shipments`,
		},
		{
			icon: OrganizationUsersIcon,
			requiredPermissions: ['user.read'],
			text: 'ORGANIZATION USERS',
			to: `/organizations/${id}/users`,
		},
		{
			icon: AgentAccessIcon,
			requiredPermissions: ['organization.manageaccess'],
			text: 'PARTNER ACCESS',
			to: `/organizations/${id}/partner_access`,
		},
		{
			icon: OrganizationHistoryIcon,
			requiredPermissions: ['organization.read'],
			text: 'ORGANIZATION HISTORY',
			to: `/organizations/${id}/history`,
		},
	]

	if (!organizationLoaded || !shipmentsLoaded) {
		if (errorMessage) {
			return (
				<DefaultLayout
					bottomTabItems={OrganizationBottomTabItems}
					leftMenuItems={OrganizationLeftMenuItems}
					showPrint={true}
					tabMenuItems={ShipmentsTabMenuItems}
					topTabItems={GlobalTopTabItems}
				>
					<div className={styles.shipmentsContainer}>
						<div className={styles.redirectMessage}>
							{redirectMessage}
						</div>
						<div className={styles.errorMessage}>
							{errorMessage}
						</div>
					</div>
				</DefaultLayout>
			)
		}

		return <div className="loading">Loading...</div>
	}

	filters.push({
		field: '*',
		operator: 'contains',
		value: organization.name,
	})

	sessionStorage.removeItem('redirect-message')
	return (
		<DefaultLayout
			bottomTabItems={OrganizationBottomTabItems}
			leftMenuItems={OrganizationLeftMenuItems}
			showPrint={true}
			tabMenuItems={ShipmentsTabMenuItems}
			topTabItems={GlobalTopTabItems}
		>
			<div className={styles.shipmentsContainer}>
				<div className={styles.redirectMessage}>{redirectMessage}</div>
				<div className={styles.errorMessage}>{errorMessage}</div>

				<div className={styles.searchForm}>
					<SearchForm
						data={{}}
						layout={ShipmentSearchLayout}
						name="shipments"
						readOnly={false}
					/>
				</div>

				<NavTable
					columns={[
						{
							children: [
								{
									id: 'booking_number',
									title: 'BOOKING #',
								},
								{
									control: Control.Partner,
									id: 'origin_partner',
									title: 'ORIGIN OFFICE',
								},
							],
						},
						{
							children: [
								{
									control: Control.Status,
									id: 'status',
									title: 'STATUS',
								},
								{
									control: Control.ConfirmedRejected,
									id: 'confirmed,rejected',
									title: 'CONFIRMED / REJECTED',
								},
							],
						},
						{
							children: [
								{
									id: 'mbl',
									title: 'MBL',
								},
								{
									control: Control.Carrier,
									id: 'mbl_carrier',
									title: 'CARRIER SCAC',
								},
							],
						},
						{
							children: [
								{
									id: 'hbl',
									title: 'HBL',
								},
								{
									control: Control.Carrier,
									id: 'hbl_carrier',
									title: 'HBL SCAC',
								},
							],
						},
						{
							children: [
								{
									control: Control.Date,
									id: 'ready_date',
									title: 'ETD',
								},
								{
									control: Control.Date,
									id: 'eta_discharge',
									title: 'ETA DISCHARGE',
								},
							],
						},
						{
							children: [
								{
									control: Control.Port,
									id: 'loading_port',
									title: 'LOADING PORT',
								},
								{
									control: Control.Partner,
									id: 'destination_partner',
									title: 'DESTINATION PARTNER',
								},
							],
						},
						{
							children: [
								{
									control: Control.Port,
									id: 'discharge_port',
									title: 'DISCHARGE PORT',
								},
								{
									control: Control.Port,
									id: 'place_of_delivery',
									title: 'PLACE OF DELIVERY',
								},
							],
						},
						{
							id: 'vessel',
							title: 'VESSEL',
						},
						{
							children: [
								{
									id: 'container_numbers',
									title: 'CONTAINER #S',
								},
								{
									control: Control.Organization,
									id: 'consignee',
									title: 'CONSIGNEE',
								},
							],
						},
						{
							children: [
								{
									control: Control.DateTime,
									id: 'created',
									title: 'CREATION DATE',
								},
								{
									control: Control.User,
									id: 'created_by',
									title: 'CREATED BY',
								},
							],
						},
						{
							children: [
								{
									control: Control.DateTime,
									id: 'modified',
									title: 'MODIFIED DATE',
								},
								{
									control: Control.User,
									id: 'modified_by',
									title: 'MODIFIED BY',
								},
							],
						},
						{
							children: [
								{
									control: Control.Locked,
									icon: LockedIcon,
									id: 'lock',
									title: 'LOCKED',
								},
								{
									control: Control.HotListed,
									icon: HotListActiveIcon,
									id: 'hotlist',
									title: 'HOT LIST',
								},
								{
									control: Control.DataCorrection,
									icon: DataCorrectionIcon,
									id: 'data_correction',
									title: 'DATA CORRECTION',
								},
							],
						},
					]}
					count={shipmentCount}
					data={shipments}
					filters={filters}
					includeDelete={HasPermission('shipment', 'delete')}
					linkPathRoot="/shipments"
					primaryDisplayColumn="booking_number,hbl,mbl"
					primaryKey="id"
				/>
			</div>
		</DefaultLayout>
	)
}
