import axios from 'axios'
import { useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'

import ACIIcon from '../../../../../assets/icons/aci.svg'
import AMSIcon from '../../../../../assets/icons/ams.svg'
import ArrivalIcon from '../../../../../assets/icons/arrival-notice.svg'
import DownArrowIcon from '../../../../../assets/icons/arrow-down.svg'
import BookingIcon from '../../../../../assets/icons/booking.svg'
import DocumentsIcon from '../../../../../assets/icons/documents.svg'
import ShipmentHistoryIcon from '../../../../../assets/icons/history.svg'
import ISFIcon from '../../../../../assets/icons/isf.svg'
import NRAIcon from '../../../../../assets/icons/open-nra.svg'
import TrackingIcon from '../../../../../assets/icons/remarks.svg'
import ShipmentTrackingIcon from '../../../../../assets/icons/tracking.svg'
import { ShipmentSummary } from '../../../../../components/ShipmentSummary'
import { GlobalTopTabItems } from '../../../../../components/Tabs'
import { DefaultLayout } from '../../../../../layouts/DefaultLayout'
import { APIHostname, LogOff } from '../../../../../Profile'
import styles from './ShipmentTracking.module.scss'

export const ShipmentTracking = () => {
	const { id } = useParams()

	const [shipment, setShipment]: any = useState(null)
	const [tracking, setTracking]: any = useState(null)
	const [shipmentLoaded, setShipmentLoaded] = useState(false)
	const [trackingLoaded, setTrackingLoaded] = useState(false)
	const [errorMessage, setErrorMessage] = useState(<></>)
	const initialRedirectMessage = sessionStorage['redirect-message']
	const [redirectMessage] = useState(initialRedirectMessage)

	// Vary based on query string.
	const location = useLocation()
	const urlParams = new URLSearchParams(location.search)
	if (!urlParams.get('limit')) {
		urlParams.set('limit', '25')
	}

	useEffect(() => {
		axios({
			data: {},
			headers: {
				Authorization: `Bearer ${localStorage['token']}`,
			},
			method: 'GET',
			url: `${APIHostname}/api/v1/shipments/${id}`,
		})
			.then((shipmentResponse: any) => {
				setErrorMessage(<></>)
				setShipment(shipmentResponse.data)
				setShipmentLoaded(true)

				const containerNumbers: { [key: string]: string } = {}
				;(shipmentResponse?.data?.containers ?? []).map(
					(container: any) => {
						containerNumbers[truncate(container.number)] = truncate(
							container.number
						)
					}
				)

				axios({
					data: {},
					headers: {
						Authorization: `Bearer ${localStorage['token']}`,
					},
					method: 'GET',
					url: `https://usa.goalportal.net/api/v1/railinc_tracking?containerIDs=${Object.keys(
						containerNumbers
					).join(',')}}`,
				})
					.then((shipmentResponse: any) => {
						setErrorMessage(<></>)
						setTracking(shipmentResponse.data)
						setTrackingLoaded(true)
					})
					.catch((error: any) => {
						switch (error?.response?.status) {
						case 401:
							LogOff('token-expired')
							return
						case 403:
							setErrorMessage(
								<>
										Error: You do not have permission to
										view shipment tracking.
								</>
							)
							break
						default:
							if (error?.response?.data?.error) {
								setErrorMessage(
									error?.response?.data?.link ? (
										<Link
											to={error?.response?.data?.link}
										>
												Error:
											{error?.response?.data?.error}
										</Link>
									) : (
										<>{error?.response?.data?.error}</>
									)
								)
							} else {
								setErrorMessage(<>Error: Unknown error.</>)
									console.error(error) // eslint-disable-line
							}
						}
					})
			})
			.catch((error: any) => {
				switch (error?.response?.status) {
				case 401:
					LogOff('token-expired')
					return
				case 403:
					setErrorMessage(
						<>
								Error: You do not have permission to view
								shipments.
						</>
					)
					break
				default:
					if (error?.response?.data?.error) {
						setErrorMessage(
							error?.response?.data?.link ? (
								<Link to={error?.response?.data?.link}>
										Error:
									{error?.response?.data?.error}
								</Link>
							) : (
								<>{error?.response?.data?.error}</>
							)
						)
					} else {
						setErrorMessage(<>Error: Unknown error.</>)
							console.error(error) // eslint-disable-line
					}
				}
			})
	}, [location])

	const arrivalNoticeTemplate = shipment?.arrival_notice_template
	const usDestination =
		shipment?.discharge_port?.toLowerCase().startsWith('us') ||
		shipment?.final_port_of_entry?.toLowerCase().startsWith('us')
	const caDestination =
		shipment?.discharge_port?.toLowerCase().startsWith('ca') ||
		shipment?.final_port_of_entry?.toLowerCase().startsWith('ca')
	const ShipmentLeftMenuItems = [
		{
			icon: BookingIcon,
			text: 'BOOKING',
			to: `/shipments/${id}`,
		},
		{
			count: shipment?.unread_remarks ?? null,
			icon: TrackingIcon,
			text: 'REMARKS',
			to: `/shipments/${id}/remarks`,
		},
		{
			disabled: !usDestination && !shipment?.ams_status,
			disabledReason: 'Shipment does not have US destination ports.',
			icon: AMSIcon,
			text: 'AMS',
			to: `/shipments/${id}/ams`,
		},
		{
			disabled: !caDestination && !shipment?.aci_status,
			disabledReason: 'Shipment does not have CA destination ports.',
			icon: ACIIcon,
			text: 'ACI',
			to: `/shipments/${id}/aci`,
		},
		{
			disabled: !usDestination,
			disabledReason: 'Shipment does not have US destination ports.',
			icon: ISFIcon,
			text: 'ISF',
			to: `/shipments/${id}/isf`,
		},
		{
			icon: DocumentsIcon,
			text: 'DOCUMENTS',
			to: `/shipments/${id}/documents`,
		},
		{
			icon: DocumentsIcon,
			text: 'HBL PRINT',
			to: `/shipments/${id}/hbl`,
		},
		{
			disabled: !usDestination,
			disabledReason: 'Shipment does not have US destination ports.',
			icon: NRAIcon,
			text: 'NRA',
			to: `/shipments/${id}/nra`,
		},
		{
			disabled: !arrivalNoticeTemplate,
			disabledReason:
				'Destination partner does not have an Arrival Notice template.',
			icon: ArrivalIcon,
			text: 'ARRIVAL NOTICE',
			to: `/shipments/${id}/arrival_notice${
				arrivalNoticeTemplate == 'default'
					? ''
					: `/${arrivalNoticeTemplate}`
			}`,
		},
		{
			icon: ShipmentTrackingIcon,
			requiredRole: 'administrator||memberpartner||partner||organization',
			text: 'SHIPMENT TRACKING',
			to: `/shipments/${id}/tracking`,
		},
		{
			icon: ShipmentHistoryIcon,
			text: 'SHIPMENT HISTORY',
			to: `/shipments/${id}/history`,
		},
	]

	const ShipmentTrackingBottomTabItems = [
		{
			icon: DownArrowIcon,
			text: `SHIPMENT #${id} TRACKING`,
			to: `/shipments/${id}/tracking`,
		},
	]

	if (!shipmentLoaded || !trackingLoaded) {
		if (errorMessage) {
			return (
				<DefaultLayout
					bottomTabItems={ShipmentTrackingBottomTabItems}
					leftMenuItems={ShipmentLeftMenuItems}
					showPrint={true}
					tabMenuItems={ShipmentTabMenuItems}
					topTabItems={GlobalTopTabItems}
				>
					<div className={styles.shipmentTrackingContainer}>
						<div className={styles.redirectMessage}>
							{redirectMessage}
						</div>
						<div className={styles.errorMessage}>
							{errorMessage}
						</div>
					</div>
				</DefaultLayout>
			)
		}

		return <div className="loading">Loading...</div>
	}

	sessionStorage.removeItem('redirect-message')
	return (
		<DefaultLayout
			bottomTabItems={ShipmentTrackingBottomTabItems}
			leftMenuItems={ShipmentLeftMenuItems}
			showPrint={true}
			tabMenuItems={ShipmentTabMenuItems}
			topTabItems={GlobalTopTabItems}
		>
			<div className={styles.shipmentTrackingContainer}>
				<ShipmentSummary shipment={shipment} />

				<div className={styles.redirectMessage}>{redirectMessage}</div>
				<div className={styles.errorMessage}>
					{errorMessage}
					{errorMessage}{' '}
					{!shipment?.active ? (
						<>This shipment has been deleted.</>
					) : (
						<></>
					)}
				</div>

				<table className={styles.trackingTable}>
					<thead>
						<tr className={styles.headerRow}>
							<th>Initial</th>
							<th>Number</th>
							<th>Location City</th>
							<th>Location State</th>
							<th>Sighting Date</th>
							<th>Load Empty Status</th>
							<th>Event Status</th>
							<th>Train ID</th>
							<th>Destination City</th>
							<th>Destination State</th>
							<th>Reporting RR</th>
						</tr>
					</thead>
					<tbody>
						{Object.keys(tracking).map((key: string) => {
							if (!key || key == '}') {
								return (
									<tr key={key}>
										<td>No containers located.</td>
									</tr>
								)
							}
							const trackingItem = tracking[key]

							if (
								trackingItem.Reply &&
								trackingItem.Reply?.EquipmentReplies?.Equipment
									?.EquipmentID?.EquipmentInitial
							)
								return (
									<tr key={key}>
										<td>
											{
												trackingItem.Reply
													?.EquipmentReplies.Equipment
													.EquipmentID
													.EquipmentInitial
											}
										</td>
										<td>
											{
												trackingItem.Reply
													?.EquipmentReplies.Equipment
													.EquipmentID.EquipmentNumber
											}
										</td>
										<td>
											{
												trackingItem.Reply
													?.EquipmentReplies
													.MovementEvents.Location
													.CityLong
											}
										</td>
										<td>
											{
												trackingItem.Reply
													?.EquipmentReplies
													.MovementEvents.Location
													.State
											}
										</td>
										<td>
											{
												trackingItem.Reply
													?.EquipmentReplies
													.MovementEvents.SightingDate
											}
										</td>
										<td>
											{
												trackingItem.Reply
													?.EquipmentReplies
													.MovementEvents
													.LoadEmptyStatus
											}
										</td>
										<td>
											{
												trackingItem.Reply
													?.EquipmentReplies
													.MovementEvents
													.SightingEventCodeText
											}
										</td>
										<td>
											{
												trackingItem.Reply
													?.EquipmentReplies
													.MovementEvents.FromMarkID
											}
										</td>
										<td>
											{
												trackingItem.Reply
													?.EquipmentReplies.WayBill
													.Destination.CityLong
											}
										</td>
										<td>
											{
												trackingItem.Reply
													?.EquipmentReplies.WayBill
													.Destination.State
											}
										</td>
										<td>
											{
												trackingItem.Reply
													?.EquipmentReplies.WayBill
													.BillRoad
											}
										</td>
									</tr>
								)

							return (
								<tr key={key}>
									<td colSpan={10}>
										Container {key} not located.
									</td>
								</tr>
							)
						})}
					</tbody>
				</table>
			</div>
		</DefaultLayout>
	)
}

export const ShipmentTabMenuItems = []

const truncate = (input: string): string => {
	if (input.length > 10) {
		return input.substring(0, 10)
	}

	return input
}
