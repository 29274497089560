import DataCorrectionIcon from '../../assets/icons/data-correction.svg'
import styles from './RequiredStatus.module.scss'

export const RequiredStatus = () => {
	return (
		<div
			className={styles.dataCorrection}
			id="dataCorrection"
			style={{ display: 'none' }}
		>
			<h2>
				<img src={DataCorrectionIcon} title="DATA CORRECTION" /> DATA
				CORRECTION
			</h2>
			<div
				className={styles.hardInvalidFields}
				id="hardInvalidFields"
			></div>
			<div
				className={styles.softInvalidFields}
				id="softInvalidFields"
			></div>
		</div>
	)
}
