import axios from 'axios'
import { ChangeEvent, ReactElement, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDebouncedCallback } from 'use-debounce'

import CarrierIcon from '../../../../assets/icons/carriers.svg'
import { APIHostname, LogOff, ProfileRole } from '../../../../Profile'
import { EncodedStringify } from '../../../../types/Filter'
import { RandomID } from '../../../../Utils'
import { FieldProps } from '../../Field'
import styles from './Carrier.module.scss'

export const EditCarrier = (props: FieldProps) => {
	const [previews, setPreviews] = useState(<></>)
	const [value, setValue] = useState(props.value ?? 0)
	const [searchAheadID] = useState(RandomID(16))

	const search = (target: HTMLInputElement) => {
		if (target.value) {
			const filters = [
				{
					field: '*',
					operator: 'contains',
					value: target.value,
				},
			]
			if (props.field.SubType) {
				filters.push({
					field: 'type',
					operator: 'equals',
					value: props.field.SubType,
				})
			}

			if (
				props.field.Property == 'hbl_carrier' &&
				ProfileRole() == 'nonmemberpartner'
			) {
				filters.push({
					field: 'scac',
					operator: 'ne',
					value: 'GOAG',
				})
			}

			axios({
				data: {},
				headers: {
					Authorization: `Bearer ${localStorage['token']}`,
				},
				method: 'GET',
				url: `${APIHostname}/api/v1/carriers?filters=${EncodedStringify(
					filters
				)}&sorts=[{"field":"name","order":"ASC"}]&limit=250`,
			})
				.then((response: any) => {
					if (response.data.carriers) {
						const rows: ReactElement[] = []
						response.data.carriers.forEach((p: any) => {
							rows.push(
								<tr
									key={`preview${p.id}`}
									onClick={(event: any) => {
										setValue(p.id)
										;(
											document.getElementById(
												`${props.field.Property}_link`
											) as HTMLAnchorElement
										).href = `/carriers/${p.id}`
										if (p.has_logo) {
											(
												document.getElementById(
													`${props.field.Property}_logo`
												) as HTMLImageElement
											).src = p.image_preview
										} else {
											(
												document.getElementById(
													`${props.field.Property}_logo`
												) as HTMLImageElement
											).src = CarrierIcon
										}
										(
											document.getElementById(
												`${props.field.Property}_name`
											) as HTMLInputElement
										).value = `[${p.scac}] ${p.name}`
										;(
											document.getElementById(
												`${props.field.Property}_name`
											) as HTMLInputElement
										).dataset.value = `[${p.scac}] ${p.name}`
										setPreviews(<></>)

										if (props.onChange) {
											props.onChange(event)
										}
									}}
								>
									<td>
										<img
											onError={(event: any) => {
												if (
													event.target.src ==
													CarrierIcon
												) {
													event.target.style.display =
														'none'
												} else {
													event.target.src =
														CarrierIcon
												}
											}}
											onLoad={(event: any) => {
												event.target.style.display = ''
											}}
											src={
												p.image_preview
													? p.image_preview
													: CarrierIcon
											}
										/>
									</td>
									<td>{p.id}</td>
									<td>{p.scac}</td>
									<td>{p.name}</td>
								</tr>
							)
						})
						const searchAhead =
							document.getElementById(searchAheadID)
						if (searchAhead) {
							searchAhead.style.left = `${target.offsetLeft}px`
							searchAhead.style.top = `${
								target.offsetTop + target.offsetHeight
							}px`
							searchAhead.style.width = `${Math.max(
								target.offsetWidth,
								400
							)}px`
						}
						setPreviews(
							<table>
								<tbody>{rows}</tbody>
							</table>
						)
					} else {
						setPreviews(<></>)
					}
				})
				.catch((error: any) => {
					switch (error?.response?.status) {
					case 401:
						LogOff('token-expired')
						return
					default:
							console.error(error) // eslint-disable-line
					}
				})
		} else {
			setPreviews(<></>)
		}
	}

	const defaultValue = props.value
		? `[${props.data[`${props.field.Property}_scac`]}] ${
			props.data[`${props.field.Property}_name`]
		  }`
		: ''
	return (
		<div className={styles.fieldOuterContainer}>
			<div className={styles.label}>
				{props.field.Label}
				{props.field.HardRequired && !props.isSearch ? (
					<>
						{'\u00A0'}
						<span
							className="hardRequired"
							title="REQUIRED IN ORDER TO SAVE"
						>
							*
						</span>
					</>
				) : (
					<></>
				)}
				{props.field.SoftRequired && !props.isSearch ? (
					<>
						{'\u00A0'}
						<span className="softRequired" title="REQUIRED">
							*
						</span>
					</>
				) : (
					<></>
				)}{' '}
			</div>
			<div className={`${styles.searchAheadBox} pseudoInput`}>
				<Link
					id={`${props.field.Property}_link`}
					rel="noreferrer"
					target="_blank"
					to={`/carriers/${props.data[props.field.Property]}`}
				>
					<img
						id={`${props.field.Property}_logo`}
						onError={(event: any) => {
							if (event.target.src == CarrierIcon) {
								event.target.style.display = 'none'
							} else {
								event.target.src = CarrierIcon
							}
						}}
						onLoad={(event: any) => {
							event.target.style.display = ''
						}}
						src={
							props.data[`${props.field.Property}_image_preview`]
								? props.data[
									`${props.field.Property}_image_preview`
								  ]
								: CarrierIcon
						}
					/>
				</Link>
				<input
					autoComplete="off"
					className={`${
						props.field.HardRequired && !props.isSearch
							? 'hardRequired'
							: ''
					} ${
						props.field.SoftRequired && !props.isSearch
							? 'softRequired'
							: ''
					}`}
					data-value={defaultValue}
					defaultValue={defaultValue}
					id={`${props.field.Property}_name`}
					onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
						search(e.target)
						if (props.onChange) {
							props.onChange(e)
						}
					}}
					onFocus={useDebouncedCallback((e: ChangeEvent) => {
						search(e.target as HTMLInputElement)
					}, 100)}
					placeholder=" "
					readOnly={props.readOnly || props.field.ReadOnly}
					required={props.field.HardRequired && !props.isSearch}
					title={props.field.Label?.replace(':', '')}
					type="text"
				/>
				<div className={styles.previews} id={searchAheadID}>
					{previews}
				</div>
			</div>
			<input
				data-hardrequired={props.field.HardRequired && !props.isSearch}
				data-nullable={true}
				data-number={true}
				data-softrequired={props.field.SoftRequired && !props.isSearch}
				id={props.field.Property}
				name={props.field.Property}
				readOnly={true}
				type="hidden"
				value={value}
			/>
		</div>
	)
}
