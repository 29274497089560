import axios from 'axios'
import { useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'

import AgentAccessIcon from '../../../../../../assets/icons/agent-access.svg'
import DownArrowIcon from '../../../../../../assets/icons/arrow-down.svg'
import DocumentsIcon from '../../../../../../assets/icons/documents.svg'
import OrganizationHistoryIcon from '../../../../../../assets/icons/organization-history.svg'
import OrganizationInfoIcon from '../../../../../../assets/icons/organization-info.svg'
import OrganizationUsersIcon from '../../../../../../assets/icons/organization-users.svg'
import ShipmentsIcon from '../../../../../../assets/icons/shipments.svg'
import {
	EditForm,
	ParsedFormData,
} from '../../../../../../components/Form/EditForm'
import { OrganizationSummary } from '../../../../../../components/OrganizationSummary'
import { GlobalTopTabItems } from '../../../../../../components/Tabs'
import { DefaultLayout } from '../../../../../../layouts/DefaultLayout'
import { APIHostname, HasPermission, LogOff } from '../../../../../../Profile'
import { NewUserLayout } from '../../../../../../types/data/User'
import styles from './UsersNew.module.scss'

export const OrganizationUsersNew = () => {
	const { id } = useParams()

	const [errorMessage, setErrorMessage] = useState(<></>)
	const initialRedirectMessage = sessionStorage['redirect-message']
	const [redirectMessage] = useState(initialRedirectMessage)

	const location = useLocation()
	const [organization, setOrganization]: any = useState(null)
	const [organizationLoaded, setOrganizationLoaded] = useState(false)
	const [organizationName, setOrganizationName] = useState('()')
	const [organizationPreview, setOrganizationPreview] = useState('')

	useEffect(() => {
		axios({
			data: {},
			headers: {
				Authorization: `Bearer ${localStorage['token']}`,
			},
			method: 'GET',
			url: `${APIHostname}/api/v1/organizations/${id}`,
		})
			.then((response: any) => {
				setErrorMessage(<></>)
				setOrganization(response.data)
				setOrganizationName(response.data.name)
				setOrganizationPreview(response.data.preview)
				setOrganizationLoaded(true)
			})
			.catch((error: any) => {
				switch (error?.response?.status) {
				case 401:
					LogOff('token-expired')
					return
				case 403:
					setErrorMessage(
						<>
								Error: You do not have permission to view
								organizations.
						</>
					)
					break
				case 404:
					setOrganizationName('()')
					setOrganizationPreview('')
					setOrganizationLoaded(true)
					break
				default:
					if (error?.response?.data?.error) {
						setErrorMessage(
							error?.response?.data?.link ? (
								<Link to={error?.response?.data?.link}>
										Error:
									{error?.response?.data?.error}
								</Link>
							) : (
								<>{error?.response?.data?.error}</>
							)
						)
					} else {
						setErrorMessage(<>Error: Unknown error.</>)
							console.error(error) // eslint-disable-line
					}
				}
			})
	}, [location])

	if (!organizationLoaded) {
		return <div className="loading">Loading...</div>
	}

	let css = <></>
	if (id) {
		css = (
			<style>
				{`
				.role, .organization, .partner {
					display: none;
				}
			`}
			</style>
		)
	}

	const NewUsersLeftMenuItems = [
		{
			icon: OrganizationInfoIcon,
			requiredPermissions: ['organization.read'],
			text: 'ORGANIZATION INFO',
			to: `/organizations/${id}`,
		},
		{
			icon: DocumentsIcon,
			requiredPermissions: ['shipment.read'],
			text: 'ORGANIZATION DOCUMENTS',
			to: `/organizations/${id}/documents`,
		},
		{
			icon: ShipmentsIcon,
			requiredPermissions: ['shipment.read'],
			text: 'LINKED SHIPMENTS',
			to: `/organizations/${id}/shipments`,
		},
		{
			icon: OrganizationUsersIcon,
			requiredPermissions: ['user.read'],
			text: 'ORGANIZATION USERS',
			to: `/organizations/${id}/users`,
		},
		{
			icon: AgentAccessIcon,
			requiredPermissions: ['organization.manageaccess'],
			text: 'PARTNER ACCESS',
			to: `/organizations/${id}/partner_access`,
		},
		{
			icon: OrganizationHistoryIcon,
			requiredPermissions: ['organization.read'],
			text: 'ORGANIZATION HISTORY',
			to: `/organizations/${id}/history`,
		},
	]

	const NewUsersBottomTabItems = [
		{
			icon: DownArrowIcon,
			text: 'NEW USER',
			to: `/organizations/${id}/users/new`,
		},
	]

	/* eslint-disable @typescript-eslint/ban-types */
	sessionStorage.removeItem('redirect-message')
	return (
		<DefaultLayout
			bottomTabItems={NewUsersBottomTabItems}
			leftMenuItems={NewUsersLeftMenuItems}
			showPrint={true}
			tabMenuItems={NewUsersTabMenuItems}
			topTabItems={GlobalTopTabItems}
		>
			{css}
			<div className={styles.userContainer}>
				<OrganizationSummary organization={organization} />

				<div className={styles.redirectMessage}>{redirectMessage}</div>
				<div className={styles.errorMessage}>{errorMessage}</div>

				<EditForm
					data={{
						organization: id,
						organization_name: organizationName,
						organization_preview: organizationPreview,
						partner: '-1',
						partner_name: '()',
						partner_preview: '',
						permissions: {
							'arrival_notice.read': true,
							'carrier.read': true,
							'nra.read': true,
							'organization.read': true,
							'partner.read': true,
							'report.read': true,
							'shipment.read': true,
							'user.read': true,
							'user.updateself': true,
						},
						role: 'organization',
					}}
					layout={NewUserLayout}
					name="user"
					onSubmit={async (formData: FormData) => {
						const payload = await ParsedFormData(formData, [])
						payload.permissions = {}
						const permissions = document.querySelectorAll(
							'input[type=checkbox]'
						)
						permissions.forEach((permission: Element) => {
							const permissionBox = permission as HTMLInputElement
							if (permissionBox.name.indexOf('.') > -1) {
								if (payload[permissionBox.name] == true) {
									payload.permissions[permissionBox.name] =
										true
								}
								delete payload[permissionBox.name]
							}
						})
						axios({
							data: payload,
							headers: {
								Authorization: `Bearer ${localStorage['token']}`,
								'Content-Type': 'application/json',
							},
							method: 'POST',
							url: `${APIHostname}/api/v1/users`,
						})
							.then((response: any) => {
								sessionStorage[
									'redirect-message'
								] = `User #${response.data.user_id} created.`
								window.history.replaceState(
									{},
									'',
									`/users/${response.data.user_id}`
								)
								document.location.reload()
							})
							.catch((error: any) => {
								switch (error?.response?.status) {
								case 401:
									LogOff('token-expired')
									return
								case 403:
									setErrorMessage(
										<>
												Error: You do not have
												permission to edit users.
										</>
									)
									return
								default:
									if (error?.response?.data?.error) {
										setErrorMessage(
											error?.response?.data?.link ? (
												<Link
													to={
														error?.response
															?.data?.link
													}
												>
														Error:
													{
														error?.response
															?.data?.error
													}
												</Link>
											) : (
												<>
													{
														error?.response
															?.data?.error
													}
												</>
											)
										)
									} else {
										setErrorMessage(
											<>Error: Unknown error.</>
										)
											console.error(error) // eslint-disable-line
									}
									return
								}
							})
					}}
					previewAPIData={async (formData: FormData) => {
						const payload = await ParsedFormData(formData, [])
						payload.permissions = {}
						const permissions = document.querySelectorAll(
							'input[type=checkbox]'
						)
						permissions.forEach((permission: Element) => {
							const permissionBox = permission as HTMLInputElement
							if (permissionBox.name.indexOf('.') > -1) {
								if (payload[permissionBox.name] == true) {
									payload.permissions[permissionBox.name] =
										true
								}
								delete payload[permissionBox.name]
							}
						})

						return {
							data: JSON.stringify(payload, null, 4),
							method: 'POST',
							url: `${APIHostname}/api/v1/users`,
						}
					}}
					readOnly={!HasPermission('user', 'update')}
				/>
			</div>
		</DefaultLayout>
	)
}

export const NewUsersTabMenuItems = []
