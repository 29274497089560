import Control from '../Control'
import { Layout } from '../Layout'

export const PartnerAccessLayout: Layout = {
	Groups: [
		{
			OptionalRows: [
				{
					Fields: [
						{
							Control: Control.Organization,
							Label: 'ADD ORGANIZATION',
							Multiple: true,
							Property: 'organizations',
							Width: 100,
						},
					],
				},
			],
			Width: 100,
		},
	],
}
