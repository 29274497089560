import { useState } from 'react'
import { useLocation } from 'react-router-dom'

import ExpandDownDarkIcon from '../../assets/icons/expand-down-dark.svg'
import { HasPermissionsRoleAndValues } from '../../Profile'
import { Field } from '../../types/Field'
import { EncodedStringify, Filter } from '../../types/Filter'
import { Group } from '../../types/Group'
import { Layout } from '../../types/Layout'
import { EscapeURIParameters, RandomID } from '../../Utils'
import { EditButton } from './Button'
import { EditGroup } from './EditGroup'
import { EditField } from './Field'
import styles from './SearchForm.module.scss'

export const SearchForm = (props: SearchFormProps) => {
	const location = useLocation()

	const urlParams = new URLSearchParams(location.search)
	const filters = JSON.parse(urlParams.get('filters') ?? '[]')
	let nonWildcard = false
	filters.forEach((filter: Filter) => {
		if (
			filter.field != '*' &&
			(filter.field != 'active' ||
				filter.operator != 'eq' ||
				filter.value != 'true')
		) {
			nonWildcard = true
		}
	})
	const [showAdvanced, setShowAdvanced] = useState(nonWildcard)
	filters.forEach((filter: Filter) => {
		props.data[filter.field ?? ''] = filter.value
		props.data[`${filter.field}-${filter.operator}`] = filter.value
	})

	const buttonRow = (
		<div className={styles.buttonRow}>
			<div
				className={styles.expandOptionalRows}
				onClick={() => {
					setShowAdvanced(!showAdvanced)
				}}
			>
				<span className="showAdvanced">
					{showAdvanced ? (
						<>
							<img
								className={styles.flip}
								src={ExpandDownDarkIcon}
							/>{' '}
							HIDE{' '}
						</>
					) : (
						<>SHOW </>
					)}
					ADVANCED OPTIONS
					{showAdvanced ? (
						<></>
					) : (
						<>
							{' '}
							<img src={ExpandDownDarkIcon} />
						</>
					)}
				</span>
			</div>

			<div className={styles.buttons} key={RandomID(16)}>
				{
					<>
						<EditButton
							button={{
								Label: 'CLEAR',
								Type: 'clear',
							}}
							key={RandomID(16)}
						/>
						<EditButton
							button={{
								Label: 'SEARCH',
								Type: 'submit',
							}}
							key={RandomID(16)}
						/>
					</>
				}
			</div>
		</div>
	)

	return (
		<form
			className={styles.formContainer}
			name={props.name}
			onSubmit={async (e: React.FormEvent<HTMLFormElement>) => {
				const formData = new FormData(e.currentTarget)
				const filters: Filter[] = []
				formData.forEach((value: FormDataEntryValue, key: string) => {
					const operator =
						document.getElementById(key)?.dataset.searchoperator
					if (key.indexOf('-') > -1) {
						key = key.substring(0, key.indexOf('-'))
					}
					if (value && value.toString() != '0') {
						filters.push({
							field: key,
							operator: operator ?? 'contains',
							value: value.toString(),
						})
					}
				})
				if (filters.length > 0) {
					urlParams.set('filters', EncodedStringify(filters))
				} else {
					urlParams.delete('filters')
				}
				document.location.replace(
					`${document.location.origin}${location.pathname}${
						urlParams.size > 0 ? '?' : ''
					}${EscapeURIParameters(urlParams.toString())}`
				)
				e.preventDefault()
				e.preventDefault()
			}}
		>
			{props.layout.Groups?.map((group: Group, index: number) => {
				return group.IncludeInSearch != false &&
					HasPermissionsRoleAndValues(
						group.RequiredPermissions,
						group.RequiredRole,
						group.RequiredValues,
						props.data
					) ? (
						<EditGroup
							data={props.data}
							group={group}
							isSearch={true}
							key={`group${index}`}
							showOptional={showAdvanced}
						>
							{index == 0 ? buttonRow : <></>}
						</EditGroup>
					) : (
						<span key={RandomID(16)}></span>
					)
			})}

			{props.layout.Fields?.map((field: Field, index: number) => (
				<EditField
					data={props.data ?? {}}
					field={field}
					isSearch={true}
					key={`field${index}`}
					value={props.data ? props.data[field.Property] : null}
				/>
			))}
		</form>
	)
}

export type SearchFormProps = {
	data: any
	layout: Layout
	name: string
	readOnly: boolean
}
