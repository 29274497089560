import axios from 'axios'
import { useEffect, useState } from 'react'

import { APIHostname, LogOff } from '../../../../Profile'
import { OrganizationType } from '../../../../types/data/OrganizationType'
import { FieldProps } from '../../Field'
import styles from './OrganizationType.module.scss'

export const EditOrganizationType = (props: FieldProps) => {
	const [organizationTypes, setOrganizationTypes]: any = useState(null)
	const [value, setValue] = useState(props.value)
	const [loaded, setLoaded] = useState(false)

	useEffect(() => {
		axios({
			data: {},
			headers: {
				Authorization: `Bearer ${localStorage['token']}`,
			},
			method: 'GET',
			url: `${APIHostname}/api/v1/organizationtypes?sorts=[{"field":"name","order":"ASC"}]`,
		})
			.then((response: any) => {
				setOrganizationTypes(response.data.organization_types)
				setLoaded(true)
			})
			.catch((error: any) => {
				switch (error?.response?.status) {
				case 401:
					LogOff('token-expired')
					return
				default:
						console.error(error) // eslint-disable-line
				}
			})
	}, [])

	if (!loaded) {
		return <></>
	}

	if (props.readOnly || props.field.ReadOnly) {
		return (
			<div
				className={styles.fieldOuterContainer}
				id={
					props.field.Property
						? `${props.field.Property}_container`
						: ''
				}
			>
				<div className={styles.label}>
					{props.field.Label}
					{props.field.HardRequired && !props.isSearch ? (
						<>
							{'\u00A0'}
							<span
								className="hardRequired"
								title="REQUIRED IN ORDER TO SAVE"
							>
								*
							</span>
						</>
					) : (
						<></>
					)}
					{props.field.SoftRequired && !props.isSearch ? (
						<>
							{'\u00A0'}
							<span className="softRequired" title="REQUIRED">
								*
							</span>
						</>
					) : (
						<></>
					)}{' '}
				</div>
				<input
					data-hardrequired={
						props.field.HardRequired && !props.isSearch
					}
					data-softrequired={
						props.field.SoftRequired && !props.isSearch
					}
					defaultValue={
						organizationTypes.filter((organizationType: any) => {
							return organizationType.type == value
						})[0]?.name
					}
					onChange={props.onChange}
					readOnly={true}
					title={props.field.Label?.replace(':', '')}
					type="text"
				/>
			</div>
		)
	}

	return (
		<div
			className={styles.fieldOuterContainer}
			id={props.field.Property ? `${props.field.Property}_container` : ''}
		>
			<div className={styles.label}>
				{props.field.Label}
				{props.field.HardRequired && !props.isSearch ? (
					<>
						{'\u00A0'}
						<span
							className="hardRequired"
							title="REQUIRED IN ORDER TO SAVE"
						>
							*
						</span>
					</>
				) : (
					<></>
				)}
				{props.field.SoftRequired && !props.isSearch ? (
					<>
						{'\u00A0'}
						<span className="softRequired" title="REQUIRED">
							*
						</span>
					</>
				) : (
					<></>
				)}{' '}
			</div>
			<select
				autoComplete="off"
				className={`${
					props.field.HardRequired && !props.isSearch
						? 'hardRequired'
						: ''
				} ${
					props.field.SoftRequired && !props.isSearch
						? 'softRequired'
						: ''
				}`}
				data-hardrequired={props.field.HardRequired && !props.isSearch}
				data-nullable={props.field.Nullable}
				data-softrequired={props.field.SoftRequired && !props.isSearch}
				id={props.field.Property}
				name={props.field.Property}
				onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
					setValue(e.target.value)
					if (props.onChange) {
						props.onChange(e)
					}
				}}
				required={props.field.HardRequired && !props.isSearch}
				title={props.field.Label?.replace(':', '')}
				value={value}
			>
				<option value=""></option>
				{organizationTypes?.map(
					(organizationtype: OrganizationType, index: number) => (
						<option
							key={`organizationtype${index}`}
							value={organizationtype.type}
						>
							{organizationtype.name}
						</option>
					)
				)}
			</select>
		</div>
	)
}
