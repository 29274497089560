import axios from 'axios'
import { useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'

import AgentAccessIcon from '../../../../assets/icons/agent-access.svg'
import DownArrowIcon from '../../../../assets/icons/arrow-down.svg'
import ChainIcon from '../../../../assets/icons/chain.svg'
import DocumentsIcon from '../../../../assets/icons/documents.svg'
import PayBalanceIcon from '../../../../assets/icons/goal-pay-balance.svg'
import PayRequestMoneyIcon from '../../../../assets/icons/goal-pay-request-money.svg'
import PaySendMoneyIcon from '../../../../assets/icons/goal-pay-send-money.svg'
import PayTransactionsIcon from '../../../../assets/icons/goal-pay-transactions.svg'
import PayIncomingRequestsIcon from '../../../../assets/icons/goal-pay-transfer-money.svg'
import PartnerHistoryIcon from '../../../../assets/icons/organization-history.svg'
import PartnerInfoIcon from '../../../../assets/icons/organization-info.svg'
import PartnerUsersIcon from '../../../../assets/icons/organization-users.svg'
import { EditForm, ParsedFormData } from '../../../../components/Form/EditForm'
import { LeftMenuItem } from '../../../../components/LeftMenu'
import { CurrencyFormatter, NavTable } from '../../../../components/NavTable'
import { PartnerSummary } from '../../../../components/PartnerSummary'
import { GlobalTopTabItems } from '../../../../components/Tabs'
import { DefaultLayout } from '../../../../layouts/DefaultLayout'
import {
	APIHostname,
	HasPermission,
	LogOff,
	ProfilePartnerID,
} from '../../../../Profile'
import Control from '../../../../types/Control'
import { PayRequestLayout } from '../../../../types/data/PayRequest'
import { EscapeURIParameters } from '../../../../Utils'
import styles from './PayRequest.module.scss'

export const PayRequest = () => {
	let { id } = useParams()
	if (!id) {
		id = ProfilePartnerID()?.toString()
	}
	if (!id) {
		id = '0'
	}

	const [partner, setPartner]: any = useState(null)
	const [balance, setBalance]: any = useState(null)
	const [transactions, setTransactions]: any = useState(null)
	const [transactionCount, setTransactionCount]: any = useState(null)
	const [partnerLoaded, setPartnerLoaded]: any = useState(false)
	const [balanceLoaded, setBalanceLoaded]: any = useState(false)
	const [transactionsLoaded, setTransactionsLoaded]: any = useState(false)
	const [payRequestCount, setPayRequestCount] = useState(0)
	const [countsLoaded, setCountsLoaded] = useState(false)
	const [errorMessage, setErrorMessage] = useState(<></>)
	const initialRedirectMessage = sessionStorage['redirect-message']
	const [redirectMessage] = useState(initialRedirectMessage)

	// Vary based on query string.
	const location = useLocation()
	const urlParams = new URLSearchParams(location.search)
	if (!urlParams.get('limit')) {
		urlParams.set('limit', '25')
	}
	const locationPrefix = location.pathname.startsWith('/partners')
		? `/partners/${id}`
		: ''

	useEffect(() => {
		axios({
			data: {},
			headers: {
				Authorization: `Bearer ${localStorage['token']}`,
			},
			method: 'GET',
			url: `${APIHostname}/api/v1/partners/${id}?${EscapeURIParameters(
				urlParams.toString()
			)}`,
		})
			.then((response: any) => {
				setErrorMessage(<></>)
				setPartner(response.data)
				setPartnerLoaded(true)

				axios({
					data: {},
					headers: {
						Authorization: `Bearer ${localStorage['token']}`,
					},
					method: 'GET',
					url: `${APIHostname}/api/v1${locationPrefix}/pay_counts`,
				})
					.then((response: any) => {
						setErrorMessage(<></>)
						setPayRequestCount(response.data.pay_request_count)
						setCountsLoaded(true)
					})
					.catch((error: any) => {
						switch (error?.response?.status) {
						case 401:
							LogOff('token-expired')
							return
						case 403:
							setCountsLoaded(true)
							setErrorMessage(
								<>
										Error: You do not have permission to
										view GOAL pay requests.
								</>
							)
							break
						default:
							if (error?.response?.data?.error) {
								setErrorMessage(
									error?.response?.data?.link ? (
										<Link
											to={error?.response?.data?.link}
										>
												Error:
											{error?.response?.data?.error}
										</Link>
									) : (
										<>{error?.response?.data?.error}</>
									)
								)
							} else {
								setErrorMessage(<>Error: Unknown error.</>)
									console.error(error) // eslint-disable-line
							}
						}
					})
			})
			.catch((error: any) => {
				switch (error?.response?.status) {
				case 401:
					LogOff('token-expired')
					return
				case 403:
					setErrorMessage(
						<>
								Error: You do not have permission to view
								partners.
						</>
					)
					break
				case 404:
					setErrorMessage(
						<>
								No partner selected. Either log in as a partner
								or access GOAL Pay after selecting a partner.
						</>
					)
					return
				default:
					if (error?.response?.data?.error) {
						setErrorMessage(
							error?.response?.data?.link ? (
								<Link to={error?.response?.data?.link}>
										Error:
									{error?.response?.data?.error}
								</Link>
							) : (
								<>{error?.response?.data?.error}</>
							)
						)
					} else {
						setErrorMessage(<>Error: Unknown error.</>)
							console.error(error) // eslint-disable-line
					}
				}
			})

		axios({
			data: {},
			headers: {
				Authorization: `Bearer ${localStorage['token']}`,
			},
			method: 'GET',
			url: `${APIHostname}/api/v1/partners/${id}/pay_balance?${EscapeURIParameters(
				urlParams.toString()
			)}`,
		})
			.then((response: any) => {
				setErrorMessage(<></>)
				setBalance(response.data.balance)
				setBalanceLoaded(true)
			})
			.catch((error: any) => {
				switch (error?.response?.status) {
				case 401:
					LogOff('token-expired')
					return
				case 403:
					setErrorMessage(
						<>
								Error: You do not have permission to view GOAL
								Pay balances.
						</>
					)
					break
				case 404:
					setErrorMessage(
						<>
								No partner selected. Either log in as a partner
								or access GOAL Pay after selecting a partner.
						</>
					)
					return
				default:
					if (error?.response?.data?.error) {
						setErrorMessage(
							error?.response?.data?.link ? (
								<Link to={error?.response?.data?.link}>
										Error:
									{error?.response?.data?.error}
								</Link>
							) : (
								<>{error?.response?.data?.error}</>
							)
						)
					} else {
						setErrorMessage(<>Error: Unknown error.</>)
							console.error(error) // eslint-disable-line
					}
				}
			})

		axios({
			data: {},
			headers: {
				Authorization: `Bearer ${localStorage['token']}`,
			},
			method: 'GET',
			url: `${APIHostname}/api/v1/partners/${id}/pay_transactions?${EscapeURIParameters(
				urlParams.toString()
			)}`,
		})
			.then((response: any) => {
				setErrorMessage(<></>)

				for (let i = 0; i < response.data.transactions?.length; i++) {
					if (response.data.transactions[i].origin == id) {
						response.data.transactions[i].amount =
							-1 * response.data.transactions[i].amount
						response.data.transactions[i].class = 'sent'
						response.data.transactions[i].image = (
							<img src={PaySendMoneyIcon} title="SENT" />
						)
					} else {
						response.data.transactions[i].class = 'received'
						response.data.transactions[i].image = (
							<img src={PayRequestMoneyIcon} title="RECEIVED" />
						)
					}
				}

				setTransactions(response.data.transactions)
				setTransactionCount(response.data.transaction_count)
				setTransactionsLoaded(true)
			})
			.catch((error: any) => {
				switch (error?.response?.status) {
				case 401:
					LogOff('token-expired')
					return
				case 403:
					setErrorMessage(
						<>
								Error: You do not have permission to view GOAL
								Pay balances.
						</>
					)
					break
				case 404:
					setErrorMessage(
						<>
								No partner selected. Either log in as a partner
								or access GOAL Pay after selecting a partner.
						</>
					)
					return
				default:
					if (error?.response?.data?.error) {
						setErrorMessage(
							error?.response?.data?.link ? (
								<Link to={error?.response?.data?.link}>
										Error:
									{error?.response?.data?.error}
								</Link>
							) : (
								<>{error?.response?.data?.error}</>
							)
						)
					} else {
						setErrorMessage(<>Error: Unknown error.</>)
							console.error(error) // eslint-disable-line
					}
				}
			})
	}, [location])

	const PayRequestBottomTabItems = [
		{
			icon: DownArrowIcon,
			text: 'GOAL PAY - REQUEST MONEY',
			to: `${locationPrefix}/pay/request`,
		},
	]

	const PayLeftMenuItems: LeftMenuItem[] = []

	if (locationPrefix) {
		PayLeftMenuItems.push(
			{
				icon: PartnerInfoIcon,
				requiredPermissions: ['partner.read'],
				text: 'PARTNER INFO',
				to: `/partners/${id}`,
			},
			{
				icon: AgentAccessIcon,
				requiredPermissions: ['organization.manageaccess'],
				text: 'ORGANIZATION ACCESS',
				to: `/partners/${id}/organization_access`,
			},
			{
				icon: DocumentsIcon,
				requiredPermissions: ['partner.read'],
				text: 'PARTNER DOCUMENTS',
				to: `/partners/${id}/documents`,
			},
			{
				icon: ChainIcon,
				requiredPermissions: ['shipment.read'],
				text: 'LINKED SHIPMENTS',
				to: `/partners/${id}/shipments`,
			},
			{
				icon: PartnerUsersIcon,
				requiredPermissions: ['user.read'],
				text: 'PARTNER USERS',
				to: `/partners/${id}/users`,
			},
			{
				icon: PartnerHistoryIcon,
				requiredPermissions: ['partner.read'],
				text: 'PARTNER HISTORY',
				to: `/partners/${id}/history`,
			},
			{
				disabled: true,
				requiredPermissions: [],
				text: 'ADMINISTRATOR OPTIONS',
				to: '',
			}
		)
	}

	PayLeftMenuItems.push(
		{
			icon: PayBalanceIcon,
			requiredPermissions: ['pay.viewbalance'],
			text: 'BALANCE',
			to: `${locationPrefix}/pay/balance`,
		},
		{
			icon: PaySendMoneyIcon,
			requiredPermissions: ['pay.sendmoney'],
			requiredRole: 'administrator',
			text: 'DEPOSIT MONEY',
			to: `${locationPrefix}/pay/deposit`,
		},
		{
			icon: PayRequestMoneyIcon,
			requiredPermissions: ['pay.requestmoney'],
			requiredRole: 'administrator',
			text: 'WITHDRAW MONEY',
			to: `${locationPrefix}/pay/withdraw`,
		},
		{
			icon: PayRequestMoneyIcon,
			requiredPermissions: ['pay.requestmoney'],
			text: 'REQUEST MONEY',
			to: `${locationPrefix}/pay/request`,
		},
		{
			icon: PaySendMoneyIcon,
			requiredPermissions: ['pay.sendmoney'],
			text: 'SEND MONEY',
			to: `${locationPrefix}/pay/send`,
		},
		{
			count: payRequestCount,
			icon: PayIncomingRequestsIcon,
			requiredPermissions: ['pay.sendmoney'],
			text: 'INCOMING PAYMENT REQUESTS',
			to: `${locationPrefix}/pay/incoming_requests`,
		},
		{
			icon: PayTransactionsIcon,
			requiredPermissions: ['pay.viewbalance'],
			text: 'TRANSACTIONS',
			to: `${locationPrefix}/pay/transactions`,
		}
	)

	if (
		!partnerLoaded ||
		!balanceLoaded ||
		!transactionsLoaded ||
		!countsLoaded
	) {
		if (errorMessage) {
			return (
				<DefaultLayout
					bottomTabItems={PayRequestBottomTabItems}
					leftMenuItems={PayLeftMenuItems}
					showPrint={true}
					tabMenuItems={PayRequestTabMenuItems}
					topTabItems={GlobalTopTabItems}
				>
					<div className={styles.payRequestContainer}>
						<div className={styles.redirectMessage}>
							{redirectMessage}
						</div>
						<div className={styles.errorMessage}>
							{errorMessage}
						</div>
					</div>
				</DefaultLayout>
			)
		}

		return <div className="loading">Loading...</div>
	}

	sessionStorage.removeItem('redirect-message')
	return (
		<DefaultLayout
			bottomTabItems={PayRequestBottomTabItems}
			leftMenuItems={PayLeftMenuItems}
			showPrint={true}
			tabMenuItems={PayRequestTabMenuItems}
			topTabItems={GlobalTopTabItems}
		>
			<div className={styles.payRequestContainer}>
				<div className={styles.redirectMessage}>{redirectMessage}</div>
				<div className={styles.errorMessage}>{errorMessage}</div>

				<div className={styles.contentBox}>
					<PartnerSummary horizontalChildren={true} partner={partner}>
						<div className={styles.column}>
							<div className={styles.label}>CURRENT BALANCE:</div>
							<div className={styles.balance}>
								{CurrencyFormatter.format(balance)}
							</div>
						</div>
					</PartnerSummary>
				</div>

				<EditForm
					data={{}}
					layout={PayRequestLayout}
					name="payrequest"
					onSubmit={async (formData: FormData) => {
						const parsedFormData = await ParsedFormData(
							formData,
							[]
						)
						parsedFormData.destination = parseInt(id ?? '0')

						// Request pay
						axios({
							data: parsedFormData,
							headers: {
								Authorization: `Bearer ${localStorage['token']}`,
								'Content-Type': 'application/json',
							},
							method: 'POST',
							url: `${APIHostname}/api/v1/partners/${id}/pay_requests`,
						})
							.then((response: any) => {
								sessionStorage[
									'redirect-message'
								] = `Request #${response.data.request_id} submitted.`
								window.history.replaceState(
									{},
									'',
									`${locationPrefix}/pay/balance`
								)
								document.location.reload()
							})
							.catch((error: any) => {
								switch (error?.response?.status) {
								case 401:
									LogOff('token-expired')
									return
								case 403:
									setErrorMessage(
										<>
												Error: You do not have
												permission to send money.
										</>
									)
									break
								default:
									if (error?.response?.data?.error) {
										setErrorMessage(
											error?.response?.data?.link ? (
												<Link
													to={
														error?.response
															?.data?.link
													}
												>
														Error:
													{
														error?.response
															?.data?.error
													}
												</Link>
											) : (
												<>
													{
														error?.response
															?.data?.error
													}
												</>
											)
										)
									} else {
										setErrorMessage(
											<>Error: Unknown error.</>
										)
											console.error(error) // eslint-disable-line
									}
								}
							})
					}}
					previewAPIData={async (formData: FormData) => {
						const parsedFormData = await ParsedFormData(
							formData,
							[]
						)
						parsedFormData.destination = parseInt(id ?? '0')

						return {
							data: JSON.stringify(parsedFormData, null, 4),
							method: 'POST',
							url: `${APIHostname}/api/v1/partners/${id}/pay_requests`,
						}
					}}
					readOnly={!HasPermission('pay', 'sendmoney')}
				/>

				<NavTable
					columns={[
						{
							control: Control.HTML,
							id: 'image',
							title: '',
						},
						{
							control: Control.Partner,
							id: 'origin',
							title: 'FROM',
						},
						{
							control: Control.Partner,
							id: 'destination',
							title: 'TO',
						},
						{
							id: 'memo',
							title: 'MEMO',
						},
						{
							control: Control.Currency,
							id: 'amount',
							title: 'AMOUNT',
						},
						{
							control: Control.Currency,
							id: 'balance',
							title: 'BALANCE',
						},
						{
							id: 'origin_reference',
							title: 'ORIGIN REFERENCE #',
						},
						{
							id: 'destination_reference',
							title: 'DESTINATION REFERENCE #',
						},
						{
							children: [
								{
									control: Control.DateTime,
									id: 'created',
									title: 'TRANSACTION DATE',
								},
								{
									control: Control.User,
									id: 'created_by',
									title: 'SENDER',
								},
							],
						},
					]}
					count={transactionCount}
					data={transactions}
					filters={JSON.parse(urlParams.get('filters') ?? '[]')}
					includeDelete={false}
					includeDisplayCount={false}
					label={'RECENT ACTIVITY:'}
					linkPathRoot={`/partners/${id}/pay_transactions`}
					primaryDisplayColumn="id"
					primaryKey="na"
				/>
			</div>
		</DefaultLayout>
	)
}

export const PayRequestTabMenuItems = []
