import { ReactNode } from 'react'
import { Link } from 'react-router-dom'

import { Carrier } from '../../types/data/Carrier'
import styles from './CarrierSummary.module.scss'

export const CarrierSummary = (props: CarrierSummaryProps) => {
	if (!props.carrier) {
		return <></>
	}

	return (
		<div className={styles.carrierSummaryOuterContainer}>
			<div className={styles.carrierSummaryInnerContainer}>
				<div>
					<div className={styles.label}>NAME:</div>
					<div className={styles.value}>{props.carrier.name}</div>
				</div>
				<div>
					<div className={styles.label}>SCAC:</div>
					<div className={styles.value}>
						<Link to={`/carriers/${props.carrier.id}`}>
							{props.carrier.scac}
						</Link>
					</div>
				</div>
			</div>
			{props.children}
		</div>
	)
}

export type CarrierSummaryProps = {
	children?: ReactNode
	carrier: Carrier
}
