export type Filter = {
	aggregate?: string
	children?: Filter[]
	field?: string
	operator?: string
	value?: string
}

export const EncodedStringify = (input: any) => {
	return JSON.stringify(input).replaceAll('&', '%26')
}
