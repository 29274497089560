import axios from 'axios'
import { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

import DownArrowIcon from '../../../assets/icons/arrow-down.svg'
import NewIcon from '../../../assets/icons/new.svg'
import { SearchForm } from '../../../components/Form/SearchForm'
import { AdministrationLeftMenuItems } from '../../../components/LeftMenu'
import { NavTable } from '../../../components/NavTable'
import { GlobalTopTabItems } from '../../../components/Tabs'
import { DefaultLayout } from '../../../layouts/DefaultLayout'
import { APIHostname, HasPermission, HasRole, LogOff } from '../../../Profile'
import Control from '../../../types/Control'
import { CarrierSearchLayout } from '../../../types/data/Carrier'
import { EscapeURIParameters } from '../../../Utils'
import styles from './Carriers.module.scss'

export const Carriers = () => {
	const [carriers, setCarriers]: any = useState(null)
	const [carrierCount, setCarrierCount] = useState(0)
	const [loaded, setLoaded]: any = useState(false)
	const [errorMessage, setErrorMessage] = useState(<></>)
	const initialRedirectMessage = sessionStorage['redirect-message']
	const [redirectMessage] = useState(initialRedirectMessage)

	// Vary based on query string.
	const location = useLocation()
	const urlParams = new URLSearchParams(location.search)
	if (!urlParams.get('limit')) {
		urlParams.set('limit', '25')
	}

	useEffect(() => {
		axios({
			data: {},
			headers: {
				Authorization: `Bearer ${localStorage['token']}`,
			},
			method: 'GET',
			url: `${APIHostname}/api/v1/carriers?${EscapeURIParameters(
				urlParams.toString()
			)}`,
		})
			.then((response: any) => {
				setErrorMessage(<></>)
				setCarriers(response.data.carriers)
				setCarrierCount(response.data.carrier_count)
				setLoaded(true)
			})
			.catch((error: any) => {
				switch (error?.response?.status) {
				case 401:
					LogOff('token-expired')
					return
				case 403:
					setErrorMessage(
						<>
								Error: You do not have permission to view
								carriers.
						</>
					)
					break
				default:
					if (error?.response?.data?.error) {
						setErrorMessage(
							error?.response?.data?.link ? (
								<Link to={error?.response?.data?.link}>
										Error:
									{error?.response?.data?.error}
								</Link>
							) : (
								<>{error?.response?.data?.error}</>
							)
						)
					} else {
						setErrorMessage(<>Error: Unknown error.</>)
							console.error(error) // eslint-disable-line
					}
				}
			})
	}, [location])

	if (!loaded) {
		if (errorMessage) {
			return (
				<DefaultLayout
					bottomTabItems={CarriersBottomTabItems}
					leftMenuItems={AdministrationLeftMenuItems}
					showPrint={true}
					tabMenuItems={CarriersTabMenuItems}
					topTabItems={GlobalTopTabItems}
				>
					<div className={styles.carriersContainer}>
						<div className={styles.redirectMessage}>
							{redirectMessage}
						</div>
						<div className={styles.errorMessage}>
							{errorMessage}
						</div>
					</div>
				</DefaultLayout>
			)
		}

		return <div className="loading">Loading...</div>
	}

	sessionStorage.removeItem('redirect-message')
	return (
		<DefaultLayout
			bottomTabItems={CarriersBottomTabItems}
			leftMenuItems={AdministrationLeftMenuItems}
			showPrint={true}
			tabMenuItems={CarriersTabMenuItems}
			topTabItems={GlobalTopTabItems}
		>
			<div className={styles.carriersContainer}>
				<div className={styles.redirectMessage}>{redirectMessage}</div>
				<div className={styles.errorMessage}>{errorMessage}</div>

				<div className={styles.searchForm}>
					<SearchForm
						data={{}}
						layout={CarrierSearchLayout}
						name="carriers"
						readOnly={false}
					/>
				</div>

				<NavTable
					columns={[
						{
							control: Control.ImageFile,
							id: 'image',
							title: '',
						},
						{
							id: 'scac',
							title: 'SCAC',
						},
						{
							id: 'name',
							title: 'Carrier Name',
						},
						{
							id: 'type',
							title: 'Type',
						},
						{
							id: 'aci',
							title: 'ACI',
						},
						{
							id: 'notes',
							title: 'Notes',
						},
						{
							children: [
								{
									control: Control.DateTime,
									id: 'created',
									title: 'Creation Date',
								},
								{
									control: Control.User,
									id: 'created_by',
									title: 'Created By',
								},
							],
						},
						{
							children: [
								{
									control: Control.DateTime,
									id: 'modified',
									title: 'Modified Date',
								},
								{
									control: Control.User,
									id: 'modified_by',
									title: 'Modified By',
								},
							],
						},
					]}
					count={carrierCount}
					data={carriers}
					filters={JSON.parse(urlParams.get('filters') ?? '[]')}
					includeDelete={
						HasRole('administrator') &&
						HasPermission('carrier', 'delete')
					}
					primaryDisplayColumn="scac"
					primaryKey="id"
				/>
			</div>
		</DefaultLayout>
	)
}

export const CarriersTabMenuItems = [
	{
		icon: NewIcon,
		requiredPermissions: ['carrier.create'],
		text: 'NEW',
		to: '/carriers/new',
	},
]

export const CarriersBottomTabItems = [
	{
		icon: DownArrowIcon,
		text: 'CARRIERS',
		to: '/carriers',
	},
]
