import { ReactNode } from 'react'
import { Link } from 'react-router-dom'

import { ArrivalNotice } from '../../types/data/ArrivalNotice'
import styles from './ArrivalNoticeSummary.module.scss'

export const ArrivalNoticeSummary = (props: ArrivalNoticeSummaryProps) => {
	if (!props.arrivalNotice) {
		return <></>
	}

	return (
		<div className={styles.arrivalNoticeSummaryOuterContainer}>
			<div className={styles.arrivalNoticeSummaryInnerContainer}>
				<div>
					<div className={styles.label}>HBL:</div>
					<div className={styles.value}>
						<Link to={`/arrival_notices/${props.arrivalNotice.id}`}>
							{props.arrivalNotice.override_hbl}
						</Link>
					</div>
				</div>
				<div>
					<div className={styles.label}>MBL:</div>
					<div className={styles.value}>
						{props.arrivalNotice.override_mbl}
					</div>
				</div>
				<div>
					<div className={styles.label}>REFERENCE #:</div>
					<div className={styles.value}>
						{props.arrivalNotice.override_reference}
					</div>
				</div>
			</div>
			{props.children}
		</div>
	)
}

export type ArrivalNoticeSummaryProps = {
	children?: ReactNode
	arrivalNotice: ArrivalNotice
}
