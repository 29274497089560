import axios from 'axios'
import { useState } from 'react'
import { Link } from 'react-router-dom'

import DownArrowIcon from '../../../../assets/icons/arrow-down.svg'
import BookingIcon from '../../../../assets/icons/booking.svg'
import HotListIcon from '../../../../assets/icons/hot-inactive.svg'
import NewIcon from '../../../../assets/icons/new.svg'
import { EditForm, ParsedFormData } from '../../../../components/Form/EditForm'
import { GlobalTopTabItems } from '../../../../components/Tabs'
import { DefaultLayout } from '../../../../layouts/DefaultLayout'
import {
	APIHostname,
	HasPermission,
	LogOff,
	ProfilePartnerID,
	ProfilePartnerName,
} from '../../../../Profile'
import { ShipmentEditLayout } from '../../../../types/data/Shipment'
import styles from './ShipmentsNew.module.scss'

export const ShipmentsNew = () => {
	const [errorMessage, setErrorMessage] = useState(<></>)
	const initialRedirectMessage = sessionStorage['redirect-message']
	const [redirectMessage] = useState(initialRedirectMessage)

	sessionStorage.removeItem('redirect-message')
	return (
		<DefaultLayout
			bottomTabItems={NewShipmentBottomTabItems}
			leftMenuItems={NewShipmentLeftMenuItems}
			showPrint={true}
			tabMenuItems={NewShipmentTabMenuItems}
			topTabItems={GlobalTopTabItems}
		>
			<div className={styles.shipmentsContainer}>
				<div className={styles.redirectMessage}>{redirectMessage}</div>
				<div className={styles.errorMessage}>{errorMessage}</div>

				<EditForm
					customHardValidation={() => {
						const hiFields: string[] = []

						const partnerID = ProfilePartnerID()?.toString()
						if (partnerID) {
							if (
								partnerID !=
									(
										document.getElementById(
											'destination_partner'
										) as HTMLInputElement
									)?.value &&
								partnerID !=
									(
										document.getElementById(
											'origin_partner'
										) as HTMLInputElement
									)?.value
							) {
								hiFields.push(
									'EITHER DESTINATION PARTNER OR ORIGIN PARTNER MUST MATCH YOUR PARTNER'
								)
							}
						}
						return hiFields
					}}
					customSoftValidation={() => {
						const siFields: string[] = []

						const containerNumbersMap: { [key: string]: boolean } =
							{}
						const containerNumbers = document.querySelectorAll(
							'[id^=containers_][id$=_number]'
						)
						containerNumbers.forEach((number: any) => {
							if (!number.id.endsWith('_po_number')) {
								if (containerNumbersMap[number.value]) {
									siFields.push(
										'CONTAINER NUMBERS MUST BE UNIQUE'
									)
								}
								containerNumbersMap[number.value] = true
							}
						})

						let containersActiveCount = 0
						const containersActive = document.querySelectorAll(
							'[id^=containers_][id$=_active]'
						)
						containersActive.forEach((active: any) => {
							if (active.value == 'true') {
								containersActiveCount++
							}
						})
						if (containersActiveCount == 0) {
							siFields.push('CONTAINER(S) REQUIRED')
						}

						let nrasActiveCount = 0
						const nrasActive = document.querySelectorAll(
							'[id^=nras_][id$=_active]'
						)
						nrasActive.forEach((active: any) => {
							if (active.value == 'true') {
								nrasActiveCount++
							}
						})
						if (nrasActiveCount == 0) {
							siFields.push(
								'NRA TO GOAL UNDER SERVICE CONTRACT REQUIRED'
							)
						}

						return siFields
					}}
					data={{
						origin_partner: ProfilePartnerID(),
						origin_partner_name: ProfilePartnerName(),
						status: 'inprogress',
					}}
					layout={ShipmentEditLayout}
					name="shipment"
					onSubmit={async (formData: FormData) => {
						// Create shipment
						axios({
							data: await ParsedFormData(formData, [
								'containers',
								'pricings',
								'hazardous_cargoes',
								'nras_to_goal',
							]),
							headers: {
								Authorization: `Bearer ${localStorage['token']}`,
								'Content-Type': 'application/json',
							},
							method: 'POST',
							url: `${APIHostname}/api/v1/shipments`,
						})
							.then((response: any) => {
								sessionStorage[
									'redirect-message'
								] = `Shipment #${response.data.shipment_id} created.`
								window.history.replaceState(
									{},
									'',
									`/shipments/${response.data.shipment_id}`
								)
								document.location.reload()
							})
							.catch((error: any) => {
								switch (error?.response?.status) {
								case 401:
									LogOff('token-expired')
									return
								case 403:
									setErrorMessage(
										<>
												Error: You do not have
												permission to edit shipments.
										</>
									)
									break
								default:
									if (error?.response?.data?.error) {
										setErrorMessage(
											error?.response?.data?.link ? (
												<Link
													to={
														error?.response
															?.data?.link
													}
												>
														Error:
													{
														error?.response
															?.data?.error
													}
												</Link>
											) : (
												<>
													{
														error?.response
															?.data?.error
													}
												</>
											)
										)
									} else {
										setErrorMessage(
											<>Error: Unknown error.</>
										)
											console.error(error) // eslint-disable-line
									}
								}
							})
					}}
					previewAPIData={async (formData: FormData) => {
						const parsedFormData = await ParsedFormData(formData, [
							'containers',
							'pricings',
							'hazardous_cargoes',
							'nras_to_goal',
						])

						return {
							data: JSON.stringify(parsedFormData, null, 4),
							method: 'POST',
							url: `${APIHostname}/api/v1/shipments`,
						}
					}}
					readOnly={!HasPermission('shipment', 'create')}
					warnOnNavigate={true}
				/>
			</div>
		</DefaultLayout>
	)
}

export const NewShipmentTabMenuItems = [
	{
		icon: HotListIcon,
		requiredPermissions: ['shipment.update'],
		text: 'HOT',
		to: '/shipments/?filters=[{%22field%22:%22hotlist%22,%22operator%22:%22eq%22,%22value%22:%22true%22}]',
	},
	{
		icon: NewIcon,
		requiredPermissions: ['shipment.create'],
		text: 'NEW',
		to: '/shipments/new',
	},
].reverse()

export const NewShipmentBottomTabItems = [
	{
		icon: DownArrowIcon,
		text: 'NEW SHIPMENT',
		to: '/shipments/new',
	},
]

export const NewShipmentLeftMenuItems = [
	{
		icon: BookingIcon,
		text: 'BOOKING',
		to: '/shipments/new',
	},
]
