import axios from 'axios'
import { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

import DownArrowIcon from '../../../assets/icons/arrow-down.svg'
import NewIcon from '../../../assets/icons/new.svg'
import { SearchForm } from '../../../components/Form/SearchForm'
import { AdministrationLeftMenuItems } from '../../../components/LeftMenu'
import { NavTable } from '../../../components/NavTable'
import { GlobalTopTabItems } from '../../../components/Tabs'
import { DefaultLayout } from '../../../layouts/DefaultLayout'
import { APIHostname, HasRole, LogOff } from '../../../Profile'
import Control from '../../../types/Control'
import { EmailTemplateLayout } from '../../../types/data/EmailTemplate'
import { EscapeURIParameters } from '../../../Utils'
import styles from './EmailTemplates.module.scss'

export const EmailTemplates = () => {
	const [emailTemplates, setEmailTemplates]: any = useState(null)
	const [emailTemplateCount, setEmailTemplateCount] = useState(0)
	const [loaded, setLoaded]: any = useState(false)
	const [errorMessage, setErrorMessage] = useState(<></>)
	const initialRedirectMessage = sessionStorage['redirect-message']
	const [redirectMessage] = useState(initialRedirectMessage)

	// Vary based on query string.
	const location = useLocation()
	const urlParams = new URLSearchParams(location.search)
	if (!urlParams.get('limit')) {
		urlParams.set('limit', '25')
	}

	useEffect(() => {
		axios({
			data: {},
			headers: {
				Authorization: `Bearer ${localStorage['token']}`,
			},
			method: 'GET',
			url: `${APIHostname}/api/v1/email_templates?${EscapeURIParameters(
				urlParams.toString()
			)}&view=browse`,
		})
			.then((response: any) => {
				setEmailTemplates(response.data.email_templates)
				setEmailTemplateCount(response.data.email_template_count)
				setLoaded(true)
			})
			.catch((error: any) => {
				switch (error?.response?.status) {
				case 401:
					LogOff('token-expired')
					return
				case 403:
					setErrorMessage(
						<>
								Error: You do not have permission to view email
								templates.
						</>
					)
					break
				default:
					if (error?.response?.data?.error) {
						setErrorMessage(
							error?.response?.data?.link ? (
								<Link to={error?.response?.data?.link}>
										Error:
									{error?.response?.data?.error}
								</Link>
							) : (
								<>{error?.response?.data?.error}</>
							)
						)
					} else {
						setErrorMessage(<>Error: Unknown error.</>)
							console.error(error) // eslint-disable-line
					}
				}
			})
	}, [location])

	if (!loaded) {
		if (errorMessage) {
			return (
				<DefaultLayout
					bottomTabItems={EmailTemplatesBottomTabItems}
					leftMenuItems={AdministrationLeftMenuItems}
					showPrint={true}
					tabMenuItems={EmailTemplatesTabMenuItems}
					topTabItems={GlobalTopTabItems}
				>
					<div className={styles.emailTemplatesContainer}>
						<div className={styles.redirectMessage}>
							{redirectMessage}
						</div>
						<div className={styles.errorMessage}>
							{errorMessage}
						</div>
					</div>
				</DefaultLayout>
			)
		}

		return <div className="loading">Loading...</div>
	}

	sessionStorage.removeItem('redirect-message')
	return (
		<DefaultLayout
			bottomTabItems={EmailTemplatesBottomTabItems}
			leftMenuItems={AdministrationLeftMenuItems}
			showPrint={true}
			tabMenuItems={EmailTemplatesTabMenuItems}
			topTabItems={GlobalTopTabItems}
		>
			<div className={styles.emailTemplatesContainer}>
				<div className={styles.redirectMessage}>{redirectMessage}</div>
				<div className={styles.errorMessage}>{errorMessage}</div>

				<div className={styles.searchForm}>
					<SearchForm
						data={{}}
						layout={EmailTemplateLayout}
						name="emailTemplates"
						readOnly={false}
					/>
				</div>

				<div className={styles.navTable}>
					<NavTable
						columns={[
							{
								id: 'name',
								title: 'Name',
							},
							{
								id: 'subject',
								title: 'Subject',
							},
							{
								control: Control.Email,
								id: 'sender',
								title: 'Sender',
							},
							{
								children: [
									{
										control: Control.DateTime,
										id: 'created',
										title: 'Creation Date',
									},
									{
										control: Control.User,
										id: 'created_by',
										title: 'Created By',
									},
								],
							},
							{
								children: [
									{
										control: Control.DateTime,
										id: 'modified',
										title: 'Modified Date',
									},
									{
										control: Control.User,
										id: 'modified_by',
										title: 'Modified By',
									},
								],
							},
						]}
						count={emailTemplateCount}
						data={emailTemplates}
						filters={JSON.parse(urlParams.get('filters') ?? '[]')}
						includeDelete={HasRole('administrator')}
						primaryDisplayColumn="name"
						primaryKey="id"
					/>
				</div>
			</div>
		</DefaultLayout>
	)
}

export const EmailTemplatesTabMenuItems = [
	{
		icon: NewIcon,
		requiredRole: 'administrator',
		text: 'NEW',
		to: '/email_templates/new',
	},
].reverse()

export const EmailTemplatesBottomTabItems = [
	{
		icon: DownArrowIcon,
		text: 'EMAIL TEMPLATES',
		to: '/email_templates',
	},
]
