import axios from 'axios'
import jwt_decode from 'jwt-decode'
import { FormEvent, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import DownArrowIcon from '../../assets/icons/arrow-down.svg'
import ForgotPasswordIcon from '../../assets/icons/forgot-password.svg'
//import HelpIcon from '../../assets/icons/help-small.svg'
import LogInSideIcon from '../../assets/icons/login-side.svg'
import CreateAccountIcon from '../../assets/icons/new-user.svg'
import PasswordShowIcon from '../../assets/icons/password-show.svg'
import { DefaultLayout } from '../../layouts/DefaultLayout'
import { APIHostname, Authenticated, LogOff } from '../../Profile'
import styles from './LogIn.module.scss'

export const LogIn = () => {
	const [email, setEmail] = useState('')
	let initialErrorMessage = <></>
	if (window.location.hash == '#token-expired') {
		initialErrorMessage = (
			<>Your session has expired. Please log in again.</>
		)
	}
	const [errorMessage, setErrorMessage] = useState(initialErrorMessage)
	const [password, setPassword] = useState('')
	const [passwordVisible, setPasswordVisible] = useState(false)
	const [rememberMe, setRememberMe] = useState(
		localStorage['remember-me'] == 'true'
	)
	const [leftColumn, setLeftColumn] = useState('')
	const [rightColumn, setRightColumn] = useState('')
	const [loaded, setLoaded] = useState(false)

	useEffect(() => {
		axios({
			data: {},
			method: 'GET',
			url: `${APIHostname}/api/v1/theme/homepage`,
		})
			.then((response: any) => {
				setErrorMessage(<></>)
				if (response.data.body.login.leftColumn) {
					setLeftColumn(response.data.body.login.leftColumn)
				}
				if (response.data.body.login.rightColumn) {
					setRightColumn(response.data.body.login.rightColumn)
				}
				setLoaded(true)
			})
			.catch((error: any) => {
				switch (error?.response?.status) {
				case 401:
					LogOff('token-expired')
					return
				case 403:
					setErrorMessage(
						<>
								Error: You do not have permission to view
								container types.
						</>
					)
					break
				default:
					if (error?.response?.data?.error) {
						setErrorMessage(
							error?.response?.data?.link ? (
								<Link to={error?.response?.data?.link}>
										Error:
									{error?.response?.data?.error}
								</Link>
							) : (
								<>{error?.response?.data?.error}</>
							)
						)
					} else {
						setErrorMessage(<>Error: Unknown error.</>)
							console.error(error) // eslint-disable-line
					}
				}
			})
	}, [location])

	if (!loaded) {
		return <></>
	}

	return (
		<DefaultLayout
			bottomTabItems={LogInBottomTabItems}
			leftMenuItems={LogInLeftMenuItems}
			showPrint={false}
			tabMenuItems={LogInTabMenuItems}
			topTabItems={LogInTopTabItems}
		>
			<div className={styles.logInContainer}>
				<div className={styles.leftColumn}>
					<div className={styles.leftColumnInner}>
						<div className={styles.logInForm}>
							<form
								name="login-form"
								onSubmit={(e: FormEvent<HTMLFormElement>) => {
									axios({
										data: {
											email: email,
											password: password,
											rememberMe: rememberMe,
										},
										headers: {
											'Content-Type': 'application/json',
										},
										method: 'POST',
										url: `${APIHostname}/api/v1/authenticate`,
									})
										.then((response: any) => {
											setErrorMessage(<></>)

											const token: any = jwt_decode(
												response.data.token
											)
											if (!token.interactive) {
												setErrorMessage(
													<>
														Error: Token does not
														support interactive use
													</>
												)
												return
											}

											localStorage['logged-in'] = 'true'
											localStorage['token'] =
												response.data.token
											Authenticated(true)
											window.history.replaceState(
												{},
												'',
												'/'
											)
											document.location.reload()
										})
										.catch((error: any) => {
											switch (error?.response?.status) {
											case 401:
												if (
													error?.response?.data
														?.error
												) {
													setErrorMessage(
														<>
																Error:
															{
																error
																	?.response
																	?.data
																	?.error
															}
														</>
													)
												} else {
													setErrorMessage(
														<>
																Error: Incorrect
																email or
																password.
														</>
													)
												}
												break
											default:
													console.error(error) // eslint-disable-line
												setErrorMessage(
													<>
															Error: Unknown
															error.
													</>
												)
												break
											}
										})
									e.preventDefault()
								}}
							>
								<div className={styles.errorMessage}>
									{errorMessage}
								</div>
								<div>EMAIL:</div>
								<div className={styles.row}>
									<input
										id="email"
										onChange={(
											e: React.ChangeEvent<HTMLInputElement>
										) => {
											setEmail(e.target.value)
										}}
										title="EMAIL"
										type="text"
										value={email}
									/>
									{/*								<img
									alt="EMAIL ADDRESS"
									onClick={() => {
										document
											.getElementById('username')
											?.focus()
									}}
									src={HelpIcon}
									title="EMAIL ADDRESS"
								/>*/}
								</div>
								<div>PASSWORD:</div>
								<div className={styles.row}>
									<input
										id="password"
										onChange={(
											e: React.ChangeEvent<HTMLInputElement>
										) => {
											setPassword(e.target.value)
										}}
										title="PASSWORD"
										type={
											passwordVisible
												? 'text'
												: 'password'
										}
										value={password}
									/>
									<img
										alt="SHOW / HIDE PASSWORD"
										onClick={() => {
											setPasswordVisible(!passwordVisible)
										}}
										src={PasswordShowIcon}
										title="SHOW / HIDE PASSWORD"
									/>
									<input
										className={styles.submit}
										type="submit"
										value="LOGIN"
									/>
								</div>
								<div className={styles.rememberMeContainer}>
									<input
										checked={rememberMe}
										id="rememberMe"
										onChange={(
											e: React.ChangeEvent<HTMLInputElement>
										) => {
											setRememberMe(e.target.checked)
											localStorage.setItem(
												'remember-me',
												e.target.checked.toString()
											)
										}}
										title="REMEMBER ME"
										type="checkbox"
									/>
									<label htmlFor="rememberMe">
										{' '}
										REMEMBER ME
									</label>
								</div>
							</form>
						</div>
						<div
							dangerouslySetInnerHTML={{
								__html: leftColumn,
							}}
						/>
					</div>
				</div>
				<div className={styles.rightColumn}>
					<div
						className={styles.rightColumnInner}
						dangerouslySetInnerHTML={{
							__html: rightColumn,
						}}
					/>
				</div>
			</div>
		</DefaultLayout>
	)
}

export const LogInTopTabItems = []

export const LogInTabMenuItems = []

export const LogInBottomTabItems = [
	{
		icon: DownArrowIcon,
		text: 'PLEASE LOG IN',
		to: '/',
	},
]

export const LogInLeftMenuItems = [
	{
		icon: LogInSideIcon,
		text: 'LOGIN',
		to: '/',
	},
	{
		icon: ForgotPasswordIcon,
		text: 'FORGOT PASSWORD',
		to: '/forgot-password',
	},
	{
		icon: CreateAccountIcon,
		text: 'CREATE ACCOUNT',
		to: '/register',
	},
]
