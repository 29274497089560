import axios from 'axios'
import { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

import DownArrowIcon from '../../../assets/icons/arrow-down.svg'
import NewIcon from '../../../assets/icons/new.svg'
import OrganizationsIcon from '../../../assets/icons/organizations.svg'
import { SearchForm } from '../../../components/Form/SearchForm'
import { NavTable } from '../../../components/NavTable'
import { GlobalTopTabItems } from '../../../components/Tabs'
import { DefaultLayout } from '../../../layouts/DefaultLayout'
import { APIHostname, HasPermission, LogOff } from '../../../Profile'
import Control from '../../../types/Control'
import { OrganizationSearchLayout } from '../../../types/data/Organization'
import { EscapeURIParameters } from '../../../Utils'
import styles from './Organizations.module.scss'

export const Organizations = () => {
	const [organizations, setOrganizations]: any = useState(null)
	const [organizationCount, setOrganizationCount] = useState(0)
	const [loaded, setLoaded]: any = useState(false)
	const [errorMessage, setErrorMessage] = useState(<></>)
	const initialRedirectMessage = sessionStorage['redirect-message']
	const [redirectMessage] = useState(initialRedirectMessage)

	// Vary based on query string.
	const location = useLocation()
	const urlParams = new URLSearchParams(location.search)
	if (!urlParams.get('limit')) {
		urlParams.set('limit', '25')
	}

	useEffect(() => {
		axios({
			data: {},
			headers: {
				Authorization: `Bearer ${localStorage['token']}`,
			},
			method: 'GET',
			url: `${APIHostname}/api/v1/organizations?${EscapeURIParameters(
				urlParams.toString()
			)}`,
		})
			.then((response: any) => {
				setErrorMessage(<></>)
				setOrganizations(response.data.organizations)
				setOrganizationCount(response.data.organization_count)
				setLoaded(true)
			})
			.catch((error: any) => {
				switch (error?.response?.status) {
				case 401:
					LogOff('token-expired')
					return
				case 403:
					setErrorMessage(
						<>
								Error: You do not have permission to view
								organizations.
						</>
					)
					break
				default:
					if (error?.response?.data?.error) {
						setErrorMessage(
							error?.response?.data?.link ? (
								<Link to={error?.response?.data?.link}>
										Error:
									{error?.response?.data?.error}
								</Link>
							) : (
								<>{error?.response?.data?.error}</>
							)
						)
					} else {
						setErrorMessage(<>Error: Unknown error.</>)
							console.error(error) // eslint-disable-line
					}
				}
			})
	}, [location])

	if (!loaded) {
		if (errorMessage) {
			return (
				<DefaultLayout
					bottomTabItems={OrganizationsBottomTabItems}
					leftMenuItems={OrganizationsLeftMenuItems}
					showPrint={true}
					tabMenuItems={OrganizationsTabMenuItems}
					topTabItems={GlobalTopTabItems}
				>
					<div className={styles.organizationsContainer}>
						<div className={styles.redirectMessage}>
							{redirectMessage}
						</div>
						<div className={styles.errorMessage}>
							{errorMessage}
						</div>
					</div>
				</DefaultLayout>
			)
		}

		return <div className="loading">Loading...</div>
	}

	sessionStorage.removeItem('redirect-message')
	return (
		<DefaultLayout
			bottomTabItems={OrganizationsBottomTabItems}
			leftMenuItems={OrganizationsLeftMenuItems}
			showPrint={true}
			tabMenuItems={OrganizationsTabMenuItems}
			topTabItems={GlobalTopTabItems}
		>
			<div className={styles.organizationsContainer}>
				<div className={styles.redirectMessage}>{redirectMessage}</div>
				<div className={styles.errorMessage}>{errorMessage}</div>

				<div className={styles.searchForm}>
					<SearchForm
						data={{}}
						layout={OrganizationSearchLayout}
						name="organizations"
						readOnly={false}
					/>
				</div>

				<div className={styles.navTable}>
					<NavTable
						columns={[
							{
								children: [
									{
										control: Control.ImageFile,
										id: 'image',
										title: '',
									},
								],
							},
							{
								children: [
									{
										id: 'code',
										title: 'Account Code',
									},
									{
										id: 'name',
										title: 'Account Name',
									},
								],
							},
							{
								id: 'type',
								title: 'Type',
							},
							{
								children: [
									{
										id: 'city',
										title: 'City',
									},
									{
										id: 'state',
										title: 'State',
									},
									{
										control: Control.Country,
										id: 'country',
										title: 'Country',
									},
								],
							},
							{
								children: [
									{
										control: Control.Partner,
										id: 'account_owner',
										title: 'Account Owner',
									},
									{
										id: 'main_contact_name',
										title: 'Main Contact',
									},
								],
							},
							{
								children: [
									{
										control: Control.Email,
										id: 'main_contact_email',
										title: 'Email',
									},
									{
										control: Control.Phone,
										id: 'main_contact_phone',
										title: 'Phone',
									},
								],
							},
							{
								id: 'notes',
								title: 'Notes',
							},
							{
								children: [
									{
										control: Control.DateTime,
										id: 'created',
										title: 'Creation Date',
									},
									{
										control: Control.User,
										id: 'created_by',
										title: 'Created By',
									},
								],
							},
							{
								children: [
									{
										control: Control.DateTime,
										id: 'modified',
										title: 'Modified Date',
									},
									{
										control: Control.User,
										id: 'modified_by',
										title: 'Modified By',
									},
								],
							},
						]}
						count={organizationCount}
						data={organizations}
						filters={JSON.parse(urlParams.get('filters') ?? '[]')}
						includeDelete={HasPermission('organization', 'delete')}
						primaryDisplayColumn="code"
						primaryKey="id"
					/>
				</div>
			</div>
		</DefaultLayout>
	)
}

export const OrganizationsTabMenuItems = [
	{
		icon: NewIcon,
		requiredPermissions: ['organization.create'],
		text: 'NEW',
		to: '/organizations/new',
	},
].reverse()

export const OrganizationsBottomTabItems = [
	{
		icon: DownArrowIcon,
		text: 'ORGANIZATIONS',
		to: '/organizations',
	},
]

export const OrganizationsLeftMenuItems = [
	{
		icon: OrganizationsIcon,
		requiredPermissions: ['organization.read'],
		text: 'ORGANIZATIONS',
		to: '/organizations',
	},
]
