import axios from 'axios'
import { FormEvent, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

import DownArrowIcon from '../../../../assets/icons/arrow-down.svg'
import { DefaultLayout } from '../../../../layouts/DefaultLayout'
import { APIHostname } from '../../../../Profile'
import { LogInLeftMenuItems, LogInTopTabItems } from '../..'
import styles from './ChangePassword.module.scss'

export const ChangePassword = () => {
	const location = useLocation()
	const urlParams = new URLSearchParams(location.search)
	const [email] = useState(urlParams.get('email') ?? '')
	const [password, setPassword] = useState('')
	const [confirmPassword, setConfirmPassword] = useState('')
	const [formDisabled, setFormDisabled] = useState(false)
	const [formVisible, setFormVisible] = useState(false)
	const [errorMessage, setErrorMessage] = useState<
		JSX.Element | string | null
	>(null)
	const [successMessage, setSuccessMessage] = useState<
		JSX.Element | string | null
	>(null)
	const nonce = urlParams.get('nonce')

	useEffect(() => {
		axios({
			method: 'GET',
			params: {
				email: email,
				nonce: nonce,
			},
			url: `${APIHostname}/api/v1/forgotpassword`,
		})
			.then(() => {
				setSuccessMessage(null)
				setErrorMessage(null)
				setFormVisible(true)
			})
			.catch((error: any) => {
				console.error(error) // eslint-disable-line
				setErrorMessage(
					<>
						Invalid link. Please use the{' '}
						<Link to="/forgot-password">Forgot Password</Link> page.
					</>
				)
				setFormVisible(false)
			})
	}, [])

	if (!email || !nonce) {
		setErrorMessage(
			<>
				Invalid link. Please use the{' '}
				<Link to="/forgot-password">Forgot Password</Link> page.
			</>
		)
		setFormVisible(false)
	}

	return (
		<DefaultLayout
			bottomTabItems={ForgotPasswordBottomTabItems}
			leftMenuItems={LogInLeftMenuItems}
			showPrint={false}
			tabMenuItems={ForgotPasswordTabMenuItems}
			topTabItems={LogInTopTabItems}
		>
			<div className={styles.changePasswordOuterContainer}>
				<div className={styles.changePasswordInnerContainer}>
					<div className={styles.successMessage}>
						{successMessage}
					</div>
					<div className={styles.errorMessage}>{errorMessage}</div>
					<form
						className={styles.changePasswordForm}
						name="partners-form"
						onSubmit={(e: FormEvent<HTMLFormElement>) => {
							setFormDisabled(true)
							axios({
								data: {
									email: email,
									nonce: nonce,
									password: password,
								},
								headers: {
									'Content-Type': 'application/json',
								},
								method: 'POST',
								url: `${APIHostname}/api/v1/forgotpassword`,
							})
								.then(() => {
									setSuccessMessage(
										<>
											Your password has been updated.{' '}
											<Link to="/">
												Please click here to log in.
											</Link>
										</>
									)
									setErrorMessage(<></>)
									setFormVisible(false)
								})
								.catch((error: any) => {
									console.error(error) // eslint-disable-line
									setFormDisabled(false)
									setSuccessMessage(null)
									setErrorMessage(<>Error: Unknown error.</>)
								})
							e.preventDefault()
						}}
						style={!formVisible ? { display: 'none' } : {}}
					>
						<div>EMAIL:</div>
						<div className={styles.email}>{email}</div>
						<div>NEW PASSWORD:</div>
						<input
							className={'hardRequired'}
							id="password"
							onChange={(
								e: React.ChangeEvent<HTMLInputElement>
							) => {
								setPassword(e.target.value)
							}}
							pattern={
								'^(?=.*[a-z])(?=.*[A-Z])(?=.*d)(?=.*[`~!@#$%^&*()-_=+[]{}\\|;:\'",<.>/?])[A-Za-zd`~!@#$%^&*()-_=+[]{}\\|;:\'",<.>/?]{8,32}$'
							}
							required={true}
							title="PASSWORD"
							type="password"
							value={password}
						/>
						<div>CONFIRM PASSWORD:</div>
						<input
							className={'hardRequired'}
							id="confirm_password"
							onChange={(
								e: React.ChangeEvent<HTMLInputElement>
							) => {
								setConfirmPassword(e.target.value)
								;(
									e.target as HTMLInputElement
								).setCustomValidity(
									(
										document.getElementById(
											'password'
										) as HTMLInputElement
									).value !=
										(
											document.getElementById(
												'confirm_password'
											) as HTMLInputElement
										).value
										? 'Passwords must match.'
										: ''
								)
							}}
							pattern={
								'^(?=.*[a-z])(?=.*[A-Z])(?=.*d)(?=.*[`~!@#$%^&*()-_=+[]{}\\|;:\'",<.>/?])[A-Za-zd`~!@#$%^&*()-_=+[]{}\\|;:\'",<.>/?]{8,32}$'
							}
							required={true}
							title="CONFIRM PASSWORD"
							type="password"
							value={confirmPassword}
						/>
						<input
							disabled={formDisabled}
							type="submit"
							value="Submit"
						/>
					</form>
				</div>
			</div>
		</DefaultLayout>
	)
}

export const ForgotPasswordBottomTabItems = [
	{
		icon: DownArrowIcon,
		text: 'FORGOT PASSWORD',
		to: '/forgot-password',
	},
]

export const ForgotPasswordTabMenuItems = []
