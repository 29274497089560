import axios from 'axios'
import { useState } from 'react'
import { Link } from 'react-router-dom'

import ArrivalNoticeIcon from '../../../../assets/icons/arrival-notice.svg'
import DownArrowIcon from '../../../../assets/icons/arrow-down.svg'
import NewIcon from '../../../../assets/icons/new.svg'
import { EditForm, ParsedFormData } from '../../../../components/Form/EditForm'
import { GlobalTopTabItems } from '../../../../components/Tabs'
import { DefaultLayout } from '../../../../layouts/DefaultLayout'
import {
	APIHostname,
	HasPermission,
	LogOff,
	ProfilePartnerID,
	ProfilePartnerName,
} from '../../../../Profile'
import { StandaloneArrivalNoticeLayout } from '../../../../types/data/ArrivalNoticeOverride'
import styles from './ArrivalNoticesNew.module.scss'

export const ArrivalNoticesNew = () => {
	const [errorMessage, setErrorMessage] = useState(<></>)
	const initialRedirectMessage = sessionStorage['redirect-message']
	const [redirectMessage] = useState(initialRedirectMessage)

	sessionStorage.removeItem('redirect-message')
	return (
		<DefaultLayout
			bottomTabItems={NewArrivalNoticeBottomTabItems}
			leftMenuItems={NewArrivalNoticeLeftMenuItems}
			showPrint={true}
			tabMenuItems={NewArrivalNoticeTabMenuItems}
			topTabItems={GlobalTopTabItems}
		>
			<div className={styles.arrivalNoticesContainer}>
				<div className={styles.redirectMessage}>{redirectMessage}</div>
				<div className={styles.errorMessage}>{errorMessage}</div>

				<EditForm
					data={{
						destination_partner: ProfilePartnerID(),
						destination_partner_image_preview: `${APIHostname}/api/v1/partners/${ProfilePartnerID()}/image`,
						destination_partner_name: ProfilePartnerName(),
						override_date: new Date().toISOString().split('T')[0],
					}}
					layout={StandaloneArrivalNoticeLayout}
					name="arrivalNotice"
					onSubmit={async (formData: FormData) => {
						// Create arrival notice
						axios({
							data: await ParsedFormData(formData, ['charges']),
							headers: {
								Authorization: `Bearer ${localStorage['token']}`,
								'Content-Type': 'application/json',
							},
							method: 'POST',
							url: `${APIHostname}/api/v1/arrival_notices`,
						})
							.then((response: any) => {
								sessionStorage[
									'redirect-message'
								] = `Arrival Notice #${response.data.arrival_notice_id} created.`
								window.history.replaceState(
									{},
									'',
									`/arrival_notices/${response.data.arrival_notice_id}`
								)
								document.location.reload()
							})
							.catch((error: any) => {
								switch (error?.response?.status) {
								case 401:
									LogOff('token-expired')
									return
								case 403:
									setErrorMessage(
										<>
												Error: You do not have
												permission to edit arrival
												notices.
										</>
									)
									break
								default:
									if (error?.response?.data?.error) {
										setErrorMessage(
											error?.response?.data?.link ? (
												<Link
													to={
														error?.response
															?.data?.link
													}
												>
														Error:
													{
														error?.response
															?.data?.error
													}
												</Link>
											) : (
												<>
													{
														error?.response
															?.data?.error
													}
												</>
											)
										)
									} else {
										setErrorMessage(
											<>Error: Unknown error.</>
										)
											console.error(error) // eslint-disable-line
									}
								}
							})
					}}
					previewAPIData={async (formData: FormData) => {
						const parsedFormData = await ParsedFormData(formData, [
							'charges',
						])

						return {
							data: JSON.stringify(parsedFormData, null, 4),
							method: 'POST',
							url: `${APIHostname}/api/v1/arrival_notices`,
						}
					}}
					readOnly={!HasPermission('arrival_notice', 'create')}
				/>
			</div>
		</DefaultLayout>
	)
}

export const NewArrivalNoticeTabMenuItems = [
	{
		icon: NewIcon,
		requiredPermissions: ['arrival_notice.create'],
		text: 'NEW',
		to: '/arrival_notices/new',
	},
].reverse()

export const NewArrivalNoticeBottomTabItems = [
	{
		icon: DownArrowIcon,
		text: 'NEW ARRIVAL NOTICE',
		to: '/arrival_notices/new',
	},
]

export const NewArrivalNoticeLeftMenuItems = [
	{
		icon: ArrivalNoticeIcon,
		text: 'ARRIVAL NOTICES',
		to: '/arrival_notices',
	},
]
