import axios from 'axios'
import { useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'

import ACIIcon from '../../../../../assets/icons/aci.svg'
import AMSIcon from '../../../../../assets/icons/ams.svg'
import ArrivalIcon from '../../../../../assets/icons/arrival-notice.svg'
import DownArrowIcon from '../../../../../assets/icons/arrow-down.svg'
import BookingIcon from '../../../../../assets/icons/booking.svg'
import DocumentsIcon from '../../../../../assets/icons/documents.svg'
import ShipmentHistoryIcon from '../../../../../assets/icons/history.svg'
import ISFIcon from '../../../../../assets/icons/isf.svg'
import NRAIcon from '../../../../../assets/icons/open-nra.svg'
import RemarksIcon from '../../../../../assets/icons/remarks.svg'
import ShipmentTrackingIcon from '../../../../../assets/icons/tracking.svg'
import {
	EditForm,
	ParsedFormData,
} from '../../../../../components/Form/EditForm'
import { Modal } from '../../../../../components/Modal'
import { NavTable } from '../../../../../components/NavTable'
import { ShipmentSummary } from '../../../../../components/ShipmentSummary'
import { GlobalTopTabItems } from '../../../../../components/Tabs'
import { DefaultLayout } from '../../../../../layouts/DefaultLayout'
import {
	APIHostname,
	HasPermission,
	HasRole,
	LogOff,
} from '../../../../../Profile'
import Control from '../../../../../types/Control'
import { OrganizationAccessDocumentLayout } from '../../../../../types/data/OrganizationAccessDocument'
import { EscapeURIParameters } from '../../../../../Utils'
import styles from './ShipmentDocuments.module.scss'

export const ShipmentDocuments = () => {
	const { id } = useParams()

	const [documents, setDocuments]: any = useState(null)
	const [shipment, setShipment]: any = useState(null)
	const [shipmentLoaded, setShipmentLoaded] = useState(false)
	const [documentsLoaded, setDocumentsLoaded] = useState(false)
	const [documentCount, setDocumentCount] = useState(0)
	const [errorMessage, setErrorMessage] = useState(<></>)
	const initialRedirectMessage = sessionStorage['redirect-message']
	const [redirectMessage] = useState(initialRedirectMessage)
	const [showModal, setShowModal] = useState(false)

	// Vary based on query string.
	const location = useLocation()
	const urlParams = new URLSearchParams(location.search)
	if (!urlParams.get('limit')) {
		urlParams.set('limit', '25')
	}

	useEffect(() => {
		axios({
			data: {},
			headers: {
				Authorization: `Bearer ${localStorage['token']}`,
			},
			method: 'GET',
			url: `${APIHostname}/api/v1/shipments/${id}`,
		})
			.then((shipmentResponse: any) => {
				setErrorMessage(<></>)
				setShipment(shipmentResponse.data)
				setShipmentLoaded(true)
			})
			.catch((error: any) => {
				switch (error?.response?.status) {
				case 401:
					LogOff('token-expired')
					return
				case 403:
					setErrorMessage(
						<>
								Error: You do not have permission to edit
								shipment documents.
						</>
					)
					break
				default:
					if (error?.response?.data?.error) {
						setErrorMessage(
							error?.response?.data?.link ? (
								<Link to={error?.response?.data?.link}>
										Error:
									{error?.response?.data?.error}
								</Link>
							) : (
								<>{error?.response?.data?.error}</>
							)
						)
					} else {
						setErrorMessage(<>Error: Unknown error.</>)
							console.error(error) // eslint-disable-line
					}
				}
			})

		axios({
			data: {},
			headers: {
				Authorization: `Bearer ${localStorage['token']}`,
			},
			method: 'GET',
			url: `${APIHostname}/api/v1/shipments/${id}/documents?${EscapeURIParameters(
				urlParams.toString()
			)}&view=browse`,
		})
			.then((response: any) => {
				setErrorMessage(<></>)
				setDocuments(response.data.documents)
				setDocumentCount(response.data.document_count)
				setDocumentsLoaded(true)
			})
			.catch((error: any) => {
				if (error?.response?.status == 401) {
					LogOff('token-expired')
					return
				}
				console.error(error) // eslint-disable-line
				setErrorMessage(<>Error: Unknown error.</>)
			})
	}, [location])

	const arrivalNoticeTemplate = shipment?.arrival_notice_template
	const usDestination =
		shipment?.discharge_port?.toLowerCase().startsWith('us') ||
		shipment?.final_port_of_entry?.toLowerCase().startsWith('us')
	const caDestination =
		shipment?.discharge_port?.toLowerCase().startsWith('ca') ||
		shipment?.final_port_of_entry?.toLowerCase().startsWith('ca')
	const ShipmentLeftMenuItems = [
		{
			icon: BookingIcon,
			text: 'BOOKING',
			to: `/shipments/${id}`,
		},
		{
			count: shipment?.unread_remarks ?? null,
			icon: RemarksIcon,
			text: 'REMARKS',
			to: `/shipments/${id}/remarks`,
		},
		{
			disabled: !usDestination && !shipment?.ams_status,
			disabledReason: 'Shipment does not have US destination ports.',
			icon: AMSIcon,
			requiredRole: 'administrator||memberpartner||partner',
			text: 'AMS',
			to: `/shipments/${id}/ams`,
		},
		{
			disabled: !caDestination && !shipment?.aci_status,
			disabledReason: 'Shipment does not have CA destination ports.',
			icon: ACIIcon,
			requiredRole: 'administrator||memberpartner||partner',
			text: 'ACI',
			to: `/shipments/${id}/aci`,
		},
		{
			disabled: !usDestination,
			disabledReason: 'Shipment does not have US destination ports.',
			icon: ISFIcon,
			requiredRole: 'administrator||memberpartner||partner',
			text: 'ISF',
			to: `/shipments/${id}/isf`,
		},
		{
			icon: DocumentsIcon,
			text: 'DOCUMENTS',
			to: `/shipments/${id}/documents`,
		},
		{
			icon: DocumentsIcon,
			requiredRole: 'administrator||memberpartner||partner',
			text: 'HBL PRINT',
			to: `/shipments/${id}/hbl`,
		},
		{
			disabled: !usDestination,
			disabledReason: 'Shipment does not have US destination ports.',
			icon: NRAIcon,
			requiredRole: 'administrator||memberpartner||partner',
			text: 'NRA',
			to: `/shipments/${id}/nra`,
		},
		{
			disabled: !arrivalNoticeTemplate,
			disabledReason:
				'Destination partner does not have an Arrival Notice template.',
			icon: ArrivalIcon,
			requiredRole: 'administrator||memberpartner||partner',
			text: 'ARRIVAL NOTICE',
			to: `/shipments/${id}/arrival_notice${
				arrivalNoticeTemplate == 'default'
					? ''
					: `/${arrivalNoticeTemplate}`
			}`,
		},
		{
			icon: ShipmentTrackingIcon,
			requiredRole: 'administrator||memberpartner||partner||organization',
			text: 'SHIPMENT TRACKING',
			to: `/shipments/${id}/tracking`,
		},
		{
			icon: ShipmentHistoryIcon,
			text: 'SHIPMENT HISTORY',
			to: `/shipments/${id}/history`,
		},
	]

	const ShipmentBottomTabItems = [
		{
			icon: DownArrowIcon,
			text: `SHIPMENT #${id} DOCUMENTS`,
			to: `/shipments/${id}/documents`,
		},
	]

	if (!shipmentLoaded || !documentsLoaded) {
		if (errorMessage) {
			return (
				<DefaultLayout
					bottomTabItems={ShipmentBottomTabItems}
					leftMenuItems={ShipmentLeftMenuItems}
					showPrint={true}
					tabMenuItems={ShipmentTabMenuItems}
					topTabItems={GlobalTopTabItems}
				>
					<div className={styles.shipmentDocumentsContainer}>
						<div className={styles.redirectMessage}>
							{redirectMessage}
						</div>
						<div className={styles.errorMessage}>
							{errorMessage}
						</div>
					</div>
				</DefaultLayout>
			)
		}

		return <div className="loading">Loading...</div>
	}

	const columns = [
		{
			control: Control.FileName,
			id: 'blob_filename',
			title: 'FILE NAME',
		},
		{
			id: 'description',
			title: 'Description',
		},
		{
			id: 'type',
			title: 'Type',
		},
		{
			control: Control.FileSize,
			id: 'size',
			title: 'Size',
		},
		{
			control: Control.DateTime,
			id: 'created',
			title: 'Uploaded Time',
		},
		{
			control: Control.User,
			id: 'created_by',
			title: 'Uploaded By',
		},
		{
			control: Control.Read,
			endpoint: 'documents',
			id: 'read',
			title: 'Read',
		},
		{
			control: Control.User,
			endpoint: 'documents',
			id: 'read_by',
			title: 'Read By',
		},
		{
			control: Control.ReadStatus,
			endpoint: 'documents',
			id: 'read_status',
			title: 'Read Status',
		},
		{
			control: Control.Download,
			id: 'download',
			title: 'Download',
		},
	]

	if (HasRole('administrator', 'partner', 'memberpartner')) {
		columns.push({
			control: Control.Boolean,
			id: 'organization_access',
			title: 'Organization Access',
		})
	}

	sessionStorage.removeItem('redirect-message')
	return (
		<DefaultLayout
			bottomTabItems={ShipmentBottomTabItems}
			leftMenuItems={ShipmentLeftMenuItems}
			showPrint={true}
			tabMenuItems={ShipmentTabMenuItems}
			topTabItems={GlobalTopTabItems}
		>
			{showModal ? (
				<Modal setShowModal={setShowModal}>
					<h2>UPLOAD A DOCUMENT</h2>
					<EditForm
						data={{}}
						layout={OrganizationAccessDocumentLayout}
						name="document-form"
						onSubmit={async (formData: FormData) => {
							const parsedFormData = await ParsedFormData(
								formData,
								[]
							)
							setShowModal(false)
							parsedFormData['shipment'] = parseInt(id ?? '0')

							// Create shipment document
							axios({
								data: parsedFormData,
								headers: {
									Authorization: `Bearer ${localStorage['token']}`,
									'Content-Type': 'application/json',
								},
								method: 'POST',
								url: `${APIHostname}/api/v1/shipments/${id}/documents`,
							})
								.then((response: any) => {
									setShowModal(false)
									sessionStorage[
										'redirect-message'
									] = `Document #${response.data.document_id} created.`
									document.location.reload()
								})
								.catch((error: any) => {
									switch (error?.response?.status) {
									case 401:
										LogOff('token-expired')
										return
									case 403:
										setErrorMessage(
											<>
													Error: You do not have
													permission to edit shipment
													documents.
											</>
										)
										break
									default:
										if (error?.response?.data?.error) {
											setErrorMessage(
												error?.response?.data
													?.link ? (
														<Link
															to={
																error?.response
																	?.data?.link
															}
														>
															Error:
															{
																error?.response
																	?.data
																	?.error
															}
														</Link>
													) : (
														<>
															{
																error?.response
																	?.data
																	?.error
															}
														</>
													)
											)
										} else {
											setErrorMessage(
												<>Error: Unknown error.</>
											)
												console.error(error) // eslint-disable-line
										}
									}
								})
						}}
						previewAPIData={async (formData: FormData) => {
							const parsedFormData = await ParsedFormData(
								formData,
								[]
							)
							parsedFormData['shipment'] = parseInt(id ?? '0')

							// Save ACI data
							parsedFormData['id'] = parseInt(id ?? '0')
							return {
								data: JSON.stringify(parsedFormData, null, 4),
								method: 'POST',
								url: `${APIHostname}/api/v1/shipments/${id}/documents`,
							}
						}}
						readOnly={!HasPermission('shipment', 'update')}
					></EditForm>
				</Modal>
			) : (
				<></>
			)}
			<div className={styles.shipmentDocumentsContainer}>
				<ShipmentSummary shipment={shipment}>
					{HasPermission('shipment', 'update') ? (
						<div className={styles.uploadButton}>
							<input
								className="pseudoSubmit"
								onClick={() => {
									setShowModal(true)
								}}
								type="button"
								value="UPLOAD FILE"
							/>
						</div>
					) : (
						<></>
					)}
				</ShipmentSummary>

				<div className={styles.redirectMessage}>{redirectMessage}</div>
				<div className={styles.errorMessage}>
					{errorMessage}
					{errorMessage}{' '}
					{!shipment?.active ? (
						<>This shipment has been deleted.</>
					) : (
						<></>
					)}
				</div>

				<div className={styles.navTable}>
					<NavTable
						columns={columns}
						count={documentCount}
						data={documents}
						filters={JSON.parse(urlParams.get('filters') ?? '[]')}
						includeDelete={HasPermission('shipment', 'delete')}
						includeExport={false}
						includeOrganizationAccess={HasPermission(
							'shipment',
							'update'
						)}
						primaryDisplayColumn=""
						primaryKey=""
					/>
				</div>
			</div>
		</DefaultLayout>
	)
}

export const ShipmentTabMenuItems = []
