import { useState } from 'react'

import { EscapeURIParameters } from '../../Utils'
import { Modal } from '../Modal'
import { EditFormProps } from './EditForm'

export type Button = {
	Label: string
	Type: 'api' | 'clear' | 'submit'
}

export const EditButton = (props: ButtonProps) => {
	const forms = document.getElementsByTagName('form')
	const [showModal, setShowModal] = useState(false)
	const [modalData, setModalData] = useState('')
	const [modalMethod, setModalMethod] = useState('')
	const [modalURL, setModalURL] = useState('')

	switch (props.button.Type) {
	case 'api':
		return (
			<>
				<input
					className="previewAPI"
					disabled={props.disabled}
					onClick={async (e: React.FormEvent) => {
						e.preventDefault()

						if (props.form) {
							const formData = new FormData(
								forms[forms.length - 1]
							)
							const apiData = await props.form.previewAPIData(
								formData
							)
							setModalData(apiData.data)
							setModalMethod(apiData.method)
							setModalURL(apiData.url)
							setShowModal(true)
						}
					}}
					type="submit"
					value={props.button.Label}
				/>
				{showModal ? (
					<Modal setShowModal={setShowModal}>
						<pre className="json-container">
							{modalMethod} {modalURL}
							{'\r\n'}
								Authorization: Bearer ...{'\r\n\r\n'}
							{modalData}
						</pre>
					</Modal>
				) : (
					<></>
				)}
			</>
		)
	case 'clear':
		return (
			<input
				disabled={props.disabled}
				onClick={(e: React.FormEvent) => {
					// eslint-disable-next-line
						if (!confirm('Really clear the form?')) {
						return <span></span>
					}
					e.preventDefault()
					const urlParams = new URLSearchParams(location.search)
					urlParams.delete('filters')
					const newLocation = `${location.origin}${
						location.pathname
					}${urlParams.size > 0 ? '?' : ''}${EscapeURIParameters(
						urlParams.toString()
					)}`
					if (location.href != newLocation) {
						location.replace(newLocation)
					}
					if (forms && forms.length > 0) {
						forms[forms.length - 1].reset()
					}
				}}
				type="button"
				value={props.button.Label}
			/>
		)

	case 'submit':
		return <input type="submit" value={props.button.Label} />
	}
}

export type ButtonProps = {
	button: Button
	form?: EditFormProps
	disabled?: boolean
}
