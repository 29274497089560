import { ReactNode } from 'react'

import { Footer } from '../../components/Footer'
import { LeftMenu, LeftMenuItem } from '../../components/LeftMenu'
import { TabItem, Tabs } from '../../components/Tabs'
import { PartnerTabs } from '../../components/Tabs/PartnerTabs'
import { ProfilePartnerID } from '../../Profile'
import styles from './DefaultLayout.module.scss'

export const DefaultLayout = (params: DefaultParams) => {
	if (ProfilePartnerID()) {
		return (
			<>
				<PartnerTabs
					bottomTabs={params.bottomTabItems}
					menuItems={params.tabMenuItems}
					showPrint={params.showPrint}
					topTabs={params.topTabItems}
				/>
				<div className={styles.defaultLayoutContainer}>
					<LeftMenu menuItems={params.leftMenuItems} />
					<main>{params.children}</main>
				</div>
				<Footer />
			</>
		)
	}

	return (
		<>
			<Tabs
				bottomTabs={params.bottomTabItems}
				menuItems={params.tabMenuItems}
				showPrint={params.showPrint}
				topTabs={params.topTabItems}
			/>
			<div className={styles.defaultLayoutContainer}>
				<LeftMenu menuItems={params.leftMenuItems} />
				<main>{params.children}</main>
			</div>
			<Footer />
		</>
	)
}

export type DefaultParams = {
	children: ReactNode
	bottomTabItems: TabItem[]
	leftMenuItems: LeftMenuItem[]
	onClick?: () => void
	showPrint: boolean
	tabMenuItems: TabItem[]
	topTabItems: TabItem[]
}
