import axios from 'axios'
import { FormEvent, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'

import DownArrowIcon from '../../../../../assets/icons/arrow-down.svg'
import InboxIcon from '../../../../../assets/icons/inbox.svg'
import UserIcon from '../../../../../assets/icons/new-user.svg'
import UserHistoryIcon from '../../../../../assets/icons/organization-history.svg'
import { GlobalTopTabItems } from '../../../../../components/Tabs'
import { UserSummary } from '../../../../../components/UserSummary'
import { DefaultLayout } from '../../../../../layouts/DefaultLayout'
import { APIHostname, LogOff } from '../../../../../Profile'
import styles from './Notifications.module.scss'

export const Notifications = () => {
	const { id } = useParams()

	const [errorMessage, setErrorMessage] = useState(<></>)
	const initialRedirectMessage = sessionStorage['redirect-message']
	const [notifications, setNotifications]: any = useState(null)
	const [user, setUser]: any = useState(null)
	const [userLoaded, setUserLoaded] = useState(false)
	const [notificationsLoaded, setNotificationsLoaded] = useState(false)
	const [redirectMessage] = useState(initialRedirectMessage)

	const notificationTypes = [
		/*{
			key: 'ams-submitted',
			label: 'AMS submitted to GOAL Portal (Origin Partner subscription)',
		},
		{
			key: 'ams-sent-to-customs',
			label: 'AMS Sent to Customs (Destination Partner subscription',
		},
		{ key: 'daily-ams-pre-alert', label: 'Daily AMS Pre-Alert Report' },
		{ key: 'document-uploaded', label: 'Document Uploaded' },
		{
			key: 'isf-submitted',
			label: 'ISF Submitted to GOAL Portal (Destination Partner subscription',
		},
		{ key: 'actual-departure', label: 'Actual Departure' },
		{ key: 'actual-arrival', label: 'Actual Arrival' },
		{
			key: 'booking-submitted',
			label: 'Booking Submit (Destination Partner subscription)',
		},
		{
			key: 'booking-reply',
			label: 'Booking Reply (Loading Partner subscription)',
		},
		{ key: 'ams-status-notification', label: 'AMS Status Notification' },
		{ key: 'daily-booking-report', label: 'Daily Booking Report' },
		{
			key: 'booking-revised',
			label: 'Booking Revised (Destination Partner subscription)',
		},
		{
			key: 'upload',
			label: 'Upload to GOAL Portal Only (Destination Partner subscription',
		},
		{
			key: 'daily-revised-booking-report',
			label: 'Daily Revised Booking Report',
		},
		{ key: 'daily-airbooking-report', label: 'Daily AirBooking Report' },
		{ key: 's6-processed', label: 'S6 Processed by U.S. Customs' },
		{ key: 'late-ams', label: 'Late File AMS Report' },
		{
			key: 'aci-sent-to-customs',
			label: 'ACI Sent to Customs (Destination Partner Subscription)',
		},
		{ key: 'fail-ams-submit', label: 'Fail AMS Submit Status' },
		{ key: 'daily-pending-isf-report', label: 'Daily Pending ISF Report' },
		{
			key: 'ams-all-uploaded-daily',
			label: 'AMS - All Uploaded Documents Daily Report',
		},
		{ key: 'document-upload', label: 'Document Upload (Show Document)' },*/
		{
			key: 'confirmed_rejected_booking',
			label: 'Confirmed / Rejected Booking Notice',
		},
		{ key: 'new_booking', label: 'New Booking Notice' },
		{ key: 'new_user', label: 'New User' },
	].sort((a: NotificationType, b: NotificationType) => {
		if (a.label < b.label) {
			return -1
		}
		if (a.label > b.label) {
			return 1
		}
		return 0
	})

	useEffect(() => {
		axios({
			data: {},
			headers: {
				Authorization: `Bearer ${localStorage['token']}`,
			},
			method: 'GET',
			url: `${APIHostname}/api/v1/users/${id}`,
		})
			.then((response: any) => {
				setUser(response.data)
				setUserLoaded(true)
			})
			.catch((error: any) => {
				switch (error?.response?.status) {
				case 401:
					LogOff('token-expired')
					return
				case 403:
					setErrorMessage(
						<>
								Error: You do not have permission to view users.
						</>
					)
					break
				default:
					if (error?.response?.data?.error) {
						setErrorMessage(
							error?.response?.data?.link ? (
								<Link to={error?.response?.data?.link}>
										Error:
									{error?.response?.data?.error}
								</Link>
							) : (
								<>{error?.response?.data?.error}</>
							)
						)
					} else {
						setErrorMessage(<>Error: Unknown error.</>)
							console.error(error) // eslint-disable-line
					}
				}
			})

		axios({
			data: {},
			headers: {
				Authorization: `Bearer ${localStorage['token']}`,
			},
			method: 'GET',
			url: `${APIHostname}/api/v1/users/${id}/notifications`,
		})
			.then((response: any) => {
				setErrorMessage(<></>)
				setNotifications(response.data)
				setNotificationsLoaded(true)
			})
			.catch((error: any) => {
				switch (error?.response?.status) {
				case 401:
					LogOff('token-expired')
					return
				case 403:
					setErrorMessage(
						<>
								Error: You do not have permission to view users.
						</>
					)
					break
				default:
					if (error?.response?.data?.error) {
						setErrorMessage(
							error?.response?.data?.link ? (
								<Link to={error?.response?.data?.link}>
										Error:
									{error?.response?.data?.error}
								</Link>
							) : (
								<>{error?.response?.data?.error}</>
							)
						)
					} else {
						setErrorMessage(<>Error: Unknown error.</>)
							console.error(error) // eslint-disable-line
					}
				}
			})
	}, [location])

	const NotificationsBottomTabItems = [
		{
			icon: DownArrowIcon,
			text: 'NOTIFICATIONS',
			to: `/users/${id}/notifications`,
		},
	]

	const NotificationsLeftMenuItems = [
		{
			icon: UserIcon,
			requiredPermissions: ['user.read'],
			text: 'USER INFO',
			to: `/users/${id}`,
		},
		{
			icon: UserIcon,
			requireDeveloperMode: true,
			requiredPermissions: ['user.generateapitoken'],
			text: 'USER API TOKENS',
			to: `/users/${id}/api`,
		},
		{
			icon: InboxIcon,
			requiredPermissions: ['user.read'],
			text: 'USER NOTIFICATIONS',
			to: `/users/${id}/notifications`,
		},
		{
			icon: UserHistoryIcon,
			requiredPermissions: ['user.read'],
			text: 'USER HISTORY',
			to: `/users/${id}/history`,
		},
	]

	if (!userLoaded || !notificationsLoaded) {
		if (errorMessage) {
			return (
				<DefaultLayout
					bottomTabItems={NotificationsBottomTabItems}
					leftMenuItems={NotificationsLeftMenuItems}
					showPrint={true}
					tabMenuItems={NotificationsTabMenuItems}
					topTabItems={GlobalTopTabItems}
				>
					<div className={styles.userContainer}>
						<div className={styles.redirectMessage}>
							{redirectMessage}
						</div>
						<div className={styles.errorMessage}>
							{errorMessage}
						</div>
					</div>
				</DefaultLayout>
			)
		}

		return <div className="loading">Loading...</div>
	}

	sessionStorage.removeItem('redirect-message')
	const checkedNotifications: { [key: string]: string } = {}
	if (notifications) {
		notifications.forEach((notification: any) => {
			if (notification.email && notification.email != '') {
				checkedNotifications[notification.notification] =
					notification.email
			} else {
				checkedNotifications[notification.notification] = user.email
			}
		})
	}
	return (
		<DefaultLayout
			bottomTabItems={NotificationsBottomTabItems}
			leftMenuItems={NotificationsLeftMenuItems}
			showPrint={true}
			tabMenuItems={NotificationsTabMenuItems}
			topTabItems={GlobalTopTabItems}
		>
			<div className={styles.notificationsContainer}>
				<UserSummary user={user} />

				<div className={styles.redirectMessage}>{redirectMessage}</div>
				<div className={styles.errorMessage}>
					{errorMessage}
					{!user?.active ? <>This user has been deleted.</> : <></>}
				</div>

				<div className={styles.notificationsLabel}>
					CHOOSE EMAIL NOTIFICATIONS TO RECEIVE:
				</div>
				<form
					name="notifications-form"
					onSubmit={(e: FormEvent<HTMLFormElement>) => {
						e.preventDefault()
						e.stopPropagation()

						const checked = document.querySelectorAll(
							'input[type=\'checkbox\']:checked'
						)
						const notifications: any[] = []
						checked.forEach((notificationElement: any) => {
							notifications.push({
								email:
									(
										document.getElementById(
											`${notificationElement.id}_email`
										) as HTMLInputElement
									)?.value ?? '',
								notification: notificationElement.id,
							})
						})

						axios({
							data: notifications,
							headers: {
								Authorization: `Bearer ${localStorage['token']}`,
							},
							method: 'POST',
							url: `${APIHostname}/api/v1/users/${id}/notifications`,
						})
							.then(() => {
								sessionStorage[
									'redirect-message'
								] = `User #${id} notifications updated.`
								window.history.replaceState(
									{},
									'',
									`/users/${id}/notifications`
								)
								document.location.reload()
							})
							.catch((error: any) => {
								switch (error?.response?.status) {
								case 401:
									LogOff('token-expired')
									return
								case 403:
									setErrorMessage(
										<>
												Error: You do not have
												permission to edit
												notifications.
										</>
									)
									break
								default:
									if (error?.response?.data?.error) {
										setErrorMessage(
											error?.response?.data?.link ? (
												<Link
													to={
														error?.response
															?.data?.link
													}
												>
														Error:
													{
														error?.response
															?.data?.error
													}
												</Link>
											) : (
												<>
													{
														error?.response
															?.data?.error
													}
												</>
											)
										)
									} else {
										setErrorMessage(
											<>Error: Unknown error.</>
										)
											console.error(error) // eslint-disable-line
									}
								}
							})
						e.preventDefault()
					}}
				>
					<table className={styles.notificationTypes}>
						<tbody>
							{notificationTypes.map((nt: NotificationType) => {
								return (
									<tr key={nt.key}>
										<td>
											<input
												defaultChecked={
													checkedNotifications[
														nt.key
													] != null &&
													checkedNotifications[
														nt.key
													] != ''
												}
												id={nt.key}
												name={nt.key}
												onChange={(e: any) => {
													const emailInput =
														document.getElementById(
															`${e.target.id}_email`
														)
													if (emailInput) {
														const emailInputHTML =
															emailInput as HTMLInputElement
														if (
															emailInputHTML.value ==
															''
														) {
															emailInputHTML.value =
																user.email
														}

														emailInputHTML.style.display =
															e.target.checked
																? ''
																: 'none'
													}
												}}
												type="checkbox"
												value={nt.label}
											/>
										</td>
										<td>
											<label htmlFor={nt.key}>
												{nt.label}
											</label>
										</td>
										<td>
											<input
												defaultValue={
													checkedNotifications[nt.key]
												}
												id={`${nt.key}_email`}
												name={`${nt.key}_email`}
												style={{
													display:
														checkedNotifications[
															nt.key
														]
															? ''
															: 'none',
												}}
												type="text"
											/>
										</td>
									</tr>
								)
							})}
						</tbody>
					</table>
					To send notifications to multiple addresses, separate them
					with a semicolon. Example:{' '}
					<em>address1@domain.com; address2@domain.com</em>
					<div className={styles.submitContainer}>
						<input type="submit" value="SUBMIT" />
					</div>
				</form>
			</div>
		</DefaultLayout>
	)
}

export const NotificationsTabMenuItems = []

type NotificationType = {
	key: string
	label: string
}
