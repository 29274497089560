import axios from 'axios'
import { useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'

import AgentAccessIcon from '../../../../../assets/icons/agent-access.svg'
import DownArrowIcon from '../../../../../assets/icons/arrow-down.svg'
import ChainIcon from '../../../../../assets/icons/chain.svg'
import DocumentsIcon from '../../../../../assets/icons/documents.svg'
import PayBalanceIcon from '../../../../../assets/icons/goal-pay-balance.svg'
import PayRequestMoneyIcon from '../../../../../assets/icons/goal-pay-request-money.svg'
import PaySendMoneyIcon from '../../../../../assets/icons/goal-pay-send-money.svg'
import PayTransactionsIcon from '../../../../../assets/icons/goal-pay-transactions.svg'
import PayIncomingRequestsIcon from '../../../../../assets/icons/goal-pay-transfer-money.svg'
import NewIcon from '../../../../../assets/icons/new.svg'
import PartnerHistoryIcon from '../../../../../assets/icons/organization-history.svg'
import PartnerInfoIcon from '../../../../../assets/icons/organization-info.svg'
import PartnerUsersIcon from '../../../../../assets/icons/organization-users.svg'
import { SearchForm } from '../../../../../components/Form/SearchForm'
import { LeftMenuItem } from '../../../../../components/LeftMenu'
import { NavTable } from '../../../../../components/NavTable'
import { PartnerSummary } from '../../../../../components/PartnerSummary'
import { GlobalTopTabItems } from '../../../../../components/Tabs'
import { DefaultLayout } from '../../../../../layouts/DefaultLayout'
import {
	APIHostname,
	HasPermission,
	HasRole,
	LogOff,
	ProfilePartnerID,
	ProfileRole,
} from '../../../../../Profile'
import Control from '../../../../../types/Control'
import { SearchUserLayout } from '../../../../../types/data/User'
import { EncodedStringify, Filter } from '../../../../../types/Filter'
import { EscapeURIParameters } from '../../../../../Utils'
import styles from './PartnerUsers.module.scss'

export const PartnerUsers = () => {
	const { id } = useParams()

	const [users, setUsers]: any = useState(null)
	const [partner, setPartner]: any = useState(null)
	const [partnerLoaded, setPartnerLoaded] = useState(false)
	const [userCount, setUserCount] = useState(0)
	const [usersLoaded, setUsersLoaded]: any = useState(false)
	const [payRequestCount, setPayRequestCount] = useState(0)
	const [countsLoaded, setCountsLoaded] = useState(false)
	const [errorMessage, setErrorMessage] = useState(<></>)
	const initialRedirectMessage = sessionStorage['redirect-message']
	const [redirectMessage] = useState(initialRedirectMessage)

	// Vary based on query string.
	const location = useLocation()
	const urlParams = new URLSearchParams(location.search)
	if (!urlParams.get('limit')) {
		urlParams.set('limit', '25')
	}

	useEffect(() => {
		const filters = JSON.parse(urlParams.get('filters') ?? '[]')
		let filterFound = false
		filters.forEach((filter: Filter, index: number) => {
			if (filter.field?.toLowerCase() == 'partner') {
				filters[index].operator = 'eq'
				filters[index].value = id?.toString()
				filterFound = true
			}
		})
		if (!filterFound) {
			filters.push({
				field: 'partner',
				operator: 'eq',
				value: id?.toString(),
			})
		}
		urlParams.set('filters', EncodedStringify(filters))

		axios({
			data: {},
			headers: {
				Authorization: `Bearer ${localStorage['token']}`,
			},
			method: 'GET',
			url: `${APIHostname}/api/v1/partners/${id}`,
		})
			.then((response: any) => {
				setErrorMessage(<></>)
				setPartnerLoaded(true)
				setPartner(response.data)

				axios({
					data: {},
					headers: {
						Authorization: `Bearer ${localStorage['token']}`,
					},
					method: 'GET',
					url: `${APIHostname}/api/v1/partners/${id}/pay_counts`,
				})
					.then((response: any) => {
						setErrorMessage(<></>)
						setPayRequestCount(response.data.pay_request_count)
						setCountsLoaded(true)
					})
					.catch((error: any) => {
						switch (error?.response?.status) {
						case 401:
							LogOff('token-expired')
							return
						case 403:
							setCountsLoaded(true)
							setErrorMessage(
								<>
										Error: You do not have permission to
										view GOAL pay requests.
								</>
							)
							break
						case 404:
							setCountsLoaded(true)
							break
						default:
							if (error?.response?.data?.error) {
								setErrorMessage(
									error?.response?.data?.link ? (
										<Link
											to={error?.response?.data?.link}
										>
												Error:
											{error?.response?.data?.error}
										</Link>
									) : (
										<>{error?.response?.data?.error}</>
									)
								)
							} else {
								setErrorMessage(<>Error: Unknown error.</>)
									console.error(error) // eslint-disable-line
							}
						}
					})
			})
			.catch((error: any) => {
				switch (error?.response?.status) {
				case 401:
					LogOff('token-expired')
					return
				case 403:
					setErrorMessage(
						<>
								Error: You do not have permission to view
								partners.
						</>
					)
					break
				default:
					if (error?.response?.data?.error) {
						setErrorMessage(
							error?.response?.data?.link ? (
								<Link to={error?.response?.data?.link}>
										Error:
									{error?.response?.data?.error}
								</Link>
							) : (
								<>{error?.response?.data?.error}</>
							)
						)
					} else {
						setErrorMessage(<>Error: Unknown error.</>)
							console.error(error) // eslint-disable-line
					}
				}
			})

		axios({
			data: {},
			headers: {
				Authorization: `Bearer ${localStorage['token']}`,
			},
			method: 'GET',
			url: `${APIHostname}/api/v1/users?${EscapeURIParameters(
				urlParams.toString()
			)}`,
		})
			.then((response: any) => {
				setErrorMessage(<></>)
				setUsers(response.data.users)
				setUserCount(response.data.user_count)
				setUsersLoaded(true)
			})
			.catch((error: any) => {
				switch (error?.response?.status) {
				case 401:
					LogOff('token-expired')
					return
				case 403:
					setErrorMessage(
						<>
								Error: You do not have permission to view users.
						</>
					)
					break
				default:
					if (error?.response?.data?.error) {
						setErrorMessage(
							error?.response?.data?.link ? (
								<Link to={error?.response?.data?.link}>
										Error:
									{error?.response?.data?.error}
								</Link>
							) : (
								<>{error?.response?.data?.error}</>
							)
						)
					} else {
						setErrorMessage(<>Error: Unknown error.</>)
							console.error(error) // eslint-disable-line
					}
				}
			})
	}, [location])

	const PartnerLeftMenuItems: LeftMenuItem[] = [
		{
			icon: PartnerInfoIcon,
			requiredPermissions: ['partner.read'],
			text: 'PARTNER INFO',
			to: `/partners/${id}`,
		},
		{
			icon: AgentAccessIcon,
			requiredPermissions: ['organization.manageaccess'],
			text: 'ORGANIZATION ACCESS',
			to: `/partners/${id}/organization_access`,
		},
	]

	if (!partner?.goal_member) {
		PartnerLeftMenuItems.push({
			icon: AgentAccessIcon,
			requiredPermissions: ['partner.manageaccess'],
			requiredRole: 'administrator',
			text: 'PARTNER ACCESS',
			to: `/partners/${id}/partner_access`,
		})
	}
	PartnerLeftMenuItems.push({
		icon: DocumentsIcon,
		requiredPermissions: ['partner.read'],
		text: 'PARTNER DOCUMENTS',
		to: `/partners/${id}/documents`,
	})
	PartnerLeftMenuItems.push({
		icon: ChainIcon,
		requiredPermissions: ['shipment.read'],
		text: 'LINKED SHIPMENTS',
		to: `/partners/${id}/shipments`,
	})
	PartnerLeftMenuItems.push({
		icon: PartnerUsersIcon,
		requiredPermissions: ['user.read'],
		text: 'PARTNER USERS',
		to: `/partners/${id}/users`,
	})
	PartnerLeftMenuItems.push({
		icon: PartnerHistoryIcon,
		requiredPermissions: ['partner.read'],
		text: 'PARTNER HISTORY',
		to: `/partners/${id}/history`,
	})

	if (HasRole('administrator')) {
		if (
			HasPermission('pay', 'viewbalance') ||
			HasPermission('pay', 'sendmoney') ||
			HasPermission('pay', 'requestmoney')
		) {
			PartnerLeftMenuItems.push(
				{
					disabled: true,
					requiredPermissions: [],
					text: 'ADMINISTRATOR OPTIONS',
					to: '',
				},
				{
					icon: PayBalanceIcon,
					requiredPermissions: ['pay.viewbalance'],
					text: 'BALANCE',
					to: `/partners/${id}/pay/balance`,
				},
				{
					icon: PaySendMoneyIcon,
					requiredPermissions: ['pay.sendmoney'],
					requiredRole: 'administrator',
					text: 'DEPOSIT MONEY',
					to: `/partners/${id}/pay/deposit`,
				},
				{
					icon: PayRequestMoneyIcon,
					requiredPermissions: ['pay.requestmoney'],
					requiredRole: 'administrator',
					text: 'WITHDRAW MONEY',
					to: `/partners/${id}/pay/withdraw`,
				},
				{
					icon: PayRequestMoneyIcon,
					requiredPermissions: ['pay.requestmoney'],
					text: 'REQUEST MONEY',
					to: `/partners/${id}/pay/request`,
				},
				{
					icon: PaySendMoneyIcon,
					requiredPermissions: ['pay.sendmoney'],
					text: 'SEND MONEY',
					to: `/partners/${id}/pay/send`,
				},
				{
					count: payRequestCount,
					icon: PayIncomingRequestsIcon,
					requiredPermissions: ['pay.sendmoney'],
					text: 'INCOMING PAYMENT REQUESTS',
					to: `/partners/${id}/pay/incoming_requests`,
				},
				{
					icon: PayTransactionsIcon,
					requiredPermissions: ['pay.viewbalance'],
					text: 'TRANSACTIONS',
					to: `/partners/${id}/pay/transactions`,
				}
			)
		}
	}

	const PartnerUsersBottomTabItems = [
		{
			icon: DownArrowIcon,
			text: `PARTNER #${id} USERS`,
			to: `/partners/${id}/users`,
		},
	]

	const PartnerUsersTabMenuItems = []

	if (
		(ProfileRole() == 'administrator' ||
			(ProfileRole() == 'memberpartner' &&
				id == ProfilePartnerID().toString())) &&
		partner?.active
	) {
		PartnerUsersTabMenuItems.push({
			icon: NewIcon,
			requiredPermissions: ['user.create'],
			text: 'NEW',
			to: `/partners/${id}/users/new`,
		})
	}

	if (!usersLoaded || !partnerLoaded || !countsLoaded) {
		if (errorMessage) {
			return (
				<DefaultLayout
					bottomTabItems={PartnerUsersBottomTabItems}
					leftMenuItems={PartnerLeftMenuItems}
					showPrint={true}
					tabMenuItems={PartnerUsersTabMenuItems}
					topTabItems={GlobalTopTabItems}
				>
					<div className={styles.organizationContainer}>
						<div className={styles.redirectMessage}>
							{redirectMessage}
						</div>
						<div className={styles.errorMessage}>
							{errorMessage}
						</div>
					</div>
				</DefaultLayout>
			)
		}

		return <div className="loading">Loading...</div>
	}

	sessionStorage.removeItem('redirect-message')
	return (
		<DefaultLayout
			bottomTabItems={PartnerUsersBottomTabItems}
			leftMenuItems={PartnerLeftMenuItems}
			showPrint={true}
			tabMenuItems={PartnerUsersTabMenuItems}
			topTabItems={GlobalTopTabItems}
		>
			<div className={styles.partnerUsersContainer}>
				<PartnerSummary partner={partner} />

				<div className={styles.redirectMessage}>{redirectMessage}</div>
				<div className={styles.errorMessage}>
					{errorMessage}{' '}
					{!partner?.active ? (
						<>This partner has been deleted.</>
					) : (
						<></>
					)}
				</div>

				<div className={styles.searchForm}>
					<SearchForm
						data={{}}
						layout={SearchUserLayout}
						name="users"
						readOnly={false}
					/>
				</div>

				<div className={styles.navTable}>
					<NavTable
						columns={[
							{
								children: [
									{
										control: Control.ImageFile,
										id: 'image',
										title: '',
									},
								],
							},
							{
								children: [
									{
										id: 'name',
										title: 'Account Name',
									},
									{
										id: 'title',
										title: 'Title',
									},
								],
							},
							{
								children: [
									{
										control: Control.Role,
										id: 'role',
										title: 'Role',
									},
									{
										control: Control.Partner,
										id: 'partner',
										title: 'Partner',
									},
								],
							},
							{
								children: [
									{
										id: 'city',
										title: 'City',
									},
									{
										id: 'state',
										title: 'State',
									},
									{
										control: Control.Country,
										id: 'country',
										title: 'Country',
									},
								],
							},
							{
								children: [
									{
										control: Control.Email,
										id: 'email',
										title: 'Email',
									},
									{
										control: Control.Phone,
										id: 'phone',
										title: 'Phone',
									},
								],
							},
							{
								id: 'notes',
								title: 'Notes',
							},
							{
								control: Control.Boolean,
								id: 'approved',
								title: 'Approved',
							},
							{
								children: [
									{
										control: Control.DateTime,
										id: 'created',
										title: 'Creation Date',
									},
									{
										control: Control.User,
										id: 'created_by',
										title: 'Created By',
									},
								],
							},
							{
								children: [
									{
										control: Control.DateTime,
										id: 'modified',
										title: 'Modified Date',
									},
									{
										control: Control.User,
										id: 'modified_by',
										title: 'Modified By',
									},
								],
							},
						]}
						count={userCount}
						data={users}
						filters={JSON.parse(urlParams.get('filters') ?? '[]')}
						includeDelete={HasPermission('user', 'delete')}
						linkPathRoot="/users"
						primaryDisplayColumn="name"
						primaryKey="id"
					/>
				</div>
			</div>
		</DefaultLayout>
	)
}
