import Control from '../Control'
import { Layout } from '../Layout'

export const EmailTemplateLayout: Layout = {
	Groups: [
		{
			Border: true,
			Direction: 'column',
			Margin: '1rem 0 0 0',
			OptionalRows: [
				{
					Fields: [
						{
							HardRequired: true,
							Label: 'NAME',
							Property: 'name',
							Width: 100,
						},
					],
				},
				{
					Fields: [
						{
							HardRequired: true,
							Label: 'SUBJECT',
							Property: 'subject',
							Width: 100,
						},
					],
				},
				{
					Fields: [
						{
							HardRequired: true,
							Label: 'SENDER',
							Property: 'sender',
							Width: 100,
						},
					],
				},
				{
					Fields: [
						{
							Control: Control.HTML,
							HardRequired: true,
							Label: 'BODY',
							Property: 'body',
							Width: 100,
						},
					],
				},
			],
			RequiredRows: [
				{
					Fields: [
						{
							IncludeInEdit: false,
							IncludeInSearch: true,
							Label: 'WILDCARD (SEARCH ALL FIELDS; USE "OR" FOR MULTIPLE CRITERIA)',
							Property: '*',
							Width: 100,
						},
					],
				},
			],
			Width: 100,
		},
	],
}
