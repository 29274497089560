import axios from 'axios'
import { useState } from 'react'
import { Link } from 'react-router-dom'

import DownArrowIcon from '../../../../assets/icons/arrow-down.svg'
import EmailTemplatesIcon from '../../../../assets/icons/email_templates.svg'
import { EditForm, ParsedFormData } from '../../../../components/Form/EditForm'
import { GlobalTopTabItems } from '../../../../components/Tabs'
import { DefaultLayout } from '../../../../layouts/DefaultLayout'
import { APIHostname, HasRole, LogOff } from '../../../../Profile'
import { EmailTemplateLayout } from '../../../../types/data/EmailTemplate'
import styles from './EmailTemplatesNew.module.scss'

export const EmailTemplatesNew = () => {
	const [errorMessage, setErrorMessage] = useState(<></>)
	const initialRedirectMessage = sessionStorage['redirect-message']
	const [redirectMessage] = useState(initialRedirectMessage)

	/* eslint-disable @typescript-eslint/ban-types */
	sessionStorage.removeItem('redirect-message')
	return (
		<DefaultLayout
			bottomTabItems={EmailTemplatesBottomTabItems}
			leftMenuItems={EmailTemplatesLeftMenuItems}
			showPrint={true}
			tabMenuItems={EmailTemplatesTabMenuItems}
			topTabItems={GlobalTopTabItems}
		>
			<div className={styles.emailTemplateContainer}>
				<div className={styles.redirectMessage}>{redirectMessage}</div>
				<div className={styles.errorMessage}>{errorMessage}</div>

				<EditForm
					data={{}}
					layout={EmailTemplateLayout}
					name="email_template"
					onSubmit={async (formData: FormData) => {
						// Create email template
						const parsedFormData = await ParsedFormData(
							formData,
							[]
						)
						axios({
							data: parsedFormData,
							headers: {
								Authorization: `Bearer ${localStorage['token']}`,
								'Content-Type': 'application/json',
							},
							method: 'POST',
							url: `${APIHostname}/api/v1/email_templates`,
						})
							.then((response: any) => {
								sessionStorage[
									'redirect-message'
								] = `Email template #${response.data.email_template_id} created.`
								window.history.replaceState(
									{},
									'',
									'/email_templates'
								)
								document.location.reload()
							})
							.catch((error: any) => {
								switch (error?.response?.status) {
								case 401:
									LogOff('token-expired')
									return
								case 403:
									setErrorMessage(
										<>
												Error: You do not have
												permission to edit email
												templates.
										</>
									)
									break
								default:
									if (error?.response?.data?.error) {
										setErrorMessage(
											error?.response?.data?.link ? (
												<Link
													to={
														error?.response
															?.data?.link
													}
												>
														Error:
													{
														error?.response
															?.data?.error
													}
												</Link>
											) : (
												<>
													{
														error?.response
															?.data?.error
													}
												</>
											)
										)
									} else {
										setErrorMessage(
											<>Error: Unknown error.</>
										)
											console.error(error) // eslint-disable-line
									}
								}
							})
					}}
					previewAPIData={async (formData: FormData) => {
						const payload = await ParsedFormData(formData, [])

						return {
							data: JSON.stringify(payload, null, 4),
							method: 'POST',
							url: `${APIHostname}/api/v1/email_templates`,
						}
					}}
					readOnly={!HasRole('administrator')}
				/>
			</div>
		</DefaultLayout>
	)
}

export const EmailTemplatesTabMenuItems = []

export const EmailTemplatesBottomTabItems = [
	{
		icon: DownArrowIcon,
		text: 'EMAIL TEMPLATES',
		to: '/email_templates',
	},
]

export const EmailTemplatesLeftMenuItems = [
	{
		icon: EmailTemplatesIcon,
		text: 'EMAIL TEMPLATES',
		to: '/email_templates',
	},
]
