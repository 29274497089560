import Control from '../Control'
import { Layout } from '../Layout'

export const ISFLayout: Layout = {
	Groups: [
		{
			Border: true,
			RequiredRows: [
				{
					Fields: [
						{
							Control: Control.ISFTransmissionType,
							HardRequired: true,
							Label: 'ISF TRANSMISSION TYPE',
							Property: 'isf_transmission_type',
							Width: 33,
						},
						{
							Control: Control.ShipmentType,
							HardRequired: true,
							Label: 'SHIPMENT TYPE',
							Property: 'shipment_type',
							Width: 33,
						},
						{
							Control: Control.AmendmentType,
							HardRequired: true,
							Label: 'ACT REASON',
							Property: 'act_reason',
							Width: 33,
						},
					],
				},
			],
			Width: 100,
		},
		{
			Direction: 'row',
			Groups: [
				{
					Border: true,
					Direction: 'column',
					RequiredRows: [
						{
							Fields: [
								{
									Control: Control.Organization,
									Label: 'IMPORTER',
									Property: 'importer',
									SubType: 'importer',
									Width: 100,
								},
							],
						},
					],
					Width: 33,
				},
				{
					Border: true,
					Direction: 'column',
					RequiredRows: [
						{
							Fields: [
								{
									Control: Control.Organization,
									Label: 'CONSIGNEE',
									Property: 'consignee',
									SubType: 'consignee',
									Width: 100,
								},
							],
						},
					],
					Width: 33,
				},
				{
					Border: true,
					Direction: 'column',
					RequiredRows: [
						{
							Fields: [
								{
									Control: Control.Organization,
									Label: 'BUYER',
									Property: 'buyer',
									SubType: 'buyer',
									Width: 100,
								},
							],
						},
					],
					Width: 33,
				},
			],
			Width: 100,
		},
		{
			Direction: 'row',
			Groups: [
				{
					Border: true,
					Direction: 'column',
					RequiredRows: [
						{
							Fields: [
								{
									Control: Control.Organization,
									Label: 'SHIP TO PARTY',
									Property: 'ship_to_party',
									SubType: 'shiptoparty',
									Width: 100,
								},
							],
						},
					],
					Width: 33,
				},
				{
					Border: true,
					Direction: 'column',
					RequiredRows: [
						{
							Fields: [
								{
									Control: Control.Organization,
									Label: 'BOND HOLDER',
									Property: 'bond_holder',
									SubType: 'bondholder',
									Width: 100,
								},
							],
						},
					],
					Width: 33,
				},
				{
					Border: true,
					Direction: 'column',
					RequiredRows: [
						{
							Fields: [
								{
									Control: Control.Organization,
									Label: 'SELLER',
									Property: 'seller',
									SubType: 'seller',
									Width: 100,
								},
							],
						},
					],
					Width: 33,
				},
			],
			Width: 100,
		},
		{
			Direction: 'row',
			Groups: [
				{
					Border: true,
					Direction: 'column',
					RequiredRows: [
						{
							Fields: [
								{
									Control: Control.Organization,
									Label: 'CONTAINER STUFFER',
									Property: 'container_stuffer',
									SubType: 'containerstuffer',
									Width: 100,
								},
							],
						},
					],
					Width: 33,
				},
				{
					Border: true,
					Direction: 'column',
					RequiredRows: [
						{
							Fields: [
								{
									Control: Control.Organization,
									Label: 'CONSOLIDATOR',
									Property: 'consolidator',
									SubType: 'consolidator',
									Width: 100,
								},
							],
						},
					],
					Width: 33,
				},
				{
					Border: true,
					Direction: 'column',
					RequiredRows: [
						{
							Fields: [
								{
									Control: Control.TextBox,
									Label: 'NOTES',
									Property: 'isf_notes',
									Width: 100,
								},
							],
						},
					],
					Width: 33,
				},
			],
			Width: 100,
		},
		{
			Direction: 'column',
			Padding: '0',
			RequiredRows: [
				{
					Fields: [
						{
							Control: Control.ToggleContactInfo,
							Label: 'SHOW CONTACT INFO?',
							Property: 'manufacturerLineItems',
							Width: 100,
						},
					],
				},
			],
			Width: 100,
		},
		{
			Label: 'MANUFACTURER LINE ITEMS',
			LabelBackground: 'blue',
			Padding: '0',
			RequiredRows: [
				{
					Fields: [
						{
							Control: Control.ManufacturerLineItems,
							Property: 'manufacturer_line_items',
							Width: 100,
						},
					],
				},
			],
			Width: 100,
		},
	],
}
