import Control from '../Control'
import { Layout } from '../Layout'

export const HBLLayout: Layout = {
	Groups: [
		{
			Border: true,
			Direction: 'column',
			Groups: [
				{
					Groups: [
						{
							RequiredRows: [
								{
									Fields: [
										{
											Control:
												Control.OrganizationTextbox,
											Label: 'SHIPPER (Principal or Seller-licensee and address)',
											Property: 'shipper',
											ReadOnly: true,
											SubType: 'shipper',
											Width: 100,
										},
									],
								},
							],
							Width: 50,
						},
						{
							RequiredRows: [
								{
									Fields: [
										{
											Label: 'B/L NO.',
											Property: 'hbl',
											ReadOnly: true,
											Width: 100,
										},
									],
								},
								{
									Fields: [
										{
											Control: Control.TextBox,
											Label: 'EXPORT REFERENCES (CO-LOADING MESSAGE)',
											Property: 'export_references',
											Width: 100,
										},
									],
								},
							],
							Width: 50,
						},
					],
					Width: 100,
				},
				{
					Groups: [
						{
							RequiredRows: [
								{
									Fields: [
										{
											Control:
												Control.OrganizationTextbox,
											Label: 'CONSIGNEE (NON-Negotiable unless consigned to order)',
											Property: 'consignee',
											ReadOnly: true,
											SubType: 'consignee',
											Width: 100,
										},
									],
								},
							],
							Width: 50,
						},
						{
							RequiredRows: [
								{
									Fields: [
										{
											Label: 'FORWARDING AGENT',
											Property: 'forwarding_agent',
											ReadOnly: true,
											Width: 100,
										},
									],
								},
								{
									Fields: [
										{
											Label: 'POINT AND COUNTRY OF ORIGIN',
											Property: 'place_of_receipt',
											ReadOnly: true,
											Width: 100,
										},
									],
								},
							],
							Width: 50,
						},
					],
					Width: 100,
				},
				{
					Groups: [
						{
							RequiredRows: [
								{
									Fields: [
										{
											Control:
												Control.OrganizationTextbox,
											Label: 'NOTIFY PARTNER/INTERMEDIATE CONSIGNEE (Name and address)',
											Property: 'notify',
											ReadOnly: true,
											SubType: 'notify',
											Width: 100,
										},
									],
								},
							],
							Width: 50,
						},
						{
							RequiredRows: [
								{
									Fields: [
										{
											Control: Control.TextBox,
											Label: 'ROUTING INSTRUCTIONS',
											Property: 'routing_instructions',
											Width: 100,
										},
									],
								},
							],
							Width: 50,
						},
					],
					Width: 100,
				},
				{
					Groups: [
						{
							Direction: 'column',
							Groups: [
								{
									RequiredRows: [
										{
											Fields: [
												{
													Label: 'PIER',
													Property: 'pier',
													Width: 100,
												},
											],
										},
									],
									Width: 100,
								},
								{
									RequiredRows: [
										{
											Fields: [
												{
													Label: 'EXPORT CARRIER',
													Properties: [
														'vessel',
														'voyage',
														'vessel_country',
													],
													Property: 'vessel',
													ReadOnly: true,
													Width: 50,
												},
												{
													Control: Control.Port,
													Label: 'PORT OF LOADING',
													Property: 'loading_port',
													ReadOnly: true,
													Width: 50,
												},
											],
										},
										{
											Fields: [
												{
													Control: Control.Port,
													Label: 'PORT OF DISCHARGE',
													Property: 'discharge_port',
													ReadOnly: true,
													Width: 50,
												},
												{
													Control: Control.Port,
													Label: 'PLACE OF DELIVERY',
													Property:
														'place_of_delivery',
													ReadOnly: true,
													Width: 50,
												},
											],
										},
									],
									Width: 100,
								},
							],
							Width: 50,
						},
						{
							RequiredRows: [
								{
									Fields: [
										{
											Control: Control.TextBox,
											Label: 'TO OBTAIN DELIVERY ARRANGEMENTS',
											Property: 'to_obtain_delivery',
											Width: 100,
										},
									],
								},
							],
							Width: 50,
						},
					],
					Width: 100,
				},
				{
					Groups: [
						{
							Label: 'FREIGHT RATES, CHARGES, WEIGHTS, AND/OR MEASUREMENTS',
							RequiredRows: [
								{
									Fields: [
										{
											Control: Control.FreightRates,
											Label: 'FREIGHT RATES',
											Property: 'freight_rates',
											Width: 100,
										},
										{
											Control: Control.Number,
											Label: 'NO. OF BILLS OF LADING',
											Property: 'num_bills_of_lading',
											Width: 100,
										},
									],
								},
							],
							Width: 100,
						},
					],
				},
				{
					Groups: [
						{
							RequiredRows: [
								{
									Fields: [
										{
											Control: Control.Currency,
											Label: 'DECLARED VALUE',
											Property: 'declared_value',
											RowSpan: 2,
											Width: 100,
										},
										{
											Control: Control.Date,
											Label: 'DATED',
											Property: 'hbl_date',
											Width: 100,
										},
									],
								},
								{
									Fields: [
										{
											Label: ' ',
											Property: '',
											Width: 50,
										},
										{
											Control: Control.Port,
											Label: 'PLACE OF ISSUE',
											Property: 'place_of_issue',
											ReadOnly: true,
											Width: 50,
										},
									],
								},
								{
									Fields: [
										{
											Control: Control.TextBox,
											Label: 'HBL NOTES',
											Property: 'hbl_notes',
											Width: 100,
										},
									],
								},
								{
									Fields: [
										{
											Control: Control.Checkbox,
											Label: 'APPLY "COPY" WATERMARK',
											Property: 'copy',
											Width: 100,
										},
									],
								},
								{
									Fields: [
										{
											Label: 'FILENAME',
											Property: 'filename',
											Width: 100,
										},
									],
								},
								{
									Fields: [
										{
											Control: Control.Checkbox,
											Label: 'SAVE TO DOCUMENTS',
											Property: 'save',
											Width: 100,
										},
									],
								},
							],
							Width: 100,
						},
					],
					Width: 100,
				},
			],
			Width: 100,
		},
	],
}
