import { FocusEvent, ReactElement, useState } from 'react'

import AddIcon from '../../../../assets/icons/add.svg'
import DeleteIcon from '../../../../assets/icons/delete.svg'
import { DateOnly } from '../../EditForm'
import { FieldProps } from '../../Field'
import { EditCarrier } from '../Carrier'
import { flattenArrays } from '../NRAsToGOAL'
import styles from './Pricing.module.scss'

export const EditPricing = (props: FieldProps) => {
	const [pricingCount, setPricingCount] = useState(
		props.data?.pricings?.length ? props.data.pricings?.length : 0
	)

	const rows: ReactElement[] = []
	for (let i = 0; i < pricingCount; i++) {
		rows.push(
			<div
				className={styles.row}
				id={`${props.field.Property}_${i}`}
				key={`pricings${i}`}
			>
				<input
					data-number={true}
					defaultValue={
						props.data?.pricings ? props.data?.pricings[i]?.id : ''
					}
					id={`${props.field.Property}_${i}_id`}
					name={`${props.field.Property}_${i}_id`}
					type="hidden"
				/>
				<input
					data-number={true}
					defaultValue={
						props.data?.pricings
							? props.data?.pricings[i]?.shipment
							: ''
					}
					id={`${props.field.Property}_${i}_shipment`}
					name={`${props.field.Property}_${i}_shipment`}
					type="hidden"
				/>
				<input
					data-boolean={true}
					defaultValue={'true'}
					id={`${props.field.Property}_${i}_active`}
					name={`${props.field.Property}_${i}_active`}
					type="hidden"
				/>
				<div
					className={styles.row}
					id={`${props.field.Property}_${i}_contents`}
				>
					<div className={styles.deleteColumn}>
						<div>{i + 1}</div>
						<img
							className={styles.delete}
							onClick={() => {
								// eslint-disable-next-line no-alert
								if (confirm('Really delete pricing?')) {
									(
										document.getElementById(
											`${props.field.Property}_${i}_active`
										) as HTMLInputElement
									).value = 'false'
									;(
										document.getElementById(
											`${props.field.Property}_${i}`
										) as HTMLInputElement
									).style.display = 'none'
									;(
										document.getElementById(
											`${props.field.Property}_${i}_contents`
										) as HTMLInputElement
									).innerHTML = ''

									if (props.onChange) {
										props.onChange(null)
									}
								}
							}}
							src={DeleteIcon}
							title="DELETE ROW"
						/>{' '}
					</div>
					<div className={styles.inputColumn}>
						<div className={styles.row}>
							<div className={styles.oneTenth}>
								<EditCarrier
									data={flattenArrays(props.data, '')}
									field={{
										Label: 'CARRIER',
										Property: `${props.field.Property}_${i}_carrier`,
										SoftRequired: true,
									}}
									isSearch={props.isSearch}
									onChange={props.onChange}
									readOnly={
										props.readOnly || props.field.ReadOnly
									}
									value={
										props.data?.pricings
											? props.data?.pricings[i]?.carrier
											: ''
									}
								/>
							</div>
							{!props.isSearch ? (
								<>
									<div className={styles.oneTenth}>
										<div className={styles.label}>
											ETD:{' '}
											<span
												className="softRequired"
												title="REQUIRED"
											>
												*
											</span>
										</div>
										<input
											className={`${
												!props.isSearch
													? 'softRequired'
													: ''
											} ${
												!props.data?.pricings ||
												!props.data?.pricings[i].etd
													? 'blank'
													: ''
											}`}
											data-softrequired={true}
											defaultValue={
												props.data?.pricings
													? DateOnly(
														props.data
															?.pricings[i]
															?.etd
													  ) ?? ''
													: ''
											}
											id={`${props.field.Property}_${i}_etd`}
											name={`${props.field.Property}_${i}_etd`}
											onChange={(e: any) => {
												if (e.target.value) {
													e.target.classList.remove(
														'blank'
													)
												} else {
													e.target.classList.add(
														'blank'
													)
												}

												if (props.onChange) {
													props.onChange(e)
												}
											}}
											onFocus={(e: FocusEvent) => {
												if (
													!props.readOnly &&
													!props.field.ReadOnly
												)
													(
														e.target as HTMLInputElement
													).showPicker()
											}}
											readOnly={
												props.readOnly ||
												props.field.ReadOnly
											}
											title="ETD"
											type="date"
										/>
									</div>

									<div className={styles.oneTenth}>
										<div className={styles.label}>
											ETA PORT:{' '}
											<span
												className="softRequired"
												title="REQUIRED"
											>
												*
											</span>
										</div>
										<input
											className={`${
												!props.isSearch
													? 'softRequired'
													: ''
											} ${
												!props.data?.pricings ||
												!props.data?.pricings[i]
													.eta_port
													? 'blank'
													: ''
											}`}
											data-softrequired={true}
											defaultValue={
												props.data?.pricings
													? DateOnly(
														props.data
															?.pricings[i]
															?.eta_port
													  ) ?? ''
													: ''
											}
											id={`${props.field.Property}_${i}_eta_port`}
											name={`${props.field.Property}_${i}_eta_port`}
											onChange={(e: any) => {
												if (e.target.value) {
													e.target.classList.remove(
														'blank'
													)
												} else {
													e.target.classList.add(
														'blank'
													)
												}

												if (props.onChange) {
													props.onChange(e)
												}
											}}
											onFocus={(e: FocusEvent) => {
												if (
													!props.readOnly &&
													!props.field.ReadOnly
												) {
													(
														e.target as HTMLInputElement
													).showPicker()
												}
											}}
											readOnly={
												props.readOnly ||
												props.field.ReadOnly
											}
											title="ETA PORT"
											type="date"
										/>
									</div>

									<div className={styles.oneHalf}>
										<div className={styles.label}>
											PRICING NOTES:
										</div>
										<textarea
											defaultValue={
												props.data?.pricings
													? props.data?.pricings[i]
														?.pricing_notes ??
													  ''
													: ''
											}
											id={`${props.field.Property}_${i}_pricing_notes`}
											name={`${props.field.Property}_${i}_pricing_notes`}
											onChange={props.onChange}
											placeholder=" "
											readOnly={
												props.readOnly ||
												props.field.ReadOnly
											}
											title="PRICING NOTES"
										></textarea>
									</div>

									<div className={styles.oneTenth}>
										<div className={styles.label}>
											VESSEL:{' '}
											<span
												className="softRequired"
												title="REQUIRED"
											>
												*
											</span>
										</div>
										<input
											className={
												!props.isSearch
													? 'softRequired'
													: ''
											}
											data-softrequired={true}
											defaultValue={
												props.data?.pricings
													? props.data?.pricings[i]
														?.vessel
													: ''
											}
											id={`${props.field.Property}_${i}_vessel`}
											maxLength={23}
											name={`${props.field.Property}_${i}_vessel`}
											onChange={props.onChange}
											placeholder=" "
											readOnly={
												props.readOnly ||
												props.field.ReadOnly
											}
											title="VESSEL"
											type="text"
										/>
									</div>

									<div className={styles.oneTenth}>
										<div className={styles.label}>
											VOYAGE:{' '}
											<span
												className="softRequired"
												title="REQUIRED"
											>
												*
											</span>
										</div>
										<input
											className={
												!props.isSearch
													? 'softRequired'
													: ''
											}
											data-softrequired={true}
											defaultValue={
												props.data?.pricings
													? props.data?.pricings[i]
														?.voyage
													: ''
											}
											id={`${props.field.Property}_${i}_voyage`}
											maxLength={5}
											name={`${props.field.Property}_${i}_voyage`}
											onChange={props.onChange}
											placeholder=" "
											readOnly={
												props.readOnly ||
												props.field.ReadOnly
											}
											title="VOYAGE"
											type="text"
										/>
									</div>
									<div className={styles.oneTenth}>
										<div className={styles.label}>
											COST:{' '}
											<span
												className="softRequired"
												title="REQUIRED"
											>
												*
											</span>
										</div>
										<input
											className={
												!props.isSearch
													? 'softRequired'
													: ''
											}
											data-number={true}
											data-softrequired={true}
											defaultValue={
												props.data?.pricings
													? props.data?.pricings[i]
														?.cost
													: ''
											}
											id={`${props.field.Property}_${i}_cost`}
											name={`${props.field.Property}_${i}_cost`}
											onChange={props.onChange}
											placeholder=" "
											readOnly={
												props.readOnly ||
												props.field.ReadOnly
											}
											step={0.01}
											title="COST"
											type="number"
										/>
									</div>

									<div className={styles.oneTenth}>
										<div className={styles.label}>
											SELLING:{' '}
											<span
												className="softRequired"
												title="REQUIRED"
											>
												*
											</span>
										</div>
										<input
											className={
												!props.isSearch
													? 'softRequired'
													: ''
											}
											data-number={true}
											data-softrequired={true}
											defaultValue={
												props.data?.pricings
													? props.data?.pricings[i]
														?.selling
													: ''
											}
											id={`${props.field.Property}_${i}_selling`}
											name={`${props.field.Property}_${i}_selling`}
											onChange={props.onChange}
											placeholder=" "
											readOnly={
												props.readOnly ||
												props.field.ReadOnly
											}
											step={0.01}
											title="SELLING"
											type="number"
										/>
									</div>
								</>
							) : (
								<></>
							)}
						</div>
					</div>
				</div>
			</div>
		)
	}
	if (pricingCount == 0) {
		rows.push(
			<div
				className={`${styles.row} ${styles.emptyRow}`}
				key="noPricings"
			>
				No pricings.
			</div>
		)
	}

	return (
		<div
			className={`${styles.fieldOuterContainer} ${
				!props.isSearch ? styles.editForm : ''
			}`}
			id={props.field.Property ? `${props.field.Property}_container` : ''}
			key="addPricing"
		>
			{rows}
			{!(props.isSearch || props.readOnly) ? (
				<div className={styles.buttonRow}>
					<button type="button">
						<img
							alt="ADD PRICING"
							onClick={() => {
								setPricingCount(pricingCount + 1)
								if (props.onChange) {
									props.onChange(null)
									setTimeout(() => {
										if (props.onChange) {
											props.onChange(null)
										}
									}, 100)
									setTimeout(() => {
										if (props.onChange) {
											props.onChange(null)
										}
									}, 250)
									setTimeout(() => {
										if (props.onChange) {
											props.onChange(null)
										}
									}, 500)
									setTimeout(() => {
										if (props.onChange) {
											props.onChange(null)
										}
									}, 1000)
									setTimeout(() => {
										if (props.onChange) {
											props.onChange(null)
										}
									}, 2500)
								}
							}}
							src={AddIcon}
						/>
					</button>
				</div>
			) : (
				<></>
			)}
		</div>
	)
}
