import { ReactNode } from 'react'
import { Link } from 'react-router-dom'

import { NRA } from '../../types/data/NRA'
import styles from './NRASummary.module.scss'

export const NRASummary = (props: NRASummaryProps) => {
	if (!props.nra) {
		return <></>
	}

	return (
		<div className={styles.nraSummaryOuterContainer}>
			<div className={styles.nraSummaryInnerContainer}>
				<div>
					<div className={styles.label}>ORGANIZATION:</div>
					<div className={styles.value}>
						{props.nra.organization_name}
					</div>
				</div>
				<div>
					<div className={styles.label}>REFERENCE NUMBER:</div>
					<div className={styles.value}>
						<Link to={`/nra/${props.nra.id}`}>
							{props.nra.reference_number}
						</Link>
					</div>
				</div>
			</div>
			{props.children}
		</div>
	)
}

export type NRASummaryProps = {
	children?: ReactNode
	nra: NRA
}
