import { useReactiveVar } from '@apollo/client'
import { Link } from 'react-router-dom'

import DownArrowIcon from '../../../assets/icons/arrow-down.svg'
import ReportsIcon from '../../../assets/icons/tracking.svg'
import { GlobalTopTabItems } from '../../../components/Tabs'
import { DefaultLayout } from '../../../layouts/DefaultLayout'
import { HasRole, ProfileUserID } from '../../../Profile'
import styles from './Reports.module.scss'

export const Reports = () => {
	const userID = useReactiveVar(ProfileUserID)

	return (
		<DefaultLayout
			bottomTabItems={ReportsBottomTabItems}
			leftMenuItems={ReportsLeftMenuItems}
			showPrint={true}
			tabMenuItems={ReportsTabMenuItems}
			topTabItems={GlobalTopTabItems}
		>
			<div className={styles.reportsContainer}>
				REPORTS PENDING. PLEASE CONTACT YOUR GOAL REPRESENTATIVE IF YOU
				REQUIRE A REPORT.
				{HasRole('administrator') ? (
					<>
						<p />
						<ul>
							<li>
								<Link to="/reports/nonmemberpartnerbilling">
									NON-MEMBER PARTNER BILLING REPORT
								</Link>
							</li>
						</ul>
					</>
				) : (
					<></>
				)}
				<p />
				<Link to={`/users/${userID}/notifications`}>
					CLICK HERE TO MANAGE EMAIL NOTIFICATIONS.
				</Link>
			</div>
		</DefaultLayout>
	)
}

export const ReportsTabMenuItems = []

export const ReportsBottomTabItems = [
	{
		icon: DownArrowIcon,
		text: 'REPORTS',
		to: '/reports',
	},
]

export const ReportsLeftMenuItems = [
	{
		icon: ReportsIcon,
		text: 'REPORTS',
		to: '/reports',
	},
]
