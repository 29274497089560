import { ReactNode } from 'react'
import { Link } from 'react-router-dom'

import { User } from '../../types/data/User'
import styles from './UserSummary.module.scss'

export const UserSummary = (props: UserSummaryProps) => {
	if (!props.user) {
		return <></>
	}

	return (
		<div className={styles.userSummaryOuterContainer}>
			<div className={styles.userSummaryInnerContainer}>
				<div>
					<div className={styles.label}>NAME:</div>
					<div className={styles.value}>
						<Link to={`/shipments/${props.user.id}`}>
							{props.user.name}
						</Link>
					</div>
				</div>
				<div>
					<div className={styles.label}>ROLE:</div>
					<div className={styles.value}>
						{props.user.role?.toUpperCase()}
					</div>
				</div>
				<div>
					<div className={styles.label}>EMAIL:</div>
					<div className={styles.value}>{props.user.email}</div>
				</div>
			</div>
			{props.children}
		</div>
	)
}

export type UserSummaryProps = {
	children?: ReactNode
	user: User
}
