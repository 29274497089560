import { useState } from 'react'

import {
	HasRole,
	ProfilePartnerID,
	ProfilePartnerName,
} from '../../../../Profile'
import { Capitalize } from '../../../../Utils'
import { FieldProps } from '../../Field'
import styles from './Role.module.scss'

export const EditRole = (props: FieldProps) => {
	const [value, setValue] = useState(props.value)

	if (props.readOnly || props.field.ReadOnly) {
		return (
			<div
				className={`${styles.fieldOuterContainer} role`}
				id={
					props.field.Property
						? `${props.field.Property}_container`
						: ''
				}
			>
				<div className={styles.label}>
					{props.field.Label}
					{props.field.HardRequired && !props.isSearch ? (
						<>
							{'\u00A0'}
							<span
								className="hardRequired"
								title="REQUIRED IN ORDER TO SAVE"
							>
								*
							</span>
						</>
					) : (
						<></>
					)}
					{props.field.SoftRequired && !props.isSearch ? (
						<>
							{'\u00A0'}
							<span className="softRequired" title="REQUIRED">
								*
							</span>
						</>
					) : (
						<></>
					)}{' '}
				</div>
				<input
					data-hardrequired={
						props.field.HardRequired && !props.isSearch
					}
					data-softrequired={
						props.field.SoftRequired && !props.isSearch
					}
					defaultValue={Capitalize(props.value)}
					readOnly={true}
					title={props.field.Label?.replace(':', '')}
					type="text"
				/>
			</div>
		)
	}

	return (
		<div
			className={`${styles.fieldOuterContainer} role`}
			id={props.field.Property ? `${props.field.Property}_container` : ''}
		>
			<div className={styles.label}>
				{props.field.Label}
				{props.field.HardRequired && !props.isSearch ? (
					<>
						{'\u00A0'}
						<span
							className="hardRequired"
							title="REQUIRED IN ORDER TO SAVE"
						>
							*
						</span>
					</>
				) : (
					<></>
				)}
				{props.field.SoftRequired && !props.isSearch ? (
					<>
						{'\u00A0'}
						<span className="softRequired" title="REQUIRED">
							*
						</span>
					</>
				) : (
					<></>
				)}{' '}
			</div>
			<select
				className={`${
					props.field.HardRequired && !props.isSearch
						? 'hardRequired'
						: ''
				} ${
					props.field.SoftRequired && !props.isSearch
						? 'softRequired'
						: ''
				}`}
				data-hardrequired={props.field.HardRequired && !props.isSearch}
				data-nullable={props.field.Nullable}
				data-softrequired={props.field.SoftRequired && !props.isSearch}
				defaultValue={value}
				id={props.field.Property}
				name={props.field.Property}
				onChange={(e: React.ChangeEvent) => {
					setValue((e.target as HTMLSelectElement).value)

					const partner = document.getElementById('partner')
					const partnerName = document.getElementById('partner_name')
					const partnerContainer =
						document.getElementById('partner_container')
					const partnerPermissionsContainer = document.getElementById(
						'permissions.partner'
					)
					const organization = document.getElementById('organization')
					const organizationName =
						document.getElementById('organization_name')
					const organizationContainer = document.getElementById(
						'organization_container'
					)
					const organizationPermissionsContainer =
						document.getElementById('permissions.organization')
					let permissionsContainer: any =
						document.getElementsByClassName('permissionsContainer')
					if (
						permissionsContainer &&
						permissionsContainer.length > 0
					) {
						permissionsContainer = permissionsContainer[0]
					}
					switch ((e.target as HTMLSelectElement).value) {
					case 'administrator':
						if (permissionsContainer) {
							permissionsContainer.classList.add(
								'administrator-permissions'
							)
							permissionsContainer.classList.remove(
								'organization-permissions'
							)
							permissionsContainer.classList.remove(
								'partner-permissions'
							)
						}
						if (organization) {
							(organization as HTMLInputElement).value = ''
						}
						if (organizationName) {
							(
									organizationName as HTMLInputElement
							).required = false
							;(organizationName as HTMLInputElement).value =
									''
						}
						if (organizationContainer) {
							organizationContainer.style.display = 'none'
						}
						if (organizationPermissionsContainer) {
							organizationPermissionsContainer.style.display =
									'none'
						}
						if (partner) {
							(partner as HTMLInputElement).value = ''
						}
						if (partnerName) {
							(partnerName as HTMLInputElement).required =
									false
							;(partnerName as HTMLInputElement).value = ''
						}
						if (partnerContainer) {
							partnerContainer.style.display = 'none'
						}
						if (partnerPermissionsContainer) {
							partnerPermissionsContainer.style.display =
									'none'
						}
						break
					case 'organization':
						if (permissionsContainer) {
							permissionsContainer.classList.remove(
								'administrator-permissions'
							)
							permissionsContainer.classList.add(
								'organization-permissions'
							)
							permissionsContainer.classList.remove(
								'partner-permissions'
							)
						}
						if (organization) {
							(organization as HTMLInputElement).value = ''
						}
						if (organizationName) {
							(
									organizationName as HTMLInputElement
							).required = true
							;(organizationName as HTMLInputElement).value =
									''
						}
						if (organizationContainer) {
							organizationContainer.style.display = ''
						}
						if (organizationPermissionsContainer) {
							organizationPermissionsContainer.style.display =
									''
						}
						if (partner) {
							(partner as HTMLInputElement).value = ''
						}
						if (partnerName) {
							(partnerName as HTMLInputElement).required =
									false
							;(partnerName as HTMLInputElement).value = ''
						}
						if (partnerContainer) {
							partnerContainer.style.display = 'none'
						}
						if (partnerPermissionsContainer) {
							partnerPermissionsContainer.style.display =
									'none'
						}
						break
					case 'memberpartner':
					case 'nonmemberpartner':
					case 'partner':
						if (permissionsContainer) {
							permissionsContainer.classList.remove(
								'administrator-permissions'
							)
							permissionsContainer.classList.remove(
								'organization-permissions'
							)
							permissionsContainer.classList.add(
								'partner-permissions'
							)
						}
						if (organization) {
							(organization as HTMLInputElement).value = ''
						}
						if (organizationName) {
							(
									organizationName as HTMLInputElement
							).required = false
							;(organizationName as HTMLInputElement).value =
									''
						}
						if (organizationContainer) {
							organizationContainer.style.display = 'none'
						}
						if (organizationPermissionsContainer) {
							organizationPermissionsContainer.style.display =
									'none'
						}
						if (partner) {
							if (ProfilePartnerID()) {
								(partner as HTMLInputElement).value =
										ProfilePartnerID().toString()
							} else {
								(partner as HTMLInputElement).value = ''
							}
						}
						if (partnerName) {
							(partnerName as HTMLInputElement).required =
									true
							if (ProfilePartnerID()) {
								(partnerName as HTMLInputElement).value =
										ProfilePartnerName()
								;(
										partnerName as HTMLInputElement
								).readOnly = true
							} else {
								(partnerName as HTMLInputElement).value =
										''
							}
						}
						if (partnerContainer) {
							partnerContainer.style.display = ''
						}
						if (partnerPermissionsContainer) {
							partnerPermissionsContainer.style.display = ''
						}
						break
					}

					if (props.onChange) {
						props.onChange(e)
					}
				}}
				required={props.field.HardRequired && !props.isSearch}
				title={props.field.Label?.replace(':', '')}
			>
				<option value=""></option>
				{HasRole('administrator') ? (
					<option key="administrator" value="administrator">
						Administrator
					</option>
				) : (
					<></>
				)}
				{HasRole(
					'administrator',
					'memberpartner',
					'nonmemberpartner',
					'partner'
				) ? (
						<option key="partner" value="partner">
						Partner
						</option>
					) : (
						<></>
					)}
				<option key="organization" value="organization">
					Organization
				</option>
			</select>
		</div>
	)
}
