import { FormEvent, useState } from 'react'
import { Link } from 'react-router-dom'

import MagnifyingGlass from '../../assets/icons/magnifying-glass.svg'
import Print from '../../assets/icons/print.svg'
import {
	Authenticated,
	HasDeveloperMode,
	HasPermission,
	HasPermissions,
	HasRole,
} from '../../Profile'
import { EncodedStringify } from '../../types/Filter'
import { RandomID } from '../../Utils'
import styles from './Tabs.module.scss'

export const Tabs = (props: TabsProps) => {
	const [search, setSearch] = useState('')

	if (props.loading) {
		return <></>
	}

	const tabs: { [key: string]: boolean } = {}
	return (
		<div className={styles.tabsContainer}>
			<div className={styles.topTabsContainer}>
				<div className={styles.topLeftGap}></div>
				{props.topTabs ? (
					props.topTabs.map((tabItem, index) => {
						if (!tabs[tabItem.to]) {
							if (
								HasDeveloperMode(
									tabItem.requireDeveloperMode
								) &&
								HasPermissions(tabItem.requiredPermissions) &&
								HasRole(tabItem.requiredRole ?? '')
							) {
								if (tabItem.icon) {
									if (
										document.location.pathname.startsWith(
											tabItem.to.split('?')[0]
										)
									) {
										return (
											<div
												className={`${styles.tab} ${styles.selected}`}
												key={`tab${index}`}
											>
												{document.location.pathname ==
												tabItem.to ? (
														<>
															{tabItem.text}
															<img
																alt={tabItem.text}
																src={tabItem.icon}
															/>
														</>
													) : (
														<Link to={tabItem.to}>
															{tabItem.text}{' '}
															<img
																alt={tabItem.text}
																src={tabItem.icon}
															/>
														</Link>
													)}
											</div>
										)
									} else {
										return (
											<div
												className={styles.tab}
												key={`tab${index}`}
											>
												<Link to={tabItem.to}>
													{tabItem.text}{' '}
													<img
														alt={tabItem.text}
														src={tabItem.icon}
													/>
												</Link>
											</div>
										)
									}
								} else {
									if (
										document.location.pathname.startsWith(
											tabItem.to.split('?')[0]
										)
									) {
										return (
											<div
												className={`${styles.tab} ${styles.selected}`}
												key={`tab${index}`}
											>
												{document.location.pathname ==
												tabItem.to ? (
														<>{tabItem.text}</>
													) : (
														<Link to={tabItem.to}>
															{tabItem.text}{' '}
														</Link>
													)}
											</div>
										)
									} else {
										return (
											<div
												className={styles.tab}
												key={`tab${index}`}
											>
												<Link to={tabItem.to}>
													{tabItem.text}
												</Link>
											</div>
										)
									}
								}
							}
							tabs[tabItem.to] = true
						}
					})
				) : (
					<></>
				)}
				{Authenticated() && HasPermission('shipment', 'read') ? (
					<div className={styles.searchContainer}>
						<div className={styles.searchBox}>
							<form
								name={'shipments-search'}
								onSubmit={(e: FormEvent<HTMLFormElement>) => {
									if (search) {
										const filters = [
											{
												field: '*',
												operator: 'contains',
												value: search,
											},
										]
										window.history.replaceState(
											{},
											'',
											`/shipments/?filters=${EncodedStringify(
												filters
											)}`
										)
									} else {
										window.history.replaceState(
											{},
											'',
											'/shipments'
										)
									}
									document.location.reload()
									e.preventDefault()
								}}
							>
								<input
									name="shipments-search"
									onChange={(
										e: React.ChangeEvent<HTMLInputElement>
									) => {
										setSearch(e.target.value)
									}}
									placeholder="Search..."
									type="text"
									value={search}
								/>
								<button type="submit">
									<img alt="SEARCH" src={MagnifyingGlass} />
								</button>
							</form>
						</div>
					</div>
				) : (
					<></>
				)}
			</div>
			<div className={styles.bottomTabsContainer}>
				{props.bottomTabs ? (
					props.bottomTabs.map((tabItem, index) => {
						const tabItemPathName = tabItem.to.split('?')[0]
						if (
							HasPermissions(tabItem.requiredPermissions) &&
							HasRole(tabItem.requiredRole ?? '')
						) {
							if (
								trimSlash(document.location.pathname) ==
								trimSlash(tabItemPathName)
							) {
								return (
									<h1
										className={`${styles.tab} ${styles.selected}`}
										key={`tab${index}`}
									>
										{tabItem.text}{' '}
										{tabItem.icon ? (
											<img
												alt={tabItem.text}
												src={tabItem.icon}
											/>
										) : (
											<></>
										)}
									</h1>
								)
							} else {
								return (
									<Link
										className={`${styles.tab} ${styles.unselected}`}
										key={`tab${index}`}
										to={tabItem.to}
									>
										{tabItem.text}{' '}
										{tabItem.icon ? (
											<img
												alt={tabItem.text}
												src={tabItem.icon}
											/>
										) : (
											<></>
										)}
									</Link>
								)
							}
						}
					})
				) : (
					<></>
				)}

				<div className={styles.tabsMenuContainer}>
					{props.showPrint ? (
						<div
							className={`${styles.menuItem} ${styles.print}`}
							onClick={() => {
								window.print()
							}}
						>
							<img alt="PRINT" src={Print} /> PRINT
						</div>
					) : (
						<></>
					)}

					{props.menuItems ? (
						props.menuItems.map((tabItem, index) =>
							HasPermissions(tabItem.requiredPermissions) &&
							HasRole(tabItem.requiredRole ?? '') ? (
									<div
										className={styles.menuItem}
										key={`tab${index}`}
									>
										{tabItem.onClick ? (
											<div
												className={styles.fakeLink}
												onClick={tabItem.onClick}
											>
												{' '}
												<img
													alt={tabItem.text}
													src={tabItem.icon}
												/>{' '}
												{tabItem.text}
											</div>
										) : (
											<Link to={tabItem.to}>
												<img
													alt={tabItem.text}
													src={tabItem.icon}
												/>{' '}
												{tabItem.text}
											</Link>
										)}
									</div>
								) : (
									<div key={RandomID(16)} />
								)
						)
					) : (
						<div key={RandomID(16)} />
					)}
				</div>
			</div>
		</div>
	)
}

export type TabItem = {
	count?: number
	to: string
	icon?: string
	onClick?: any
	requireDeveloperMode?: boolean
	requiredPermissions?: string[]
	requiredRole?: string
	text: string
}

export type TabsProps = {
	bottomTabs: TabItem[]
	loading?: boolean
	menuItems: TabItem[]
	showPrint: boolean
	topTabs: TabItem[]
}

export const GlobalTopTabItems = [
	{
		requiredPermissions: ['shipment.read'],
		text: 'DASHBOARD',
		to: '/dashboard',
	},
	{
		requiredPermissions: ['shipment.read'],
		text: 'SHIPMENTS',
		to: '/shipments',
	},
	{
		requiredPermissions: ['nra.read'],
		requiredRole: 'administrator||memberpartner||partner',
		text: 'NRA',
		to: '/nra?open',
	},
	{
		requiredPermissions: ['organization.read'],
		text: 'ORGANIZATIONS',
		to: '/organizations',
	},
	{
		requiredPermissions: ['partner.read'],
		requiredRole: 'administrator||memberpartner||partner',
		text: 'PARTNERS',
		to: '/partners',
	},
	{
		requiredPermissions: ['pay.viewbalance'],
		requiredRole: 'memberpartner||partner',
		text: 'PAY',
		to: '/pay',
	},
	{
		requiredPermissions: ['report.read'],
		requiredRole: 'administrator||memberpartner||partner',
		text: 'REPORTS',
		to: '/reports',
	},
	{
		requiredRole: 'administrator',
		text: 'ADMINISTRATION',
		to: '/administration',
	},
]

const trimSlash = (input: string): string => {
	if (input.endsWith('/')) {
		return input.slice(0, -1)
	}
	return input
}
