import { useState } from 'react'

import { FieldProps } from '../../Field'
import styles from './CarrierType.module.scss'

export const EditCarrierType = (props: FieldProps) => {
	const [value, setValue] = useState(props.value)

	const carrierTypes = ['hbl', 'mbl']

	if (props.readOnly || props.field.ReadOnly) {
		return (
			<div
				className={styles.fieldOuterContainer}
				id={
					props.field.Property
						? `${props.field.Property}_container`
						: ''
				}
			>
				<div className={styles.label}>
					{props.field.Label}
					{props.field.HardRequired && !props.isSearch ? (
						<>
							{'\u00A0'}
							<span
								className="hardRequired"
								title="REQUIRED IN ORDER TO SAVE"
							>
								*
							</span>
						</>
					) : (
						<></>
					)}
					{props.field.SoftRequired && !props.isSearch ? (
						<>
							{'\u00A0'}
							<span className="softRequired" title="REQUIRED">
								*
							</span>
						</>
					) : (
						<></>
					)}{' '}
				</div>
				<input
					data-hardrequired={
						props.field.HardRequired && !props.isSearch
					}
					data-softrequired={
						props.field.SoftRequired && !props.isSearch
					}
					defaultValue={value}
					onChange={props.onChange}
					readOnly={true}
					title={props.field.Label?.replace(':', '')}
					type="text"
				/>
			</div>
		)
	}

	return (
		<div
			className={styles.fieldOuterContainer}
			id={props.field.Property ? `${props.field.Property}_container` : ''}
		>
			<div className={styles.label}>
				{props.field.Label}
				{props.field.HardRequired && !props.isSearch ? (
					<>
						{'\u00A0'}
						<span
							className="hardRequired"
							title="REQUIRED IN ORDER TO SAVE"
						>
							*
						</span>
					</>
				) : (
					<></>
				)}
				{props.field.SoftRequired && !props.isSearch ? (
					<>
						{'\u00A0'}
						<span className="softRequired" title="REQUIRED">
							*
						</span>
					</>
				) : (
					<></>
				)}{' '}
			</div>
			<select
				autoComplete="off"
				className={`${
					props.field.HardRequired && !props.isSearch
						? 'hardRequired'
						: ''
				} ${
					props.field.SoftRequired && !props.isSearch
						? 'softRequired'
						: ''
				}`}
				data-hardrequired={props.field.HardRequired && !props.isSearch}
				data-nullable={props.field.Nullable}
				data-softrequired={props.field.SoftRequired && !props.isSearch}
				id={props.field.Property}
				name={props.field.Property}
				onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
					setValue(e.target.value)
					if (props.onChange) {
						props.onChange(e)
					}
				}}
				required={props.field.HardRequired && !props.isSearch}
				title={props.field.Label?.replace(':', '')}
				value={value}
			>
				<option value=""></option>
				{carrierTypes?.map((carriertype: string, index: number) => (
					<option key={`carriertype${index}`} value={carriertype}>
						{carriertype.toUpperCase()}
					</option>
				))}
			</select>
		</div>
	)
}
