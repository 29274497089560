import { Suspense } from 'react'
import {
	BrowserRouter,
	Route,
	Routes,
} from 'react-router-dom'

import { Header } from './components/Header'
import { Loader } from './components/Loader'
import { CarrierTracking } from './pages/CarrierTracking'
import { LogIn } from './pages/LogIn'
import { ForgotPassword } from './pages/LogIn/ForgotPassword'
import { ChangePassword } from './pages/LogIn/ForgotPassword/ChangePassword'
import { LogInRedirect } from './pages/LogIn/LogInRedirect'
import { CreateAccount as Register } from './pages/LogIn/Register'
import { VerifyEmail } from './pages/LogIn/Register/VerifyEmail'

export const AnonymousRouter = () => {
	return (
		<BrowserRouter>
			<Suspense fallback={<Loader />}>
				<Header />
				<Routes>
					<Route element={<LogIn />} index />
					<Route
						element={<CarrierTracking />}
						path="/carrier-tracking"
					/>
					<Route element={<Register />} path="/register" />
					<Route element={<VerifyEmail />} path="/register/verify" />
					<Route
						element={<ForgotPassword />}
						path="/forgot-password"
					/>
					<Route
						element={<ChangePassword />}
						path="/forgot-password/change"
					/>
					<Route element={<LogInRedirect />} path="*" />
				</Routes>
			</Suspense>
		</BrowserRouter>
	)
}
