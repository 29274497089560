import axios from 'axios'
import { useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'

import AgentAccessIcon from '../../../../../assets/icons/agent-access.svg'
import DownArrowIcon from '../../../../../assets/icons/arrow-down.svg'
import DocumentsIcon from '../../../../../assets/icons/documents.svg'
import OrganizationHistoryIcon from '../../../../../assets/icons/organization-history.svg'
import OrganizationInfoIcon from '../../../../../assets/icons/organization-info.svg'
import OrganizationUsersIcon from '../../../../../assets/icons/organization-users.svg'
import ShipmentsIcon from '../../../../../assets/icons/shipments.svg'
import {
	EditForm,
	ParsedFormData,
} from '../../../../../components/Form/EditForm'
import { NavTable } from '../../../../../components/NavTable'
import { OrganizationSummary } from '../../../../../components/OrganizationSummary'
import { GlobalTopTabItems } from '../../../../../components/Tabs'
import { DefaultLayout } from '../../../../../layouts/DefaultLayout'
import { APIHostname, HasPermission, LogOff } from '../../../../../Profile'
import Control from '../../../../../types/Control'
import { OrganizationAccessLayout } from '../../../../../types/data/OrganizationAccess'
import { EscapeURIParameters } from '../../../../../Utils'
import styles from './PartnerAccess.module.scss'

export const PartnerAccess = () => {
	const { id } = useParams()
	const [organization, setOrganization]: any = useState(null)
	const [partners, setPartners]: any = useState(null)
	const [partnerCount, setPartnerCount] = useState(0)
	const [organizationLoaded, setOrganizationLoaded]: any = useState(false)
	const [partnersLoaded, setPartnersLoaded]: any = useState(false)
	const [errorMessage, setErrorMessage] = useState(<></>)
	const initialRedirectMessage = sessionStorage['redirect-message']
	const [redirectMessage] = useState(initialRedirectMessage)

	// Vary based on query string.
	const location = useLocation()
	const urlParams = new URLSearchParams(location.search)
	if (!urlParams.get('limit')) {
		urlParams.set('limit', '25')
	}

	useEffect(() => {
		axios({
			data: {},
			headers: {
				Authorization: `Bearer ${localStorage['token']}`,
			},
			method: 'GET',
			url: `${APIHostname}/api/v1/organizations/${id}${location.search}`,
		})
			.then((response: any) => {
				setErrorMessage(<></>)
				setOrganization(response.data)
				setOrganizationLoaded(true)
			})
			.catch((error: any) => {
				switch (error?.response?.status) {
				case 401:
					LogOff('token-expired')
					return
				case 403:
					setErrorMessage(
						<>
								Error: You do not have permission to view
								organizations.
						</>
					)
					break
				default:
					if (error?.response?.data?.error) {
						setErrorMessage(
							error?.response?.data?.link ? (
								<Link to={error?.response?.data?.link}>
										Error:
									{error?.response?.data?.error}
								</Link>
							) : (
								<>{error?.response?.data?.error}</>
							)
						)
					} else {
						setErrorMessage(<>Error: Unknown error.</>)
							console.error(error) // eslint-disable-line
					}
				}
			})

		axios({
			data: {},
			headers: {
				Authorization: `Bearer ${localStorage['token']}`,
			},
			method: 'GET',
			url: `${APIHostname}/api/v1/organizations/${id}/partner_access?${EscapeURIParameters(
				urlParams.toString()
			)}`,
		})
			.then((response: any) => {
				setErrorMessage(<></>)
				setPartners(response.data.partners)
				setPartnerCount(response.data.partner_count)
				setPartnersLoaded(true)
			})
			.catch((error: any) => {
				switch (error?.response?.status) {
				case 401:
					LogOff('token-expired')
					return
				case 403:
					setErrorMessage(
						<>
								Error: You do not have permission to view
								partners for this organization.
						</>
					)
					break
				default:
					if (error?.response?.data?.error) {
						setErrorMessage(
							error?.response?.data?.link ? (
								<Link to={error?.response?.data?.link}>
										Error:
									{error?.response?.data?.error}
								</Link>
							) : (
								<>{error?.response?.data?.error}</>
							)
						)
					} else {
						setErrorMessage(<>Error: Unknown error.</>)
							console.error(error) // eslint-disable-line
					}
				}
			})
	}, [location])

	const OrganizationBottomTabItems = [
		{
			icon: DownArrowIcon,
			text: `ORGANIZATION #${id} PARTNER ACCESS`,
			to: `/organizations/${id}/partner_access`,
		},
	]

	const OrganizationLeftMenuItems = [
		{
			icon: OrganizationInfoIcon,
			requiredPermissions: ['organization.read'],
			text: 'ORGANIZATION INFO',
			to: `/organizations/${id}`,
		},
		{
			icon: DocumentsIcon,
			requiredPermissions: ['shipment.read'],
			text: 'ORGANIZATION DOCUMENTS',
			to: `/organizations/${id}/documents`,
		},
		{
			icon: ShipmentsIcon,
			requiredPermissions: ['shipment.read'],
			text: 'LINKED SHIPMENTS',
			to: `/organizations/${id}/shipments`,
		},
		{
			icon: OrganizationUsersIcon,
			requiredPermissions: ['user.read'],
			text: 'ORGANIZATION USERS',
			to: `/organizations/${id}/users`,
		},
		{
			icon: AgentAccessIcon,
			requiredPermissions: ['organization.manageaccess'],
			text: 'PARTNER ACCESS',
			to: `/organizations/${id}/partner_access`,
		},
		{
			icon: OrganizationHistoryIcon,
			requiredPermissions: ['organization.read'],
			text: 'ORGANIZATION HISTORY',
			to: `/organizations/${id}/history`,
		},
	]

	if (!organizationLoaded || !partnersLoaded) {
		if (errorMessage) {
			return (
				<DefaultLayout
					bottomTabItems={OrganizationBottomTabItems}
					leftMenuItems={OrganizationLeftMenuItems}
					showPrint={true}
					tabMenuItems={OrganizationTabMenuItems}
					topTabItems={GlobalTopTabItems}
				>
					<div className={styles.organizationsContainer}>
						<div className={styles.redirectMessage}>
							{redirectMessage}
						</div>
						<div className={styles.errorMessage}>
							{errorMessage}
						</div>
					</div>
				</DefaultLayout>
			)
		}

		return <div className="loading">Loading...</div>
	}

	sessionStorage.removeItem('redirect-message')
	return (
		<DefaultLayout
			bottomTabItems={OrganizationBottomTabItems}
			leftMenuItems={OrganizationLeftMenuItems}
			showPrint={true}
			tabMenuItems={OrganizationTabMenuItems}
			topTabItems={GlobalTopTabItems}
		>
			<div className={styles.partnerAccessContainer}>
				<div className={styles.redirectMessage}>{redirectMessage}</div>
				<div className={styles.errorMessage}>
					{errorMessage}{' '}
					{!organization?.active ? (
						<>This organization has been deleted.</>
					) : (
						<></>
					)}
				</div>

				<OrganizationSummary organization={organization} />

				<div className={styles.addPartnerForm}>
					<EditForm
						data={{}}
						hasClearButton={false}
						layout={OrganizationAccessLayout}
						name="organizationAccess"
						onSubmit={async (formData: FormData) => {
							axios({
								data: await ParsedFormData(formData, []),
								headers: {
									Authorization: `Bearer ${localStorage['token']}`,
									'Content-Type': 'application/json',
								},
								method: 'POST',
								url: `${APIHostname}/api/v1/organizations/${id}/partner_access`,
							})
								.then(() => {
									sessionStorage['redirect-message'] =
										'Partner access added.'
									window.history.replaceState(
										{},
										'',
										`/organizations/${id}/partner_access`
									)
									document.location.reload()
								})
								.catch((error: any) => {
									switch (error?.response?.status) {
									case 401:
										LogOff('token-expired')
										return
									case 403:
										setErrorMessage(
											<>
													Error: You do not have
													permission to add partner
													access.
											</>
										)
										break
									default:
										if (error?.response?.data?.error) {
											setErrorMessage(
												error?.response?.data
													?.link ? (
														<Link
															to={
																error?.response
																	?.data?.link
															}
														>
															Error:
															{
																error?.response
																	?.data
																	?.error
															}
														</Link>
													) : (
														<>
															{
																error?.response
																	?.data
																	?.error
															}
														</>
													)
											)
										} else {
											setErrorMessage(
												<>Error: Unknown error.</>
											)
												console.error(error) // eslint-disable-line
										}
									}
								})
						}}
						previewAPIData={async (formData: FormData) => {
							const payload = await ParsedFormData(formData, [])

							return {
								data: JSON.stringify(payload, null, 4),
								method: 'POST',
								url: `${APIHostname}/api/v1/organizations/${id}/partner_access`,
							}
						}}
						readOnly={
							!HasPermission('organization', 'manageaccess') ||
							!organization?.active
						}
						submitButtonLabel="ADD PARTNER"
					/>
				</div>

				<NavTable
					apiPathRoot={`/organizations/${id}/partner_access`}
					columns={[
						{
							control: Control.ImageFile,
							id: 'image',
							title: '',
						},
						{
							id: 'code',
							title: 'Partner Code',
						},
						{
							id: 'name',
							title: 'Partner Name',
						},
						{
							control: Control.Partner,
							id: 'parent',
							title: 'Parent',
						},
						{
							children: [
								{
									id: 'city',
									title: 'City',
								},
								{
									id: 'state',
									title: 'State',
								},
								{
									control: Control.Country,
									id: 'country',
									title: 'Country',
								},
							],
						},
						{
							control: Control.Hyperlink,
							id: 'website',
							title: 'Website',
						},
						{
							control: Control.Phone,
							id: 'phone',
							title: 'Phone',
						},
						{
							id: 'notes',
							title: 'Notes',
						},
						{
							children: [
								{
									control: Control.DateTime,
									id: 'created',
									title: 'Creation Date',
								},
								{
									control: Control.User,
									id: 'created_by',
									title: 'Created By',
								},
							],
						},
						{
							children: [
								{
									control: Control.DateTime,
									id: 'modified',
									title: 'Modified Date',
								},
								{
									control: Control.User,
									id: 'modified_by',
									title: 'Modified By',
								},
							],
						},
					]}
					count={partnerCount}
					data={partners}
					filters={JSON.parse(urlParams.get('filters') ?? '[]')}
					includeDelete={
						//	HasRole('administrator') &&
						HasPermission('partner', 'delete')
					}
					linkPathRoot="/partners"
					primaryDisplayColumn="code"
					primaryKey="id"
				/>
			</div>
		</DefaultLayout>
	)
}

export const OrganizationTabMenuItems = [].reverse()
