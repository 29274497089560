import axios from 'axios'
import { MouseEvent, useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'

import DownArrowIcon from '../../../../assets/icons/arrow-down.svg'
import DeleteIcon from '../../../../assets/icons/delete.svg'
import PortsIcon from '../../../../assets/icons/ports.svg'
import ShipmentsIcon from '../../../../assets/icons/shipments.svg'
import { EditForm, ParsedFormData } from '../../../../components/Form/EditForm'
import { GlobalTopTabItems, TabItem } from '../../../../components/Tabs'
import { DefaultLayout } from '../../../../layouts/DefaultLayout'
import { APIHostname, HasRole, LogOff } from '../../../../Profile'
import { EditPortLayout } from '../../../../types/data/Port'
import styles from './Port.module.scss'

export const Port = () => {
	const { code } = useParams()
	const [port, setPort]: any = useState(null)
	const [errorMessage, setErrorMessage] = useState(<></>)
	const initialRedirectMessage = sessionStorage['redirect-message']
	const [redirectMessage] = useState(initialRedirectMessage)

	// Vary based on query string.
	const location = useLocation()

	useEffect(() => {
		axios({
			data: {},
			headers: {
				Authorization: `Bearer ${localStorage['token']}`,
			},
			method: 'GET',
			url: `${APIHostname}/api/v1/ports/${code}`,
		})
			.then((response: any) => {
				setErrorMessage(<></>)
				setPort(response.data)
			})
			.catch((error: any) => {
				switch (error?.response?.status) {
				case 401:
					LogOff('token-expired')
					return
				case 403:
					setErrorMessage(
						<>
								Error: You do not have permission to view ports.
						</>
					)
					break
				default:
					if (error?.response?.data?.error) {
						setErrorMessage(
							<Link to={error?.response?.data?.link}>
									Error: {error?.response?.data?.error}
							</Link>
						)
					} else {
						setErrorMessage(<>Error: Unknown error.</>)
							console.error(error) // eslint-disable-line
					}
				}
			})
	}, [location])

	const PortBottomTabItems = [
		{
			icon: DownArrowIcon,
			text: `PORT #${code}`,
			to: `/ports/${code}`,
		},
	]

	const PortLeftMenuItems = [
		{
			icon: PortsIcon,
			text: 'PORT INFO',
			to: `/ports/${code}`,
		},
		{
			icon: ShipmentsIcon,
			requiredPermissions: ['shipment.read'],
			text: 'PORT SHIPMENTS',
			to: `/ports/${code}/shipments`,
		},
	]

	const PortTabMenuItems: TabItem[] = []
	if (port?.active) {
		PortTabMenuItems.push({
			icon: DeleteIcon,
			onClick: async (e: MouseEvent<Element>) => {
				e.preventDefault()

				// eslint-disable-next-line
				const confirmation = confirm(`Really delete port?`)
				if (!confirmation) {
					return
				}

				axios({
					headers: {
						Authorization: `Bearer ${localStorage['token']}`,
						'Content-Type': 'application/json',
					},
					method: 'DELETE',
					url: `${APIHostname}/api/v1/ports/${code}`,
				})
					.then((response: any) => {
						sessionStorage[
							'redirect-message'
						] = `Port #${response.data.port_code} deleted.`
						window.history.replaceState({}, '', '/ports')
						document.location.reload()
					})
					.catch((error: any) => {
						switch (error?.response?.status) {
						case 401:
							LogOff('token-expired')
							return
						case 403:
							setErrorMessage(
								<>
										Error: You do not have permission to
										delete ports.
								</>
							)
							break
						default:
							if (error?.response?.data?.error) {
								setErrorMessage(
									<Link to={error?.response?.data?.link}>
											Error:
										{error?.response?.data?.error}
									</Link>
								)
							} else {
								setErrorMessage(<>Error: Unknown error.</>)
									console.error(error) // eslint-disable-line
							}
						}
					})
			},
			requiredRole: 'administrator',
			text: 'DELETE',
			to: '#',
		})
	} else {
		PortTabMenuItems.push({
			icon: DeleteIcon,
			onClick: async (e: MouseEvent<Element>) => {
				e.preventDefault()

				// eslint-disable-next-line
				const confirmation = confirm(`Really undelete port?`)
				if (!confirmation) {
					return
				}

				axios({
					headers: {
						Authorization: `Bearer ${localStorage['token']}`,
						'Content-Type': 'application/json',
					},
					method: 'POST',
					url: `${APIHostname}/api/v1/ports/${code}/undelete`,
				})
					.then((response: any) => {
						sessionStorage[
							'redirect-message'
						] = `Port #${response.data.port_code} undeleted.`
						window.history.replaceState({}, '', '/ports')
						document.location.reload()
					})
					.catch((error: any) => {
						switch (error?.response?.status) {
						case 401:
							LogOff('token-expired')
							return
						case 403:
							setErrorMessage(
								<>
										Error: You do not have permission to
										undelete ports.
								</>
							)
							break
						default:
							if (error?.response?.data?.error) {
								setErrorMessage(
									<Link to={error?.response?.data?.link}>
											Error:
										{error?.response?.data?.error}
									</Link>
								)
							} else {
								setErrorMessage(<>Error: Unknown error.</>)
									console.error(error) // eslint-disable-line
							}
						}
					})
			},
			requiredRole: 'administrator',
			text: 'UNDELETE',
			to: '#',
		})
	}
	PortTabMenuItems.reverse()

	if (port == null) {
		if (errorMessage) {
			<DefaultLayout
				bottomTabItems={PortBottomTabItems}
				leftMenuItems={PortLeftMenuItems}
				showPrint={true}
				tabMenuItems={PortTabMenuItems}
				topTabItems={GlobalTopTabItems}
			>
				<div className={styles.portContainer}>
					<div className={styles.redirectMessage}>
						{redirectMessage}
					</div>
					<div className={styles.errorMessage}>{errorMessage}</div>
				</div>
			</DefaultLayout>
		}

		return <div className="loading">Loading...</div>
	}

	sessionStorage.removeItem('redirect-message')

	const readOnly = !HasRole('administrator')
	return (
		<DefaultLayout
			bottomTabItems={PortBottomTabItems}
			leftMenuItems={PortLeftMenuItems}
			showPrint={true}
			tabMenuItems={PortTabMenuItems}
			topTabItems={GlobalTopTabItems}
		>
			<div className={styles.portContainer}>
				<div className={styles.redirectMessage}>{redirectMessage}</div>
				<div className={styles.errorMessage}>
					{errorMessage}
					{!port?.active ? <>This port has been deleted.</> : <></>}
				</div>

				<EditForm
					data={port}
					layout={EditPortLayout}
					name="port"
					onSubmit={async (formData: FormData) => {
						// Update organization
						axios({
							data: await ParsedFormData(formData, []),
							headers: {
								Authorization: `Bearer ${localStorage['token']}`,
								'Content-Type': 'application/json',
							},
							method: 'POST',
							url: `${APIHostname}/api/v1/ports/${code}`,
						})
							.then(() => {
								sessionStorage[
									'redirect-message'
								] = `Port #${code} updated.`
								window.history.replaceState({}, '', '/ports')
								document.location.reload()
							})
							.catch((error: any) => {
								switch (error?.response?.status) {
								case 401:
									LogOff('token-expired')
									return
								case 403:
										console.error(error) // eslint-disable-line
									setErrorMessage(
										<>
												Error: You do not have
												permission to edit ports.
										</>
									)
									break
								default:
									if (error?.response?.data?.error) {
										setErrorMessage(
											error?.response?.data?.link ? (
												<Link
													to={
														error?.response
															?.data?.link
													}
												>
														Error:
													{
														error?.response
															?.data?.error
													}
												</Link>
											) : (
												<>
													{
														error?.response
															?.data?.error
													}
												</>
											)
										)
									} else {
										setErrorMessage(
											<>Error: Unknown error.</>
										)
											console.error(error) // eslint-disable-line
									}
								}
							})
					}}
					previewAPIData={async (formData: FormData) => {
						const parsedFormData = await ParsedFormData(
							formData,
							[]
						)

						return {
							data: JSON.stringify(parsedFormData, null, 4),
							method: 'POST',
							url: `${APIHostname}/api/v1/ports/${code}`,
						}
					}}
					readOnly={readOnly}
				/>
			</div>
		</DefaultLayout>
	)
}
