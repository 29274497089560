import { FieldProps } from '../../Field'
import styles from './Boolean.module.scss'

export const EditBoolean = (props: BooleanProps) => {
	if (!props.isSearch && props.readOnly) {
		return (
			<>
				<div className={styles.label}>
					<label htmlFor={props.field.Property}>
						{props.field.Label ?? <span>{'\u00A0'}</span>}
						{props.field.HardRequired && !props.isSearch ? (
							<>
								{'\u00A0'}
								<span
									className="hardRequired"
									title="REQUIRED IN ORDER TO SAVE"
								>
									*
								</span>
							</>
						) : (
							<></>
						)}
						{props.field.SoftRequired && !props.isSearch ? (
							<>
								{'\u00A0'}
								<span className="softRequired" title="REQUIRED">
									*
								</span>
							</>
						) : (
							<></>
						)}{' '}
					</label>
				</div>
				<input
					className={styles.readOnly}
					data-boolean={true}
					data-hardrequired={
						props.field.HardRequired && !props.isSearch
					}
					data-softrequired={
						props.field.SoftRequired && !props.isSearch
					}
					defaultValue={
						props.value == 'all'
							? 'Any'
							: props.value == 'true'
								? 'True'
								: 'False'
					}
					readOnly={true}
					title={props.field.Label?.replace(':', '')}
					type="text"
				/>
			</>
		)
	}

	if (props.isSearch) {
		return (
			<div className={styles.label}>
				<label htmlFor={props.field.Property}>
					{props.field.Label ?? <span>{'\u00A0'}</span>}
					{props.field.HardRequired && !props.isSearch ? (
						<>
							{'\u00A0'}
							<span
								className="hardRequired"
								title="REQUIRED IN ORDER TO SAVE"
							>
								*
							</span>
						</>
					) : (
						<></>
					)}
					{props.field.SoftRequired && !props.isSearch ? (
						<>
							{'\u00A0'}
							<span className="softRequired" title="REQUIRED">
								*
							</span>
						</>
					) : (
						<></>
					)}{' '}
				</label>
				<select
					className={`${
						props.field.HardRequired && !props.isSearch
							? 'hardRequired'
							: ''
					} ${
						props.field.SoftRequired && !props.isSearch
							? 'softRequired'
							: ''
					}`}
					data-hardrequired={
						props.field.HardRequired && !props.isSearch
					}
					data-nullable={props.field.Nullable}
					data-searchoperator={'eq'}
					data-softrequired={
						props.field.SoftRequired && !props.isSearch
					}
					defaultValue={props.value}
					id={props.field.Property}
					key={`${props.field}-${props.value}`}
					name={props.field.Property}
					required={props.field.HardRequired && !props.isSearch}
					title={props.field.Label?.replace(':', '')}
				>
					<option key="all" value="">
						Any
					</option>
					<option key="true" value="true">
						True
					</option>
					<option key="false" value="false">
						False
					</option>
				</select>
			</div>
		)
	}

	if (props.checkbox) {
		return (
			<div className={styles.label}>
				<label htmlFor={props.field.Property}>
					{props.field.Label ?? <span>{'\u00A0'}</span>}
					{props.field.HardRequired && !props.isSearch ? (
						<>
							{'\u00A0'}
							<span
								className="hardRequired"
								title="REQUIRED IN ORDER TO SAVE"
							>
								*
							</span>
						</>
					) : (
						<></>
					)}
					{props.field.SoftRequired && !props.isSearch ? (
						<>
							{'\u00A0'}
							<span className="softRequired" title="REQUIRED">
								*
							</span>
						</>
					) : (
						<></>
					)}{' '}
				</label>
				<input
					data-boolean={true}
					data-hardrequired={
						props.field.HardRequired && !props.isSearch
					}
					data-softrequired={
						props.field.SoftRequired && !props.isSearch
					}
					defaultChecked={props.value}
					id={props.field.Property}
					key={`${props.field}-${props.value}`}
					name={props.field.Property}
					onChange={props.onChange}
					required={props.field.HardRequired && !props.isSearch}
					title={props.field.Label?.replace(':', '')}
					type="checkbox"
					value={'true'}
				/>
			</div>
		)
	}

	return (
		<div className={styles.label}>
			<label htmlFor={props.field.Property}>
				{props.field.Label ?? <span>{'\u00A0'}</span>}
				{props.field.HardRequired && !props.isSearch ? (
					<>
						{'\u00A0'}
						<span
							className="hardRequired"
							title="REQUIRED IN ORDER TO SAVE"
						>
							*
						</span>
					</>
				) : (
					<></>
				)}
				{props.field.SoftRequired && !props.isSearch ? (
					<>
						{'\u00A0'}
						<span className="softRequired" title="REQUIRED">
							*
						</span>
					</>
				) : (
					<></>
				)}{' '}
			</label>
			<select
				className={`${
					props.field.HardRequired && !props.isSearch
						? 'hardRequired'
						: ''
				} ${
					props.field.SoftRequired && !props.isSearch
						? 'softRequired'
						: ''
				}`}
				data-boolean={true}
				data-hardrequired={props.field.HardRequired && !props.isSearch}
				data-nullable={props.field.Nullable}
				data-softrequired={props.field.SoftRequired && !props.isSearch}
				defaultValue={props.value ? 'true' : 'false'}
				id={props.field.Property}
				key={`${props.field}-${props.value}`}
				name={props.field.Property}
				required={props.field.HardRequired && !props.isSearch}
				title={props.field.Label?.replace(':', '')}
			>
				<option key="true" value="true">
					True
				</option>
				<option key="false" value="false">
					False
				</option>
			</select>
		</div>
	)
}

export type BooleanProps = FieldProps & {
	checkbox?: boolean
}
