import axios from 'axios'
import { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

import LateACIIcon from '../../../assets/icons/aci.svg'
import DownArrowIcon from '../../../assets/icons/arrow-down.svg'
import BookingIcon from '../../../assets/icons/booking.svg'
import DashboardIcon from '../../../assets/icons/dashboard.svg'
import DataCorrectionIcon from '../../../assets/icons/data-correction.svg'
import NewDocumentsIcon from '../../../assets/icons/documents.svg'
import HotListIcon from '../../../assets/icons/hot-list.svg'
import LateAMSIcon from '../../../assets/icons/late-ams.svg'
import MissingNRAIcon from '../../../assets/icons/nra.svg'
import NewRemarksIcon from '../../../assets/icons/remarks.svg'
import UnconfirmedIcon from '../../../assets/icons/unconfirmed-booking.svg'
import { EditOrganization } from '../../../components/Form/Controls/Organization'
import { GlobalTopTabItems, TabItem } from '../../../components/Tabs'
import { DataCorrectionShipments } from '../../../components/Widgets/DataCorrectionShipments'
import { HotListShipments } from '../../../components/Widgets/HotListShipments'
import { LateACIFilings } from '../../../components/Widgets/LateACIFilings'
import { LateAMSFilings } from '../../../components/Widgets/LateAMSFilings'
import { MissingNRAShipments } from '../../../components/Widgets/MissingNRAShipments'
import { NewDocuments } from '../../../components/Widgets/NewDocuments'
import { NewRemarks } from '../../../components/Widgets/NewRemarks'
import { OpenShipments } from '../../../components/Widgets/OpenShipments'
import { PreAlerts } from '../../../components/Widgets/PreAlerts'
import { UnconfirmedBookings } from '../../../components/Widgets/UnconfirmedBookings'
import { DefaultLayout } from '../../../layouts/DefaultLayout'
import { APIHostname, HasRole, LogOff } from '../../../Profile'
import styles from './Dashboard.module.scss'

export const Dashboard = () => {
	const location = useLocation()

	const urlParams = new URLSearchParams(location.search)
	const consigneeStart: number[] = JSON.parse(
		urlParams.get('consignees') ?? '[]'
	)
	const [consignees, setConsignees] = useState(consigneeStart)
	const [loaded, setLoaded] = useState(false)
	const [dataCorrection, setDataCorrection]: any = useState([])
	const [hotlist, setHotList] = useState([])
	const [lateACIFilings, setLateACIFilings]: any = useState([])
	const [lateAMSFilings, setLateAMSFilings]: any = useState([])
	const [missingNRA, setMissingNRA]: any = useState([])
	const [newDocuments, setNewDocuments]: any = useState([])
	const [newRemarks, setNewRemarks]: any = useState([])
	const [unconfirmedBookings, setUnconfirmedBookings]: any = useState([])
	const [preAlertCount, setPreAlertCount] = useState(0)
	const [openShipmentCount, setOpenShipmentCount] = useState(0)
	const [errorMessage, setErrorMessage] = useState(<></>)
	const initialRedirectMessage = sessionStorage['redirect-message']
	const [redirectMessage] = useState(initialRedirectMessage)

	useEffect(() => {
		axios({
			data: {},
			headers: {
				Authorization: `Bearer ${localStorage['token']}`,
			},
			method: 'GET',
			url: `${APIHostname}/api/v1/dashboard?consignees=[${consignees.join(
				','
			)}]`,
		})
			.then((response: any) => {
				setErrorMessage(<></>)
				setDataCorrection(response.data.data_correction)
				setHotList(response.data.hot_list_shipments)
				setLateACIFilings(response.data.late_aci_filings)
				setLateAMSFilings(response.data.late_ams_filings)
				setMissingNRA(response.data.missing_nra)
				setNewDocuments(response.data.new_documents)
				setNewRemarks(response.data.new_remarks)
				setOpenShipmentCount(response.data.open_shipment_count)
				setPreAlertCount(response.data.pre_alert_count)
				setUnconfirmedBookings(response.data.unconfirmed_bookings)
				setLoaded(true)
			})
			.catch((error: any) => {
				switch (error?.response?.status) {
				case 401:
					LogOff('token-expired')
					return
				case 403:
					setErrorMessage(
						<>
								Error: You do not have permission to view
								shipments.
						</>
					)
					break
				default:
					if (error?.response?.data?.error) {
						setErrorMessage(
							error?.response?.data?.link ? (
								<Link to={error?.response?.data?.link}>
										Error:
									{error?.response?.data?.error}
								</Link>
							) : (
								<>{error?.response?.data?.error}</>
							)
						)
					} else {
						setErrorMessage(<>Error: Unknown error.</>)
							console.error(error) // eslint-disable-line
					}
				}
			})
	}, [location, consignees])

	const DashboardLeftMenuItems: TabItem[] = [
		{
			icon: DashboardIcon,
			requiredPermissions: ['shipment.read'],
			text: 'DASHBOARD',
			to: '/dashboard',
		},
		{
			icon: BookingIcon,
			requiredPermissions: ['shipment.read'],
			text: 'ALL SHIPMENTS',
			to: '/shipments',
		},
	]
	if (HasRole('administrator', 'partner', 'memberpartner')) {
		DashboardLeftMenuItems.push({
			count: dataCorrection?.length ?? null,
			icon: DataCorrectionIcon,
			requiredPermissions: ['shipment.read'],
			text: 'DATA CORRECTION',
			to: '/shipments/?filters=[{%22field%22:%22data_correction%22,%22operator%22:%22eq%22,%22value%22:%22true%22}]',
		})
	}
	DashboardLeftMenuItems.push({
		count: hotlist?.length ?? null,
		icon: HotListIcon,
		requiredPermissions: ['shipment.read'],
		text: 'HOT LIST',
		to: '/shipments/?filters=[{%22field%22:%22hotlist%22,%22operator%22:%22eq%22,%22value%22:%22true%22}]',
	})

	if (HasRole('administrator', 'partner', 'memberpartner')) {
		DashboardLeftMenuItems.push({
			count: lateACIFilings?.length ?? null,
			icon: LateACIIcon,
			requiredPermissions: ['shipment.read'],
			text: 'LATE ACI',
			to: '/shipments/?filters=[{%22field%22:%22late_aci%22,%22operator%22:%22eq%22,%22value%22:%22true%22}]',
		})
		DashboardLeftMenuItems.push({
			count: lateAMSFilings?.length ?? null,
			icon: LateAMSIcon,
			requiredPermissions: ['shipment.read'],
			text: 'LATE AMS',
			to: '/shipments/?filters=[{%22field%22:%22late_ams%22,%22operator%22:%22eq%22,%22value%22:%22true%22}]',
		})
		DashboardLeftMenuItems.push({
			count: missingNRA?.length ?? null,
			icon: MissingNRAIcon,
			requiredPermissions: ['shipment.read'],
			text: 'MISSING NRA',
			to: '/shipments/?filters=[{%22field%22:%22missing_nra%22,%22operator%22:%22eq%22,%22value%22:%22true%22}]',
		})
	}
	DashboardLeftMenuItems.push({
		count: newDocuments?.length ?? null,
		icon: NewDocumentsIcon,
		requiredPermissions: ['shipment.read'],
		text: 'NEW DOCUMENTS',
		to: '/shipments/?filters=[{%22field%22:%22new_documents%22,%22operator%22:%22eq%22,%22value%22:%22true%22}]&linkSuffix=/documents',
	})
	DashboardLeftMenuItems.push({
		count: newRemarks?.length ?? null,
		icon: NewRemarksIcon,
		requiredPermissions: ['shipment.read'],
		text: 'NEW REMARKS',
		to: '/shipments/?filters=[{%22field%22:%22new_remarks%22,%22operator%22:%22eq%22,%22value%22:%22true%22}]&linkSuffix=/remarks',
	})
	DashboardLeftMenuItems.push({
		count: unconfirmedBookings?.length ?? null,
		icon: UnconfirmedIcon,
		requiredPermissions: ['shipment.read'],
		text: 'UNCONFIRMED',
		to: '/shipments/?filters=[{%22field%22:%22unconfirmed%22,%22operator%22:%22eq%22,%22value%22:%22true%22}]',
	})

	if (!loaded) {
		if (errorMessage) {
			return (
				<DefaultLayout
					bottomTabItems={DashboardBottomTabItems}
					leftMenuItems={DashboardLeftMenuItems}
					showPrint={true}
					tabMenuItems={DashboardTabMenuItems}
					topTabItems={GlobalTopTabItems}
				>
					<div className={styles.dashboardContainer}>
						<div className={styles.redirectMessage}>
							{redirectMessage}
						</div>
						<div className={styles.errorMessage}>
							{errorMessage}
						</div>
					</div>
				</DefaultLayout>
			)
		}

		return <div className="loading">Loading...</div>
	}

	sessionStorage.removeItem('redirect-message')
	return (
		<DefaultLayout
			bottomTabItems={DashboardBottomTabItems}
			leftMenuItems={DashboardLeftMenuItems}
			showPrint={true}
			tabMenuItems={DashboardTabMenuItems}
			topTabItems={GlobalTopTabItems}
		>
			<div className={styles.redirectMessage}>{redirectMessage}</div>
			<div className={styles.errorMessage}>{errorMessage}</div>
			<div className={styles.dashboardContainer}>
				<div className="widgetsContainer">
					{HasRole(
						'administrator',
						'partner',
						'memberpartner',
						'nonmemberpartner'
					) ? (
							<div className={styles.consignees}>
								<EditOrganization
									data={{}}
									field={{
										Label: 'FILTER CONSIGNEES',
										Multiple: true,
										Property: 'consignees',
									}}
									isSearch={false}
									onChange={() => {
										const bulks = document.querySelectorAll(
											'input[type=\'checkbox\'][id^=\'multiple\']:checked'
										)
										const ids: number[] = []
										bulks.forEach((element: any) => {
											const idParts = element.id.split('_')
											ids.push(
												parseInt(
													idParts[idParts.length - 1]
												)
											)
										})

										setConsignees(ids)
										window.history.replaceState(
											{},
											'',
											ids.length > 0
												? `/dashboard?consignees=[${ids.join(
													','
											  )}]`
												: '/dashboard'
										)
									}}
									value={consignees.join(',')}
								/>
							</div>
						) : (
							<></>
						)}

					{HasRole('administrator', 'partner', 'memberpartner') ? (
						<>
							<div className="widget">
								<LateAMSFilings
									lateAMSFilings={lateAMSFilings}
								/>
							</div>
							<div className="widget">
								<LateACIFilings
									lateACIFilings={lateACIFilings}
								/>
							</div>
						</>
					) : (
						<></>
					)}
					<div className="widget">
						<NewRemarks newRemarks={newRemarks} />
					</div>
					<div className="widget">
						<NewDocuments newDocuments={newDocuments} />
					</div>
					<div className="widget">
						<UnconfirmedBookings
							unconfirmedBookings={unconfirmedBookings}
						/>
					</div>
					<div className="widget">
						<HotListShipments hotlist={hotlist} />
					</div>
					{HasRole('administrator', 'partner', 'memberpartner') ? (
						<>
							<div className="widget">
								<MissingNRAShipments missingNRA={missingNRA} />
							</div>
							<div className="widget">
								<DataCorrectionShipments
									data_correction={dataCorrection}
								/>
							</div>
						</>
					) : (
						<></>
					)}
				</div>
				<div className="kpisContainer">
					<PreAlerts preAlertCount={preAlertCount} />
					<OpenShipments openShipmentCount={openShipmentCount} />
				</div>
			</div>
		</DefaultLayout>
	)
}

export const DashboardTabMenuItems = []

export const DashboardBottomTabItems = [
	{
		icon: DownArrowIcon,
		text: 'WELCOME TO GOAL',
		to: '/dashboard',
	},
]
