import Control from '../Control'
import { Layout } from '../Layout'

export type ArrivalNotice = {
	booking_number: string
	consignee: number
	consignee_name: string
	data_correction: boolean
	days_since_oldest_remark: number
	days_until_last_port: number
	destination_partner_reference: string
	etd: string
	override_hbl: string
	hbl_carrier_scac: string
	hotlist: boolean
	id: number
	lock: boolean
	override_mbl: string
	mbl_carrier_scac: string
	origin_partner_reference: string
	override_reference: string
}

export const ArrivalNoticeLayout: Layout = {
	Groups: [
		{
			Border: true,
			Direction: 'column',
			Groups: [
				{
					Direction: 'row',
					Flex: '1 1 0%',
					Groups: [
						{
							RequiredRows: [
								{
									Fields: [
										{
											HardRequired: true,
											Label: 'IT NUMBER',
											Property: 'it_number',
											Width: 50,
										},
										{
											Control: Control.Date,
											HardRequired: true,
											Label: 'IT DATE',
											Property: 'it_date',
											Width: 50,
										},
									],
								},
								{
									Fields: [
										{
											Control: Control.Port,
											HardRequired: true,
											Label: 'IT PLACE OF ISSUE',
											Property: 'it_place_of_issue',
											Width: 50,
										},
										{
											Control: Control.TextBox,
											HardRequired: true,
											Label: 'CARGO LOCATION',
											Property: 'cargo_location',
											Width: 50,
										},
									],
								},
								{
									Fields: [
										{
											Control: Control.Boolean,
											Label: 'ENDORSED ORIGINAL REQUIRED',
											Property:
												'endorsed_original_required',
											Width: 50,
										},
									],
								},
							],
							Width: 50,
						},
						{
							Flex: '1 1 0%',
							RequiredRows: [
								{
									Fields: [
										{
											Control: Control.TextBox,
											Label: 'NOTES',
											Property: 'arrival_notice_notes',
											Width: 100,
										},
									],
								},
								{
									Fields: [
										{
											Control: Control.TextBox,
											Label: 'CARGO DESCRIPTION',
											Property: 'cargo_description',
											Width: 100,
										},
									],
								},
							],
							Width: 50,
						},
					],
				},
			],
			Width: 100,
		},
		{
			Label: 'CHARGES',
			LabelBackground: 'blue',
			Padding: '0',
			RequiredRows: [
				{
					Fields: [
						{
							Control: Control.Charges,
							Property: 'charges',
							Width: 100,
						},
					],
				},
			],

			Width: 100,
		},
		{
			Groups: [
				{
					Border: true,
					RequiredRows: [
						{
							Fields: [
								{
									Label: 'FILENAME',
									Property: 'filename',
									Width: 25,
								},
								{
									Label: '',
									Property: '',
									Width: 25,
								},
								{
									Label: '',
									Property: '',
									Width: 25,
								},
								{
									Label: '',
									Property: '',
									Width: 25,
								},
							],
						},
						{
							Fields: [
								{
									Control: Control.Checkbox,
									Label: 'SAVE TO DOCUMENTS',
									Property: 'save',
									Width: 100,
								},
							],
						},
					],
					Width: 100,
				},
			],
			Width: 100,
		},
	],
}
