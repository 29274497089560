import { Column } from '../../types/Column'
import styles from './CSVEditor.module.scss'

export const CSVEditor = (props: CSVEditorProps) => {
	let value = ''
	const lastField = props.columns.length - 1
	props.data?.forEach((row: any, index: number) => {
		if (index > 0) {
			value += '\n'
		}
		props.columns?.forEach((column: Column, fieldIndex: number) => {
			const fieldValue = row[column.id ?? '']?.toString() ?? ''
			const escapeChar =
				fieldValue.indexOf(',') > -1 ||
				fieldValue.indexOf('\n') > -1 ||
				fieldValue.indexOf('"') > -1
					? '"'
					: ''
			value += `${escapeChar}${fieldValue.replaceAll(
				'"',
				'""'
			)}${escapeChar}${fieldIndex < lastField ? ',' : ''}`
		})
	})

	return (
		<div className={styles.formContainer}>
			<div className={styles.directions}>
				The following form is used to edit {props.title} using
				Comma-Separated Values (CSV). Edit content below or copy it into
				Excel for easier bulk editing.
			</div>
			<div className={styles.columnHeaders}>
				{props.columns?.map((column: Column, fieldIndex: number) => (
					<span key={`field${fieldIndex}`}>
						{column.title}
						{fieldIndex < lastField ? ', ' : ''}
					</span>
				))}
			</div>
			<textarea
				defaultValue={value}
				name={props.name}
				title="VALUES"
			></textarea>
		</div>
	)
}

export type CSVEditorProps = {
	columns: Column[]
	name: string
	data: any[]
	title: string
}
