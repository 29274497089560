import axios from 'axios'
import { useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'

import AgentAccessIcon from '../../../../../assets/icons/agent-access.svg'
import DownArrowIcon from '../../../../../assets/icons/arrow-down.svg'
import DocumentsIcon from '../../../../../assets/icons/documents.svg'
import OrganizationHistoryIcon from '../../../../../assets/icons/organization-history.svg'
import OrganizationInfoIcon from '../../../../../assets/icons/organization-info.svg'
import OrganizationUsersIcon from '../../../../../assets/icons/organization-users.svg'
import ShipmentsIcon from '../../../../../assets/icons/shipments.svg'
import { NavTable } from '../../../../../components/NavTable'
import { OrganizationSummary } from '../../../../../components/OrganizationSummary'
import { GlobalTopTabItems } from '../../../../../components/Tabs'
import { DefaultLayout } from '../../../../../layouts/DefaultLayout'
import { APIHostname, LogOff } from '../../../../../Profile'
import Control from '../../../../../types/Control'
import { EscapeURIParameters } from '../../../../../Utils'
import styles from './OrganizationHistory.module.scss'

export const OrganizationHistory = () => {
	const { id } = useParams()
	const [history, setHistory]: any = useState(null)
	const [historyCount, setHistoryCount] = useState(0)
	const [errorMessage, setErrorMessage] = useState(<></>)
	const [organizationLoaded, setOrganizationLoaded]: any = useState(false)
	const [historyLoaded, setHistoryLoaded]: any = useState(false)
	const [organization, setOrganization]: any = useState(null)
	const initialRedirectMessage = sessionStorage['redirect-message']
	const [redirectMessage] = useState(initialRedirectMessage)

	// Vary based on query string.
	const location = useLocation()
	const urlParams = new URLSearchParams(location.search)
	if (!urlParams.get('limit')) {
		urlParams.set('limit', '25')
	}

	useEffect(() => {
		axios({
			data: {},
			headers: {
				Authorization: `Bearer ${localStorage['token']}`,
			},
			method: 'GET',
			url: `${APIHostname}/api/v1/organizations/${id}${location.search}`,
		})
			.then((response: any) => {
				setErrorMessage(<></>)
				setOrganization(response.data)
				setOrganizationLoaded(true)
			})
			.catch((error: any) => {
				switch (error?.response?.status) {
				case 401:
					LogOff('token-expired')
					return
				case 403:
					setErrorMessage(
						<>
								Error: You do not have permission to view
								organizations.
						</>
					)
					break
				default:
					if (error?.response?.data?.error) {
						setErrorMessage(
							error?.response?.data?.link ? (
								<Link to={error?.response?.data?.link}>
										Error:
									{error?.response?.data?.error}
								</Link>
							) : (
								<>{error?.response?.data?.error}</>
							)
						)
					} else {
						setErrorMessage(<>Error: Unknown error.</>)
							console.error(error) // eslint-disable-line
					}
				}
			})

		axios({
			data: {},
			headers: {
				Authorization: `Bearer ${localStorage['token']}`,
			},
			method: 'GET',
			url: `${APIHostname}/api/v1/organizations/${id}/history?${EscapeURIParameters(
				urlParams.toString()
			)}`,
		})
			.then((response: any) => {
				setErrorMessage(<></>)
				setHistory(response.data.historyRecords)
				setHistoryCount(response.data.historyRecord_count)
				setHistoryLoaded(true)
			})
			.catch((error: any) => {
				switch (error?.response?.status) {
				case 401:
					LogOff('token-expired')
					return
				case 403:
					setErrorMessage(
						<>
								Error: You do not have permission to view
								organizations.
						</>
					)
					break
				default:
					if (error?.response?.data?.error) {
						setErrorMessage(
							error?.response?.data?.link ? (
								<Link to={error?.response?.data?.link}>
										Error:
									{error?.response?.data?.error}
								</Link>
							) : (
								<>{error?.response?.data?.error}</>
							)
						)
					} else {
						setErrorMessage(<>Error: Unknown error.</>)
							console.error(error) // eslint-disable-line
					}
				}
			})
	}, [location])

	const OrganizationLeftMenuItems = [
		{
			icon: OrganizationInfoIcon,
			requiredPermissions: ['organization.read'],
			text: 'ORGANIZATION INFO',
			to: `/organizations/${id}`,
		},
		{
			icon: DocumentsIcon,
			requiredPermissions: ['shipment.read'],
			text: 'ORGANIZATION DOCUMENTS',
			to: `/organizations/${id}/documents`,
		},
		{
			icon: ShipmentsIcon,
			requiredPermissions: ['shipment.read'],
			text: 'LINKED SHIPMENTS',
			to: `/organizations/${id}/shipments`,
		},
		{
			icon: OrganizationUsersIcon,
			requiredPermissions: ['user.read'],
			text: 'ORGANIZATION USERS',
			to: `/organizations/${id}/users`,
		},
		{
			icon: AgentAccessIcon,
			requiredPermissions: ['organization.manageaccess'],
			text: 'PARTNER ACCESS',
			to: `/organizations/${id}/partner_access`,
		},
		{
			icon: OrganizationHistoryIcon,
			requiredPermissions: ['organization.read'],
			text: 'ORGANIZATION HISTORY',
			to: `/organizations/${id}/history`,
		},
	]

	const OrganizationHistoryBottomTabItems = [
		{
			icon: DownArrowIcon,
			text: `ORGANIZATION #${id} HISTORY`,
			to: `/organizations/${id}/history`,
		},
	]

	const OrganizationTabMenuItems: [] = []

	if (!organizationLoaded || !historyLoaded) {
		if (errorMessage) {
			return (
				<DefaultLayout
					bottomTabItems={OrganizationHistoryBottomTabItems}
					leftMenuItems={OrganizationLeftMenuItems}
					showPrint={true}
					tabMenuItems={OrganizationTabMenuItems}
					topTabItems={GlobalTopTabItems}
				>
					<div className={styles.organizationHistoryContainer}>
						<div className={styles.redirectMessage}>
							{redirectMessage}
						</div>
						<div className={styles.errorMessage}>
							{errorMessage}
						</div>
					</div>
				</DefaultLayout>
			)
		}

		return <div className="loading">Loading...</div>
	}

	sessionStorage.removeItem('redirect-message')
	return (
		<DefaultLayout
			bottomTabItems={OrganizationHistoryBottomTabItems}
			leftMenuItems={OrganizationLeftMenuItems}
			showPrint={true}
			tabMenuItems={OrganizationTabMenuItems}
			topTabItems={GlobalTopTabItems}
		>
			<div className={styles.organizationHistoryContainer}>
				<div className={styles.redirectMessage}>{redirectMessage}</div>
				<div className={styles.errorMessage}>
					{errorMessage}{' '}
					{!organization?.active ? (
						<>This organization has been deleted.</>
					) : (
						<></>
					)}
				</div>

				<OrganizationSummary organization={organization} />

				<div className={styles.navTable}>
					<NavTable
						columns={[
							{
								control: Control.Hyperlink,
								id: 'link',
								title: 'Link',
							},
							{
								id: 'version',
								title: 'Version',
							},
							{
								id: 'action',
								title: 'Action',
							},
							{
								id: 'element',
								title: 'Element',
							},
							{
								id: 'original',
								title: 'Original Value',
							},
							{
								id: 'new',
								title: 'New Value',
							},
							{
								control: Control.User,
								id: 'created_by',
								title: 'USER',
							},
							{
								control: Control.DateTime,
								id: 'created',
								title: 'Date/Time',
							},
						]}
						count={historyCount}
						data={history}
						filters={JSON.parse(urlParams.get('filters') ?? '[]')}
						includeDelete={false}
						primaryDisplayColumn=""
						primaryKey=""
					/>
				</div>
			</div>
		</DefaultLayout>
	)
}
