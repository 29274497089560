import axios from 'axios'
import { useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'

import AgentAccessIcon from '../../../../../../assets/icons/agent-access.svg'
import DownArrowIcon from '../../../../../../assets/icons/arrow-down.svg'
import ChainIcon from '../../../../../../assets/icons/chain.svg'
import DocumentsIcon from '../../../../../../assets/icons/documents.svg'
import PayBalanceIcon from '../../../../../../assets/icons/goal-pay-balance.svg'
import PayRequestMoneyIcon from '../../../../../../assets/icons/goal-pay-request-money.svg'
import PaySendMoneyIcon from '../../../../../../assets/icons/goal-pay-send-money.svg'
import PayTransactionsIcon from '../../../../../../assets/icons/goal-pay-transactions.svg'
import PayIncomingRequestsIcon from '../../../../../../assets/icons/goal-pay-transfer-money.svg'
import PartnerHistoryIcon from '../../../../../../assets/icons/organization-history.svg'
import PartnerInfoIcon from '../../../../../../assets/icons/organization-info.svg'
import PartnerUsersIcon from '../../../../../../assets/icons/organization-users.svg'
import {
	EditForm,
	ParsedFormData,
} from '../../../../../../components/Form/EditForm'
import { LeftMenuItem } from '../../../../../../components/LeftMenu'
import { PartnerSummary } from '../../../../../../components/PartnerSummary'
import { GlobalTopTabItems } from '../../../../../../components/Tabs'
import { DefaultLayout } from '../../../../../../layouts/DefaultLayout'
import {
	APIHostname,
	HasPermission,
	HasRole,
	LogOff,
} from '../../../../../../Profile'
import { NewUserLayout } from '../../../../../../types/data/User'
import styles from './UsersNew.module.scss'

export const PartnerUsersNew = () => {
	const { id } = useParams()

	const [errorMessage, setErrorMessage] = useState(<></>)
	const initialRedirectMessage = sessionStorage['redirect-message']
	const [redirectMessage] = useState(initialRedirectMessage)

	const location = useLocation()
	const [partner, setPartner]: any = useState(null)
	const [partnerLoaded, setPartnerLoaded] = useState(false)
	const [payRequestCount, setPayRequestCount] = useState(0)
	const [countsLoaded, setCountsLoaded] = useState(false)
	const [partnerName, setPartnerName] = useState('()')
	const [partnerPreview, setPartnerPreview] = useState('')

	useEffect(() => {
		axios({
			data: {},
			headers: {
				Authorization: `Bearer ${localStorage['token']}`,
			},
			method: 'GET',
			url: `${APIHostname}/api/v1/partners/${id}`,
		})
			.then((response: any) => {
				setErrorMessage(<></>)
				setPartner(response.data)
				setPartnerName(response.data.name)
				setPartnerPreview(response.data.preview)
				setPartnerLoaded(true)

				axios({
					data: {},
					headers: {
						Authorization: `Bearer ${localStorage['token']}`,
					},
					method: 'GET',
					url: `${APIHostname}/api/v1/partners/${id}/pay_counts`,
				})
					.then((response: any) => {
						setErrorMessage(<></>)
						setPayRequestCount(response.data.pay_request_count)
						setCountsLoaded(true)
					})
					.catch((error: any) => {
						switch (error?.response?.status) {
						case 401:
							LogOff('token-expired')
							return
						case 403:
							setCountsLoaded(true)
							setErrorMessage(
								<>
										Error: You do not have permission to
										view GOAL pay requests.
								</>
							)
							break
						case 404:
							setCountsLoaded(true)
							break
						default:
							if (error?.response?.data?.error) {
								setErrorMessage(
									error?.response?.data?.link ? (
										<Link
											to={error?.response?.data?.link}
										>
												Error:
											{error?.response?.data?.error}
										</Link>
									) : (
										<>{error?.response?.data?.error}</>
									)
								)
							} else {
								setErrorMessage(<>Error: Unknown error.</>)
									console.error(error) // eslint-disable-line
							}
						}
					})
			})
			.catch((error: any) => {
				switch (error?.response?.status) {
				case 401:
					LogOff('token-expired')
					return
				case 403:
					setErrorMessage(
						<>
								Error: You do not have permission to view
								organizations.
						</>
					)
					break
				case 404:
					setPartnerName('()')
					setPartnerPreview('')
					setPartnerLoaded(true)
					break
				default:
					if (error?.response?.data?.error) {
						setErrorMessage(
							error?.response?.data?.link ? (
								<Link to={error?.response?.data?.link}>
										Error:
									{error?.response?.data?.error}
								</Link>
							) : (
								<>{error?.response?.data?.error}</>
							)
						)
					} else {
						setErrorMessage(<>Error: Unknown error.</>)
							console.error(error) // eslint-disable-line
					}
				}
			})
	}, [location])

	if (!partnerLoaded || !countsLoaded) {
		return <div className="loading">Loading...</div>
	}

	let css = <></>
	if (id) {
		css = (
			<style>
				{`
				.role, .organization, .partner {
					display: none;
				}
			`}
			</style>
		)
	}

	const PartnerNewUsersLeftMenuItems: LeftMenuItem[] = [
		{
			icon: PartnerInfoIcon,
			requiredPermissions: ['partner.read'],
			text: 'PARTNER INFO',
			to: `/partners/${id}`,
		},
		{
			icon: AgentAccessIcon,
			requiredPermissions: ['organization.manageaccess'],
			text: 'ORGANIZATION ACCESS',
			to: `/partners/${id}/organization_access`,
		},
	]

	if (!partner?.goal_member) {
		PartnerNewUsersLeftMenuItems.push({
			icon: AgentAccessIcon,
			requiredPermissions: ['partner.manageaccess'],
			requiredRole: 'administrator',
			text: 'PARTNER ACCESS',
			to: `/partners/${id}/partner_access`,
		})
	}
	PartnerNewUsersLeftMenuItems.push({
		icon: DocumentsIcon,
		requiredPermissions: ['partner.read'],
		text: 'PARTNER DOCUMENTS',
		to: `/partners/${id}/documents`,
	})
	PartnerNewUsersLeftMenuItems.push({
		icon: ChainIcon,
		requiredPermissions: ['shipment.read'],
		text: 'LINKED SHIPMENTS',
		to: `/partners/${id}/shipments`,
	})
	PartnerNewUsersLeftMenuItems.push({
		icon: PartnerUsersIcon,
		requiredPermissions: ['user.read'],
		text: 'PARTNER USERS',
		to: `/partners/${id}/users`,
	})
	PartnerNewUsersLeftMenuItems.push({
		icon: PartnerHistoryIcon,
		requiredPermissions: ['partner.read'],
		text: 'PARTNER HISTORY',
		to: `/partners/${id}/history`,
	})

	if (HasRole('administrator')) {
		if (
			HasPermission('pay', 'viewbalance') ||
			HasPermission('pay', 'sendmoney') ||
			HasPermission('pay', 'requestmoney')
		) {
			PartnerNewUsersLeftMenuItems.push(
				{
					disabled: true,
					requiredPermissions: [],
					text: 'ADMINISTRATOR OPTIONS',
					to: '',
				},
				{
					icon: PayBalanceIcon,
					requiredPermissions: ['pay.viewbalance'],
					text: 'BALANCE',
					to: `/partners/${id}/pay/balance`,
				},
				{
					icon: PaySendMoneyIcon,
					requiredPermissions: ['pay.sendmoney'],
					requiredRole: 'administrator',
					text: 'DEPOSIT MONEY',
					to: `/partners/${id}/pay/deposit`,
				},
				{
					icon: PayRequestMoneyIcon,
					requiredPermissions: ['pay.requestmoney'],
					requiredRole: 'administrator',
					text: 'WITHDRAW MONEY',
					to: `/partners/${id}/pay/withdraw`,
				},
				{
					icon: PayRequestMoneyIcon,
					requiredPermissions: ['pay.requestmoney'],
					text: 'REQUEST MONEY',
					to: `/partners/${id}/pay/request`,
				},
				{
					icon: PaySendMoneyIcon,
					requiredPermissions: ['pay.sendmoney'],
					text: 'SEND MONEY',
					to: `/partners/${id}/pay/send`,
				},
				{
					count: payRequestCount,
					icon: PayIncomingRequestsIcon,
					requiredPermissions: ['pay.sendmoney'],
					text: 'INCOMING PAYMENT REQUESTS',
					to: `/partners/${id}/pay/incoming_requests`,
				},
				{
					icon: PayTransactionsIcon,
					requiredPermissions: ['pay.viewbalance'],
					text: 'TRANSACTIONS',
					to: `/partners/${id}/pay/transactions`,
				}
			)
		}
	}

	const NewUsersBottomTabItems = [
		{
			icon: DownArrowIcon,
			text: 'NEW USER',
			to: `/partners/${id}/users/new`,
		},
	]

	/* eslint-disable @typescript-eslint/ban-types */
	sessionStorage.removeItem('redirect-message')
	return (
		<DefaultLayout
			bottomTabItems={NewUsersBottomTabItems}
			leftMenuItems={PartnerNewUsersLeftMenuItems}
			showPrint={true}
			tabMenuItems={NewUsersTabMenuItems}
			topTabItems={GlobalTopTabItems}
		>
			{css}
			<div className={styles.userContainer}>
				<PartnerSummary partner={partner} />

				<div className={styles.redirectMessage}>{redirectMessage}</div>
				<div className={styles.errorMessage}>
					{errorMessage}{' '}
					{!partner?.active ? (
						<>This partner has been deleted.</>
					) : (
						<></>
					)}
				</div>

				<EditForm
					data={{
						organization: '-1',
						organization_name: '()',
						organization_preview: '',
						partner: id,
						partner_name: partnerName,
						partner_preview: partnerPreview,
						permissions: {
							'arrival_notice.read': true,
							'carrier.read': true,
							'nra.read': true,
							'organization.read': true,
							'partner.read': true,
							'report.read': true,
							'shipment.read': true,
							'user.read': true,
							'user.updateself': true,
						},
						role: 'partner',
					}}
					layout={NewUserLayout}
					name="user"
					onSubmit={async (formData: FormData) => {
						const payload = await ParsedFormData(formData, [])
						payload.permissions = {}
						const permissions = document.querySelectorAll(
							'input[type=checkbox]'
						)
						permissions.forEach((permission: Element) => {
							const permissionBox = permission as HTMLInputElement
							if (permissionBox.name.indexOf('.') > -1) {
								if (payload[permissionBox.name] == true) {
									payload.permissions[permissionBox.name] =
										true
								}
								delete payload[permissionBox.name]
							}
						})
						axios({
							data: payload,
							headers: {
								Authorization: `Bearer ${localStorage['token']}`,
								'Content-Type': 'application/json',
							},
							method: 'POST',
							url: `${APIHostname}/api/v1/users`,
						})
							.then((response: any) => {
								sessionStorage[
									'redirect-message'
								] = `User #${response.data.user_id} created.`
								window.history.replaceState(
									{},
									'',
									`/users/${response.data.user_id}`
								)
								document.location.reload()
							})
							.catch((error: any) => {
								switch (error?.response?.status) {
								case 401:
									LogOff('token-expired')
									return
								case 403:
									setErrorMessage(
										<>
												Error: You do not have
												permission to edit users.
										</>
									)
									return
								default:
									if (error?.response?.data?.error) {
										setErrorMessage(
											error?.response?.data?.link ? (
												<Link
													to={
														error?.response
															?.data?.link
													}
												>
														Error:
													{
														error?.response
															?.data?.error
													}
												</Link>
											) : (
												<>
													{
														error?.response
															?.data?.error
													}
												</>
											)
										)
									} else {
										setErrorMessage(
											<>Error: Unknown error.</>
										)
											console.error(error) // eslint-disable-line
									}
									return
								}
							})
					}}
					previewAPIData={async (formData: FormData) => {
						const payload = await ParsedFormData(formData, [])
						payload.permissions = {}
						const permissions = document.querySelectorAll(
							'input[type=checkbox]'
						)
						permissions.forEach((permission: Element) => {
							const permissionBox = permission as HTMLInputElement
							if (permissionBox.name.indexOf('.') > -1) {
								if (payload[permissionBox.name] == true) {
									payload.permissions[permissionBox.name] =
										true
								}
								delete payload[permissionBox.name]
							}
						})

						return {
							data: JSON.stringify(payload, null, 4),
							method: 'POST',
							url: `${APIHostname}/api/v1/users`,
						}
					}}
					readOnly={
						!HasPermission('user', 'update') || !partner?.active
					}
				/>
			</div>
		</DefaultLayout>
	)
}

export const NewUsersTabMenuItems = []
