import { FocusEvent, ReactElement, useState } from 'react'

import AddIcon from '../../../../assets/icons/add.svg'
import DeleteIcon from '../../../../assets/icons/delete.svg'
import { EditField, FieldProps } from '../../Field'
import { flattenArrays } from '../NRAsToGOAL'
import styles from './FreightRates.module.scss'

export const EditFreightRates = (props: FieldProps) => {
	const [freightrateCount, setFreightRateCount] = useState(
		props.data?.freight_rates?.length ? props.data.freight_rates?.length : 0
	)

	const rows: ReactElement[] = []
	for (let i = 0; i < freightrateCount; i++) {
		rows.push(
			<div
				className={styles.row}
				id={`${props.field.Property}_${i}`}
				key={`freight_rates${i}`}
			>
				<input
					data-number={true}
					defaultValue={
						props.data?.freight_rates
							? props.data?.freight_rates[i]?.id
							: ''
					}
					id={`${props.field.Property}_${i}_id`}
					name={`${props.field.Property}_${i}_id`}
					type="hidden"
				/>
				<input
					data-number={true}
					defaultValue={
						props.data?.freight_rates
							? props.data?.freight_rates[i]?.shipment
							: ''
					}
					id={`${props.field.Property}_${i}_shipment`}
					name={`${props.field.Property}_${i}_shipment`}
					type="hidden"
				/>
				<input
					data-boolean={true}
					defaultValue={'true'}
					id={`${props.field.Property}_${i}_active`}
					name={`${props.field.Property}_${i}_active`}
					type="hidden"
				/>
				<div
					className={styles.row}
					id={`${props.field.Property}_${i}_contents`}
				>
					<div className={styles.deleteColumn}>
						<div>{i + 1}</div>
						<img
							className={styles.delete}
							onClick={() => {
								// eslint-disable-next-line no-alert
								if (confirm('Really delete freightrate?')) {
									(
										document.getElementById(
											`${props.field.Property}_${i}_active`
										) as HTMLInputElement
									).value = 'false'
									;(
										document.getElementById(
											`${props.field.Property}_${i}`
										) as HTMLInputElement
									).style.display = 'none'
									;(
										document.getElementById(
											`${props.field.Property}_${i}_contents`
										) as HTMLInputElement
									).innerHTML = ''

									if (props.onChange) {
										props.onChange(null)
									}
								}
							}}
							src={DeleteIcon}
							title="DELETE ROW"
						/>{' '}
					</div>
					<div className={styles.inputColumn}>
						<div className={styles.row}>
							<div className={styles.oneTenth}>
								<EditField
									data={flattenArrays(props.data, '') ?? {}}
									field={{
										HardRequired: true,
										Label: 'TEXT',
										Property: `${props.field.Property}_${i}_subject`,
									}}
									isSearch={props.isSearch}
									onChange={props.onChange}
									readOnly={
										props.readOnly || props.field.ReadOnly
									}
									value={
										props.data?.freight_rates
											? props.data?.freight_rates[i]
												?.subject
											: ''
									}
								/>
							</div>
							{!props.isSearch ? (
								<>
									<div className={styles.oneTenth}>
										<div className={styles.label}>
											PREPAID:{' '}
											<span
												className="hardRequired"
												title="REQUIRED"
											>
												*
											</span>
										</div>
										<input
											className={`hardRequired ${
												!props.data?.freight_rates ||
												!props.data?.freight_rates[i]
													.prepaid
													? 'blank'
													: ''
											}`}
											data-hardrequired={true}
											data-number={true}
											defaultValue={
												props.data?.freight_rates
													? props.data?.freight_rates[
														i
													  ]?.prepaid
													: ''
											}
											id={`${props.field.Property}_${i}_prepaid`}
											name={`${props.field.Property}_${i}_prepaid`}
											onChange={(e: any) => {
												if (e.target.value) {
													e.target.classList.remove(
														'blank'
													)
												} else {
													e.target.classList.add(
														'blank'
													)
												}

												if (props.onChange) {
													props.onChange(e)
												}
											}}
											onFocus={(e: FocusEvent) => {
												if (
													!props.readOnly &&
													!props.field.ReadOnly
												)
													(
														e.target as HTMLInputElement
													).showPicker()
											}}
											readOnly={
												props.readOnly ||
												props.field.ReadOnly
											}
											step={0.01}
											title="PREPAID"
											type="number"
										/>
									</div>

									<div className={styles.oneTenth}>
										<div className={styles.label}>
											COLLECT:{' '}
											<span
												className="hardRequired"
												title="REQUIRED"
											>
												*
											</span>
										</div>
										<input
											className={`hardRequired ${
												!props.data?.freight_rates ||
												!props.data?.freight_rates[i]
													.collect
													? 'blank'
													: ''
											}`}
											data-hardrequired={true}
											data-number={true}
											defaultValue={
												props.data?.freight_rates
													? props.data?.freight_rates[
														i
													  ]?.collect
													: ''
											}
											id={`${props.field.Property}_${i}_collect`}
											name={`${props.field.Property}_${i}_collect`}
											onChange={(e: any) => {
												if (e.target.value) {
													e.target.classList.remove(
														'blank'
													)
												} else {
													e.target.classList.add(
														'blank'
													)
												}

												if (props.onChange) {
													props.onChange(e)
												}
											}}
											onFocus={(e: FocusEvent) => {
												if (
													!props.readOnly &&
													!props.field.ReadOnly
												)
													(
														e.target as HTMLInputElement
													).showPicker()
											}}
											readOnly={
												props.readOnly ||
												props.field.ReadOnly
											}
											step={0.01}
											title="COLLECT"
											type="number"
										/>
									</div>
								</>
							) : (
								<></>
							)}
						</div>
					</div>
				</div>
			</div>
		)
	}
	if (freightrateCount == 0) {
		rows.push(
			<div
				className={`${styles.row} ${styles.emptyRow}`}
				key="noFreightRates"
			>
				No freight rates.
			</div>
		)
	}

	return (
		<div
			className={`${styles.fieldOuterContainer} ${
				!props.isSearch ? styles.editForm : ''
			}`}
			id={props.field.Property ? `${props.field.Property}_container` : ''}
			key="addFreightRate"
		>
			{rows}
			{!(props.isSearch || props.readOnly) ? (
				<div className={styles.buttonRow}>
					<button type="button">
						<img
							alt="ADD FREIGHT RATE"
							onClick={() => {
								setFreightRateCount(freightrateCount + 1)
								if (props.onChange) {
									props.onChange(null)
									setTimeout(() => {
										if (props.onChange) {
											props.onChange(null)
										}
									}, 100)
									setTimeout(() => {
										if (props.onChange) {
											props.onChange(null)
										}
									}, 250)
									setTimeout(() => {
										if (props.onChange) {
											props.onChange(null)
										}
									}, 500)
									setTimeout(() => {
										if (props.onChange) {
											props.onChange(null)
										}
									}, 1000)
									setTimeout(() => {
										if (props.onChange) {
											props.onChange(null)
										}
									}, 2500)
								}
							}}
							src={AddIcon}
						/>
					</button>
				</div>
			) : (
				<></>
			)}
		</div>
	)
}
