import { useReactiveVar } from '@apollo/client'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import LateACIIcon from '../../../assets/icons/aci.png'
import ContactsIcon from '../../../assets/icons/contacts.svg'
import DocumentsIcon from '../../../assets/icons/documents.svg'
import DotsIcon from '../../../assets/icons/dots.svg'
import PayIncomingRequestsIcon from '../../../assets/icons/goal-pay-transfer-money.svg'
import HelpIcon from '../../../assets/icons/help.svg'
import InboxIcon from '../../../assets/icons/inbox.svg'
import LateAMSIcon from '../../../assets/icons/late-ams.png'
import NotificationsIcon from '../../../assets/icons/notifications.png'
import PendingUsersIcon from '../../../assets/icons/pending_users.svg'
import RemarksIcon from '../../../assets/icons/remarks.svg'
import {
	APIHostname,
	Authenticated,
	HasRole,
	LogOff,
	ProfilePartnerID,
	ProfileUserID,
} from '../../../Profile'
import styles from './GlobalMenu.module.scss'

export const GlobalMenu = () => {
	const [newRemarkCount, setNewRemarkCount] = useState(0)
	const [newDocumentCount, setNewDocumentCount] = useState(0)
	const [lateACICount, setLateACICount] = useState(0)
	const [lateAMSCount, setLateAMSCount] = useState(0)
	const [payRequestCount, setPayRequestCount] = useState(0)
	const [pendingUserCount, setPendingUserCount] = useState(0)
	const [loaded, setLoaded] = useState(false)
	const [notificationCountInterval, setNotificationCountInterval] =
		useState<any>(null)
	const userID = useReactiveVar(ProfileUserID)

	const loadNotificationCount = () => {
		if (!localStorage['token']) {
			LogOff('token-expired')

			return
		}

		axios({
			data: {},
			headers: {
				Authorization: `Bearer ${localStorage['token']}`,
			},
			method: 'GET',
			url: `${APIHostname}/api/v1/notification_counts`,
		})
			.then((response: any) => {
				setNewDocumentCount(response.data.new_documents_count)
				setNewRemarkCount(response.data.new_remarks_count)
				setLateACICount(response.data.late_aci_count)
				setLateAMSCount(response.data.late_ams_count)
				setPayRequestCount(response.data.pay_request_count)
				setPendingUserCount(response.data.pending_user_count)
				setLoaded(true)
			})
			.catch((error: any) => {
				switch (error?.response?.status) {
				case 401:
					LogOff('token-expired')
					return
				default:
						console.error(error) // eslint-disable-line
				}
			})
	}
	useEffect(loadNotificationCount, [])

	if (!Authenticated()) {
		return <header className={styles.globalMenu}></header>
	}

	if (!notificationCountInterval) {
		setNotificationCountInterval(setInterval(loadNotificationCount, 60000))
	}

	if (!loaded) {
		return <header className={styles.globalMenu}></header>
	}

	return (
		<header className={styles.globalMenu}>
			{HasRole(
				'administrator',
				'memberpartner',
				'nonmemberpartner',
				'partner'
			) ? (
					<>
						{lateAMSCount > 0 ? (
							<Link to="/shipments/?filters=[{%22field%22:%22late_ams%22,%22operator%22:%22eq%22,%22value%22:%22true%22}]">
								<img
									alt="LATE AMS"
									className={styles.withNotificationCount}
									src={LateAMSIcon}
									title="LATE AMS"
								/>{' '}
								<div
									className={styles.notificationCount}
									title="LATE AMS"
								>
									{lateAMSCount}
								</div>
							</Link>
						) : (
							<Link to="/dashboard">
								<img
									alt="NOTIFICATIONS"
									src={NotificationsIcon}
									title="NOTIFICATIONS"
								/>
							</Link>
						)}
						{lateACICount > 0 ? (
							<Link to="/shipments/?filters=[{%22field%22:%22late_aci%22,%22operator%22:%22eq%22,%22value%22:%22true%22}]">
								<img
									alt="LATE ACI"
									className={styles.withNotificationCount}
									src={LateACIIcon}
									title="LATE ACI"
								/>{' '}
								<div
									className={styles.notificationCount}
									title="LATE ACI"
								>
									{lateACICount}
								</div>
							</Link>
						) : (
							<></>
						)}
						{newDocumentCount > 0 ? (
							<Link to="/shipments/?filters=[{%22field%22:%22new_documents%22,%22operator%22:%22eq%22,%22value%22:%22true%22}]&linkSuffix=/documents">
								<img
									alt="NEW DOCUMENTS"
									className={styles.withNotificationCount}
									src={DocumentsIcon}
									title="NEW DOCUMENTS"
								/>
								<div
									className={styles.notificationCount}
									title="NEW DOCUMENTS"
								>
									{newDocumentCount}
								</div>
							</Link>
						) : (
							<></>
						)}
						{newRemarkCount > 0 ? (
							<Link to="/shipments/?filters=[{%22field%22:%22new_remarks%22,%22operator%22:%22eq%22,%22value%22:%22true%22}]&linkSuffix=/remarks">
								<img
									alt="NEW REMARKS"
									className={styles.withNotificationCount}
									src={RemarksIcon}
									title="NEW REMARKS"
								/>
								<div
									className={styles.notificationCount}
									title="NEW REMARKS"
								>
									{newRemarkCount}
								</div>
							</Link>
						) : (
							<></>
						)}
					</>
				) : (
					<></>
				)}
			{payRequestCount > 0 ? (
				<Link
					to={`/partners/${ProfilePartnerID()}/pay/incoming_requests`}
				>
					<img
						alt="INCOMING PAYMENT REQUESTS"
						className={styles.withNotificationCount}
						src={PayIncomingRequestsIcon}
						title="INCOMING PAYMENT REQUESTS"
					/>
					<div
						className={styles.notificationCount}
						title="INCOMING PAYMENT REQUESTS"
					>
						{payRequestCount}
					</div>
				</Link>
			) : (
				<></>
			)}
			{pendingUserCount > 0 ? (
				<Link to="/users?filters=[{%22field%22:%22approved%22,%22operator%22:%22eq%22,%22value%22:%22false%22}]">
					<img
						alt="PENDING USERS"
						className={styles.withNotificationCount}
						src={PendingUsersIcon}
						title="PENDING USERS"
					/>
					<div
						className={styles.notificationCount}
						title="PENDING USERS"
					>
						{pendingUserCount}
					</div>
				</Link>
			) : (
				<></>
			)}
			{HasRole('administrator', 'partner', 'memberpartner') ? (
				<Link to="/partners?filters=[{%22field%22:%22goal_member%22,%22operator%22:%22eq%22,%22value%22:%22true%22}]">
					<img
						alt="PARTNER DIRECTORY"
						src={ContactsIcon}
						title="PARTNER DIRECTORY"
					/>
				</Link>
			) : (
				<></>
			)}
			<Link to={`/users/${userID}/notifications`}>
				<img
					alt="NOTIFICATION SETTINGS"
					src={InboxIcon}
					title="NOTIFICATION SETTINGS"
				/>
			</Link>
			<Link target="_blank" to="https://support.goalportal.net">
				<img alt="HELP" src={HelpIcon} title="HELP" />
			</Link>
			<img alt="DIVIDER" className={styles.divider} src={DotsIcon} />
		</header>
	)
}
