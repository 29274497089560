import { FieldProps } from '../../Field'

export const EditActive = (props: FieldProps) => {
	return (
		<select
			className={`${
				props.field.HardRequired && !props.isSearch
					? 'hardRequired'
					: ''
			} ${
				props.field.SoftRequired && !props.isSearch
					? 'softRequired'
					: ''
			}`}
			data-boolean="true"
			data-hardrequired={props.field.HardRequired && !props.isSearch}
			data-nullable={props.field.Nullable}
			data-searchoperator="eq"
			data-softrequired={props.field.SoftRequired && !props.isSearch}
			defaultValue={props.value ? props.value : 'active'}
			id={props.field.Property}
			key={`active${props.field.Property}-${props.value}`}
			name={props.field.Property}
			onChange={props.onChange}
			required={props.field.HardRequired && !props.isSearch}
			title={props.field.Label?.replace(':', '')}
		>
			<option key="active" value="true">
				Active Only
			</option>
			<option key="all" value="any">
				Active and Deleted
			</option>
			<option key="deleted" value="false">
				Deleted Only
			</option>
		</select>
	)
}
