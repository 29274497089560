import { useState } from 'react'

import { FieldProps } from '../../Field'
import styles from './ShipmentStatus.module.scss'

export const EditShipmentStatus = (props: FieldProps) => {
	const [value, setValue] = useState(props.value)

	if (props.readOnly || props.field.ReadOnly) {
		return (
			<div
				className={styles.fieldOuterContainer}
				id={
					props.field.Property
						? `${props.field.Property}_container`
						: ''
				}
			>
				<div className={styles.label}>
					{props.field.Label}
					{props.field.HardRequired && !props.isSearch ? (
						<>
							{'\u00A0'}
							<span
								className="hardRequired"
								title="REQUIRED IN ORDER TO SAVE"
							>
								*
							</span>
						</>
					) : (
						<></>
					)}
					{props.field.SoftRequired && !props.isSearch ? (
						<>
							{'\u00A0'}
							<span className="softRequired" title="REQUIRED">
								*
							</span>
						</>
					) : (
						<></>
					)}{' '}
				</div>
				<input
					data-hardrequired={
						props.field.HardRequired && !props.isSearch
					}
					data-softrequired={
						props.field.SoftRequired && !props.isSearch
					}
					defaultValue={ShipmentStatuses[props.value]}
					readOnly={true}
					title={props.field.Label?.replace(':', '')}
					type="text"
				/>
			</div>
		)
	}

	return (
		<div
			className={styles.fieldOuterContainer}
			id={props.field.Property ? `${props.field.Property}_container` : ''}
		>
			<div className={styles.label}>
				{props.field.Label}
				{props.field.HardRequired && !props.isSearch ? (
					<>
						{'\u00A0'}
						<span
							className="hardRequired"
							title="REQUIRED IN ORDER TO SAVE"
						>
							*
						</span>
					</>
				) : (
					<></>
				)}
				{props.field.SoftRequired && !props.isSearch ? (
					<>
						{'\u00A0'}
						<span className="softRequired" title="REQUIRED">
							*
						</span>
					</>
				) : (
					<></>
				)}{' '}
			</div>
			<select
				className={`${
					props.field.HardRequired && !props.isSearch
						? 'hardRequired'
						: ''
				} ${
					props.field.SoftRequired && !props.isSearch
						? 'softRequired'
						: ''
				}`}
				data-hardrequired={props.field.HardRequired && !props.isSearch}
				data-nullable={props.field.Nullable}
				data-softrequired={props.field.SoftRequired && !props.isSearch}
				defaultValue={value}
				id={props.field.Property}
				name={props.field.Property}
				onChange={(e: React.ChangeEvent) => {
					setValue((e.target as HTMLSelectElement).value)
					if (props.onChange) {
						props.onChange(e)
					}
				}}
				required={props.field.HardRequired && !props.isSearch}
				title={props.field.Label?.replace(':', '')}
			>
				<option value=""></option>
				{Object.keys(ShipmentStatuses).map((key: string) => (
					<option key={`status${key}`} value={key}>
						{ShipmentStatuses[key]}
					</option>
				))}
			</select>
		</div>
	)
}

/* eslint-disable sort-keys */
export const ShipmentStatuses: { [key: string]: string } = {
	prealert: 'Pre-Alert',
	inprogress: 'Booking In Progress',
	unconfirmed: 'Unconfirmed Booking',
	accepted: 'Accepted Booking',
	rejected: 'Rejected Booking',
	intransit: 'In Transit',
	arrivedport: 'Arrived Port',
	pendingdelivery: 'Pending Delivery',
	delivered: 'Delivered',
	closed: 'Closed',
}
