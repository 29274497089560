import { useQuery } from '@tanstack/react-query'

import { APIHostname, ProfilePartnerID } from '../../../Profile'
import { Tabs, TabsProps } from '..'

export const PartnerTabs = (props: TabsProps) => {
	const { isPending, data } = useQuery({
		queryFn: () =>
			fetch(
				`${APIHostname}/api/v1/partners/${
					ProfilePartnerID() ?? 0
				}/links`,
				{
					headers: {
						Authorization: `Bearer ${localStorage['token']}`,
					},
				}
			).then((response) => response.json()),
		queryKey: [],
		refetchInterval: 0,
		refetchOnWindowFocus: false,
	})

	const topTabs = structuredClone(props.topTabs)
	data?.forEach((link: any) => {
		topTabs.splice(link.position, 0, {
			text: link.label,
			to: link.url,
		})
	})

	return Tabs({
		bottomTabs: props.bottomTabs,
		loading: isPending,
		menuItems: props.menuItems,
		showPrint: props.showPrint,
		topTabs: topTabs,
	})
}
