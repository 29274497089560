import axios from 'axios'
import { FormEvent, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

import DownArrowIcon from '../../../assets/icons/arrow-down.svg'
import { CSVEditor } from '../../../components/CSVEditor/CSVEditor'
import { AdministrationLeftMenuItems } from '../../../components/LeftMenu'
import { GlobalTopTabItems } from '../../../components/Tabs'
import { DefaultLayout } from '../../../layouts/DefaultLayout'
import { APIHostname, LogOff } from '../../../Profile'
import styles from './Countries.module.scss'

export const Countries = () => {
	const [countries, setCountries]: any = useState(null)
	const [loaded, setLoaded]: any = useState(false)
	const [errorMessage, setErrorMessage] = useState(<></>)
	const initialRedirectMessage = sessionStorage['redirect-message']
	const [redirectMessage] = useState(initialRedirectMessage)

	// Vary based on query string.
	const location = useLocation()

	useEffect(() => {
		axios({
			data: {},
			headers: {
				Authorization: `Bearer ${localStorage['token']}`,
			},
			method: 'GET',
			url: `${APIHostname}/api/v1/countries${location.search}`,
		})
			.then((response: any) => {
				setCountries(response.data.countries)
				setLoaded(true)
			})
			.catch((error: any) => {
				switch (error?.response?.status) {
				case 401:
					LogOff('token-expired')
					return
				case 403:
					setErrorMessage(
						<>
								Error: You do not have permission to view
								countries.
						</>
					)
					break
				default:
					if (error?.response?.data?.error) {
						setErrorMessage(
							<Link to={error?.response?.data?.link}>
									Error: {error?.response?.data?.error}
							</Link>
						)
					} else {
						setErrorMessage(<>Error: Unknown error.</>)
							console.error(error) // eslint-disable-line
					}
				}
			})
	}, [location])

	if (!loaded) {
		if (errorMessage) {
			<DefaultLayout
				bottomTabItems={CountriesBottomTabItems}
				leftMenuItems={AdministrationLeftMenuItems}
				showPrint={true}
				tabMenuItems={CountriesTabMenuItems}
				topTabItems={GlobalTopTabItems}
			>
				<div className={styles.countriesContainer}>
					<div className={styles.redirectMessage}>
						{redirectMessage}
					</div>
					<div className={styles.errorMessage}>{errorMessage}</div>
				</div>
			</DefaultLayout>
		}

		return <div className="loading">Loading...</div>
	}

	sessionStorage.removeItem('redirect-message')
	return (
		<DefaultLayout
			bottomTabItems={CountriesBottomTabItems}
			leftMenuItems={AdministrationLeftMenuItems}
			showPrint={true}
			tabMenuItems={CountriesTabMenuItems}
			topTabItems={GlobalTopTabItems}
		>
			<div className={styles.countriesContainer}>
				<div className={styles.redirectMessage}>{redirectMessage}</div>
				<div className={styles.errorMessage}>{errorMessage}</div>

				<form
					name="countries-form"
					onSubmit={(e: FormEvent<HTMLFormElement>) => {
						axios({
							data: (
								document.getElementsByName(
									'countries'
								)[0] as HTMLSelectElement
							).value,
							headers: {
								Authorization: `Bearer ${localStorage['token']}`,
								'Content-Type': 'text/csv',
							},
							method: 'POST',
							url: `${APIHostname}/api/v1/countries`,
						})
							.then(() => {
								setErrorMessage(<></>)
							})
							.catch((error: any) => {
								switch (error?.response?.status) {
								case 401:
									LogOff('token-expired')
									return
								case 403:
										console.error(error) // eslint-disable-line
									setErrorMessage(
										<>
												Error: You do not have
												permission to edit countries.
										</>
									)
									break
								default:
									if (error?.response?.data?.error) {
										setErrorMessage(
											<Link
												to={
													error?.response?.data
														?.link
												}
											>
													Error:
												{
													error?.response?.data
														?.error
												}
											</Link>
										)
									} else {
										setErrorMessage(
											<>Error: Unknown error.</>
										)
											console.error(error) // eslint-disable-line
									}
								}
							})
						e.preventDefault()
					}}
				>
					<CSVEditor
						columns={[
							{
								id: 'code',
								title: 'Code',
							},
							{
								id: 'name',
								title: 'Name',
							},
							{
								id: 'three_letter_code',
								title: 'Three Letter Code',
							},
						]}
						data={countries}
						name="countries"
						title="Countries"
					/>

					<div className={styles.submitContainer}>
						<input type="submit" value="SUBMIT" />
					</div>
				</form>
			</div>
		</DefaultLayout>
	)
}

export const CountriesTabMenuItems = []

export const CountriesBottomTabItems = [
	{
		icon: DownArrowIcon,
		text: 'COUNTRIES',
		to: '/countries',
	},
]
