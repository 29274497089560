import axios from 'axios'
import { FormEvent, useState } from 'react'
import {
	GoogleReCaptcha,
	GoogleReCaptchaProvider,
} from 'react-google-recaptcha-v3'

import DownArrowIcon from '../../../assets/icons/arrow-down.svg'
import { DefaultLayout } from '../../../layouts/DefaultLayout'
import { APIHostname } from '../../../Profile'
import { RecaptchaSiteKey } from '../../../types/Recaptcha'
import { LogInLeftMenuItems, LogInTopTabItems } from '..'
import styles from './ForgotPassword.module.scss'

export const ForgotPassword = () => {
	const [email, setEmail] = useState('')
	const [errorMessage, setErrorMessage] = useState(<></>)
	const [successMessage, setSuccessMessage] = useState('')
	const [formDisabled, setFormDisabled] = useState(false)
	const [formVisible, setFormVisible] = useState(true)
	const [reCaptchaVerified, setReCaptchaVerified] = useState(false)

	return (
		<DefaultLayout
			bottomTabItems={ForgotPasswordBottomTabItems}
			leftMenuItems={LogInLeftMenuItems}
			showPrint={false}
			tabMenuItems={ForgotPasswordTabMenuItems}
			topTabItems={LogInTopTabItems}
		>
			<div className={styles.forgotPasswordOuterContainer}>
				<div className={styles.forgotPasswordInnerContainer}>
					<div className={styles.successMessage}>
						{successMessage}
					</div>
					<div className={styles.errorMessage}>{errorMessage}</div>
					<GoogleReCaptchaProvider reCaptchaKey={RecaptchaSiteKey}>
						<form
							className={styles.forgotPasswordForm}
							name="forgot-password-form"
							onSubmit={(e: FormEvent<HTMLFormElement>) => {
								if (reCaptchaVerified) {
									setFormDisabled(true)
									axios({
										data: {
											email: email,
										},
										headers: {
											'Content-Type': 'application/json',
										},
										method: 'POST',
										url: `${APIHostname}/api/v1/forgotpassword`,
									})
										.then(() => {
											setSuccessMessage(
												`Thank you. If an account exists for ${email}, a recovery email has been sent.`
											)
											setErrorMessage(<></>)
											setFormVisible(false)
										})
										.catch((error: any) => {
											console.error(error) // eslint-disable-line
											setSuccessMessage('')
											setErrorMessage(
												<>Error: Unknown error.</>
											)
											setFormDisabled(false)
										})
									e.preventDefault()
								} else {
									setErrorMessage(
										<>Please complete the Captcha.</>
									)
								}
							}}
							style={!formVisible ? { display: 'none' } : {}}
						>
							<div>EMAIL:</div>
							<input
								className={'hardRequired'}
								onChange={(
									e: React.ChangeEvent<HTMLInputElement>
								) => {
									setEmail(e.target.value)
								}}
								title="EMAIL"
								type="email"
								value={email}
							/>
							<GoogleReCaptcha
								onVerify={() => {
									setReCaptchaVerified(true)
								}}
							/>
							<input
								disabled={formDisabled}
								type="submit"
								value="Submit"
							/>
						</form>
					</GoogleReCaptchaProvider>
				</div>
			</div>
		</DefaultLayout>
	)
}

export const ForgotPasswordBottomTabItems = [
	{
		icon: DownArrowIcon,
		text: 'FORGOT PASSWORD',
		to: '/forgot-password',
	},
]

export const ForgotPasswordTabMenuItems = []
