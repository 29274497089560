import { ReactNode } from 'react'

import {
	HasPermissions,
	HasPermissionsRoleAndValues,
	HasRole,
	HasValues,
} from '../../Profile'
import { Field } from '../../types/Field'
import { Group } from '../../types/Group'
import { Row } from '../../types/Row'
import { RandomID } from '../../Utils'
import { EditField } from './Field'
import styles from './Group.module.scss'

export const EditGroup = (props: EditGroupProps) => {
	return (
		<div
			className={`${props.group.ClassName} ${
				props.group.Groups ? '' : styles.groupOuterContainer
			}`}
			key={RandomID(16)}
			style={{
				flex: props.group.Flex,
				flexDirection: props.group.Direction,
				margin: props.group.Margin ? props.group.Margin : undefined,
				width: props.group.Width
					? `${props.group.Width.toString()}%`
					: 'unset',
			}}
		>
			<div
				className={
					props.group.Groups
						? styles.groupIntermediateContainer
						: styles.groupMiddleContainer
				}
				key={RandomID(16)}
				style={{
					boxShadow: props.group.Border
						? '0 0 1rem #dee0e3'
						: undefined,
				}}
			>
				{props.group.Label && (
					<div
						className={`${styles.label}`}
						key={RandomID(16)}
						style={{
							backgroundColor:
								props.group.LabelBackground == 'orange'
									? '#F6931E'
									: '#7D9ECF',
						}}
					>
						{props.group.Label}
					</div>
				)}
				<div
					className={
						props.group.Groups
							? styles.groupNestedContainer
							: styles.groupInnerContainer
					}
					key={RandomID(16)}
					style={{
						backgroundColor:
							props.group.Background == 'white'
								? 'white'
								: props.group.Background == 'dark'
									? props.group.Groups
										? 'unset'
										: props.group.Border
											? '#F7F9FE'
											: '#F1F4F9'
									: undefined,
						flex: props.group.Flex,
						flexDirection: props.group.Direction,
						padding: props.group.Padding
							? props.group.Padding
							: undefined,
					}}
				>
					{props.group.Groups ? (
						<>
							{props.group.Groups?.map(
								(group: Group, index: number) => {
									return (
										<EditGroup
											data={props.data}
											group={group}
											isSearch={false}
											key={`group${index}`}
											onChange={props.onChange}
											readOnly={props.readOnly}
											showOptional={true}
										/>
									)
								}
							)}
						</>
					) : (
						<></>
					)}
					{props.group.RequiredRows ? (
						<div className={styles.requiredRows} key={RandomID(16)}>
							{props.group.RequiredRows?.map((row: Row) => {
								const hasPermission =
									((props.isSearch &&
										row.IncludeInSearch != false) ||
										(!props.isSearch &&
											row.IncludeInEdit != false)) &&
									HasPermissionsRoleAndValues(
										row.RequiredPermissions,
										row.RequiredRole,
										row.RequiredValues,
										props.data
									)
								if (hasPermission) {
									return (
										<div
											className={styles.row}
											key={RandomID(16)}
										>
											{row.Fields?.map((field: Field) => {
												return ((props.isSearch &&
													field.IncludeInSearch !=
														false) ||
													(!props.isSearch &&
														field.IncludeInEdit !=
															false)) &&
													(!field.RequiredPermissions ||
														HasPermissions(
															field.RequiredPermissions
														)) &&
													(!field.RequiredRole ||
														HasRole(
															field.RequiredRole
														)) ? (
														<EditField
															data={props.data ?? {}}
															field={field}
															hidden={
																!HasValues(
																	field.RequiredValues,
																	props.data
																)
															}
															isSearch={
																props.isSearch
															}
															key={RandomID(16)}
															onChange={
																props.onChange
															}
															readOnly={
																props.readOnly
															}
															value={
																props.data
																	? props.data[
																		field
																			.Property
																  ]
																	: null
															}
														/>
													) : (
														<div
															key={RandomID(16)}
															style={{
																display: 'none',
															}}
														></div>
													)
											})}{' '}
										</div>
									)
								} else {
									<div
										key={RandomID(16)}
										style={{ display: 'none' }}
									></div>
								}
							})}
						</div>
					) : (
						<></>
					)}
					{props.group.OptionalRows ? (
						<div className={styles.optionalRows} key={RandomID(16)}>
							{props.group.OptionalRows?.map((row: Row) => {
								const hasPermission =
									((props.isSearch &&
										row.IncludeInSearch != false) ||
										(!props.isSearch &&
											row.IncludeInEdit != false)) &&
									HasPermissionsRoleAndValues(
										row.RequiredPermissions,
										row.RequiredRole,
										row.RequiredValues,
										props.data
									)

								if (hasPermission) {
									return (
										<div
											className={styles.row}
											key={RandomID(16)}
											style={{
												display:
													!props.isSearch ||
													props.showOptional
														? ''
														: 'none',
											}}
										>
											{row.Fields?.map((field: Field) => {
												return ((props.isSearch &&
													field.IncludeInSearch !=
														false) ||
													(!props.isSearch &&
														field.IncludeInEdit !=
															false)) &&
													HasPermissionsRoleAndValues(
														field.RequiredPermissions,
														field.RequiredRole,
														field.RequiredValues,
														props.data
													) ? (
														<EditField
															data={props.data ?? {}}
															field={field}
															isSearch={
																props.isSearch
															}
															key={RandomID(16)}
															onChange={
																props.onChange
															}
															readOnly={
																props.readOnly
															}
															value={
																props.data
																	? props.data[
																		field
																			.Property
																  ]
																	: null
															}
														/>
													) : (
														<div
															key={RandomID(16)}
															style={{
																display: 'none',
															}}
														></div>
													)
											})}
										</div>
									)
								} else {
									<div
										key={RandomID(16)}
										style={{ display: 'none' }}
									></div>
								}
							})}
						</div>
					) : (
						<></>
					)}
					{props.children}
				</div>
			</div>
		</div>
	)
}

export type EditGroupProps = {
	children?: ReactNode
	className?: string
	data: any
	group: Group
	isSearch: boolean
	onChange?: (event: any) => void
	onSubmit?: () => void
	readOnly?: boolean
	showOptional: boolean
}
