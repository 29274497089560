import './APIReference.scss'

import { Link } from 'react-router-dom'

import DownArrowIcon from '../../assets/icons/arrow-down.svg'
import ListsIcon from '../../assets/icons/lists.svg'
import { GlobalTopTabItems } from '../../components/Tabs'
import { DefaultLayout } from '../../layouts/DefaultLayout'
import { ProfileUserID } from '../../Profile'
import styles from './APIReference.module.scss'

export const APIReference = () => {
	if (location.pathname != '/api/v1') {
		location.replace('/api/v1')
	}

	{
		/*const copyContents = (event: any) => {
		const selection = window.getSelection()!
		const range = document.createRange()
		range.selectNodeContents(event.target)
		selection.removeAllRanges()
		selection.addRange(range)
		document.execCommand('copy')
	*/
	}

	return (
		<DefaultLayout
			bottomTabItems={APIReferenceBottomTabItems}
			leftMenuItems={APIReferenceLeftMenuItems}
			showPrint={true}
			tabMenuItems={APIReferenceTabMenuItems}
			topTabItems={GlobalTopTabItems}
		>
			<div className={styles.apiReferenceContainer}>
				<div>
					GOAL Portal supports a{' '}
					<a
						href="https://restfulapi.net/"
						rel="noreferrer"
						target="_blank"
					>
						REST API
					</a>{' '}
					for interacting with data at{' '}
					<strong>https://{location.host}/api/v1</strong>. The API
					allows for automated creation and tracking of shipments and
					related data.
				</div>
				<div>
					<h2>1. Developer Mode</h2>
					Users can be enrolled in &quot;Developer Mode&quot;, which
					supports the generation of API tokens and enables
					developer-specific features within the GOAL Portal.
					<p />
					To enable Developer Mode, navigate to the user&apos;s
					profile page as a partner or administrator and click the
					&quot;Developer Mode&quot; checkbox then save.
				</div>
				<div>
					<h2>2. Generating an API Token</h2>
					All API requests require a{' '}
					<a href="https://jwt.io/" rel="noreferrer" target="_blank">
						JSON Web Token (JWT)
					</a>{' '}
					to be passed via the <strong>Authorization</strong> header.{' '}
					<p />
					<Link to={`/users/${ProfileUserID()}/api`}>
						API tokens
					</Link>{' '}
					can be generated by authorized users under{' '}
					<Link to={`/users/${ProfileUserID()}`}>My Profile</Link>.
					API tokens should be scoped with the minimal number of
					permissions required.
				</div>
				<div>
					<h2>3. API Token Security</h2>
					API tokens are highly privileged and should be kept secure.
					They should not be shared between personnel. All actions
					taken via the API are logged and audited.
					<p />
					Due to the nature of automation, API tokens currently have a
					years-long lifespan. If a token is compromised, please
					notify GOAL immediately to have the token revoked.
				</div>
				<div>
					<h2>4. Setting the Authorization Header</h2>
					Once acquired, Authorization tokens are passed via the{' '}
					<strong>Authorization</strong> header with the prefix
					&quot;Bearer &quot;
					<p />
					<code>
						<span className={styles.header}>Authorization:</span>{' '}
						<i>Bearer eyJh...</i>
					</code>
					<p />
					Tokens must be passed with every request to the API. If a
					token lacks required permissions, a <b>
						401 Unauthorized
					</b>{' '}
					response or <b>403 Forbidden</b> response will be returned.
					When permissions need to be added, a new token needs to be
					regenerated.
				</div>
				<div>
					<h2>5. REST Semantics</h2>
					The API follows REST semantics, with endpoints for object
					collections and individual objects. The API supports the
					following HTTP methods:
					<p />
					<ul>
						<li>
							<b>GET</b>: Retrieve an item (in JSON format) or a
							collection of items (in JSON or CSV format)
						</li>
						<li>
							<b>POST</b>: Create a new item or replace an
							existing item (in JSON format)
						</li>
						<li>
							<b>PATCH</b>: Perform a differential update to an
							item (in JSON format)
						</li>
						<li>
							<b>DELETE</b>: Delete an item
						</li>
					</ul>
					Semantic HTTP response codes are returned, with a{' '}
					<b>200 OK</b> response indicating success.
					<p />
					Please limit API requests to no more than 2 per second.
				</div>
				<div>
					<h2>6. Common Endpoints</h2>
					The GOAL Portal supports many data types, but the most
					commonly used are:
					<p />
					<ul>
						<li>
							<b>Organizations:</b>
							<ul>
								<li>
									Collection: <em>/api/v1/organizations</em>
								</li>
								<li>
									Organization:{' '}
									<em>
										/api/v1/organizations/
										{'{organizationID}'}
									</em>
								</li>
							</ul>
						</li>
						<li>
							<b>Partners:</b>
							<ul>
								<li>
									Collection: <em>/api/v1/partners</em>
								</li>
								<li>
									Partner:{' '}
									<em>
										/api/v1/partners/
										{'{partnerID}'}
									</em>
								</li>
							</ul>
						</li>
						<li>
							<b>Shipments:</b>
							<ul>
								<li>
									Collection: <em>/api/v1/shipments</em>
								</li>
								<li>
									Shipment:{' '}
									<em>/api/v1/shipments/{'{shipmentID}'}</em>
								</li>
							</ul>
						</li>
						<li>
							<b>Users:</b>
							<ul>
								<li>
									Collection: <em>/api/v1/users</em>
								</li>
								<li>
									User: <em>/api/v1/users/{'{userID}'}</em>
								</li>
							</ul>
						</li>
					</ul>
				</div>
				<div>
					{' '}
					<h2>7. OpenAPI / Swagger Specification</h2>
					An{' '}
					<a href="/openapi.yaml">
						OpenAPI / Swagger specification file
					</a>{' '}
					is available at{' '}
					<a href="/openapi.yaml">
						https://{location.host}/openapi.yaml
					</a>{' '}
					which describes all data types, endpoints, and conventions
					for working with the API.
				</div>
				<div>
					<h2>8. Filtering Collection Results</h2>
					Collection endpoints can be filtered using the following
					query-string parameters:
					<h3>filters</h3>A JSON-formatted array of filters. Each
					filter has the following attributes:
					<ul>
						<li>
							<b>field</b>: Name of the field
						</li>
						<li>
							<b>operator</b>: Operator to apply:
							<ul>
								<li>
									<b>contains</b>: Contains the substring
								</li>
								<li>
									<b>doesnotcontain</b>: Does not contain the
									substring
								</li>
								<li>
									<b>eq</b>: Equals
								</li>
								<li>
									<b>gt</b>: Greater than
								</li>
								<li>
									<b>gte</b>: Greater than or equal to
								</li>
								<li>
									<b>like</b>: Matches a glob pattern
								</li>
								<li>
									<b>lt</b>: Less than
								</li>
								<li>
									<b>lte</b>: Less than or equal to
								</li>
								<li>
									<b>ne</b>: Not Equal
								</li>
								<li>
									<b>null</b>: Is null
								</li>
								<li>
									<b>notlike</b>: Does not match a glob
									pattern
								</li>
								<li>
									<b>notnull</b>: Is not null
								</li>
							</ul>
						</li>
						<li>
							<b>value</b>: Value of the field
						</li>
						<li>
							<b>aggregate</b>: Aggregate applied to child
							filters:
							<ul>
								<li>
									<b>and</b>
								</li>
								<li>
									<b>or</b>
								</li>
							</ul>
						</li>
						<li>
							<b>children</b>: Child filters to be applied for
							aggregate nested logic
						</li>
					</ul>
					<h3>format</h3>
					The format to return results in (supported by a subset of
					endpoints). One of the following:
					<ul>
						<li>
							<b>json</b> (default)
						</li>
						<li>
							<b>csv</b>
						</li>
					</ul>
					<h3>limit</h3>
					The number of results to return. Default is 25.
					<h3>offset</h3>
					The number of results to skip (for pagination). Default is
					0.
					<h3>sorts</h3>A JSON-formatted array of sorts to apply. Each
					sort has the following attributes:
					<ul>
						<li>
							<b>field</b>: Name of the field
						</li>
						<li>
							<b>order</b>: Whether to order in ascending or
							descending order:
							<ul>
								<li>
									<b>asc</b>
								</li>
								<li>
									<b>desc</b>
								</li>
							</ul>
						</li>
					</ul>
				</div>
				<div>
					<h2>9. Lookup Values</h2>
					Many fields in the GOAL Portal are lookup references using
					numeric IDs. For example, shipments reference
					&quot;origin_partner&quot; and
					&quot;destination_partner&quot; fields, which are numeric
					lookups to the partners collection. Exact values can be
					queried from the <em>/api/v1/partners</em> endpoint or a
					partner lookup code can be used. The same is true for the
					organizations collection.
					<p />
					<h3>Shipment PATCH example using numeric IDs:</h3>
					<pre>
						{`{
	"destination_partner": 123,
	"origin_partner": 456,

	"consignee": 1001,
	"notify": 2002,
	"shipper": 3003
}`}
					</pre>
					For convenience, each partner and organization has an
					eight-character code associated with it. These lookup values
					can be passed as &quot;origin_partner_code&quot;,
					&quot;destination_partner_code&quot;, etc..
					<h3>Shipment PATCH example using lookup codes:</h3>
					<pre>
						{`{
	"destination_partner_code": "DESTPART",
	"origin_partner_code": "ORIGPART",

	"consignee_code": "CONSIORG",
	"notify": "NOTIFORG",
	"shipper": "SHIPPORG"
}`}
					</pre>
				</div>
				<div>
					<h2>10. Obtaining Sample API Call Data</h2>
					When a user has been set to Developer Mode, the GOAL Portal
					will show a button on each page labeled &quot;
					<b>PREVIEW API CALL</b>&quot;. Clicking this button instead
					of &quot;Submit&quot; will show the JSON data and exact API
					call that would be represented by the current page.
					<p />
					Modeling API calls after these samples is the fastest way to
					get started.
				</div>
				<div>
					<h2>API Support</h2>
					Please contact your GOAL representative for support
					integrating with the GOAL Portal API.
				</div>
			</div>
		</DefaultLayout>
	)
}

export const APIReferenceTabMenuItems = []

export const APIReferenceBottomTabItems = [
	{
		icon: DownArrowIcon,
		text: 'API REFERENCE',
		to: '/api/v1',
	},
]

export const APIReferenceLeftMenuItems = [
	{
		icon: ListsIcon,
		text: 'API REFERENCE',
		to: '/api/v1',
	},
]
