import ExcelIcon from '../../assets/icons/excel.svg'
import PDFIcon from '../../assets/icons/pdf.svg'
import styles from './MediaType.module.scss'

export const MediaType = (props: MediaTypeProps) => {
	switch (props.MediaType.toLowerCase()) {
	case 'application/pdf':
		return <img className={styles.mediaType} src={PDFIcon} />
	case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
		return <img className={styles.mediaType} src={ExcelIcon} />
	}

	return <></>
}

export type MediaTypeProps = {
	MediaType: string
}
