import axios from 'axios'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import DownArrowIcon from '../../../../assets/icons/arrow-down.svg'
import { DefaultLayout } from '../../../../layouts/DefaultLayout'
import { APIHostname } from '../../../../Profile'
import { LogInLeftMenuItems, LogInTopTabItems } from '../..'
import styles from './VerifyEmail.module.scss'

export const VerifyEmail = () => {
	const location = useLocation()
	const urlParams = new URLSearchParams(location.search)
	const email = useState(urlParams.get('email') ?? '')
	const [errorMessage, setErrorMessage] = useState<
		JSX.Element | string | null
	>(null)
	const [successMessage, setSuccessMessage] = useState<
		JSX.Element | string | null
	>(null)
	const nonce = urlParams.get('nonce')

	useEffect(() => {
		axios({
			method: 'GET',
			params: {
				email: email,
				nonce: nonce,
			},
			url: `${APIHostname}/api/v1/verifyemail`,
		})
			.then(() => {
				setSuccessMessage(
					'Thank you. Your email has been verified. You will receive another email when your account has been activated.'
				)
				setErrorMessage(null)
			})
			.catch((error: any) => {
				console.error(error) // eslint-disable-line
				setErrorMessage(<>Invalid link.</>)
			})
	}, [])

	if (!email || !nonce) {
		setErrorMessage(<>Invalid link.</>)
	}

	return (
		<DefaultLayout
			bottomTabItems={CreateAccountBottomTabItems}
			leftMenuItems={LogInLeftMenuItems}
			showPrint={false}
			tabMenuItems={CreateAccountTabMenuItems}
			topTabItems={LogInTopTabItems}
		>
			<div className={styles.registerContainer}>
				<div className={styles.successMessage}>{successMessage}</div>
				<div className={styles.errorMessage}>{errorMessage}</div>
			</div>
		</DefaultLayout>
	)
}

export const CreateAccountBottomTabItems = [
	{
		icon: DownArrowIcon,
		text: 'CREATE ACCOUNT',
		to: '/register',
	},
]

export const CreateAccountTabMenuItems = []
