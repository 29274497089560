import { ReactElement, useRef } from 'react'
import { useBlocker } from 'react-router-dom'

import {
	HasPermissionsRoleAndValues,
	ProfileDeveloperMode,
} from '../../Profile'
import { Field } from '../../types/Field'
import { Group } from '../../types/Group'
import { Layout } from '../../types/Layout'
import { RandomID } from '../../Utils'
import { EditButton } from './Button'
import styles from './EditForm.module.scss'
import { EditGroup } from './EditGroup'
import { EditField } from './Field'
import { RequiredStatus } from './RequiredStatus'

export const EditForm = (props: EditFormProps) => {
	const allowSubmit = useRef(true)
	const calculated = useRef(false)

	const blocker = useBlocker(() => {
		if (!props.warnOnNavigate) {
			if (blocker && blocker.proceed) {
				blocker.proceed()
			}
			return false
		}

		/* eslint-disable no-alert */
		if (
			location.hash == '' ||
			confirm('Navigate away without submitting form?')
		) {
			if (blocker && blocker.proceed) {
				blocker.proceed()
			}
			return false
		}
		/* eslint-enable no-alert */

		return true
	})

	const calculateInvalidFields = () => {
		const hiFields: string[] = []
		const hiFieldsCovered: { [key: string]: boolean } = {}
		document
			.querySelectorAll('input:invalid,select:invalid')
			.forEach((field: any) => {
				const container = document.getElementById(
					`${field.id.replace('_name', '')}_container`
				)
				if (!container || container.style.display != 'none') {
					let fieldTitle = field.getAttribute('title')
					let undersScoreIndex = -1
					const underScoreParts = field.id.split('_')
					underScoreParts.forEach((part: string, i: number) => {
						if (!isNaN(parseInt(part))) {
							undersScoreIndex = i
						}
					})
					if (undersScoreIndex > -1) {
						const noun = underScoreParts
							.slice(0, undersScoreIndex)
							.join(' ')
						fieldTitle = `${noun
							.substring(0, noun.length - 1)
							.toUpperCase()
							.replaceAll('HAZARDOUS CARGOE', 'HAZARDOUS CARGO')
							.replaceAll('NRAS TO GOA', 'NRA TO GOAL')
							.replaceAll('NRAS TO GOA', 'NRA TO GOAL')} #${(
							parseInt(underScoreParts[undersScoreIndex]) + 1
						).toString()} ${fieldTitle}`
					}
					const fieldValidationMessage =
						field.validationMessage.toUpperCase()
					if (
						fieldValidationMessage == 'PLEASE FILL OUT THIS FIELD.'
					) {
						hiFields.push(fieldTitle)
					} else {
						hiFields.push(
							`${fieldTitle}: ${field.validationMessage
								.substr(0, field.validationMessage.length - 1)
								.toUpperCase()}`
						)
					}
					hiFieldsCovered[field.id] = true
				}
			})
		document
			.querySelectorAll(
				'input.hardRequired,select.hardRequired,textarea.hardRequired'
			)
			.forEach((field: any) => {
				if (!field.value && !hiFieldsCovered[field.id]) {
					const container = document.getElementById(
						`${field.id.replace('_name', '')}_container`
					)
					if (!container || container.style.display != 'none') {
						let fieldTitle = field.getAttribute('title')
						let undersScoreIndex = -1
						const underScoreParts = field.id.split('_')
						underScoreParts.forEach((part: string, i: number) => {
							if (!isNaN(parseInt(part))) {
								undersScoreIndex = i
							}
						})
						if (undersScoreIndex > -1) {
							const noun = underScoreParts
								.slice(0, undersScoreIndex)
								.join(' ')
							fieldTitle = `${noun
								.substring(0, noun.length - 1)
								.toUpperCase()
								.replaceAll(
									'HAZARDOUS CARGOE',
									'HAZARDOUS CARGO'
								)
								.replaceAll('NRAS TO GOA', 'NRA TO GOAL')
								.replaceAll('NRAS TO GOA', 'NRA TO GOAL')} #${(
								parseInt(underScoreParts[undersScoreIndex]) + 1
							).toString()} ${fieldTitle}`
						}
						hiFields.push(fieldTitle)
					}
				}
			})
		if (props.customHardValidation) {
			hiFields.push(...props.customHardValidation())
		}
		const hardInvalidFields = document.getElementById('hardInvalidFields')
		if (hardInvalidFields) {
			if (hiFields.length > 0) {
				hardInvalidFields.innerHTML = `THE FOLLOWING FIELDS ARE REQUIRED TO SUBMIT:
				<ul><li>${hiFields.join('</li><li>')}</li></ul>`
			} else {
				hardInvalidFields.innerHTML = ''
			}
		}

		const siFields: string[] = []
		document
			.querySelectorAll(
				'input.softRequired,select.softRequired,textarea.softRequired'
			)
			.forEach((field: any) => {
				if (!field.value) {
					const container = document.getElementById(
						`${field.id.replace('_name', '')}_container`
					)
					if (!container || container.style.display != 'none') {
						let fieldTitle = field.getAttribute('title')
						let undersScoreIndex = -1
						const underScoreParts = field.id.split('_')
						underScoreParts.forEach((part: string, i: number) => {
							if (!isNaN(parseInt(part))) {
								undersScoreIndex = i
							}
						})
						if (undersScoreIndex > -1) {
							const noun = underScoreParts
								.slice(0, undersScoreIndex)
								.join(' ')
							fieldTitle = `${noun
								.substring(0, noun.length - 1)
								.toUpperCase()
								.replaceAll(
									'HAZARDOUS CARGOE',
									'HAZARDOUS CARGO'
								)
								.replaceAll('NRAS TO GOA', 'NRA TO GOAL')} #${(
								parseInt(underScoreParts[undersScoreIndex]) + 1
							).toString()} ${fieldTitle}`
						}
						siFields.push(fieldTitle)
					}
				}
			})
		if (props.customSoftValidation) {
			siFields.push(...props.customSoftValidation())
		}
		const softInvalidFields = document.getElementById('softInvalidFields')
		if (softInvalidFields) {
			if (siFields.length > 0) {
				softInvalidFields.innerHTML = `THE FOLLOWING FIELDS ARE MISSING OR INVALID:
					<ul><li>${siFields.join('</li><li>')}</li></ul>`
			} else {
				softInvalidFields.innerHTML = ''
			}
		}
		const dataCorrection = document.getElementById('dataCorrection')
		if (dataCorrection) {
			if (hiFields.length == 0 && siFields.length == 0) {
				dataCorrection.style.display = 'none'
			} else {
				dataCorrection.style.display = 'flex'
			}
		}

		if (hiFields.length == 0 && !props.disableSubmitButton) {
			document
				.querySelectorAll('input[type=submit]')
				.forEach((field: any) => {
					field.removeAttribute('disabled')
				})
		} else {
			document
				.querySelectorAll('input[type=submit]')
				.forEach((field: any) => {
					field.setAttribute('disabled', true)
				})
		}
	}
	if (!calculated.current) {
		calculated.current = true
		setTimeout(calculateInvalidFields, 100)
		setTimeout(calculateInvalidFields, 250)
		setTimeout(calculateInvalidFields, 500)
		setTimeout(calculateInvalidFields, 1000)
		setTimeout(calculateInvalidFields, 2500)
	}

	return (
		<form
			className={styles.formContainer}
			name={props.name}
			onSubmit={async (e: React.FormEvent<HTMLFormElement>) => {
				if (blocker && blocker.proceed) {
					blocker.proceed()
				}
				e.preventDefault()
				e.stopPropagation()

				if (!allowSubmit) {
					return false
				}
				allowSubmit.current = false

				const formData = new FormData(e.currentTarget)

				if (props.onSubmit) {
					await props.onSubmit(formData)
				}
				allowSubmit.current = true
				return false
			}}
		>
			{props.layout.Groups?.map((group: Group, index: number) => {
				return group.IncludeInEdit != false &&
					HasPermissionsRoleAndValues(
						group.RequiredPermissions,
						group.RequiredRole,
						group.RequiredValues,
						props.data
					) ? (
						<EditGroup
							data={props.data}
							group={group}
							isSearch={false}
							key={`group${index}`}
							onChange={() => {
								calculateInvalidFields()
								setTimeout(() => {
									if (props.warnOnNavigate) {
										history.replaceState(undefined, '', '#!')
									}
									calculateInvalidFields()
								}, 500)
							}}
							readOnly={props.readOnly}
							showOptional={true}
						/>
					) : (
						<div key={RandomID(16)}></div>
					)
			})}

			{props.layout.Fields?.map((field: Field, index: number) => (
				<EditField
					data={props.data ?? {}}
					field={field}
					isSearch={false}
					key={index}
					onChange={() => {
						calculateInvalidFields()
						setTimeout(() => {
							if (props.warnOnNavigate) {
								history.replaceState(undefined, '', '#!')
							}
							calculateInvalidFields()
						}, 500)
					}}
					readOnly={props.readOnly}
					value={props.data ? props.data[field.Property] : null}
				/>
			))}

			{props.children}

			{!props.readOnly ? (
				<div className={styles.buttons}>
					<RequiredStatus />
					{!props.disablePreviewAPIButton &&
					ProfileDeveloperMode() ? (
							<EditButton
								button={{
									Label: 'PREVIEW API CALL',
									Type: 'api',
								}}
								form={props}
							/>
						) : (
							<></>
						)}
					{props.hasClearButton != false ? (
						<EditButton
							button={{
								Label: 'CLEAR',
								Type: 'clear',
							}}
							form={props}
						/>
					) : (
						<></>
					)}
					{props.additionalButtons}
					{props.hasSubmitButton != false ? (
						<EditButton
							button={{
								Label: props.submitButtonLabel
									? props.submitButtonLabel
									: 'SUBMIT',
								Type: 'submit',
							}}
							disabled={!allowSubmit || props.disableSubmitButton}
							form={props}
						/>
					) : (
						<></>
					)}
				</div>
			) : (
				<div key={RandomID(16)}></div>
			)}
		</form>
	)
}

export type EditFormProps = {
	additionalButtons?: ReactElement
	children?: ReactElement
	customHardValidation?: () => string[]
	customSoftValidation?: () => string[]
	data: any
	disablePreviewAPIButton?: boolean
	disableSubmitButton?: boolean
	hasClearButton?: boolean
	hasSubmitButton?: boolean
	layout: Layout
	name: string
	onSubmit?: (formData: FormData) => void
	previewAPIData: (formData: FormData) => Promise<PreviewAPIData>
	readOnly: boolean
	submitButtonLabel?: string
	warnOnNavigate?: boolean
}

export type PreviewAPIData = {
	data: string
	method: string
	url: string
}

export const ParsedFormData = async (formData: FormData, flatten: string[]) => {
	const data: {
		[key: string]: any
	} = {}
	const flattenMap: { [key: string]: boolean } = {}
	flatten.forEach((field: string) => {
		data[field] = []
		flattenMap[field] = true
	})
	for (const valuePair of formData) {
		if (valuePair) {
			let setValue: any | null = valuePair[1].valueOf()
			let setFileNameValue: any | null
			if (
				document.getElementById(valuePair[0])?.dataset.nullable &&
				(setValue == '0' || setValue == 0 || setValue == '')
			) {
				setValue = null
			} else {
				if (document.getElementById(valuePair[0])?.dataset.file) {
					const files = (document.getElementById(valuePair[0]) as any)
						?.files
					if (files && files.length > 0) {
						setValue = await readFile(files.item(0))
						setFileNameValue = files.item(0).name
					} else {
						setValue = null
					}
				} else {
					if (document.getElementById(valuePair[0])?.dataset.number) {
						if (
							document.getElementById(valuePair[0])?.dataset
								.multiple
						) {
							setValue = []
							const values = valuePair[1]
								.valueOf()
								.toString()
								.split(',')
							values.forEach((value: string) => {
								setValue.push(parseFloat(value))
							})
						} else {
							setValue = parseFloat(
								valuePair[1].valueOf().toString()
							)
						}
					} else {
						if (
							document.getElementById(valuePair[0])?.dataset
								.boolean
						) {
							switch (valuePair[1].valueOf()) {
							case 'true':
								setValue = true
								break
							case 'false':
								setValue = false
								break
							default:
								setValue = null
								break
							}
						} else {
							if (
								document.getElementById(valuePair[0])?.dataset
									.checkbox
							) {
								setValue = (
									document.getElementById(
										valuePair[0]
									) as HTMLInputElement
								)?.checked
							} else {
								setValue = valuePair[1].valueOf()
							}
						}
					}
				}
			}
			const keyParts = valuePair[0].split('_')
			if (keyParts.length > 2) {
				let buffer = ''
				let flattened = false
				for (let i = 0; i < keyParts.length - 1; i++) {
					if (i > 0) {
						buffer += '_'
					}

					buffer += keyParts[i]
					if (flattenMap[buffer]) {
						flattened = true
						if (!data[buffer][parseInt(keyParts[i + 1])]) {
							data[buffer][parseInt(keyParts[i + 1])] = {}
						}
						data[buffer][parseInt(keyParts[i + 1])][
							keyParts.slice(i + 2).join('_')
						] = setValue
						if (setFileNameValue) {
							data[buffer][parseInt(keyParts[i + 1])][
								`${keyParts.slice(i + 2).join('_')}_filename`
							] = setFileNameValue
						}
					}
				}
				if (!flattened) {
					data[valuePair[0]] = setValue
					if (setFileNameValue) {
						data[`${valuePair[0]}_filename`] = setFileNameValue
					}
				}
			} else {
				data[valuePair[0]] = setValue
				if (setFileNameValue) {
					data[`${valuePair[0]}_filename`] = setFileNameValue
				}
			}
		}
	}

	return data
}

async function readFile(file: File) {
	const result_base64 = await new Promise((resolve) => {
		const fileReader = new FileReader()
		fileReader.onload = () => resolve(fileReader.result)
		fileReader.readAsDataURL(file)
	})

	return (result_base64 as string).substring(
		(result_base64 as string).indexOf(',') + 1
	)
}

export const DateOnly = (date: string | null): string => {
	if (date == null) {
		return ''
	}

	const tPos = date.indexOf('T')
	if (tPos > -1) {
		return date.replaceAll('/', '-').substring(0, tPos)
	} else {
		return date.replaceAll('/', '-')
	}
}

export const DateTime = (date: string | null): string => {
	if (date == null) {
		return ''
	}

	const dateVal = new Date(date.replaceAll('T00:00:00Z', ''))
	return `${padComponents(
		dateVal.toLocaleDateString()
	)} ${dateVal.toLocaleTimeString()}`
}

const padComponents = (input: string): string => {
	const parts = input.split('/')
	for (let i = 0; i < parts.length; i++) {
		parts[i] = parts[i].padStart(2, '0')
	}
	return parts.join('/')
}
