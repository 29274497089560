import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'

import { APIHostname } from '../../../../Profile'
import { FieldProps } from '../../Field'
import styles from './ContainerType.module.scss'

export const EditContainerType = (props: FieldProps) => {
	const [value, setValue] = useState(props.value)

	const { isPending, error, data } = useQuery({
		queryFn: () =>
			fetch(
				`${APIHostname}/api/v1/container_types?sorts=[{"field":"id","order":"ASC"}]`,
				{
					headers: {
						Authorization: `Bearer ${localStorage['token']}`,
					},
				}
			).then((response) => response.json()),
		queryKey: ['container_types'],
		refetchInterval: 0,
		refetchOnWindowFocus: false,
	})

	if (error) {
		console.error(error) // eslint-disable-line
	}

	if (isPending) {
		return <>Loading...</>
	}

	const containerTypes: { [key: string]: string } = {}
	data.container_types.forEach((containerType: any) => {
		containerTypes[containerType.id] = containerType.name
	})

	if (props.readOnly || props.field.ReadOnly) {
		return (
			<div
				className={styles.fieldOuterContainer}
				id={
					props.field.Property
						? `${props.field.Property}_container`
						: ''
				}
			>
				<div className={styles.label}>
					{props.field.Label}
					{props.field.HardRequired && !props.isSearch ? (
						<>
							{'\u00A0'}
							<span
								className="hardRequired"
								title="REQUIRED IN ORDER TO SAVE"
							>
								*
							</span>
						</>
					) : (
						<></>
					)}
					{props.field.SoftRequired && !props.isSearch ? (
						<>
							{'\u00A0'}
							<span className="softRequired" title="REQUIRED">
								*
							</span>
						</>
					) : (
						<></>
					)}{' '}
				</div>
				<input
					data-hardrequired={
						props.field.HardRequired && !props.isSearch
					}
					data-softrequired={
						props.field.SoftRequired && !props.isSearch
					}
					defaultValue={containerTypes[value]}
					readOnly={true}
					title={props.field.Label?.replace(':', '')}
					type="text"
				/>
			</div>
		)
	}
	return (
		<div
			className={styles.fieldOuterContainer}
			id={props.field.Property ? `${props.field.Property}_container` : ''}
		>
			<div className={styles.label}>
				{' '}
				{props.field.Label}
				{props.field.HardRequired ? (
					<>
						{'\u00A0'}
						<span
							className="hardRequired"
							title="REQUIRED IN ORDER TO SAVE"
						>
							*
						</span>
					</>
				) : (
					<></>
				)}
				{props.field.SoftRequired && !props.isSearch ? (
					<>
						{'\u00A0'}
						<span className="softRequired" title="REQUIRED">
							*
						</span>
					</>
				) : (
					<></>
				)}{' '}
			</div>
			<select
				className={`${
					props.field.HardRequired && !props.isSearch
						? 'hardRequired'
						: ''
				} ${
					props.field.SoftRequired && !props.isSearch
						? 'softRequired'
						: ''
				}`}
				data-hardrequired={props.field.HardRequired && !props.isSearch}
				data-nullable={props.field.Nullable}
				data-number={true}
				data-softrequired={props.field.SoftRequired && !props.isSearch}
				defaultValue={value}
				id={props.field.Property}
				name={props.field.Property}
				onChange={(e: React.ChangeEvent) => {
					setValue((e.target as HTMLSelectElement).value)

					if (props.onChange) {
						props.onChange(e)
					}
				}}
				required={props.field.HardRequired && !props.isSearch}
				title={props.field.Label?.replace(':', '')}
			>
				<option value=""></option>
				{Object.keys(containerTypes).map((key: string) => (
					<option key={`containerType${key}`} value={key}>
						{containerTypes[key]}
					</option>
				))}
			</select>
		</div>
	)
}
