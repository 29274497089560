import styles from './NotFound.module.scss'

export const NotFound = () => {
	return (
		<div className={styles.notFoundContainer}>
			<h1>404 - Not Found</h1>
			Either the page does not exist or you have not been granted access
			to it.
		</div>
	)
}
