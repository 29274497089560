const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
export const RandomID = (length: number) => {
	let result = ''
	for (let i = 0; i < length; i++) {
		result += characters.charAt(
			Math.floor(Math.random() * characters.length)
		)
	}
	return result
}

export const Capitalize = (input: string) => {
	if (!input) {
		return ''
	}

	return input.substring(0, 1).toUpperCase() + input.substring(1)
}

export const EscapeURIParameters = (s: string) => {
	return s
		.replaceAll('%5B', '[')
		.replaceAll('%7B', '{')
		.replaceAll('%2C', ',')
		.replaceAll('%3A', ':')
		.replaceAll('%7D', '}')
		.replaceAll('%5D', ']')
}

export const FormatDateMMDDYYYY = (s: string | Date) => {
	if (!s) {
		return ''
	}
	const d: Date = typeof s == 'string' ? new Date(s) : s
	return `${d.getMonth() + 1}/${d
		.getDate()
		.toString()
		.padStart(2, '0')}/${d.getFullYear()}`
}

export const FormatDateMMDDYYYYToday = (s: string | Date) => {
	if (!s) {
		return ''
	}
	const d: Date = typeof s == 'string' ? new Date(s) : s
	const now = new Date()
	if (
		now.getFullYear() == d.getUTCFullYear() &&
		now.getMonth() == d.getUTCMonth() &&
		now.getDate() == d.getUTCDate()
	) {
		return 'TODAY'
	}

	return `${d.getUTCMonth() + 1}/${d
		.getUTCDate()
		.toString()
		.padStart(2, '0')}/${d.getUTCFullYear()}`
}

export const RemoveEmpty = (obj: any): any => {
	return Object.fromEntries(
		Object.entries(obj)
			// eslint-disable-next-line
			.filter(([_, v]) => {
				switch (typeof v) {
				case 'number':
					return !Number.isNaN(v) && v != null
				case 'string':
					return v != '' && v != null
				default:
					return v != null
				}
			})
			.map(([k, v]) => [k, v === Object(v) ? RemoveEmpty(v) : v])
	)
}

const whitespaceChars: { [key: string]: boolean } = {
	'\t': true,
	'\n': true,
	'\r': true,
	' ': true,
}
export const TrimWhitespace = (input: string): string => {
	let startIndex = 0
	let endIndex = input.length - 1
	for (let i = 0; i < input.length; i++) {
		if (!whitespaceChars[input[i]]) {
			startIndex = i
			break
		}
	}
	for (let i = input.length - 1; i >= 0; i--) {
		if (!whitespaceChars[input[i]]) {
			endIndex = i
			break
		}
	}
	return input.substring(startIndex, endIndex + 1)
}
