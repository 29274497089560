import axios from 'axios'
import { MouseEvent, useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'

import AgentAccessIcon from '../../../../assets/icons/agent-access.svg'
import DownArrowIcon from '../../../../assets/icons/arrow-down.svg'
import CopyIcon from '../../../../assets/icons/copy.svg'
import DeleteIcon from '../../../../assets/icons/delete.svg'
import DocumentsIcon from '../../../../assets/icons/documents.svg'
import OrganizationHistoryIcon from '../../../../assets/icons/organization-history.svg'
import OrganizationInfoIcon from '../../../../assets/icons/organization-info.svg'
import OrganizationUsersIcon from '../../../../assets/icons/organization-users.svg'
import ShipmentsIcon from '../../../../assets/icons/shipments.svg'
import { EditForm, ParsedFormData } from '../../../../components/Form/EditForm'
import { GlobalTopTabItems, TabItem } from '../../../../components/Tabs'
import { DefaultLayout } from '../../../../layouts/DefaultLayout'
import {
	APIHostname,
	HasPermission,
	LogOff,
	ProfileEmail,
	ProfileOrganizationID,
	RenewToken,
} from '../../../../Profile'
import { OrganizationEditLayout } from '../../../../types/data/Organization'
import styles from './Organization.module.scss'

export const Organization = () => {
	const { id } = useParams()
	const [organization, setOrganization]: any = useState(null)
	const [loaded, setLoaded]: any = useState(false)
	const [errorMessage, setErrorMessage] = useState(<></>)
	const initialRedirectMessage = sessionStorage['redirect-message']
	const [redirectMessage] = useState(initialRedirectMessage)

	// Vary based on query string.
	const location = useLocation()

	useEffect(() => {
		axios({
			data: {},
			headers: {
				Authorization: `Bearer ${localStorage['token']}`,
			},
			method: 'GET',
			url: `${APIHostname}/api/v1/organizations/${id}${location.search}`,
		})
			.then((response: any) => {
				setErrorMessage(<></>)
				setOrganization(response.data)
				setLoaded(true)
			})
			.catch((error: any) => {
				switch (error?.response?.status) {
				case 401:
					LogOff('token-expired')
					return
				case 403:
					setErrorMessage(
						<>
								Error: You do not have permission to view
								organizations.
						</>
					)
					break
				default:
					if (error?.response?.data?.error) {
						setErrorMessage(
							error?.response?.data?.link ? (
								<Link to={error?.response?.data?.link}>
										Error:
									{error?.response?.data?.error}
								</Link>
							) : (
								<>{error?.response?.data?.error}</>
							)
						)
					} else {
						setErrorMessage(<>Error: Unknown error.</>)
							console.error(error) // eslint-disable-line
					}
				}
			})
	}, [])

	const OrganizationBottomTabItems = [
		{
			icon: DownArrowIcon,
			text: `ORGANIZATION #${id}`,
			to: `/organizations/${id}`,
		},
	]

	const OrganizationLeftMenuItems = [
		{
			icon: OrganizationInfoIcon,
			requiredPermissions: ['organization.read'],
			text: 'ORGANIZATION INFO',
			to: `/organizations/${id}`,
		},
		{
			icon: DocumentsIcon,
			requiredPermissions: ['shipment.read'],
			text: 'ORGANIZATION DOCUMENTS',
			to: `/organizations/${id}/documents`,
		},
		{
			icon: ShipmentsIcon,
			requiredPermissions: ['shipment.read'],
			text: 'LINKED SHIPMENTS',
			to: `/organizations/${id}/shipments`,
		},
		{
			icon: OrganizationUsersIcon,
			requiredPermissions: ['user.read'],
			text: 'ORGANIZATION USERS',
			to: `/organizations/${id}/users`,
		},
		{
			icon: AgentAccessIcon,
			requiredPermissions: ['organization.manageaccess'],
			text: 'PARTNER ACCESS',
			to: `/organizations/${id}/partner_access`,
		},
		{
			icon: OrganizationHistoryIcon,
			requiredPermissions: ['organization.read'],
			text: 'ORGANIZATION HISTORY',
			to: `/organizations/${id}/history`,
		},
	]

	const OrganizationTabMenuItems: TabItem[] = [
		{
			icon: CopyIcon,
			onClick: async (e: MouseEvent<Element>) => {
				e.preventDefault()

				// eslint-disable-next-line
				const confirmation = confirm(`Really copy organization?`)
				if (!confirmation) {
					return
				}

				const form = document.querySelector('form[name=\'organization\']')
				const formData = new FormData(form as HTMLFormElement)
				const payload = await ParsedFormData(formData, [])
				payload.copy = true
				payload.permissions = {}
				const permissions = document.querySelectorAll(
					'input[type=checkbox]'
				)
				permissions.forEach((permission: Element) => {
					const permissionBox = permission as HTMLInputElement
					payload.permissions[permissionBox.name] =
						permissionBox.checked
				})
				axios({
					data: payload,
					headers: {
						Authorization: `Bearer ${localStorage['token']}`,
						'Content-Type': 'application/json',
					},
					method: 'POST',
					url: `${APIHostname}/api/v1/organizations`,
				})
					.then((response: any) => {
						sessionStorage[
							'redirect-message'
						] = `Organization #${response.data.organization_id} created.`
						window.history.replaceState(
							{},
							'',
							`/organizations/${response.data.organization_id}`
						)
						document.location.reload()
					})
					.catch((error: any) => {
						switch (error?.response?.status) {
						case 401:
							LogOff('token-expired')
							return
						case 403:
							setErrorMessage(
								<>
										Error: You do not have permission to
										edit organizations.
								</>
							)
							break
						default:
							if (error?.response?.data?.error) {
								setErrorMessage(
									error?.response?.data?.link ? (
										<Link
											to={error?.response?.data?.link}
										>
												Error:
											{error?.response?.data?.error}
										</Link>
									) : (
										<>{error?.response?.data?.error}</>
									)
								)
							} else {
								setErrorMessage(<>Error: Unknown error.</>)
									console.error(error) // eslint-disable-line
							}
						}
					})
			},
			requiredPermissions: ['organization.create'],
			requiredRole: 'administrator||memberpartner||partner',
			text: 'COPY',
			to: '#',
		},
	]
	if (organization?.active) {
		OrganizationTabMenuItems.push({
			icon: DeleteIcon,
			onClick: async (e: MouseEvent<Element>) => {
				e.preventDefault()

				// eslint-disable-next-line
				const confirmation = confirm(`Really delete organization?`)
				if (!confirmation) {
					return
				}

				axios({
					headers: {
						Authorization: `Bearer ${localStorage['token']}`,
						'Content-Type': 'application/json',
					},
					method: 'DELETE',
					url: `${APIHostname}/api/v1/organizations/${id}`,
				})
					.then((response: any) => {
						sessionStorage[
							'redirect-message'
						] = `Organization #${response.data.organization_id} deleted.`
						window.history.replaceState({}, '', '/organizations')
						document.location.reload()
					})
					.catch((error: any) => {
						switch (error?.response?.status) {
						case 401:
							LogOff('token-expired')
							return
						case 403:
							setErrorMessage(
								<>
										Error: You do not have permission to
										delete organizations.
								</>
							)
							break
						default:
							if (error?.response?.data?.error) {
								setErrorMessage(
									error?.response?.data?.link ? (
										<Link
											to={error?.response?.data?.link}
										>
												Error:
											{error?.response?.data?.error}
										</Link>
									) : (
										<>{error?.response?.data?.error}</>
									)
								)
							} else {
								setErrorMessage(<>Error: Unknown error.</>)
									console.error(error) // eslint-disable-line
							}
						}
					})
			},
			requiredPermissions: ['organization.delete'],
			text: 'DELETE',
			to: '#',
		})
	} else {
		OrganizationTabMenuItems.push({
			icon: DeleteIcon,
			onClick: async (e: MouseEvent<Element>) => {
				e.preventDefault()

				// eslint-disable-next-line
				const confirmation = confirm(`Really undelete organization?`)
				if (!confirmation) {
					return
				}

				axios({
					headers: {
						Authorization: `Bearer ${localStorage['token']}`,
						'Content-Type': 'application/json',
					},
					method: 'POST',
					url: `${APIHostname}/api/v1/organizations/${id}/undelete`,
				})
					.then((response: any) => {
						sessionStorage[
							'redirect-message'
						] = `Organization #${response.data.organization_id} undeleted.`
						window.history.replaceState({}, '', '/organizations')
						document.location.reload()
					})
					.catch((error: any) => {
						switch (error?.response?.status) {
						case 401:
							LogOff('token-expired')
							return
						case 403:
							setErrorMessage(
								<>
										Error: You do not have permission to
										undelete organizations.
								</>
							)
							break
						default:
							if (error?.response?.data?.error) {
								setErrorMessage(
									error?.response?.data?.link ? (
										<Link
											to={error?.response?.data?.link}
										>
												Error:
											{error?.response?.data?.error}
										</Link>
									) : (
										<>{error?.response?.data?.error}</>
									)
								)
							} else {
								setErrorMessage(<>Error: Unknown error.</>)
									console.error(error) // eslint-disable-line
							}
						}
					})
			},
			requiredPermissions: ['organization.delete'],
			text: 'UNDELETE',
			to: '#',
		})
	}
	OrganizationTabMenuItems.reverse()

	if (!loaded) {
		if (errorMessage) {
			return (
				<DefaultLayout
					bottomTabItems={OrganizationBottomTabItems}
					leftMenuItems={OrganizationLeftMenuItems}
					showPrint={true}
					tabMenuItems={OrganizationTabMenuItems}
					topTabItems={GlobalTopTabItems}
				>
					<div className={styles.organizationContainer}>
						<div className={styles.redirectMessage}>
							{redirectMessage}
						</div>
						<div className={styles.errorMessage}>
							{errorMessage}
						</div>
					</div>
				</DefaultLayout>
			)
		}

		return <div className="loading">Loading...</div>
	}

	sessionStorage.removeItem('redirect-message')

	let readOnly = !HasPermission('organization', 'update')
	if (readOnly && parseInt(id ?? '0') == ProfileOrganizationID()) {
		readOnly = !HasPermission('organization', 'updateself')
	}
	return (
		<DefaultLayout
			bottomTabItems={OrganizationBottomTabItems}
			leftMenuItems={OrganizationLeftMenuItems}
			showPrint={true}
			tabMenuItems={OrganizationTabMenuItems}
			topTabItems={GlobalTopTabItems}
		>
			<div className={styles.organizationContainer}>
				<div className={styles.redirectMessage}>{redirectMessage}</div>
				<div className={styles.errorMessage}>
					{errorMessage}{' '}
					{!organization?.active ? (
						<>This organization has been deleted.</>
					) : (
						<></>
					)}
				</div>

				<EditForm
					data={organization}
					layout={OrganizationEditLayout}
					name="organization"
					onSubmit={async (formData: FormData) => {
						// Update organization
						axios({
							data: await ParsedFormData(formData, []),
							headers: {
								Authorization: `Bearer ${localStorage['token']}`,
								'Content-Type': 'application/json',
							},
							method: 'POST',
							url: `${APIHostname}/api/v1/organizations/${id}`,
						})
							.then(async () => {
								if (
									parseInt(id ?? '0') ==
									ProfileOrganizationID()
								) {
									await RenewToken(
										ProfileEmail(),
										localStorage['remember-me'] == 'true'
									)
								}

								sessionStorage[
									'redirect-message'
								] = `Organization #${id} updated.`
								window.history.replaceState(
									{},
									'',
									'/organizations'
								)
								document.location.reload()
							})
							.catch((error: any) => {
								switch (error?.response?.status) {
								case 401:
									LogOff('token-expired')
									return
								case 403:
									setErrorMessage(
										<>
												Error: You do not have
												permission to edit
												organizations.
										</>
									)
									break
								default:
									if (error?.response?.data?.error) {
										setErrorMessage(
											error?.response?.data?.link ? (
												<Link
													to={
														error?.response
															?.data?.link
													}
												>
														Error:
													{
														error?.response
															?.data?.error
													}
												</Link>
											) : (
												<>
													{
														error?.response
															?.data?.error
													}
												</>
											)
										)
									} else {
										setErrorMessage(
											<>Error: Unknown error.</>
										)
											console.error(error) // eslint-disable-line
									}
								}
							})
					}}
					previewAPIData={async (formData: FormData) => {
						const payload = await ParsedFormData(formData, [])

						return {
							data: JSON.stringify(payload, null, 4),
							method: 'POST',
							url: `${APIHostname}/api/v1/organizations/${id}`,
						}
					}}
					readOnly={readOnly}
				/>
			</div>
		</DefaultLayout>
	)
}
