import { useQuery } from '@tanstack/react-query'
import { FocusEvent, ReactElement, useState } from 'react'

import AddIcon from '../../../../assets/icons/add.svg'
import DeleteIcon from '../../../../assets/icons/delete.svg'
import { APIHostname, ProfileName, ProfileUserID } from '../../../../Profile'
import { DateOnly } from '../../EditForm'
import { FieldProps } from '../../Field'
import { EditPartner } from '../Partner'
import { EditUser } from '../User'
import styles from './NRAsToGOAL.module.scss'

export const EditNRAsToGOAL = (props: FieldProps) => {
	const [nraCount, setNRACount] = useState(
		props.data?.nras_to_goal?.length
			? props.data?.nras_to_goal?.length
			: props.data.id > 0
				? 0
				: 1
	)

	const { isPending, error, data } = useQuery({
		queryFn: () =>
			fetch(
				`${APIHostname}/api/v1/container_types?sorts=[{"field":"id","order":"ASC"}]`,
				{
					headers: {
						Authorization: `Bearer ${localStorage['token']}`,
					},
				}
			).then((response) => response.json()),
		queryKey: ['container_types'],
		refetchInterval: 0,
		refetchOnWindowFocus: false,
	})

	if (error) {
		console.error(error) // eslint-disable-line
	}

	if (isPending) {
		return <>Loading...</>
	}

	const rows: ReactElement[] = []
	for (let i = 0; i < nraCount; i++) {
		if (!props.data) {
			props.data = {}
		}
		if (!props.data.nras_to_goal) {
			props.data.nras_to_goal = {}
		}
		if (!props.data.nras_to_goal[i]) {
			props.data.nras_to_goal[i] = {}
		}
		if (!props.data.nras_to_goal[i].rate_accepted_by) {
			props.data.nras_to_goal[i].rate_accepted_by = 3
			props.data.nras_to_goal[i].rate_accepted_by_name = 'Tony Kozlowski'
			props.data.nras_to_goal[
				i
			].rate_accepted_by_image_preview = `${APIHostname}/api/v1/users/3/profile_picture`
			props.data[`nras_${i}_rate_accepted_by`] = 3
			props.data[`nras_${i}_rate_accepted_by_name`] = 'Tony Kozlowski'
			props.data[
				`nras_${i}_rate_accepted_by_image_preview`
			] = `${APIHostname}/api/v1/users/3/profile_picture`
		}
		if (!props.data.nras_to_goal[i].offered_by) {
			props.data.nras_to_goal[i].offered_by = ProfileUserID()
			props.data.nras_to_goal[i].offered_by_name = ProfileName()
			props.data.nras_to_goal[
				i
			].offered_by_image_preview = `${APIHostname}/api/v1/users/${ProfileUserID()}/profile_picture`
			props.data[`nras_${i}_offered_by`] = ProfileUserID()
			props.data[`nras_${i}_offered_by_name`] = ProfileName()
			props.data[
				`nras_${i}_offered_by_image_preview`
			] = `${APIHostname}/api/v1/users/${ProfileUserID()}/profile_picture`
		}
		if (!props.data.nras_to_goal[i].carrier) {
			if (props.data.origin_partner) {
				props.data.nras_to_goal[i].carrier = props.data.origin_partner
				props.data.nras_to_goal[i].carrier_name =
					props.data.origin_partner_name
				props.data.nras_to_goal[
					i
				].carrier_image_preview = `${APIHostname}/api/v1/partners/${props.data.origin_partner}/logo`
				props.data[`nras_${i}_carrier`] = props.data.origin_partner
				props.data[`nras_${i}_carrier_name`] =
					props.data.origin_partner_name
				props.data[
					`nras_${i}_carrier_image_preview`
				] = `${APIHostname}/api/v1/partners/${props.data.origin_partner}/logo`
			} else {
				props.data.nras_to_goal[i].carrier = -1
				props.data.nras_to_goal[i].carrier_name = '[AUTO-GENERATED]'
				props.data[`nras_${i}_carrier`] = -1
				props.data[`nras_${i}_carrier_name`] = '[AUTO-GENERATED]'
			}
		}

		rows.push(
			<div
				className={styles.row}
				id={`${props.field.Property}_${i}`}
				key={`nra${i}`}
			>
				<input
					data-number={true}
					defaultValue={
						props.data?.nras_to_goal
							? props.data?.nras_to_goal[i]?.id
							: ''
					}
					id={`${props.field.Property}_${i}_id`}
					name={`${props.field.Property}_${i}_id`}
					type="hidden"
				/>
				<input
					data-number={true}
					defaultValue={
						props.data?.nras_to_goal
							? props.data?.nras_to_goal[i]?.shipment
							: ''
					}
					id={`${props.field.Property}_${i}_shipment`}
					name={`${props.field.Property}_${i}_shipment`}
					type="hidden"
				/>
				<input
					data-boolean={true}
					defaultValue={'true'}
					id={`${props.field.Property}_${i}_active`}
					name={`${props.field.Property}_${i}_active`}
					type="hidden"
				/>
				<div
					className={styles.row}
					id={`${props.field.Property}_${i}_contents`}
				>
					<div className={styles.deleteColumn}>
						<div>{i + 1}</div>
						<img
							className={styles.delete}
							onClick={() => {
								// eslint-disable-next-line no-alert
								if (confirm('Really delete NRA?')) {
									(
										document.getElementById(
											`${props.field.Property}_${i}_active`
										) as HTMLInputElement
									).value = 'false'
									;(
										document.getElementById(
											`${props.field.Property}_${i}`
										) as HTMLInputElement
									).style.display = 'none'
									;(
										document.getElementById(
											`${props.field.Property}_${i}_contents`
										) as HTMLInputElement
									).innerHTML = ''

									if (props.onChange) {
										props.onChange(null)
									}
								}
							}}
							src={DeleteIcon}
							title="DELETE ROW"
						/>{' '}
					</div>
					<div className={styles.inputColumn}>
						<div className={styles.row}>
							<div className={styles.oneEighth}>
								<div className={styles.label}>
									NRA FILE #:&nbsp;
									<span
										className="softRequired"
										title="REQUIRED"
									>
										*
									</span>
								</div>
								<input
									className={
										!props.isSearch ? 'softRequired' : ''
									}
									data-softrequired={true}
									defaultValue={
										props.data[props.field.Property] &&
										props.data[props.field.Property][i] &&
										props.data[props.field.Property][i]
											.file_number
											? props.data[props.field.Property][
												i
											  ].file_number
											: '[AUTO-GENERATED]'
									}
									id={`${props.field.Property}_${i}_file_number`}
									name={`${props.field.Property}_${i}_file_number`}
									onChange={props.onChange}
									placeholder=" "
									readOnly={true}
									title={'NRA FILE #'}
									type="text"
								/>
							</div>
							{!props.isSearch ? (
								<>
									<div className={styles.oneEighth}>
										<div className={styles.label}>
											EFFECTIVE DATE:&nbsp;
											<span
												className="hardRequired"
												title="REQUIRED"
											>
												*
											</span>
										</div>
										<input
											className={`${
												!props.isSearch
													? 'hardRequired'
													: ''
											} ${
												!(
													props.data[
														props.field.Property
													] &&
													props.data[
														props.field.Property
													][i] &&
													props.data[
														props.field.Property
													] &&
													props.data[
														props.field.Property
													][i].effective_date
												)
													? 'blank'
													: ''
											}`}
											data-hardrequired={true}
											defaultValue={
												props.data[
													props.field.Property
												] &&
												props.data[
													props.field.Property
												][i]
													? DateOnly(
														props.data[
															props.field
																.Property
														][i].effective_date
													  )!
													: ''
											}
											id={`${props.field.Property}_${i}_effective_date`}
											name={`${props.field.Property}_${i}_effective_date`}
											onChange={(e: any) => {
												if (e.target.value) {
													e.target.classList.remove(
														'blank'
													)
												} else {
													e.target.classList.add(
														'blank'
													)
												}

												if (props.onChange) {
													props.onChange(e)
												}
											}}
											onFocus={(e: FocusEvent) => {
												(
													e.target as HTMLInputElement
												).showPicker()
											}}
											readOnly={
												props.readOnly ||
												props.field.ReadOnly
											}
											title="EFFECTIVE DATE"
											type="date"
										/>
									</div>

									<div className={styles.oneEighth}>
										<div className={styles.label}>
											EXPIRATION DATE:&nbsp;
											<span
												className="hardRequired"
												title="REQUIRED"
											>
												*
											</span>
										</div>
										<input
											className={`${
												!props.isSearch
													? 'hardRequired'
													: ''
											} ${
												!(
													props.data[
														props.field.Property
													] &&
													props.data[
														props.field.Property
													][i] &&
													props.data[
														props.field.Property
													][i].expiration_date
												)
													? 'blank'
													: ''
											}`}
											data-hardrequired={true}
											defaultValue={
												props.data[
													props.field.Property
												] &&
												props.data[
													props.field.Property
												][i]
													? DateOnly(
														props.data[
															props.field
																.Property
														][i].expiration_date
													  )!
													: ''
											}
											id={`${props.field.Property}_${i}_expiration_date`}
											name={`${props.field.Property}_${i}_expiration_date`}
											onChange={(e: any) => {
												if (e.target.value) {
													e.target.classList.remove(
														'blank'
													)
												} else {
													e.target.classList.add(
														'blank'
													)
												}

												if (props.onChange) {
													props.onChange(e)
												}
											}}
											onFocus={(e: FocusEvent) => {
												(
													e.target as HTMLInputElement
												).showPicker()
											}}
											readOnly={
												props.readOnly ||
												props.field.ReadOnly
											}
											title="EXPIRATION DATE"
											type="date"
										/>
									</div>

									<div className={styles.oneEighth}>
										<div className={styles.label}>
											CONTAINER TYPE:&nbsp;
											<span
												className="hardRequired"
												title="REQUIRED IN ORDER TO SAVE"
											>
												*
											</span>
										</div>
										{props.readOnly ||
										props.field.ReadOnly ? (
												<input
													readOnly={true}
													type="text"
													value={
														data.container_types.filter(
															(
																containerType: any
															) => {
																if (
																	props.data
																		?.nras_to_goal
																) {
																	return (
																		containerType.id ==
																	props.data
																		?.nras_to_goal[
																			i
																		]
																		?.container_type
																	)
																}
																return false
															}
														)[0]?.name
													}
												/>
											) : (
												<select
													className={`${
														styles.containerType
													} ${
														!props.isSearch
															? 'hardRequired'
															: ''
													}`}
													data-hardrequired={true}
													data-nullable={
														props.field.Nullable
													}
													data-number={true}
													defaultValue={
														props.data?.nras_to_goal
															? props.data
																?.nras_to_goal[
																	i
														  ]?.container_type
															: ''
													}
													id={`${props.field.Property}_${i}_container_type`}
													name={`${props.field.Property}_${i}_container_type`}
													onChange={props.onChange}
													title="CONTAINER TYPE"
												>
													<option value=""></option>
													{data.container_types.map(
														(containerType: any) => {
															return (
																<option
																	key={
																		containerType.id
																	}
																	value={
																		containerType.id
																	}
																>
																	{
																		containerType.name
																	}
																</option>
															)
														}
													)}
												</select>
											)}
									</div>

									<div className={styles.oneHalf}>
										<div className={styles.label}>
											REMARKS / COMMODITY:
										</div>
										<textarea
											defaultValue={
												props.data[
													props.field.Property
												] &&
												props.data[
													props.field.Property
												][i]
													? props.data[
														props.field.Property
													  ][i].remarks
													: ''
											}
											id={`${props.field.Property}_${i}_remarks`}
											name={`${props.field.Property}_${i}_remarks`}
											placeholder=" "
											readOnly={
												props.readOnly ||
												props.field.ReadOnly
											}
											title="REMARKS"
										></textarea>
									</div>

									<div className={styles.oneEighth}>
										<div className={styles.label}>
											NRA RATE:&nbsp;
											<span
												className="hardRequired"
												title="REQUIRED"
											>
												*
											</span>
										</div>
										<div
											className={`${styles.currency} ${styles.row}`}
										>
											$
											<input
												className={
													!props.isSearch
														? 'hardRequired'
														: ''
												}
												data-hardrequired={true}
												data-number="true"
												defaultValue={
													props.data[
														props.field.Property
													] &&
													props.data[
														props.field.Property
													][i]
														? props.data[
															props.field
																.Property
														  ][i].rate
														: ''
												}
												id={`${props.field.Property}_${i}_rate`}
												name={`${props.field.Property}_${i}_rate`}
												onChange={props.onChange}
												placeholder=" "
												readOnly={
													props.readOnly ||
													props.field.ReadOnly
												}
												step={0.01}
												title="NRA RATE"
												type="number"
											/>
										</div>
									</div>

									<div className={styles.oneEighth}>
										<EditUser
											data={flattenArrays(props.data, '')}
											field={{
												Label: 'OFFERED BY',
												Property: `${props.field.Property}_${i}_offered_by`,
											}}
											isSearch={props.isSearch}
											onChange={props.onChange}
											readOnly={true}
											value={
												props.data?.nras_to_goal &&
												props.data?.nras_to_goal[i] &&
												props.data?.nras_to_goal[i]
													.offered_by
													? props.data?.nras_to_goal[
														i
													  ]?.offered_by
													: ''
											}
										/>
									</div>

									<div className={styles.oneEighth}>
										<EditUser
											data={flattenArrays(props.data, '')}
											field={{
												Label: 'RATE ACCEPTED BY',
												Property: `${props.field.Property}_${i}_rate_accepted_by`,
											}}
											isSearch={props.isSearch}
											onChange={props.onChange}
											readOnly={true}
											value={
												props.data?.nras_to_goal &&
												props.data?.nras_to_goal[i] &&
												props.data?.nras_to_goal[i]
													.rate_accepted_by
													? props.data?.nras_to_goal[
														i
													  ]?.rate_accepted_by
													: ''
											}
										/>
									</div>

									<div className={styles.oneEighth}>
										<EditPartner
											data={flattenArrays(props.data, '')}
											field={{
												Label: 'CARRIER',
												Property: `${props.field.Property}_${i}_carrier`,
											}}
											isSearch={props.isSearch}
											onChange={props.onChange}
											readOnly={true}
											value={
												props.data?.nras_to_goal &&
												props.data?.nras_to_goal[i] &&
												props.data?.nras_to_goal[i]
													.carrier
													? props.data?.nras_to_goal[
														i
													  ]?.carrier
													: ''
											}
										/>
									</div>
								</>
							) : (
								<></>
							)}
						</div>
					</div>
				</div>
			</div>
		)
	}
	if (nraCount == 0) {
		rows.push(
			<div className={`${styles.row} ${styles.emptyRow}`} key="noNRAs">
				No NRAs.
			</div>
		)
	}

	return (
		<div
			className={`${styles.fieldOuterContainer} ${
				!props.isSearch ? styles.editForm : ''
			}`}
			id={props.field.Property ? `${props.field.Property}_container` : ''}
			key="addNRA"
		>
			{rows}
			{!(props.isSearch || props.readOnly) ? (
				<div className={styles.buttonRow}>
					<button type="button">
						<img
							alt="ADD NRA"
							onClick={() => {
								setNRACount(nraCount + 1)
								if (props.onChange) {
									props.onChange(null)
									setTimeout(() => {
										if (props.onChange) {
											props.onChange(null)
										}
									}, 100)
									setTimeout(() => {
										if (props.onChange) {
											props.onChange(null)
										}
									}, 250)
									setTimeout(() => {
										if (props.onChange) {
											props.onChange(null)
										}
									}, 500)
									setTimeout(() => {
										if (props.onChange) {
											props.onChange(null)
										}
									}, 1000)
									setTimeout(() => {
										if (props.onChange) {
											props.onChange(null)
										}
									}, 2500)
								}
							}}
							src={AddIcon}
						/>
					</button>
				</div>
			) : (
				<></>
			)}
		</div>
	)
}

export const flattenArrays = (input: any, prefix: string) => {
	let output: any = {}
	Object.keys(input).forEach((key: string) => {
		if (Array.isArray(input[key])) {
			input[key].forEach((subitem: any, index: number) => {
				output = {
					...output,
					...flattenArrays(subitem, `${prefix}${key}_${index}_`),
				}
			})
		} else {
			output[`${prefix}${key}`] = input[key]
		}
	})
	return output
}
