import { ReactNode } from 'react'

import styles from './Modal.module.scss'

export const Modal = (props: ModalProps) => {
	const eventListener = (e: KeyboardEvent) => {
		if (e.key == 'Escape') {
			props.setShowModal(false)
			document.removeEventListener('keydown', eventListener)
			e.stopPropagation()
		}
		return
	}
	document.addEventListener('keydown', eventListener)

	return (
		<div
			className={styles.modalOuterContainer}
			onMouseDown={() => {
				props.setShowModal(false)
				document.removeEventListener('keydown', eventListener)
			}}
		>
			<div
				className={styles.modalInnerContainer}
				onMouseDown={(e: React.MouseEvent) => {
					props.setShowModal(false)
					document.removeEventListener('keydown', eventListener)
					e.stopPropagation()
				}}
			>
				<div
					className={styles.modalContentContainer}
					onMouseDown={(e: React.MouseEvent) => {
						e.stopPropagation()
					}}
				>
					<div className={styles.modalCloseContainer}>
						<div
							onClick={(e: React.MouseEvent) => {
								props.setShowModal(false)
								document.removeEventListener(
									'keydown',
									eventListener
								)
								e.stopPropagation()
							}}
						>
							CLOSE
						</div>
					</div>
					{props.children}
				</div>
			</div>
		</div>
	)
}

export type ModalProps = {
	children?: ReactNode
	setShowModal: React.Dispatch<React.SetStateAction<boolean>>
}
