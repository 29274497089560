import axios from 'axios'
import { useState } from 'react'
import { Link } from 'react-router-dom'

import DownArrowIcon from '../../../../assets/icons/arrow-down.svg'
import CarriersIcon from '../../../../assets/icons/carriers.svg'
import { EditForm, ParsedFormData } from '../../../../components/Form/EditForm'
import { GlobalTopTabItems } from '../../../../components/Tabs'
import { DefaultLayout } from '../../../../layouts/DefaultLayout'
import { APIHostname, HasPermission, LogOff } from '../../../../Profile'
import { NewCarrierLayout } from '../../../../types/data/Carrier'
import styles from './CarriersNew.module.scss'

export const CarriersNew = () => {
	const [errorMessage, setErrorMessage] = useState(<></>)
	const initialRedirectMessage = sessionStorage['redirect-message']
	const [redirectMessage] = useState(initialRedirectMessage)

	/* eslint-disable @typescript-eslint/ban-types */
	sessionStorage.removeItem('redirect-message')
	return (
		<DefaultLayout
			bottomTabItems={CarriersNewBottomTabItems}
			leftMenuItems={CarriersNewLeftMenuItems}
			showPrint={true}
			tabMenuItems={CarriersNewTabMenuItems}
			topTabItems={GlobalTopTabItems}
		>
			<div className={styles.carrierContainer}>
				<div className={styles.redirectMessage}>{redirectMessage}</div>
				<div className={styles.errorMessage}>{errorMessage}</div>

				<EditForm
					data={{}}
					layout={NewCarrierLayout}
					name="carrier"
					onSubmit={async (formData: FormData) => {
						// Create carrier
						const parsedFormData = await ParsedFormData(
							formData,
							[]
						)
						axios({
							data: parsedFormData,
							headers: {
								Authorization: `Bearer ${localStorage['token']}`,
								'Content-Type': 'application/json',
							},
							method: 'POST',
							url: `${APIHostname}/api/v1/carriers`,
						})
							.then((response: any) => {
								sessionStorage[
									'redirect-message'
								] = `Carrier #${response.data.carrier_id} created.`
								window.history.replaceState(
									{},
									'',
									`/carriers/${response.data.carrier_id}`
								)
								document.location.reload()
							})
							.catch((error: any) => {
								switch (error?.response?.status) {
								case 401:
									LogOff('token-expired')
									return
								case 403:
									setErrorMessage(
										<>
												Error: You do not have
												permission to edit carriers.
										</>
									)
									break
								default:
									if (error?.response?.data?.error) {
										setErrorMessage(
											error?.response?.data?.link ? (
												<Link
													to={
														error?.response
															?.data?.link
													}
												>
														Error:
													{
														error?.response
															?.data?.error
													}
												</Link>
											) : (
												<>
													{
														error?.response
															?.data?.error
													}
												</>
											)
										)
									} else {
										setErrorMessage(
											<>Error: Unknown error.</>
										)
											console.error(error) // eslint-disable-line
									}
								}
							})
					}}
					previewAPIData={async (formData: FormData) => {
						const parsedFormData = await ParsedFormData(
							formData,
							[]
						)

						return {
							data: JSON.stringify(parsedFormData, null, 4),
							method: 'POST',
							url: `${APIHostname}/api/v1/carriers`,
						}
					}}
					readOnly={!HasPermission('carrier', 'create')}
				/>
			</div>
		</DefaultLayout>
	)
	/* eslint-enable @typescript-eslint/ban-types */
}

export const CarriersNewTabMenuItems = []

export const CarriersNewBottomTabItems = [
	{
		icon: DownArrowIcon,
		text: 'ADD A CARRIER',
		to: '/carriers/new',
	},
]

export const CarriersNewLeftMenuItems = [
	{
		icon: CarriersIcon,
		requiredRole: 'administrator',
		text: 'CARRIERS',
		to: '/carriers',
	},
]
