import axios from 'axios'
import { useState } from 'react'
import { Link } from 'react-router-dom'

import DownArrowIcon from '../../../../assets/icons/arrow-down.svg'
import ExpiredIcon from '../../../../assets/icons/expire.svg'
import OpenNRAIcon from '../../../../assets/icons/open-nra.svg'
import { EditForm, ParsedFormData } from '../../../../components/Form/EditForm'
import { GlobalTopTabItems } from '../../../../components/Tabs'
import { DefaultLayout } from '../../../../layouts/DefaultLayout'
import {
	APIHostname,
	HasPermission,
	LogOff,
	ProfileName,
	ProfileUserID,
} from '../../../../Profile'
import { NewNRALayout } from '../../../../types/data/NRA'
import { NRAsTabMenuItems } from '..'
import styles from './NRANew.module.scss'

export const NRAsNew = () => {
	const [errorMessage, setErrorMessage] = useState(<></>)
	const initialRedirectMessage = sessionStorage['redirect-message']
	const [redirectMessage] = useState(initialRedirectMessage)

	sessionStorage.removeItem('redirect-message')
	return (
		<DefaultLayout
			bottomTabItems={NRAsNewBottomTabItems}
			leftMenuItems={NRAsNewLeftMenuItems}
			showPrint={true}
			tabMenuItems={NRAsTabMenuItems}
			topTabItems={GlobalTopTabItems}
		>
			<div className={styles.nraContainer}>
				<div className={styles.redirectMessage}>{redirectMessage}</div>
				<div className={styles.errorMessage}>{errorMessage}</div>

				<EditForm
					data={{
						effective_date: new Date().toISOString().split('T')[0],
						presented_by: ProfileUserID(),
						presented_by_image_preview: `${APIHostname}/api/v1/users/${ProfileUserID()}/preview`,
						presented_by_name: ProfileName(),
					}}
					layout={NewNRALayout}
					name="nra"
					onSubmit={async (formData: FormData) => {
						// Create shipment
						axios({
							data: await ParsedFormData(formData, ['rates']),
							headers: {
								Authorization: `Bearer ${localStorage['token']}`,
								'Content-Type': 'application/json',
							},
							method: 'POST',
							url: `${APIHostname}/api/v1/nras`,
						})
							.then((response: any) => {
								sessionStorage[
									'redirect-message'
								] = `NRA #${response.data.nra_id} created.`
								window.history.replaceState(
									{},
									'',
									`/nra/${response.data.nra_id}`
								)
								document.location.reload()
							})
							.catch((error: any) => {
								switch (error?.response?.status) {
								case 401:
									LogOff('token-expired')
									return
								case 403:
									setErrorMessage(
										<>
												Error: You do not have
												permission to edit NRAs.
										</>
									)
									break
								default:
									if (error?.response?.data?.error) {
										setErrorMessage(
											error?.response?.data?.link ? (
												<Link
													to={
														error?.response
															?.data?.link
													}
												>
														Error:
													{
														error?.response
															?.data?.error
													}
												</Link>
											) : (
												<>
													{
														error?.response
															?.data?.error
													}
												</>
											)
										)
									} else {
										setErrorMessage(
											<>Error: Unknown error.</>
										)
											console.error(error) // eslint-disable-line
									}
								}
							})
					}}
					previewAPIData={async (formData: FormData) => {
						const payload = await ParsedFormData(formData, [
							'rates',
						])

						return {
							data: JSON.stringify(payload, null, 4),
							method: 'POST',
							url: `${APIHostname}/api/v1/nras`,
						}
					}}
					readOnly={!HasPermission('nra', 'update')}
				/>
			</div>
		</DefaultLayout>
	)
}

export const NRAsNewBottomTabItems = [
	{
		icon: DownArrowIcon,
		requiredPermissions: ['nra.create'],
		text: 'NEW NRA',
		to: '/nra/new',
	},
]

const tomorrow = new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
export const NRAsNewLeftMenuItems = [
	{
		icon: OpenNRAIcon,
		requiredPermissions: ['nra.read'],
		text: 'OPEN NRAs',
		to: '/nra?open',
	},
	{
		icon: ExpiredIcon,
		requiredPermissions: ['nra.read'],
		text: 'EXPIRED NRAs',
		to: `/nra?filters=[{%22field%22:%22expiration_date%22,%22operator%22:%22lt%22,%22value%22:%22${tomorrow.getFullYear()}-${(
			tomorrow.getMonth() + 1
		)
			.toString()
			.padStart(2, '0')}-${tomorrow.getDate()}%22}]`,
	},
]
