import axios from 'axios'
import { useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'

import DownArrowIcon from '../../../../../assets/icons/arrow-down.svg'
import ChainIcon from '../../../../../assets/icons/chain.svg'
import DocumentsIcon from '../../../../../assets/icons/documents.svg'
import NRAHistoryIcon from '../../../../../assets/icons/history.svg'
import NRAInfoIcon from '../../../../../assets/icons/open-nra.svg'
import {
	EditForm,
	ParsedFormData,
} from '../../../../../components/Form/EditForm'
import { Modal } from '../../../../../components/Modal'
import { NavTable } from '../../../../../components/NavTable'
import { NRASummary } from '../../../../../components/NRASummary'
import { GlobalTopTabItems } from '../../../../../components/Tabs'
import { DefaultLayout } from '../../../../../layouts/DefaultLayout'
import { APIHostname, HasPermission, LogOff } from '../../../../../Profile'
import Control from '../../../../../types/Control'
import { DocumentLayout } from '../../../../../types/data/Document'
import { EscapeURIParameters } from '../../../../../Utils'
import styles from './NRADocuments.module.scss'

export const NRADocuments = () => {
	const { id } = useParams()

	const [documents, setDocuments]: any = useState(null)
	const [nra, setNRA]: any = useState(null)
	const [nraLoaded, setNRALoaded] = useState(false)
	const [documentsLoaded, setDocumentsLoaded] = useState(false)
	const [documentCount, setDocumentCount] = useState(0)
	const [errorMessage, setErrorMessage] = useState(<></>)
	const initialRedirectMessage = sessionStorage['redirect-message']
	const [redirectMessage] = useState(initialRedirectMessage)
	const [showModal, setShowModal] = useState(false)

	// Vary based on query string.
	const location = useLocation()
	const urlParams = new URLSearchParams(location.search)
	if (!urlParams.get('limit')) {
		urlParams.set('limit', '25')
	}

	useEffect(() => {
		axios({
			data: {},
			headers: {
				Authorization: `Bearer ${localStorage['token']}`,
			},
			method: 'GET',
			url: `${APIHostname}/api/v1/nras/${id}`,
		})
			.then((nraResponse: any) => {
				setErrorMessage(<></>)
				setNRA(nraResponse.data)
				setNRALoaded(true)
			})
			.catch((error: any) => {
				switch (error?.response?.status) {
				case 401:
					LogOff('token-expired')
					return
				case 403:
					setErrorMessage(
						<>
								Error: You do not have permission to edit nra
								documents.
						</>
					)
					break
				default:
					if (error?.response?.data?.error) {
						setErrorMessage(
							error?.response?.data?.link ? (
								<Link to={error?.response?.data?.link}>
										Error:
									{error?.response?.data?.error}
								</Link>
							) : (
								<>{error?.response?.data?.error}</>
							)
						)
					} else {
						setErrorMessage(<>Error: Unknown error.</>)
							console.error(error) // eslint-disable-line
					}
				}
			})

		axios({
			data: {},
			headers: {
				Authorization: `Bearer ${localStorage['token']}`,
			},
			method: 'GET',
			url: `${APIHostname}/api/v1/nras/${id}/documents?${EscapeURIParameters(
				urlParams.toString()
			)}&view=browse`,
		})
			.then((response: any) => {
				setErrorMessage(<></>)
				setDocuments(response.data.documents)
				setDocumentCount(response.data.document_count)
				setDocumentsLoaded(true)
			})
			.catch((error: any) => {
				if (error?.response?.status == 401) {
					LogOff('token-expired')
					return
				}
				console.error(error) // eslint-disable-line
				setErrorMessage(<>Error: Unknown error.</>)
			})
	}, [location])

	const NRALeftMenuItems = [
		{
			icon: NRAInfoIcon,
			requiredPermissions: ['nra.read'],
			text: 'NRA INFO',
			to: `/nra/${id}`,
		},
		{
			icon: DocumentsIcon,
			requiredPermissions: ['nra.read'],
			text: 'NRA DOCUMENTS',
			to: `/nra/${id}/documents`,
		},
		{
			icon: ChainIcon,
			requiredPermissions: ['shipment.read'],
			text: 'LINKED SHIPMENTS',
			to: `/nra/${id}/shipments`,
		},
		{
			icon: NRAHistoryIcon,
			requiredPermissions: ['nra.read'],
			text: 'NRA HISTORY',
			to: `/nra/${id}/history`,
		},
	]

	const NRABottomTabItems = [
		{
			icon: DownArrowIcon,
			text: `NRA #${id} DOCUMENTS`,
			to: `/nra/${id}/documents`,
		},
	]

	if (!nraLoaded || !documentsLoaded) {
		if (errorMessage) {
			return (
				<DefaultLayout
					bottomTabItems={NRABottomTabItems}
					leftMenuItems={NRALeftMenuItems}
					showPrint={true}
					tabMenuItems={NRATabMenuItems}
					topTabItems={GlobalTopTabItems}
				>
					<div className={styles.nraDocumentsContainer}>
						<div className={styles.redirectMessage}>
							{redirectMessage}
						</div>
						<div className={styles.errorMessage}>
							{errorMessage}
						</div>
					</div>
				</DefaultLayout>
			)
		}

		return <div className="loading">Loading...</div>
	}

	sessionStorage.removeItem('redirect-message')
	return (
		<DefaultLayout
			bottomTabItems={NRABottomTabItems}
			leftMenuItems={NRALeftMenuItems}
			showPrint={true}
			tabMenuItems={NRATabMenuItems}
			topTabItems={GlobalTopTabItems}
		>
			{showModal ? (
				<Modal setShowModal={setShowModal}>
					<h2>UPLOAD A DOCUMENT</h2>
					<EditForm
						data={{}}
						layout={DocumentLayout}
						name="document-form"
						onSubmit={async (formData: FormData) => {
							const parsedFormData = await ParsedFormData(
								formData,
								[]
							)
							setShowModal(false)
							parsedFormData['nra'] = parseInt(id ?? '0')

							// Create nra document
							axios({
								data: parsedFormData,
								headers: {
									Authorization: `Bearer ${localStorage['token']}`,
									'Content-Type': 'application/json',
								},
								method: 'POST',
								url: `${APIHostname}/api/v1/nras/${id}/documents`,
							})
								.then((response: any) => {
									setShowModal(false)
									sessionStorage[
										'redirect-message'
									] = `Document #${response.data.document_id} created.`
									document.location.reload()
								})
								.catch((error: any) => {
									switch (error?.response?.status) {
									case 401:
										LogOff('token-expired')
										return
									case 403:
										setErrorMessage(
											<>
													Error: You do not have
													permission to edit nra
													documents.
											</>
										)
										break
									default:
										if (error?.response?.data?.error) {
											setErrorMessage(
												error?.response?.data
													?.link ? (
														<Link
															to={
																error?.response
																	?.data?.link
															}
														>
															Error:
															{
																error?.response
																	?.data
																	?.error
															}
														</Link>
													) : (
														<>
															{
																error?.response
																	?.data
																	?.error
															}
														</>
													)
											)
										} else {
											setErrorMessage(
												<>Error: Unknown error.</>
											)
												console.error(error) // eslint-disable-line
										}
									}
								})
						}}
						previewAPIData={async (formData: FormData) => {
							const parsedFormData = await ParsedFormData(
								formData,
								[]
							)

							return {
								data: JSON.stringify(parsedFormData, null, 4),
								method: 'POST',
								url: `${APIHostname}/api/v1/nras/${id}/documents`,
							}
						}}
						readOnly={
							!HasPermission('nra', 'update') || !nra?.active
						}
					></EditForm>
				</Modal>
			) : (
				<></>
			)}
			<div className={styles.nraDocumentsContainer}>
				<NRASummary nra={nra}>
					{HasPermission('nra', 'update') ? (
						<div className={styles.uploadButton}>
							<input
								className="pseudoSubmit"
								onClick={() => {
									setShowModal(true)
								}}
								type="button"
								value="UPLOAD FILE"
							/>
						</div>
					) : (
						<></>
					)}
				</NRASummary>

				<div className={styles.redirectMessage}>{redirectMessage}</div>
				<div className={styles.errorMessage}>
					{errorMessage}{' '}
					{!nra?.active ? <>This NRA has been deleted.</> : <></>}
				</div>

				<div className={styles.navTable}>
					<NavTable
						columns={[
							{
								control: Control.FileName,
								id: 'blob_filename',
								title: 'FILE NAME',
							},
							{
								id: 'description',
								title: 'Description',
							},
							{
								id: 'type',
								title: 'Type',
							},
							{
								control: Control.FileSize,
								id: 'size',
								title: 'Size',
							},
							{
								control: Control.DateTime,
								id: 'created',
								title: 'Uploaded Time',
							},
							{
								control: Control.User,
								id: 'created_by',
								title: 'Uploaded By',
							},
							{
								control: Control.Download,
								id: 'download',
								title: 'Download',
							},
						]}
						count={documentCount}
						data={documents}
						filters={JSON.parse(urlParams.get('filters') ?? '[]')}
						includeDelete={HasPermission('nra', 'delete')}
						includeExport={false}
						primaryDisplayColumn=""
						primaryKey=""
					/>
				</div>
			</div>
		</DefaultLayout>
	)
}

export const NRATabMenuItems = []
