import Control from '../Control'
import { Layout } from '../Layout'

export const PayWithdrawLayout: Layout = {
	Groups: [
		{
			Border: true,
			Direction: 'column',
			Flex: '1 1',
			Margin: '1rem 0 0 0',
			OptionalRows: [
				{
					Fields: [
						{
							Control: Control.Currency,
							HardRequired: true,
							Label: 'AMOUNT',
							Property: 'amount',
							Width: 50,
						},
						{
							Label: 'PARTNER\'S REFERENCE NUMBER',
							Property: 'origin_reference',
							SoftRequired: true,
							Width: 50,
						},
					],
				},
				{
					Fields: [
						{
							Control: Control.TextBox,
							Label: 'MEMO',
							Property: 'memo',
							SoftRequired: true,
							Width: 100,
						},
					],
				},
			],

			Width: 100,
		},
	],
}
