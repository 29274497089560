import { Suspense } from 'react'
import {
	createBrowserRouter,
	createRoutesFromElements,
	Navigate,
	Outlet,
	Route,
	RouterProvider,
} from 'react-router-dom'

import { Header } from './components/Header'
import { Loader } from './components/Loader'
import { APIReference } from './pages/APIReference'
import { AMSCodes } from './pages/Authenticated/AMSCodes'
import { ArrivalNotices } from './pages/Authenticated/ArrivalNotices'
import { ArrivalNotice } from './pages/Authenticated/ArrivalNotices/ArrivalNotice'
import { ArrivalNoticeHistory } from './pages/Authenticated/ArrivalNotices/ArrivalNotice/History'
import { ArrivalNoticesNew } from './pages/Authenticated/ArrivalNotices/New'
import { Carriers } from './pages/Authenticated/Carriers'
import { Carrier } from './pages/Authenticated/Carriers/Carrier'
import { CarrierHistory } from './pages/Authenticated/Carriers/Carrier/History'
import { CarrierShipments } from './pages/Authenticated/Carriers/Carrier/Shipments'
import { CarriersNew } from './pages/Authenticated/Carriers/New'
import { ContainerTypes } from './pages/Authenticated/ContainerTypes'
import { Countries } from './pages/Authenticated/Countries'
import { Dashboard } from './pages/Authenticated/Dashboard'
import { EmailTemplates } from './pages/Authenticated/EmailTemplates'
import { EmailTemplate } from './pages/Authenticated/EmailTemplates/EmailTemplate'
import { EmailTemplatesNew } from './pages/Authenticated/EmailTemplates/New'
import { NRAs } from './pages/Authenticated/NRAs'
import { NRAsNew } from './pages/Authenticated/NRAs/New'
import { NRA } from './pages/Authenticated/NRAs/NRA'
import { NRADocuments } from './pages/Authenticated/NRAs/NRA/Documents'
import { NRAHistory } from './pages/Authenticated/NRAs/NRA/History'
import { NRAShipments } from './pages/Authenticated/NRAs/NRA/Shipments'
import { Organizations } from './pages/Authenticated/Organizations'
import { OrganizationsNew } from './pages/Authenticated/Organizations/New'
import { Organization } from './pages/Authenticated/Organizations/Organization'
import { OrganizationDocuments } from './pages/Authenticated/Organizations/Organization/Documents'
import { OrganizationHistory } from './pages/Authenticated/Organizations/Organization/History'
import { PartnerAccess } from './pages/Authenticated/Organizations/Organization/PartnerAccess'
import { OrganizationShipments } from './pages/Authenticated/Organizations/Organization/Shipments'
import { OrganizationUsers } from './pages/Authenticated/Organizations/Organization/Users'
import { OrganizationUsersNew } from './pages/Authenticated/Organizations/Organization/Users/New'
import { Partners } from './pages/Authenticated/Partners'
import { PartnersNew } from './pages/Authenticated/Partners/New'
import { Partner } from './pages/Authenticated/Partners/Partner'
import { PartnerDocuments } from './pages/Authenticated/Partners/Partner/Documents'
import { PartnerHistory } from './pages/Authenticated/Partners/Partner/History'
import { OrganizationAccess } from './pages/Authenticated/Partners/Partner/OrganizationAccess'
import { NonMemberPartnerAccess } from './pages/Authenticated/Partners/Partner/PartnerAccess'
import { PartnerShipments } from './pages/Authenticated/Partners/Partner/Shipments'
import { PartnerUsers } from './pages/Authenticated/Partners/Partner/Users'
import { PartnerUsersNew } from './pages/Authenticated/Partners/Partner/Users/New'
import { PayBalance } from './pages/Authenticated/Pay/Balance'
import { PayDeposit } from './pages/Authenticated/Pay/Deposit'
import { PayIncomingRequests } from './pages/Authenticated/Pay/IncomingRequests'
import { PayRequest } from './pages/Authenticated/Pay/Request'
import { PaySend } from './pages/Authenticated/Pay/Send'
import { PayTransactions } from './pages/Authenticated/Pay/Transactions'
import { PayWithdraw } from './pages/Authenticated/Pay/Withdraw'
import { Ports } from './pages/Authenticated/Ports'
import { PortsNew } from './pages/Authenticated/Ports/New'
import { Port } from './pages/Authenticated/Ports/Port'
import { PortShipments } from './pages/Authenticated/Ports/Port/Shipments'
import { Reports } from './pages/Authenticated/Reports'
import { NonMemberPartnerBillingReport } from './pages/Authenticated/Reports/NonMemberPartnerBilling'
import { Shipments } from './pages/Authenticated/Shipments'
import { ShipmentsAMS } from './pages/Authenticated/Shipments/AMS'
import { ShipmentsDocuments } from './pages/Authenticated/Shipments/Documents'
import { ShipmentsNew } from './pages/Authenticated/Shipments/New'
import { Shipment } from './pages/Authenticated/Shipments/Shipment'
import { ShipmentACI } from './pages/Authenticated/Shipments/Shipment/ACI'
import { ShipmentAMS } from './pages/Authenticated/Shipments/Shipment/AMS'
import { ShipmentArrivalNotice } from './pages/Authenticated/Shipments/Shipment/ArrivalNotice'
import { ShipmentArrivalNoticeOverride } from './pages/Authenticated/Shipments/Shipment/ArrivalNotice/Override'
import { ShipmentDocuments } from './pages/Authenticated/Shipments/Shipment/Documents'
import { ShipmentHBL } from './pages/Authenticated/Shipments/Shipment/HBL'
import { ShipmentHistory } from './pages/Authenticated/Shipments/Shipment/History'
import { ShipmentISF } from './pages/Authenticated/Shipments/Shipment/ISF'
import { ShipmentNRA } from './pages/Authenticated/Shipments/Shipment/NRA'
import { ShipmentRemarks } from './pages/Authenticated/Shipments/Shipment/Remarks'
import { ShipmentTracking } from './pages/Authenticated/Shipments/Shipment/Tracking'
import { Sitemap } from './pages/Authenticated/Sitemap'
import { Users } from './pages/Authenticated/Users'
import { UsersNew } from './pages/Authenticated/Users/New'
import { User } from './pages/Authenticated/Users/User'
import { UserHistory } from './pages/Authenticated/Users/User/History'
import { Notifications } from './pages/Authenticated/Users/User/Notifications'
import { UserAPI } from './pages/Authenticated/Users/User/UserAPI'
import { CarrierTracking } from './pages/CarrierTracking'
import { ForgotPassword } from './pages/LogIn/ForgotPassword'
import { ChangePassword } from './pages/LogIn/ForgotPassword/ChangePassword'
import { VerifyEmail } from './pages/LogIn/Register/VerifyEmail'
import { NotFound } from './pages/NotFound'

export const AuthenticatedRouter = () => {
	const router = createBrowserRouter([
		{
			children: createRoutesFromElements(
				<>
					<Route
						element={<Navigate to="/users" />}
						path="/administration"
					/>
					<Route
						element={<Navigate to="/users" />}
						path="/administration/lists"
					/>
					<Route element={<AMSCodes />} path="/ams_codes" />
					<Route element={<APIReference />} path="/api" />
					<Route element={<APIReference />} path="/api/v1" />
					<Route
						element={<ArrivalNotices />}
						path="/arrival_notices"
					/>
					<Route
						element={<ArrivalNoticesNew />}
						path="/arrival_notices/new"
					/>
					<Route
						element={<ArrivalNotice />}
						path="/arrival_notices/:id"
					/>
					<Route
						element={<ArrivalNoticeHistory />}
						path="/arrival_notices/:id/history"
					/>
					<Route
						element={<CarrierTracking />}
						path="/carrier-tracking"
					/>
					<Route element={<Carriers />} path="/carriers" />
					<Route element={<CarriersNew />} path="/carriers/new" />
					<Route element={<Carrier />} path="/carriers/:id" />
					<Route
						element={<CarrierHistory />}
						path="/carriers/:id/history"
					/>
					<Route
						element={<CarrierShipments />}
						path="/carriers/:id/shipments"
					/>
					<Route
						element={<ContainerTypes />}
						path="/container_types"
					/>
					<Route element={<Countries />} path="/countries" />
					<Route
						element={<Navigate to="/organizations" />}
						path="/customers"
					/>
					<Route element={<Navigate to="/dashboard" />} index />
					<Route element={<Dashboard />} path="/dashboard" />
					<Route
						element={<EmailTemplates />}
						path="/email_templates"
					/>
					<Route
						element={<EmailTemplatesNew />}
						path="/email_templates/new"
					/>
					<Route
						element={<EmailTemplate />}
						path="/email_templates/:id"
					/>
					<Route
						element={<ForgotPassword />}
						path="/forgot-password"
					/>
					<Route
						element={<ChangePassword />}
						path="/forgot-password/change"
					/>
					<Route element={<NRAs />} path="/nra" />
					<Route element={<NRA />} path="/nra/:id" />
					<Route
						element={<NRADocuments />}
						path="/nra/:id/documents"
					/>
					<Route element={<NRAHistory />} path="/nra/:id/history" />
					<Route
						element={<NRAShipments />}
						path="/nra/:id/shipments"
					/>
					<Route element={<NRAsNew />} path="/nra/new" />
					<Route element={<Navigate to="/nra" />} path="/nras" />
					<Route element={<Navigate to="/nra" />} path="/nras/*" />
					<Route element={<Organizations />} path="/organizations" />
					<Route
						element={<OrganizationsNew />}
						path="/organizations/new"
					/>
					<Route
						element={<Organization />}
						path="/organizations/:id"
					/>
					<Route
						element={<OrganizationDocuments />}
						path="/organizations/:id/documents"
					/>
					<Route
						element={<OrganizationHistory />}
						path="/organizations/:id/history"
					/>
					<Route
						element={<PartnerAccess />}
						path="/organizations/:id/partner_access"
					/>
					<Route
						element={<OrganizationShipments />}
						path="/organizations/:id/shipments"
					/>
					<Route
						element={<OrganizationUsers />}
						path="/organizations/:id/users"
					/>
					<Route
						element={<OrganizationUsersNew />}
						path="/organizations/:id/users/new"
					/>
					<Route element={<Partners />} path="/partners" />
					<Route element={<PartnersNew />} path="/partners/new" />
					<Route element={<Partner />} path="/partners/:id" />
					<Route
						element={<PartnerDocuments />}
						path="/partners/:id/documents"
					/>
					<Route
						element={<PartnerHistory />}
						path="/partners/:id/history"
					/>
					<Route
						element={<OrganizationAccess />}
						path="/partners/:id/organization_access"
					/>
					<Route
						element={<NonMemberPartnerAccess />}
						path="/partners/:id/partner_access"
					/>
					<Route
						element={<PayBalance />}
						path="/partners/:id/pay/balance"
					/>
					<Route
						element={<PayDeposit />}
						path="/partners/:id/pay/deposit"
					/>
					<Route
						element={<PayIncomingRequests />}
						path="/partners/:id/pay/incoming_requests"
					/>
					<Route
						element={<PayRequest />}
						path="/partners/:id/pay/request"
					/>
					<Route
						element={<PaySend />}
						path="/partners/:id/pay/send"
					/>
					<Route
						element={<PayTransactions />}
						path="/partners/:id/pay/transactions"
					/>
					<Route
						element={<PayWithdraw />}
						path="/partners/:id/pay/withdraw"
					/>
					<Route
						element={<PartnerShipments />}
						path="/partners/:id/shipments"
					/>
					<Route
						element={<PartnerUsers />}
						path="/partners/:id/users"
					/>
					<Route
						element={<PartnerUsersNew />}
						path="/partners/:id/users/new"
					/>
					<Route
						element={<Navigate to="/pay/balance" />}
						path="/pay"
					/>
					<Route element={<PayBalance />} path="/pay/balance" />
					<Route
						element={<PayIncomingRequests />}
						path="/pay/incoming_requests"
					/>
					<Route element={<PayRequest />} path="/pay/request" />
					<Route element={<PaySend />} path="/pay/send" />
					<Route
						element={<PayTransactions />}
						path="/pay/transactions"
					/>
					<Route element={<Ports />} path="/ports" />
					<Route element={<Port />} path="/ports/:code" />
					<Route
						element={<PortShipments />}
						path="/ports/:code/shipments"
					/>
					<Route element={<PortsNew />} path="/ports/new" />
					<Route element={<VerifyEmail />} path="/register/verify" />
					<Route element={<Reports />} path="/reports" />
					<Route
						element={<NonMemberPartnerBillingReport />}
						path="/reports/nonmemberpartnerbilling"
					/>
					<Route element={<Shipments />} path="/shipments" />
					<Route element={<Shipment />} path="/shipments/:id" />
					<Route element={<ShipmentsAMS />} path="/shipments/ams" />
					<Route
						element={<ShipmentsDocuments />}
						path="/shipments/documents"
					/>
					<Route element={<ShipmentsNew />} path="/shipments/new" />
					<Route
						element={<ShipmentACI />}
						path="/shipments/:id/aci"
					/>
					<Route
						element={<ShipmentAMS />}
						path="/shipments/:id/ams"
					/>
					<Route
						element={<ShipmentArrivalNotice />}
						path="/shipments/:id/arrival_notice"
					/>
					<Route
						element={<ShipmentArrivalNoticeOverride />}
						path="/shipments/:id/arrival_notice/nal"
					/>
					<Route
						element={<ShipmentArrivalNoticeOverride />}
						path="/shipments/:id/arrival_notice/override"
					/>
					<Route
						element={<ShipmentDocuments />}
						path="/shipments/:id/documents"
					/>
					<Route
						element={<ShipmentHBL />}
						path="/shipments/:id/hbl"
					/>
					<Route
						element={<ShipmentHistory />}
						path="/shipments/:id/history"
					/>
					<Route
						element={<ShipmentISF />}
						path="/shipments/:id/isf"
					/>
					<Route
						element={<ShipmentNRA />}
						path="/shipments/:id/nra"
					/>
					<Route
						element={<ShipmentRemarks />}
						path="/shipments/:id/remarks"
					/>
					<Route
						element={<ShipmentTracking />}
						path="/shipments/:id/tracking"
					/>
					<Route element={<Sitemap />} path="/sitemap" />
					<Route element={<Users />} path="/users" />
					<Route element={<User />} path="/users/:id" />
					<Route element={<UserAPI />} path="/users/:id/api" />
					<Route
						element={<UserHistory />}
						path="/users/:id/history"
					/>
					<Route
						element={<Notifications />}
						path="/users/:id/notifications"
					/>
					<Route element={<UsersNew />} path="/users/new" />
					<Route element={<NotFound />} path="*" />
				</>
			),
			element: (
				<>
					<Header />
					<Outlet />
				</>
			),
		},
	])

	return (
		<Suspense fallback={<Loader />}>
			<RouterProvider router={router} />
		</Suspense>
	)
}
