import axios from 'axios'
import { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

import DownArrowIcon from '../../../assets/icons/arrow-down.svg'
import NewIcon from '../../../assets/icons/new.svg'
import { SearchForm } from '../../../components/Form/SearchForm'
import { AdministrationLeftMenuItems } from '../../../components/LeftMenu'
import { NavTable } from '../../../components/NavTable'
import { GlobalTopTabItems } from '../../../components/Tabs'
import { DefaultLayout } from '../../../layouts/DefaultLayout'
import { APIHostname, HasRole, LogOff } from '../../../Profile'
import Control from '../../../types/Control'
import { SearchPortLayout } from '../../../types/data/Port'
import { EscapeURIParameters } from '../../../Utils'
import styles from './Ports.module.scss'

export const Ports = () => {
	const [ports, setPorts]: any = useState(null)
	const [portCount, setPortCount] = useState(0)
	const [loaded, setLoaded]: any = useState(false)
	const [errorMessage, setErrorMessage] = useState(<></>)
	const initialRedirectMessage = sessionStorage['redirect-message']
	const [redirectMessage] = useState(initialRedirectMessage)

	// Vary based on query string.
	const location = useLocation()
	const urlParams = new URLSearchParams(location.search)
	if (!urlParams.get('limit')) {
		urlParams.set('limit', '25')
	}

	useEffect(() => {
		axios({
			data: {},
			headers: {
				Authorization: `Bearer ${localStorage['token']}`,
			},
			method: 'GET',
			url: `${APIHostname}/api/v1/ports?${EscapeURIParameters(
				urlParams.toString()
			)}`,
		})
			.then((response: any) => {
				setErrorMessage(<></>)
				setPorts(response.data.ports)
				setPortCount(response.data.port_count)
				setLoaded(true)
			})
			.catch((error: any) => {
				switch (error?.response?.status) {
				case 401:
					LogOff('token-expired')
					return
				case 403:
					setErrorMessage(
						<>
								Error: You do not have permission to view ports.
						</>
					)
					break
				default:
					if (error?.response?.data?.error) {
						setErrorMessage(
							error?.response?.data?.link ? (
								<Link to={error?.response?.data?.link}>
										Error:
									{error?.response?.data?.error}
								</Link>
							) : (
								<>{error?.response?.data?.error}</>
							)
						)
					} else {
						setErrorMessage(<>Error: Unknown error.</>)
							console.error(error) // eslint-disable-line
					}
				}
			})
	}, [location])

	if (!loaded) {
		if (errorMessage) {
			return (
				<DefaultLayout
					bottomTabItems={PortsBottomTabItems}
					leftMenuItems={AdministrationLeftMenuItems}
					showPrint={true}
					tabMenuItems={PortsTabMenuItems}
					topTabItems={GlobalTopTabItems}
				>
					<div className={styles.portsContainer}>
						<div className={styles.redirectMessage}>
							{redirectMessage}
						</div>
						<div className={styles.errorMessage}>
							{errorMessage}
						</div>
					</div>
				</DefaultLayout>
			)
		}

		return <div className="loading">Loading...</div>
	}

	sessionStorage.removeItem('redirect-message')
	return (
		<DefaultLayout
			bottomTabItems={PortsBottomTabItems}
			leftMenuItems={AdministrationLeftMenuItems}
			showPrint={true}
			tabMenuItems={PortsTabMenuItems}
			topTabItems={GlobalTopTabItems}
		>
			<div className={styles.portsContainer}>
				<div className={styles.redirectMessage}>{redirectMessage}</div>
				<div className={styles.errorMessage}>{errorMessage}</div>

				<div className={styles.searchForm}>
					<SearchForm
						data={{}}
						layout={SearchPortLayout}
						name="ports"
						readOnly={false}
					/>
				</div>

				<NavTable
					columns={[
						{
							control: Control.Port,
							id: 'code',
							title: 'Port Code',
						},
						{
							control: Control.Country,
							id: 'country_code',
							title: 'Country',
						},
						{
							id: 'state',
							title: 'State',
						},
						{
							id: 'city_code',
							title: 'City',
						},
						{
							id: 'name',
							title: 'Port Name',
						},
						{
							id: 'dcode',
							title: 'D Code',
						},
						{
							id: 'kcode',
							title: 'K Code',
						},
						{
							control: Control.Boolean,
							id: 'is_ocean_port',
							title: 'Ocean Port',
						},
						{
							control: Control.Boolean,
							id: 'is_air_port',
							title: 'Air Port',
						},
						{
							children: [
								{
									control: Control.DateTime,
									id: 'created',
									title: 'Creation Date',
								},
								{
									control: Control.User,
									id: 'created_by',
									title: 'Created By',
								},
							],
						},
						{
							children: [
								{
									control: Control.DateTime,
									id: 'modified',
									title: 'Modified Date',
								},
								{
									control: Control.User,
									id: 'modified_by',
									title: 'Modified By',
								},
							],
						},
					]}
					count={portCount}
					data={ports}
					filters={JSON.parse(urlParams.get('filters') ?? '[]')}
					includeDelete={HasRole('administrator')}
					primaryDisplayColumn="code"
					primaryKey="code"
				/>
			</div>
		</DefaultLayout>
	)
}

export const PortsTabMenuItems = [
	{
		icon: NewIcon,
		requiredRole: 'administrator',
		text: 'NEW',
		to: '/ports/new',
	},
]

export const PortsBottomTabItems = [
	{
		icon: DownArrowIcon,
		text: 'PORTS',
		to: '/ports',
	},
]
