import Control from '../Control'
import { Layout } from '../Layout'

export const ArrivalNoticeOverrideLayout: Layout = {
	Groups: [
		{
			Label: 'OVERRIDE VALUES (ONLY VISIBLE ON ARRIVAL NOTICE)',
			LabelBackground: 'blue',
			Width: 100,
		},
		{
			Border: true,
			Groups: [
				{
					Direction: 'row',
					Flex: '1 1 0%',
					Groups: [
						{
							RequiredRows: [
								{
									Fields: [
										{
											Label: 'HBL',
											Property: 'override_hbl',
											Width: 50,
										},
										{
											Control: Control.Date,
											Label: 'DATE',
											Property: 'override_date',
											Width: 50,
										},
									],
								},
								{
									Fields: [
										{
											Control: Control.Boolean,
											Label: 'ENDORSED ORIGINAL REQUIRED',
											Property:
												'endorsed_original_required',
											Width: 50,
										},
										{
											Label: 'REFERENCE #',
											Property: 'override_reference',
											Width: 50,
										},
									],
								},
								{
									Fields: [
										{
											Control: Control.Partner,
											Label: 'ORIGIN PARTNER',
											Property: 'origin_partner',
											Width: 50,
										},
										{
											Control: Control.Partner,
											Label: 'DESTINATION PARTNER',
											Property: 'destination_partner',
											Width: 50,
										},
									],
								},
								{
									Fields: [
										{
											Control:
												Control.OrganizationTextbox,
											Label: 'SHIPPER',
											Property: 'override_shipper',
											SubType: 'shipper',
											Width: 33,
										},
										{
											Control:
												Control.OrganizationTextbox,
											Label: 'CONSIGNEE',
											Property: 'override_consignee',
											SubType: 'consignee',
											Width: 33,
										},
										{
											Control:
												Control.OrganizationTextbox,
											Label: 'NOTIFY',
											Property: 'override_notify',
											Width: 33,
										},
									],
								},
								{
									Fields: [
										{
											HardRequired: true,
											Label: 'IT#',
											Property: 'it_number',
											Width: 33,
										},
										{
											Control: Control.Date,
											HardRequired: true,
											Label: 'IT DATE',
											Property: 'it_date',
											Width: 33,
										},
										{
											HardRequired: true,
											Label: 'IT ISSUE',
											Property: 'it_place_of_issue',
											Width: 33,
										},
									],
								},
								{
									Fields: [
										{
											Label: 'MBL#',
											Property: 'override_mbl',
											Width: 33,
										},
										{
											Label: 'VESSEL',
											Property: 'override_vessel',
											Width: 33,
										},
										{
											Label: 'VOYAGE',
											Property: 'override_voyage',
											Width: 33,
										},
									],
								},
								{
									Fields: [
										{
											Label: 'AMS SCAC',
											Property: 'override_ams_scac',
											Width: 33,
										},
										{
											Label: 'AMS',
											Property: 'override_ams',
											Width: 33,
										},
										{
											Label: 'PORT OF LOAD',
											Property: 'override_port_of_load',
											Width: 33,
										},
									],
								},
								{
									Fields: [
										{
											Label: 'PORT OF DISCHARGE',
											Property:
												'override_port_of_discharge',
											Width: 50,
										},
										{
											Label: 'ETA PORT OF DISCHARGE',
											Property:
												'override_port_of_discharge_eta',
											Width: 50,
										},
									],
								},
								{
									Fields: [
										{
											Label: 'PLACE OF DELIVERY',
											Property:
												'override_place_of_delivery',
											Width: 50,
										},
										{
											Label: 'PLACE OF DELIVERY ETA',
											Property:
												'override_place_of_delivery_eta',
											Width: 50,
										},
									],
								},
								{
									Fields: [
										{
											Label: 'KGS/CBM/PCS',
											Property: 'override_kgs_cbm_pieces',
											Width: 50,
										},
										{
											Control: Control.TextBox,
											HardRequired: true,
											Label: 'CARGO LOCATION',
											Property: 'cargo_location',
											Width: 50,
										},
									],
								},
								{
									Fields: [
										{
											Control: Control.TextBox,
											Label: 'CONTAINER NUMBERS',
											Property:
												'override_container_numbers',
											Width: 50,
										},
										{
											Control: Control.TextBox,
											Label: 'DESCRIPTION',
											Property: 'cargo_description',
											Width: 50,
										},
									],
								},
							],
							Width: 100,
						},
					],
				},
			],
			Padding: '0',
			Width: 100,
		},
		{
			Label: 'CHARGES',
			LabelBackground: 'blue',
			Padding: '0',
			RequiredRows: [
				{
					Fields: [
						{
							Control: Control.Charges,
							Property: 'charges',
							Width: 100,
						},
					],
				},
			],

			Width: 100,
		},
		{
			Border: true,
			Direction: 'column',
			Groups: [
				{
					Direction: 'row',
					Flex: '1 1 0%',
					Groups: [
						{
							Flex: '1 1 0%',
							RequiredRows: [
								{
									Fields: [
										{
											Control: Control.TextBox,
											Label: 'NOTES',
											Property: 'arrival_notice_notes',
											Width: 100,
										},
									],
								},
							],
							Width: 50,
						},
					],
				},
			],
			Width: 100,
		},
		{
			Groups: [
				{
					Border: true,
					RequiredRows: [
						{
							Fields: [
								{
									Label: 'FILENAME',
									Property: 'filename',
									Width: 25,
								},
								{
									Label: '',
									Property: '',
									Width: 25,
								},
								{
									Label: '',
									Property: '',
									Width: 25,
								},
								{
									Label: '',
									Property: '',
									Width: 25,
								},
							],
						},
						{
							Fields: [
								{
									Control: Control.Checkbox,
									Label: 'SAVE TO DOCUMENTS',
									Property: 'save',
									Width: 100,
								},
							],
						},
					],
					Width: 100,
				},
			],
			Width: 100,
		},
	],
}

export const StandaloneArrivalNoticeLayout: Layout = {
	Groups: [
		{
			Border: true,
			Groups: [
				{
					Direction: 'row',
					Flex: '1 1 0%',
					Groups: [
						{
							RequiredRows: [
								{
									Fields: [
										{
											HardRequired: true,
											Label: 'HBL',
											Property: 'override_hbl',
											Width: 50,
										},
										{
											Control: Control.Date,
											HardRequired: true,
											Label: 'DATE',
											Property: 'override_date',
											Width: 50,
										},
									],
								},
								{
									Fields: [
										{
											Control: Control.Boolean,
											Label: 'ENDORSED ORIGINAL REQUIRED',
											Property:
												'endorsed_original_required',
											Width: 50,
										},
										{
											Label: 'REFERENCE #',
											Property: 'override_reference',
											Width: 50,
										},
									],
								},
								{
									Fields: [
										{
											Control: Control.Partner,
											HardRequired: true,
											Label: 'DESTINATION PARTNER',
											Property: 'destination_partner',
											Width: 100,
										},
									],
								},
								{
									Fields: [
										{
											Control:
												Control.OrganizationTextbox,
											Label: 'SHIPPER',
											Property: 'override_shipper',
											SubType: 'shipper',
											Width: 33,
										},
										{
											Control:
												Control.OrganizationTextbox,
											Label: 'CONSIGNEE',
											Property: 'override_consignee',
											SubType: 'consignee',
											Width: 33,
										},
										{
											Control:
												Control.OrganizationTextbox,
											Label: 'NOTIFY',
											Property: 'override_notify',
											Width: 33,
										},
									],
								},
								{
									Fields: [
										{
											Label: 'IT#',
											Property: 'it_number',
											Width: 33,
										},
										{
											Control: Control.Date,
											Label: 'IT DATE',
											Property: 'override_it_date',
											Width: 33,
										},
										{
											Label: 'IT ISSUE',
											Property: 'it_place_of_issue',
											Width: 33,
										},
									],
								},
								{
									Fields: [
										{
											Label: 'MBL#',
											Property: 'override_mbl',
											Width: 33,
										},
										{
											Label: 'VESSEL',
											Property: 'override_vessel',
											Width: 33,
										},
										{
											Label: 'VOYAGE',
											Property: 'override_voyage',
											Width: 33,
										},
									],
								},
								{
									Fields: [
										{
											Label: 'AMS SCAC',
											Property: 'override_ams_scac',
											Width: 33,
										},
										{
											Label: 'AMS',
											Property: 'override_ams',
											Width: 33,
										},
										{
											Label: 'PORT OF LOAD',
											Property: 'override_port_of_load',
											Width: 33,
										},
									],
								},
								{
									Fields: [
										{
											Label: 'PORT OF DISCHARGE',
											Property:
												'override_port_of_discharge',
											Width: 50,
										},
										{
											Label: 'ETA PORT OF DISCHARGE',
											Property:
												'override_port_of_discharge_eta',
											Width: 50,
										},
									],
								},
								{
									Fields: [
										{
											Label: 'PLACE OF DELIVERY',
											Property:
												'override_place_of_delivery',
											Width: 50,
										},
										{
											Label: 'PLACE OF DELIVERY ETA',
											Property:
												'override_place_of_delivery_eta',
											Width: 50,
										},
									],
								},
								{
									Fields: [
										{
											Label: 'KGS/CBM/PCS',
											Property: 'override_kgs_cbm_pieces',
											Width: 50,
										},
										{
											Control: Control.TextBox,
											Label: 'CARGO LOCATION',
											Property: 'cargo_location',
											Width: 50,
										},
									],
								},
								{
									Fields: [
										{
											Control: Control.TextBox,
											Label: 'CONTAINER NUMBERS',
											Property:
												'override_container_numbers',
											Width: 50,
										},
										{
											Control: Control.TextBox,
											Label: 'DESCRIPTION',
											Property: 'cargo_description',
											Width: 50,
										},
									],
								},
							],
							Width: 100,
						},
					],
				},
			],
			Padding: '0',
			Width: 100,
		},
		{
			Label: 'CHARGES',
			LabelBackground: 'blue',
			Padding: '0',
			RequiredRows: [
				{
					Fields: [
						{
							Control: Control.Charges,
							Property: 'charges',
							Width: 100,
						},
					],
				},
			],

			Width: 100,
		},
		{
			Border: true,
			Direction: 'column',
			Groups: [
				{
					Direction: 'row',
					Flex: '1 1 0%',
					Groups: [
						{
							Flex: '1 1 0%',
							RequiredRows: [
								{
									Fields: [
										{
											Control: Control.TextBox,
											Label: 'NOTES',
											Property: 'arrival_notice_notes',
											Width: 100,
										},
									],
								},
							],
							Width: 50,
						},
					],
				},
			],
			Width: 100,
		},
	],
}

export const ArrivalNoticeSearchLayout: Layout = {
	Groups: [
		{
			RequiredRows: [
				{
					Fields: [
						{
							IncludeInEdit: false,
							IncludeInSearch: true,
							Label: 'WILDCARD (SEARCH ALL FIELDS; USE "OR" FOR MULTIPLE CRITERIA)',
							Property: '*',
							Width: 100,
						},
					],
				},
			],
			Width: 100,
		},
	],
}
