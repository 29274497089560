import axios from 'axios'
import { useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'

import ACIIcon from '../../../../../assets/icons/aci.svg'
import AMSIcon from '../../../../../assets/icons/ams.svg'
import ArrivalIcon from '../../../../../assets/icons/arrival-notice.svg'
import DownArrowIcon from '../../../../../assets/icons/arrow-down.svg'
import BookingIcon from '../../../../../assets/icons/booking.svg'
import DocumentsIcon from '../../../../../assets/icons/documents.svg'
import ShipmentHistoryIcon from '../../../../../assets/icons/history.svg'
import ISFIcon from '../../../../../assets/icons/isf.svg'
import NRAIcon from '../../../../../assets/icons/open-nra.svg'
import RemarksIcon from '../../../../../assets/icons/remarks.svg'
import ShipmentTrackingIcon from '../../../../../assets/icons/tracking.svg'
import {
	EditForm,
	ParsedFormData,
} from '../../../../../components/Form/EditForm'
import { Modal } from '../../../../../components/Modal'
import { NavTable } from '../../../../../components/NavTable'
import { ShipmentSummary } from '../../../../../components/ShipmentSummary'
import { GlobalTopTabItems } from '../../../../../components/Tabs'
import { DefaultLayout } from '../../../../../layouts/DefaultLayout'
import { APIHostname, HasPermission, LogOff } from '../../../../../Profile'
import Control from '../../../../../types/Control'
import { RemarkLayout } from '../../../../../types/data/Remark'
import { EscapeURIParameters } from '../../../../../Utils'
import styles from './ShipmentRemarks.module.scss'

export const ShipmentRemarks = () => {
	const { id } = useParams()

	const [remarks, setRemarks]: any = useState(null)
	const [shipment, setShipment]: any = useState(null)
	const [shipmentLoaded, setShipmentLoaded] = useState(false)
	const [remarksLoaded, setRemarksLoaded] = useState(false)
	const [remarkCount, setRemarkCount] = useState(0)
	const [errorMessage, setErrorMessage] = useState(<></>)
	const initialRedirectMessage = sessionStorage['redirect-message']
	const [redirectMessage] = useState(initialRedirectMessage)
	const [showModal, setShowModal] = useState(false)

	// Vary based on query string.
	const location = useLocation()
	const urlParams = new URLSearchParams(location.search)
	if (!urlParams.get('limit')) {
		urlParams.set('limit', '25')
	}

	useEffect(() => {
		axios({
			data: {},
			headers: {
				Authorization: `Bearer ${localStorage['token']}`,
			},
			method: 'GET',
			url: `${APIHostname}/api/v1/shipments/${id}`,
		})
			.then((shipmentResponse: any) => {
				setErrorMessage(<></>)
				setShipment(shipmentResponse.data)
				setShipmentLoaded(true)
			})
			.catch((error: any) => {
				switch (error?.response?.status) {
				case 401:
					LogOff('token-expired')
					return
				case 403:
					setErrorMessage(
						<>
								Error: You do not have permission to view
								shipments.
						</>
					)
					break
				default:
					if (error?.response?.data?.error) {
						setErrorMessage(
							error?.response?.data?.link ? (
								<Link to={error?.response?.data?.link}>
										Error:
									{error?.response?.data?.error}
								</Link>
							) : (
								<>{error?.response?.data?.error}</>
							)
						)
					} else {
						setErrorMessage(<>Error: Unknown error.</>)
							console.error(error) // eslint-disable-line
					}
				}
			})

		axios({
			data: {},
			headers: {
				Authorization: `Bearer ${localStorage['token']}`,
			},
			method: 'GET',
			url: `${APIHostname}/api/v1/shipments/${id}/remarks?${EscapeURIParameters(
				urlParams.toString()
			)}&view=browse`,
		})
			.then((response: any) => {
				setErrorMessage(<></>)
				setRemarks(response.data.remarks)
				setRemarkCount(response.data.remark_count)
				setRemarksLoaded(true)
			})
			.catch((error: any) => {
				if (error?.response?.status == 401) {
					LogOff('token-expired')
					return
				}
				console.error(error) // eslint-disable-line
				setErrorMessage(<>Error: Unknown error.</>)
			})
	}, [location])

	const arrivalNoticeTemplate = shipment?.arrival_notice_template
	const usDestination =
		shipment?.discharge_port?.toLowerCase().startsWith('us') ||
		shipment?.final_port_of_entry?.toLowerCase().startsWith('us')
	const caDestination =
		shipment?.discharge_port?.toLowerCase().startsWith('ca') ||
		shipment?.final_port_of_entry?.toLowerCase().startsWith('ca')
	const ShipmentLeftMenuItems = [
		{
			icon: BookingIcon,
			text: 'BOOKING',
			to: `/shipments/${id}`,
		},
		{
			count: shipment?.unread_remarks ?? null,
			icon: RemarksIcon,
			text: 'REMARKS',
			to: `/shipments/${id}/remarks`,
		},
		{
			disabled: !usDestination && !shipment?.ams_status,
			disabledReason: 'Shipment does not have US destination ports.',
			icon: AMSIcon,
			requiredRole: 'administrator||memberpartner||partner',
			text: 'AMS',
			to: `/shipments/${id}/ams`,
		},
		{
			disabled: !caDestination && !shipment?.aci_status,
			disabledReason: 'Shipment does not have CA destination ports.',
			icon: ACIIcon,
			requiredRole: 'administrator||memberpartner||partner',
			text: 'ACI',
			to: `/shipments/${id}/aci`,
		},
		{
			disabled: !usDestination,
			disabledReason: 'Shipment does not have US destination ports.',
			icon: ISFIcon,
			requiredRole: 'administrator||memberpartner||partner',
			text: 'ISF',
			to: `/shipments/${id}/isf`,
		},
		{
			icon: DocumentsIcon,
			text: 'DOCUMENTS',
			to: `/shipments/${id}/documents`,
		},
		{
			icon: DocumentsIcon,
			requiredRole: 'administrator||memberpartner||partner',
			text: 'HBL PRINT',
			to: `/shipments/${id}/hbl`,
		},
		{
			disabled: !usDestination,
			disabledReason: 'Shipment does not have US destination ports.',
			icon: NRAIcon,
			requiredRole: 'administrator||memberpartner||partner',
			text: 'NRA',
			to: `/shipments/${id}/nra`,
		},
		{
			disabled: !arrivalNoticeTemplate,
			disabledReason:
				'Destination partner does not have an Arrival Notice template.',
			icon: ArrivalIcon,
			requiredRole: 'administrator||memberpartner||partner',
			text: 'ARRIVAL NOTICE',
			to: `/shipments/${id}/arrival_notice${
				arrivalNoticeTemplate == 'default'
					? ''
					: `/${arrivalNoticeTemplate}`
			}`,
		},
		{
			icon: ShipmentTrackingIcon,
			requiredRole: 'administrator||memberpartner||partner||organization',
			text: 'SHIPMENT TRACKING',
			to: `/shipments/${id}/tracking`,
		},
		{
			icon: ShipmentHistoryIcon,
			text: 'SHIPMENT HISTORY',
			to: `/shipments/${id}/history`,
		},
	]

	const ShipmentRemarksBottomTabItems = [
		{
			icon: DownArrowIcon,
			text: `SHIPMENT #${id} REMARKS`,
			to: `/shipments/${id}/remarks`,
		},
	]

	if (!shipmentLoaded || !remarksLoaded) {
		if (errorMessage) {
			return (
				<DefaultLayout
					bottomTabItems={ShipmentRemarksBottomTabItems}
					leftMenuItems={ShipmentLeftMenuItems}
					showPrint={true}
					tabMenuItems={ShipmentTabMenuItems}
					topTabItems={GlobalTopTabItems}
				>
					<div className={styles.shipmentRemarksContainer}>
						<div className={styles.redirectMessage}>
							{redirectMessage}
						</div>
						<div className={styles.errorMessage}>
							{errorMessage}
						</div>
					</div>
				</DefaultLayout>
			)
		}

		return <div className="loading">Loading...</div>
	}

	sessionStorage.removeItem('redirect-message')
	return (
		<DefaultLayout
			bottomTabItems={ShipmentRemarksBottomTabItems}
			leftMenuItems={ShipmentLeftMenuItems}
			showPrint={true}
			tabMenuItems={ShipmentTabMenuItems}
			topTabItems={GlobalTopTabItems}
		>
			<>
				{showModal ? (
					<Modal setShowModal={setShowModal}>
						<h2>ADD A REMARK</h2>
						<EditForm
							data={{}}
							layout={RemarkLayout}
							name="remark-form"
							onSubmit={async (formData: FormData) => {
								const parsedFormData = await ParsedFormData(
									formData,
									[]
								)
								setShowModal(false)
								parsedFormData['shipment'] = parseInt(id ?? '0')

								// Create remark
								axios({
									data: parsedFormData,
									headers: {
										Authorization: `Bearer ${localStorage['token']}`,
										'Content-Type': 'application/json',
									},
									method: 'POST',
									url: `${APIHostname}/api/v1/shipments/${id}/remarks`,
								})
									.then(() => {
										sessionStorage['redirect-message'] =
											'Shipment remark created.'
										document.location.reload()
									})
									.catch((error: any) => {
										switch (error?.response?.status) {
										case 401:
											LogOff('token-expired')
											return
										case 403:
											setErrorMessage(
												<>
														Error: You do not have
														permission to edit
														shipment remarks.
												</>
											)
											break
										default:
											if (
												error?.response?.data
													?.message
											) {
												setErrorMessage(
													error?.response?.data
														?.link ? (
															<Link
																to={
																	error
																		?.response
																		?.data
																		?.link
																}
															>
																Error:
																{
																	error
																		?.response
																		?.data
																		?.error
																}
															</Link>
														) : (
															<>
																{
																	error
																		?.response
																		?.data
																		?.error
																}
															</>
														)
												)
											} else {
												setErrorMessage(
													<>
															Error: Unknown
															error.
													</>
												)
													console.error(error) // eslint-disable-line
											}
										}
									})
							}}
							previewAPIData={async (formData: FormData) => {
								const parsedFormData = await ParsedFormData(
									formData,
									[]
								)
								parsedFormData['shipment'] = parseInt(id ?? '0')

								return {
									data: JSON.stringify(
										parsedFormData,
										null,
										4
									),
									method: 'POST',
									url: `${APIHostname}/api/v1/shipments/${id}/remarks`,
								}
							}}
							readOnly={!HasPermission('shipment', 'read')}
						></EditForm>
					</Modal>
				) : (
					<></>
				)}
				<div className={styles.shipmentRemarksContainer}>
					<ShipmentSummary shipment={shipment}>
						{' '}
						<div className={styles.addButton}>
							<input
								className="pseudoSubmit"
								onClick={() => {
									setShowModal(true)
									document
										.getElementsByName('remark')[0]
										?.focus()
								}}
								type="button"
								value="ADD REMARK"
							/>
						</div>
					</ShipmentSummary>

					<div className={styles.redirectMessage}>
						{redirectMessage}
					</div>
					<div className={styles.errorMessage}>
						{errorMessage}
						{errorMessage}{' '}
						{!shipment?.active ? (
							<>This shipment has been deleted.</>
						) : (
							<></>
						)}
					</div>

					<div className={styles.navTable}>
						<NavTable
							columns={[
								{
									id: 'remark',
									title: 'Remark',
								},
								{
									control: Control.DateTime,
									id: 'created',
									title: 'Created',
								},
								{
									control: Control.User,
									id: 'created_by',
									title: 'Created By',
								},
								{
									control: Control.Read,
									endpoint: 'remarks',
									id: 'read',
									title: 'Read',
								},
								{
									control: Control.User,
									endpoint: 'remarks',
									id: 'read_by',
									title: 'Read By',
								},
								{
									control: Control.ReadStatus,
									endpoint: 'remarks',
									id: 'read_status',
									title: 'Read Status',
								},
							]}
							count={remarkCount}
							data={remarks}
							filters={JSON.parse(
								urlParams.get('filters') ?? '[]'
							)}
							includeDelete={false}
							includeExport={false}
							primaryDisplayColumn=""
							primaryKey=""
						/>
					</div>
				</div>
			</>
		</DefaultLayout>
	)
}

export const ShipmentTabMenuItems = []
