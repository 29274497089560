import { FieldProps } from '../../Field'
import styles from './Calculation.module.scss'

export const EditCalculation = (props: FieldProps) => {
	let val: any = null
	switch (props.field.Property) {
	case 'name':
		val = `${props.data.first_name ?? ''} ${
			props.data.last_name ?? ''
		}`.trim()
		break
	case 'total_cbm':
		val = 0
		for (let i = 0; i < props.data.containers?.length; i++) {
			val += props.data.containers[i].cbm
		}
		break
	case 'total_packages':
		val = 0
		for (let i = 0; i < props.data.containers?.length; i++) {
			val += props.data.containers[i].packages
		}
		break
	case 'total_gross_weight':
		val = 0
		for (let i = 0; i < props.data.containers?.length; i++) {
			val += props.data.containers[i].gross_weight
		}
		break
	}

	return (
		<div
			className={styles.fieldOuterContainer}
			id={props.field.Property ? `${props.field.Property}_container` : ''}
		>
			<div className={styles.label}>{props.field.Label}</div>
			<input
				data-hardrequired={props.field.HardRequired && !props.isSearch}
				data-number={props.field.Property != 'name' ? true : null}
				data-softrequired={props.field.SoftRequired && !props.isSearch}
				id={props.field.Property}
				name={props.field.Property}
				readOnly={true}
				tabIndex={-1}
				title={props.field.Label?.replace(':', '')}
				type={props.field.Property == 'name' ? 'text' : 'number'}
				value={val ? val : props.value}
			/>
		</div>
	)
}
