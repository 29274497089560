import axios from 'axios'
import { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

import LateACIIcon from '../../../../assets/icons/aci.svg'
import DownArrowIcon from '../../../../assets/icons/arrow-down.svg'
import BookingIcon from '../../../../assets/icons/booking.svg'
import DashboardIcon from '../../../../assets/icons/dashboard.svg'
import DataCorrectionIcon from '../../../../assets/icons/data-correction.svg'
import NewDocumentsIcon from '../../../../assets/icons/documents.svg'
import HotListActiveIcon from '../../../../assets/icons/hot-active.svg'
import HotListInactiveIcon from '../../../../assets/icons/hot-inactive.svg'
import HotListIcon from '../../../../assets/icons/hot-list.svg'
import LateAMSIcon from '../../../../assets/icons/late-ams.svg'
import LockedIcon from '../../../../assets/icons/locked.svg'
import NewIcon from '../../../../assets/icons/new.svg'
import MissingNRAIcon from '../../../../assets/icons/nra.svg'
import NewRemarksIcon from '../../../../assets/icons/remarks.svg'
import UnconfirmedIcon from '../../../../assets/icons/unconfirmed-booking.svg'
import { SearchForm } from '../../../../components/Form/SearchForm'
import { NavTable } from '../../../../components/NavTable'
import { TabItem } from '../../../../components/Tabs'
import { GlobalTopTabItems } from '../../../../components/Tabs'
import { DefaultLayout } from '../../../../layouts/DefaultLayout'
import {
	APIHostname,
	HasPermission,
	HasRole,
	LogOff,
} from '../../../../Profile'
import Control from '../../../../types/Control'
import { ShipmentSearchLayout } from '../../../../types/data/Shipment'
import { EscapeURIParameters } from '../../../../Utils'
import styles from './ShipmentsDocuments.module.scss'

export const ShipmentsDocuments = () => {
	const [shipments, setShipments] = useState([])
	const [shipmentCount, setShipmentCount] = useState(0)
	const [dataCorrectionCount, setDataCorrectionCount]: any = useState([])
	const [hotlistCount, setHotListCount] = useState([])
	const [lateACIFilingsCount, setLateACIFilingsCount]: any = useState([])
	const [lateAMSFilingsCount, setLateAMSFilingsCount]: any = useState([])
	const [missingNRACount, setMissingNRACount]: any = useState([])
	const [newDocumentsCount, setNewDocumentsCount]: any = useState([])
	const [newRemarksCount, setNewRemarksCount]: any = useState([])
	const [unconfirmedBookingsCount, setUnconfirmedBookingsCount]: any =
		useState([])
	const [countsLoaded, setCountsLoaded] = useState(false)
	const [shipmentsLoaded, setShipmentsLoaded] = useState(false)
	const [errorMessage, setErrorMessage] = useState(<></>)
	const initialRedirectMessage = sessionStorage['redirect-message']
	const [redirectMessage] = useState(initialRedirectMessage)

	// Vary based on query string.
	const location = useLocation()
	const urlParams = new URLSearchParams(location.search)
	if (!urlParams.get('limit')) {
		urlParams.set('limit', '25')
	}

	useEffect(() => {
		axios({
			data: {},
			headers: {
				Authorization: `Bearer ${localStorage['token']}`,
			},
			method: 'GET',
			url: `${APIHostname}/api/v1/shipments?${EscapeURIParameters(
				urlParams.toString()
			)}&view=documents`,
		})
			.then((response: any) => {
				setErrorMessage(<></>)
				setShipments(response.data.shipments)
				setShipmentCount(response.data.shipment_count)
				setShipmentsLoaded(true)
			})
			.catch((error: any) => {
				switch (error?.response?.status) {
				case 401:
					LogOff('token-expired')
					return
				case 403:
					setErrorMessage(
						<>
								Error: You do not have permission to view
								shipments.
						</>
					)
					break
				default:
					if (error?.response?.data?.error) {
						setErrorMessage(
							error?.response?.data?.link ? (
								<Link to={error?.response?.data?.link}>
										Error:
									{error?.response?.data?.error}
								</Link>
							) : (
								<>{error?.response?.data?.error}</>
							)
						)
					} else {
						setErrorMessage(<>Error: Unknown error.</>)
							console.error(error) // eslint-disable-line
					}
				}
			})

		axios({
			data: {},
			headers: {
				Authorization: `Bearer ${localStorage['token']}`,
			},
			method: 'GET',
			url: `${APIHostname}/api/v1/shipment_counts`,
		})
			.then((response: any) => {
				setErrorMessage(<></>)
				setDataCorrectionCount(response.data.data_correction_count)
				setHotListCount(response.data.hot_list_shipments_count)
				setLateACIFilingsCount(response.data.late_aci_filings_count)
				setLateAMSFilingsCount(response.data.late_ams_filings_count)
				setMissingNRACount(response.data.missing_nra_count)
				setNewDocumentsCount(response.data.new_documents_count)
				setNewRemarksCount(response.data.new_remarks_count)
				setUnconfirmedBookingsCount(
					response.data.unconfirmed_bookings_count
				)
				setCountsLoaded(true)
			})
			.catch((error: any) => {
				switch (error?.response?.status) {
				case 401:
					LogOff('token-expired')
					return
				case 403:
					setErrorMessage(
						<>
								Error: You do not have permission to view
								shipments.
						</>
					)
					break
				default:
					if (error?.response?.data?.error) {
						setErrorMessage(
							error?.response?.data?.link ? (
								<Link to={error?.response?.data?.link}>
										Error:
									{error?.response?.data?.error}
								</Link>
							) : (
								<>{error?.response?.data?.error}</>
							)
						)
					} else {
						setErrorMessage(<>Error: Unknown error.</>)
							console.error(error) // eslint-disable-line
					}
				}
			})
	}, [location])

	const ShipmentsTabMenuItems = [
		{
			icon:
				location.search.indexOf(
					'?filters=[{%22field%22:%22hotlist%22,%22operator%22:%22eq%22,%22value%22:%22true%22}]'
				) > -1
					? HotListActiveIcon
					: HotListInactiveIcon,
			requiredPermissions: ['shipment.update'],
			text: 'HOT',
			to: '/shipments/?filters=[{%22field%22:%22hotlist%22,%22operator%22:%22eq%22,%22value%22:%22true%22}]',
		},
		{
			icon: NewIcon,
			requiredPermissions: ['shipment.create'],
			text: 'NEW',
			to: '/shipments/new',
		},
	].reverse()

	const ShipmentsLeftMenuItems: TabItem[] = [
		{
			icon: DashboardIcon,

			text: 'DASHBOARD',
			to: '/dashboard',
		},
		{
			icon: BookingIcon,
			text: 'ALL SHIPMENTS',
			to: '/shipments',
		},
		{
			count: dataCorrectionCount ?? null,
			icon: DataCorrectionIcon,
			text: 'DATA CORRECTION',
			to: '/shipments/?filters=[{%22field%22:%22data_correction%22,%22operator%22:%22eq%22,%22value%22:%22true%22}]',
		},
		{
			count: hotlistCount ?? null,
			icon: HotListIcon,
			text: 'HOT LIST',
			to: '/shipments/?filters=[{%22field%22:%22hotlist%22,%22operator%22:%22eq%22,%22value%22:%22true%22}]',
		},
	]

	if (HasRole('administrator', 'partner', 'memberpartner')) {
		ShipmentsLeftMenuItems.push({
			count: lateACIFilingsCount ?? null,
			icon: LateACIIcon,
			text: 'LATE ACI',
			to: '/shipments/?filters=[{%22field%22:%22late_aci%22,%22operator%22:%22eq%22,%22value%22:%22true%22}]',
		})
		ShipmentsLeftMenuItems.push({
			count: lateAMSFilingsCount ?? null,
			icon: LateAMSIcon,
			text: 'LATE AMS',
			to: '/shipments/?filters=[{%22field%22:%22late_ams%22,%22operator%22:%22eq%22,%22value%22:%22true%22}]',
		})
		ShipmentsLeftMenuItems.push({
			count: missingNRACount ?? null,
			icon: MissingNRAIcon,
			requiredPermissions: ['shipment.read'],
			text: 'MISSING NRA',
			to: '/shipments/?filters=[{%22field%22:%22missing_nra%22,%22operator%22:%22eq%22,%22value%22:%22true%22}]',
		})
	}
	ShipmentsLeftMenuItems.push({
		count: newDocumentsCount ?? null,
		icon: NewDocumentsIcon,
		requiredPermissions: ['shipment.read'],
		text: 'NEW DOCUMENTS',
		to: '/shipments/?filters=[{%22field%22:%22new_documents%22,%22operator%22:%22eq%22,%22value%22:%22true%22}]&linkSuffix=/documents',
	})
	ShipmentsLeftMenuItems.push({
		count: newRemarksCount ?? null,
		icon: NewRemarksIcon,
		text: 'NEW REMARKS',
		to: '/shipments/?filters=[{%22field%22:%22new_remarks%22,%22operator%22:%22eq%22,%22value%22:%22true%22}]&linkSuffix=/remarks',
	})
	ShipmentsLeftMenuItems.push({
		count: unconfirmedBookingsCount ?? null,
		icon: UnconfirmedIcon,
		text: 'UNCONFIRMED',
		to: '/shipments/?filters=[{%22field%22:%22unconfirmed%22,%22operator%22:%22eq%22,%22value%22:%22true%22}]',
	})

	if (!shipmentsLoaded || !countsLoaded) {
		if (errorMessage) {
			return (
				<DefaultLayout
					bottomTabItems={ShipmentsBottomTabItems}
					leftMenuItems={ShipmentsLeftMenuItems}
					showPrint={true}
					tabMenuItems={ShipmentsTabMenuItems}
					topTabItems={GlobalTopTabItems}
				>
					<div className={styles.shipmentsContainer}>
						<div className={styles.redirectMessage}>
							{redirectMessage}
						</div>
						<div className={styles.errorMessage}>
							{errorMessage}
						</div>
					</div>
				</DefaultLayout>
			)
		}

		return <div className="loading">Loading...</div>
	}

	sessionStorage.removeItem('redirect-message')
	return (
		<DefaultLayout
			bottomTabItems={ShipmentsBottomTabItems}
			leftMenuItems={ShipmentsLeftMenuItems}
			showPrint={true}
			tabMenuItems={ShipmentsTabMenuItems}
			topTabItems={GlobalTopTabItems}
		>
			<div className={styles.shipmentsContainer}>
				<div className={styles.redirectMessage}>{redirectMessage}</div>
				<div className={styles.errorMessage}>{errorMessage}</div>

				<div className={styles.searchForm}>
					<SearchForm
						data={{}}
						layout={ShipmentSearchLayout}
						name="shipments"
						readOnly={false}
					/>
				</div>

				<NavTable
					columns={[
						{
							children: [
								{
									id: 'booking_number',
									title: 'BOOKING #',
								},
								{
									control: Control.Partner,
									id: 'origin_partner',
									title: 'ORIGIN OFFICE',
								},
							],
						},
						{
							children: [
								{
									id: 'mbl',
									title: 'MBL',
								},
								{
									control: Control.Carrier,
									id: 'mbl_carrier',
									title: 'CARRIER SCAC',
								},
							],
						},
						{
							children: [
								{
									id: 'hbl',
									title: 'HBL',
								},
								{
									control: Control.Carrier,
									id: 'hbl_carrier',
									title: 'HBL SCAC',
								},
							],
						},
						{
							children: [
								{
									control: Control.Date,
									id: 'loading_port_etd',
									title: 'ETD',
								},
								{
									control: Control.Date,
									id: 'place_of_delivery_eta',
									title: 'ETA DISCHARGE',
								},
							],
						},
						{
							children: [
								{
									control: Control.Port,
									id: 'loading_port',
									title: 'LOADING PORT',
								},
								{
									control: Control.Partner,
									id: 'origin_partner',
									title: 'ORIGIN PARTNER',
								},
							],
						},
						{
							id: 'vessel',
							title: 'VESSEL',
						},
						{
							children: [
								{
									id: 'container_numbers',
									title: 'CONTAINER #S',
								},
								{
									control: Control.Organization,
									id: 'consignee',
									title: 'CONSIGNEE',
								},
							],
						},
						{
							control: Control.DateTime,
							id: 'documents_modified',
							title: 'DOCUMENTS MODIFIED',
						},
						{
							control: Control.DateTime,
							id: 'remarks_modified',
							title: 'REMARKS MODIFIED',
						},
						{
							children: [
								{
									control: Control.Locked,
									icon: LockedIcon,
									id: 'lock',
									title: 'LOCKED',
								},
								{
									control: Control.HotListed,
									icon: HotListActiveIcon,
									id: 'hotlist',
									title: 'HOT LIST',
								},
								{
									control: Control.DataCorrection,
									icon: DataCorrectionIcon,
									id: 'data_correction',
									title: 'DATA CORRECTION',
								},
							],
						},
					]}
					count={shipmentCount}
					data={shipments}
					filters={JSON.parse(urlParams.get('filters') ?? '[]')}
					includeDelete={HasPermission('shipment', 'delete')}
					linkPathRoot="/shipments"
					linkSuffix="/documents"
					primaryDisplayColumn="booking_number,hbl,mbl"
					primaryKey="id"
				/>
			</div>
		</DefaultLayout>
	)
}

export const ShipmentsBottomTabItems = [
	{
		text: 'SHIPMENTS',
		to: `/shipments${window.location.search}`,
	},
	{
		requiredRole: 'administrator||partner||memberpartner',
		text: 'AMS / ISF / ACI',
		to: `/shipments/ams${window.location.search}`,
	},
	{
		icon: DownArrowIcon,
		requiredRole: 'administrator||partner||memberpartner||nonmemberpartner',
		text: 'DOCUMENTS',
		to: `/shipments/documents${window.location.search}`,
	},
]
