import axios from 'axios'
import { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

import DownArrowIcon from '../../../../assets/icons/arrow-down.svg'
import UsersIcon from '../../../../assets/icons/organization-users.svg'
import { EditForm, ParsedFormData } from '../../../../components/Form/EditForm'
import { GlobalTopTabItems } from '../../../../components/Tabs'
import { DefaultLayout } from '../../../../layouts/DefaultLayout'
import { APIHostname, HasPermission, LogOff } from '../../../../Profile'
import { NewUserLayout } from '../../../../types/data/User'
import styles from './UsersNew.module.scss'

export const UsersNew = () => {
	const [errorMessage, setErrorMessage] = useState(<></>)
	const initialRedirectMessage = sessionStorage['redirect-message']
	const [redirectMessage] = useState(initialRedirectMessage)

	const location = useLocation()
	const urlParams = new URLSearchParams(location.search)
	const organization = parseInt(urlParams.get('organization') ?? '-1')
	const [organizationLoaded, setOrganizationLoaded] = useState(false)
	const [organizationName, setOrganizationName] = useState('()')
	const [organizationPreview, setOrganizationPreview] = useState('')
	const partner = parseInt(urlParams.get('partner') ?? '-1')
	const [partnerLoaded, setPartnerLoaded] = useState(false)
	const [partnerName, setPartnerName] = useState('()')
	const [partnerPreview, setPartnerPreview] = useState('')
	const [role, setRole] = useState('')

	useEffect(() => {
		axios({
			data: {},
			headers: {
				Authorization: `Bearer ${localStorage['token']}`,
			},
			method: 'GET',
			url: `${APIHostname}/api/v1/organizations/${organization}`,
		})
			.then((response: any) => {
				setErrorMessage(<></>)
				setOrganizationName(response.data.name)
				setOrganizationPreview(response.data.preview)
				setOrganizationLoaded(true)
				setRole('organization')
			})
			.catch((error: any) => {
				switch (error?.response?.status) {
				case 401:
					LogOff('token-expired')
					return
				case 403:
					setErrorMessage(
						<>
								Error: You do not have permission to view
								organizations.
						</>
					)
					break
				case 404:
					setOrganizationName('()')
					setOrganizationPreview('')
					setOrganizationLoaded(true)
					break
				default:
					if (error?.response?.data?.error) {
						setErrorMessage(
							error?.response?.data?.link ? (
								<Link to={error?.response?.data?.link}>
										Error:
									{error?.response?.data?.error}
								</Link>
							) : (
								<>{error?.response?.data?.error}</>
							)
						)
					} else {
						setErrorMessage(<>Error: Unknown error.</>)
							console.error(error) // eslint-disable-line
					}
				}
			})

		axios({
			data: {},
			headers: {
				Authorization: `Bearer ${localStorage['token']}`,
			},
			method: 'GET',
			url: `${APIHostname}/api/v1/partners/${partner}`,
		})
			.then((response: any) => {
				setErrorMessage(<></>)
				setPartnerName(response.data.name)
				setPartnerPreview(response.data.preview)
				setPartnerLoaded(true)
				setRole('partner')
			})
			.catch((error: any) => {
				switch (error?.response?.status) {
				case 401:
					LogOff('token-expired')
					return
				case 403:
					setErrorMessage(
						<>
								Error: You do not have permission to view
								organizations.
						</>
					)
					break
				case 404:
					setPartnerName('()')
					setPartnerPreview('')
					setPartnerLoaded(true)
					break
				default:
					if (error?.response?.data?.error) {
						setErrorMessage(
							error?.response?.data?.link ? (
								<Link to={error?.response?.data?.link}>
										Error:
									{error?.response?.data?.error}
								</Link>
							) : (
								<>{error?.response?.data?.error}</>
							)
						)
					} else {
						setErrorMessage(<>Error: Unknown error.</>)
							console.error(error) // eslint-disable-line
					}
				}
			})
	}, [location])

	if (!organizationLoaded || !partnerLoaded) {
		return <div className="loading">Loading...</div>
	}

	let css = <></>
	if (organization > 0 || partner > 0) {
		css = (
			<style>
				{`
				.role, .organization, .partner {
					display: none;
				}
			`}
			</style>
		)
	}

	/* eslint-disable @typescript-eslint/ban-types */
	sessionStorage.removeItem('redirect-message')
	return (
		<DefaultLayout
			bottomTabItems={UsersBottomTabItems}
			leftMenuItems={UsersLeftMenuItems}
			showPrint={true}
			tabMenuItems={UsersTabMenuItems}
			topTabItems={GlobalTopTabItems}
		>
			{css}
			<div className={styles.userContainer}>
				<div className={styles.redirectMessage}>{redirectMessage}</div>
				<div className={styles.errorMessage}>{errorMessage}</div>

				<EditForm
					data={{
						organization: organization,
						organization_name: organizationName,
						organization_preview: organizationPreview,
						partner: partner,
						partner_name: partnerName,
						partner_preview: partnerPreview,
						permissions: {
							'arrival_notice.read': true,
							'carrier.read': true,
							'nra.read': true,
							'organization.read': true,
							'partner.read': true,
							'report.read': true,
							'shipment.read': true,
							'user.read': true,
							'user.updateself': true,
						},
						role: role,
					}}
					layout={NewUserLayout}
					name="user"
					onSubmit={async (formData: FormData) => {
						const payload = await ParsedFormData(formData, [])
						payload.permissions = {}
						const permissions = document.querySelectorAll(
							'input[type=checkbox]'
						)
						permissions.forEach((permission: Element) => {
							const permissionBox = permission as HTMLInputElement
							if (permissionBox.name.indexOf('.') > -1) {
								if (payload[permissionBox.name] == true) {
									payload.permissions[permissionBox.name] =
										true
								}
								delete payload[permissionBox.name]
							}
						})
						axios({
							data: payload,
							headers: {
								Authorization: `Bearer ${localStorage['token']}`,
								'Content-Type': 'application/json',
							},
							method: 'POST',
							url: `${APIHostname}/api/v1/users`,
						})
							.then((response: any) => {
								sessionStorage[
									'redirect-message'
								] = `User #${response.data.user_id} created.`
								window.history.replaceState(
									{},
									'',
									`/users/${response.data.user_id}`
								)
								document.location.reload()
							})
							.catch((error: any) => {
								switch (error?.response?.status) {
								case 401:
									LogOff('token-expired')
									return
								case 403:
									setErrorMessage(
										<>
												Error: You do not have
												permission to edit users.
										</>
									)
									return
								default:
									if (error?.response?.data?.error) {
										setErrorMessage(
											error?.response?.data?.link ? (
												<Link
													to={
														error?.response
															?.data?.link
													}
												>
														Error:
													{
														error?.response
															?.data?.error
													}
												</Link>
											) : (
												<>
													{
														error?.response
															?.data?.error
													}
												</>
											)
										)
									} else {
										setErrorMessage(
											<>Error: Unknown error.</>
										)
											console.error(error) // eslint-disable-line
									}
									return
								}
							})
					}}
					previewAPIData={async (formData: FormData) => {
						const payload = await ParsedFormData(formData, [])
						payload.permissions = {}
						const permissions = document.querySelectorAll(
							'input[type=checkbox]'
						)
						permissions.forEach((permission: Element) => {
							const permissionBox = permission as HTMLInputElement
							if (permissionBox.name.indexOf('.') > -1) {
								if (payload[permissionBox.name] == true) {
									payload.permissions[permissionBox.name] =
										true
								}
								delete payload[permissionBox.name]
							}
						})

						return {
							data: JSON.stringify(payload, null, 4),
							method: 'POST',
							url: `${APIHostname}/api/v1/users`,
						}
					}}
					readOnly={!HasPermission('user', 'update')}
				/>
			</div>
		</DefaultLayout>
	)
}

export const UsersTabMenuItems = []

export const UsersBottomTabItems = [
	{
		icon: DownArrowIcon,
		text: 'NEW USER',
		to: '/users/new',
	},
]

export const UsersLeftMenuItems = [
	{
		icon: UsersIcon,
		text: 'USERS',
		to: '/users',
	},
]
