import DownArrowIcon from '../../../../assets/icons/arrow-down.svg'
import ReportsIcon from '../../../../assets/icons/tracking.svg'
import { EditForm, ParsedFormData } from '../../../../components/Form/EditForm'
import { GlobalTopTabItems } from '../../../../components/Tabs'
import { DefaultLayout } from '../../../../layouts/DefaultLayout'
import { APIHostname, HasRole } from '../../../../Profile'
import { NonMemberPartnerBillingLayout } from '../../../../types/reports/NonMemberPartnerBilling'
import styles from './Reports.module.scss'

export const NonMemberPartnerBillingReport = () => {
	if (!HasRole('administrator')) {
		window.location.href = '/reports'
		return <></>
	}

	return (
		<DefaultLayout
			bottomTabItems={ReportsBottomTabItems}
			leftMenuItems={ReportsLeftMenuItems}
			showPrint={true}
			tabMenuItems={ReportsTabMenuItems}
			topTabItems={GlobalTopTabItems}
		>
			<div className={styles.reportsContainer}>
				<EditForm
					data={{}}
					disablePreviewAPIButton={true}
					layout={NonMemberPartnerBillingLayout}
					name="report"
					onSubmit={async (formData: FormData) => {
						const parsedFormData = await ParsedFormData(
							formData,
							[]
						)

						window.history.replaceState(
							{},
							'',
							`${APIHostname}/api/v1/reports/nonmemberpartnerbilling/?partner=${parsedFormData.partner}&start_date=${parsedFormData.start_date}&end_date=${parsedFormData.end_date}`
						)
						document.location.reload()
					}}
					previewAPIData={async (formData: FormData) => {
						const parsedFormData = await ParsedFormData(
							formData,
							[]
						)

						return {
							data: JSON.stringify(parsedFormData, null, 4),
							method: 'POST',
							url: `${APIHostname}/api/v1/reports/nonmemberpartnerbilling`,
						}
					}}
					readOnly={false}
				/>
			</div>
		</DefaultLayout>
	)
}

export const ReportsTabMenuItems = []

export const ReportsBottomTabItems = [
	{
		icon: DownArrowIcon,
		text: 'NON-MEMBER PARTNER BILLING REPORT',
		to: '/reports/nonmemberpartnerbilling',
	},
]

export const ReportsLeftMenuItems = [
	{
		icon: ReportsIcon,
		text: 'REPORTS',
		to: '/reports',
	},
]
