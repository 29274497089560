import { ReactElement, useState } from 'react'

import AddIcon from '../../../../assets/icons/add.svg'
import DeleteIcon from '../../../../assets/icons/delete.svg'
import { FieldProps } from '../../Field'
import { EditNRA } from '../NRA'
import styles from './ShipmentNRAs.module.scss'

export const EditShipmentNRAs = (props: FieldProps) => {
	const [nraCount, setNRACount] = useState(
		props.data?.nras?.length ? props.data?.nras?.length : 0
	)

	const rows: ReactElement[] = []
	for (let i = 0; i < nraCount; i++) {
		if (!props.data) {
			props.data = {}
		}
		if (!props.data.nras) {
			props.data.nras = {}
		}
		if (!props.data.nras[i]) {
			props.data.nras[i] = {}
		}

		rows.push(
			<div
				className={styles.row}
				id={`${props.field.Property}_${i}`}
				key={`nra${i}`}
			>
				<input
					data-number={true}
					defaultValue={
						props.data?.nras ? props.data?.nras[i]?.id : ''
					}
					id={`${props.field.Property}_${i}_id`}
					name={`${props.field.Property}_${i}_id`}
					type="hidden"
				/>
				<input
					data-number={true}
					defaultValue={
						props.data?.nras ? props.data?.nras[i]?.shipment : ''
					}
					id={`${props.field.Property}_${i}_shipment`}
					name={`${props.field.Property}_${i}_shipment`}
					type="hidden"
				/>
				<input
					data-boolean={true}
					defaultValue={'true'}
					id={`${props.field.Property}_${i}_active`}
					name={`${props.field.Property}_${i}_active`}
					type="hidden"
				/>
				<div
					className={styles.row}
					id={`${props.field.Property}_${i}_contents`}
				>
					<div className={styles.deleteColumn}>
						<div>{i + 1}</div>
						<img
							className={styles.delete}
							onClick={() => {
								// eslint-disable-next-line no-alert
								if (confirm('Really delete NRA?')) {
									(
										document.getElementById(
											`${props.field.Property}_${i}_active`
										) as HTMLInputElement
									).value = 'false'
									;(
										document.getElementById(
											`${props.field.Property}_${i}`
										) as HTMLInputElement
									).style.display = 'none'
									;(
										document.getElementById(
											`${props.field.Property}_${i}_contents`
										) as HTMLInputElement
									).innerHTML = ''

									if (props.onChange) {
										props.onChange(null)
									}
								}
							}}
							src={DeleteIcon}
							title="DELETE ROW"
						/>{' '}
					</div>
					<div className={styles.inputColumn}>
						<div className={styles.row}>
							<div className={styles.oneHalf}>
								<EditNRA
									data={flattenArrays(props.data, '')}
									field={{
										HardRequired: true,
										Label: 'NRA:',
										Property: `${props.field.Property}_${i}`,
									}}
									isSearch={props.isSearch}
									onChange={props.onChange}
									readOnly={
										props.readOnly || props.field.ReadOnly
									}
									value={
										props.data?.nras &&
										props.data?.nras[i] &&
										props.data?.nras[i].id
											? props.data?.nras[i]?.id
											: ''
									}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
	if (nraCount == 0) {
		rows.push(
			<div className={`${styles.row} ${styles.emptyRow}`} key="noNRAs">
				No NRAs.
			</div>
		)
	}

	return (
		<div
			className={`${styles.fieldOuterContainer} ${
				!props.isSearch ? styles.editForm : ''
			}`}
			id={props.field.Property ? `${props.field.Property}_container` : ''}
			key="addNRA"
		>
			{rows}
			{!(props.isSearch || props.readOnly) ? (
				<div className={styles.buttonRow}>
					<button type="button">
						<img
							alt="ADD NRA"
							onClick={() => {
								setNRACount(nraCount + 1)
								if (props.onChange) {
									props.onChange(null)
									setTimeout(() => {
										if (props.onChange) {
											props.onChange(null)
										}
									}, 100)
									setTimeout(() => {
										if (props.onChange) {
											props.onChange(null)
										}
									}, 250)
									setTimeout(() => {
										if (props.onChange) {
											props.onChange(null)
										}
									}, 500)
									setTimeout(() => {
										if (props.onChange) {
											props.onChange(null)
										}
									}, 1000)
									setTimeout(() => {
										if (props.onChange) {
											props.onChange(null)
										}
									}, 2500)
								}
							}}
							src={AddIcon}
						/>
					</button>
				</div>
			) : (
				<></>
			)}
		</div>
	)
}

export const flattenArrays = (input: any, prefix: string) => {
	let output: any = {}
	Object.keys(input).forEach((key: string) => {
		if (Array.isArray(input[key])) {
			input[key].forEach((subitem: any, index: number) => {
				output = {
					...output,
					...flattenArrays(subitem, `${prefix}${key}_${index}_`),
				}
			})
		} else {
			output[`${prefix}${key}`] = input[key]
		}
	})
	return output
}
