import {
	getAllTimezones,
	getCountriesForTimezone,
	getTimezone,
} from 'countries-and-timezones'
import { useState } from 'react'

import { FieldProps } from '../../Field'
import styles from './TimeZone.module.scss'

export const EditTimeZone = (props: FieldProps) => {
	const [timezone, setTimezone]: any = useState(props.value)
	const timezones = getAllTimezones()

	if (props.readOnly || props.field.ReadOnly) {
		const tz = getTimezone(timezone)

		return (
			<div
				className={styles.fieldOuterContainer}
				id={
					props.field.Property
						? `${props.field.Property}_container`
						: ''
				}
			>
				{getCountriesForTimezone(timezone).map((country, index) => {
					return (
						<div className={styles.timeZoneBox} key={index}>
							<span>
								{' '}
								<span
									className={`fi fi-${country.id.toLowerCase()} ${
										styles.flag
									}`}
									title={country.name}
								></span>{' '}
							</span>
							<input
								data-hardrequired={
									props.field.HardRequired && !props.isSearch
								}
								data-softrequired={
									props.field.SoftRequired && !props.isSearch
								}
								defaultValue={`${tz.name} (${
									tz.utcOffset > 0 ? '+' : ''
								}${tz.utcOffset / 60} Standard / ${
									tz.dstOffset > 0 ? '+' : ''
								}${tz.dstOffset / 60} DST)`}
								readOnly={true}
								title={props.field.Label?.replace(':', '')}
								type="text"
							/>
						</div>
					)
				})}
			</div>
		)
	}

	return (
		<div
			className={styles.fieldOuterContainer}
			id={props.field.Property ? `${props.field.Property}_container` : ''}
		>
			<div className={styles.timeZoneBox}>
				{getCountriesForTimezone(timezone).map((country, index) => {
					return (
						<span key={index}>
							{' '}
							<span
								className={`fi fi-${country.id.toLowerCase()} ${
									styles.flag
								}`}
								title={country.name}
							></span>{' '}
						</span>
					)
				})}
				<select
					className={`${
						props.field.HardRequired && !props.isSearch
							? 'hardRequired'
							: ''
					} ${
						props.field.SoftRequired && !props.isSearch
							? 'softRequired'
							: ''
					}`}
					data-hardrequired={
						props.field.HardRequired && !props.isSearch
					}
					data-nullable={props.field.Nullable}
					data-softrequired={
						props.field.SoftRequired && !props.isSearch
					}
					id={props.field.Property}
					key={`${props.field.Property}-${props.value}`}
					name={props.field.Property}
					onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
						setTimezone(e.target.value)
						if (props.onChange) {
							props.onChange(e)
						}
					}}
					required={props.field.HardRequired && !props.isSearch}
					title={props.field.Label?.replace(':', '')}
					value={timezone}
				>
					<option value=""></option>
					{Object.entries(timezones)
						.sort((a: [string, any], b: [string, any]) => {
							return a[0] < b[0] ? -1 : 1
						})
						.map((tz: [name: string, tz: any], index: number) => (
							<option key={`timezone${index}`} value={tz[0]}>
								{tz[1].name} ({tz[1].utcOffset > 0 ? '+' : ''}
								{tz[1].utcOffset / 60} Standard /{' '}
								{tz[1].dstOffset > 0 ? '+' : ''}
								{tz[1].dstOffset / 60} DST)
							</option>
						))}
				</select>
			</div>
		</div>
	)
}
