import { ReactNode } from 'react'
import { Link } from 'react-router-dom'

import { Partner } from '../../types/data/Partner'
import styles from './PartnerSummary.module.scss'

export const PartnerSummary = (props: PartnerSummaryProps) => {
	if (!props.partner) {
		return <></>
	}

	return (
		<div className={styles.partnerSummaryOuterContainer}>
			<div className={styles.partnerSummaryInnerContainer}>
				<div>
					<div className={styles.label}>PARTNER CODE:</div>
					<div className={styles.value}>
						<Link to={`/partners/${props.partner.id}`}>
							{props.partner.code}
						</Link>
					</div>
				</div>
				<div>
					<div className={styles.label}>PARTNER NAME:</div>
					<div className={styles.value}>{props.partner.name}</div>
				</div>
				<div>
					<div className={styles.label}>MAIN CONTACT:</div>
					<div className={styles.value}>
						{props.partner.main_contact_name}
					</div>
				</div>
				<div>
					<div className={styles.label}>OTI #:</div>
					<div className={styles.value}>{props.partner.oti}</div>
				</div>
				{props.horizontalChildren ? props.children : <></>}
			</div>
			{!props.horizontalChildren ? props.children : <></>}
		</div>
	)
}

export type PartnerSummaryProps = {
	children?: ReactNode
	horizontalChildren?: boolean
	partner: Partner
}
