import Control from '../Control'
import { Layout } from '../Layout'

export const RemarkLayout: Layout = {
	Groups: [
		{
			Border: true,
			RequiredRows: [
				{
					Fields: [
						{
							AutoComplete: false,
							AutoFocus: true,
							Control: Control.TextBox,
							HardRequired: true,
							IncludeInEdit: true,
							IncludeInSearch: true,
							Label: 'REMARK',
							Property: 'remark',
							Width: 100,
						},
					],
				},
			],
			Width: 100,
		},
	],
}
