import { useState } from 'react'
import { Link } from 'react-router-dom'

import AllUsersIcon from '../../assets/icons/all_users.svg'
import ApprovedUsersIcon from '../../assets/icons/approved_users.svg'
import CarriersIcon from '../../assets/icons/carriers.svg'
import ContainerTypesIcon from '../../assets/icons/container_types.svg'
import CountriesIcon from '../../assets/icons/countries.svg'
import EmailTemplatesIcon from '../../assets/icons/email_templates.svg'
import PendingUsersIcon from '../../assets/icons/pending_users.svg'
import PortsIcon from '../../assets/icons/ports.svg'
import { HasDeveloperMode, HasPermissions, HasRole } from '../../Profile'
import { RandomID } from '../../Utils'
import styles from './LeftMenu.module.scss'

export const LeftMenu = (params: LeftMenuParams) => {
	const [showMobileMenu, setShowMobileMenu] = useState(false)

	return (
		<div className={styles.leftMenuContainer}>
			<div
				className={styles.leftMobileMenu}
				onClick={() => {
					setShowMobileMenu(!showMobileMenu)
				}}
			>
				{showMobileMenu ? 'HIDE' : 'SHOW'} MENU
			</div>
			<ul
				className={`${styles.leftMenu} ${
					showMobileMenu ? '' : styles.hideMobileMenu
				}`}
			>
				{params.menuItems ? (
					params.menuItems.map((menuItem, index) =>
						HasDeveloperMode(menuItem.requireDeveloperMode) &&
						HasPermissions(menuItem.requiredPermissions) &&
						HasRole(menuItem.requiredRole ?? '') ? (
								<li
									className={
										menuItem.to ===
										document.location.pathname ||
									menuItem.to ===
										document.location.pathname +
											document.location.search
											? styles.selected
											: ''
									}
									key={`menuItem${index}`}
								>
									{menuItem.disabled ? (
										<span title={menuItem.disabledReason}>
											{menuItem.icon ? (
												<img
													alt={menuItem.text}
													className={styles.disabled}
													src={menuItem.icon}
												/>
											) : (
												<></>
											)}{' '}
											{menuItem.text}
											{menuItem.count ? (
												<span
													className={
														styles.countOuterContainer
													}
												>
													<span
														className={
															styles.countInnerContainer
														}
													>
														{menuItem.count}
													</span>
												</span>
											) : (
												<></>
											)}
										</span>
									) : (
										<Link
											target={menuItem.target}
											to={menuItem.to}
										>
											{menuItem.icon ? (
												<img
													alt={menuItem.text}
													src={menuItem.icon}
												/>
											) : (
												<></>
											)}{' '}
											{menuItem.text}
											{menuItem.count ? (
												<span className={styles.count}>
													{menuItem.count}
												</span>
											) : (
												<></>
											)}
										</Link>
									)}
								</li>
							) : (
								<div key={RandomID(16)} />
							)
					)
				) : (
					<div key={RandomID(16)} />
				)}
			</ul>
		</div>
	)
}

export type LeftMenuItem = {
	count?: number
	disabled?: boolean
	disabledReason?: string
	requireDeveloperMode?: boolean
	requiredPermissions?: string[]
	requiredRole?: string
	target?: string
	to: string
	icon?: string
	text: string
}

export type LeftMenuParams = {
	menuItems: LeftMenuItem[]
}

export const AdministrationLeftMenuItems = [
	{
		icon: AllUsersIcon,
		requirdPermissions: ['user.read'],
		text: 'ALL USERS',
		to: '/users',
	},
	{
		icon: ApprovedUsersIcon,
		requirdPermissions: ['user.read'],
		requiredRole: 'administrator||memberpartner||partner',
		text: 'APPROVED USERS',
		to: '/users?filters=[{%22field%22:%22approved%22,%22operator%22:%22eq%22,%22value%22:%22true%22}]',
	},
	{
		icon: PendingUsersIcon,
		requirdPermissions: ['user.read'],
		requiredRole: 'administrator||memberpartner||partner',
		text: 'PENDING USERS',
		to: '/users?filters=[{%22field%22:%22approved%22,%22operator%22:%22eq%22,%22value%22:%22false%22}]',
	},
	{
		icon: CarriersIcon,
		requiredRole: 'administrator',
		text: 'CARRIERS',
		to: '/carriers',
	},
	{
		icon: ContainerTypesIcon,
		requiredRole: 'administrator',
		text: 'CONTAINER TYPES',
		to: '/container_types',
	},
	{
		icon: CountriesIcon,
		requiredRole: 'administrator',
		text: 'COUNTRIES',
		to: '/countries',
	},
	{
		icon: EmailTemplatesIcon,
		requiredRole: 'administrator',
		text: 'EMAIL TEMPLATES',
		to: '/email_templates',
	},
	{
		icon: PortsIcon,
		requiredRole: 'administrator',
		text: 'PORTS',
		to: '/ports',
	},
]
