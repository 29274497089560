import { useState } from 'react'

import { FieldProps } from '../../Field'
import styles from './ISFTransmissionType.module.scss'

export const EditISFTransmissionType = (props: FieldProps) => {
	const [value, setValue] = useState(props.value)

	/* eslint-disable sort-keys */
	const transmissionTypes: { [key: string]: string } = {
		0: 'Foreign port discharge/no USA stop',
		10: 'ISF-10 USA discharge',
		5: 'ISF-5  USA stop/foreign port discharge',
	}

	if (props.readOnly || props.field.ReadOnly) {
		return (
			<div
				className={styles.fieldOuterContainer}
				id={
					props.field.Property
						? `${props.field.Property}_container`
						: ''
				}
			>
				<div className={styles.label}>
					{props.field.Label}
					{props.field.HardRequired && !props.isSearch ? (
						<>
							{'\u00A0'}
							<span
								className="hardRequired"
								title="REQUIRED IN ORDER TO SAVE"
							>
								*
							</span>
						</>
					) : (
						<></>
					)}
					{props.field.SoftRequired && !props.isSearch ? (
						<>
							{'\u00A0'}
							<span className="softRequired" title="REQUIRED">
								*
							</span>
						</>
					) : (
						<></>
					)}{' '}
				</div>
				<input
					data-hardrequired={
						props.field.HardRequired && !props.isSearch
					}
					data-softrequired={
						props.field.SoftRequired && !props.isSearch
					}
					defaultValue={transmissionTypes[value]}
					readOnly={true}
					title={props.field.Label?.replace(':', '')}
					type="text"
				/>
			</div>
		)
	}
	return (
		<div
			className={styles.fieldOuterContainer}
			id={props.field.Property ? `${props.field.Property}_container` : ''}
		>
			<div className={styles.label}>
				{' '}
				{props.field.Label}
				{props.field.HardRequired ? (
					<>
						{'\u00A0'}
						<span
							className="hardRequired"
							title="REQUIRED IN ORDER TO SAVE"
						>
							*
						</span>
					</>
				) : (
					<></>
				)}
				{props.field.SoftRequired && !props.isSearch ? (
					<>
						{'\u00A0'}
						<span className="softRequired" title="REQUIRED">
							*
						</span>
					</>
				) : (
					<></>
				)}{' '}
			</div>
			<select
				className={`${
					props.field.HardRequired && !props.isSearch
						? 'hardRequired'
						: ''
				} ${
					props.field.SoftRequired && !props.isSearch
						? 'softRequired'
						: ''
				}`}
				data-hardrequired={props.field.HardRequired && !props.isSearch}
				data-nullable={props.field.Nullable}
				data-softrequired={props.field.SoftRequired && !props.isSearch}
				defaultValue={value}
				id={props.field.Property}
				name={props.field.Property}
				onChange={(e: React.ChangeEvent) => {
					setValue((e.target as HTMLSelectElement).value)

					if (props.onChange) {
						props.onChange(e)
					}
				}}
				required={props.field.HardRequired && !props.isSearch}
				title={props.field.Label?.replace(':', '')}
			>
				<option value=""></option>
				{Object.keys(transmissionTypes).map((key: string) => (
					<option key={`bol${key}`} value={key}>
						[{key}] {transmissionTypes[key]}
					</option>
				))}
			</select>
		</div>
	)
}
