import { ReactElement, useState } from 'react'

import AddIcon from '../../../../assets/icons/add.svg'
import DeleteIcon from '../../../../assets/icons/delete.svg'
import { FieldProps } from '../../Field'
import { EditContainerType } from '../ContainerType'
import styles from './NRARates.module.scss'

export const EditNRARates = (props: FieldProps) => {
	const [rateCount, setRateCount] = useState(
		props.data?.rates?.length ? props.data?.rates?.length : 0
	)

	const rows: ReactElement[] = []
	for (let i = 0; i < rateCount; i++) {
		rows.push(
			<div
				className={styles.row}
				id={`${props.field.Property}_${i}`}
				key={`nraRateNumber${i}`}
			>
				<input
					data-number={true}
					defaultValue={
						props.data?.rates ? props.data?.rates[i]?.id : ''
					}
					id={`${props.field.Property}_${i}_id`}
					name={`${props.field.Property}_${i}_id`}
					type="hidden"
				/>
				<input
					data-number={true}
					defaultValue={
						props.data?.rates ? props.data?.rates[i]?.nra : ''
					}
					id={`${props.field.Property}_${i}_nra`}
					name={`${props.field.Property}_${i}_nra`}
					type="hidden"
				/>
				<input
					data-boolean={true}
					defaultValue={'true'}
					id={`${props.field.Property}_${i}_active`}
					name={`${props.field.Property}_${i}_active`}
					type="hidden"
				/>
				<div
					className={styles.row}
					id={`${props.field.Property}_${i}_contents`}
				>
					<div className={styles.deleteColumn}>
						<div>{i + 1}</div>
						<img
							className={styles.delete}
							onClick={() => {
								// eslint-disable-next-line no-alert
								if (confirm('Really delete NRA rate?')) {
									(
										document.getElementById(
											`${props.field.Property}_${i}_active`
										) as HTMLInputElement
									).value = 'false'
									;(
										document.getElementById(
											`${props.field.Property}_${i}`
										) as HTMLInputElement
									).style.display = 'none'
									;(
										document.getElementById(
											`${props.field.Property}_${i}_contents`
										) as HTMLInputElement
									).innerHTML = ''

									if (props.onChange) {
										props.onChange(null)
									}
								}
							}}
							src={DeleteIcon}
							title="DELETE ROW"
						/>{' '}
					</div>
					<div className={styles.inputColumn}>
						<div className={styles.row}>
							<div className={styles.oneTenth}>
								<EditContainerType
									data-hardrequired={true}
									field={{
										HardRequired: true,
										Label: 'CONTAINER TYPE:',
										Property: `${props.field.Property}_${i}_type`,
									}}
									isSearch={props.isSearch}
									onChange={props.onChange}
									readOnly={
										props.readOnly || props.field.ReadOnly
									}
									value={
										props.data?.rates
											? props.data?.rates[i]?.type
											: ''
									}
								/>{' '}
							</div>
							{!props.isSearch ? (
								<>
									<div className={styles.oneTenth}>
										<div className={styles.label}>
											RATE:{' '}
											<span
												className="hardRequired"
												title="REQUIRED"
											>
												*
											</span>
										</div>
										<input
											className={
												!props.isSearch
													? 'hardRequired'
													: ''
											}
											data-hardrequired={true}
											data-number={true}
											defaultValue={
												props.data?.rates
													? props.data?.rates[i]?.rate
													: ''
											}
											id={`${props.field.Property}_${i}_rate`}
											name={`${props.field.Property}_${i}_rate`}
											onChange={props.onChange}
											placeholder=" "
											readOnly={
												props.readOnly ||
												props.field.ReadOnly
											}
											step={0.01}
											title="RATE"
											type="number"
										/>
									</div>

									<div className={styles.oneTenth}>
										<div className={styles.label}>
											REMARKS / COMMODITY:
										</div>
										<textarea
											defaultValue={
												props.data?.rates
													? props.data?.rates[i]
														?.remarks
													: ''
											}
											id={`${props.field.Property}_${i}_remarks`}
											name={`${props.field.Property}_${i}_remarks`}
											onChange={props.onChange}
											readOnly={
												props.readOnly ||
												props.field.ReadOnly
											}
											title="REMARKS / COMMODITY"
										></textarea>
									</div>
								</>
							) : (
								<></>
							)}
						</div>
					</div>
				</div>
			</div>
		)
	}

	if (rateCount == 0) {
		rows.push(
			<div className={`${styles.row} ${styles.emptyRow}`} key="noRates">
				No NRA Rates.
			</div>
		)
	}

	return (
		<div
			className={`${styles.fieldOuterContainer} ${
				!props.isSearch ? styles.editForm : ''
			}`}
			id={props.field.Property ? `${props.field.Property}_rate` : ''}
			key="addNRARate"
		>
			{rows}
			{!(props.isSearch || props.readOnly) ? (
				<div className={styles.buttonRow}>
					<button type="button">
						<img
							alt="ADD RATE"
							onClick={() => {
								setRateCount(rateCount + 1)
								if (props.onChange) {
									props.onChange(null)
									setTimeout(() => {
										if (props.onChange) {
											props.onChange(null)
										}
									}, 100)
									setTimeout(() => {
										if (props.onChange) {
											props.onChange(null)
										}
									}, 250)
									setTimeout(() => {
										if (props.onChange) {
											props.onChange(null)
										}
									}, 500)
									setTimeout(() => {
										if (props.onChange) {
											props.onChange(null)
										}
									}, 1000)
									setTimeout(() => {
										if (props.onChange) {
											props.onChange(null)
										}
									}, 2500)
								}
							}}
							src={AddIcon}
						/>
					</button>
				</div>
			) : (
				<></>
			)}
		</div>
	)
}
