import axios from 'axios'
import { useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'

import AgentAccessIcon from '../../../../../assets/icons/agent-access.svg'
import DownArrowIcon from '../../../../../assets/icons/arrow-down.svg'
import ChainIcon from '../../../../../assets/icons/chain.svg'
import DocumentsIcon from '../../../../../assets/icons/documents.svg'
import PayBalanceIcon from '../../../../../assets/icons/goal-pay-balance.svg'
import PayRequestMoneyIcon from '../../../../../assets/icons/goal-pay-request-money.svg'
import PaySendMoneyIcon from '../../../../../assets/icons/goal-pay-send-money.svg'
import PayTransactionsIcon from '../../../../../assets/icons/goal-pay-transactions.svg'
import PayIncomingRequestsIcon from '../../../../../assets/icons/goal-pay-transfer-money.svg'
import PartnerHistoryIcon from '../../../../../assets/icons/organization-history.svg'
import PartnerInfoIcon from '../../../../../assets/icons/organization-info.svg'
import PartnerUsersIcon from '../../../../../assets/icons/organization-users.svg'
import {
	EditForm,
	ParsedFormData,
} from '../../../../../components/Form/EditForm'
import { LeftMenuItem } from '../../../../../components/LeftMenu'
import { NavTable } from '../../../../../components/NavTable'
import { PartnerSummary } from '../../../../../components/PartnerSummary'
import { GlobalTopTabItems } from '../../../../../components/Tabs'
import { DefaultLayout } from '../../../../../layouts/DefaultLayout'
import {
	APIHostname,
	HasPermission,
	HasRole,
	LogOff,
} from '../../../../../Profile'
import Control from '../../../../../types/Control'
import { PartnerAccessLayout } from '../../../../../types/data/PartnerAccess'
import { EscapeURIParameters } from '../../../../../Utils'
import styles from './OrganizationAccess.module.scss'

export const OrganizationAccess = () => {
	const { id } = useParams()
	const [partner, setPartner]: any = useState(null)
	const [organizations, setOrganizations]: any = useState(null)
	const [organizationCount, setOrganizationCount] = useState(0)
	const [partnerLoaded, setPartnerLoaded]: any = useState(false)
	const [organizationsLoaded, setOrganizationsLoaded]: any = useState(false)
	const [payRequestCount, setPayRequestCount] = useState(0)
	const [countsLoaded, setCountsLoaded] = useState(false)
	const [errorMessage, setErrorMessage] = useState(<></>)
	const initialRedirectMessage = sessionStorage['redirect-message']
	const [redirectMessage] = useState(initialRedirectMessage)

	// Vary based on query string.
	const location = useLocation()
	const urlParams = new URLSearchParams(location.search)
	if (!urlParams.get('limit')) {
		urlParams.set('limit', '25')
	}

	useEffect(() => {
		axios({
			data: {},
			headers: {
				Authorization: `Bearer ${localStorage['token']}`,
			},
			method: 'GET',
			url: `${APIHostname}/api/v1/partners/${id}${location.search}`,
		})
			.then((response: any) => {
				setErrorMessage(<></>)
				setPartner(response.data)
				setPartnerLoaded(true)

				axios({
					data: {},
					headers: {
						Authorization: `Bearer ${localStorage['token']}`,
					},
					method: 'GET',
					url: `${APIHostname}/api/v1/partners/${id}/pay_counts`,
				})
					.then((response: any) => {
						setErrorMessage(<></>)
						setPayRequestCount(response.data.pay_request_count)
						setCountsLoaded(true)
					})
					.catch((error: any) => {
						switch (error?.response?.status) {
						case 401:
							LogOff('token-expired')
							return
						case 403:
							setCountsLoaded(true)
							setErrorMessage(
								<>
										Error: You do not have permission to
										view GOAL pay requests.
								</>
							)
							break
						case 404:
							setCountsLoaded(true)
							break
						default:
							if (error?.response?.data?.error) {
								setErrorMessage(
									error?.response?.data?.link ? (
										<Link
											to={error?.response?.data?.link}
										>
												Error:
											{error?.response?.data?.error}
										</Link>
									) : (
										<>{error?.response?.data?.error}</>
									)
								)
							} else {
								setErrorMessage(<>Error: Unknown error.</>)
									console.error(error) // eslint-disable-line
							}
						}
					})
			})
			.catch((error: any) => {
				switch (error?.response?.status) {
				case 401:
					LogOff('token-expired')
					return
				case 403:
					setErrorMessage(
						<>
								Error: You do not have permission to view
								partners.
						</>
					)
					break
				default:
					if (error?.response?.data?.error) {
						setErrorMessage(
							error?.response?.data?.link ? (
								<Link to={error?.response?.data?.link}>
										Error:
									{error?.response?.data?.error}
								</Link>
							) : (
								<>{error?.response?.data?.error}</>
							)
						)
					} else {
						setErrorMessage(<>Error: Unknown error.</>)
							console.error(error) // eslint-disable-line
					}
				}
			})

		axios({
			data: {},
			headers: {
				Authorization: `Bearer ${localStorage['token']}`,
			},
			method: 'GET',
			url: `${APIHostname}/api/v1/partners/${id}/organization_access?${EscapeURIParameters(
				urlParams.toString()
			)}`,
		})
			.then((response: any) => {
				setErrorMessage(<></>)
				setOrganizations(response.data.organizations)
				setOrganizationCount(response.data.organization_count)
				setOrganizationsLoaded(true)
			})
			.catch((error: any) => {
				switch (error?.response?.status) {
				case 401:
					LogOff('token-expired')
					return
				case 403:
					setErrorMessage(
						<>
								Error: You do not have permission to view
								organizations for this partner.
						</>
					)
					break
				default:
					if (error?.response?.data?.error) {
						setErrorMessage(
							error?.response?.data?.link ? (
								<Link to={error?.response?.data?.link}>
										Error:
									{error?.response?.data?.error}
								</Link>
							) : (
								<>{error?.response?.data?.error}</>
							)
						)
					} else {
						setErrorMessage(<>Error: Unknown error.</>)
							console.error(error) // eslint-disable-line
					}
				}
			})
	}, [location])

	const PartnerBottomTabItems = [
		{
			icon: DownArrowIcon,
			text: `PARTNER #${id} ORGANIZATION ACCESS`,
			to: `/partners/${id}/organization_access`,
		},
	]

	const PartnerLeftMenuItems: LeftMenuItem[] = [
		{
			icon: PartnerInfoIcon,
			requiredPermissions: ['partner.read'],
			text: 'PARTNER INFO',
			to: `/partners/${id}`,
		},
		{
			icon: AgentAccessIcon,
			requiredPermissions: ['organization.manageaccess'],
			text: 'ORGANIZATION ACCESS',
			to: `/partners/${id}/organization_access`,
		},
	]

	if (!partner?.goal_member) {
		PartnerLeftMenuItems.push({
			icon: AgentAccessIcon,
			requiredPermissions: ['partner.manageaccess'],
			requiredRole: 'administrator',
			text: 'PARTNER ACCESS',
			to: `/partners/${id}/partner_access`,
		})
	}
	PartnerLeftMenuItems.push({
		icon: DocumentsIcon,
		requiredPermissions: ['partner.read'],
		text: 'PARTNER DOCUMENTS',
		to: `/partners/${id}/documents`,
	})
	PartnerLeftMenuItems.push({
		icon: ChainIcon,
		requiredPermissions: ['shipment.read'],
		text: 'LINKED SHIPMENTS',
		to: `/partners/${id}/shipments`,
	})
	PartnerLeftMenuItems.push({
		icon: PartnerUsersIcon,
		requiredPermissions: ['user.read'],
		text: 'PARTNER USERS',
		to: `/partners/${id}/users`,
	})
	PartnerLeftMenuItems.push({
		icon: PartnerHistoryIcon,
		requiredPermissions: ['partner.read'],
		text: 'PARTNER HISTORY',
		to: `/partners/${id}/history`,
	})

	if (HasRole('administrator')) {
		if (
			HasPermission('pay', 'viewbalance') ||
			HasPermission('pay', 'sendmoney') ||
			HasPermission('pay', 'requestmoney')
		) {
			PartnerLeftMenuItems.push(
				{
					disabled: true,
					requiredPermissions: [],
					text: 'ADMINISTRATOR OPTIONS',
					to: '',
				},
				{
					icon: PayBalanceIcon,
					requiredPermissions: ['pay.viewbalance'],
					text: 'BALANCE',
					to: `/partners/${id}/pay/balance`,
				},
				{
					icon: PaySendMoneyIcon,
					requiredPermissions: ['pay.sendmoney'],
					requiredRole: 'administrator',
					text: 'DEPOSIT MONEY',
					to: `/partners/${id}/pay/deposit`,
				},
				{
					icon: PayRequestMoneyIcon,
					requiredPermissions: ['pay.requestmoney'],
					requiredRole: 'administrator',
					text: 'WITHDRAW MONEY',
					to: `/partners/${id}/pay/withdraw`,
				},
				{
					icon: PayRequestMoneyIcon,
					requiredPermissions: ['pay.requestmoney'],
					text: 'REQUEST MONEY',
					to: `/partners/${id}/pay/request`,
				},
				{
					icon: PaySendMoneyIcon,
					requiredPermissions: ['pay.sendmoney'],
					text: 'SEND MONEY',
					to: `/partners/${id}/pay/send`,
				},
				{
					count: payRequestCount,
					icon: PayIncomingRequestsIcon,
					requiredPermissions: ['pay.sendmoney'],
					text: 'INCOMING PAYMENT REQUESTS',
					to: `/partners/${id}/pay/incoming_requests`,
				},
				{
					icon: PayTransactionsIcon,
					requiredPermissions: ['pay.viewbalance'],
					text: 'TRANSACTIONS',
					to: `/partners/${id}/pay/transactions`,
				}
			)
		}
	}

	if (!partnerLoaded || !organizationsLoaded || !countsLoaded) {
		if (errorMessage) {
			return (
				<DefaultLayout
					bottomTabItems={PartnerBottomTabItems}
					leftMenuItems={PartnerLeftMenuItems}
					showPrint={true}
					tabMenuItems={PartnerTabMenuItems}
					topTabItems={GlobalTopTabItems}
				>
					<div className={styles.organizationAccessContainer}>
						<div className={styles.redirectMessage}>
							{redirectMessage}
						</div>
						<div className={styles.errorMessage}>
							{errorMessage}
						</div>
					</div>
				</DefaultLayout>
			)
		}

		return <div className="loading">Loading...</div>
	}

	sessionStorage.removeItem('redirect-message')
	return (
		<DefaultLayout
			bottomTabItems={PartnerBottomTabItems}
			leftMenuItems={PartnerLeftMenuItems}
			showPrint={true}
			tabMenuItems={PartnerTabMenuItems}
			topTabItems={GlobalTopTabItems}
		>
			<div className={styles.organizationAccessContainer}>
				<div className={styles.redirectMessage}>{redirectMessage}</div>
				<div className={styles.errorMessage}>
					{errorMessage}{' '}
					{!partner?.active ? (
						<>This partner has been deleted.</>
					) : (
						<></>
					)}
				</div>

				<PartnerSummary partner={partner} />

				<div className={styles.addOrganizationForm}>
					<EditForm
						data={{}}
						hasClearButton={false}
						layout={PartnerAccessLayout}
						name="partnerAccess"
						onSubmit={async (formData: FormData) => {
							axios({
								data: await ParsedFormData(formData, []),
								headers: {
									Authorization: `Bearer ${localStorage['token']}`,
									'Content-Type': 'application/json',
								},
								method: 'POST',
								url: `${APIHostname}/api/v1/partners/${id}/organization_access`,
							})
								.then(() => {
									sessionStorage['redirect-message'] =
										'Organization access added.'
									window.history.replaceState(
										{},
										'',
										`/partners/${id}/organization_access`
									)
									document.location.reload()
								})
								.catch((error: any) => {
									switch (error?.response?.status) {
									case 401:
										LogOff('token-expired')
										return
									case 403:
										setErrorMessage(
											<>
													Error: You do not have
													permission to add
													organization access.
											</>
										)
										break
									default:
										if (error?.response?.data?.error) {
											setErrorMessage(
												error?.response?.data
													?.link ? (
														<Link
															to={
																error?.response
																	?.data?.link
															}
														>
															Error:
															{
																error?.response
																	?.data
																	?.error
															}
														</Link>
													) : (
														<>
															{
																error?.response
																	?.data
																	?.error
															}
														</>
													)
											)
										} else {
											setErrorMessage(
												<>Error: Unknown error.</>
											)
												console.error(error) // eslint-disable-line
										}
									}
								})
						}}
						previewAPIData={async (formData: FormData) => {
							const parsedFormData = await ParsedFormData(
								formData,
								[]
							)

							return {
								data: JSON.stringify(parsedFormData, null, 4),
								method: 'POST',
								url: `${APIHostname}/api/v1/partners/${id}/organization_access`,
							}
						}}
						readOnly={
							!HasPermission('organization', 'manageaccess') ||
							!partner.active
						}
						submitButtonLabel="ADD ORGANIZATION"
					/>
				</div>

				<NavTable
					apiPathRoot={`/partners/${id}/organization_access`}
					columns={[
						{
							children: [
								{
									control: Control.ImageFile,
									id: 'image',
									title: '',
								},
							],
						},
						{
							children: [
								{
									id: 'code',
									title: 'Account Code',
								},
								{
									id: 'name',
									title: 'Account Name',
								},
							],
						},
						{
							id: 'type',
							title: 'Type',
						},
						{
							children: [
								{
									id: 'city',
									title: 'City',
								},
								{
									id: 'state',
									title: 'State',
								},
								{
									control: Control.Country,
									id: 'country',
									title: 'Country',
								},
							],
						},
						{
							children: [
								{
									control: Control.Partner,
									id: 'account_owner',
									title: 'Account Owner',
								},
								{
									id: 'main_contact_name',
									title: 'Main Contact',
								},
							],
						},
						{
							children: [
								{
									control: Control.Email,
									id: 'main_contact_email',
									title: 'Email',
								},
								{
									control: Control.Phone,
									id: 'main_contact_phone',
									title: 'Phone',
								},
							],
						},
						{
							id: 'notes',
							title: 'Notes',
						},
						{
							children: [
								{
									control: Control.DateTime,
									id: 'created',
									title: 'Creation Date',
								},
								{
									control: Control.User,
									id: 'created_by',
									title: 'Created By',
								},
							],
						},
						{
							children: [
								{
									control: Control.DateTime,
									id: 'modified',
									title: 'Modified Date',
								},
								{
									control: Control.User,
									id: 'modified_by',
									title: 'Modified By',
								},
							],
						},
					]}
					count={organizationCount}
					data={organizations}
					filters={JSON.parse(urlParams.get('filters') ?? '[]')}
					includeDelete={
						// HasRole('administrator') &&
						HasPermission('organization', 'delete')
					}
					linkPathRoot={'/organizations'}
					primaryDisplayColumn="code"
					primaryKey="id"
				/>
			</div>
		</DefaultLayout>
	)
}

export const PartnerTabMenuItems = [].reverse()
