import axios from 'axios'
import { useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'

import AgentAccessIcon from '../../../../../assets/icons/agent-access.svg'
import DownArrowIcon from '../../../../../assets/icons/arrow-down.svg'
import ChainIcon from '../../../../../assets/icons/chain.svg'
import DataCorrectionIcon from '../../../../../assets/icons/data-correction.svg'
import DocumentsIcon from '../../../../../assets/icons/documents.svg'
import PayBalanceIcon from '../../../../../assets/icons/goal-pay-balance.svg'
import PayRequestMoneyIcon from '../../../../../assets/icons/goal-pay-request-money.svg'
import PaySendMoneyIcon from '../../../../../assets/icons/goal-pay-send-money.svg'
import PayTransactionsIcon from '../../../../../assets/icons/goal-pay-transactions.svg'
import PayIncomingRequestsIcon from '../../../../../assets/icons/goal-pay-transfer-money.svg'
import HotListActiveIcon from '../../../../../assets/icons/hot-active.svg'
import HotListInactiveIcon from '../../../../../assets/icons/hot-inactive.svg'
import LockedIcon from '../../../../../assets/icons/locked.svg'
import NewIcon from '../../../../../assets/icons/new.svg'
import PartnerHistoryIcon from '../../../../../assets/icons/organization-history.svg'
import PartnerInfoIcon from '../../../../../assets/icons/organization-info.svg'
import PartnerUsersIcon from '../../../../../assets/icons/organization-users.svg'
import { SearchForm } from '../../../../../components/Form/SearchForm'
import { LeftMenuItem } from '../../../../../components/LeftMenu'
import { NavTable } from '../../../../../components/NavTable'
import { GlobalTopTabItems } from '../../../../../components/Tabs'
import { DefaultLayout } from '../../../../../layouts/DefaultLayout'
import {
	APIHostname,
	HasPermission,
	HasRole,
	LogOff,
} from '../../../../../Profile'
import Control from '../../../../../types/Control'
import { ShipmentSearchLayout } from '../../../../../types/data/Shipment'
import { EncodedStringify, Filter } from '../../../../../types/Filter'
import { EscapeURIParameters } from '../../../../../Utils'
import styles from './Shipments.module.scss'

export const PartnerShipments = () => {
	const { id } = useParams()

	const [shipments, setShipments] = useState([])
	const [shipmentCount, setShipmentCount] = useState(0)
	const [shipmentsLoaded, setShipmentsLoaded] = useState(false)
	const [partnerLoaded, setPartnerLoaded]: any = useState(false)
	const [partner, setPartner]: any = useState(null)
	const [payRequestCount, setPayRequestCount] = useState(0)
	const [countsLoaded, setCountsLoaded] = useState(false)
	const [errorMessage, setErrorMessage] = useState(<></>)
	const initialRedirectMessage = sessionStorage['redirect-message']
	const [redirectMessage] = useState(initialRedirectMessage)

	// Vary based on query string.
	const location = useLocation()
	const urlParams = new URLSearchParams(location.search)
	if (!urlParams.get('limit')) {
		urlParams.set('limit', '25')
	}
	const filters = JSON.parse(urlParams.get('filters') ?? '[]')
	let filterFound = false
	filters.forEach((filter: Filter) => {
		if (filter.aggregate?.toLowerCase() == 'or') {
			filterFound = true
		}
	})
	if (!filterFound) {
		filters.push({
			aggregate: 'or',
			children: [
				{
					field: 'origin_partner',
					operator: 'eq',
					value: id?.toString(),
				},
				{
					field: 'destination_partner',
					operator: 'eq',
					value: id?.toString(),
				},
			],
		})
	}
	urlParams.set('filters', EncodedStringify(filters))

	useEffect(() => {
		axios({
			data: {},
			headers: {
				Authorization: `Bearer ${localStorage['token']}`,
			},
			method: 'GET',
			url: `${APIHostname}/api/v1/partners/${id}${location.search}`,
		})
			.then((response: any) => {
				setErrorMessage(<></>)
				setPartner(response.data)
				setPartnerLoaded(true)

				axios({
					data: {},
					headers: {
						Authorization: `Bearer ${localStorage['token']}`,
					},
					method: 'GET',
					url: `${APIHostname}/api/v1/partners/${id}/pay_counts`,
				})
					.then((response: any) => {
						setErrorMessage(<></>)
						setPayRequestCount(response.data.pay_request_count)
						setCountsLoaded(true)
					})
					.catch((error: any) => {
						switch (error?.response?.status) {
						case 401:
							LogOff('token-expired')
							return
						case 403:
							setCountsLoaded(true)
							setErrorMessage(
								<>
										Error: You do not have permission to
										view GOAL pay requests.
								</>
							)
							break
						case 404:
							setCountsLoaded(true)
							break
						default:
							if (error?.response?.data?.error) {
								setErrorMessage(
									error?.response?.data?.link ? (
										<Link
											to={error?.response?.data?.link}
										>
												Error:
											{error?.response?.data?.error}
										</Link>
									) : (
										<>{error?.response?.data?.error}</>
									)
								)
							} else {
								setErrorMessage(<>Error: Unknown error.</>)
									console.error(error) // eslint-disable-line
							}
						}
					})
			})
			.catch((error: any) => {
				switch (error?.response?.status) {
				case 401:
					LogOff('token-expired')
					return
				case 403:
					setErrorMessage(
						<>
								Error: You do not have permission to view
								partners.
						</>
					)
					break
				default:
					if (error?.response?.data?.error) {
						setErrorMessage(
							error?.response?.data?.link ? (
								<Link to={error?.response?.data?.link}>
										Error:
									{error?.response?.data?.error}
								</Link>
							) : (
								<>{error?.response?.data?.error}</>
							)
						)
					} else {
						setErrorMessage(<>Error: Unknown error.</>)
							console.error(error) // eslint-disable-line
					}
				}
			})

		axios({
			data: {},
			headers: {
				Authorization: `Bearer ${localStorage['token']}`,
			},
			method: 'GET',
			url: `${APIHostname}/api/v1/shipments?${EscapeURIParameters(
				urlParams.toString()
			)}&view=browse`,
		})
			.then((response: any) => {
				setShipments(response.data.shipments)
				setShipmentCount(response.data.shipment_count)
				setShipmentsLoaded(true)
			})
			.catch((error: any) => {
				switch (error?.response?.status) {
				case 401:
					LogOff('token-expired')
					return
				case 403:
					setErrorMessage(
						<>
								Error: You do not have permission to view
								shipments.
						</>
					)
					break
				default:
					if (error?.response?.data?.error) {
						setErrorMessage(
							error?.response?.data?.link ? (
								<Link to={error?.response?.data?.link}>
										Error:
									{error?.response?.data?.error}
								</Link>
							) : (
								<>{error?.response?.data?.error}</>
							)
						)
					} else {
						setErrorMessage(<>Error: Unknown error.</>)
							console.error(error) // eslint-disable-line
					}
				}
			})
	}, [location])

	const ShipmentsTabMenuItems = [
		{
			icon:
				location.search.indexOf(
					'?filters=[{%22field%22:%22hotlist%22,%22operator%22:%22eq%22,%22value%22:%22true%22}]'
				) > -1
					? HotListActiveIcon
					: HotListInactiveIcon,
			requiredPermissions: ['shipment.update'],
			text: 'HOT',
			to: '/shipments/?filters=[{%22field%22:%22hotlist%22,%22operator%22:%22eq%22,%22value%22:%22true%22}]',
		},
		{
			icon: NewIcon,
			requiredPermissions: ['shipment.create'],
			text: 'NEW',
			to: '/shipments/new',
		},
	].reverse()

	const PartnerBottomTabItems = [
		{
			icon: DownArrowIcon,
			text: `PARTNER #${id} SHIPMENTS`,
			to: `/partners/${id}/shipments`,
		},
	]

	const PartnerLeftMenuItems: LeftMenuItem[] = [
		{
			icon: PartnerInfoIcon,
			requiredPermissions: ['partner.read'],
			text: 'PARTNER INFO',
			to: `/partners/${id}`,
		},
		{
			icon: AgentAccessIcon,
			requiredPermissions: ['organization.manageaccess'],
			text: 'ORGANIZATION ACCESS',
			to: `/partners/${id}/organization_access`,
		},
	]

	if (!partner?.goal_member) {
		PartnerLeftMenuItems.push({
			icon: AgentAccessIcon,
			requiredPermissions: ['partner.manageaccess'],
			requiredRole: 'administrator',
			text: 'PARTNER ACCESS',
			to: `/partners/${id}/partner_access`,
		})
	}
	PartnerLeftMenuItems.push({
		icon: DocumentsIcon,
		requiredPermissions: ['partner.read'],
		text: 'PARTNER DOCUMENTS',
		to: `/partners/${id}/documents`,
	})
	PartnerLeftMenuItems.push({
		icon: ChainIcon,
		requiredPermissions: ['shipment.read'],
		text: 'LINKED SHIPMENTS',
		to: `/partners/${id}/shipments`,
	})
	PartnerLeftMenuItems.push({
		icon: PartnerUsersIcon,
		requiredPermissions: ['user.read'],
		text: 'PARTNER USERS',
		to: `/partners/${id}/users`,
	})
	PartnerLeftMenuItems.push({
		icon: PartnerHistoryIcon,
		requiredPermissions: ['partner.read'],
		text: 'PARTNER HISTORY',
		to: `/partners/${id}/history`,
	})

	if (HasRole('administrator')) {
		if (
			HasPermission('pay', 'viewbalance') ||
			HasPermission('pay', 'sendmoney') ||
			HasPermission('pay', 'requestmoney')
		) {
			PartnerLeftMenuItems.push(
				{
					disabled: true,
					requiredPermissions: [],
					text: 'ADMINISTRATOR OPTIONS',
					to: '',
				},
				{
					icon: PayBalanceIcon,
					requiredPermissions: ['pay.viewbalance'],
					text: 'BALANCE',
					to: `/partners/${id}/pay/balance`,
				},
				{
					icon: PaySendMoneyIcon,
					requiredPermissions: ['pay.sendmoney'],
					requiredRole: 'administrator',
					text: 'DEPOSIT MONEY',
					to: `/partners/${id}/pay/deposit`,
				},
				{
					icon: PayRequestMoneyIcon,
					requiredPermissions: ['pay.requestmoney'],
					requiredRole: 'administrator',
					text: 'WITHDRAW MONEY',
					to: `/partners/${id}/pay/withdraw`,
				},
				{
					icon: PayRequestMoneyIcon,
					requiredPermissions: ['pay.requestmoney'],
					text: 'REQUEST MONEY',
					to: `/partners/${id}/pay/request`,
				},
				{
					icon: PaySendMoneyIcon,
					requiredPermissions: ['pay.sendmoney'],
					text: 'SEND MONEY',
					to: `/partners/${id}/pay/send`,
				},
				{
					count: payRequestCount,
					icon: PayIncomingRequestsIcon,
					requiredPermissions: ['pay.sendmoney'],
					text: 'INCOMING PAYMENT REQUESTS',
					to: `/partners/${id}/pay/incoming_requests`,
				},
				{
					icon: PayTransactionsIcon,
					requiredPermissions: ['pay.viewbalance'],
					text: 'TRANSACTIONS',
					to: `/partners/${id}/pay/transactions`,
				}
			)
		}
	}

	if (!shipmentsLoaded || !partnerLoaded || !countsLoaded) {
		if (errorMessage) {
			return (
				<DefaultLayout
					bottomTabItems={PartnerBottomTabItems}
					leftMenuItems={PartnerLeftMenuItems}
					showPrint={true}
					tabMenuItems={ShipmentsTabMenuItems}
					topTabItems={GlobalTopTabItems}
				>
					<div className={styles.shipmentsContainer}>
						<div className={styles.redirectMessage}>
							{redirectMessage}
						</div>
						<div className={styles.errorMessage}>
							{errorMessage}
						</div>
					</div>
				</DefaultLayout>
			)
		}

		return <div className="loading">Loading...</div>
	}

	filters.push({
		field: '*',
		operator: 'contains',
		value: partner.name,
	})

	sessionStorage.removeItem('redirect-message')
	return (
		<DefaultLayout
			bottomTabItems={PartnerBottomTabItems}
			leftMenuItems={PartnerLeftMenuItems}
			showPrint={true}
			tabMenuItems={ShipmentsTabMenuItems}
			topTabItems={GlobalTopTabItems}
		>
			<div className={styles.shipmentsContainer}>
				<div className={styles.redirectMessage}>{redirectMessage}</div>
				<div className={styles.errorMessage}>{errorMessage}</div>

				<div className={styles.searchForm}>
					<SearchForm
						data={{}}
						layout={ShipmentSearchLayout}
						name="shipments"
						readOnly={false}
					/>
				</div>

				<NavTable
					columns={[
						{
							children: [
								{
									id: 'booking_number',
									title: 'BOOKING #',
								},
								{
									control: Control.Partner,
									id: 'origin_partner',
									title: 'ORIGIN OFFICE',
								},
							],
						},
						{
							children: [
								{
									control: Control.Status,
									id: 'status',
									title: 'STATUS',
								},
								{
									control: Control.ConfirmedRejected,
									id: 'confirmed,rejected',
									title: 'CONFIRMED / REJECTED',
								},
							],
						},
						{
							children: [
								{
									id: 'mbl',
									title: 'MBL',
								},
								{
									control: Control.Carrier,
									id: 'mbl_carrier',
									title: 'CARRIER SCAC',
								},
							],
						},
						{
							children: [
								{
									id: 'hbl',
									title: 'HBL',
								},
								{
									control: Control.Carrier,
									id: 'hbl_carrier',
									title: 'HBL SCAC',
								},
							],
						},
						{
							children: [
								{
									control: Control.Date,
									id: 'ready_date',
									title: 'ETD',
								},
								{
									control: Control.Date,
									id: 'eta_discharge',
									title: 'ETA DISCHARGE',
								},
							],
						},
						{
							children: [
								{
									control: Control.Port,
									id: 'loading_port',
									title: 'LOADING PORT',
								},
								{
									control: Control.Partner,
									id: 'destination_partner',
									title: 'DESTINATION PARTNER',
								},
							],
						},
						{
							children: [
								{
									control: Control.Port,
									id: 'discharge_port',
									title: 'DISCHARGE PORT',
								},
								{
									control: Control.Port,
									id: 'place_of_delivery',
									title: 'PLACE OF DELIVERY',
								},
							],
						},
						{
							id: 'vessel',
							title: 'VESSEL',
						},
						{
							children: [
								{
									id: 'container_numbers',
									title: 'CONTAINER #S',
								},
								{
									control: Control.Partner,
									id: 'consignee',
									title: 'CONSIGNEE',
								},
							],
						},
						{
							children: [
								{
									control: Control.DateTime,
									id: 'created',
									title: 'CREATION DATE',
								},
								{
									control: Control.User,
									id: 'created_by',
									title: 'CREATED BY',
								},
							],
						},
						{
							children: [
								{
									control: Control.DateTime,
									id: 'modified',
									title: 'MODIFIED DATE',
								},
								{
									control: Control.User,
									id: 'modified_by',
									title: 'MODIFIED BY',
								},
							],
						},
						{
							children: [
								{
									control: Control.Locked,
									icon: LockedIcon,
									id: 'lock',
									title: 'LOCKED',
								},
								{
									control: Control.HotListed,
									icon: HotListActiveIcon,
									id: 'hotlist',
									title: 'HOT LIST',
								},
								{
									control: Control.DataCorrection,
									icon: DataCorrectionIcon,
									id: 'data_correction',
									title: 'DATA CORRECTION',
								},
							],
						},
					]}
					count={shipmentCount}
					data={shipments}
					filters={filters}
					includeDelete={HasPermission('shipment', 'delete')}
					linkPathRoot="/shipments"
					primaryDisplayColumn="booking_number,hbl,mbl"
					primaryKey="id"
				/>
			</div>
		</DefaultLayout>
	)
}
