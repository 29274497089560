import { ReactNode } from 'react'
import { Link } from 'react-router-dom'

import { Organization } from '../../types/data/Organization'
import { Capitalize } from '../../Utils'
import styles from './OrganizationSummary.module.scss'

export const OrganizationSummary = (props: OrganizationSummaryProps) => {
	if (!props.organization) {
		return <></>
	}

	return (
		<div className={styles.organizationSummaryOuterContainer}>
			<div className={styles.organizationSummaryInnerContainer}>
				<div>
					<div className={styles.label}>ORGANIZATION CODE:</div>
					<div className={styles.value}>
						<Link to={`/organizations/${props.organization.id}`}>
							{props.organization.code}
						</Link>
					</div>
				</div>
				<div>
					<div className={styles.label}>ORGANIZATION NAME:</div>
					<div className={styles.value}>
						{props.organization.name}
					</div>
				</div>
				<div>
					<div className={styles.label}>TYPE:</div>
					<div className={styles.value}>
						{Capitalize(props.organization.type)}
					</div>
				</div>
				<div>
					<div className={styles.label}>ACCOUNT OWNER:</div>
					<div className={styles.value}>
						<Link
							to={`/partners/${props.organization.account_owner}`}
						>
							{props.organization.has_account_owner_logo ? (
								<img
									onError={(event: any) => {
										event.target.style.display = 'none'
									}}
									onLoad={(event: any) => {
										event.target.style.display = ''
									}}
									src={props.organization.logo_image_preview}
								/>
							) : (
								<></>
							)}
							{Capitalize(props.organization.account_owner_name)}
						</Link>
					</div>
				</div>
			</div>
			{props.children}
		</div>
	)
}

export type OrganizationSummaryProps = {
	children?: ReactNode
	organization: Organization
}
