import Control from '../Control'
import { Layout } from '../Layout'

export const OrganizationAccessLayout: Layout = {
	Groups: [
		{
			OptionalRows: [
				{
					Fields: [
						{
							Control: Control.Partner,
							Label: 'ADD PARTNER',
							Multiple: true,
							Property: 'partners',
							Width: 100,
						},
					],
				},
			],
			Width: 100,
		},
	],
}
