import Control from '../Control'
import { Layout } from '../Layout'

export const ACILayout: Layout = {
	Groups: [
		{
			RequiredRows: [
				{
					Fields: [
						{
							Control: Control.ACIBillOfLadingType,
							HardRequired: true,
							Label: 'ACI BILL OF LADING TYPE',
							Property: 'aci_bol_type',
							Width: 100,
						},
					],
				},
			],
			Width: 100,
		},
	],
}
