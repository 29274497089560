import axios from 'axios'
import { useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'

import DownArrowIcon from '../../../../../assets/icons/arrow-down.svg'
import CarriersIcon from '../../../../../assets/icons/carriers.svg'
import ChainIcon from '../../../../../assets/icons/chain.svg'
import HistoryIcon from '../../../../../assets/icons/history.svg'
import { CarrierSummary } from '../../../../../components/CarrierSummary'
import { NavTable } from '../../../../../components/NavTable'
import { GlobalTopTabItems } from '../../../../../components/Tabs'
import { DefaultLayout } from '../../../../../layouts/DefaultLayout'
import { APIHostname, LogOff } from '../../../../../Profile'
import Control from '../../../../../types/Control'
import { EscapeURIParameters } from '../../../../../Utils'
import styles from './CarrierHistory.module.scss'

export const CarrierHistory = () => {
	const { id } = useParams()
	const [history, setHistory]: any = useState(null)
	const [historyCount, setHistoryCount] = useState(0)
	const [errorMessage, setErrorMessage] = useState(<></>)
	const [carrierLoaded, setCarrierLoaded]: any = useState(false)
	const [historyLoaded, setHistoryLoaded]: any = useState(false)
	const [carrier, setCarrier]: any = useState(null)
	const initialRedirectMessage = sessionStorage['redirect-message']
	const [redirectMessage] = useState(initialRedirectMessage)

	// Vary based on query string.
	const location = useLocation()
	const urlParams = new URLSearchParams(location.search)
	if (!urlParams.get('limit')) {
		urlParams.set('limit', '25')
	}

	useEffect(() => {
		axios({
			data: {},
			headers: {
				Authorization: `Bearer ${localStorage['token']}`,
			},
			method: 'GET',
			url: `${APIHostname}/api/v1/carriers/${id}${location.search}`,
		})
			.then((response: any) => {
				setErrorMessage(<></>)
				setCarrier(response.data)
				setCarrierLoaded(true)
			})
			.catch((error: any) => {
				switch (error?.response?.status) {
				case 401:
					LogOff('token-expired')
					return
				case 403:
					setErrorMessage(
						<>
								Error: You do not have permission to view
								carriers.
						</>
					)
					break
				default:
					if (error?.response?.data?.error) {
						setErrorMessage(
							error?.response?.data?.link ? (
								<Link to={error?.response?.data?.link}>
										Error:
									{error?.response?.data?.error}
								</Link>
							) : (
								<>{error?.response?.data?.error}</>
							)
						)
					} else {
						setErrorMessage(<>Error: Unknown error.</>)
							console.error(error) // eslint-disable-line
					}
				}
			})

		axios({
			data: {},
			headers: {
				Authorization: `Bearer ${localStorage['token']}`,
			},
			method: 'GET',
			url: `${APIHostname}/api/v1/carriers/${id}/history?${EscapeURIParameters(
				urlParams.toString()
			)}`,
		})
			.then((response: any) => {
				setErrorMessage(<></>)
				setHistory(response.data.historyRecords)
				setHistoryCount(response.data.historyRecord_count)
				setHistoryLoaded(true)
			})
			.catch((error: any) => {
				switch (error?.response?.status) {
				case 401:
					LogOff('token-expired')
					return
				case 403:
					setErrorMessage(
						<>
								Error: You do not have permission to view
								carriers.
						</>
					)
					break
				default:
					if (error?.response?.data?.error) {
						setErrorMessage(
							error?.response?.data?.link ? (
								<Link to={error?.response?.data?.link}>
										Error:
									{error?.response?.data?.error}
								</Link>
							) : (
								<>{error?.response?.data?.error}</>
							)
						)
					} else {
						setErrorMessage(<>Error: Unknown error.</>)
							console.error(error) // eslint-disable-line
					}
				}
			})
	}, [location])

	const CarrierLeftMenuItems = [
		{
			icon: CarriersIcon,
			requiredPermissions: ['carrier.read'],
			text: 'CARRIER INFO',
			to: `/carriers/${id}`,
		},
		{
			icon: ChainIcon,
			requiredPermissions: ['shipment.read'],
			text: 'LINKED SHIPMENTS',
			to: `/carriers/${id}/shipments`,
		},
		{
			icon: HistoryIcon,
			requiredPermissions: ['shipment.read'],
			text: 'CARRIER HISTORY',
			to: `/carriers/${id}/history`,
		},
	]

	const CarrierHistoryBottomTabItems = [
		{
			icon: DownArrowIcon,
			text: `CARRIER #${id} HISTORY`,
			to: `/carriers/${id}/history`,
		},
	]

	const CarrierTabMenuItems: [] = []

	if (!carrierLoaded || !historyLoaded) {
		if (errorMessage) {
			return (
				<DefaultLayout
					bottomTabItems={CarrierHistoryBottomTabItems}
					leftMenuItems={CarrierLeftMenuItems}
					showPrint={true}
					tabMenuItems={CarrierTabMenuItems}
					topTabItems={GlobalTopTabItems}
				>
					<div className={styles.carrierHistoryContainer}>
						<div className={styles.redirectMessage}>
							{redirectMessage}
						</div>
						<div className={styles.errorMessage}>
							{errorMessage}
						</div>
					</div>
				</DefaultLayout>
			)
		}

		return <div className="loading">Loading...</div>
	}

	sessionStorage.removeItem('redirect-message')
	return (
		<DefaultLayout
			bottomTabItems={CarrierHistoryBottomTabItems}
			leftMenuItems={CarrierLeftMenuItems}
			showPrint={true}
			tabMenuItems={CarrierTabMenuItems}
			topTabItems={GlobalTopTabItems}
		>
			<div className={styles.carrierHistoryContainer}>
				<div className={styles.redirectMessage}>{redirectMessage}</div>
				<div className={styles.errorMessage}>
					{errorMessage}{' '}
					{!carrier?.active ? (
						<>This carrier has been deleted.</>
					) : (
						<></>
					)}
				</div>

				<CarrierSummary carrier={carrier} />

				<div className={styles.navTable}>
					<NavTable
						columns={[
							{
								control: Control.Hyperlink,
								id: 'link',
								title: 'Link',
							},
							{
								id: 'version',
								title: 'Version',
							},
							{
								id: 'action',
								title: 'Action',
							},
							{
								id: 'element',
								title: 'Element',
							},
							{
								id: 'original',
								title: 'Original Value',
							},
							{
								id: 'new',
								title: 'New Value',
							},
							{
								control: Control.User,
								id: 'created_by',
								title: 'USER',
							},
							{
								control: Control.DateTime,
								id: 'created',
								title: 'Date/Time',
							},
						]}
						count={historyCount}
						data={history}
						filters={JSON.parse(urlParams.get('filters') ?? '[]')}
						includeDelete={false}
						primaryDisplayColumn=""
						primaryKey=""
					/>
				</div>
			</div>
		</DefaultLayout>
	)
}
