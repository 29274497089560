import { Link } from 'react-router-dom'

import OpenShipmentsIcon from '../../../assets/icons/open-shipment.svg'
import { EncodedStringify } from '../../../types/Filter'
import styles from './OpenShipments.module.scss'

export const OpenShipments = (props: OpenShipmentsProps) => {
	const filters = [
		{
			field: 'status',
			operator: 'ne',
			value: 'closed',
		},
	]
	return (
		<div className="kpiOuterContainer">
			<div className={`kpiInnerContainer ${styles.innerContainer}`}>
				<Link to={`/shipments/?filters=${EncodedStringify(filters)}`}>
					<img alt="OPEN SHIPMENTS" src={OpenShipmentsIcon} />
					<br />
					OPEN
					<br />
					SHIPMENTS:
					<br />
					{props.openShipmentCount}
				</Link>
			</div>
		</div>
	)
}

export type OpenShipmentsProps = {
	openShipmentCount: number
}
