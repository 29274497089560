import axios from 'axios'
import { MouseEvent, useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'

import DownArrowIcon from '../../../../assets/icons/arrow-down.svg'
import CarriersIcon from '../../../../assets/icons/carriers.svg'
import ChainIcon from '../../../../assets/icons/chain.svg'
import CopyIcon from '../../../../assets/icons/copy.svg'
import DeleteIcon from '../../../../assets/icons/delete.svg'
import HistoryIcon from '../../../../assets/icons/history.svg'
import { EditForm, ParsedFormData } from '../../../../components/Form/EditForm'
import { GlobalTopTabItems, TabItem } from '../../../../components/Tabs'
import { DefaultLayout } from '../../../../layouts/DefaultLayout'
import { APIHostname, HasPermission, LogOff } from '../../../../Profile'
import { CarrierEditLayout } from '../../../../types/data/Carrier'
import styles from './Carrier.module.scss'

export const Carrier = () => {
	const { id } = useParams()
	const [carrier, setCarrier]: any = useState(null)
	const [errorMessage, setErrorMessage] = useState(<></>)
	const initialRedirectMessage = sessionStorage['redirect-message']
	const [redirectMessage] = useState(initialRedirectMessage)

	// Vary based on query string.
	const location = useLocation()

	useEffect(() => {
		axios({
			data: {},
			headers: {
				Authorization: `Bearer ${localStorage['token']}`,
			},
			method: 'GET',
			url: `${APIHostname}/api/v1/carriers/${id}`,
		})
			.then((response: any) => {
				setErrorMessage(<></>)
				setCarrier(response.data)
			})
			.catch((error: any) => {
				switch (error?.response?.status) {
				case 401:
					LogOff('token-expired')
					return
				case 403:
					setErrorMessage(
						<>
								Error: You do not have permission to view
								carriers.
						</>
					)
					break
				default:
					if (error?.response?.data?.error) {
						setErrorMessage(
							error?.response?.data?.link ? (
								<Link to={error?.response?.data?.link}>
										Error:
									{error?.response?.data?.error}
								</Link>
							) : (
								<>{error?.response?.data?.error}</>
							)
						)
					} else {
						setErrorMessage(<>Error: Unknown error.</>)
							console.error(error) // eslint-disable-line
					}
				}
			})
	}, [location])

	const CarrierBottomTabItems = [
		{
			icon: DownArrowIcon,
			text: `CARRIER #${id}`,
			to: `/carriers/${id}`,
		},
	]

	const CarrierLeftMenuItems = [
		{
			icon: CarriersIcon,
			requiredPermissions: ['carrier.read'],
			text: 'CARRIER INFO',
			to: `/carriers/${id}`,
		},
		{
			icon: ChainIcon,
			requiredPermissions: ['shipment.read'],
			text: 'LINKED SHIPMENTS',
			to: `/carriers/${id}/shipments`,
		},
		{
			icon: HistoryIcon,
			requiredPermissions: ['shipment.read'],
			text: 'CARRIER HISTORY',
			to: `/carriers/${id}/history`,
		},
	]

	const CarrierTabMenuItems: TabItem[] = [
		{
			icon: CopyIcon,
			onClick: async (e: MouseEvent<Element>) => {
				e.preventDefault()

				// eslint-disable-next-line
				const confirmation = confirm(`Really copy carrier?`)
				if (!confirmation) {
					return
				}

				const form = document.querySelector('form[name=\'carrier\']')
				const formData = new FormData(form as HTMLFormElement)
				const parsedFormData = await ParsedFormData(formData, [])
				parsedFormData.copy = true
				axios({
					data: parsedFormData,
					headers: {
						Authorization: `Bearer ${localStorage['token']}`,
						'Content-Type': 'application/json',
					},
					method: 'POST',
					url: `${APIHostname}/api/v1/carriers`,
				})
					.then((response: any) => {
						sessionStorage[
							'redirect-message'
						] = `Carrier #${response.data.carrier_id} created.`
						window.history.replaceState(
							{},
							'',
							`/carriers/${response.data.carrier_id}`
						)
						document.location.reload()
					})
					.catch((error: any) => {
						switch (error?.response?.status) {
						case 401:
							LogOff('token-expired')
							return
						case 403:
							setErrorMessage(
								<>
										Error: You do not have permission to
										edit carriers.
								</>
							)
							break
						default:
							if (error?.response?.data?.error) {
								setErrorMessage(
									error?.response?.data?.link ? (
										<Link
											to={error?.response?.data?.link}
										>
												Error:
											{error?.response?.data?.error}
										</Link>
									) : (
										<>{error?.response?.data?.error}</>
									)
								)
							} else {
								setErrorMessage(<>Error: Unknown error.</>)
									console.error(error) // eslint-disable-line
							}
						}
					})
			},
			requiredPermissions: ['carrier.create'],
			requiredRole: 'administrator||carrier',
			text: 'COPY',
			to: '#',
		},
	]
	if (carrier?.active) {
		CarrierTabMenuItems.push({
			icon: DeleteIcon,
			onClick: async (e: MouseEvent<Element>) => {
				e.preventDefault()

				// eslint-disable-next-line
				const confirmation = confirm(`Really delete carrier?`)
				if (!confirmation) {
					return
				}

				axios({
					headers: {
						Authorization: `Bearer ${localStorage['token']}`,
						'Content-Type': 'application/json',
					},
					method: 'DELETE',
					url: `${APIHostname}/api/v1/carriers/${id}`,
				})
					.then((response: any) => {
						sessionStorage[
							'redirect-message'
						] = `Carrier #${response.data.carrier_id} deleted.`
						window.history.replaceState({}, '', '/carriers')
						document.location.reload()
					})
					.catch((error: any) => {
						switch (error?.response?.status) {
						case 401:
							LogOff('token-expired')
							return
						case 403:
							setErrorMessage(
								<>
										Error: You do not have permission to
										delete carriers.
								</>
							)
							break
						default:
							if (error?.response?.data?.error) {
								setErrorMessage(
									error?.response?.data?.link ? (
										<Link
											to={error?.response?.data?.link}
										>
												Error:
											{error?.response?.data?.error}
										</Link>
									) : (
										<>{error?.response?.data?.error}</>
									)
								)
							} else {
								setErrorMessage(<>Error: Unknown error.</>)
									console.error(error) // eslint-disable-line
							}
						}
					})
			},
			requiredPermissions: ['carrier.delete'],
			text: 'DELETE',
			to: '#',
		})
	} else {
		CarrierTabMenuItems.push({
			icon: DeleteIcon,
			onClick: async (e: MouseEvent<Element>) => {
				e.preventDefault()

				// eslint-disable-next-line
				const confirmation = confirm(`Really undelete carrier?`)
				if (!confirmation) {
					return
				}

				axios({
					headers: {
						Authorization: `Bearer ${localStorage['token']}`,
						'Content-Type': 'application/json',
					},
					method: 'POST',
					url: `${APIHostname}/api/v1/carriers/${id}/undelete`,
				})
					.then((response: any) => {
						sessionStorage[
							'redirect-message'
						] = `Carrier #${response.data.carrier_id} undeleted.`
						window.history.replaceState({}, '', '/carriers')
						document.location.reload()
					})
					.catch((error: any) => {
						switch (error?.response?.status) {
						case 401:
							LogOff('token-expired')
							return
						case 403:
							setErrorMessage(
								<>
										Error: You do not have permission to
										undelete carriers.
								</>
							)
							break
						default:
							if (error?.response?.data?.error) {
								setErrorMessage(
									error?.response?.data?.link ? (
										<Link
											to={error?.response?.data?.link}
										>
												Error:
											{error?.response?.data?.error}
										</Link>
									) : (
										<>{error?.response?.data?.error}</>
									)
								)
							} else {
								setErrorMessage(<>Error: Unknown error.</>)
									console.error(error) // eslint-disable-line
							}
						}
					})
			},
			requiredPermissions: ['carrier.delete'],
			text: 'UNDELETE',
			to: '#',
		})
	}
	CarrierTabMenuItems.reverse()

	if (carrier == null) {
		if (errorMessage) {
			<DefaultLayout
				bottomTabItems={CarrierBottomTabItems}
				leftMenuItems={CarrierLeftMenuItems}
				showPrint={true}
				tabMenuItems={CarrierTabMenuItems}
				topTabItems={GlobalTopTabItems}
			>
				<div className={styles.carrierContainer}>
					<div className={styles.redirectMessage}>
						{redirectMessage}
					</div>
					<div className={styles.errorMessage}>{errorMessage}</div>
				</div>
			</DefaultLayout>
		}

		return <div className="loading">Loading...</div>
	}

	sessionStorage.removeItem('redirect-message')

	const readOnly = !HasPermission('carrier', 'update') || !carrier?.active
	return (
		<DefaultLayout
			bottomTabItems={CarrierBottomTabItems}
			leftMenuItems={CarrierLeftMenuItems}
			showPrint={true}
			tabMenuItems={CarrierTabMenuItems}
			topTabItems={GlobalTopTabItems}
		>
			<div className={styles.carrierContainer}>
				<div className={styles.redirectMessage}>{redirectMessage}</div>
				<div className={styles.errorMessage}>
					{errorMessage}{' '}
					{!carrier?.active ? (
						<>This carrier has been deleted.</>
					) : (
						<></>
					)}
				</div>

				<EditForm
					data={carrier}
					layout={CarrierEditLayout}
					name="carrier"
					onSubmit={async (formData: FormData) => {
						// Update organization
						axios({
							data: await ParsedFormData(formData, []),
							headers: {
								Authorization: `Bearer ${localStorage['token']}`,
								'Content-Type': 'application/json',
							},
							method: 'POST',
							url: `${APIHostname}/api/v1/carriers/${id}`,
						})
							.then(() => {
								sessionStorage[
									'redirect-message'
								] = `Carrier #${id} updated.`
								window.history.replaceState({}, '', '/carriers')
								document.location.reload()
							})
							.catch((error: any) => {
								switch (error?.response?.status) {
								case 401:
									LogOff('token-expired')
									return
								case 403:
										console.error(error) // eslint-disable-line
									setErrorMessage(
										<>
												Error: You do not have
												permission to edit carriers.
										</>
									)
									break
								default:
									if (error?.response?.data?.error) {
										setErrorMessage(
											<Link
												to={
													error?.response?.data
														?.link
												}
											>
													Error:
												{
													error?.response?.data
														?.error
												}
											</Link>
										)
									} else {
										setErrorMessage(
											<>Error: Unknown error.</>
										)
											console.error(error) // eslint-disable-line
									}
								}
							})
					}}
					previewAPIData={async (formData: FormData) => {
						const parsedFormData = await ParsedFormData(
							formData,
							[]
						)

						return {
							data: JSON.stringify(parsedFormData, null, 4),
							method: 'POST',
							url: `${APIHostname}/api/v1/carriers/${id}`,
						}
					}}
					readOnly={readOnly}
				/>
			</div>
		</DefaultLayout>
	)
}
