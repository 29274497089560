import Control from '../Control'
import { SearchOperator } from '../Field'
import { Layout } from '../Layout'

export type NRA = {
	id: number
	organization_name: string
	reference_number: string
}

export const EditNRALayout: Layout = {
	Groups: [
		{
			Border: true,
			Margin: '1rem 0 0 0',
			OptionalRows: [
				{
					Fields: [
						{
							Control: Control.Organization,
							HardRequired: true,
							Label: 'CONSIGNEE',
							Property: 'organization',
							SubType: 'consignee',
							Width: 25,
						},
						{
							Label: 'PRESENTED TO',
							Property: 'presented_to',
							Width: 25,
						},
						{
							Label: 'NRA REFERENCE NAME',
							Property: 'reference_name',
							SoftRequired: true,
							Width: 25,
						},
						{
							Default: '[AUTO-GENERATED UPON SAVE]',
							HardRequired: true,
							Label: 'NRA FILE NUMBER',
							Property: 'reference_number',
							ReadOnly: true,
							Width: 25,
						},
					],
				},
				{
					Fields: [
						{
							Control: Control.User,
							Label: 'PRESENTED BY',
							Property: 'presented_by',
							Width: 25,
						},

						{
							Control: Control.Port,
							Label: 'ORIGIN',
							Property: 'origin',
							Width: 25,
						},
						{
							Control: Control.Port,
							Label: 'DESTINATION',
							Property: 'destination',
							Width: 25,
						},
						{ Label: '', Property: '', Width: 25 },
					],
				},
				{
					Fields: [
						{
							Control: Control.Date,
							Label: 'EFFECTIVE DATE',
							Property: 'effective_date',
							Width: 25,
						},
						{
							Control: Control.Date,
							Label: 'EXPIRATION DATE',
							Property: 'expiration_date',
							Width: 25,
						},

						{ Label: '', Property: '', Width: 25 },
						{ Label: '', Property: '', Width: 25 },
					],
				},
				{
					Fields: [
						{
							Label: 'ADDITIONAL NRA CHARGES NOTE',
							Property: 'charges_note',
							Width: 50,
						},
						{
							Control: Control.NRAReference,
							Label: 'REPLACES',
							Nullable: true,
							Property: 'replaces',
							ReadOnly: true,
							Width: 25,
						},
						{
							Control: Control.NRAReference,
							Label: 'REPLACED BY',
							Nullable: true,
							Property: 'replaced_by',
							ReadOnly: true,
							Width: 25,
						},
					],
				},
			],
			RequiredRows: [
				{
					Fields: [
						{
							IncludeInEdit: false,
							IncludeInSearch: true,
							Label: 'WILDCARD (SEARCH ALL FIELDS; USE "OR" FOR MULTIPLE CRITERIA)',
							Property: '*',
							Width: 100,
						},
					],
				},
			],
			Width: 100,
		},
		{
			IncludeInSearch: false,
			Label: 'RATES',
			LabelBackground: 'blue',
			Padding: '0',
			RequiredRows: [
				{
					Fields: [
						{
							Control: Control.NRARates,
							Property: 'rates',
							Width: 100,
						},
					],
				},
			],

			Width: 100,
		},
	],
}

export const NewNRALayout: Layout = {
	Groups: [
		{
			Border: true,
			Margin: '1rem 0 0 0',
			OptionalRows: [
				{
					Fields: [
						{
							Control: Control.Organization,
							HardRequired: true,
							Label: 'CONSIGNEE',
							Property: 'organization',
							SubType: 'consignee',
							Width: 25,
						},
						{
							Label: 'PRESENTED TO',
							Property: 'presented_to',
							Width: 25,
						},
						{
							Label: 'NRA REFERENCE NAME',
							Property: 'reference_name',
							SoftRequired: true,
							Width: 25,
						},
						{
							Default: '[AUTO-GENERATED UPON SAVE]',
							HardRequired: true,
							Label: 'NRA FILE NUMBER',
							Property: 'reference_number',
							ReadOnly: true,
							Width: 25,
						},
					],
				},
				{
					Fields: [
						{
							Control: Control.User,
							Label: 'PRESENTED BY',
							Property: 'presented_by',
							Width: 25,
						},

						{
							Control: Control.Port,
							Label: 'ORIGIN',
							Property: 'origin',
							Width: 25,
						},
						{
							Control: Control.Port,
							Label: 'DESTINATION',
							Property: 'destination',
							Width: 25,
						},
						{ Label: '', Property: '', Width: 25 },
					],
				},
				{
					Fields: [
						{
							Control: Control.Date,
							Label: 'EFFECTIVE DATE',
							Property: 'effective_date',
							Width: 25,
						},
						{
							Control: Control.Date,
							Label: 'EXPIRATION DATE',
							Property: 'expiration_date',
							Width: 25,
						},

						{ Label: '', Property: '', Width: 25 },
						{ Label: '', Property: '', Width: 25 },
					],
				},
				{
					Fields: [
						{
							Label: 'ADDITIONAL NRA CHARGES NOTE',
							Property: 'charges_note',
							Width: 50,
						},
					],
				},
			],
			Width: 100,
		},
		{
			Label: 'RATES',
			LabelBackground: 'blue',
			Padding: '0',
			RequiredRows: [
				{
					Fields: [
						{
							Control: Control.NRARates,
							Property: 'rates',
							Width: 100,
						},
					],
				},
			],

			Width: 100,
		},
	],
}

export const SearchNRALayout: Layout = {
	Groups: [
		{
			Border: true,
			Margin: '1rem 0 0 0',
			OptionalRows: [
				{
					Fields: [
						{
							Control: Control.Organization,
							HardRequired: true,
							Label: 'CONSIGNEE',
							Property: 'organization',
							SubType: 'consignee',
							Width: 25,
						},
						{
							Label: 'PRESENTED TO',
							Property: 'presented_to',
							Width: 25,
						},
						{
							Label: 'NRA REFERENCE NAME',
							Property: 'reference_name',
							SoftRequired: true,
							Width: 25,
						},
						{
							HardRequired: true,
							Label: 'NRA FILE NUMBER',
							Property: 'reference_number',
							ReadOnly: true,
							Width: 25,
						},
					],
				},
				{
					Fields: [
						{
							Control: Control.User,
							Label: 'PRESENTED BY',
							Property: 'presented_by',
							Width: 25,
						},

						{
							Control: Control.Port,
							Label: 'ORIGIN',
							Property: 'origin',
							Width: 25,
						},
						{
							Control: Control.Port,
							Label: 'DESTINATION',
							Property: 'destination',
							Width: 25,
						},
						{ Label: '', Property: '', Width: 25 },
					],
				},
				{
					Fields: [
						{
							Control: Control.Date,
							Label: 'EFFECTIVE DATE',
							Property: 'effective_date-gte',
							SearchOperator: SearchOperator.GreaterThanOrEqual,
							Width: 22.5,
						},
						{
							Control: Control.Static,
							Property: 'TO',
							Width: 5,
						},
						{
							Control: Control.Date,
							Property: 'effective_date-lte',
							SearchOperator: SearchOperator.LessThanOrEqual,
							Width: 22.5,
						},
						{
							Control: Control.Date,
							Label: 'EXPIRATION DATE',
							Property: 'expiration_date-gte',
							SearchOperator: SearchOperator.GreaterThanOrEqual,
							Width: 22.5,
						},
						{
							Control: Control.Static,
							Property: 'TO',
							Width: 5,
						},
						{
							Control: Control.Date,
							Property: 'expiration_date-lte',
							SearchOperator: SearchOperator.LessThanOrEqual,
							Width: 22.5,
						},
					],
				},
				{
					Fields: [
						{
							Label: 'ADDITIONAL NRA CHARGES NOTE',
							Property: 'charges_note',
							Width: 50,
						},
						{
							Control: Control.Boolean,
							Label: 'REPLACES ANOTHER',
							Property: 'is_replacement',
							Width: 25,
						},
						{
							Control: Control.Boolean,
							Default: 'false',
							Label: 'IS REPLACED BY ANOTHER',
							Property: 'is_replaced',
							Width: 25,
						},
					],
				},
				{
					Fields: [
						{
							Control: Control.Active,
							IncludeInEdit: false,
							Label: 'INCLUDE',
							Property: 'active',
							RequiredRole: 'administrator',
							Width: 25,
						},
					],
				},
			],
			RequiredRows: [
				{
					Fields: [
						{
							IncludeInEdit: false,
							IncludeInSearch: true,
							Label: 'WILDCARD (SEARCH ALL FIELDS; USE "OR" FOR MULTIPLE CRITERIA)',
							Property: '*',
							Width: 100,
						},
					],
				},
			],
			Width: 100,
		},
	],
}
