import axios from 'axios'
import { useState } from 'react'
import { Link } from 'react-router-dom'

import DownArrowIcon from '../../../../assets/icons/arrow-down.svg'
import OrganizationsIcon from '../../../../assets/icons/organizations.svg'
import { EditForm, ParsedFormData } from '../../../../components/Form/EditForm'
import { GlobalTopTabItems } from '../../../../components/Tabs'
import { DefaultLayout } from '../../../../layouts/DefaultLayout'
import {
	APIHostname,
	HasPermission,
	LogOff,
	ProfilePartnerID,
	ProfilePartnerName,
} from '../../../../Profile'
import { OrganizationEditLayout } from '../../../../types/data/Organization'
import styles from './OrganizationsNew.module.scss'

export const OrganizationsNew = () => {
	const [errorMessage, setErrorMessage] = useState(<></>)
	const initialRedirectMessage = sessionStorage['redirect-message']
	const [redirectMessage] = useState(initialRedirectMessage)

	sessionStorage.removeItem('redirect-message')
	return (
		<DefaultLayout
			bottomTabItems={OrganizationsBottomTabItems}
			leftMenuItems={OrganizationsLeftMenuItems}
			showPrint={true}
			tabMenuItems={OrganizationsTabMenuItems}
			topTabItems={GlobalTopTabItems}
		>
			<div className={styles.formContainer}>
				<div className={styles.redirectMessage}>{redirectMessage}</div>
				<div className={styles.errorMessage}>{errorMessage}</div>
				<EditForm
					data={
						ProfilePartnerID()
							? {
								account_owner: ProfilePartnerID(),
								account_owner_name: ProfilePartnerName(),
								account_owner_preview_image: `/api/v1/partners/${ProfilePartnerID()}/logo`,
							  }
							: {}
					}
					layout={OrganizationEditLayout}
					name="organization"
					onSubmit={async (formData: FormData) => {
						// Create organization
						axios({
							data: await ParsedFormData(formData, []),
							headers: {
								Authorization: `Bearer ${localStorage['token']}`,
								'Content-Type': 'application/json',
							},
							method: 'POST',
							url: `${APIHostname}/api/v1/organizations`,
						})
							.then((response: any) => {
								sessionStorage[
									'redirect-message'
								] = `Organization #${response.data.organization_id} created.`
								window.history.replaceState(
									{},
									'',
									'/organizations'
								)
								document.location.reload()
							})
							.catch((error: any) => {
								switch (error?.response?.status) {
								case 401:
									LogOff('token-expired')
									return
								case 403:
									setErrorMessage(
										<>
												Error: You do not have
												permission to edit
												organizations.
										</>
									)
									break
								default:
									if (error?.response?.data?.error) {
										setErrorMessage(
											error?.response?.data?.link ? (
												<Link
													to={
														error?.response
															?.data?.link
													}
												>
														Error:
													{
														error?.response
															?.data?.error
													}
												</Link>
											) : (
												<>
													{
														error?.response
															?.data?.error
													}
												</>
											)
										)
									} else {
										setErrorMessage(
											<>Error: Unknown error.</>
										)
											console.error(error) // eslint-disable-line
									}
								}
							})
					}}
					previewAPIData={async (formData: FormData) => {
						const payload = await ParsedFormData(formData, [])

						return {
							data: JSON.stringify(payload, null, 4),
							method: 'POST',
							url: `${APIHostname}/api/v1/organizations`,
						}
					}}
					readOnly={!HasPermission('organization', 'create')}
				/>
			</div>
		</DefaultLayout>
	)
}

export const OrganizationsTabMenuItems = []

export const OrganizationsBottomTabItems = [
	{
		icon: DownArrowIcon,
		text: 'ORGANIZATIONS',
		to: '/organizations',
	},
]

export const OrganizationsLeftMenuItems = [
	{
		icon: OrganizationsIcon,
		requiredPermissions: ['organization.read'],
		text: 'ORGANIZATIONS',
		to: '/organizations',
	},
]
