import axios from 'axios'
import { useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'

import ArrivalNoticeIcon from '../../../../../assets/icons/arrival-notice.svg'
import DownArrowIcon from '../../../../../assets/icons/arrow-down.svg'
import ArrivalNoticeHistoryIcon from '../../../../../assets/icons/history.svg'
import PDFIcon from '../../../../../assets/icons/pdf.svg'
import { ArrivalNoticeSummary } from '../../../../../components/ArrivalNoticeSummary'
import { NavTable } from '../../../../../components/NavTable'
import { GlobalTopTabItems } from '../../../../../components/Tabs'
import { DefaultLayout } from '../../../../../layouts/DefaultLayout'
import { APIHostname, LogOff } from '../../../../../Profile'
import Control from '../../../../../types/Control'
import { EscapeURIParameters } from '../../../../../Utils'
import styles from './ArrivalNoticeHistory.module.scss'

export const ArrivalNoticeHistory = () => {
	const { id } = useParams()
	const [history, setHistory]: any = useState(null)
	const [arrivalNotice, setArrivalNotice]: any = useState(null)
	const [arrivalNoticeLoaded, setArrivalNoticeLoaded] = useState(false)
	const [historyLoaded, setHistoryLoaded] = useState(false)
	const [historyCount, setHistoryCount] = useState(0)
	const [errorMessage, setErrorMessage] = useState(<></>)
	const initialRedirectMessage = sessionStorage['redirect-message']
	const [redirectMessage] = useState(initialRedirectMessage)

	// Vary based on query string.
	const location = useLocation()
	const urlParams = new URLSearchParams(location.search)
	if (!urlParams.get('limit')) {
		urlParams.set('limit', '25')
	}

	useEffect(() => {
		axios({
			data: {},
			headers: {
				Authorization: `Bearer ${localStorage['token']}`,
			},
			method: 'GET',
			url: `${APIHostname}/api/v1/arrival_notices/${id}`,
		})
			.then((response: any) => {
				setErrorMessage(<></>)
				setArrivalNotice(response.data)
				setArrivalNoticeLoaded(true)
			})
			.catch((error: any) => {
				switch (error?.response?.status) {
				case 401:
					LogOff('token-expired')
					return
				case 403:
					setErrorMessage(
						<>
								Error: You do not have permission to view
								arrival notices.
						</>
					)
					break
				default:
					if (error?.response?.data?.error) {
						setErrorMessage(
							error?.response?.data?.link ? (
								<Link to={error?.response?.data?.link}>
										Error:
									{error?.response?.data?.error}
								</Link>
							) : (
								<>{error?.response?.data?.error}</>
							)
						)
					} else {
						setErrorMessage(<>Error: Unknown error.</>)
							console.error(error) // eslint-disable-line
					}
				}
			})

		axios({
			data: {},
			headers: {
				Authorization: `Bearer ${localStorage['token']}`,
			},
			method: 'GET',
			url: `${APIHostname}/api/v1/arrival_notices/${id}/history?${EscapeURIParameters(
				urlParams.toString()
			)}`,
		})
			.then((response: any) => {
				setErrorMessage(<></>)
				setHistory(response.data.historyRecords)
				setHistoryCount(response.data.historyRecord_count)
				setHistoryLoaded(true)
			})
			.catch((error: any) => {
				switch (error?.response?.status) {
				case 401:
					LogOff('token-expired')
					return
				case 403:
					setErrorMessage(
						<>
								Error: You do not have permission to view
								arrival notices.
						</>
					)
					break
				default:
					if (error?.response?.data?.error) {
						setErrorMessage(
							error?.response?.data?.link ? (
								<Link to={error?.response?.data?.link}>
										Error:
									{error?.response?.data?.error}
								</Link>
							) : (
								<>{error?.response?.data?.error}</>
							)
						)
					} else {
						setErrorMessage(<>Error: Unknown error.</>)
							console.error(error) // eslint-disable-line
					}
				}
			})
	}, [location])

	const ArrivalNoticeLeftMenuItems = [
		{
			icon: ArrivalNoticeIcon,
			text: 'ARRIVAL NOTICE',
			to: `/arrival_notices/${id}`,
		},
		{
			icon: PDFIcon,
			target: '_blank',
			text: 'ARRIVAL NOTICE PDF',
			to: `${APIHostname}/api/v1/arrival_notices/${id}/pdf`,
		},
		{
			icon: ArrivalNoticeHistoryIcon,
			text: 'ARRIVAL NOTICE HISTORY',
			to: `/arrival_notices/${id}/history`,
		},
	]

	const ArrivalNoticeHistoryBottomTabItems = [
		{
			icon: DownArrowIcon,
			text: `ARRIVAL NOTICE #${id} HISTORY`,
			to: `/arrival_notices/${id}/history`,
		},
	]

	if (!arrivalNoticeLoaded || !historyLoaded) {
		if (errorMessage) {
			return (
				<DefaultLayout
					bottomTabItems={ArrivalNoticeHistoryBottomTabItems}
					leftMenuItems={ArrivalNoticeLeftMenuItems}
					showPrint={true}
					tabMenuItems={ArrivalNoticesTabMenuItems}
					topTabItems={GlobalTopTabItems}
				>
					<div className={styles.arrivalNoticeHistoryContainer}>
						<div className={styles.redirectMessage}>
							{redirectMessage}
						</div>
						<div className={styles.errorMessage}>
							{errorMessage}
						</div>
					</div>
				</DefaultLayout>
			)
		}

		return <div className="loading">Loading...</div>
	}

	sessionStorage.removeItem('redirect-message')
	return (
		<DefaultLayout
			bottomTabItems={ArrivalNoticeHistoryBottomTabItems}
			leftMenuItems={ArrivalNoticeLeftMenuItems}
			showPrint={true}
			tabMenuItems={ArrivalNoticesTabMenuItems}
			topTabItems={GlobalTopTabItems}
		>
			<div className={styles.arrivalNoticeHistoryContainer}>
				<ArrivalNoticeSummary arrivalNotice={arrivalNotice} />

				<div className={styles.redirectMessage}>{redirectMessage}</div>
				<div className={styles.errorMessage}>
					{errorMessage}{' '}
					{!arrivalNotice?.active ? (
						<>This arrival notice has been deleted.</>
					) : (
						<></>
					)}
				</div>

				<div className={styles.navTable}>
					<NavTable
						columns={[
							{
								control: Control.Hyperlink,
								id: 'link',
								title: 'Link',
							},
							{
								id: 'version',
								title: 'Version',
							},
							{
								id: 'action',
								title: 'Action',
							},
							{
								id: 'element',
								title: 'Element',
							},
							{
								id: 'original',
								title: 'Original Value',
							},
							{
								id: 'new',
								title: 'New Value',
							},
							{
								control: Control.LogFile,
								id: 'filename',
								title: 'FILENAME',
							},
							{
								control: Control.User,
								id: 'created_by',
								title: 'User',
							},
							{
								control: Control.DateTime,
								id: 'created',
								title: 'Date/Time',
							},
						]}
						count={historyCount}
						data={history}
						filters={JSON.parse(urlParams.get('filters') ?? '[]')}
						includeDelete={false}
						includeExport={false}
						primaryDisplayColumn=""
						primaryKey=""
					/>
				</div>
			</div>
		</DefaultLayout>
	)
}

export const ArrivalNoticesTabMenuItems = []
