import axios from 'axios'
import { useState } from 'react'
import { Link } from 'react-router-dom'

import DownArrowIcon from '../../../../assets/icons/arrow-down.svg'
import PartnersIcon from '../../../../assets/icons/partners.svg'
import { EditForm, ParsedFormData } from '../../../../components/Form/EditForm'
import { GlobalTopTabItems } from '../../../../components/Tabs'
import { DefaultLayout } from '../../../../layouts/DefaultLayout'
import { APIHostname, HasPermission, LogOff } from '../../../../Profile'
import { PartnerEditLayout } from '../../../../types/data/Partner'
import styles from './PartnersNew.module.scss'

export const PartnersNew = () => {
	const [errorMessage, setErrorMessage] = useState(<></>)
	const initialRedirectMessage = sessionStorage['redirect-message']
	const [redirectMessage] = useState(initialRedirectMessage)

	/* eslint-disable @typescript-eslint/ban-types */
	sessionStorage.removeItem('redirect-message')
	return (
		<DefaultLayout
			bottomTabItems={PartnersNewBottomTabItems}
			leftMenuItems={PartnersNewLeftMenuItems}
			showPrint={true}
			tabMenuItems={PartnersNewTabMenuItems}
			topTabItems={GlobalTopTabItems}
		>
			<div className={styles.partnerContainer}>
				<div className={styles.redirectMessage}>{redirectMessage}</div>
				<div className={styles.errorMessage}>{errorMessage}</div>

				<EditForm
					data={{}}
					layout={PartnerEditLayout}
					name="partner"
					onSubmit={async (formData: FormData) => {
						// Create partner
						const parsedFormData = await ParsedFormData(
							formData,
							[]
						)
						axios({
							data: parsedFormData,
							headers: {
								Authorization: `Bearer ${localStorage['token']}`,
								'Content-Type': 'application/json',
							},
							method: 'POST',
							url: `${APIHostname}/api/v1/partners`,
						})
							.then((response: any) => {
								sessionStorage[
									'redirect-message'
								] = `Partner #${response.data.partner_id} created.`
								window.history.replaceState(
									{},
									'',
									`/partners/${response.data.partner_id}`
								)
								document.location.reload()
							})
							.catch((error: any) => {
								switch (error?.response?.status) {
								case 401:
									LogOff('token-expired')
									return
								case 403:
									setErrorMessage(
										<>
												Error: You do not have
												permission to edit partners.
										</>
									)
									break
								default:
									if (error?.response?.data?.error) {
										setErrorMessage(
											error?.response?.data?.link ? (
												<Link
													to={
														error?.response
															?.data?.link
													}
												>
														Error:
													{
														error?.response
															?.data?.error
													}
												</Link>
											) : (
												<>
													{
														error?.response
															?.data?.error
													}
												</>
											)
										)
									} else {
										setErrorMessage(
											<>Error: Unknown error.</>
										)
											console.error(error) // eslint-disable-line
									}
								}
							})
					}}
					previewAPIData={async (formData: FormData) => {
						const payload = await ParsedFormData(formData, [])

						return {
							data: JSON.stringify(payload, null, 4),
							method: 'POST',
							url: `${APIHostname}/api/v1/partners`,
						}
					}}
					readOnly={!HasPermission('partner', 'create')}
				/>
			</div>
		</DefaultLayout>
	)
	/* eslint-enable @typescript-eslint/ban-types */
}

export const PartnersNewTabMenuItems = []

export const PartnersNewBottomTabItems = [
	{
		icon: DownArrowIcon,
		text: 'ADD A PARTNER',
		to: '/partners/new',
	},
]

export const PartnersNewLeftMenuItems = [
	{
		icon: PartnersIcon,
		requiredRole: 'administrator',
		text: 'PARTNERS',
		to: '/partners',
	},
]
