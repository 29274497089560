import { ReactElement, useState } from 'react'

import AddIcon from '../../../../assets/icons/add.svg'
import DeleteIcon from '../../../../assets/icons/delete.svg'
import { FieldProps } from '../../Field'
import { EditCountry } from '../Country'
import { EditOrganization } from '../Organization'
import styles from './ManufacturerLineItems.module.scss'

export const EditManufacturerLineItems = (props: FieldProps) => {
	const [manufacturerlineitemCount, setManufacturerLineItemCount] = useState(
		props.data?.manufacturer_line_items?.length
			? props.data?.manufacturer_line_items?.length
			: 0
	)

	const rows: ReactElement[] = []
	for (let i = 0; i < manufacturerlineitemCount; i++) {
		rows.push(
			<div
				className={styles.row}
				id={`${props.field.Property}_${i}`}
				key={`manufacturerLineItemNumber${i}`}
			>
				<input
					data-number={true}
					defaultValue={
						props.data?.manufacturer_line_items
							? props.data?.manufacturer_line_items[i]?.id
							: ''
					}
					id={`${props.field.Property}_${i}_id`}
					name={`${props.field.Property}_${i}_id`}
					type="hidden"
				/>
				<input
					data-number={true}
					defaultValue={
						props.data?.manufacturer_line_items
							? props.data?.manufacturer_line_items[i]?.shipment
							: ''
					}
					id={`${props.field.Property}_${i}_shipment`}
					name={`${props.field.Property}_${i}_shipment`}
					type="hidden"
				/>
				<input
					data-boolean={true}
					defaultValue={'true'}
					id={`${props.field.Property}_${i}_active`}
					name={`${props.field.Property}_${i}_active`}
					type="hidden"
				/>
				<div
					className={styles.row}
					id={`${props.field.Property}_${i}_contents`}
				>
					<div className={styles.deleteColumn}>
						<div>{i + 1}</div>
						<img
							className={styles.delete}
							onClick={() => {
								if (
									// eslint-disable-next-line no-alert
									confirm(
										'Really delete manufacturer line item?'
									)
								) {
									(
										document.getElementById(
											`${props.field.Property}_${i}_active`
										) as HTMLInputElement
									).value = 'false'
									;(
										document.getElementById(
											`${props.field.Property}_${i}`
										) as HTMLInputElement
									).style.display = 'none'
									;(
										document.getElementById(
											`${props.field.Property}_${i}_contents`
										) as HTMLInputElement
									).innerHTML = ''

									if (props.onChange) {
										props.onChange(null)
									}
								}
							}}
							src={DeleteIcon}
							title="DELETE ROW"
						/>{' '}
					</div>
					<div className={styles.inputColumn}>
						<div className={styles.row}>
							<div className={styles.oneTenth}>
								<div className={styles.label}>
									HARMONIZED TARIFF #:
								</div>
								<input
									defaultValue={
										props.data?.manufacturer_line_items
											? props.data
												?.manufacturer_line_items[i]
												?.harmonized_tariff_number
											: ''
									}
									id={`${props.field.Property}_${i}_harmonized_tariff_number`}
									name={`${props.field.Property}_${i}_harmonized_tariff_number`}
									onChange={props.onChange}
									readOnly={
										props.readOnly || props.field.ReadOnly
									}
									title={'HARMONIZED TARIFF #'}
									type="text"
								/>
							</div>
							{!props.isSearch ? (
								<>
									<div className={styles.oneTenth}>
										<div className={styles.label}>
											COUNTRY OF ORIGIN:
										</div>
										<EditCountry
											data-softrequired={true}
											field={{
												Label: 'COUNTRY OF ORIGIN',
												Property: `${props.field.Property}_${i}_manufacturer_country_of_origin`,
											}}
											isSearch={props.isSearch}
											onChange={props.onChange}
											readOnly={
												props.readOnly ||
												props.field.ReadOnly
											}
											value={
												props.data
													?.manufacturer_line_items
													? props.data
														?.manufacturer_line_items[
															i
													  ]?.manufacturer_country
													: ''
											}
										/>
									</div>
									<div className={styles.oneTenth}>
										<div className={styles.label}>
											DESCRIPTION:
										</div>
										<input
											defaultValue={
												props.data
													?.manufacturer_line_items
													? props.data
														?.manufacturer_line_items[
															i
													  ]?.description
													: ''
											}
											id={`${props.field.Property}_${i}_description`}
											name={`${props.field.Property}_${i}_description`}
											onChange={props.onChange}
											readOnly={
												props.readOnly ||
												props.field.ReadOnly
											}
											title={'DESCRIPTION'}
											type="text"
										/>
									</div>
									<div className={styles.oneTenth}>
										<EditOrganization
											data={
												props.data
													?.manufacturer_line_items
													? props.data
														?.manufacturer_line_items[
															i
													  ]
													: {}
											}
											data-softrequired={true}
											field={{
												Label: 'MANUFACTURER',
												Property: 'manufacturer',
												SubType: 'manufacturer',
											}}
											isSearch={props.isSearch}
											onChange={props.onChange}
											readOnly={
												(props.readOnly ||
													props.field.ReadOnly) &&
												!props.isSearch
											}
											value={
												props.data
													?.manufacturer_line_items
													? props.data
														?.manufacturer_line_items[
															i
													  ]?.manufacturer
													: ''
											}
										/>
									</div>
								</>
							) : (
								<></>
							)}
						</div>
					</div>
				</div>
			</div>
		)
	}

	if (manufacturerlineitemCount == 0) {
		rows.push(
			<div
				className={`${styles.row} ${styles.emptyRow}`}
				key="noManufacturerLineItems"
			>
				No manufacturer line items.
			</div>
		)
	}

	return (
		<div
			className={`${styles.fieldOuterContainer} ${
				!props.isSearch ? styles.editForm : ''
			}`}
			id={props.field.Property ? `${props.field.Property}_container` : ''}
			key="addManufacturerLineItem"
		>
			{rows}
			{!(props.isSearch || props.readOnly) ? (
				<div className={styles.buttonRow}>
					<button type="button">
						<img
							alt="ADD MANUFACTURER LINE ITEM"
							onClick={() => {
								setManufacturerLineItemCount(
									manufacturerlineitemCount + 1
								)
								if (props.onChange) {
									props.onChange(null)
									setTimeout(() => {
										if (props.onChange) {
											props.onChange(null)
										}
									}, 100)
									setTimeout(() => {
										if (props.onChange) {
											props.onChange(null)
										}
									}, 250)
									setTimeout(() => {
										if (props.onChange) {
											props.onChange(null)
										}
									}, 500)
									setTimeout(() => {
										if (props.onChange) {
											props.onChange(null)
										}
									}, 1000)
									setTimeout(() => {
										if (props.onChange) {
											props.onChange(null)
										}
									}, 2500)
								}
							}}
							src={AddIcon}
						/>
					</button>
				</div>
			) : (
				<></>
			)}
		</div>
	)
}
