import Control from '../Control'
import { Layout } from '../Layout'

export type Port = {
	code: string
	city_code: string
	country_code: string
}

export const EditPortLayout: Layout = {
	Groups: [
		{
			Border: true,
			Direction: 'column',
			Flex: '1 1',
			Margin: '1rem 0 0 0',
			OptionalRows: [
				{
					Fields: [
						{
							Control: Control.Country,
							HardRequired: true,
							Label: 'COUNTRY CODE',
							Property: 'country_code',
							Width: 33,
						},
						{
							HardRequired: true,
							Label: 'CITY CODE',
							MaxLength: 3,
							MinLength: 3,
							Property: 'city_code',
							Width: 33,
						},
						{
							Label: 'STATE',
							Property: 'state',
							Width: 33,
						},
					],
				},
				{
					Fields: [
						{
							HardRequired: true,
							Label: 'NAME',
							Property: 'name',
							Width: 100,
						},
					],
				},
				{
					Fields: [
						{
							Label: 'D CODE',
							MaxLength: 4,
							MinLength: 4,
							Property: 'dcode',
							SoftRequired: true,
							Width: 50,
						},
						{
							Label: 'K CODE',
							MaxLength: 5,
							MinLength: 5,
							Property: 'kcode',
							SoftRequired: true,
							Width: 50,
						},
					],
				},
				{
					Fields: [
						{
							Control: Control.Checkbox,
							Label: 'OCEAN PORT?',
							Property: 'is_ocean_port',
							Width: 50,
						},
						{
							Control: Control.Checkbox,
							Label: 'AIR PORT?',
							Property: 'is_air_port',
							Width: 50,
						},
					],
				},
				{
					Fields: [
						{
							Control: Control.Date,
							IncludeInSearch: false,
							Label: 'CREATION DATE',
							Property: 'created',
							ReadOnly: true,
							Width: 33,
						},
						{
							Control: Control.Date,
							IncludeInSearch: false,
							Label: 'MODIFIED DATE',
							Property: 'modified',
							ReadOnly: true,
							Width: 33,
						},
						{
							Control: Control.Number,
							IncludeInSearch: false,
							Label: 'VERSION',
							Property: 'version',
							ReadOnly: true,
							Width: 33,
						},
					],
				},
			],

			Width: 100,
		},
	],
}

export const NewPortLayout: Layout = {
	Groups: [
		{
			Border: true,
			Direction: 'column',
			Flex: '1 1',
			Margin: '1rem 0 0 0',
			OptionalRows: [
				{
					Fields: [
						{
							Control: Control.Country,
							HardRequired: true,
							Label: 'COUNTRY CODE',
							Property: 'country_code',
							Width: 33,
						},
						{
							HardRequired: true,
							Label: 'CITY CODE',
							MaxLength: 3,
							MinLength: 3,
							Property: 'city_code',
							Width: 33,
						},
						{
							Label: 'STATE',
							Property: 'state',
							Width: 33,
						},
					],
				},
				{
					Fields: [
						{
							HardRequired: true,
							Label: 'NAME',
							Property: 'name',
							Width: 100,
						},
					],
				},
				{
					Fields: [
						{
							Label: 'D CODE',
							MaxLength: 4,
							MinLength: 4,
							Property: 'dcode',
							SoftRequired: true,
							Width: 50,
						},
						{
							Label: 'K CODE',
							MaxLength: 5,
							MinLength: 5,
							Property: 'kcode',
							SoftRequired: true,
							Width: 50,
						},
					],
				},
				{
					Fields: [
						{
							Control: Control.Checkbox,
							Label: 'OCEAN PORT?',
							Property: 'is_ocean_port',
							Width: 50,
						},
						{
							Control: Control.Checkbox,
							Label: 'AIR PORT?',
							Property: 'is_air_port',
							Width: 50,
						},
					],
				},
			],
			Width: 100,
		},
	],
}

export const SearchPortLayout: Layout = {
	Groups: [
		{
			Border: true,
			Direction: 'column',
			Flex: '1 1',
			Margin: '1rem 0 0 0',
			OptionalRows: [
				{
					Fields: [
						{
							Control: Control.Country,
							HardRequired: true,
							Label: 'COUNTRY CODE',
							Property: 'country_code',
							Width: 33,
						},
						{
							HardRequired: true,
							Label: 'CITY CODE',
							MaxLength: 3,
							MinLength: 3,
							Property: 'city_code',
							Width: 33,
						},
						{
							Label: 'STATE',
							Property: 'state',
							Width: 33,
						},
					],
				},
				{
					Fields: [
						{
							HardRequired: true,
							Label: 'NAME',
							Property: 'name',
							Width: 100,
						},
					],
				},
				{
					Fields: [
						{
							Label: 'D CODE',
							MaxLength: 4,
							MinLength: 4,
							Property: 'dcode',
							SoftRequired: true,
							Width: 50,
						},
						{
							Label: 'K CODE',
							MaxLength: 5,
							MinLength: 5,
							Property: 'kcode',
							SoftRequired: true,
							Width: 50,
						},
					],
				},
				{
					Fields: [
						{
							Control: Control.Boolean,
							Label: 'OCEAN PORT?',
							Property: 'is_ocean_port',
							Width: 50,
						},
						{
							Control: Control.Boolean,
							Label: 'AIR PORT?',
							Property: 'is_air_port',
							Width: 50,
						},
					],
				},
			],
			RequiredRows: [
				{
					Fields: [
						{
							IncludeInEdit: false,
							IncludeInSearch: true,
							Label: 'WILDCARD (SEARCH ALL FIELDS; USE "OR" FOR MULTIPLE CRITERIA)',
							Property: '*',
							Width: 100,
						},
					],
				},
			],
			Width: 100,
		},
	],
}
