import { ReactElement, useState } from 'react'

import AddIcon from '../../../../assets/icons/add.svg'
import DeleteIcon from '../../../../assets/icons/delete.svg'
import { FieldProps } from '../../Field'
import styles from './HazardousCargo.module.scss'

export const EditHazardousCargo = (props: FieldProps) => {
	const [hazardousCargoCount, setHazardousCargoCount] = useState(
		props.data?.hazardous_cargoes?.length
			? props.data.hazardous_cargoes.length
			: 0
	)

	const rows: ReactElement[] = []
	for (let i = 0; i < hazardousCargoCount; i++) {
		rows.push(
			<div
				className={styles.row}
				id={`${props.field.Property}_${i}`}
				key={`hazardousCargo${i}`}
			>
				<input
					data-number={true}
					defaultValue={
						props.data?.hazardous_cargoes
							? props.data?.hazardous_cargoes[i]?.id
							: ''
					}
					id={`${props.field.Property}_${i}_id`}
					name={`${props.field.Property}_${i}_id`}
					readOnly={props.readOnly || props.field.ReadOnly}
					type="hidden"
				/>
				<input
					data-number={true}
					defaultValue={
						props.data?.hazardous_cargoes
							? props.data?.hazardous_cargoes[i]?.shipment
							: ''
					}
					id={`${props.field.Property}_${i}_shipment`}
					name={`${props.field.Property}_${i}_shipment`}
					readOnly={props.readOnly || props.field.ReadOnly}
					type="hidden"
				/>
				<input
					data-boolean={true}
					defaultValue={'true'}
					id={`${props.field.Property}_${i}_active`}
					name={`${props.field.Property}_${i}_active`}
					type="hidden"
				/>
				<div
					className={styles.row}
					id={`${props.field.Property}_${i}_contents`}
				>
					<div className={styles.deleteColumn}>
						<div>{i + 1}</div>
						<img
							className={styles.delete}
							onClick={() => {
								// eslint-disable-next-line no-alert
								if (confirm('Really delete hazardous cargo?')) {
									(
										document.getElementById(
											`${props.field.Property}_${i}_active`
										) as HTMLInputElement
									).value = 'false'
									;(
										document.getElementById(
											`${props.field.Property}_${i}`
										) as HTMLInputElement
									).style.display = 'none'
									;(
										document.getElementById(
											`${props.field.Property}_${i}_contents`
										) as HTMLInputElement
									).innerHTML = ''

									if (props.onChange) {
										props.onChange(null)
									}
								}
							}}
							src={DeleteIcon}
							title="DELETE ROW"
						/>{' '}
					</div>
					<div className={styles.inputColumn}>
						<div className={styles.row}>
							<div className={styles.oneTenth}>
								<div className={styles.label}>
									PACKING GROUP:{' '}
									<span
										className="softRequired"
										title="REQUIRED"
									>
										*
									</span>
								</div>
								<input
									className={
										!props.isSearch ? 'softRequired' : ''
									}
									data-softrequired={true}
									defaultValue={
										props.data?.hazardous_cargoes
											? props.data?.hazardous_cargoes[i]
												?.packing_group
											: ''
									}
									id={`${props.field.Property}_${i}_packing_group`}
									name={`${props.field.Property}_${i}_packing_group`}
									onChange={props.onChange}
									placeholder=" "
									readOnly={
										props.readOnly || props.field.ReadOnly
									}
									title={'PACKING GROUP'}
									type="text"
								/>
							</div>
							{!props.isSearch ? (
								<>
									<div className={styles.oneFifth}>
										<div className={styles.label}>
											UN NUMBER:{' '}
											<span
												className="softRequired"
												title="REQUIRED"
											>
												*
											</span>
										</div>
										<input
											className={
												!props.isSearch
													? 'softRequired'
													: ''
											}
											data-softrequired={true}
											defaultValue={
												props.data?.hazardous_cargoes
													? props.data
														?.hazardous_cargoes[
															i
													  ]?.un_number
													: ''
											}
											id={`${props.field.Property}_${i}_un_number`}
											name={`${props.field.Property}_${i}_un_number`}
											onChange={props.onChange}
											placeholder=" "
											readOnly={
												props.readOnly ||
												props.field.ReadOnly
											}
											title={'UN NUMBER'}
											type="text"
										/>
									</div>

									<div className={styles.oneTenth}>
										<div className={styles.label}>
											CLASS:{' '}
											<span
												className="softRequired"
												title="REQUIRED"
											>
												*
											</span>
										</div>
										<input
											className={
												!props.isSearch
													? 'softRequired'
													: ''
											}
											data-softrequired={true}
											defaultValue={
												props.data?.hazardous_cargoes
													? props.data
														?.hazardous_cargoes[
															i
													  ]?.class
													: ''
											}
											id={`${props.field.Property}_${i}_class`}
											name={`${props.field.Property}_${i}_class`}
											onChange={props.onChange}
											placeholder=" "
											readOnly={
												props.readOnly ||
												props.field.ReadOnly
											}
											title="CLASS"
											type="text"
										/>
									</div>

									<div className={styles.oneFifth}>
										<div className={styles.label}>
											PAGE NUMBER:{' '}
											<span
												className="softRequired"
												title="REQUIRED"
											>
												*
											</span>
										</div>
										<input
											className={
												!props.isSearch
													? 'softRequired'
													: ''
											}
											data-number="true"
											data-softrequired={true}
											defaultValue={
												props.data?.hazardous_cargoes
													? props.data
														?.hazardous_cargoes[
															i
													  ]?.page_number
													: ''
											}
											id={`${props.field.Property}_${i}_page_number`}
											name={`${props.field.Property}_${i}_page_number`}
											onChange={props.onChange}
											placeholder=" "
											readOnly={
												props.readOnly ||
												props.field.ReadOnly
											}
											title="PAGE NUMBER"
											type="number"
										/>
									</div>

									<div className={styles.oneTenth}>
										<div className={styles.label}>
											DESCRIPTION LABEL:{' '}
											<span
												className="softRequired"
												title="REQUIRED"
											>
												*
											</span>
										</div>
										<textarea
											className={
												!props.isSearch
													? 'softRequired'
													: ''
											}
											data-softrequired={true}
											defaultValue={
												props.data?.hazardous_cargoes
													? props.data
														?.hazardous_cargoes[
															i
													  ]?.description_label
													: ''
											}
											id={`${props.field.Property}_${i}_description_label`}
											name={`${props.field.Property}_${i}_description_label`}
											onChange={props.onChange}
											placeholder=" "
											readOnly={
												props.readOnly ||
												props.field.ReadOnly
											}
											title="DESCRIPTION LABEL"
										></textarea>
									</div>

									<div className={styles.oneTenth}>
										<div className={styles.label}>
											FLASHPOINT TEMP:{' '}
											<span
												className="softRequired"
												title="REQUIRED"
											>
												*
											</span>
										</div>
										<input
											className={
												!props.isSearch
													? 'softRequired'
													: ''
											}
											data-number="true"
											data-softrequired={true}
											defaultValue={
												props.data?.hazardous_cargoes
													? props.data
														?.hazardous_cargoes[
															i
													  ]?.flashpoint_temp
													: ''
											}
											id={`${props.field.Property}_${i}_flashpoint_temp`}
											name={`${props.field.Property}_${i}_flashpoint_temp`}
											onChange={props.onChange}
											placeholder=" "
											readOnly={
												props.readOnly ||
												props.field.ReadOnly
											}
											title="FLASHPOINT TEMP"
											type="number"
										/>
									</div>

									<div className={styles.oneTenth}>
										<div className={styles.label}>
											FLASHPOINT UNIT:{' '}
											<span
												className="softRequired"
												title="REQUIRED"
											>
												*
											</span>
										</div>
										{props.readOnly ||
										props.field.ReadOnly ? (
												<input
													readOnly={true}
													type="text"
													value={
														props.data
															?.hazardous_cargoes
															? props.data?.hazardous_cargoes[
																i
														  ]?.flashpoint_unit?.toUpperCase()
															: ''
													}
												/>
											) : (
												<select
													className={
														!props.isSearch
															? 'softRequired'
															: ''
													}
													data-nullable={
														props.field.Nullable
													}
													data-softrequired={true}
													defaultValue={
														props.data
															?.hazardous_cargoes
															? props.data
																?.hazardous_cargoes[
																	i
														  ]?.flashpoint_unit
															: ''
													}
													id={`${props.field.Property}_${i}_flashpoint_unit`}
													name={`${props.field.Property}_${i}_flashpoint_unit`}
													onChange={props.onChange}
													title="FLASHPOINT UNIT"
												>
													<option value=""></option>
													<option value="c">C</option>
													<option value="f">F</option>
												</select>
											)}
									</div>

									<div className={styles.oneTenth}>
										<div className={styles.label}>
											MAIN CONTACT:{' '}
											<span
												className="softRequired"
												title="REQUIRED"
											>
												*
											</span>
										</div>
										<input
											className={
												!props.isSearch
													? 'softRequired'
													: ''
											}
											data-softrequired={true}
											defaultValue={
												props.data?.hazardous_cargoes
													? props.data
														?.hazardous_cargoes[
															i
													  ]?.main_contact
													: ''
											}
											id={`${props.field.Property}_${i}_main_contact`}
											name={`${props.field.Property}_${i}_main_contact`}
											onChange={props.onChange}
											placeholder=" "
											readOnly={
												props.readOnly ||
												props.field.ReadOnly
											}
											title="MAIN CONTACT"
											type="text"
										/>
									</div>
								</>
							) : (
								<></>
							)}
						</div>
					</div>
				</div>
			</div>
		)
	}
	if (hazardousCargoCount == 0) {
		rows.push(
			<div
				className={`${styles.row} ${styles.emptyRow}`}
				key="noHazardousCargoes"
			>
				No hazardous cargoes.
			</div>
		)
	}

	return (
		<div
			className={`${styles.fieldOuterContainer} ${
				!props.isSearch ? styles.editForm : ''
			}`}
			id={props.field.Property ? `${props.field.Property}_container` : ''}
			key="addHazardousCargo"
		>
			{rows}
			{!(props.isSearch || props.readOnly) ? (
				<div className={styles.buttonRow}>
					<button type="button">
						<img
							alt="ADD HAZARDOUS CARGO"
							onClick={() => {
								setHazardousCargoCount(hazardousCargoCount + 1)
								if (props.onChange) {
									props.onChange(null)
									setTimeout(() => {
										if (props.onChange) {
											props.onChange(null)
										}
									}, 100)
									setTimeout(() => {
										if (props.onChange) {
											props.onChange(null)
										}
									}, 250)
									setTimeout(() => {
										if (props.onChange) {
											props.onChange(null)
										}
									}, 500)
									setTimeout(() => {
										if (props.onChange) {
											props.onChange(null)
										}
									}, 1000)
									setTimeout(() => {
										if (props.onChange) {
											props.onChange(null)
										}
									}, 2500)
								}
							}}
							src={AddIcon}
						/>
					</button>
				</div>
			) : (
				<></>
			)}
		</div>
	)
}
