import axios from 'axios'
import { useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'

import AgentAccessIcon from '../../../../../assets/icons/agent-access.svg'
import DownArrowIcon from '../../../../../assets/icons/arrow-down.svg'
import DocumentsIcon from '../../../../../assets/icons/documents.svg'
import NewIcon from '../../../../../assets/icons/new.svg'
import OrganizationHistoryIcon from '../../../../../assets/icons/organization-history.svg'
import OrganizationInfoIcon from '../../../../../assets/icons/organization-info.svg'
import OrganizationUsersIcon from '../../../../../assets/icons/organization-users.svg'
import ShipmentsIcon from '../../../../../assets/icons/shipments.svg'
import { SearchForm } from '../../../../../components/Form/SearchForm'
import { NavTable } from '../../../../../components/NavTable'
import { OrganizationSummary } from '../../../../../components/OrganizationSummary'
import { GlobalTopTabItems } from '../../../../../components/Tabs'
import { DefaultLayout } from '../../../../../layouts/DefaultLayout'
import {
	APIHostname,
	HasPermission,
	LogOff,
	ProfileRole,
} from '../../../../../Profile'
import Control from '../../../../../types/Control'
import { SearchUserLayout } from '../../../../../types/data/User'
import { EncodedStringify, Filter } from '../../../../../types/Filter'
import { EscapeURIParameters } from '../../../../../Utils'
import styles from './OrganizationUsers.module.scss'

export const OrganizationUsers = () => {
	const { id } = useParams()

	const [users, setUsers]: any = useState(null)
	const [organization, setOrganization]: any = useState(null)
	const [organizationLoaded, setOrganizationLoaded] = useState(false)
	const [userCount, setUserCount] = useState(0)
	const [usersLoaded, setLoaded]: any = useState(false)
	const [errorMessage, setErrorMessage] = useState(<></>)
	const initialRedirectMessage = sessionStorage['redirect-message']
	const [redirectMessage] = useState(initialRedirectMessage)

	// Vary based on query string.
	const location = useLocation()
	const urlParams = new URLSearchParams(location.search)
	if (!urlParams.get('limit')) {
		urlParams.set('limit', '25')
	}

	const filters = JSON.parse(urlParams.get('filters') ?? '[]')
	useEffect(() => {
		let filterFound = false
		filters.forEach((filter: Filter, index: number) => {
			if (filter.field?.toLowerCase() == 'organization') {
				filters[index].operator = 'eq'
				filters[index].value = id?.toString()
				filterFound = true
			}
		})
		if (!filterFound) {
			filters.push({
				field: 'organization',
				operator: 'eq',
				value: id?.toString(),
			})
		}
		urlParams.set('filters', EncodedStringify(filters))

		axios({
			data: {},
			headers: {
				Authorization: `Bearer ${localStorage['token']}`,
			},
			method: 'GET',
			url: `${APIHostname}/api/v1/organizations/${id}`,
		})
			.then((response: any) => {
				setErrorMessage(<></>)
				setOrganizationLoaded(true)
				setOrganization(response.data)
			})
			.catch((error: any) => {
				switch (error?.response?.status) {
				case 401:
					LogOff('token-expired')
					return
				case 403:
					setErrorMessage(
						<>
								Error: You do not have permission to view
								partners.
						</>
					)
					break
				default:
					if (error?.response?.data?.error) {
						setErrorMessage(
							error?.response?.data?.link ? (
								<Link to={error?.response?.data?.link}>
										Error:
									{error?.response?.data?.error}
								</Link>
							) : (
								<>{error?.response?.data?.error}</>
							)
						)
					} else {
						setErrorMessage(<>Error: Unknown error.</>)
							console.error(error) // eslint-disable-line
					}
				}
			})

		axios({
			data: {},
			headers: {
				Authorization: `Bearer ${localStorage['token']}`,
			},
			method: 'GET',
			url: `${APIHostname}/api/v1/users?${EscapeURIParameters(
				urlParams.toString()
			)}`,
		})
			.then((response: any) => {
				setErrorMessage(<></>)
				setUsers(response.data.users)
				setUserCount(response.data.user_count)
				setLoaded(true)
			})
			.catch((error: any) => {
				switch (error?.response?.status) {
				case 401:
					LogOff('token-expired')
					return
				case 403:
					setErrorMessage(
						<>
								Error: You do not have permission to view users.
						</>
					)
					break
				default:
					if (error?.response?.data?.error) {
						setErrorMessage(
							error?.response?.data?.link ? (
								<Link to={error?.response?.data?.link}>
										Error:
									{error?.response?.data?.error}
								</Link>
							) : (
								<>{error?.response?.data?.error}</>
							)
						)
					} else {
						setErrorMessage(<>Error: Unknown error.</>)
							console.error(error) // eslint-disable-line
					}
				}
			})
	}, [location])

	const OrganizationLeftMenuItems = [
		{
			icon: OrganizationInfoIcon,
			requiredPermissions: ['organization.read'],
			text: 'ORGANIZATION INFO',
			to: `/organizations/${id}`,
		},
		{
			icon: DocumentsIcon,
			requiredPermissions: ['shipment.read'],
			text: 'ORGANIZATION DOCUMENTS',
			to: `/organizations/${id}/documents`,
		},
		{
			icon: ShipmentsIcon,
			requiredPermissions: ['shipment.read'],
			text: 'LINKED SHIPMENTS',
			to: `/organizations/${id}/shipments`,
		},
		{
			icon: OrganizationUsersIcon,
			requiredPermissions: ['user.read'],
			text: 'ORGANIZATION USERS',
			to: `/organizations/${id}/users`,
		},
		{
			icon: AgentAccessIcon,
			requiredPermissions: ['organization.manageaccess'],
			text: 'PARTNER ACCESS',
			to: `/organizations/${id}/partner_access`,
		},
		{
			icon: OrganizationHistoryIcon,
			requiredPermissions: ['organization.read'],
			text: 'ORGANIZATION HISTORY',
			to: `/organizations/${id}/history`,
		},
	]

	const OrganizationUsersBottomTabItems = [
		{
			icon: DownArrowIcon,
			text: `ORGANIZATION #${id} USERS`,
			to: `/organizations/${id}/users`,
		},
	]

	const OrganizationUsersTabMenuItems = []

	if (
		(ProfileRole() == 'administrator' ||
			ProfileRole() == 'memberpartner') &&
		organization?.active
	) {
		OrganizationUsersTabMenuItems.push({
			icon: NewIcon,
			requiredPermissions: ['user.create'],
			text: 'NEW',
			to: `/organizations/${id}/users/new`,
		})
	}

	if (!usersLoaded || !organizationLoaded) {
		if (errorMessage) {
			return (
				<DefaultLayout
					bottomTabItems={OrganizationUsersBottomTabItems}
					leftMenuItems={OrganizationLeftMenuItems}
					showPrint={true}
					tabMenuItems={OrganizationUsersTabMenuItems}
					topTabItems={GlobalTopTabItems}
				>
					<div className={styles.organizationContainer}>
						<div className={styles.redirectMessage}>
							{redirectMessage}
						</div>
						<div className={styles.errorMessage}>
							{errorMessage}
						</div>
					</div>
				</DefaultLayout>
			)
		}

		return <div className="loading">Loading...</div>
	}

	sessionStorage.removeItem('redirect-message')
	return (
		<DefaultLayout
			bottomTabItems={OrganizationUsersBottomTabItems}
			leftMenuItems={OrganizationLeftMenuItems}
			showPrint={true}
			tabMenuItems={OrganizationUsersTabMenuItems}
			topTabItems={GlobalTopTabItems}
		>
			<div className={styles.organizationUsersContainer}>
				<OrganizationSummary organization={organization} />

				<div className={styles.redirectMessage}>{redirectMessage}</div>
				<div className={styles.errorMessage}>
					{errorMessage}{' '}
					{!organization?.active ? (
						<>This organization has been deleted.</>
					) : (
						<></>
					)}
				</div>

				<div className={styles.searchForm}>
					<SearchForm
						data={{}}
						layout={SearchUserLayout}
						name="users"
						readOnly={false}
					/>
				</div>

				<div className={styles.navTable}>
					<NavTable
						columns={[
							{
								children: [
									{
										control: Control.ImageFile,
										id: 'image',
										title: '',
									},
								],
							},
							{
								children: [
									{
										id: 'name',
										title: 'Account Name',
									},
									{
										id: 'title',
										title: 'Title',
									},
								],
							},
							{
								children: [
									{
										control: Control.Role,
										id: 'role',
										title: 'Role',
									},
									{
										control: Control.Organization,
										id: 'organization',
										title: 'Organization',
									},
								],
							},
							{
								children: [
									{
										id: 'city',
										title: 'City',
									},
									{
										id: 'state',
										title: 'State',
									},
									{
										control: Control.Country,
										id: 'country',
										title: 'Country',
									},
								],
							},
							{
								children: [
									{
										control: Control.Email,
										id: 'email',
										title: 'Email',
									},
									{
										control: Control.Phone,
										id: 'phone',
										title: 'Phone',
									},
								],
							},
							{
								id: 'notes',
								title: 'Notes',
							},
							{
								control: Control.Boolean,
								id: 'approved',
								title: 'Approved',
							},
							{
								children: [
									{
										control: Control.DateTime,
										id: 'created',
										title: 'Creation Date',
									},
									{
										control: Control.User,
										id: 'created_by',
										title: 'Created By',
									},
								],
							},
							{
								children: [
									{
										control: Control.DateTime,
										id: 'modified',
										title: 'Modified Date',
									},
									{
										control: Control.User,
										id: 'modified_by',
										title: 'Modified By',
									},
								],
							},
						]}
						count={userCount}
						data={users}
						filters={filters}
						includeDelete={HasPermission('user', 'delete')}
						linkPathRoot="/users"
						primaryDisplayColumn="name"
						primaryKey="id"
					/>
				</div>
			</div>
		</DefaultLayout>
	)
}
