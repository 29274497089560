import { HasRole } from '../../Profile'
import Control from '../Control'
import { Layout } from '../Layout'

export type Organization = {
	id: string
	code: string
	has_account_owner_logo: boolean
	account_owner: string
	account_owner_name: string
	logo_image_preview: string
	address: string
	city: string
	country: string
	name: string
	postal_code: string
	state: string
	timezone: string
	type: string
	created: Date
	modified: Date
}

export const OrganizationEditLayout: Layout = {
	Groups: [
		{
			Border: true,
			Direction: 'column',
			Flex: '1 1',
			Margin: '1rem 0 0 0',
			OptionalRows: [
				{
					Fields: [
						{
							HardRequired: true,
							Label: 'CODE',
							MaxLength: 8,
							MinLength: 8,
							Property: 'code',
							Width: 33,
						},
						{
							HardRequired: true,
							Label: 'DISPLAY NAME',
							Property: 'name',
							Width: 33,
						},
						{
							HardRequired: true,
							Label: 'LEGAL NAME',
							Property: 'legal_name',
							Width: 33,
						},
					],
				},
				{
					Fields: [
						{
							Control: Control.OrganizationType,
							HardRequired: true,
							Label: 'TYPE',
							Property: 'type',
							Width: 100,
						},
					],
				},
				{
					Fields: [
						{
							Control: Control.TextBox,
							Label: 'ADDRESS',
							Property: 'address',
							RowSpan: 2,
							SoftRequired: true,
							Width: 100,
						},
					],
				},
				{
					Fields: [
						{
							Label: 'CITY',
							Property: 'city',
							SoftRequired: true,
							Width: 25,
						},
						{
							Label: 'STATE',
							Property: 'state',
							Width: 25,
						},
						{
							Label: 'POSTAL CODE',
							Property: 'postal_code',
							SoftRequired: true,
							Width: 25,
						},
						{
							Control: Control.Country,
							HardRequired: true,
							Label: 'COUNTRY',
							Property: 'country',
							Width: 25,
						},
					],
				},
				{
					Fields: [
						{
							Control: Control.ImageFile,
							Label: 'ACCOUNT LOGO',
							Property: 'logo',
							Width: 100,
						},
					],
					IncludeInSearch: false,
				},
			],
			Width: 50,
		},
		{
			Border: true,
			Direction: 'column',
			Flex: '1 1',
			Margin: '1rem 0 0 0',
			OptionalRows: [
				{
					Fields: [
						{
							Control: Control.Partner,
							Label: 'ACCOUNT OWNER',
							Property: 'account_owner',
							ReadOnly: !HasRole('administrator'),
							SoftRequired: true,
							Width: 100,
						},
					],
				},
				{
					Fields: [
						{
							Label: 'MAIN CONTACT',
							Property: 'main_contact_name',
							SoftRequired: true,
							Width: 33,
						},
						{
							Control: Control.Email,
							Label: 'MAIN CONTACT EMAIL',
							Property: 'main_contact_email',
							SoftRequired: true,
							Width: 33,
						},
						{
							Control: Control.Phone,
							Label: 'MAIN CONTACT PHONE',
							Property: 'main_contact_phone',
							SoftRequired: true,
							Width: 33,
						},
					],
				},
				{
					Fields: [
						{
							Control: Control.TextBox,
							Label: 'ADDITIONAL EMAILS',
							Property: 'additional_emails',
							Width: 100,
						},
					],
				},
				{
					Fields: [
						{
							Control: Control.TimeZone,
							Label: 'TIME ZONE',
							Property: 'timezone',
							Width: 50,
						},
					],
				},
				{
					Fields: [
						{
							Control: Control.TextBox,
							Label: 'NOTES',
							Property: 'notes',
							Width: 100,
						},
					],
				},
				{
					Fields: [
						{
							Control: Control.Active,
							Label: 'INCLUDE',
							Property: 'active',
							Width: 100,
						},
					],
					IncludeInEdit: false,
					RequiredRole: 'administrator',
				},
				{
					Fields: [
						{
							Control: Control.Date,
							Label: 'CREATION DATE',
							Property: 'created',
							ReadOnly: true,
							Width: 33,
						},
						{
							Control: Control.Date,
							Label: 'MODIFIED DATE',
							Property: 'modified',
							ReadOnly: true,
							Width: 33,
						},
						{
							Control: Control.Number,
							Label: 'VERSION',
							Property: 'version',
							ReadOnly: true,
							Width: 33,
						},
					],
				},
			],
			Width: 50,
		},
	],
}

export const OrganizationSearchLayout: Layout = {
	Groups: [
		{
			Border: true,
			Direction: 'column',
			Margin: '1rem 0 0 0',
			OptionalRows: [
				{
					Fields: [
						{
							Label: 'CODE',
							MaxLength: 8,
							MinLength: 8,
							Property: 'code',
							Width: 50,
						},
						{
							Label: 'NAME',
							Property: 'name',
							Width: 50,
						},
					],
				},
				{
					Fields: [
						{
							Control: Control.TextBox,
							Label: 'ADDRESS',
							Property: 'address',
							RowSpan: 2,
							Width: 50,
						},
						{
							Label: 'MAIN CONTACT',
							Property: 'main_contact_name',
							Width: 50,
						},
					],
				},
				{
					Fields: [
						{
							Label: 'MAIN CONTACT EMAIL',
							Property: 'main_contact_email',
							Width: 50,
						},
					],
				},
				{
					Fields: [
						{
							Label: 'CITY',
							Property: 'city',
							Width: 25,
						},
						{
							Label: 'STATE',
							Property: 'state',
							Width: 25,
						},
						{
							Label: 'COUNTRY',
							Property: 'country',
							Width: 25,
						},
						{
							Label: 'MAIN CONTACT PHONE',
							Property: 'main_contact_phone',
							Width: 50,
						},
					],
				},
				{
					Fields: [
						{
							Label: 'ZIP',
							Property: 'postal_code',
							Width: 25,
						},
						{
							Control: Control.Country,
							Label: 'COUNTRY',
							Property: 'country',
							Width: 25,
						},
						{
							Control: Control.Date,
							Label: 'CREATION DATE',
							Property: 'created',
							ReadOnly: true,
							Width: 25,
						},
						{
							Control: Control.Date,
							Label: 'MODIFIED DATE',
							Property: 'modified',
							ReadOnly: true,
							Width: 25,
						},
					],
				},
				{
					Fields: [
						{
							Control: Control.OrganizationType,
							Label: 'TYPE',
							Property: 'type',
							Width: 50,
						},
						{
							Control: Control.TimeZone,
							Label: 'TIME ZONE',
							Property: 'timezone',
							Width: 50,
						},
					],
				},
				{
					Fields: [
						{
							Label: 'NOTES',
							Property: 'notes',
							Width: 100,
						},
					],
				},
				{
					Fields: [
						{
							Control: Control.Active,
							Label: 'INCLUDE',
							Property: 'active',
							Width: 100,
						},
					],
					IncludeInEdit: false,
					RequiredRole: 'administrator',
				},
			],
			RequiredRows: [
				{
					Fields: [
						{
							IncludeInEdit: false,
							IncludeInSearch: true,
							Label: 'WILDCARD (SEARCH ALL FIELDS; USE "OR" FOR MULTIPLE CRITERIA)',
							Property: '*',
							Width: 100,
						},
					],
				},
			],
			Width: 100,
		},
	],
}
