import '@szhsin/react-menu/dist/index.css'
import '@szhsin/react-menu/dist/transitions/slide.css'

import { Menu, MenuButton, MenuItem } from '@szhsin/react-menu'
import axios from 'axios'
import { Link } from 'react-router-dom'

import ExpandDownIcon from '../../../assets/icons/expand-down.svg'
import LoginIcon from '../../../assets/icons/login.svg'
import {
	APIHostname,
	Authenticated,
	ProfileImagePreview,
	ProfileName,
	ProfileOrganizationID,
	ProfileOrganizationName,
	ProfilePartnerID,
	ProfilePartnerName,
	ProfileRole,
	ProfileUserID,
} from '../../../Profile'
import styles from './ProfileMenu.module.scss'

export const ProfileMenu = () => {
	let orgImage = <></>
	let role = <></>
	switch (ProfileRole()) {
	case 'administrator':
		orgImage = (
			<img
				className={styles.orgIcon}
				onError={(event: any) => {
					event.target.style.display = 'none'
				}}
				src={'/goal-logo.svg'}
			/>
		)
		role = <span className={styles.administrator}>ADMINISTRATOR</span>
		break
	case 'organization':
		orgImage = (
			<img
				className={styles.orgIcon}
				onError={(event: any) => {
					event.target.style.display = 'none'
				}}
				src={`${APIHostname}/api/v1/organizations/${ProfileOrganizationID()}/logo`}
			/>
		)
		role = (
			<span className={styles.organization}>
				{ProfileOrganizationName()}
			</span>
		)
		break
	case 'memberpartner':
	case 'nonmemberpartner':
	case 'partner':
		orgImage = (
			<img
				className={styles.orgIcon}
				onError={(event: any) => {
					event.target.style.display = 'none'
				}}
				src={`${APIHostname}/api/v1/partners/${ProfilePartnerID()}/logo`}
			/>
		)
		role = (
			<span className={styles.partner}>{ProfilePartnerName()}</span>
		)
		break
	}

	if (Authenticated()) {
		return (
			<div className={styles.profileMenu}>
				<Menu
					menuButton={
						<MenuButton>
							{orgImage}
							<img
								alt="LOGIN"
								className={styles.profileIcon}
								onError={(event: any) => {
									event.target.src = LoginIcon
								}}
								src={
									ProfileImagePreview()
										? ProfileImagePreview()
										: LoginIcon
								}
							/>{' '}
							<div className={styles.name}>
								{ProfileName()}
								<br />
								{role}
							</div>
							<img
								alt="EXPAND"
								className={styles.expandIcon}
								src={ExpandDownIcon}
							/>
						</MenuButton>
					}
					transition
				>
					<MenuItem href={`/users/${ProfileUserID()}`}>
						MY PROFILE
					</MenuItem>
					{ProfileOrganizationID() > 0 ? (
						<MenuItem
							href={`/organizations/${ProfileOrganizationID()}`}
						>
							MY ORGANIZATION INFORMATION
						</MenuItem>
					) : (
						<></>
					)}
					{ProfilePartnerID() > 0 ? (
						<MenuItem href={`/partners/${ProfilePartnerID()}`}>
							MY PARTNER INFORMATION
						</MenuItem>
					) : (
						<></>
					)}
					{ProfileRole() == 'administrator' ? (
						<MenuItem href="/administration">
							SYSTEM ADMINISTRATION
						</MenuItem>
					) : (
						<></>
					)}
					<MenuItem>-</MenuItem>
					<MenuItem
						href="#"
						onClick={() => {
							axios({
								method: 'POST',
								url: `${APIHostname}/api/v1/logout`,
							})
							localStorage.clear()

							const cookies = document.cookie.split(';')
							for (let i = 0; i < cookies.length; i++) {
								const cookie = cookies[i]
								const eqPos = cookie.indexOf('=')
								const name =
									eqPos > -1
										? cookie.substr(0, eqPos)
										: cookie
								document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`
							}

							window.history.replaceState({}, '', '/')
							document.location.reload()
						}}
					>
						LOG OUT
					</MenuItem>
				</Menu>
			</div>
		)
	} else {
		return (
			<div className={styles.profileMenu}>
				<Link to="/">
					<img
						alt="LOGIN"
						className={styles.profileIcon}
						src={LoginIcon}
					/>{' '}
					ACCOUNT LOGIN
				</Link>
			</div>
		)
	}
}
