import { Link } from 'react-router-dom'

import styles from './Logo.module.scss'

export const Logo = () => {
	return (
		<div className={styles.logoContainer}>
			<Link to="/">
				<img alt="Logo" id="themeLogo" />
			</Link>
		</div>
	)
}
