import axios from 'axios'
import { ChangeEvent, ReactElement, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDebouncedCallback } from 'use-debounce'

import PartnerIcon from '../../../../assets/icons/partners.svg'
import { APIHostname, LogOff } from '../../../../Profile'
import { EncodedStringify } from '../../../../types/Filter'
import { RandomID } from '../../../../Utils'
import { FieldProps } from '../../Field'
import styles from './Partner.module.scss'

export const EditPartner = (props: FieldProps) => {
	const [previews, setPreviews] = useState(<></>)
	const [labels, setLabels] = useState(
		props.value?.toString().length > 0 ? (
			<>
				{props.value
					.toString()
					.split(',')
					.map((name: string) => (
						<li key={name}>ORGANIZATION #{name}</li>
					))}
			</>
		) : (
			<></>
		)
	)
	const [value, setValue] = useState(props.value ?? '0')
	const [searchAheadID] = useState(RandomID(16))

	const search = (target: HTMLInputElement) => {
		if (target.value) {
			const filters = [
				{
					field: 'name,code,legal_name',
					operator: 'contains',
					value: target.value,
				},
			]
			switch (props.field.SubType) {
			case 'memberpartner':
				filters.push({
					field: 'goal_member',
					operator: 'equals',
					value: 'true',
				})
				break
			case 'nonmemberpartner':
				filters.push({
					field: 'goal_member',
					operator: 'equals',
					value: 'false',
				})
				break
			}
			axios({
				data: {},
				headers: {
					Authorization: `Bearer ${localStorage['token']}`,
				},
				method: 'GET',
				url: `${APIHostname}/api/v1/partners?filters=${EncodedStringify(
					filters
				)}&sorts=[{"field":"name","order":"ASC"}]&limit=250`,
			})
				.then((response: any) => {
					const idMap: { [id: string]: boolean } = {}
					if (props.field.Multiple) {
						value
							?.toString()
							.split(',')
							.forEach((id: string) => {
								idMap[id] = true
							})
					}

					if (response.data.partner_count != null) {
						if (response.data.partners) {
							const rows: ReactElement[] = []
							response.data.partners.forEach((p: any) => {
								rows.push(
									<tr
										key={`preview${p.id}`}
										onMouseDown={(event: any) => {
											if (props.field.Multiple) {
												document
													.getElementById(
														`${props.field.Property}_name`
													)
													?.focus()
												const checkbox =
													document.getElementById(
														`multiple_partner_${p.id}`
													) as HTMLInputElement
												checkbox.checked =
													!checkbox.checked

												const checkboxes =
													document.querySelectorAll(
														'input[type=\'checkbox\'][id^=\'multiple\']:checked'
													)
												const ids: string[] = []
												const names: string[] = []
												checkboxes.forEach(
													(element: any) => {
														const idParts =
															element.id.split(
																'_'
															)
														ids.push(
															idParts[
																idParts.length -
																	1
															]
														)
														names.push(
															(
																document.getElementById(
																	`${element.id}_name`
																) as HTMLInputElement
															).value
														)
													}
												)
												setValue(ids.join(','))
												if (ids.length > 0) {
													setLabels(
														<>
															{names.map(
																(
																	name: string
																) => (
																	<li
																		key={
																			name
																		}
																	>
																		{name}
																	</li>
																)
															)}
														</>
													)
												} else {
													setLabels(<></>)
												}

												event.preventDefault()
												return false
											} else {
												setValue(p.id)
												;(
													document.getElementById(
														`${props.field.Property}_link`
													) as HTMLAnchorElement
												).href = `/partners/${p.id}`
												if (p.has_logo) {
													(
														document.getElementById(
															`${props.field.Property}_logo`
														) as HTMLImageElement
													).src = p.image_preview
												} else {
													(
														document.getElementById(
															`${props.field.Property}_logo`
														) as HTMLImageElement
													).src = PartnerIcon
												}
												(
													document.getElementById(
														`${props.field.Property}_name`
													) as HTMLInputElement
												).value = p.name
												;(
													document.getElementById(
														`${props.field.Property}_name`
													) as HTMLInputElement
												).dataset.value = p.name
											}
											setPreviews(<></>)

											if (props.onChange) {
												props.onChange(event)
											}
										}}
									>
										{props.field.Multiple ? (
											<td>
												<input
													className={styles.checkbox}
													defaultChecked={idMap[p.id]}
													id={`multiple_partner_${p.id}`}
													onChange={(event: any) => {
														event.stopPropagation()
													}}
													type="checkbox"
													value={p.id}
												/>
												<input
													id={`multiple_partner_${p.id}_name`}
													type="hidden"
													value={p.name}
												/>
											</td>
										) : (
											<></>
										)}

										<td>
											<img
												onError={(event: any) => {
													if (
														event.target.src ==
														PartnerIcon
													) {
														event.target.style.display =
															'none'
													} else {
														event.target.src =
															PartnerIcon
													}
												}}
												onLoad={(event: any) => {
													event.target.style.display =
														''
												}}
												src={
													p.image_preview
														? p.image_preview
														: PartnerIcon
												}
											/>
										</td>
										<td>{p.id}</td>
										<td>{p.code}</td>
										<td>{p.name}</td>
									</tr>
								)
							})
							const searchAhead =
								document.getElementById(searchAheadID)
							if (searchAhead) {
								searchAhead.style.left = `${target.offsetLeft}px`
								searchAhead.style.top = `${
									target.offsetTop + target.offsetHeight
								}px`
								searchAhead.style.width = `${Math.max(
									target.offsetWidth,
									400
								)}px`
							}
							setPreviews(
								<table>
									<tbody>{rows}</tbody>
								</table>
							)
						} else {
							setPreviews(
								<div className={styles.noMatches}>
									NO MATCHING PARTNERS
								</div>
							)
						}
					}
				})
				.catch((error: any) => {
					switch (error?.response?.status) {
					case 401:
						LogOff('token-expired')
						return
					default:
							console.error(error) // eslint-disable-line
					}
					setPreviews(<></>)
				})
		} else {
			setPreviews(<></>)
		}
	}

	const defaultValue = props.value
		? props.data[`${props.field.Property}_name`]
		: ''
	return (
		<div className={`${styles.fieldOuterContainer} partner`}>
			<div className={styles.label}>
				{props.field.Label}
				{props.field.HardRequired && !props.isSearch ? (
					<>
						{'\u00A0'}
						<span
							className="hardRequired"
							title="REQUIRED IN ORDER TO SAVE"
						>
							*
						</span>
					</>
				) : (
					<></>
				)}
				{props.field.SoftRequired && !props.isSearch ? (
					<>
						{'\u00A0'}
						<span className="softRequired" title="REQUIRED">
							*
						</span>
					</>
				) : (
					<></>
				)}{' '}
			</div>
			<div className={`${styles.searchAheadBox} pseudoInput`}>
				<Link
					id={`${props.field.Property}_link`}
					rel="noreferrer"
					target="_blank"
					to={`/partners/${props.data[props.field.Property]}`}
				>
					<img
						id={`${props.field.Property}_logo`}
						onError={(event: any) => {
							if (event.target.src == PartnerIcon) {
								event.target.style.display = 'none'
							} else {
								event.target.src = PartnerIcon
							}
						}}
						onLoad={(event: any) => {
							event.target.style.display = ''
						}}
						src={
							props.data[`${props.field.Property}_image_preview`]
								? props.data[
									`${props.field.Property}_image_preview`
								  ]
								: PartnerIcon
						}
					/>
				</Link>
				<input
					autoComplete="off"
					className={`${
						props.field.HardRequired && !props.isSearch
							? 'hardRequired'
							: ''
					} ${
						props.field.SoftRequired && !props.isSearch
							? 'softRequired'
							: ''
					}`}
					data-value={defaultValue}
					defaultValue={defaultValue}
					id={`${props.field.Property}_name`}
					onBlur={() => {
						setPreviews(<></>)
					}}
					onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
						if (!props.readOnly) {
							search(e.target)
						}
					}}
					onFocus={useDebouncedCallback((e: ChangeEvent) => {
						if (!props.readOnly) {
							search(e.target as HTMLInputElement)
						}
					}, 100)}
					placeholder=" "
					readOnly={props.readOnly || props.field.ReadOnly}
					required={props.field.HardRequired && !props.isSearch}
					title={props.field.Label?.replace(':', '')}
					type="text"
				/>
				<div className={styles.previews} id={searchAheadID}>
					{previews}
				</div>
			</div>
			{props.field.Multiple ? (
				<ul
					className={styles.multiLabel}
					id={`${props.field.Property}_multilabel`}
				>
					{labels}
				</ul>
			) : (
				<></>
			)}
			<input
				data-hardrequired={props.field.HardRequired && !props.isSearch}
				data-multiple={props.field.Multiple}
				data-nullable={true}
				data-number={true}
				data-softrequired={props.field.SoftRequired && !props.isSearch}
				id={props.field.Property}
				name={props.field.Property}
				readOnly={true}
				type="hidden"
				value={value}
			/>
		</div>
	)
}
