import { Link } from 'react-router-dom'

import DownArrowIcon from '../../../assets/icons/arrow-down.svg'
import ListsIcon from '../../../assets/icons/lists.svg'
import { GlobalTopTabItems } from '../../../components/Tabs'
import { DefaultLayout } from '../../../layouts/DefaultLayout'
import { HasPermissions, HasRole, ProfileUserID } from '../../../Profile'
import { RandomID } from '../../../Utils'
import styles from './Sitemap.module.scss'

export const Sitemap = () => {
	const links = [
		{
			text: 'AMS CODES',
			to: '/ams_codes',
		},
		{
			text: 'API REFERENCE',
			to: '/api/v1',
		},
		{
			requiredPermissions: ['user.generateapitoken'],
			text: 'API TOKENS',
			to: `/users/${ProfileUserID()}/api`,
		},
		{
			text: 'CARRIER TRACKING',
			to: '/carrier-tracking',
		},
		{
			requiredRole: 'administrator',
			text: 'CARRIERS',
			to: '/carriers',
		},
		{
			requiredRole: 'administrator',
			text: 'CONTAINER TYPES',
			to: '/container_types',
		},
		{
			requiredRole: 'administrator',
			text: 'COUNTRIES',
			to: '/countries',
		},
		{
			requiredPermissions: ['shipment.read'],
			text: 'DASHBOARD',
			to: '/dashboard',
		},
		{
			requiredRole: 'administrator',
			text: 'EMAIL TEMPLATES',
			to: '/email_templates',
		},
		{
			text: 'MY PROFILE',
			to: `/users/${ProfileUserID()}`,
		},
		{
			text: 'NOTIFICATIONS',
			to: `/users/${ProfileUserID()}/notifications`,
		},
		{
			children: ['HISTORY', 'NEW'],
			requiredPermissions: ['nra.read'],
			requiredRole: 'administrator||memberpartner||partner',
			text: 'NRA',
			to: '/nras',
		},
		{
			text: 'OPENAPI SPECIFICATION',
			to: '/openapi.yaml',
		},
		{
			children: [
				'HISTORY',
				'NEW',
				'PARTNER_ACCESS',
				'SHIPMENTS',
				'USERS',
			],
			requiredPermissions: ['organization.read'],
			requiredRole: 'administrator||memberpartner||partner',
			text: 'ORGANIZATIONS',
			to: '/organizations',
		},
		{
			children: ['HISTORY', 'NEW'],
			requiredPermissions: ['partner.read'],
			requiredRole: 'administrator||memberpartner||partner',
			text: 'PARTNERS',
			to: '/partners',
		},
		{
			children: [
				'BALANCE',
				'INCOMING PAYMENT REQUESTS',
				'REQUEST MONEY',
				'SEND MONEY',
				'TRANSACTIONS',
			],
			requiredPermissions: ['pay.viewbalance'],
			requiredRole: 'administrator||memberpartner||partner',
			text: 'PAY',
			to: '/pay',
		},
		{
			requiredRole: 'administrator',
			text: 'PORTS',
			to: '/ports',
		},
		{
			requiredPermissions: ['report.read'],
			requiredRole: 'administrator||memberpartner||partner',
			text: 'REPORTS',
			to: '/reports',
		},
		{
			children: [
				'ACI',
				'AMS',
				'ARRIVAL NOTICE',
				'DOCUMENTS',
				'HBL PRINT',
				'HISTORY',
				'ISF',
				'NEW',
				'NRA',
				'REMARKS',
				'TRACKING',
			],
			requiredPermissions: ['shipment.read'],
			text: 'SHIPMENTS',
			to: '/shipments',
		},
		{
			text: 'SITEMAP',
			to: '/sitemap',
		},
		{
			children: ['HISTORY', 'NEW'],
			requiredPermissions: ['user.read'],
			text: 'USERS',
			to: '/users',
		},
	]

	return (
		<DefaultLayout
			bottomTabItems={SitemapBottomTabItems}
			leftMenuItems={SitemapLeftMenuItems}
			showPrint={true}
			tabMenuItems={SitemapTabMenuItems}
			topTabItems={GlobalTopTabItems}
		>
			<div className={styles.sitemapContainer}>
				<h2>AUTHENTICATED PAGES</h2>
				<ul>
					{links.map((menuItem: any, index: number) =>
						HasPermissions(menuItem.requiredPermissions) &&
						HasRole(menuItem.requiredRole || '') ? (
								<li key={`menuItem${index}`}>
									<Link to={menuItem.to}>
										{/*<<img
										alt={menuItem.text}
										onError={(event: any) => {
											event.target.style.display = 'none'
										}}
										src={menuItem.icon}
									/>{' '}*/}
										{menuItem.text}
									</Link>
									{menuItem.children ? (
										<ul>
											{menuItem.children.map(
												(
													childItem: any,
													childIndex: number
												) => {
													return (
														<li
															key={`menuItem${index}-${childIndex}`}
														>
															{childItem}
														</li>
													)
												}
											)}
										</ul>
									) : (
										<></>
									)}
								</li>
							) : (
								<div key={RandomID(16)} />
							)
					)}
				</ul>
			</div>
		</DefaultLayout>
	)
}

export const SitemapTabMenuItems = []

export const SitemapBottomTabItems = [
	{
		icon: DownArrowIcon,
		text: 'SITEMAP',
		to: '/sitemap',
	},
]

export const SitemapLeftMenuItems = [
	{
		icon: ListsIcon,
		text: 'SITEMAP',
		to: '/sitemap',
	},
]
