import axios from 'axios'
import { MouseEvent, useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'

import DownArrowIcon from '../../../../assets/icons/arrow-down.svg'
import ChainIcon from '../../../../assets/icons/chain.svg'
import CopyIcon from '../../../../assets/icons/copy.svg'
import DeleteIcon from '../../../../assets/icons/delete.svg'
import DocumentsIcon from '../../../../assets/icons/documents.svg'
import ExpireIcon from '../../../../assets/icons/expire.svg'
import NRAHistoryIcon from '../../../../assets/icons/history.svg'
import NRAInfoIcon from '../../../../assets/icons/open-nra.svg'
import { EditForm, ParsedFormData } from '../../../../components/Form/EditForm'
import { GlobalTopTabItems, TabItem } from '../../../../components/Tabs'
import { DefaultLayout } from '../../../../layouts/DefaultLayout'
import { APIHostname, HasPermission, LogOff } from '../../../../Profile'
import { EditNRALayout } from '../../../../types/data/NRA'
import styles from './NRA.module.scss'

export const NRA = () => {
	const { id } = useParams()
	const [nra, setNRA]: any = useState(null)
	const [loaded, setLoaded]: any = useState(false)
	const [errorMessage, setErrorMessage] = useState(<></>)
	const initialRedirectMessage = sessionStorage['redirect-message']
	const [redirectMessage] = useState(initialRedirectMessage)

	// Vary based on query string.
	const location = useLocation()

	useEffect(() => {
		axios({
			data: {},
			headers: {
				Authorization: `Bearer ${localStorage['token']}`,
			},
			method: 'GET',
			url: `${APIHostname}/api/v1/nras/${id}${location.search}`,
		})
			.then((response: any) => {
				setErrorMessage(<></>)
				setNRA(response.data)
				setLoaded(true)
			})
			.catch((error: any) => {
				switch (error?.response?.status) {
				case 401:
					LogOff('token-expired')
					return
				case 403:
					setErrorMessage(
						<>Error: You do not have permission to view NRAs.</>
					)
					break
				default:
					if (error?.response?.data?.error) {
						setErrorMessage(
							error?.response?.data?.link ? (
								<Link to={error?.response?.data?.link}>
										Error:
									{error?.response?.data?.error}
								</Link>
							) : (
								<>{error?.response?.data?.error}</>
							)
						)
					} else {
						setErrorMessage(<>Error: Unknown error.</>)
							console.error(error) // eslint-disable-line
					}
				}
			})
	}, [location])

	const NRABottomTabItems = [
		{
			icon: DownArrowIcon,
			text: `NRA #${id}`,
			to: `/nra/${id}`,
		},
	]

	const NRALeftMenuItems = [
		{
			icon: NRAInfoIcon,
			requiredPermissions: ['nra.read'],
			text: 'NRA INFO',
			to: `/nra/${id}`,
		},
		{
			icon: DocumentsIcon,
			requiredPermissions: ['nra.read'],
			text: 'NRA DOCUMENTS',
			to: `/nra/${id}/documents`,
		},
		{
			icon: ChainIcon,
			requiredPermissions: ['shipment.read'],
			text: 'LINKED SHIPMENTS',
			to: `/nra/${id}/shipments`,
		},
		{
			icon: NRAHistoryIcon,
			requiredPermissions: ['nra.read'],
			text: 'NRA HISTORY',
			to: `/nra/${id}/history`,
		},
	]

	const NRATabMenuItems: TabItem[] = [
		{
			icon: CopyIcon,
			onClick: async (e: MouseEvent<Element>) => {
				e.preventDefault()

				// eslint-disable-next-line
				const confirmation = confirm(`Really copy NRA?`)
				if (!confirmation) {
					return
				}

				const form = document.querySelector('form[name=\'nra\']')
				const formData = new FormData(form as HTMLFormElement)
				const payload = await ParsedFormData(formData, ['rates'])
				payload.copy = true
				payload.permissions = {}
				const permissions = document.querySelectorAll(
					'input[type=checkbox]'
				)
				permissions.forEach((permission: Element) => {
					const permissionBox = permission as HTMLInputElement
					payload.permissions[permissionBox.name] =
						permissionBox.checked
				})
				axios({
					data: payload,
					headers: {
						Authorization: `Bearer ${localStorage['token']}`,
						'Content-Type': 'application/json',
					},
					method: 'POST',
					url: `${APIHostname}/api/v1/nras`,
				})
					.then((response: any) => {
						sessionStorage[
							'redirect-message'
						] = `NRA #${response.data.nra_id} created.`
						window.history.replaceState(
							{},
							'',
							`/nra/${response.data.nra_id}`
						)
						document.location.reload()
					})
					.catch((error: any) => {
						switch (error?.response?.status) {
						case 401:
							LogOff('token-expired')
							return
						case 403:
							setErrorMessage(
								<>
										Error: You do not have permission to
										edit NRAs.
								</>
							)
							break
						default:
							if (error?.response?.data?.error) {
								setErrorMessage(
									error?.response?.data?.link ? (
										<Link
											to={error?.response?.data?.link}
										>
												Error:
											{error?.response?.data?.error}
										</Link>
									) : (
										<>{error?.response?.data?.error}</>
									)
								)
							} else {
								setErrorMessage(<>Error: Unknown error.</>)
									console.error(error) // eslint-disable-line
							}
						}
					})
			},
			requiredPermissions: ['nra.create'],
			requiredRole: 'administrator||memberpartner||partner',
			text: 'COPY',
			to: '#',
		},
	]
	if (nra?.active) {
		NRATabMenuItems.push({
			icon: DeleteIcon,
			onClick: async (e: MouseEvent<Element>) => {
				e.preventDefault()

				// eslint-disable-next-line
				const confirmation = confirm(`Really delete NRA?`)
				if (!confirmation) {
					return
				}

				axios({
					headers: {
						Authorization: `Bearer ${localStorage['token']}`,
						'Content-Type': 'application/json',
					},
					method: 'DELETE',
					url: `${APIHostname}/api/v1/nras/${id}`,
				})
					.then((response: any) => {
						sessionStorage[
							'redirect-message'
						] = `NRA #${response.data.nra_id} deleted.`
						window.history.replaceState({}, '', '/nras')
						document.location.reload()
					})
					.catch((error: any) => {
						switch (error?.response?.status) {
						case 401:
							LogOff('token-expired')
							return
						case 403:
							setErrorMessage(
								<>
										Error: You do not have permission to
										delete NRAs.
								</>
							)
							break
						default:
							if (error?.response?.data?.error) {
								setErrorMessage(
									error?.response?.data?.link ? (
										<Link
											to={error?.response?.data?.link}
										>
												Error:
											{error?.response?.data?.error}
										</Link>
									) : (
										<>{error?.response?.data?.error}</>
									)
								)
							} else {
								setErrorMessage(<>Error: Unknown error.</>)
									console.error(error) // eslint-disable-line
							}
						}
					})
			},
			requiredPermissions: ['nra.delete'],
			text: 'DELETE',
			to: '#',
		})
	} else {
		NRATabMenuItems.push({
			icon: DeleteIcon,
			onClick: async (e: MouseEvent<Element>) => {
				e.preventDefault()

				// eslint-disable-next-line
				const confirmation = confirm(`Really undelete NRA?`)
				if (!confirmation) {
					return
				}

				axios({
					headers: {
						Authorization: `Bearer ${localStorage['token']}`,
						'Content-Type': 'application/json',
					},
					method: 'POST',
					url: `${APIHostname}/api/v1/nras/${id}/undelete`,
				})
					.then((response: any) => {
						sessionStorage[
							'redirect-message'
						] = `NRA #${response.data.nra_id} undeleted.`
						window.history.replaceState({}, '', '/nras')
						document.location.reload()
					})
					.catch((error: any) => {
						switch (error?.response?.status) {
						case 401:
							LogOff('token-expired')
							return
						case 403:
							setErrorMessage(
								<>
										Error: You do not have permission to
										undelete NRAs.
								</>
							)
							break
						default:
							if (error?.response?.data?.error) {
								setErrorMessage(
									error?.response?.data?.link ? (
										<Link
											to={error?.response?.data?.link}
										>
												Error:
											{error?.response?.data?.error}
										</Link>
									) : (
										<>{error?.response?.data?.error}</>
									)
								)
							} else {
								setErrorMessage(<>Error: Unknown error.</>)
									console.error(error) // eslint-disable-line
							}
						}
					})
			},
			requiredPermissions: ['nra.delete'],
			text: 'UNDELETE',
			to: '#',
		})
	}
	NRATabMenuItems.reverse()

	if (nra && !nra?.replaced_by) {
		NRATabMenuItems.push({
			icon: ExpireIcon,
			onClick: async (e: MouseEvent<Element>) => {
				e.preventDefault()

				// eslint-disable-next-line
				const confirmation = confirm(`Really expire NRA?`)
				if (!confirmation) {
					return
				}

				const form = document.querySelector('form[name=\'nra\']')
				const formData = new FormData(form as HTMLFormElement)
				const payload = await ParsedFormData(formData, ['rates'])
				payload.copy = true
				payload.permissions = {}
				const permissions = document.querySelectorAll(
					'input[type=checkbox]'
				)
				permissions.forEach((permission: Element) => {
					const permissionBox = permission as HTMLInputElement
					payload.permissions[permissionBox.name] =
						permissionBox.checked
				})
				axios({
					data: payload,
					headers: {
						Authorization: `Bearer ${localStorage['token']}`,
						'Content-Type': 'application/json',
					},
					method: 'POST',
					url: `${APIHostname}/api/v1/nras/${id}/expire`,
				})
					.then((response: any) => {
						sessionStorage[
							'redirect-message'
						] = `NRA #${response.data.nra_id} replaces expired NRA.`
						window.history.replaceState(
							{},
							'',
							`/nra/${response.data.nra_id}`
						)
						document.location.reload()
					})
					.catch((error: any) => {
						switch (error?.response?.status) {
						case 401:
							LogOff('token-expired')
							return
						case 403:
							setErrorMessage(
								<>
										Error: You do not have permission to
										expire NRAs.
								</>
							)
							break
						default:
							if (error?.response?.data?.error) {
								setErrorMessage(
									error?.response?.data?.link ? (
										<Link
											to={error?.response?.data?.link}
										>
												Error:
											{error?.response?.data?.error}
										</Link>
									) : (
										<>{error?.response?.data?.error}</>
									)
								)
							} else {
								setErrorMessage(<>Error: Unknown error.</>)
									console.error(error) // eslint-disable-line
							}
						}
					})
			},
			requiredPermissions: ['nra.update'],
			requiredRole: 'administrator||memberpartner||partner',
			text: 'EXPIRE',
			to: '#',
		})
	}

	NRATabMenuItems.reverse()

	if (!loaded) {
		if (errorMessage) {
			return (
				<DefaultLayout
					bottomTabItems={NRABottomTabItems}
					leftMenuItems={NRALeftMenuItems}
					showPrint={true}
					tabMenuItems={NRATabMenuItems}
					topTabItems={GlobalTopTabItems}
				>
					<div className={styles.nraContainer}>
						<div className={styles.redirectMessage}>
							{redirectMessage}
						</div>
						<div className={styles.errorMessage}>
							{errorMessage}
						</div>
					</div>
				</DefaultLayout>
			)
		}

		return <div className="loading">Loading...</div>
	}

	sessionStorage.removeItem('redirect-message')

	const readOnly = !HasPermission('nra', 'update') || !nra?.active
	return (
		<DefaultLayout
			bottomTabItems={NRABottomTabItems}
			leftMenuItems={NRALeftMenuItems}
			showPrint={true}
			tabMenuItems={NRATabMenuItems}
			topTabItems={GlobalTopTabItems}
		>
			<div className={styles.nraContainer}>
				<div className={styles.redirectMessage}>{redirectMessage}</div>
				<div className={styles.errorMessage}>
					{errorMessage}{' '}
					{!nra?.active ? <>This NRA has been deleted.</> : <></>}
				</div>

				<EditForm
					data={nra}
					layout={EditNRALayout}
					name="nra"
					onSubmit={async (formData: FormData) => {
						// Update NRA
						axios({
							data: await ParsedFormData(formData, ['rates']),
							headers: {
								Authorization: `Bearer ${localStorage['token']}`,
								'Content-Type': 'application/json',
							},
							method: 'POST',
							url: `${APIHostname}/api/v1/nras/${id}`,
						})
							.then(async () => {
								sessionStorage[
									'redirect-message'
								] = `NRA #${id} updated.`
								window.history.replaceState({}, '', '/nras')
								document.location.reload()
							})
							.catch((error: any) => {
								switch (error?.response?.status) {
								case 401:
									LogOff('token-expired')
									return
								case 403:
									setErrorMessage(
										<>
												Error: You do not have
												permission to edit NRAs.
										</>
									)
									break
								default:
									if (error?.response?.data?.error) {
										setErrorMessage(
											error?.response?.data?.link ? (
												<Link
													to={
														error?.response
															?.data?.link
													}
												>
														Error:
													{
														error?.response
															?.data?.error
													}
												</Link>
											) : (
												<>
													{
														error?.response
															?.data?.error
													}
												</>
											)
										)
									} else {
										setErrorMessage(
											<>Error: Unknown error.</>
										)
											console.error(error) // eslint-disable-line
									}
								}
							})
					}}
					previewAPIData={async (formData: FormData) => {
						const payload = await ParsedFormData(formData, [
							'rates',
						])

						return {
							data: JSON.stringify(payload, null, 4),
							method: 'POST',
							url: `${APIHostname}/api/v1/nras/${id}`,
						}
					}}
					readOnly={readOnly}
				/>
			</div>
		</DefaultLayout>
	)
}
