import axios from 'axios'
import { useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'

import DownArrowIcon from '../../../../../assets/icons/arrow-down.svg'
import ChainIcon from '../../../../../assets/icons/chain.svg'
import DocumentsIcon from '../../../../../assets/icons/documents.svg'
import NRAHistoryIcon from '../../../../../assets/icons/history.svg'
import NRAInfoIcon from '../../../../../assets/icons/open-nra.svg'
import { NavTable } from '../../../../../components/NavTable'
import { NRASummary } from '../../../../../components/NRASummary'
import { GlobalTopTabItems } from '../../../../../components/Tabs'
import { DefaultLayout } from '../../../../../layouts/DefaultLayout'
import { APIHostname, LogOff } from '../../../../../Profile'
import Control from '../../../../../types/Control'
import { EscapeURIParameters } from '../../../../../Utils'
import styles from './NRAHistory.module.scss'

export const NRAHistory = () => {
	const { id } = useParams()
	const [history, setHistory]: any = useState(null)
	const [historyCount, setHistoryCount] = useState(0)
	const [errorMessage, setErrorMessage] = useState(<></>)
	const [nraLoaded, setNRALoaded]: any = useState(false)
	const [historyLoaded, setHistoryLoaded]: any = useState(false)
	const [nra, setNRA]: any = useState(null)
	const initialRedirectMessage = sessionStorage['redirect-message']
	const [redirectMessage] = useState(initialRedirectMessage)

	// Vary based on query string.
	const location = useLocation()
	const urlParams = new URLSearchParams(location.search)
	if (!urlParams.get('limit')) {
		urlParams.set('limit', '25')
	}

	useEffect(() => {
		axios({
			data: {},
			headers: {
				Authorization: `Bearer ${localStorage['token']}`,
			},
			method: 'GET',
			url: `${APIHostname}/api/v1/nras/${id}${location.search}`,
		})
			.then((response: any) => {
				setErrorMessage(<></>)
				setNRA(response.data)
				setNRALoaded(true)
			})
			.catch((error: any) => {
				switch (error?.response?.status) {
				case 401:
					LogOff('token-expired')
					return
				case 403:
					setErrorMessage(
						<>Error: You do not have permission to view nras.</>
					)
					break
				default:
					if (error?.response?.data?.error) {
						setErrorMessage(
							error?.response?.data?.link ? (
								<Link to={error?.response?.data?.link}>
										Error:
									{error?.response?.data?.error}
								</Link>
							) : (
								<>{error?.response?.data?.error}</>
							)
						)
					} else {
						setErrorMessage(<>Error: Unknown error.</>)
							console.error(error) // eslint-disable-line
					}
				}
			})

		axios({
			data: {},
			headers: {
				Authorization: `Bearer ${localStorage['token']}`,
			},
			method: 'GET',
			url: `${APIHostname}/api/v1/nras/${id}/history?${EscapeURIParameters(
				urlParams.toString()
			)}`,
		})
			.then((response: any) => {
				setErrorMessage(<></>)
				setHistory(response.data.historyRecords)
				setHistoryCount(response.data.historyRecord_count)
				setHistoryLoaded(true)
			})
			.catch((error: any) => {
				switch (error?.response?.status) {
				case 401:
					LogOff('token-expired')
					return
				case 403:
					setErrorMessage(
						<>Error: You do not have permission to view nras.</>
					)
					break
				default:
					if (error?.response?.data?.error) {
						setErrorMessage(
							error?.response?.data?.link ? (
								<Link to={error?.response?.data?.link}>
										Error:
									{error?.response?.data?.error}
								</Link>
							) : (
								<>{error?.response?.data?.error}</>
							)
						)
					} else {
						setErrorMessage(<>Error: Unknown error.</>)
							console.error(error) // eslint-disable-line
					}
				}
			})
	}, [location])

	const NRALeftMenuItems = [
		{
			icon: NRAInfoIcon,
			requiredPermissions: ['nra.read'],
			text: 'NRA INFO',
			to: `/nra/${id}`,
		},
		{
			icon: DocumentsIcon,
			requiredPermissions: ['nra.read'],
			text: 'NRA DOCUMENTS',
			to: `/nra/${id}/documents`,
		},
		{
			icon: ChainIcon,
			requiredPermissions: ['shipment.read'],
			text: 'LINKED SHIPMENTS',
			to: `/nra/${id}/shipments`,
		},
		{
			icon: NRAHistoryIcon,
			requiredPermissions: ['nra.read'],
			text: 'NRA HISTORY',
			to: `/nra/${id}/history`,
		},
	]

	const NRAHistoryBottomTabItems = [
		{
			icon: DownArrowIcon,
			text: `NRA #${id} HISTORY`,
			to: `/nra/${id}/history`,
		},
	]

	const NRATabMenuItems: [] = []

	if (!nraLoaded || !historyLoaded) {
		if (errorMessage) {
			return (
				<DefaultLayout
					bottomTabItems={NRAHistoryBottomTabItems}
					leftMenuItems={NRALeftMenuItems}
					showPrint={true}
					tabMenuItems={NRATabMenuItems}
					topTabItems={GlobalTopTabItems}
				>
					<div className={styles.nraHistoryContainer}>
						<div className={styles.redirectMessage}>
							{redirectMessage}
						</div>
						<div className={styles.errorMessage}>
							{errorMessage}
						</div>
					</div>
				</DefaultLayout>
			)
		}

		return <div className="loading">Loading...</div>
	}

	sessionStorage.removeItem('redirect-message')
	return (
		<DefaultLayout
			bottomTabItems={NRAHistoryBottomTabItems}
			leftMenuItems={NRALeftMenuItems}
			showPrint={true}
			tabMenuItems={NRATabMenuItems}
			topTabItems={GlobalTopTabItems}
		>
			<div className={styles.nraHistoryContainer}>
				<div className={styles.redirectMessage}>{redirectMessage}</div>
				<div className={styles.errorMessage}>
					{errorMessage}{' '}
					{!nra?.active ? <>This NRA has been deleted.</> : <></>}
				</div>

				<NRASummary nra={nra} />

				<div className={styles.navTable}>
					<NavTable
						columns={[
							{
								control: Control.Hyperlink,
								id: 'link',
								title: 'Link',
							},
							{
								id: 'version',
								title: 'Version',
							},
							{
								id: 'action',
								title: 'Action',
							},
							{
								id: 'element',
								title: 'Element',
							},
							{
								id: 'original',
								title: 'Original Value',
							},
							{
								id: 'new',
								title: 'New Value',
							},
							{
								control: Control.User,
								id: 'created_by',
								title: 'USER',
							},
							{
								control: Control.DateTime,
								id: 'created',
								title: 'Date/Time',
							},
						]}
						count={historyCount}
						data={history}
						filters={JSON.parse(urlParams.get('filters') ?? '[]')}
						includeDelete={false}
						primaryDisplayColumn=""
						primaryKey=""
					/>
				</div>
			</div>
		</DefaultLayout>
	)
}
