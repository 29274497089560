import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'

import { APIHostname } from '../../../../Profile'
import { Country } from '../../../../types/data/Country'
import { FieldProps } from '../../Field'
import styles from './Country.module.scss'

export const EditCountry = (props: FieldProps) => {
	const [value, setValue] = useState(props.value)

	const { isPending, error, data } = useQuery({
		queryFn: () =>
			fetch(
				`${APIHostname}/api/v1/countries?sorts=[{"field":"name","order":"ASC"}]`,
				{
					headers: {
						Authorization: `Bearer ${localStorage['token']}`,
					},
				}
			).then((response) => response.json()),
		queryKey: ['countries'],
		refetchInterval: 0,
		refetchOnWindowFocus: false,
	})

	if (error) {
		console.error(error) // eslint-disable-line
	}

	if (isPending) {
		return <></>
	}

	if (props.readOnly || props.field.ReadOnly) {
		return (
			<div
				className={styles.fieldOuterContainer}
				id={
					props.field.Property
						? `${props.field.Property}_container`
						: ''
				}
			>
				<div className={styles.countryBox}>
					{value ? (
						<span
							className={`fi fi-${value
								?.toString()
								.toLowerCase()} ${styles.flag}`}
						></span>
					) : (
						<></>
					)}
					<input
						data-hardrequired={
							props.field.HardRequired && !props.isSearch
						}
						data-softrequired={
							props.field.SoftRequired && !props.isSearch
						}
						defaultValue={
							data.countries?.filter((ctry: any) => {
								return ctry.code == value
							})[0]?.name
						}
						readOnly={true}
						title={props.field.Label?.replace(':', '')}
						type="text"
					/>
				</div>
			</div>
		)
	}

	return (
		<div
			className={styles.fieldOuterContainer}
			id={props.field.Property ? `${props.field.Property}_container` : ''}
		>
			<div className={styles.countryBox}>
				{value ? (
					<span
						className={`fi fi-${value?.toString().toLowerCase()} ${
							styles.flag
						}`}
					></span>
				) : (
					<></>
				)}
				<select
					className={`${
						props.field.HardRequired && !props.isSearch
							? 'hardRequired'
							: ''
					} ${
						props.field.SoftRequired && !props.isSearch
							? 'softRequired'
							: ''
					}`}
					data-hardrequired={
						props.field.HardRequired && !props.isSearch
					}
					data-nullable={props.field.Nullable}
					data-softrequired={
						props.field.SoftRequired && !props.isSearch
					}
					id={props.field.Property}
					key={`${props.field.Property}-${value}`}
					name={props.field.Property}
					onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
						setValue(e.target.value)

						if (props.onChange) {
							props.onChange(e)
						}
					}}
					required={props.field.HardRequired && !props.isSearch}
					title={props.field.Label?.replace(':', '')}
					value={value}
				>
					<option value=""></option>
					{data.countries?.map((country: Country, index: number) => (
						<option key={`country${index}`} value={country.code}>
							{country.name}
						</option>
					))}
				</select>
			</div>
		</div>
	)
}
