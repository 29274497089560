import { Authenticated } from '../../Profile'
import { GlobalMenu } from './GlobalMenu'
import styles from './Header.module.scss'
import { Logo } from './Logo'
import { ProfileMenu } from './ProfileMenu'

export const Header = () => {
	if (Authenticated()) {
		return (
			<div className={styles.globalMenu}>
				<Logo />
				<div className={styles.globalMenuRight}>
					<GlobalMenu />
					<ProfileMenu />
				</div>
			</div>
		)
	}

	return (
		<div className={styles.globalMenu}>
			<Logo />
			<div className={styles.globalMenuRight}>
				<ProfileMenu />
			</div>
		</div>
	)
}
