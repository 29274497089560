import axios from 'axios'
import { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

import DownArrowIcon from '../../../assets/icons/arrow-down.svg'
import NewIcon from '../../../assets/icons/new.svg'
import { SearchForm } from '../../../components/Form/SearchForm'
import { AdministrationLeftMenuItems } from '../../../components/LeftMenu'
import { NavTable } from '../../../components/NavTable'
import { GlobalTopTabItems } from '../../../components/Tabs'
import { DefaultLayout } from '../../../layouts/DefaultLayout'
import { APIHostname, HasPermission, HasRole, LogOff } from '../../../Profile'
import Control from '../../../types/Control'
import { SearchUserLayout } from '../../../types/data/User'
import { EscapeURIParameters } from '../../../Utils'
import styles from './Users.module.scss'

export const Users = () => {
	const [users, setUsers]: any = useState(null)
	const [userCount, setUserCount] = useState(0)
	const [loaded, setLoaded]: any = useState(false)
	const [errorMessage, setErrorMessage] = useState(<></>)
	const initialRedirectMessage = sessionStorage['redirect-message']
	const [redirectMessage] = useState(initialRedirectMessage)

	// Vary based on query string.
	const location = useLocation()
	const urlParams = new URLSearchParams(location.search)
	if (!urlParams.get('limit')) {
		urlParams.set('limit', '25')
	}

	useEffect(() => {
		axios({
			data: {},
			headers: {
				Authorization: `Bearer ${localStorage['token']}`,
			},
			method: 'GET',
			url: `${APIHostname}/api/v1/users?${EscapeURIParameters(
				urlParams.toString()
			)}&view=browse`,
		})
			.then((response: any) => {
				setErrorMessage(<></>)
				setUsers(response.data.users)
				setUserCount(response.data.user_count)
				setLoaded(true)
			})
			.catch((error: any) => {
				switch (error?.response?.status) {
				case 401:
					LogOff('token-expired')
					return
				case 403:
					setErrorMessage(
						<>
								Error: You do not have permission to view users.
						</>
					)
					break
				default:
					if (error?.response?.data?.error) {
						setErrorMessage(
							error?.response?.data?.link ? (
								<Link to={error?.response?.data?.link}>
										Error:
									{error?.response?.data?.error}
								</Link>
							) : (
								<>{error?.response?.data?.error}</>
							)
						)
					} else {
						setErrorMessage(<>Error: Unknown error.</>)
							console.error(error) // eslint-disable-line
					}
				}
			})
	}, [location])

	if (!loaded) {
		if (errorMessage) {
			return (
				<DefaultLayout
					bottomTabItems={UsersBottomTabItems}
					leftMenuItems={AdministrationLeftMenuItems}
					showPrint={true}
					tabMenuItems={UsersTabMenuItems}
					topTabItems={GlobalTopTabItems}
				>
					<div className={styles.usersContainer}>
						<div className={styles.redirectMessage}>
							{redirectMessage}
						</div>
						<div className={styles.errorMessage}>
							{errorMessage}
						</div>
					</div>
				</DefaultLayout>
			)
		}

		return <div className="loading">Loading...</div>
	}

	const columns = [
		{
			children: [
				{
					control: Control.ImageFile,
					id: 'image',
					title: '',
				},
			],
		},
		{
			children: [
				{
					id: 'name',
					title: 'Account Name',
				},
				{
					id: 'title',
					title: 'Title',
				},
			],
		},
		{
			children: [
				{
					control: Control.Role,
					id: 'role',
					title: 'Role',
				},
				{
					control: Control.Organization,
					id: 'organization',
					title: 'Organization',
				},
				{
					control: Control.Partner,
					id: 'partner',
					title: 'Partner',
				},
			],
		},
		{
			children: [
				{
					id: 'city',
					title: 'City',
				},
				{
					id: 'state',
					title: 'State',
				},
				{
					control: Control.Country,
					id: 'country',
					title: 'Country',
				},
			],
		},
		{
			children: [
				{
					control: Control.Email,
					id: 'email',
					title: 'Email',
				},
				{
					control: Control.Phone,
					id: 'phone',
					title: 'Phone',
				},
			],
		},
		{
			id: 'notes',
			title: 'Notes',
		},
		{
			control: Control.Boolean,
			id: 'approved',
			title: 'Approved',
		},
		{
			children: [
				{
					control: Control.DateTime,
					id: 'created',
					title: 'Creation Date',
				},
				{
					control: Control.User,
					id: 'created_by',
					title: 'Created By',
				},
			],
		},
	]

	if (HasRole('administrator')) {
		columns.push({
			children: [
				{
					control: Control.DateTime,
					id: 'last_login',
					title: 'Last Login',
				},
				{
					control: Control.DateTime,
					id: 'last_token_refresh',
					title: 'Last Token Refresh',
				},
			],
		})
	}

	columns.push({
		children: [
			{
				control: Control.DateTime,
				id: 'modified',
				title: 'Modified Date',
			},
			{
				control: Control.User,
				id: 'modified_by',
				title: 'Modified By',
			},
		],
	})

	sessionStorage.removeItem('redirect-message')
	return (
		<DefaultLayout
			bottomTabItems={UsersBottomTabItems}
			leftMenuItems={AdministrationLeftMenuItems}
			showPrint={true}
			tabMenuItems={UsersTabMenuItems}
			topTabItems={GlobalTopTabItems}
		>
			<div className={styles.usersContainer}>
				<div className={styles.redirectMessage}>{redirectMessage}</div>
				<div className={styles.errorMessage}>{errorMessage}</div>

				<div className={styles.searchForm}>
					<SearchForm
						data={{}}
						layout={SearchUserLayout}
						name="users"
						readOnly={false}
					/>
				</div>

				<div className={styles.navTable}>
					<NavTable
						columns={columns}
						count={userCount}
						data={users}
						filters={JSON.parse(urlParams.get('filters') ?? '[]')}
						includeDelete={HasPermission('user', 'delete')}
						primaryDisplayColumn="name"
						primaryKey="id"
					/>
				</div>
			</div>
		</DefaultLayout>
	)
}

export const UsersTabMenuItems = [
	{
		icon: NewIcon,
		requiredPermissions: ['user.create'],
		text: 'NEW',
		to: '/users/new',
	},
].reverse()

export const UsersBottomTabItems = [
	{
		icon: DownArrowIcon,
		text: 'USERS',
		to: '/users',
	},
]
