import { ReactNode } from 'react'
import { Link } from 'react-router-dom'

import DataCorrectionIcon from '../../assets/icons/data-correction.svg'
import HotListActiveIcon from '../../assets/icons/hot-active.svg'
import LockedIcon from '../../assets/icons/locked.svg'
import { Shipment } from '../../types/data/Shipment'
import styles from './ShipmentSummary.module.scss'

export const ShipmentSummary = (props: ShipmentSummaryProps) => {
	if (!props.shipment) {
		return <></>
	}

	return (
		<div className={styles.shipmentSummaryOuterContainer}>
			<div className={styles.shipmentSummaryInnerContainer}>
				<div>
					<div className={styles.label}>BOOKING #:</div>
					<div className={styles.value}>
						<Link to={`/shipments/${props.shipment.id}`}>
							{props.shipment.booking_number}
						</Link>
					</div>
				</div>
				<div>
					<div className={styles.label}>MBL:</div>
					<div className={styles.value}>{props.shipment.mbl}</div>
				</div>
				<div>
					<div className={styles.label}>MBL SCAC:</div>
					<div className={styles.value}>
						{props.shipment.mbl_carrier_scac}
					</div>
				</div>
				<div>
					<div className={styles.label}>HBL:</div>
					<div className={styles.value}>{props.shipment.hbl}</div>
				</div>
				<div>
					<div className={styles.label}>HBL SCAC:</div>
					<div className={styles.value}>
						{props.shipment.hbl_carrier_scac}
					</div>
				</div>
				<div>
					<div className={styles.label}>
						DESTINATION PARTNER REFERENCE #:
					</div>
					<div className={styles.value}>
						{props.shipment.destination_partner_reference}
					</div>
				</div>
				<div>
					<div className={styles.label}>
						ORIGIN PARTNER REFERENCE #:
					</div>
					<div className={styles.value}>
						{props.shipment.origin_partner_reference}
					</div>
				</div>
				{props.shipment.lock ||
				props.shipment.data_correction ||
				props.shipment.hotlist ? (
						<div>
							<div className={styles.value}>
								{props.shipment.lock ? (
									<img
										className={styles.full}
										src={LockedIcon}
										title="LOCKED"
									/>
								) : (
									<></>
								)}{' '}
								{props.shipment.data_correction ? (
									<img
										className={styles.full}
										src={DataCorrectionIcon}
										title="DATA CORRECTION REQUIRED"
									/>
								) : (
									<></>
								)}{' '}
								{props.shipment.hotlist ? (
									<img
										className={styles.full}
										src={HotListActiveIcon}
										title="HOT LIST"
									/>
								) : (
									<></>
								)}
							</div>
						</div>
					) : (
						<></>
					)}
			</div>
			{props.children}
		</div>
	)
}

export type ShipmentSummaryProps = {
	children?: ReactNode
	shipment: Shipment
}
