import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { ReactElement, useState } from 'react'
import { Link } from 'react-router-dom'

import OrganizationsIcon from '../../../../assets/icons/organizations.svg'
import { APIHostname, LogOff } from '../../../../Profile'
import { EncodedStringify } from '../../../../types/Filter'
import { RandomID, TrimWhitespace } from '../../../../Utils'
import { FieldProps } from '../../Field'
import styles from './OrganizationTextbox.module.scss'

export const EditOrganizationTextbox = (props: FieldProps) => {
	const [previews, setPreviews] = useState(<></>)
	const [value, setValue] = useState(props.value ?? 0)
	const [searchAheadID] = useState(RandomID(16))
	const [errorMessage, setErrorMessage] = useState(<></>)

	const countries = useQuery({
		queryFn: () =>
			fetch(
				`${APIHostname}/api/v1/countries?sorts=[{"field":"name","order":"ASC"}]`,
				{
					headers: {
						Authorization: `Bearer ${localStorage['token']}`,
					},
				}
			).then((response) => response.json()),
		queryKey: ['countries'],
		refetchInterval: 0,
		refetchOnWindowFocus: false,
	})

	const organizationTypes = useQuery({
		queryFn: () =>
			fetch(
				`${APIHostname}/api/v1/organizationtypes?sorts=[{"field":"name","order":"ASC"}]`,
				{
					headers: {
						Authorization: `Bearer ${localStorage['token']}`,
					},
				}
			).then((response) => response.json()),
		queryKey: ['organization_types'],
		refetchInterval: 0,
		refetchOnWindowFocus: false,
	})

	if (countries.error) {
		console.error(countries.error) // eslint-disable-line
	}

	if (organizationTypes.error) {
		console.error(organizationTypes.error) // eslint-disable-line
	}

	if (countries.isPending || organizationTypes.isPending) {
		return <></>
	}

	const search = (target: HTMLInputElement) => {
		if (target.value) {
			const filters = [
				{
					field: 'name,code',
					operator: 'contains',
					value: target.value,
				},
			]
			if (props.field.SubType) {
				filters.push({
					field: 'type',
					operator: 'equals',
					value: props.field.SubType,
				})
			}
			axios({
				data: {},
				headers: {
					Authorization: `Bearer ${localStorage['token']}`,
				},
				method: 'GET',
				url: `${APIHostname}/api/v1/organizations?filters=${EncodedStringify(
					filters
				)}&sorts=[{"field":"name","order":"ASC"}]&limit=250`,
			})
				.then((response: any) => {
					if (response.data.organization_count != null) {
						if (response.data.organizations) {
							const rows: ReactElement[] = []
							response.data.organizations.forEach((p: any) => {
								rows.push(
									<tr
										key={`preview${p.id}`}
										onClick={(event: any) => {
											setValue(p.id)
											;(
												document.getElementById(
													`${props.field.Property}_link`
												) as HTMLAnchorElement
											).href = `/organizations/${p.id}`
											if (p.has_logo) {
												(
													document.getElementById(
														`${props.field.Property}_logo`
													) as HTMLImageElement
												).src = p.image_preview
											} else {
												(
													document.getElementById(
														`${props.field.Property}_logo`
													) as HTMLImageElement
												).src = OrganizationsIcon
											}
											(
												document.getElementById(
													`${props.field.Property}_name`
												) as HTMLInputElement
											).value = p.name
											let address = ''
											if (p.address) {
												address += TrimWhitespace(
													p.address
												)
												if (
													p.city ||
													p.state ||
													p.postal_code
												) {
													address += '\r\n'
												}
											}
											if (p.city) {
												address += TrimWhitespace(
													p.city
												)
												if (p.state) {
													address += ', '
												} else {
													if (p.postal_code) {
														address += ' '
													}
												}
											}
											if (p.state) {
												address += TrimWhitespace(
													p.state
												)
												if (p.postal_code) {
													address += ' '
												}
											}
											if (p.postal_code) {
												address += TrimWhitespace(
													p.postal_code
												)
											}
											if (p.country) {
												if (address) {
													address += '\r\n'
												}
												address +=
													countryNames[p.country]
											}
											if (
												p.main_contact_email ||
												p.main_contact_phone ||
												p.additional_emails
											) {
												if (p.main_contact_email) {
													address += `\r\n${TrimWhitespace(
														p.main_contact_email
													)}`
												}
												if (p.additional_emails) {
													address += `\r\n${TrimWhitespace(
														p.additional_emails
													)}`
												}
												if (p.main_contact_phone) {
													address += `\r\n${TrimWhitespace(
														p.main_contact_phone
													)}`
												}
											}
											(
												document.getElementById(
													`${props.field.Property}_address`
												) as HTMLInputElement
											).value = address
											setPreviews(<></>)

											if (props.onChange) {
												props.onChange(event)
											}
										}}
									>
										<td>
											<img
												onError={(event: any) => {
													if (
														event.target.src ==
														OrganizationsIcon
													) {
														event.target.style.display =
															'none'
													} else {
														event.target.src =
															OrganizationsIcon
													}
												}}
												onLoad={(event: any) => {
													event.target.style.display =
														''
												}}
												src={
													p.image_preview
														? p.image_preview
														: OrganizationsIcon
												}
											/>
										</td>
										<td>{p.id}</td>
										<td>{p.code}</td>
										<td>{p.name}</td>
										<td>
											{
												organizationTypes.data.organization_types.filter(
													(organizationType: any) => {
														return (
															organizationType.type ==
															p.type
														)
													}
												)[0]?.name
											}
										</td>
									</tr>
								)
							})
							setPreviews(
								<table>
									<tbody>{rows}</tbody>
								</table>
							)
						} else {
							setPreviews(
								<div className={styles.noMatches}>
									NO MATCHING ORGANIZATIONS
								</div>
							)
						}
						const searchAhead =
							document.getElementById(searchAheadID)
						if (searchAhead) {
							searchAhead.style.left = `${
								target.offsetLeft + 0.5
							}px`
							searchAhead.style.top = `${
								target.offsetTop + target.offsetHeight
							}px`
							searchAhead.style.width = `${Math.max(
								target.offsetWidth,
								400
							)}px`
						}
					} else {
						setPreviews(<></>)
					}
				})
				.catch((error: any) => {
					switch (error?.response?.status) {
					case 401:
						LogOff('token-expired')
						return
					case 403:
						setErrorMessage(
							<>
									Error: You do not have permission to view
									organizations.
							</>
						)
						break
					default:
						if (error?.response?.data?.error) {
							setErrorMessage(
								error?.response?.data?.link ? (
									<Link to={error?.response?.data?.link}>
											Error:
										{error?.response?.data?.error}
									</Link>
								) : (
									<>{error?.response?.data?.error}</>
								)
							)
						} else {
							setErrorMessage(<>Error: Unknown error.</>)
								console.error(error) // eslint-disable-line
						}
					}
					setPreviews(<></>)
				})
		} else {
			setPreviews(<></>)
		}
	}

	const countryNames = countries.data.countries.reduce(
		(acc: any, country: any) => {
			acc[country.code] = country.name
			return acc
		},
		{}
	)

	const defaultValue = props.value
		? props.data[`${props.field.Property}_name`]
		: ''
	return (
		<div
			className={styles.fieldOuterContainer}
			id={props.field.Property ? `${props.field.Property}_container` : ''}
		>
			<div>
				<div className={styles.label}>
					{props.field.Label}
					{props.field.Label?.endsWith(':') ? '' : ':'}
					{props.field.HardRequired && !props.isSearch ? (
						<>
							{'\u00A0'}
							<span
								className="hardRequired"
								title="REQUIRED IN ORDER TO SAVE"
							>
								*
							</span>
						</>
					) : (
						<></>
					)}
					{props.field.SoftRequired && !props.isSearch ? (
						<>
							{'\u00A0'}
							<span className="softRequired" title="REQUIRED">
								*
							</span>
						</>
					) : (
						<></>
					)}{' '}
				</div>
				<div className={styles.errorMessage}>{errorMessage}</div>
				<div className={`${styles.searchAheadBox} pseudoInput`}>
					<Link
						id={`${props.field.Property}_link`}
						rel="noreferrer"
						target="_blank"
						to={`/organizations/${
							props.data[props.field.Property]
						}`}
					>
						<img
							id={`${props.field.Property}_logo`}
							onError={(event: any) => {
								if (event.target.src == OrganizationsIcon) {
									event.target.style.display = 'none'
								} else {
									event.target.src = OrganizationsIcon
								}
							}}
							onLoad={(event: any) => {
								event.target.style.display = ''
							}}
							src={
								props.data[
									`${props.field.Property}_image_preview`
								]
									? props.data[
										`${props.field.Property}_image_preview`
									  ]
									: OrganizationsIcon
							}
						/>
					</Link>
					<input
						autoComplete="off"
						className={`${
							props.field.HardRequired && !props.isSearch
								? 'hardRequired'
								: ''
						} ${
							props.field.SoftRequired && !props.isSearch
								? 'softRequired'
								: ''
						}`}
						data-value={defaultValue}
						defaultValue={defaultValue}
						id={`${props.field.Property}_name`}
						name={`${props.field.Property}_name`}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
							search(e.target)
							if (props.onChange) {
								props.onChange(e)
							}
						}}
						onFocus={(e: React.ChangeEvent<HTMLInputElement>) => {
							search(e.target)
						}}
						placeholder=" "
						readOnly={props.readOnly || props.field.ReadOnly}
						required={props.field.HardRequired && !props.isSearch}
						title={props.field.Label?.replace(':', '')}
						type="text"
					/>
					<div className={styles.previews} id={searchAheadID}>
						{previews}
					</div>{' '}
				</div>
				<input
					data-hardrequired={
						props.field.HardRequired && !props.isSearch
					}
					data-nullable={true}
					data-number={true}
					data-softrequired={
						props.field.SoftRequired && !props.isSearch
					}
					id={props.field.Property}
					name={props.field.Property}
					readOnly={true}
					type="hidden"
					value={value}
				/>
			</div>
			<div className="organizationMetadata">
				<div>
					<textarea
						defaultValue={
							props.data
								? FormatAddress(
									props.data,
									props.field.Property,
									countryNames
								  )
								: ''
						}
						id={`${props.field.Property}_address`}
						name={`${props.field.Property}_address`}
						onChange={props.onChange}
						readOnly={props.readOnly || props.field.ReadOnly}
						rows={5}
						tabIndex={-1}
						title="ADDRESS"
					/>
				</div>
			</div>
		</div>
	)
}

export const FormatAddress = (
	data: any,
	field: string,
	countryNames: { [key: string]: string }
): string => {
	let value = ''
	if (data[`${field}_address`]) {
		value += TrimWhitespace(data[`${field}_address`])
		if (
			data[`${field}_city`] ||
			data[`${field}_state`] ||
			data[`${field}_postal_code]`]
		) {
			value += '\r\n'
		}
	}
	if (data[`${field}_city`]) {
		value += TrimWhitespace(data[`${field}_city`])
		if (data[`${field}_state`]) {
			value += ', '
		} else {
			if (data[`${field}_postal_code`]) {
				value += ' '
			}
		}
	}
	if (data[`${field}_state`]) {
		value += TrimWhitespace(data[`${field}_state`])
		if (data[`${field}_postal_code`]) {
			value += ' '
		}
	}
	if (data[`${field}_postal_code`]) {
		value += TrimWhitespace(data[`${field}_postal_code`])
	}
	if (data[`${field}_country`]) {
		if (value) {
			value += '\r\n'
		}
		value += countryNames[data[`${field}_country`]]
	}

	return value.replaceAll('\r\n\r\n', '\r\n').replaceAll('\n\n', '\n')
}
