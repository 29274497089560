import { useReactiveVar } from '@apollo/client'
import axios from 'axios'
import { MouseEvent, useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'

import DownArrowIcon from '../../../../assets/icons/arrow-down.svg'
import CopyIcon from '../../../../assets/icons/copy.svg'
import DeleteIcon from '../../../../assets/icons/delete.svg'
import InboxIcon from '../../../../assets/icons/inbox.svg'
import UserIcon from '../../../../assets/icons/new-user.svg'
import UserHistoryIcon from '../../../../assets/icons/organization-history.svg'
import { EditForm, ParsedFormData } from '../../../../components/Form/EditForm'
import { OrganizationSummary } from '../../../../components/OrganizationSummary'
import { PartnerSummary } from '../../../../components/PartnerSummary'
import { GlobalTopTabItems } from '../../../../components/Tabs'
import { DefaultLayout } from '../../../../layouts/DefaultLayout'
import {
	APIHostname,
	HasPermission,
	HasRole,
	LogOff,
	ProfileEmail,
	ProfilePartnerID,
	ProfilePartnerName,
	ProfileUserID,
	RenewToken,
} from '../../../../Profile'
import { EditUserLayout } from '../../../../types/data/User'
import styles from './User.module.scss'

export const User = () => {
	const { id } = useParams()

	let UserBottomTabItems = [
		{
			icon: DownArrowIcon,
			text: `USER #${id}`,
			to: `/users/${id}`,
		},
	]

	const userProfileID = useReactiveVar(ProfileUserID)
	if (id == userProfileID.toString()) {
		UserBottomTabItems = [
			{
				icon: DownArrowIcon,
				text: 'MY PROFILE',
				to: '/users',
			},
		]
	}
	const [user, setUser]: any = useState(null)
	const [userLoaded, setUserLoaded] = useState(false)
	const [organization, setOrganization]: any = useState(null)
	const [partner, setPartner]: any = useState(null)
	const [organizationLoaded, setOrganizationLoaded] = useState(false)
	const [partnerLoaded, setPartnerLoaded] = useState(false)
	const [errorMessage, setErrorMessage] = useState(<></>)
	const initialRedirectMessage = sessionStorage['redirect-message']
	const [redirectMessage] = useState(initialRedirectMessage)

	// Vary based on query string.
	const location = useLocation()

	useEffect(() => {
		axios({
			data: {},
			headers: {
				Authorization: `Bearer ${localStorage['token']}`,
			},
			method: 'GET',
			url: `${APIHostname}/api/v1/users/${id}${location.search}`,
		})
			.then((response: any) => {
				const user = response.data
				switch (user.role) {
				case 'administrator':
					user.organization = -1
					user.organization_name = '()'
					user.partner = -1
					user.partner_name = '()'
					break
				case 'organization':
					user.partner = -1
					user.partner_name = '()'
					break
				case 'memberpartner':
				case 'nonmemberpartner':
				case 'partner':
					if (!user.partner) {
						user.role = ''
						if (ProfilePartnerID()) {
							user.partner = ProfilePartnerID()
							user.partner_name = ProfilePartnerName()
						} else {
							user.partner = -1
							user.partner_name = '()'
						}
					}
					user.organization = -1
					user.organization_name = '()'
					break
				default:
					user.organization = -1
					user.organization_name = '()'
					if (ProfilePartnerID()) {
						user.partner = ProfilePartnerID()
						user.partner_name = ProfilePartnerName()
					} else {
						user.partner = -1
						user.partner_name = '()'
					}
					break
				}

				setErrorMessage(<></>)
				setUser(user)
				setUserLoaded(true)

				axios({
					data: {},
					headers: {
						Authorization: `Bearer ${localStorage['token']}`,
					},
					method: 'GET',
					url: `${APIHostname}/api/v1/organizations/${user.organization}`,
				})
					.then((response: any) => {
						setOrganization(response.data)
						setOrganizationLoaded(true)
					})
					.catch(() => {
						setOrganizationLoaded(true)
					})

				axios({
					data: {},
					headers: {
						Authorization: `Bearer ${localStorage['token']}`,
					},
					method: 'GET',
					url: `${APIHostname}/api/v1/partners/${user.partner}`,
				})
					.then((response: any) => {
						setPartner(response.data)
						setPartnerLoaded(true)
					})
					.catch(() => {
						setPartnerLoaded(true)
					})
			})
			.catch((error: any) => {
				switch (error?.response?.status) {
				case 401:
					LogOff('token-expired')
					return
				case 403:
					setErrorMessage(
						<>
								Error: You do not have permission to view users.
						</>
					)
					break
				default:
					if (error?.response?.data?.error) {
						setErrorMessage(
							error?.response?.data?.link ? (
								<Link to={error?.response?.data?.link}>
										Error:
									{error?.response?.data?.error}
								</Link>
							) : (
								<>{error?.response?.data?.error}</>
							)
						)
					} else {
						setErrorMessage(<>Error: Unknown error.</>)
							console.error(error) // eslint-disable-line
					}
				}
			})
	}, [location])

	const UserTabMenuItems = [
		{
			icon: CopyIcon,
			onClick: async (e: MouseEvent<Element>) => {
				e.preventDefault()

				// eslint-disable-next-line
				const confirmation = confirm(`Really copy user?`)
				if (!confirmation) {
					return
				}

				const form = document.querySelector('form[name=\'user\']')
				const formData = new FormData(form as HTMLFormElement)
				const payload = await ParsedFormData(formData, [])
				payload.copy = true
				payload.permissions = {}
				const permissions = document.querySelectorAll(
					'input[type=checkbox]'
				)
				permissions.forEach((permission: Element) => {
					const permissionBox = permission as HTMLInputElement
					payload.permissions[permissionBox.name] =
						permissionBox.checked
				})
				axios({
					data: payload,
					headers: {
						Authorization: `Bearer ${localStorage['token']}`,
						'Content-Type': 'application/json',
					},
					method: 'POST',
					url: `${APIHostname}/api/v1/users`,
				})
					.then((response: any) => {
						sessionStorage[
							'redirect-message'
						] = `User #${response.data.user_id} created.`
						window.history.replaceState(
							{},
							'',
							`/users/${response.data.user_id}`
						)
						document.location.reload()
					})
					.catch((error: any) => {
						switch (error?.response?.status) {
						case 401:
							LogOff('token-expired')
							return
						case 403:
							setErrorMessage(
								<>
										Error: You do not have permission to
										edit users.
								</>
							)
							break
						default:
							if (error?.response?.data?.error) {
								setErrorMessage(
									error?.response?.data?.link ? (
										<Link
											to={error?.response?.data?.link}
										>
												Error:
											{error?.response?.data?.error}
										</Link>
									) : (
										<>{error?.response?.data?.error}</>
									)
								)
							} else {
								setErrorMessage(<>Error: Unknown error.</>)
									console.error(error) // eslint-disable-line
							}
						}
					})
			},
			requiredPermissions: ['user.create'],
			text: 'COPY',
			to: '#',
		},
	]
	if (user?.active) {
		UserTabMenuItems.push({
			icon: DeleteIcon,
			onClick: async (e: MouseEvent<Element>) => {
				e.preventDefault()

				// eslint-disable-next-line
				const confirmation = confirm(`Really delete user?`)
				if (!confirmation) {
					return
				}

				axios({
					headers: {
						Authorization: `Bearer ${localStorage['token']}`,
						'Content-Type': 'application/json',
					},
					method: 'DELETE',
					url: `${APIHostname}/api/v1/users/${id}`,
				})
					.then((response: any) => {
						sessionStorage[
							'redirect-message'
						] = `User #${response.data.user_id} deleted.`
						window.history.replaceState({}, '', '/users')
						document.location.reload()
					})
					.catch((error: any) => {
						switch (error?.response?.status) {
						case 401:
							LogOff('token-expired')
							return
						case 403:
							setErrorMessage(
								<>
										Error: You do not have permission to
										delete users.
								</>
							)
							break
						default:
							if (error?.response?.data?.error) {
								setErrorMessage(
									error?.response?.data?.link ? (
										<Link
											to={error?.response?.data?.link}
										>
												Error:
											{error?.response?.data?.error}
										</Link>
									) : (
										<>{error?.response?.data?.error}</>
									)
								)
							} else {
								setErrorMessage(<>Error: Unknown error.</>)
									console.error(error) // eslint-disable-line
							}
						}
					})
			},
			requiredPermissions: ['user.delete'],
			text: 'DELETE',
			to: '#',
		})
	} else {
		UserTabMenuItems.push({
			icon: DeleteIcon,
			onClick: async (e: MouseEvent<Element>) => {
				e.preventDefault()

				// eslint-disable-next-line
				const confirmation = confirm(`Really undelete user?`)
				if (!confirmation) {
					return
				}

				axios({
					headers: {
						Authorization: `Bearer ${localStorage['token']}`,
						'Content-Type': 'application/json',
					},
					method: 'POST',
					url: `${APIHostname}/api/v1/users/${id}/undelete`,
				})
					.then((response: any) => {
						sessionStorage[
							'redirect-message'
						] = `User #${response.data.user_id} undeleted.`
						window.history.replaceState({}, '', '/users')
						document.location.reload()
					})
					.catch((error: any) => {
						switch (error?.response?.status) {
						case 401:
							LogOff('token-expired')
							return
						case 403:
							setErrorMessage(
								<>
										Error: You do not have permission to
										undelete users.
								</>
							)
							break
						default:
							if (error?.response?.data?.error) {
								setErrorMessage(
									error?.response?.data?.link ? (
										<Link
											to={error?.response?.data?.link}
										>
												Error:
											{error?.response?.data?.error}
										</Link>
									) : (
										<>{error?.response?.data?.error}</>
									)
								)
							} else {
								setErrorMessage(<>Error: Unknown error.</>)
									console.error(error) // eslint-disable-line
							}
						}
					})
			},
			requiredPermissions: ['user.delete'],
			text: 'UNDELETE',
			to: '#',
		})
	}
	UserTabMenuItems.reverse()

	const UserLeftMenuItems = [
		{
			icon: UserIcon,
			requiredPermissions: ['user.read'],
			text: 'USER INFO',
			to: `/users/${id}`,
		},
		{
			icon: UserIcon,
			requireDeveloperMode: true,
			requiredPermissions: ['user.generateapitoken'],
			text: 'USER API TOKENS',
			to: `/users/${id}/api`,
		},
		{
			icon: InboxIcon,
			requiredPermissions: ['user.read'],
			text: 'USER NOTIFICATIONS',
			to: `/users/${id}/notifications`,
		},
		{
			icon: UserHistoryIcon,
			requiredPermissions: ['user.read'],
			text: 'USER HISTORY',
			to: `/users/${id}/history`,
		},
	]

	if (!userLoaded || !organizationLoaded || !partnerLoaded) {
		if (errorMessage) {
			return (
				<DefaultLayout
					bottomTabItems={UserBottomTabItems}
					leftMenuItems={UserLeftMenuItems}
					showPrint={true}
					tabMenuItems={UserTabMenuItems}
					topTabItems={GlobalTopTabItems}
				>
					<div className={styles.userContainer}>
						<div className={styles.redirectMessage}>
							{redirectMessage}
						</div>
						<div className={styles.errorMessage}>
							{errorMessage}
						</div>
					</div>
				</DefaultLayout>
			)
		}

		return <div className="loading">Loading...</div>
	}

	sessionStorage.removeItem('redirect-message')

	let css = <></>
	if (user.role && !HasRole('administrator')) {
		css = (
			<style>
				{`
				.role, .organization, .partner {
					display: none;
				}
			`}
			</style>
		)
	}

	let readOnly = false
	readOnly = !HasPermission('user', 'update') || !user?.active
	if (readOnly && parseInt(id ?? '0') == ProfileUserID()) {
		readOnly = !HasPermission('user', 'updateself')
	}
	return (
		<DefaultLayout
			bottomTabItems={UserBottomTabItems}
			leftMenuItems={UserLeftMenuItems}
			showPrint={true}
			tabMenuItems={UserTabMenuItems}
			topTabItems={GlobalTopTabItems}
		>
			{css}
			<div className={styles.userContainer}>
				{organization ? (
					<OrganizationSummary organization={organization} />
				) : (
					<></>
				)}
				{partner ? <PartnerSummary partner={partner} /> : <></>}

				<div className={styles.redirectMessage}>{redirectMessage}</div>
				<div className={styles.errorMessage}>
					{errorMessage}{' '}
					{!user?.active ? <>This user has been deleted.</> : <></>}
				</div>

				<EditForm
					data={user}
					layout={EditUserLayout}
					name="user"
					onSubmit={async (formData: FormData) => {
						const payload = await ParsedFormData(formData, [])
						payload.permissions = {}
						const permissions = document.querySelectorAll(
							'input[type=checkbox]'
						)
						permissions.forEach((permission: Element) => {
							const permissionBox = permission as HTMLInputElement
							if (permissionBox.name.indexOf('.') > -1) {
								if (payload[permissionBox.name] == true) {
									payload.permissions[permissionBox.name] =
										true
								}
								delete payload[permissionBox.name]
							}
						})
						axios({
							data: payload,
							headers: {
								Authorization: `Bearer ${localStorage['token']}`,
								'Content-Type': 'application/json',
							},
							method: 'POST',
							url: `${APIHostname}/api/v1/users/${id}`,
						})
							.then(async () => {
								if (parseInt(id ?? '0') == ProfileUserID()) {
									await RenewToken(
										ProfileEmail(),
										localStorage['remember-me'] == 'true'
									)
								}
								sessionStorage[
									'redirect-message'
								] = `User #${id} updated.`
								window.history.replaceState({}, '', '/users')
								document.location.reload()
							})
							.catch((error: any) => {
								switch (error?.response?.status) {
								case 401:
									LogOff('token-expired')
									return
								case 403:
									setErrorMessage(
										<>
												Error: You do not have
												permission to view users.
										</>
									)
									break
								default:
									if (error?.response?.data?.error) {
										setErrorMessage(
											error?.response?.data?.link ? (
												<Link
													to={
														error?.response
															?.data?.link
													}
												>
														Error:
													{
														error?.response
															?.data?.error
													}
												</Link>
											) : (
												<>
													{
														error?.response
															?.data?.error
													}
												</>
											)
										)
									} else {
										setErrorMessage(
											<>Error: Unknown error.</>
										)
											console.error(error) // eslint-disable-line
									}
								}
							})
					}}
					previewAPIData={async (formData: FormData) => {
						const payload = await ParsedFormData(formData, [])
						payload.permissions = {}
						const permissions = document.querySelectorAll(
							'input[type=checkbox]'
						)
						permissions.forEach((permission: Element) => {
							const permissionBox = permission as HTMLInputElement
							if (permissionBox.name.indexOf('.') > -1) {
								if (payload[permissionBox.name] == true) {
									payload.permissions[permissionBox.name] =
										true
								}
								delete payload[permissionBox.name]
							}
						})

						return {
							data: JSON.stringify(payload, null, 4),
							method: 'POST',
							url: `${APIHostname}/api/v1/users/${id}`,
						}
					}}
					readOnly={readOnly}
				/>
			</div>{' '}
		</DefaultLayout>
	)
}
