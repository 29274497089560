import Control from '../Control'
import { Layout } from '../Layout'

export type User = {
	ID: number
	ParentID: number
	CompanyCode: string

	id: number
	email: string
	name: string
	role: string
}

export const NewUserLayout: Layout = {
	Groups: [
		{
			Direction: 'row',
			Flex: '1 1 0%',
			Groups: [
				{
					Border: true,
					Direction: 'column',
					OptionalRows: [
						{
							Fields: [
								{
									Control: Control.Email,
									HardRequired: true,
									Label: 'EMAIL',
									Property: 'email',
									Width: 100,
								},
							],
						},
						{
							Fields: [
								{
									Control: Control.NameComponent,
									HardRequired: true,
									Label: 'FIRST NAME',
									Property: 'first_name',
									Width: 50,
								},
								{
									Control: Control.NameComponent,
									HardRequired: true,
									Label: 'LAST NAME',
									Property: 'last_name',
									Width: 50,
								},
							],
						},
						{
							Fields: [
								{
									Control: Control.Calculation,
									Label: 'NAME',
									Property: 'name',
									Width: 50,
								},
								{
									Label: 'TITLE',
									Property: 'title',
									SoftRequired: true,
									Width: 50,
								},
							],
						},
						{
							Fields: [
								{
									Control: Control.ImageFile,
									Label: 'PROFILE PICTURE',
									Property: 'profile_picture',
									Width: 100,
								},
							],
						},
						{
							Fields: [
								{
									Control: Control.Checkbox,
									Label: 'DEVELOPER MODE:',
									Property: 'developer_mode',
									RequiredRole:
										'administrator||memberpartner||partner',
									Width: 100,
								},
							],
						},
					],
					Width: 100,
				},
				{
					Border: true,
					RequiredRows: [
						{
							Fields: [
								{
									Control: Control.Role,
									HardRequired: true,
									Label: 'ROLE',
									Property: 'role',
									Width: 100,
								},
							],
						},
						{
							Fields: [
								{
									Control: Control.Partner,
									HardRequired: true,
									Label: 'PARTNER',
									Property: 'partner',
									RequiredValues: {
										role: 'administrator||memberpartner||nonmemberpartner||partner',
									},
									Width: 100,
								},
								{
									Control: Control.Organization,
									HardRequired: true,
									Label: 'ORGANIZATION',
									Property: 'organization',
									RequiredValues: {
										role: 'organization',
									},
									Width: 100,
								},
							],
						},
						{
							Fields: [
								{
									Control: Control.TextBox,
									Label: 'NOTES',
									Property: 'notes',
									Width: 100,
								},
							],
						},
					],
					Width: 100,
				},
			],
			Margin: '0 0 1rem 0',
			Padding: '1rem 0 0 0',
		},
		{
			Groups: [
				{
					Border: true,
					IncludeInSearch: false,
					Label: 'PASSWORD',
					LabelBackground: 'blue',
					RequiredRows: [
						{
							Fields: [
								{
									Control: Control.Password,
									HardRequired: true,
									Label: 'PASSWORD',
									Property: 'password',
									Width: 25,
								},
								{
									Control: Control.Password,
									HardRequired: true,
									Label: 'CONFIRM PASSWORD',
									Property: 'confirm_password',
									Width: 25,
								},
								{
									Label: '',
									Property: '',
									Width: 25,
								},
								{
									Label: '',
									Property: '',
									Width: 25,
								},
							],
						},
					],
					Width: 100,
				},
			],
			Width: 100,
		},
		{
			Groups: [
				{
					Border: true,
					IncludeInSearch: false,
					Label: 'PERMISSIONS',
					LabelBackground: 'blue',
					RequiredPermissions: ['user.managepermissions'],
					RequiredRole: 'administrator||memberpartner||partner',
					RequiredRows: [
						{
							Fields: [
								{
									Control: Control.Permissions,
									Label: '',
									Property: 'permissions',
									Width: 100,
								},
							],
						},
					],
					Width: 100,
				},
			],
			Padding: '0 0 1rem 0',
			RequiredPermissions: ['user.managepermissions'],
			Width: 100,
		},
		{
			Groups: [
				{
					Border: true,
					IncludeInSearch: false,
					Label: 'SEND WELCOME EMAIL',
					LabelBackground: 'blue',
					RequiredRows: [
						{
							Fields: [
								{
									Control: Control.Checkbox,
									Label: 'SEND WELCOME EMAIL',
									Property: 'send_welcome_email',
									Width: 100,
								},
							],
						},
					],
					Width: 100,
				},
			],
			Width: 100,
		},
	],
}

export const EditUserLayout: Layout = {
	Groups: [
		{
			Direction: 'row',
			Flex: '1 1 0%',
			Groups: [
				{
					Border: true,
					Direction: 'column',
					OptionalRows: [
						{
							Fields: [
								{
									Control: Control.Email,
									HardRequired: true,
									Label: 'EMAIL',
									Property: 'email',
									Width: 100,
								},
							],
						},
						{
							Fields: [
								{
									Control: Control.NameComponent,
									HardRequired: true,
									Label: 'FIRST NAME',
									Property: 'first_name',
									Width: 50,
								},
								{
									Control: Control.NameComponent,
									HardRequired: true,
									Label: 'LAST NAME',
									Property: 'last_name',
									Width: 50,
								},
							],
						},
						{
							Fields: [
								{
									Control: Control.Calculation,
									Label: 'NAME',
									Property: 'name',
									Width: 50,
								},
								{
									Label: 'TITLE',
									Property: 'title',
									SoftRequired: true,
									Width: 50,
								},
							],
						},
						{
							Fields: [
								{
									Control: Control.ImageFile,
									Label: 'PROFILE PICTURE',
									Property: 'profile_picture',
									Width: 100,
								},
							],
						},
						{
							Fields: [
								{
									Control: Control.Checkbox,
									Label: 'DEVELOPER MODE:',
									Property: 'developer_mode',
									RequiredRole:
										'administrator||memberpartner||partner',
									Width: 100,
								},
							],
						},
					],
					Width: 100,
				},
				{
					Border: true,
					RequiredRows: [
						{
							Fields: [
								{
									Control: Control.Role,
									HardRequired: true,
									Label: 'ROLE',
									Property: 'role',
									Width: 100,
								},
							],
						},
						{
							Fields: [
								{
									Control: Control.Partner,
									HardRequired: true,
									Label: 'PARTNER',
									Property: 'partner',
									RequiredValues: {
										role: 'administrator||memberpartner||nonmemberpartner||partner',
									},
									Width: 100,
								},
								{
									Control: Control.Organization,
									HardRequired: true,
									Label: 'ORGANIZATION',
									Property: 'organization',
									RequiredValues: {
										role: 'organization',
									},
									Width: 100,
								},
							],
						},
						{
							Fields: [
								{
									Label: 'USER-PROVIDED COMPANY NAME',
									Property: 'company',
									ReadOnly: true,
									Width: 100,
								},
							],
						},
						{
							Fields: [
								{
									Control: Control.Partner,
									Label: 'REGISTRATION PARTNER',
									Property: 'registration_partner',
									ReadOnly: true,
									Width: 100,
								},
							],
						},
						{
							Fields: [
								{
									Control: Control.TextBox,
									Label: 'NOTES',
									Property: 'notes',
									Width: 100,
								},
							],
						},
						{
							Fields: [
								{
									Control: Control.Boolean,
									Label: 'EMAIL VERIFIED',
									Property: 'email_verified',
									ReadOnly: true,
									RequiredRole: 'administrator',
									Width: 50,
								},
								{
									Control: Control.Boolean,
									Label: 'APPROVED',
									Property: 'approved',
									RequiredPermissions: ['user.create'],
									Width: 50,
								},
							],
						},
						{
							Fields: [
								{
									Control: Control.Date,
									Label: 'CREATION DATE',
									Property: 'created',
									ReadOnly: true,
									Width: 33,
								},
								{
									Control: Control.Date,
									Label: 'MODIFIED DATE',
									Property: 'modified',
									ReadOnly: true,
									Width: 33,
								},
								{
									Control: Control.Number,
									Label: 'VERSION',
									Property: 'version',
									ReadOnly: true,
									Width: 33,
								},
							],
						},
						{
							Fields: [
								{
									Control: Control.DateTime,
									Label: 'LAST LOGIN',
									Property: 'last_login',
									ReadOnly: true,
									Width: 33,
								},
								{
									Control: Control.DateTime,
									Label: 'LAST TOKEN REFRESH',
									Property: 'last_token_refresh',
									ReadOnly: true,
									Width: 33,
								},
								{
									Control: Control.DateTime,
									Label: 'LAST PASSWORD CHANGE',
									Property: 'last_password_change',
									ReadOnly: true,
									Width: 33,
								},
							],
						},
					],
					Width: 100,
				},
			],
			Margin: '0 0 1rem 0',
			Padding: '1rem 0 0 0',
		},
		{
			Groups: [
				{
					Border: true,
					IncludeInSearch: false,
					Label: 'PASSWORD',
					LabelBackground: 'blue',
					RequiredRows: [
						{
							Fields: [
								{
									Control: Control.Password,
									Label: 'NEW PASSWORD',
									Property: 'password',
									Width: 25,
								},
								{
									Control: Control.Password,
									Label: 'CONFIRM NEW PASSWORD',
									Property: 'confirm_password',
									Width: 25,
								},
								{
									Label: '',
									Property: '',
									Width: 25,
								},
								{
									Label: '',
									Property: '',
									Width: 25,
								},
							],
						},
					],
					Width: 100,
				},
			],
			Width: 100,
		},
		{
			Groups: [
				{
					Border: true,
					IncludeInSearch: false,
					Label: 'PERMISSIONS',
					LabelBackground: 'blue',
					RequiredPermissions: ['user.managepermissions'],
					RequiredRole: 'administrator||memberpartner||partner',
					RequiredRows: [
						{
							Fields: [
								{
									Control: Control.Permissions,
									Label: '',
									Property: 'permissions',
									Width: 100,
								},
							],
						},
					],
					Width: 100,
				},
			],
			Padding: '0 0 1rem 0',
			RequiredPermissions: ['user.managepermissions'],
			Width: 100,
		},
	],
}

export const SearchUserLayout: Layout = {
	Groups: [
		{
			Border: true,
			Margin: '1rem 0 0 0',
			OptionalRows: [
				{
					Fields: [
						{
							Label: 'NAME',
							Property: 'name',
							Width: 50,
						},
						{
							Control: Control.Date,
							Label: 'CREATION DATE',
							Property: 'created',
							ReadOnly: true,
							Width: 25,
						},
						{
							Control: Control.Date,
							Label: 'LAST LOGIN',
							Property: 'last_login',
							ReadOnly: true,
							Width: 25,
						},
					],
				},
				{
					Fields: [
						{
							Control: Control.NameComponent,
							Label: 'FIRST NAME',
							Property: 'first_name',
							Width: 25,
						},
						{
							Control: Control.NameComponent,
							Label: 'LAST NAME',
							Property: 'last_name',
							Width: 25,
						},
						{
							Label: 'TITLE',
							Property: 'title',
							Width: 25,
						},
						{
							Control: Control.Email,
							Label: 'EMAIL',
							Property: 'email',
							Width: 25,
						},
					],
				},
				{
					Fields: [
						{
							Label: 'USER-PROVIDED COMPANY NAME',
							Property: 'company',
							Width: 25,
						},
						{
							Control: Control.Role,
							HardRequired: true,
							Label: 'ROLE',
							Property: 'role',
							Width: 25,
						},
						{
							Control: Control.Partner,
							Label: 'PARTNER',
							Property: 'partner',
							RequiredValues: {
								role: 'administrator||memberpartner||nonmemberpartner||partner',
							},
							Width: 50,
						},
						{
							Control: Control.Organization,
							Label: 'ORGANIZATION',
							Property: 'organization',
							RequiredValues: {
								role: 'organization',
							},
							Width: 50,
						},
						{
							Label: '',
							Property: '',
							RequiredValues: {
								role: 'administrator',
							},
							Width: 50,
						},
					],
				},
				{
					Fields: [
						{
							Control: Control.Active,
							IncludeInEdit: false,
							Label: 'INCLUDE',
							Property: 'active',
							Width: 100,
						},
						{
							Control: Control.Boolean,
							Label: 'EMAIL VERIFIED',
							Property: 'email_verified',
							ReadOnly: true,
							RequiredRole: 'administrator',
							Width: 25,
						},
						{
							Control: Control.Boolean,
							Label: 'APPROVED',
							Property: 'approved',
							RequiredPermissions: ['user.create'],
							Width: 25,
						},
					],
				},
			],
			RequiredRows: [
				{
					Fields: [
						{
							IncludeInEdit: false,
							IncludeInSearch: true,
							Label: 'WILDCARD (SEARCH ALL FIELDS; USE "OR" FOR MULTIPLE CRITERIA)',
							Property: '*',
							Width: 100,
						},
					],
				},
			],
			Width: 100,
		},
		{
			IncludeInSearch: false,
			Label: 'PASSWORD',
			LabelBackground: 'blue',
			RequiredRows: [
				{
					Fields: [
						{
							Control: Control.Password,
							Label: 'CURRENT PASSWORD',
							Property: 'password',
							Width: 25,
						},
						{
							Control: Control.Password,
							Label: 'NEW PASSWORD',
							Property: 'new_password',
							Width: 25,
						},
						{
							Control: Control.Password,
							Label: 'CONFIRM NEW PASSWORD',
							Property: 'new_password_2',
							Width: 25,
						},
					],
				},
			],
			Width: 100,
		},
	],
}
