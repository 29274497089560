import Control from '../Control'
import { Layout } from '../Layout'

export const NonMemberPartnerBillingLayout: Layout = {
	Groups: [
		{
			Border: true,
			Direction: 'column',
			Groups: [
				{
					Direction: 'row',
					Flex: '1 1 0%',
					Groups: [
						{
							RequiredRows: [
								{
									Fields: [
										{
											Control: Control.Partner,
											HardRequired: true,
											Label: 'PARTNER',
											Property: 'partner',
											SubType: 'memberpartner',
											Width: 50,
										},
									],
								},
								{
									Fields: [
										{
											Control: Control.Date,
											HardRequired: true,
											Label: 'START DATE',
											Property: 'start_date',
											Width: 50,
										},
										{
											Control: Control.Date,
											HardRequired: true,
											Label: 'END DATE',
											Property: 'end_date',
											Width: 50,
										},
									],
								},
							],
							Width: 100,
						},
					],
				},
			],
			Width: 100,
		},
	],
}
