import Control from './Control'

export type Field = {
	AutoComplete?: boolean
	AutoFocus?: boolean
	Control?: Control
	Default?: string
	HardRequired?: boolean
	IncludeInEdit?: boolean
	IncludeInSearch?: boolean
	Label?: string
	MaxLength?: number
	MinLength?: number
	Multiple?: boolean
	Notes?: string
	Nullable?: boolean
	Property: string
	Properties?: string[]
	ReadOnly?: boolean
	RequiredPermissions?: string[]
	RequiredRole?: string
	RequiredValues?: { [name: string]: string }
	RowSpan?: number
	SearchOperator?: string
	SoftRequired?: boolean
	SubType?: string
	Width?: 5 | 10 | 12.5 | 20 | 22.5 | 25 | 33 | 50 | 75 | 100
}

export const SearchOperator = {
	Contains: 'contains',
	Equals: 'eq',
	GreaterThan: 'gt',
	GreaterThanOrEqual: 'gte',
	LessThan: 'lt',
	LessThanOrEqual: 'lte',
	NotContains: 'notcontains',
	NotEqual: 'neq',
}
