import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'

import { APIHostname } from '../../../../Profile'
import { Unit } from '../../../../types/data/Unit'
import { FieldProps } from '../../Field'

export const EditUnit = (props: FieldProps) => {
	const [value, setValue] = useState(props.value)

	const { isPending, error, data } = useQuery({
		queryFn: () =>
			fetch(
				`${APIHostname}/api/v1/units?sorts=[{"field":"description","order":"ASC"}]`,
				{
					headers: {
						Authorization: `Bearer ${localStorage['token']}`,
					},
				}
			).then((response) => response.json()),
		queryKey: ['units'],
		refetchInterval: 0,
		refetchOnWindowFocus: false,
	})

	if (error) {
		console.error(error) // eslint-disable-line
	}

	if (isPending) {
		return <></>
	}

	if (props.readOnly || props.field.ReadOnly) {
		return (
			<input
				data-hardrequired={props.field.HardRequired && !props.isSearch}
				data-softrequired={props.field.SoftRequired && !props.isSearch}
				defaultValue={
					data.units.filter((unit: any) => {
						return unit.id == props.value
					})[0]?.description
				}
				readOnly={true}
				title={props.field.Label?.replace(':', '')}
				type="text"
			/>
		)
	}

	return (
		<select
			className={`${
				props.field.HardRequired && !props.isSearch
					? 'hardRequired'
					: ''
			} ${
				props.field.SoftRequired && !props.isSearch
					? 'softRequired'
					: ''
			}`}
			data-hardrequired={props.field.HardRequired && !props.isSearch}
			data-nullable={props.field.Nullable}
			data-number={true}
			data-softrequired={props.field.SoftRequired && !props.isSearch}
			id={props.field.Property}
			name={props.field.Property}
			onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
				setValue(e.target.value)
				if (props.onChange) {
					props.onChange(e)
				}
			}}
			required={props.field.HardRequired && !props.isSearch}
			title={props.field.Label?.replace(':', '')}
			value={value}
		>
			<option value=""></option>
			{data.units?.map((unit: Unit, index: number) => (
				<option key={`unit${index}`} value={unit.id}>
					{unit.description}
				</option>
			))}
		</select>
	)
}
