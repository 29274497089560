import { makeVar } from '@apollo/client'
import axios from 'axios'
import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'

import { APIHostname } from './Profile'

export const LogInLeft = makeVar('')
export const LogInRight = makeVar('')

export const ApplyTheme = () => {
	useEffect(() => {
		axios(`${APIHostname}/api/v1/theme`)
			.then((response: any) => {
				// Head
				const logo = document.getElementById(
					'themeLogo'
				) as HTMLImageElement
				if (response.data.head.title) {
					document.title = response.data.head.title
					if (logo) {
						logo.alt = response.data.head.title
						logo.title = response.data.head.title
					}
				}
				if (response.data.head.stylesheet) {
					let styleLink = document.getElementById('stylesheet')
					if (styleLink) {
						styleLink.setAttribute(
							'href',
							response.data.head.stylesheet
						)
					} else {
						styleLink = document.createElement('link')
						styleLink.setAttribute('rel', 'stylesheet')
						styleLink.setAttribute(
							'href',
							response.data.head.stylesheet
						)
						styleLink.setAttribute('type', 'text/css')
						styleLink.id = 'stylesheet'
						document.head.appendChild(styleLink)
					}
				}

				// Body
				if (response.data.body.logo && logo) {
					logo.src = response.data.body.logo
				}
				document.body.classList.remove('loading')
			})
			.catch((e) => {
				document.body.classList.remove('loading')

				// eslint-disable-next-line no-console
				console.error('ERROR:', e)
			})
	}, [])

	return <Outlet />
}
