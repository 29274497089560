import styles from './Permissions.module.scss'

export const EditPermissions = (props: PermissionsProps) => {
	const types: objectType[] = [
		{
			actions: {
				create: 'role-administrator-partner',
				delete: 'role-administrator-partner',
				read: 'role-administrator-partner',
				update: 'role-administrator-partner',
			},
			name: 'arrival_notice',
			requiredRoles: ['administrator', 'partner'],
			title: 'Arrival Notices',
		},
		{
			actions: {
				create: 'role-administrator-partner',
				delete: 'role-administrator-partner',
				read: 'role-administrator-partner',
				update: 'role-administrator-partner',
			},
			name: 'carrier',
			requiredRoles: ['administrator'],
			title: 'Carriers',
		},
		{
			actions: {
				create: 'role-administrator-partner',
				delete: 'role-administrator-partner',
				read: 'role-administrator-partner',
				update: 'role-administrator-partner',
			},
			name: 'nra',
			requiredRoles: ['administrator', 'partner'],
			title: 'NRAs',
		},
		{
			actions: {
				create: 'role-administrator-partner',
				delete: 'role-administrator-partner',
				manageaccess: 'role-administrator-partner',
				read: 'role-all',
				update: 'role-administrator-partner',
			},
			name: 'organization',
			requiredRoles: ['administrator', 'organization', 'partner'],
			title: 'Organizations',
		},
		{
			actions: {
				create: 'role-administrator',
				delete: 'role-administrator',
				manageaccess: 'role-administrator',
				read: 'role-all',
				update: 'role-administrator',
			},
			name: 'partner',
			requiredRoles: ['administrator', 'partner'],
			title: 'Partners',
		},
		{
			actions: {
				read: 'role-administrator-partner',
			},
			name: 'report',
			requiredRoles: ['administrator', 'partner'],
			title: 'Reports',
		},
		{
			actions: {
				create: 'role-administrator-partner',
				delete: 'role-administrator-partner',
				read: 'role-all',
				update: 'role-administrator-partner',
			},
			name: 'shipment',
			requiredRoles: ['administrator', 'organization', 'partner'],
			title: 'Shipments',
		},
		{
			actions: {
				create: 'role-administrator-partner',
				delete: 'role-administrator-partner',
				managepermissions: 'role-administrator-partner',
				read: 'role-all',
				setpasswords: 'role-administrator-partner',
				update: 'role-administrator-partner',
			},
			name: 'user',
			requiredRoles: ['administrator', 'organization', 'partner'],
			title: 'Users',
		},
	]

	/* eslint-disable sort-keys */
	const templates = {
		'Read-Only': [
			'arrival_notice.read',
			'carrier.read',
			'nra.read',
			'organization.read',
			'partner.read',
			'report.read',
			'shipment.read',
			'user.read',
			'user.updateself',
		],
		'Power User': [
			'arrival_notice.read',
			'arrival_notice.create',
			'arrival_notice.update',
			'arrival_notice.delete',
			'carrier.read',
			'carrier.create',
			'carrier.update',
			'carrier.delete',
			'nra.read',
			'nra.create',
			'nra.update',
			'nra.delete',
			'organization.read',
			'organization.create',
			'organization.update',
			'organization.delete',
			'organization.manageaccess',
			'organization.updateself',
			'partner.manageaccess',
			'partner.read',
			'report.read',
			'shipment.read',
			'shipment.create',
			'shipment.update',
			'shipment.delete',
			'user.read',
			'user.create',
			'user.update',
			'user.delete',
			'user.managepermissions',
			'user.updateself',
			'user.generateapitoken',
		],
		Administrator: [],
		Custom: [],
	}

	let role = 'administrator-permissions'
	if (props.data?.organization > 0) {
		role = 'organization-permissions'
	}
	if (props.data?.partner > 0) {
		role = 'partner-permissions'
	}
	return (
		<div
			className={`permissionsContainer ${styles.permissionsContainer} ${role}`}
		>
			PERMISSION TEMPLATE{' '}
			<select
				id="permission_template"
				onChange={(e: any) => {
					const template: string = e.target.value!.toString() ?? ''

					const oldPermissions = document.querySelectorAll(
						'input[type=checkbox]'
					)
					switch (template) {
					case 'Read-Only':
					case 'Power User':
						oldPermissions.forEach((permission: Element) => {
							const permissionBox =
									permission as HTMLInputElement
							if (permissionBox.id.indexOf('.') > -1) {
								permissionBox.checked = false
							}
						})

						templates[template].forEach(
							(permission: string) => {
								const checkbox = document.getElementById(
									permission
								) as HTMLInputElement
								if (checkbox) {
									checkbox.checked = true
								}
							}
						)
						break
					case 'Administrator':
						document
							.querySelectorAll('input[type=checkbox]')
							.forEach((permission: Element) => {
								(permission as HTMLInputElement).checked =
										true
							})
						break
					case 'Custom':
						break
					}
				}}
				title="PERMISSION TEMPLATE"
			>
				<option value=""></option>
				{Object.keys(templates).map((key: string, index: number) => {
					return (
						<option key={index} value={key}>
							{key}
						</option>
					)
				})}
			</select>
			<h2>Object Permissions</h2>
			<table>
				<thead>
					<tr>
						<th></th>
						<th>READ</th>
						<th className="role-administrator-partner">CREATE</th>
						<th className="role-administrator-partner">UPDATE</th>
						<th className="role-administrator-partner">DELETE</th>
						<th className="role-administrator-partner">
							MANAGE PERMISSIONS
						</th>
						<th className="role-administrator-partner">
							SET PASSWORDS
						</th>
					</tr>
				</thead>
				<tbody>
					{types.map((type: objectType) => {
						return (
							<tr
								className={`role-${type.requiredRoles.join(
									'-'
								)}`}
								key={type.name}
							>
								<td>{type.title}</td>
								<td
									className={`${styles.checkbox} ${type.actions['read']}`}
								>
									{type.actions['read'] && (
										<input
											data-boolean={true}
											defaultChecked={
												props.permissions
													? props.permissions[
														`${type.name}.read`
													  ]
													: false
											}
											id={`${type.name}.read`}
											name={`${type.name}.read`}
											type="checkbox"
											value="true"
										/>
									)}
								</td>
								<td
									className={`${styles.checkbox} ${type.actions['create']}`}
								>
									{type.actions['create'] && (
										<input
											data-boolean={true}
											defaultChecked={
												props.permissions
													? props.permissions[
														`${type.name}.create`
													  ]
													: false
											}
											id={`${type.name}.create`}
											name={`${type.name}.create`}
											type="checkbox"
											value="true"
										/>
									)}
								</td>
								<td
									className={`${styles.checkbox} ${type.actions['update']}`}
								>
									{type.actions['update'] && (
										<input
											data-boolean={true}
											defaultChecked={
												props.permissions
													? props.permissions[
														`${type.name}.update`
													  ]
													: false
											}
											id={`${type.name}.update`}
											name={`${type.name}.update`}
											type="checkbox"
											value="true"
										/>
									)}
								</td>
								<td
									className={`${styles.checkbox} ${type.actions['delete']}`}
								>
									{type.actions['delete'] && (
										<input
											data-boolean={true}
											defaultChecked={
												props.permissions
													? props.permissions[
														`${type.name}.delete`
													  ]
													: false
											}
											id={`${type.name}.delete`}
											name={`${type.name}.delete`}
											type="checkbox"
											value="true"
										/>
									)}
								</td>
								{(type.name == 'organization' ||
									type.name == 'partner') && (
									<>
										<td
											className={`${styles.checkbox} ${type.actions['manageaccess']}`}
										>
											{type.actions['manageaccess'] && (
												<input
													data-boolean={true}
													defaultChecked={
														props.permissions
															? props.permissions[
																`${type.name}.manageaccess`
															  ]
															: false
													}
													id={`${type.name}.manageaccess`}
													name={`${type.name}.manageaccess`}
													type="checkbox"
													value="true"
												/>
											)}
										</td>
									</>
								)}

								{type.name == 'user' && (
									<>
										<td
											className={`${styles.checkbox} ${type.actions['managepermissions']}`}
										>
											{type.actions[
												'managepermissions'
											] && (
												<input
													data-boolean={true}
													defaultChecked={
														props.permissions
															? props.permissions[
																`${type.name}.managepermissions`
															  ]
															: false
													}
													id={`${type.name}.managepermissions`}
													name={`${type.name}.managepermissions`}
													type="checkbox"
													value="true"
												/>
											)}
										</td>
										<td
											className={`${styles.checkbox} ${type.actions['setpasswords']}`}
										>
											{type.actions['setpasswords'] && (
												<input
													data-boolean={true}
													defaultChecked={
														props.permissions
															? props.permissions[
																`${type.name}.setpasswords`
															  ]
															: false
													}
													id={`${type.name}.setpasswords`}
													name={`${type.name}.setpasswords`}
													type="checkbox"
													value="true"
												/>
											)}
										</td>
									</>
								)}
							</tr>
						)
					})}
				</tbody>
			</table>
			<h2>Profile Permissions</h2>
			<table>
				<thead>
					<tr>
						<th>
							<label htmlFor="user.updateself">
								UPDATE OWN PROFILE
							</label>
						</th>
						<th className="role-administrator-partner">
							{' '}
							<label htmlFor="user.generateapitoken">
								GENERATE API TOKENS
							</label>
						</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td className={styles.checkbox}>
							<input
								data-boolean={true}
								defaultChecked={
									props.permissions
										? props.permissions['user.updateself']
										: false
								}
								id={'user.updateself'}
								name={'user.updateself'}
								type="checkbox"
								value="true"
							/>
						</td>
						<td
							className={`${styles.checkbox} role-administrator-partner`}
						>
							<input
								data-boolean={true}
								defaultChecked={
									props.permissions
										? props.permissions[
											'user.generateapitoken'
										  ]
										: false
								}
								id={'user.generateapitoken'}
								name={'user.generateapitoken'}
								type="checkbox"
								value="true"
							/>
						</td>
					</tr>
				</tbody>
			</table>
			<div className="role-organization" id="permissions.organization">
				<h2>Organization Permissions</h2>
				<table>
					<thead>
						<tr>
							<th>
								<label htmlFor="organization.updateself">
									UPDATE OWN ORGANIZATION
								</label>
							</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td className={styles.checkbox}>
								<input
									data-boolean={true}
									defaultChecked={
										props.permissions
											? props.permissions[
												'organization.updateself'
											  ]
											: false
									}
									id={'organization.updateself'}
									name={'organization.updateself'}
									type="checkbox"
									value="true"
								/>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div
				className="role-administrator-partner"
				id="permissions.partner"
			>
				<h2>Partner Permissions</h2>
				<table>
					<thead>
						<tr>
							<th className="role-partner">
								<label htmlFor="partner.updateself">
									UPDATE OWN PARTNER
								</label>
							</th>
							<th>
								<label htmlFor="partner.updatetheme">
									UPDATE THEME
								</label>
							</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td className={`${styles.checkbox} role-partner`}>
								<input
									data-boolean={true}
									defaultChecked={
										props.permissions
											? props.permissions[
												'partner.updateself'
											  ]
											: false
									}
									id={'partner.updateself'}
									name={'partner.updateself'}
									type="checkbox"
									value="true"
								/>
							</td>
							<td className={styles.checkbox}>
								<input
									data-boolean={true}
									defaultChecked={
										props.permissions
											? props.permissions[
												'partner.updatetheme'
											  ]
											: false
									}
									id={'partner.updatetheme'}
									name={'partner.updatetheme'}
									type="checkbox"
									value="true"
								/>
							</td>
						</tr>
					</tbody>
				</table>
				<h2 className="role-partner">GOAL Pay Permissions</h2>
				<table className="role-partner">
					<thead>
						<tr>
							<th>
								<label htmlFor="pay.viewbalance">
									VIEW BALANCE
								</label>
							</th>
							<th>
								<label htmlFor="pay.requestmoney">
									REQUEST MONEY
								</label>
							</th>
							<th>
								<label htmlFor="pay.sendmoney">
									SEND MONEY
								</label>
							</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td className={styles.checkbox}>
								<input
									data-boolean={true}
									defaultChecked={
										props.permissions
											? props.permissions[
												'pay.viewbalance'
											  ]
											: false
									}
									id={'pay.viewbalance'}
									name={'pay.viewbalance'}
									type="checkbox"
									value="true"
								/>
							</td>
							<td className={styles.checkbox}>
								<input
									data-boolean={true}
									defaultChecked={
										props.permissions
											? props.permissions[
												'pay.requestmoney'
											  ]
											: false
									}
									id={'pay.requestmoney'}
									name={'pay.requestmoney'}
									type="checkbox"
									value="true"
								/>
							</td>
							<td className={styles.checkbox}>
								<input
									data-boolean={true}
									defaultChecked={
										props.permissions
											? props.permissions['pay.sendmoney']
											: false
									}
									id={'pay.sendmoney'}
									name={'pay.sendmoney'}
									type="checkbox"
									value="true"
								/>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	)
}

export type PermissionsProps = {
	data?: any
	permissions: { [key: string]: boolean }
}

type objectType = {
	actions: { [key: string]: string }
	name: string
	requiredRoles: string[]
	title: string
}
