import { ReactElement, useState } from 'react'

import AddIcon from '../../../../assets/icons/add.svg'
import DeleteIcon from '../../../../assets/icons/delete.svg'
import { FieldProps } from '../../Field'
import { EditContainerType } from '../ContainerType'
import { EditUnit } from '../Unit'
import styles from './Containers.module.scss'

export const EditContainers = (props: FieldProps) => {
	const [containerCount, setContainerCount] = useState(
		props.data?.containers?.length ? props.data?.containers?.length : 0
	)

	const typesOfService = [
		{
			abbreviation: 'bb',
			name: 'Break Bulk',
		},
		{
			abbreviation: 'cs',
			name: 'Container Station',
		},
		{
			abbreviation: 'cy',
			name: 'Container Yard',
		},
		{
			abbreviation: 'hd',
			name: 'Headload of Devanning',
		},
		{
			abbreviation: 'hh',
			name: 'House-to-House',
		},
		{
			abbreviation: 'hp',
			name: 'House-to-Pier',
		},
		{
			abbreviation: 'md',
			name: 'Mixed Delivery',
		},
		{
			abbreviation: 'nc',
			name: 'Non-Containerized',
		},
		{
			abbreviation: 'ph',
			name: 'Pier-to-House',
		},
		{
			abbreviation: 'pp',
			name: 'Pier-to-Pier',
		},
		{
			abbreviation: 'ro',
			name: 'Roll On - Roll Off',
		},
	]

	const rows: ReactElement[] = []
	for (let i = 0; i < containerCount; i++) {
		rows.push(
			<div
				className={styles.row}
				id={`${props.field.Property}_${i}`}
				key={`containerNumber${i}`}
			>
				<input
					data-number={true}
					defaultValue={
						props.data?.containers
							? props.data?.containers[i]?.id
							: ''
					}
					id={`${props.field.Property}_${i}_id`}
					name={`${props.field.Property}_${i}_id`}
					type="hidden"
				/>
				<input
					data-number={true}
					defaultValue={
						props.data?.containers
							? props.data?.containers[i]?.shipment
							: ''
					}
					id={`${props.field.Property}_${i}_shipment`}
					name={`${props.field.Property}_${i}_shipment`}
					type="hidden"
				/>
				<input
					data-boolean={true}
					defaultValue={'true'}
					id={`${props.field.Property}_${i}_active`}
					name={`${props.field.Property}_${i}_active`}
					type="hidden"
				/>
				<div
					className={styles.row}
					id={`${props.field.Property}_${i}_contents`}
				>
					<div className={styles.deleteColumn}>
						<div>{i + 1}</div>
						<img
							className={styles.delete}
							onClick={() => {
								// eslint-disable-next-line no-alert
								if (confirm('Really delete container?')) {
									(
										document.getElementById(
											`${props.field.Property}_${i}_active`
										) as HTMLInputElement
									).value = 'false'
									;(
										document.getElementById(
											`${props.field.Property}_${i}`
										) as HTMLInputElement
									).style.display = 'none'
									;(
										document.getElementById(
											`${props.field.Property}_${i}_contents`
										) as HTMLInputElement
									).innerHTML = ''

									if (props.onChange) {
										props.onChange(null)
									}
								}
							}}
							src={DeleteIcon}
							title="DELETE ROW"
						/>{' '}
					</div>
					<div className={styles.inputColumn}>
						<div className={styles.row}>
							<div className={styles.oneTenth}>
								<div className={styles.label}>
									CONTAINER NUMBER:{' '}
									<span
										className="hardRequired"
										title="REQUIRED"
									>
										*
									</span>
								</div>
								<input
									className={
										!props.isSearch ? 'hardRequired' : ''
									}
									data-softrequired={true}
									defaultValue={
										props.data?.containers
											? props.data?.containers[i]?.number
											: ''
									}
									id={`${props.field.Property}_${i}_number`}
									maxLength={11}
									minLength={11}
									name={`${props.field.Property}_${i}_number`}
									onChange={props.onChange}
									pattern={'[A-Za-z]{4}[0-9]{7}'}
									placeholder=" "
									readOnly={
										props.readOnly || props.field.ReadOnly
									}
									required={true}
									title={'CONTAINER NUMBER'}
									type="text"
								/>
							</div>
							{!props.isSearch ? (
								<>
									<div className={styles.oneTenth}>
										<EditContainerType
											data-softrequired={true}
											field={{
												HardRequired: true,
												Label: 'CONTAINER TYPE',
												Property: `${props.field.Property}_${i}_type`,
											}}
											isSearch={props.isSearch}
											onChange={props.onChange}
											readOnly={
												props.readOnly ||
												props.field.ReadOnly
											}
											value={
												props.data?.containers
													? props.data?.containers[i]
														?.type
													: ''
											}
										/>{' '}
									</div>

									<div className={styles.oneTenth}>
										<div className={styles.label}>
											CBM:{' '}
											<span
												className="softRequired"
												title="REQUIRED"
											>
												*
											</span>
										</div>
										<input
											className={
												!props.isSearch
													? 'softRequired'
													: ''
											}
											data-number={true}
											data-softrequired={true}
											defaultValue={
												props.data?.containers
													? props.data?.containers[i]
														?.cbm
													: ''
											}
											id={`${props.field.Property}_${i}_cbm`}
											name={`${props.field.Property}_${i}_cbm`}
											onChange={(
												e: React.ChangeEvent<HTMLInputElement>
											) => {
												const packageNodes =
													document.querySelectorAll(
														'input[id^=containers_][id$=_cbm]'
													)
												let totalCBM = 0
												packageNodes.forEach(
													(node: Element) => {
														const cbm = parseInt(
															(
																node as HTMLInputElement
															).value ?? '0'
														)
														if (!isNaN(cbm)) {
															totalCBM += cbm
														}
													}
												)
												;(
													document.getElementById(
														'total_cbm'
													) as HTMLInputElement
												).value = totalCBM.toString()

												if (props.onChange) {
													props.onChange(e)
												}
											}}
											placeholder=" "
											readOnly={
												props.readOnly ||
												props.field.ReadOnly
											}
											step={0.001}
											title="CBM"
											type="number"
										/>
									</div>

									<div className={styles.oneTenth}>
										<div className={styles.label}>
											TYPE OF SERVICE:{' '}
											<span
												className="hardRequired"
												title="REQUIRED IN ORDER TO SAVE"
											>
												*
											</span>
										</div>
										{props.readOnly ||
										props.field.ReadOnly ? (
												<input
													readOnly={true}
													title="CBM"
													type="text"
													value={
														typesOfService.filter(
															(
																typeOfService: any
															) => {
																if (
																	props.data
																		?.containers
																) {
																	return (
																		typeOfService.abbreviation ==
																	props.data
																		?.containers[
																			i
																		]?.type
																	)
																}
																return false
															}
														)[0]?.name
													}
												/>
											) : (
												<select
													className={`${
														styles.containerType
													} ${
														!props.isSearch
															? 'hardRequired'
															: ''
													}`}
													data-hardrequired={true}
													data-nullable={
														props.field.Nullable
													}
													defaultValue={
														props.data?.containers
															? props.data
																?.containers[i]
																?.type_of_service
															: ''
													}
													id={`${props.field.Property}_${i}_type_of_service`}
													name={`${props.field.Property}_${i}_type_of_service`}
													onChange={props.onChange}
													title="TYPE OF SERVICE"
												>
													<option value=""></option>
													{Object.entries(
														typesOfService
													).map((typeOfService: any) => {
														return (
															<option
																key={
																	typeOfService[1]
																		.abbreviation
																}
																value={
																	typeOfService[1]
																		.abbreviation
																}
															>
																{
																	typeOfService[1]
																		.name
																}
															</option>
														)
													})}
												</select>
											)}
									</div>

									<div className={styles.oneTenth}>
										<div className={styles.label}>
											PACKAGES:{' '}
											<span
												className="softRequired"
												title="REQUIRED"
											>
												*
											</span>
										</div>
										<input
											className={
												!props.isSearch
													? 'softRequired'
													: ''
											}
											data-number={true}
											data-softrequired={true}
											defaultValue={
												props.data?.containers
													? props.data?.containers[i]
														?.packages
													: ''
											}
											id={`${props.field.Property}_${i}_packages`}
											name={`${props.field.Property}_${i}_packages`}
											onChange={(
												e: React.ChangeEvent<HTMLInputElement>
											) => {
												const packageNodes =
													document.querySelectorAll(
														'input[id^=containers_][id$=_packages]'
													)
												let totalPackages = 0
												packageNodes.forEach(
													(node: Element) => {
														const p = parseInt(
															(
																node as HTMLInputElement
															).value ?? '0'
														)
														if (!isNaN(p)) {
															totalPackages += p
														}
													}
												)
												;(
													document.getElementById(
														'total_packages'
													) as HTMLInputElement
												).value =
													totalPackages.toString()

												if (props.onChange) {
													props.onChange(e)
												}
											}}
											placeholder=" "
											readOnly={
												props.readOnly ||
												props.field.ReadOnly
											}
											step="any"
											title="PACKAGES"
											type="text"
										/>
									</div>

									<div className={styles.oneTenth}>
										<div className={styles.label}>
											UNIT:{' '}
											<span
												className="hardRequired"
												title="REQUIRED IN ORDER TO SAVE"
											>
												*
											</span>
										</div>
										<EditUnit
											data-softrequired={true}
											field={{
												HardRequired: true,
												Label: 'UNIT',
												Property: `${props.field.Property}_${i}_unit`,
											}}
											isSearch={props.isSearch}
											onChange={props.onChange}
											readOnly={
												props.readOnly ||
												props.field.ReadOnly
											}
											value={
												props.data?.containers
													? props.data?.containers[i]
														?.unit
													: ''
											}
										/>
									</div>

									<div className={styles.oneTenth}>
										<div className={styles.label}>
											GROSS WEIGHT (KG):{' '}
											<span
												className="softRequired"
												title="REQUIRED"
											>
												*
											</span>
										</div>
										<input
											className={
												!props.isSearch
													? 'softRequired'
													: ''
											}
											data-number={true}
											data-softrequired={true}
											defaultValue={
												props.data?.containers
													? props.data?.containers[i]
														?.gross_weight
													: ''
											}
											id={`${props.field.Property}_${i}_gross_weight`}
											name={`${props.field.Property}_${i}_gross_weight`}
											onChange={(
												e: React.ChangeEvent<HTMLInputElement>
											) => {
												const packageNodes =
													document.querySelectorAll(
														'input[id^=containers_][id$=_gross_weight]'
													)
												let totalGrossWeight = 0
												packageNodes.forEach(
													(node: Element) => {
														const gw = parseInt(
															(
																node as HTMLInputElement
															).value ?? '0'
														)
														if (!isNaN(gw)) {
															totalGrossWeight +=
																gw
														}
													}
												)
												;(
													document.getElementById(
														'total_gross_weight'
													) as HTMLInputElement
												).value =
													totalGrossWeight.toString()

												if (props.onChange) {
													props.onChange(e)
												}
											}}
											placeholder=" "
											readOnly={
												props.readOnly ||
												props.field.ReadOnly
											}
											step={0.001}
											title="GROSS WEIGHT (KG)"
											type="number"
										/>
									</div>

									<div className={styles.oneTenth}>
										<div className={styles.label}>
											SEAL 1:{' '}
											<span
												className="softRequired"
												title="REQUIRED"
											>
												*
											</span>
										</div>
										<input
											className={
												!props.isSearch
													? 'softRequired'
													: ''
											}
											data-softrequired={true}
											defaultValue={
												props.data?.containers
													? props.data?.containers[i]
														?.seal1
													: ''
											}
											id={`${props.field.Property}_${i}_seal1`}
											name={`${props.field.Property}_${i}_seal1`}
											onChange={props.onChange}
											placeholder=" "
											readOnly={
												props.readOnly ||
												props.field.ReadOnly
											}
											title="SEAL 1"
											type="text"
										/>
									</div>

									<div className={styles.oneTenth}>
										<div className={styles.label}>
											SEAL 2:
										</div>
										<input
											defaultValue={
												props.data?.containers
													? props.data?.containers[i]
														?.seal2
													: ''
											}
											id={`${props.field.Property}_${i}_seal2`}
											name={`${props.field.Property}_${i}_seal2`}
											onChange={props.onChange}
											readOnly={
												props.readOnly ||
												props.field.ReadOnly
											}
											title="SEAL 2"
											type="text"
										/>
									</div>

									<div className={styles.oneTenth}>
										<div className={styles.label}>
											PO # / REF #: {'\u00A0'}
											<span
												className="hardRequired"
												title="REQUIRED IN ORDER TO SAVE"
											>
												*
											</span>
										</div>
										<input
											className={`${
												!props.isSearch
													? 'hardRequired'
													: ''
											}`}
											data-hardrequired={true}
											defaultValue={
												props.data?.containers
													? props.data?.containers[i]
														?.po_number
													: ''
											}
											id={`${props.field.Property}_${i}_po_number`}
											name={`${props.field.Property}_${i}_po_number`}
											onChange={props.onChange}
											placeholder=" "
											readOnly={
												props.readOnly ||
												props.field.ReadOnly
											}
											required={true}
											title="PO # / REF #"
											type="text"
										/>
									</div>
								</>
							) : (
								<></>
							)}
						</div>

						{!props.isSearch ? (
							<div className={styles.row}>
								<div className={styles.oneHalf}>
									<div className={styles.label}>
										CARGO DESCRIPTION:
										<span
											className="softRequired"
											title="REQUIRED"
										>
											*
										</span>
									</div>
									<textarea
										className={
											!props.isSearch
												? 'softRequired'
												: ''
										}
										data-softrequired={true}
										defaultValue={
											props.data?.containers
												? props.data?.containers[i]
													?.cargo_description
												: ''
										}
										id={`${props.field.Property}_${i}_cargo_description`}
										name={`${props.field.Property}_${i}_cargo_description`}
										onChange={props.onChange}
										readOnly={
											props.readOnly ||
											props.field.ReadOnly
										}
										title="CARGO DESCRIPTION"
									></textarea>
								</div>

								<div className={styles.oneHalf}>
									<div className={styles.label}>
										MARKS:{' '}
										<span
											className="softRequired"
											title="REQUIRED"
										>
											*
										</span>
									</div>
									<textarea
										className={
											!props.isSearch
												? 'softRequired'
												: ''
										}
										data-softrequired={true}
										defaultValue={
											props.data?.containers
												? props.data?.containers[i]
													?.marks
												: ''
										}
										id={`${props.field.Property}_${i}_marks`}
										minLength={3}
										name={`${props.field.Property}_${i}_marks`}
										onChange={props.onChange}
										placeholder=" "
										readOnly={
											props.readOnly ||
											props.field.ReadOnly
										}
										title="MARKS"
									></textarea>
								</div>
							</div>
						) : (
							<></>
						)}
					</div>
				</div>
			</div>
		)
	}

	if (containerCount == 0) {
		rows.push(
			<div
				className={`${styles.row} ${styles.emptyRow}`}
				key="noContainers"
			>
				No containers.
			</div>
		)
	}

	return (
		<div
			className={`${styles.fieldOuterContainer} ${
				!props.isSearch ? styles.editForm : ''
			}`}
			id={props.field.Property ? `${props.field.Property}_container` : ''}
			key="addContainer"
		>
			{rows}
			{!(props.isSearch || props.readOnly) ? (
				<div className={styles.buttonRow}>
					<button type="button">
						<img
							alt="ADD CONTAINER"
							onClick={() => {
								setContainerCount(containerCount + 1)
								if (props.onChange) {
									props.onChange(null)
									setTimeout(() => {
										if (props.onChange) {
											props.onChange(null)
										}
									}, 100)
									setTimeout(() => {
										if (props.onChange) {
											props.onChange(null)
										}
									}, 250)
									setTimeout(() => {
										if (props.onChange) {
											props.onChange(null)
										}
									}, 500)
									setTimeout(() => {
										if (props.onChange) {
											props.onChange(null)
										}
									}, 1000)
									setTimeout(() => {
										if (props.onChange) {
											props.onChange(null)
										}
									}, 2500)
								}
							}}
							src={AddIcon}
						/>
					</button>
				</div>
			) : (
				<></>
			)}
		</div>
	)
}
