import axios from 'axios'
import { MouseEvent, useEffect, useMemo, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'

import ArrivalNoticeIcon from '../../../../assets/icons/arrival-notice.svg'
import DownArrowIcon from '../../../../assets/icons/arrow-down.svg'
import CopyIcon from '../../../../assets/icons/copy.svg'
import DeleteIcon from '../../../../assets/icons/delete.svg'
import ShipmentHistoryIcon from '../../../../assets/icons/history.svg'
import PDFIcon from '../../../../assets/icons/pdf.svg'
import { EditForm, ParsedFormData } from '../../../../components/Form/EditForm'
import { GlobalTopTabItems } from '../../../../components/Tabs'
import { DefaultLayout } from '../../../../layouts/DefaultLayout'
import { APIHostname, HasPermission, LogOff } from '../../../../Profile'
import { StandaloneArrivalNoticeLayout } from '../../../../types/data/ArrivalNoticeOverride'
import styles from './ArrivalNotice.module.scss'

export const ArrivalNotice = () => {
	const { id } = useParams()
	const [arrivalNotice, setArrivalNotice]: any = useState(null)
	const [loaded, setLoaded] = useState(false)
	const [errorMessage, setErrorMessage] = useState(<></>)
	const initialRedirectMessage = sessionStorage['redirect-message']
	const [redirectMessage] = useState(initialRedirectMessage)

	// Vary based on query string.
	const location = useLocation()

	useEffect(() => {
		axios({
			data: {},
			headers: {
				Authorization: `Bearer ${localStorage['token']}`,
			},
			method: 'GET',
			url: `${APIHostname}/api/v1/arrival_notices/${id}${location.search}`,
		})
			.then((response: any) => {
				setErrorMessage(<></>)
				setArrivalNotice(response.data)
				setLoaded(true)
			})
			.catch((error: any) => {
				switch (error?.response?.status) {
				case 401:
					LogOff('token-expired')
					return
				case 403:
					setErrorMessage(
						<>
								Error: You do not have permission to view
								arrival notices.
						</>
					)
					break
				default:
					if (error?.response?.data?.error) {
						setErrorMessage(
							error?.response?.data?.link ? (
								<Link to={error?.response?.data?.link}>
										Error:
									{error?.response?.data?.error}
								</Link>
							) : (
								<>{error?.response?.data?.error}</>
							)
						)
					} else {
						setErrorMessage(<>Error: Unknown error.</>)
							console.error(error) // eslint-disable-line
					}
				}
			})
	}, [location])

	const ArrivalNoticeLeftMenuItems = [
		{
			icon: ArrivalNoticeIcon,
			text: 'ARRIVAL NOTICE',
			to: `/arrival_notices/${id}`,
		},
		{
			icon: PDFIcon,
			target: '_blank',
			text: 'ARRIVAL NOTICE PDF',
			to: `${APIHostname}/api/v1/arrival_notices/${id}/pdf`,
		},
		{
			icon: ShipmentHistoryIcon,
			text: 'ARRIVAL NOTICE HISTORY',
			to: `/arrival_notices/${id}/history`,
		},
	]

	const ArrivalNoticeBottomTabItems = [
		{
			icon: DownArrowIcon,
			text: `ARRIVAL NOTICE #${id}`,
			to: `/arrival_notices/${id}`,
		},
	]

	const ArrivalNoticeTabMenuItems = [
		{
			icon: CopyIcon,
			onClick: async (e: MouseEvent<Element>) => {
				e.preventDefault()

				if (document.getElementById('hardInvalidFields')?.innerHTML) {
					// eslint-disable-next-line
					alert(
						'Please correct the errors on the form before copying.'
					)
					window.scrollTo(0, document.body.scrollHeight)
					return
				}

				// eslint-disable-next-line
				const confirmation = confirm(`Really copy arrival notice?`)
				if (!confirmation) {
					return
				}

				const form = document.querySelector(
					'form[name=\'arrivalNotice\']'
				)
				const formData = new FormData(form as HTMLFormElement)
				const parsedFormData = await ParsedFormData(formData, [
					'charges',
				])
				parsedFormData.copy = true
				axios({
					data: parsedFormData,
					headers: {
						Authorization: `Bearer ${localStorage['token']}`,
						'Content-Type': 'application/json',
					},
					method: 'POST',
					url: `${APIHostname}/api/v1/arrival_notices`,
				})
					.then((response: any) => {
						sessionStorage[
							'redirect-message'
						] = `Arrival Notice #${response.data.arrival_notice_id} created.`
						window.history.replaceState(
							{},
							'',
							`/arrival_notices/${response.data.arrival_notice_id}`
						)
						document.location.reload()
					})
					.catch((error: any) => {
						switch (error?.response?.status) {
						case 401:
							LogOff('token-expired')
							return
						case 403:
							setErrorMessage(
								<>
										Error: You do not have permission to
										create arrival notices.
								</>
							)
							break
						default:
							if (error?.response?.data?.error) {
								setErrorMessage(
									error?.response?.data?.link ? (
										<Link
											to={error?.response?.data?.link}
										>
												Error:
											{error?.response?.data?.error}
										</Link>
									) : (
										<>{error?.response?.data?.error}</>
									)
								)
							} else {
								setErrorMessage(<>Error: Unknown error.</>)
									console.error(error) // eslint-disable-line
							}
						}
					})
			},
			requiredPermissions: ['arrival_notice.create'],
			text: 'COPY',
			to: '#',
		},
	]
	if (arrivalNotice?.active) {
		ArrivalNoticeTabMenuItems.push({
			icon: DeleteIcon,
			onClick: async (e: MouseEvent<Element>) => {
				e.preventDefault()

				// eslint-disable-next-line
				const confirmation = confirm(`Really delete arrival notice?`)
				if (!confirmation) {
					return
				}

				axios({
					headers: {
						Authorization: `Bearer ${localStorage['token']}`,
						'Content-Type': 'application/json',
					},
					method: 'DELETE',
					url: `${APIHostname}/api/v1/arrival_notices/${id}`,
				})
					.then((response: any) => {
						sessionStorage[
							'redirect-message'
						] = `Arrival notice #${response.data.shipment_id} deleted.`
						window.history.replaceState({}, '', '/arrival_notices')
						document.location.reload()
					})
					.catch((error: any) => {
						switch (error?.response?.status) {
						case 401:
							LogOff('token-expired')
							return
						case 403:
							setErrorMessage(
								<>
										Error: You do not have permission to
										delete arrival notices.
								</>
							)
							break
						default:
							if (error?.response?.data?.error) {
								setErrorMessage(
									error?.response?.data?.link ? (
										<Link
											to={error?.response?.data?.link}
										>
												Error:
											{error?.response?.data?.error}
										</Link>
									) : (
										<>{error?.response?.data?.error}</>
									)
								)
							} else {
								setErrorMessage(<>Error: Unknown error.</>)
									console.error(error) // eslint-disable-line
							}
						}
					})
			},
			requiredPermissions: ['arrival_notice.delete'],
			text: 'DELETE',
			to: '#',
		})
	} else {
		ArrivalNoticeTabMenuItems.push({
			icon: DeleteIcon,
			onClick: async (e: MouseEvent<Element>) => {
				e.preventDefault()

				// eslint-disable-next-line
				const confirmation = confirm(`Really undelete arrival notice?`)
				if (!confirmation) {
					return
				}

				axios({
					headers: {
						Authorization: `Bearer ${localStorage['token']}`,
						'Content-Type': 'application/json',
					},
					method: 'POST',
					url: `${APIHostname}/api/v1/arrival_notices/${id}/undelete`,
				})
					.then((response: any) => {
						sessionStorage[
							'redirect-message'
						] = `Arrival notice #${response.data.shipment_id} undeleted.`
						window.history.replaceState({}, '', '/arrival_notices')
						document.location.reload()
					})
					.catch((error: any) => {
						switch (error?.response?.status) {
						case 401:
							LogOff('token-expired')
							return
						case 403:
							setErrorMessage(
								<>
										Error: You do not have permission to
										undelete arrival notices.
								</>
							)
							break
						default:
							if (error?.response?.data?.error) {
								setErrorMessage(
									error?.response?.data?.link ? (
										<Link
											to={error?.response?.data?.link}
										>
												Error:
											{error?.response?.data?.error}
										</Link>
									) : (
										<>{error?.response?.data?.error}</>
									)
								)
							} else {
								setErrorMessage(<>Error: Unknown error.</>)
									console.error(error) // eslint-disable-line
							}
						}
					})
			},
			requiredPermissions: ['arrival_notice.delete'],
			text: 'UNDELETE',
			to: '#',
		})
	}
	ArrivalNoticeTabMenuItems.reverse()

	const form = useMemo(
		() => (
			<EditForm
				data={arrivalNotice}
				layout={StandaloneArrivalNoticeLayout}
				name="arrivalNotice"
				onSubmit={async (formData: FormData) => {
					const parsedFormData = await ParsedFormData(formData, [
						'charges',
					])

					axios({
						data: parsedFormData,
						headers: {
							Authorization: `Bearer ${localStorage['token']}`,
							'Content-Type': 'application/json',
						},
						method: 'POST',
						url: `${APIHostname}/api/v1/arrival_notices/${id}`,
					})
						.then(() => {
							sessionStorage[
								'redirect-message'
							] = `Arrival Notice #${id} updated.`
							window.history.replaceState(
								{},
								'',
								'/arrival_notices'
							)
							document.location.reload()
						})
						.catch((error: any) => {
							switch (error?.response?.status) {
							case 401:
								LogOff('/#token-expired')
								return
							case 403:
								setErrorMessage(
									<>
											Error: You do not have permission to
											edit arrival notices.
									</>
								)
								break
							default:
								if (error?.response?.data?.error) {
									setErrorMessage(
										<>
												Error:
											{error?.response?.data?.error}
										</>
									)
								} else {
									setErrorMessage(
										<>Error: Unknown error.</>
									)
										console.error(error) // eslint-disable-line
								}
							}
						})
				}}
				previewAPIData={async (formData: FormData) => {
					const parsedFormData = await ParsedFormData(formData, [
						'charges',
					])

					return {
						data: JSON.stringify(parsedFormData, null, 4),
						method: 'POST',
						url: `${APIHostname}/api/v1/arrival_notices/${id}`,
					}
				}}
				readOnly={
					!HasPermission('arrival_notice', 'update') ||
					arrivalNotice?.lock ||
					!arrivalNotice?.active
				}
			/>
		),
		[arrivalNotice]
	)

	if (!loaded) {
		if (errorMessage) {
			return (
				<DefaultLayout
					bottomTabItems={ArrivalNoticeBottomTabItems}
					leftMenuItems={ArrivalNoticeLeftMenuItems}
					showPrint={true}
					tabMenuItems={ArrivalNoticeTabMenuItems}
					topTabItems={GlobalTopTabItems}
				>
					<div className={styles.shipmentContainer}>
						<div className={styles.redirectMessage}>
							{redirectMessage}
						</div>
						<div className={styles.errorMessage}>
							{errorMessage}
						</div>
					</div>
				</DefaultLayout>
			)
		}

		return <div className="loading">Loading...</div>
	}

	sessionStorage.removeItem('redirect-message')

	return (
		<DefaultLayout
			bottomTabItems={ArrivalNoticeBottomTabItems}
			leftMenuItems={ArrivalNoticeLeftMenuItems}
			showPrint={true}
			tabMenuItems={ArrivalNoticeTabMenuItems}
			topTabItems={GlobalTopTabItems}
		>
			<div className={styles.arrivalNoticeContainer}>
				<div className={styles.redirectMessage}>{redirectMessage}</div>
				<div className={styles.errorMessage}>
					{errorMessage}{' '}
					{!arrivalNotice?.active ? (
						<>This arrival notice has been deleted.</>
					) : (
						<></>
					)}
				</div>

				{form}
			</div>
		</DefaultLayout>
	)
}
