import { Link } from 'react-router-dom'

import { ExtendedShipment } from '../../../types/data/Shipment'
import { FormatDateMMDDYYYYToday } from '../../../Utils'

export const NewDocuments = (props: NewDocumentsProps) => {
	return (
		<>
			<h2>NEW DOCUMENTS:</h2>
			<Link
				className="seeAll"
				to="/shipments/?filters=[{%22field%22:%22new_documents%22,%22operator%22:%22eq%22,%22value%22:%22true%22}]"
			>
				See All
			</Link>

			<div className="box">
				<table>
					<thead>
						<tr>
							<th>BOOKING #</th>
							<th>CONSIGNEE</th>
							<th>HBL</th>
							<th>MBL</th>
							<th>ETD</th>
						</tr>
					</thead>
					<tbody>
						{props.newDocuments ? (
							props.newDocuments.map(
								(shipment: ExtendedShipment, index: number) => {
									return (
										<tr key={`shipment${index}`}>
											<td>
												<Link
													to={`/shipments/${shipment.id}/documents`}
												>
													{shipment.booking_number}
												</Link>
											</td>
											<td>{shipment.consignee_name}</td>
											<td>{shipment.hbl}</td>
											<td>{shipment.mbl}</td>
											<td>
												{FormatDateMMDDYYYYToday(
													shipment.etd
												)}
											</td>
										</tr>
									)
								}
							)
						) : (
							<tr>
								<td colSpan={5}>No new documents.</td>
							</tr>
						)}
					</tbody>
				</table>
			</div>
		</>
	)
}

/*				{props.newDocuments?.length > 3 ? (
					<Link
						className="more"
						to={`/shipments/?filters=${EncodedStringify(filters)}`}
					>
						MORE...
					</Link>
				) : (
					<></>
				)}*/

export type NewDocumentsProps = {
	newDocuments: ExtendedShipment[]
}
