import Control from '../Control'
import { Layout } from '../Layout'

export type ShipmentNRA = {
	id: number
	organization_name: string
	reference_number: string
}

export const ShipmentNRALayout: Layout = {
	Groups: [
		{
			RequiredRows: [
				{
					Fields: [
						{
							Control: Control.Checkbox,
							Label: 'DECLINE TO HAVE GOAL PORTAL PREPARE NRA. I WILL BE RESPONSIBLE FOR ALL FMC FILING REQUIREMENTS.',
							Property: 'decline_nra',
						},
					],
				},
			],
		},
		{
			Label: 'ASSOCIATED NRA',
			LabelBackground: 'blue',
			Padding: '0',
			RequiredRows: [
				{
					Fields: [
						{
							Control: Control.ShipmentNRAs,
							HardRequired: true,
							Property: 'nras',
							Width: 100,
						},
					],
				},
			],

			Width: 100,
		},
	],
}
