import Control from '../Control'
import { Layout } from '../Layout'

export const OrganizationAccessDocumentLayout: Layout = {
	Groups: [
		{
			Border: true,
			RequiredRows: [
				{
					Fields: [
						{
							AutoFocus: true,
							Control: Control.DocumentType,
							HardRequired: true,
							IncludeInEdit: true,
							IncludeInSearch: false,
							Label: 'TYPE',
							Property: 'type',
							Width: 100,
						},
					],
				},
				{
					Fields: [
						{
							AutoComplete: false,
							AutoFocus: true,
							Control: Control.TextBox,
							HardRequired: true,
							IncludeInEdit: true,
							IncludeInSearch: true,
							Label: 'DESCRIPTION',
							Property: 'description',
							Width: 100,
						},
					],
				},
				{
					Fields: [
						{
							Control: Control.File,
							HardRequired: true,
							IncludeInEdit: true,
							IncludeInSearch: true,
							Label: 'DOCUMENT',
							Property: 'blob',
							Width: 100,
						},
					],
				},
				{
					Fields: [
						{
							Control: Control.Boolean,
							IncludeInEdit: true,
							IncludeInSearch: true,
							Label: 'ALLOW ORGANIZATIONS (CONSIGNEES, SHIPPERS) TO ACCESS THIS DOCUMENT',
							Property: 'organization_access',
							Width: 100,
						},
					],
				},
			],
			Width: 100,
		},
	],
}
